<script lang="ts">
    import PortadaInd from '../components/PortadaInd.vue';
	import NavbarInd from '../components/NavbarInd.vue';
	import FooterInd from '../components/FooterInd.vue';
    import CarruOferta from './components/CarruOfertaA.vue';
    import Carru10Camp from './components/Carru10Camp.vue';
    import { defineComponent } from 'vue';
    import EgresadosVid from './components/oferta/EgresadosHuma.vue';
    import VideoHiL from './components/VideoHiL.vue';
    import Botones from './components/Botones.vue';
    import HoverNivel from './components/HoverNivel.vue';

    export default defineComponent({
        components: { PortadaInd, NavbarInd, FooterInd, CarruOferta, Carru10Camp, EgresadosVid, VideoHiL, Botones, HoverNivel },
        data() {
            return {
                showImage: true,
                videoUrl: "https://www.youtube.com/embed/bA3DNFmpeIs?si=J3d97hR3yZQHlF9y",
                img: "./pagesImages/SQUARE-ejec.jpg",
                showImage2: true,
                videoUrl2: "https://www.youtube.com/embed/tWkEDcMN1Fo?si=jG0FXkuyIL6bDhxJ",
                img2: "./pagesImages/ejecutivo-video.jpg",
            };
        },
        methods: {
            showVideo() {
                this.showImage = false;
            },
            showVideo2() {
                this.showImage2 = false;
            },
        },
    });
</script>

<template class="bg-white w-full">
    <section class="flex pt-[88px] lg:pt-[143px] justify-center items-center mb-12 w-full flex-col">
        <div class="flex items-center justify-center w-full bg-center bg-cover md:bg-[center-right] bg-no-repeat" style="background-image: url(/pagesImages/ejecutivo-banner.jpg);">
            <div class="flex justify-center w-full">
                <div class="flex text-justify w-full justify-center items-center max-w-4xl lg:max-w-[1200px] bg-white/50 md:bg-transparent">
                    <div class="w-full items-end flex md:mb-56 my-8 md:my-0 md:mt-32 flex-col px-4">
                        <h1 class="font-normal mb-0 text-[30px] text-black md:text-gray-600">Modelo</h1>
                        <h1 class="font-serif text-hgold text-[50px] mb-2">Ejecutivo</h1>
                        <p class="text-black md:text-gray-600 font-serif text-[19px]">"La mejor forma de predecir el futuro es cre&aacute;ndolo".</p>
                        <quote class="text-black font-serif md:text-gray-600 text-right">-Peter Drucker-</quote>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="flex flex-col items-center w-full justify-center">
        <div class="flex w-full items-center justify-center">
            <div class="flex flex-col items-center w-full max-w-4xl lg:max-w-[1200px] px-4">
                <p class="font-serif md:text-[36px] text-3xl leading-tight text-hgold mb-8">"Trabajo, estudio y<br class="md:hidden"> estoy con mi familia"</p>
                <div class="lg:flex items-center justify-center">
                    <div class="lg:w-[50%] text-justify mb-10 lg:mb-0 lg:mr-12">
                        <p>Nuestras disciplinas cuentan con el reconocimiento oficial de la Secretar&iacute;a de Educaci&oacute;n P&uacute;blica. Los programas son dise&ntilde;ados por especialistas en la materia y supervisados por el consejo acad&eacute;mico.</p>
                        <br>
                        <p>Universidad Humanitas es uno de los pioneros en el Sistema por Bloques, en el cual se consolidan tres materias en un trimestre; cada asignatura es impartida por un profesor responsable de la c&aacute;tedra con los contenidos de las tres materias durante todo el trimestre, que consta de 11 sesiones.</p>
                        <br>
                        <p>Cada uno de nuestros bloques agrupa las materias del mismo tipo de conocimiento, por lo que se vuelve un bloque especializado.</p>
                        <br>
                        <p>Durante el desarrollo acad&eacute;mico de nuestros alumnos, promovemos la construcci&oacute;n de relaciones con personas de su entorno profesional o con las que en un futuro pudieran establecer un plan de negocios, lo cual se lleva a cabo no s&oacute;lo dentro del aula, sino tambi&eacute;n mediante los coffee breaks que ofrecemos en el transcurso de sus sesiones.</p>
                    </div>
                    <div class="flex items-center justify-center">
                        <img src="./pagesImages/squere-ejec-1.jpg" alt="Modelo Ejecutivo">
                    </div>
                </div>
            </div>  
        </div>
    </section>

    <div class="my-10 flex items-center justify-center">
        <img src="../pages/pagesImages/adorno.png" loading="lazy" alt="45 aniversario">
    </div>

    <section class="flex items-center justify-center h-full w-full">
        <div class="flex items-center justify-center  max-w-4xl lg:max-w-[1200px] bg-cover bg-[center] bg-no-repeat" >
            <div class="lg:flex justify-between items-center lg:mx-12">
                <div class="flex justify-between items-center lg:mr-12 mb-4 cursor-pointer" title="Reproducir vídeo">
                    <VideoHiL :thumbnailUrl="img" :videoUrl="videoUrl2" class="w-full"/>
                </div>
                <div class="flex justify-between lg:w-[50%] mx-2">
                    <div class="flex flex-col text-[16px] text-justify text-gray-600 px-4">
                        <p class="text-hgold font-serif text-[42px] w-full text-center md:text-left mb-8">Metodolog&iacute;a</p>
                        <ul class="list-disc pl-4">
                            <li>Se cursan bloques trimestrales.</li>
                            <br>
                            <li>El compromiso del alumno en cada bloque equivale a 3 materias de su inscripci&oacute;n y es impartido por un especialista.</li>
                            <br>
                            <li>Se imparten de tres a cuatro horas de clases presenciales por semana, seg&uacute;n la complejidad de los temas que se presenten por sesi&oacute;n.</li>
                            <br>
                            <li>El trimestre se compone de 11 semanas y en la &uacute;ltima se aplica la evaluaci&oacute;n final.</li>
                            <br>
                            <li>La participaci&oacute;n en clase, foros, trabajos y examen conforman la totalidad de la evaluaci&oacute;n final.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="flex justify-center items-center w-screen h-full lg:h-[600px] bg-cover lg:bg-center bg-no-repeat my-28" style="background-image: url(../pagesImages/perfil-ejecutivo.jpg); ">
        <div class="lg:flex justify-between w-full max-w-4xl lg:max-w-[1200px] items-center">
            <div class="flex flex-col lg:justify-start justify-center items-center lg:items-start lg:w-[45%] w-full lg:my-36 my-12 text-justify px-4">
                <h1 class="text-[white]  text-left text-[28px]" style="">Perfil del</h1>
                <h1 class="text-hgold text-left font-serif mb-8 text-[42px]">Alumno Ejecutivo</h1>
                <div class="text-white text-base">
                    <p class="pb-4">
                        Los aspirantes al estudiar en el Modelo Ejecutivo son personas que comprenden la naturaleza semi abierta del Modelo Ejecutivo y est&aacute;n dispuestos a asumir la responsabilidad de gestionar su propio tiempo y compromisos para equilibrar el trabajo y los estudios.
                    </p>
                    <p class="pb-4">
                        Tienen la capacidad de autodisciplina necesaria para mantenerse motivados y enfocados en sus objetivos acad&eacute;micos, incluso en un entorno de aprendizaje menos estructurado.
                    </p>
                    <p>
                        Comprometidos por la oportunidad de combinar sus responsabilidades laborales con la b&uacute;squeda de una educaci&oacute;n superior, y la seguridad de que podr&aacute;n cumplir exitosamente con los requisitos del programa.
                    </p>
                </div>
            </div>
        </div>
    </section>

    <section class="flex flex-col w-full items-center mb-12">
        <div class="px-4 mb-8">
            <p class="font-serif text-hgold text-[42px] mb-8">Nuestra Oferta Acad&eacute;mica</p>
            <p class="text-lg">"Un hombre sabio se buscar&aacute; m&aacute;s oportunidades de las que se le presentan".</p>
            <div class="flex w-full justify-end items-center">
                <quote class="text-black font-times font-bold text-right italic">-Francis Bacon-</quote>
            </div>
        </div>
        <HoverNivel/>
    </section>

    <section class="lg:flex items-center w-full justify-center bg-cover bg-[center] bg-no-repeat" style="background-image: url(/pagesImages/bg-carrusel.jpg); position: relative; background-attachment: fixed; background-position: center; background-repeat: no-repeat; background-size: cover; overflow: hidden; transition: transform 0.3s ease;">
        <div clas="flex justify-center w-full">
            <div class="lg:flex justify-center lg:mb-12 w-full ">
                <div class="flex items-center justify-center">
                    <VideoHiL :thumbnailUrl="img2" :videoUrl="videoUrl" class="lg:mr-12 cursor-pointer w-full"/>
                </div>
                <div class="lg:w-[35%] py-16 px-4 lg:px-0">
                    <div class="text-center lg:text-left ">
                        <h2 class="text-white leading-none text-[28px] font-montse">Excelencia</h2>
                        <h2 class="text-hgold mb-4 font-serif text-[42px] ">Acad&eacute;mica</h2>
                    </div>
                    <p class="text-justify text-white">Durante el desarrollo acad&eacute;mico de nuestros alumnos, promovemos la construcci&oacute;n de relaciones con personas de su entorno profesional o con las que en un futuro pudieran establecer un plan de negocios, lo cual se lleva a cabo no s&oacute;lo dentro del aula, sino tambi&eacute;n mediante los coffe breaks que ofrecemos en el transcurso de sus sesiones.<br><br> Tenemos presencia en diversos estados de la Rep&uacute;blica Mexicana. Nuestros campus cuentan con una arquitectura neocl&aacute;sica, con jardines y fuentes dise&ntilde;ados para fomentar el estudio y la reflexi&oacute;n. La arquitectura conserva y crea espacios verdes, ya que tenemos la convicci&oacute;n de que el respeto y el amor por el entorno contribuir&aacute; a mejorar el mundo.</p>
                </div>
            </div>
        </div>
    </section>

    <EgresadosVid class="mb-12"/>

    <section class="flex items-center justify-center w-full">
        <div class="lg:flex justify-start w-full max-w-4xl lg:max-w-[1200px] items-center mb-12">
            <div class="flex items-center justify-center">
                <img src="./pagesImages/vive-eje.jpg" loading="lazy" alt="Humanitas Vive">
            </div>
            <div class="flex flex-col lg:w-[50%] items-center text-justify lg:ml-12">
                <div class="mb-8 text-justify px-4">
                    <div class="text-center lg:text-left w-full mt-6 lg:mt-0">
                        <p class="text-gray-700 mb-0 leading-tight font-normal text-[28px]">Programa</p>
                        <p class="text-hgold mt-0 leading-tight font-serif text-[42px]">Humanitas Vive</p>
                    </div>
                    <br>
                    <p>Es un programa de voluntariado en el cual las acciones y actividades est&aacute;n orientadas a la responsabilidad social de Universidad Humanitas, mediante el cual los alumnos pueden liberar su servicio social.</p>
                    <br>
                    <p>Estas actividades pueden ser:</p>
                    <br>
                    <p class="pl-2">• Sociales</p>
                    <p class="pl-2">• Culturales</p>
                    <p class="pl-2">• De altruismo</p>
                    <p class="pl-2">• Deportivas</p>
                    <p class="pl-2">• Ambientales</p>
                    <br>
                    <p>Humanitas Vive trabaja con instituciones autorizadas a las cuales se les brinda ayuda mediante donativos (sea en especie o en dinero) o mediante la organizaci&oacute;n de alguna actividad que vaya acorde con su ramo.</p>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
</style>