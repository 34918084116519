<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">La inclusión de las mujeres y la diversidad en<br class="md:block hidden">el  Derecho por Mariel Cabañas</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col md:tracking-normal tracking-tight w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-200">
            <img src="/images/inclusion-mujeres-diversidad-derecho-impacto-benefico-sociedad.webp" alt="La inclusión de las mujeres y la diversidad en el Derecho tiene un impacto benéfico en la sociedad" class="mb-6">
          <div class="flex flex-col justify-center text-justify  text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/recursos" class=" text-hone">Recursos</router-link> > <router-link to="/blog/recursos/entrevistas-y-conferencias" class=" text-hone">Entrevistas y Conferencias</router-link></p>
            <div class="lg:flex text-left items-start justify-start">
                <ul class="lg:flex list-disc pl-5 italic text-left text-[18px] md:text-[20px] mb-6 font-helvetica"><li class="mr-16 text-htwo">30 de mayo de 2024</li>  <li>Carolina Reyes Martínez</li></ul>
            </div>
            <p class="md:mb-4 mb-6">La <b>inclusión y la diversidad</b> son principios necesarios para el desarrollo de una sociedad equitativa y justa. En este artículo, explicaremos algunas estrategias para la inclusión laboral y educativa. Lee más para enterarte de la valiosa perspectiva de <b>Mariel Cabañas, presidenta de Abogadas MX.</b></p>
            <p class="md:mb-8 mb-10">Esta organización promueve el crecimiento profesional de las mujeres en el ámbito legal, y que promueve  la inclusión y la diversidad en todos los sectores.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">La importancia de la inclusión y la diversidad</h2>
            <p class="md:mb-4 mb-6"><b>La inclusión se refiere a la práctica de asegurar que todas las personas,</b> independientemente de sus diferencias, <b>tengan igualdad de oportunidades y acceso a recursos y beneficios.</b> Es un enfoque social que reconoce las potencialidades y habilidades de cada persona.</p>
            <p class="md:mb-4 mb-6"><b>La diversidad es la presencia de diferencias entre las personas, incluyendo género, raza, discapacidad, entre otras.</b> El respeto a la diversidad significa entender estas disparidades y también brindar oportunidades a las personas, que se sientan confiadas en sus cualidades y capacidades, ya que esto mejora la productividad, fomenta la creatividad y la innovación.</p>
            <p class="md:mb-8 mb-10">Incorporar la inclusión y diversidad no sólo es una cuestión de justicia social, también <router-link to="/ser-humanitas/responsabilidad-social/acciones-socialmente-responsables" class=" text-htwo">aporta beneficios tangibles a las organizaciones y comunidades,</router-link> tales como un mejor desempeño y una cultura más rica y dinámica.</p>
            <img src="/images/inclusion-mujeres-diversidad-en-espacios-laborales-derecho.webp" alt="La inclusión y la diversidad tanto en la escuela como en el trabajo genera confianza" class="md:mb-8 mb-10">
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Estrategias para la inclusión en el ámbito educativo</h3>
            <p class="md:mb-4 mb-6">Una estrategia necesaria para la inclusión educativa es <b>la implementación de políticas que aseguren la participación de todos los estudiantes.</b> Estas políticas deben ser claras y promover un ambiente escolar donde cada estudiante sienta que pertenece y es valorado: adecuar procesos pedagógicos, técnicos y crear un entorno seguro para cada uno de ellos.</p>
            <p class="md:mb-4 mb-6">Es fundamental concientizarlos acerca de la diversidad y la empatía, realizando talleres, charlas y programas que aborden temas como la igualdad de oportunidades y el respeto por las diferencias individuales.</p>
            <p class="md:mb-8 mb-10">Las dinámicas grupales y los juegos inclusivos <b>son excelentes herramientas para comprender y apreciar las diferencias individuales.</b> Su intención es resaltar la importancia del trabajo en equipo y la cooperación, al tiempo que celebran la diversidad de habilidades y puntos de vista.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Celebración de la diversidad cultural</h3>
            <p class="md:mb-8 mb-10"><b>Eventos como días internacionales, ferias culturales y exposiciones para destacar las diferentes culturas</b> presentes en una comunidad. Estas celebraciones no sólo educan a las personas sobre otras culturas, también promueven el respeto y la inclusión.</p>
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
                <Contacto class="m-3"/>
            </div>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Promover la inclusión y la diversidad en el trabajo según Mariel Cabañas</h2>
            <p class="md:mb-4 mb-6">Ya vimos porqué tanto la inclusión como la diversidad son importantes en el ámbito educativo, para que posteriormente, un ambiente seguro y de respeto se viva en el entorno laboral. En este aspecto, la visión de Mariel Cabañas es relevante.</p>
            <p class="md:mb-8 mb-10"><b>Mariel Cabañas es cofundadora y presidenta del Consejo Directivo de Abogadas MX</b> y su labor la ha llevado a promover la inclusión, sobre todo en el medio del Derecho,  donde las mujeres se sientan valoradas y respetadas, pues cree que el liderazgo que se complementa con la acción de hombres y mujeres es enriquecedor. Algunas estrategias que hay que considerar son las siguientes.</p>
            <VideoClipVue :link="'https://www.youtube.com/embed/ynV4L8HSNWg?si=WbHChD6gMy88knob'"/>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Capacitación, sensibilización y creación de espacios inclusivos</h2>
            <p class="md:mb-4 mb-6"><b>La capacitación y sensibilización del personal sobre temas de inclusión y diversidad pueden incluir talleres,</b> seminarios y cursos en línea que abordan la importancia de la inclusión, cómo identificar y combatir los sesgos inconscientes.</p>
            <p class="md:mb-4 mb-6"><b>Crear espacios inclusivos en el lugar de trabajo implica asegurarse de que las instalaciones y los recursos sean accesibles para todos:</b> desde la adaptación de oficinas y salas de reuniones para personas con discapacidad, así como la implementación de políticas que promuevan un ambiente de trabajo incluyente.</p>
            <p class="md:mb-8 mb-10">“<b>Para cualquier organización y para generar un cambio a una cultura con mayor inclusión se necesita integrar la perspectiva de género.</b> Necesitamos que nuestros compañeros hombres se sumen y agradezco a quienes ya lo han hecho, que se han sumado a la causa y que con su voz están generando resonancia, porque sin duda, unidos en el trabajo y en la lucha por la equidad y la igualdad de oportunidades, tendremos mayor peso”, asegura Mariel.</p>
            <img src="/images/inclusion-mujeres-diversidad-derecho-estrategia-ganadora.webp" alt="La inclusión de las mujeres y diversidad: una estrategia ganadora para las organizaciones" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Políticas de inclusión y diversidad en el Derecho</h2>
            <p class="md:mb-4 mb-6"><b>En el área legal se han implementado mecanismos y políticas de inclusión y diversidad,</b> como son departamentos, áreas y direcciones que se encargan por completo de estos temas, desde prevenir la discriminación dentro y fuera de la empresa o de los despachos de abogados.</p>
            <p class="md:mb-4 mb-6"><b>Las firmas legales también tienen una gran tarea a cumplir en este aspecto, ya que son agentes clave y de cambio para la sociedad.</b> Al exterior, tienen que reconocer los cambios constantes y la procuración de justicia a grupos vulnerables y excluidos, e incluso los profesionales del Derecho también pueden ser excluidos de oportunidades laborales, por lo cual, el cambio debe comenzar desde el Derecho mismo.</p>
            <p class="md:mb-4 mb-6">El reto es crear mecánicas y espacios equitativos para impulsar a las personas así como a las organizaciones y despachos de abogados.</p>
            <p class="md:mb-8 mb-10">En este sentido, <b>Mariel Cabañas, menciona que la creación de talleres de conocimiento técnico para las mujeres que ofrece Abogadas MX, asociación sin fines de lucro que ella dirige, están pensados para el desarrollo y empoderamiento de las mujeres,</b> ya que “las afiliadas pueden aprovechar nuestras iniciativas, en algunos de nuestros eventos pueden asistir al taller de liderazgo y desarrollo profesional. Lo importante es que nos conozcan”.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Conclusión</h2>
            <p class="md:mb-4 mb-6">Mariel Cabañas subraya: “Por mi experiencia en organizaciones que le dan mucha importancia al empoderamiento de la mujer, a través de la inclusión y diversidad, he podido corroborar lo que dicen muchas teorías: <b><router-link to="/blog/recursos/entrevistas-y-conferencias/liderazgo-femenino-empresarial-exitoso"  class="text-htwo">empoderar a las mujeres es una estrategia ganadora,</router-link> pues se genera una derrama virtuosa y un ejemplo multiplicador</b> que ayuda no sólo a otras mujeres también a su entorno económico y cultural, y a otras generaciones”. </p>
            <p class="md:mb-4 mb-6">También los programas de mentorías para quienes están estudiando Derecho son esenciales, así como la adaptación de las áreas de trabajo para las personas con discapacidad, pues de esta manera, se potencian sus habilidades y capacidades.</p>
            <p class="md:mb-8 mb-10">En resumen, <b>la inclusión y la diversidad son pilares esenciales para el desarrollo de una sociedad más equitativa y justa.</b> A través de programas de empoderamiento, mentoría y adaptaciones inclusivas en el entorno laboral del Derecho, podemos crear un futuro donde todas las personas, independientemente de sus diferencias, tengan la oportunidad de alcanzar su máximo potencial y ser agentes de cambio positivo en sus comunidades.</p>
            <p class="text-htwo text-left pb-20">Explora más: <a href="https://humanitas.edu.mx/licenciatura-en-derecho" target="_blank" class="text-htwo">Licenciatura en Derecho</a></p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="true" :linkAnt="'/ser-humanitas/comunidad/cuanto-cuesta-mensualidad-universidad-humanitas'" :linkSig="'/ser-humanitas/responsabilidad-social/responsabilidad-social-individual-tipos-ejemplos-inspiracion'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import VideoClipVue from '../../pagesComponents/videoClip.vue';
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import Contacto from '../../pagesComponents/Contacto.vue';
import EntrevistasVue from '../../pagesComponents/Entrevistas.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue'
import SigArt from '../../pagesComponents/SigArt.vue';



export default {
    components: { Comentarios, ConComentarios, Contacto, EntrevistasVue, LikesVue, ShareP, SigArt, VideoClipVue},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '0027-ART-RECURSOS-ENTREVISTAS-Y-CONFERENCIAS-200524',
            image: 'https://blog.humanitas.edu.mx/images/Emprende-aventura-liderazgo-visionario-albert-bosch-min.jpg',
            ruta: '/recursos/entrevistas-y-conferencias/inclusion-diversidad-en-derecho-por-mariel-cabanas',
            text: 'Descubre cómo la inclusión y la diversidad en los ámbitos del Derecho y educativo enriquecen a la sociedad, con la experiencia de Mariel Cabañas, presidenta de Abogadas MX.',
            memo: ' (No sirve el link ✧ʕ◔౪◔ʔ✧)'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Ruta relativa de la imagen
        const relativeImageUrl = 'https://blog.humanitas.edu.mx/images/Emprende-aventura-liderazgo-visionario-albert-bosch-min.jpg';

        // Convertir la ruta relativa en una URL absoluta
        const imageUrl = relativeImageUrl;

        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx/recursos/entrevistas-y-conferencias/emprende-aventura-de-tu-vida-liderazgo-visionario-albert-bosch';

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = 'El liderazgo visionario de Albert Bosch en tu vida universitaria';

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = 'Descubre cómo emprender tu vida universitaria con el liderazgo visionario de Albert Bosch y vive tu vida como una aventura.';

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = imageUrl;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>