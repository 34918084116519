<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">Estudia tu carrera en línea y prepárate para el <br> futuro</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-300">
          <img src="/images/A-Estudia-tu-carrera-en-linea.jpg" class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/academico" class=" text-hone">Académico</router-link> > <router-link to="/blog/academico/estudia-en-linea" class=" text-hone">Estudia en L&iacute;nea</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex list-disc pl-5 italic text-left text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">22 de febrero de 2024</li>  <li>Carolina Reyes Martínez</li></ul>
            </div>
            <p class=" md:mb-4 mb-6"><b>Extracto:</b> Si estás pensando en hacer tu licenciatura y quieres flexibilidad de tiempo, estudiar en línea es una opción para ti. Descubre los beneficios de esta modalidad.</p>
            <p class=" md:mb-4 mb-6">Estudiar una licenciatura no sólo es una vía hacia el éxito profesional, también implica adaptarse a los cambios tecnológicos y a toda la información que se genera día a día. Por ello, <b>además de la enseñanza escolarizada, también existe la educación a distancia,</b> lo que facilita el aprendizaje desde cualquier lugar y sólo necesitas una computadora o dispositivo móvil y una conexión a internet.</p>
            <p class=" md:mb-4 mb-6"><b>Después de los difíciles momentos en México y el mundo por la pandemia de Covid-19,</b> esta alternativa educativa demostró que se puede estudiar la universidad en línea dada su adaptabilidad a los cambios y a las nuevas tecnologías.</p>
            <p class=" md:mb-4 mb-6"><b>El crecimiento del e-learning ha sido muy significativo</b> en un mundo donde la actualización profesional es indispensable y es una solución a la demanda de los estudiantes para continuar con su formación académica e integrarse al mundo laboral.</p>
            <p class=" md:mb-8 mb-10"><b>Si estás pensando en hacer tu licenciatura en línea,</b> es normal que tengas preguntas sobre cómo funciona y qué beneficios tiene, en comparación con el modelo presencial.</p>
            <!-- Aqui va el componente de contacto, no se te olvide hacerlo manolo -->
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
              <Contacto class="m-3"/>
            </div>
            <img src="/images/Beneficios-al-estudiar-tu-carrera-en-línea.jpg" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">¿Qué beneficios obtendrás al estudiar tu carrera en línea?</h2>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Adquieres autogestión estudiantil</h3>
            <p class=" md:mb-8 mb-10"><b>Tú defines el ritmo de tu aprendizaje,</b> mides tus avances así como gestionas tus recursos materiales y de tiempo. <b>Aprender en línea se distingue por incentivar la autonomía.</b> La motivación también es muy importante ya que te dará compromiso con tu plan de estudios. Mediante las plataformas educativas, las aplicaciones, programas de estudio y demás recursos, puedes personalizar tu experiencia académica.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Tienes mayor flexibilidad y acceso a tus clases</h3>
            <p class=" md:mb-4 mb-6"><b>Cursar tu carrera en línea te brinda el equilibrio entre la escuela, la familia, el trabajo y tus hobbies.</b> Puedes revisar las clases en cualquier lugar, incluso ya no hay fronteras ni horarios.</p>
            <p class=" md:mb-8 mb-10">Esto favorece a quienes viven muy lejos de alguna institución o a las que trabajan, pues pueden estudiar en su tiempo libre y también no se tienen que desplazar a otro lugar.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-1 text-hone">Te hace consciente de las necesidades de los demás</h3>
            <p class=" md:mb-8 mb-10"><b>Las personas con discapacidad, ya sea visual, motriz o auditiva, se ven beneficiadas al estudiar en línea,</b> ya que no tienen que desplazarse a grandes distancias y su participación en clase es muy importante, ya que la comunidad estudiantil es diversa y requiere expresarse, por lo que es muy probable que empatices con esta situación y crees consciencia sobre la inclusión de las personas y cómo esto enriquece la vida universitaria.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-1 text-hone">Estudiar en línea es más ecológico</h3>
            <p class=" md:mb-4 mb-6"><b>Al hacer tu carrera en línea, reduces el uso de papel</b> pues tus materiales de estudio, trabajos y exámenes están online, así que disminuyes su impresión, pues los cartuchos de tinta para impresora acaban en la basura y tardarán cientos de años en degradarse.</p>
            <p class=" md:mb-8 mb-10"><b>La educación en línea es la aliada de la ecología,</b> de acuerdo al estudio de The Open University (Gran Bretaña) ya que implementar cursos en modalidad virtual tiene un 90% de menor gasto energético, pues genera un 87% menos de emisiones de carbono al evitar desplazarte en el transporte o en auto para ir a clases. También se evita construir instalaciones o renovar las ya existentes, reduciendo así el consumo de energía y de recursos.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Ahorrarás en tu educación</h3>
            <p class=" md:mb-8 mb-10">Invertir en tu licenciatura es muy importante y en el caso de la educación en línea, <b>al hacer tu carrera de manera virtual te ahorras gastos</b> pues no tienes que desplazarte a un lugar, o comprar comida, impresiones y sólo compras lo que necesitas.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Tendrás más seguridad en ti</h3>
            <p class=" md:mb-8 mb-10">Contrario a lo que podrías pensar, esta modalidad permite, sobre todo si eres más reservado, sentir más seguridad para participar más en clase o en los foros destinados para tus dudas y trabajos, puesto que en presencial puedes sentir timidez, pero en línea tienes la oportunidad de comunicarte directamente con los maestros y compañeros, aclarar tus dudas y expresar tus ideas libremente.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Adquieres habilidades digitales</h3>
            <p class=" md:mb-4 mb-6">La licenciatura en modalidad virtual se apoya en los recursos tecnológicos, por lo que conocerás cuáles son los derechos y los riesgos del mundo digital, comprenderás las fuentes de información y desarrollarás habilidades como una actitud activa, crítica y realista. Aprenderás a usar herramientas que complementen tus tareas como la edición de videos e imágenes.</p>
            <p class=" md:mb-8 mb-10">También aprendes a trabajar en equipo y colaboración en línea. Estas competencias te facilitan la transición de la escuela al mundo laboral, ya que hoy en día, son muy solicitadas por las empresas.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Aprendes en las mejores instituciones nacionales e internacionales</h3>
            <p class=" md:mb-8 mb-10">Cuando estudias en línea, ya no hay límites o fronteras, <a href="https://humanitas.edu.mx/virtual" target="_blank" class="text-htwo">ya que con esta modalidad puedes acceder a clases en universidades de prestigio</a> ofrece flexibilidad en el horario de entrevistas y que los candidatos se conecten desde cualquier lugar. En este 2024 seguirá en auge.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">¿Cómo saber que la universidad en línea tiene validez oficial?</h3>
            <p class=" md:mb-4 mb-6">Es muy importante que <b>revises muy bien que la institución en donde quieres estudiar en línea esté reconocida por la Secretaría de Educación Pública,</b> y las Autoridades Educativas Estatales, y para ello, la Dirección General de Acreditación, Incorporación y Revalidación (DGAIR) otorga o retira los Reconocimientos de Validez Oficial de Estudios del tipo Superior (RVOES) que puedes consultar. La modalidad en línea tiene la misma validez que la escolarizada o presencial.</p> 
            <p class=" md:mb-8 mb-10">De esta manera, tendrás la información correcta sobre la universidad y también, en esta modalidad virtual <a href="https://humanitas.edu.mx/licenciaturas" target="_blank" class="text-htwo">puedes estudiar diversas carreras.</a></p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Conclusión</h2>
            <p class=" md:mb-4 mb-6"><b>Las claves para cursar exitosamente tu carrera en línea son la constancia y la planificación.</b> Si elaboras tu plan de aprendizaje tomando en cuenta tu ritmo de estudio, podrás avanzar sin tener la estructura fija del sistema escolarizado.</p>
            <p class=" md:mb-4 mb-6">Evalúa que en algunos momentos podrías sentir soledad al no acudir a la escuela, por eso es importante que la universidad que escojas tenga los canales, los recursos de calidad y las plataformas adecuadas para el acompañamiento de tus profesores y la interacción con tus compañeros en una comunidad activa.</p>
            <p class=" md:mb-8 mb-10">Por último, <b>la información que te hemos compartido te será de gran ayuda para inscribirte en esta modalidad,</b> ¡inicia tu vida universitaria ya!</p>
            <p class="text-htwo text-left mb-6">Explora más: <a href="https://humanitas.edu.mx/" target="_blank" class="text-htwo">Universidad Humanitas</a></p>
            <p class="text-htwo text-left pb-12">Te puede interesar: <router-link to="/blog/academico/licenciatura/beneficios-carreras-universitarias-cortas"  class="text-htwo">Por qué una carrera corta es tu mejor aliada descúbrelo</router-link></p>
            <p class="text-htwo text-left font-bold mb-2">Fuente:</p>
            <p class="text-left mb-20 text-base"><a href="https://humanitas.edu.mx/virtual" target="_blank" class="text-black">Educación en Línea</a></p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="true" :linkAnt="'/academico/educacion-continua/como-un-diplomado-transforma-tu-carrera'" :linkSig="'/academico/licenciatura/mejores-universidades-derecho-mexico-guia'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import Contacto from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue'
import SigArt from '../../pagesComponents/SigArt.vue';

export default {
    components: { Comentarios, ConComentarios, Contacto, LikesVue, ShareP, SigArt},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '09-ART-ACADEMICO-ESTUDIA-EN-LINEA-220224',
            ruta: '/academico/estudia-en-linea/estudia-tu-carrera-en-linea-beneficios',
            title: 'Amplía tus horizontes y estudia tu carrera en línea en México con flexibilidad y calidad.',
            image: 'https://blog.humanitas.edu.mx/images/Estudia-tu-carrera-en-linea.jpg',
            text: 'La educación universitaria en línea te ofrece carreras flexibles y accesibles. Explora los beneficios de esta modalidad.'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx'+this.ruta;

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>