import AcademicoContinua from "../pages/Blog/Academico/Academico-Continua.vue";
import AcademicoDoctorado from "../pages/Blog/Academico/Academico-Doctorado.vue";
import AcademicoEstudiaLin from "../pages/Blog/Academico/Academico-EstudiaLin.vue";
import AcademicoLicenciatura from "../pages/Blog/Academico/Academico-Licenciatura.vue";
import AcademicoMaestria from "../pages/Blog/Academico/Academico-Maestria.vue";
import doctoradoRoutes from "./Academico/doctoradoRoutes.ts";
import educaConRoutes from "./Academico/educaConRoutes.ts";
import estudiaLinRoutes from "./Academico/estudiaLinRoutes.ts"
import licenciaturaRoutes from "./Academico/licenciaturaRoutes.ts";
import maestriaRoutes from "./Academico/maestriaRoutes.ts"


export default [
    
    {
        path: '/blog/academico/educacion-continua',
        name: 'Académico: Educación Continua',
        component: AcademicoContinua,
        meta: {
            requireAuth: false
        },
    },
    ...educaConRoutes,
    {
        path: '/blog/academico/doctorado',
        name: 'Académico: Doctorado',
        component: AcademicoDoctorado,
        meta: {
            requireAuth: false
        },
    },
    ...doctoradoRoutes,
    {
        path: '/blog/academico/estudia-en-linea',
        name: 'Académico: Estudia en Línea',
        component: AcademicoEstudiaLin,
        meta: {
            requireAuth: false
        },
    },
    ...estudiaLinRoutes,
    {
        path: '/blog/academico/licenciatura',
        name: 'Académico: Licenciatura',
        component: AcademicoLicenciatura,
        meta: {
            requireAuth: false
        },
    },
    ...licenciaturaRoutes,
    {
        path: '/blog/academico/maestria',
        name: 'Académico: Maestría',
        component: AcademicoMaestria,
        meta: {
            requireAuth: false
        },
    },
    ...maestriaRoutes
]