<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">Todo sobre el doctorado: gu&iacute;a de lo que<br class="md:block hidden"> necesitas saber</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-200">
          <img src="/images/Todo-sobre-el-doctorado-guia.jpg" class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/academico" class=" text-hone">Académico</router-link> > <router-link to="/blog/academico/doctorado" class=" text-hone">Doctorado</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex list-disc pl-5 italic text-left text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">14 de febrero de 2024</li>  <li>Carolina Reyes Martínez</li></ul>
            </div>
            <p class=" md:mb-4 mb-6"><b>Extracto:</b> <b>Te diremos todo lo que necesitas saber sobre el doctorado:</b> si tu <b>perfil</b> es el indicado, qué compromiso adquieres al estudiarlo, <b>qué requisitos necesitas y qué competencias vas a adquirir que te servirán en tu futuro laboral. </b>¡Descúbrelo!</p>
            <p class=" md:mb-8 mb-10"><b>El doctorado es el grado máximo de estudios</b> al que como estudiante puedes aspirar. Solo el 1.2% de los estudiantes mexicanos tienen un doctorado, según <b>una muestra nacional.</b> Sin embargo, cada vez más estudiantes están interesados en estudiar un doctorado. Aún queda mucho por hacer, ya que en países como Brasil o Argentina el porcentaje es más alto.</p>

            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">¿Qué factores influyen para estudiar un doctorado?</h2>
            <ul class="list-decimal pl-6 md:mb-8 mb-10">
                <li>El interés y la pasión por la investigación. </li>
                <li>Que el estudiante encuentre programas de calidad en distintas universidades públicas o privadas.</li>
                <li>La disponibilidad de becas y apoyos económicos para que pueda realizar un doctorado.</li>
                <li>Infraestructura educativa adecuada.</li>
                <li>Acompañamiento de profesores calificados o asesores, lo que lo motivará a seguir con su formación académica.</li>
                <li><b>De acuerdo con la Organización para la Cooperación y el Desarrollo Económico (OCDE) , en México, el doctorado ofrece una ventaja laboral.</b> La tasa de empleo de personas con doctorado es un 8% más alta que la de personas con maestría. Además, supera el promedio de la OCDE (5%) en la población de 25 a 64 años.</li>
            </ul>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">¿Por qué quieres hacer un doctorado y qué debes considerar?</h2>
            <p class="md:mb-4 mb-6">Cada estudiante o candidato al doctorado tiene sus razones: si quieres <b>investigar,</b> enseñar o avanzar en tu trabajo, necesitas un doctorado. También puedes <b>trabajar en empresas privadas que necesiten tus habilidades.</b> O tal vez simplemente quieres superarte a ti mismo y <b>lograr este desafío personal que te has propuesto.</b></p>
            <p class="md:mb-8 mb-10"><b>Debes tener una idea clara de tu futuro</b> antes de comenzar el doctorado. Es importante <b>pensar en tu vida personal y comprometerte con tus estudios.</b> Esto te motivará durante todo el proceso. Recuerda que invertirás dos, tres, cuatro o más años en esta etapa de tu vida.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Cuál es el perfil del estudiante ideal de doctorado</h2>
            <p class="md:mb-4 mb-6">Estudiar un doctorado es muy diferente a cuando estás en la licenciatura. <b>En este nivel tienes que investigar un tema que eliges y que te apasiona.</b> Una cualidad muy importante es tener compromiso con la investigación, además estarás bajo la supervisión de uno o dos asesores, así que la disciplina es fundamental.</p>
            <p class="md:mb-4 mb-6"><b>Este compromiso se traduce en una gran curiosidad, una excelente capacidad para el pensamiento crítico y ser muy metódico.</b> La perseverancia es importante para el doctorado, ya que hay muchos desafíos que requieren dedicación y enfoque constante.</p>
            <p class="md:mb-4 mb-6">Además del conocimiento, <b>el estudiante de doctorado debe tener habilidades interpersonales, de autogestión y de comunicación. La resiliencia y la gestión del tiempo también son esenciales,</b> ya que el proceso de investigación puede ser largo y exigente.</p>
            <p class="md:mb-8 mb-10">Si bien en el doctorado se trabaja de manera autónoma, <b>la colaboración y el intercambio de ideas con otros estudiantes son muy valiosos.</b> Navegar entre estas dos formas de trabajo es una habilidad del perfil de estudiante de doctorado exitoso.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Estrategias que te ayudarán si eres estudiante de doctorado?</h2>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Técnicas de estudio y gestión del tiempo</h3>
            <p class="md:mb-4 mb-6">Priorizar las tareas te facilitará establecer objetivos realistas, para ello puedes hacer un horario de estudio puede marcar la diferencia en el doctorado.</p>
            <p class="md:mb-8 mb-10">Concéntrate en una sola tarea. Si es muy grande, como escribir un artículo, divídelo en dos partes: una para planificar y otra para escribir las secciones.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Construye una red de apoyo sólida</h3>
            <p class="md:mb-8 mb-10">Esto es fundamental. Un doctorado puede durar varios años. Tus profesores, asesor o asesores de tesis, compañeros, familia y amigos son importantes para compartir ideas, recibir comentarios académicos y recibir apoyo emocional.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Mantén el bienestar y el equilibrio personal</h3>
            <p class="md:mb-8 mb-10">Es posible equilibrar la vida personal y el doctorado. Para lograrlo, es esencial cuidarse, descansar, comer bien, relajarse y pasar tiempo con la familia y amigos. Estas acciones contribuyen al éxito académico.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">¿Es importante la edad para hacer un doctorado?</h2>
            <p class="md:mb-4 mb-6">En realidad, la edad no es un obstáculo para realizar un doctorado. Según la OCDE, en México <b>la edad promedio para comenzar un doctorado es de 34 años,</b> más alta que la media de 29 años. Si necesitas un perfil académico más elevado, aún estás a tiempo.</p>
            <p class="md:mb-8 mb-10">Sí ya decidiste hacer un doctorado, <b>sobre todo si quieres ser investigador, empieza cuánto antes.</b> Enfócate en tus objetivos y sé muy metódico para cumplir con tu tesis y con tus artículos para su publicación. Esto enriquecerá tu currículum, sobre todo, si ya estás pensando en un postdoctorado.</p>
            <img src="/images/Perfil-estudiante-ideal-doctorado.jpg" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">¿Cómo elegir la universidad para estudiar un doctorado?</h2>
            <ul class="list-decimal pl-6 md:mb-8 mb-10">
                <li><b>Investiga muy bien a la institución,</b> su prestigio y reputación. <a href="https://humanitas.edu.mx/doctorados" target="_blank" class="text-htwo">Elige el área y el programa educativo que te llame la atención,</a> que sea acorde a lo que buscas, no te dejes llevar sólo por el nombre o alguna materia, checa muy bien la información. </li>
                <li><b>Revisa la trayectoria de los profesores,</b> ya que tu supervisor estará entre ellos: su apoyo y asesoría será indispensable para el éxito de tu tesis.</li>
                <li><b>El plan de estudios debe estar muy bien estructurado.</b> Analiza las asignaturas, los cursos, tanto obligatorios como opcionales, así como si existen programas de investigación conjuntos con otras universidades, congresos, seminarios. Esto te ayudará también a crear una red de contactos que será crucial en el futuro.</li>
                <li><b>Debes tener muy claro tus recursos.</b> Investiga qué universidades tienen becas o financiamiento para realizar el doctorado. Una beca te permite estudiar a tiempo completo, ya que en este nivel es más difícil tener un empleo y estudiar a la vez.</li>
                <li><b>Averigua qué recursos tiene la institución:</b> si está bien equipada con biblioteca, laboratorios de vanguardia, información en línea como bases de datos o revistas especializadas.</li>
                <li><b>Presencial o a distancia.</b> Existen programas de doctorado en los cuales acudes a la institución o bien, puedes estudiar a distancia, de esta manera puedes equilibrar tus actividades.</li>
                <li>Considera si <b>estudiarás en México o en el extranjero.</b> Si es la segunda opción, <b>haz un presupuesto y analiza si estás preparado para mudarte de país.</b> Incluso con beca, sí es necesario preveer. <b>En tu estancia en el extranjero descubrirás otras formas de investigar.</b> Puedes considerar esta opción, ya que nuestro país tiene un estudiante internacional por cada 100 que se quedan en México.</li>
            </ul>
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
                <ContactoVue class="m-3"/>
            </div>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">¿Para qué sirve hacer un doctorado? </h2>
            <p class="md:mb-4 mb-6">El doctorado te capacita para ser un experto en el tema del campo académico que elegiste. Te ayuda en tu trayectoria de investigador en una universidad, en el ámbito científico, si tu especialidad así lo requiere, o en la iniciativa privada.</p>
            <p class="md:mb-8 mb-10">Aprendes muchas competencias que se les llama transversales, esto quiere decir que éstas se pueden aplicar a otros sectores, a otros tipos de trabajo.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Las competencias que vas a adquirir al hacer un doctorado</h2>
            <ul class="list-decimal pl-6 md:mb-4 mb-6">
                <li><b>Pensamiento crítico-analítico.</b> Te desafía a analizar la información, a evaluar diferentes perspectivas y encontrar soluciones innovadoras a problemas complejos. También te da la capacidad de leer un artículo con ojos críticos, plantearse hipótesis si son correctas o no, basarse en datos de fuentes confiables. En el ámbito personal te ayudará a tomar decisiones.</li>
                <li><b>La capacidad de resolver problemas.</b> Durante el doctorado te encontrarás con obstáculos que no habías imaginado, pero estudiar te ayudará a resolver y a no entrar en pánico; a buscar soluciones y alternativas.</li>
                <li><b>Comunicación efectiva.</b> La practicarás en el doctorado sí o sí, ya que la divulgación de tus artículos y tesis, la haces con otras personas e instituciones. Al cultivar tus habilidades pedagógicas a través de presentaciones, charlas, publicaciones y enseñanza, aprenderás a comunicar tus ideas de manera clara y persuasiva para diferentes audiencias al terminar tus estudios. </li>
                <li><b>Autonomía y autogestión.</b> Desarrollarás tus proyectos de investigación de forma organizada y con  toma asertiva de decisiones.</li>
                <li><b>Metodología.</b> Al ser un experto en ella, la podrás usar en otros contextos y será muy positivo. Algunas técnicas te pueden ayudar a laborar diversos campos, como por ejemplo, en el manejo de datos estadísticos o cómo se estructura un texto científico.</li>
                <li><b>Conocimiento de la materia.</b> Al haber estudiado tu doctorado 4 años o más, serás un experto en tu área y tema de estudio.</li>
                <li><b>Creatividad y flexibilidad.</b> Podrás resolver problemas, conectar ideas y encontrar resultados innovadores. Desarrollarás la flexibilidad que es la capacidad de responder rápido a lo que ocurre. </li>
                <li><b>Trabajo en equipo.</b> Conocerás a personas de tu mismo país u otras partes del mundo, o bien, con tu equipo de trabajo, depende cómo sea tu doctorado. Hay doctorados en los que hay que estar solo con tus datos e investigación, pero sí hay grupos donde hay mucha colaboración que te ayudará a tener la capacidad de trabajar con las personas y liderazgo.</li>
                <li><b>Networking.</b> Asistir a los congresos, seminarios o ponencias, es una muy buena manera de conocer gente que está investigando en tu área de especialización y puedes hacer colaboraciones. Al final te puede abrir puertas ya que muchas oportunidades se dan de esta manera. </li>
                <li><b>Idiomas.</b> Si logras comunicarte mejor en otras lenguas, como el inglés, te ayudará tanto en la academia como en el ámbito laboral.</li>
            </ul>
            <p class="md:mb-8 mb-10">Estas habilidades de las que hemos hablado, se pueden trasladar a otros proyectos.</p>
            <img src="/images/Competencias-que-adquieres-con-un-doctorado.jpg" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Requisitos para hacer el doctorado</h2>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Requisitos administrativos</h3>
            <ul class="list-decimal pl-6 md:mb-8 mb-10">
                <li>Acta de nacimiento.</li>
                <li>Clave Única de Registro de Población (CURP).</li>
                <li>Credencial</li>
                <li>Los trámites de inscripción que cada universidad tenga</li>
            </ul>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Requisitos académicos</h3>
            <ul class="list-decimal pl-6 md:mb-8 mb-10">
                <li>Título de licenciatura o grado de Maestría (en algunos lugares aceptan el título de licenciatura y cédula solamente).</li>
                <li>Cédula de Maestría.</li>
                <li>Entrevista con algún director académico.</li>
                <li>Promedio mínimo de 8 o de 9.</li>
                <li>Certificación de idiomas.</li>
                <li>Certificado parcial de estudios expedido por la institución educativa de procedencia con reconocimiento de validez oficial.</li>
                <li>Algunas universidades tienen examen de ingreso.</li>
            </ul>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Requisitos laborales y de perfil</h3>
            <ul class="list-decimal pl-6 md:mb-8 mb-10">
                <li>Presentar el currículum vitae y acreditar experiencia laboral en el sector de interés del doctorado.</li>
                <li>Hacer una carta de motivos de por qué se quiere hacer un doctorado. Si se tienen investigaciones previas. En algunas universidades como la UNAM, hay reuniones con los aspirantes donde se les entrevista.</li>
                <li>Presentar el proyecto de investigación y describir qué aportará al área de estudio.</li>
            </ul>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Alumnos con estudios en el extranjero</h3>
            <ul class="list-decimal pl-6 md:mb-8 mb-10">
                <li>Entregar revalidación de estudios realizados fuera del país, emitido por la Secretaría de Educación Pública.</li>
                <li>Tener certificado de nacimiento y estudios, legalizados por el país de origen.</li>
                <li>Acreditación de calidad migratoria para estudiar si viven en la República Mexicana. (FM2)</li>
            </ul>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Conclusión. Lo que nadie te dice antes de estudiar un doctorado</h2>
            <p class="md:mb-4 mb-6">Vas a leer mucho todos los días y tal vez faltes a algunos eventos sociales, pero estructurarás mejor tus tiempos de estudio, comida y descanso.</p>
            <p class="md:mb-8 mb-10">Buscarás espacios cómodos para estudiar y con excelente conexión a internet. La biblioteca se convertirá en tu santuario, eso sí, necesitarás el apoyo de tus maestros, familiares y amigos, pero ¿sabes qué? Tu esfuerzo rendirá frutos, así que adelante con tu doctorado</p>
            <p class=" text-htwo text-left mb-6">Explora más: <a href="https://humanitas.edu.mx/" target="_blank" class="text-htwo">Universidad Humanitas</a></p>
            <p class="text-htwo text-left pb-12">Te puede interesar: <router-link to="/blog/aprende/tendencias/tendencias-en-psicologia-2024-tecnologia-salud-mental"  class="text-htwo">Tendencias en Psicología para 2024: Tecnología en la salud mental</router-link></p>
            <p class="text-htwo text-left font-bold mb-2">Fuente:</p>
            <p class="text-left mb-20 text-base"><a href="https://humanitas.edu.mx/doctorados" target="_blank" class="text-black">Programas de Doctorado</a></p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArtVue :ant="true" :linkAnt="'/academico/licenciatura/beneficios-carreras-universitarias-cortas'" :linkSig="'/aprende/tendencias/tendencias-actuales-del-derecho-2024'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import ContactoVue from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue'
import SigArtVue from '../../pagesComponents/SigArt.vue';


export default {
    components: { Comentarios, ConComentarios, ContactoVue, LikesVue, ShareP, SigArtVue},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '06-ART-ACADEMICO-DOCTORADO-140224',
            ruta: '/academico/doctorado/guia-todo-sobre-doctorado-perfil-y-competencias',
            title: 'Si quieres hacer el doctorado, esta guía te ayudará, te decimos todo lo que necesitas.',
            image: 'https://blog.humanitas.edu.mx/images/Todo-sobre-el-doctorado-guia.jpg',
            text: 'Descubre todo sobre el doctorado: las cualidades del estudiante ideal, estrategias para destacar y las competencias que adquirirás para tu vida.'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx'+this.ruta;

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>