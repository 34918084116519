<template>
    <div class="flex items-center w-full h-full">
        <iframe :src="link" class="w-full lg:h-[580px] md:h-[520px] h-[260px] md:mb-8 mb-10" title="YouTube video player"
        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen>
        </iframe>
    </div>
</template>

<script lang="js">
export default {
    props: {
      link: String,
    },
}
</script>

<style>

</style>