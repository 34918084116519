<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">Conoce las metodologías del aprendizaje <br class="md:block hidden"> en línea </h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-300">
          <img src="/images/metodologias-aprendizaje-linea-.webp" alt="Las metodologías del aprendizaje en línea revolucionan a la educación" class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/academico" class=" text-hone">Académico</router-link> > <router-link to="/blog/academico/estudia-en-linea" class=" text-hone">Estudia en L&iacute;nea</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex list-disc pl-5 italic text-left text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">07 de mayo de 2024</li>  <li>Carolina Reyes Martínez</li></ul>
            </div>
            <p class=" md:mb-8 mb-10"><b>Extracto:</b> El aprendizaje en línea ha tenido una gran evolución y ofrece oportunidades ilimitadas de conocimiento desde cualquier lugar. Por ello, es importante conocer las metodologías que hacen efectivo el aprendizaje digital.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">¿Qué es la educación virtual y cómo impacta en el aprendizaje?</h2>
            <p class=" md:mb-4 mb-6"><b>La educación se ha expandido y consolidado en todos los niveles, gracias a la integración de las tecnologías digitales</b> en la vida diaria y en la formación, la flexibilidad de acceso y el aprendizaje activo. La transición de un salón de clases a estudiar en casa o en otro sitio, tiene como antecedentes el desarrollo de cursos por correspondencia en algunos países europeos desde hace unos 150 años.</p>
            <p class=" md:mb-4 mb-6">En Estados Unidos, en el año 1873, y en México, en los años 20 y 30, cuando la Secretaría de Educación Pública instauró la instrucción basada en sistema abierto a la que se le llamó Misiones culturales, pues era necesario capacitar a los profesores que estaban en zonas muy alejadas de las ciudades.</p>
            <p class=" md:mb-4 mb-6">Ya en <b>la década de los 80, la comunicación satelital evolucionó, lo que permitió que la educación a distancia creciera</b> y hubiera más interacción con los profesores, mediante llamadas telefónicas, videoconferencias, y los materiales educativos evolucionaron en audios y videocassettes.</p>
            <p class=" md:mb-4 mb-6"><b>Con la expansión de la World Wide Web y el internet en los 90, el e-learning apareció</b> y se le denominó como el aprendizaje formal que se realiza en un lugar alejado de un instructor o maestro. La digitalización ha permitido que no haya barreras geográficas para adquirir el conocimiento académico como es la licenciatura y la maestría.</p>
            <p class=" md:mb-8 mb-10"><b>El Internet ha fomentado la ampliación de las metodologías del aprendizaje</b> y ha hecho posible ofrecer una enseñanza enfocada en el estudiante y ha evolucionado en la colaboración en tiempo real.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Metodologías principales del aprendizaje en línea</h2>
            <p class=" md:mb-4 mb-6"><a href="https://virtual.humanitas.edu.mx/?_gl=1*o3t3ld*_gcl_au*MTM0NTE0MjQyMy4xNzE0NDE5MjY3" target="_blank" class="text-htwo">El aprendizaje virtual</a> es una modalidad educativa que integra el uso de las tecnologías de la información y comunicación para tener una mayor flexibilidad de tiempo y espacio.</p>
            <p class=" md:mb-4 mb-6"><b>Una de sus características es la personalización,</b> ya que cada estudiante marca la pauta de estudio dependiendo de sus intereses, también es importante destacar que <b>es más accesible económicamente</b> y las plataformas cuentan con todo lo necesario para realizar evaluaciones en línea y recursos para complementar sus estudios como materiales y libros en línea.</p>
            <p class=" md:mb-8 mb-10">Las metodologías se convierten en aliadas de las instituciones educativas y de los profesores para que los alumnos tengan un desarrollo académico y profesional exitoso.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-1 text-hone">Aprendizaje autodirigido</h3>
            <p class=" md:mb-8 mb-10">Esta metodología <b>permite a los estudiantes controlar su aprendizaje,</b> eligiendo qué, cómo y cuándo aprender. Es ideal para quienes buscan flexibilidad pero sí requiere una gran disciplina y motivación.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-1 text-hone">Método sincrónico</h3>
            <p class=" md:mb-8 mb-10"><b>Permite que varias personas intercambien conocimientos, ideas y experiencias al mismo tiempo</b> mediante videoconferencias y presentaciones transmitidas en vivo.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-1 text-hone">Método asincrónico</h3>
            <p class=" md:mb-8 mb-10">Es una de las metodologías autodidactas que <b>permite que el alumno aprenda y descubra conceptos sin que haya un maestro</b> en ese instante por medio de clases grabadas. El estudiante puede ingresar en cualquier momento a los cursos.</p>
            <img src="/images/metodologias-aprendizaje-sincronico-linea.webp" alt="Las metodologías de aprendizaje en línea como B-learning y otras fomentan habilidades técnicas" class="md:mb-8 mb-10">
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-1 text-hone">Método B-learning</h3>
            <p class=" md:mb-4 mb-6">O también conocido como Blended learning ya que <b>es una combinación del método asincrónico y sincrónico,</b> ya que conforma a los medios digitales y presenciales al mismo tiempo.</p>
            <p class=" md:mb-8 mb-10">Esta metodología semipresencial <b>le ayuda al alumno a aprender a su ritmo y tener interacción en tiempo real</b> con sus compañeros y profesores.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-1 text-hone">Método G-learning </h3>
            <p class=" md:mb-8 mb-10">Es un tipo de aprendizaje que <b>recurre a los juegos (Game learning) para obtener nuevas habilidades</b> y destrezas en distintas áreas del conocimiento. Además se emplean escenarios reales para fomentar la adquisición de habilidades prácticas.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-1 text-hone">Método M-learning</h3>
            <p class=" md:mb-8 mb-10">El Mobile learning o aprendizaje electrónico móvil <b>es la metodología que aprovecha los contenidos de internet a través de dispositivos</b> como teléfonos celulares y tabletas en donde pueden descargarse aplicaciones diseñadas para el aprendizaje. Y contenidos como podcasts, videos, juegos, rompecabezas, imágenes y más.</p>
            <img src="/images/metodologia-aprendizaje-m-learning.webp" alt="Las metodologías de aprendizaje en línea son diversas y se actualizan constantemente" class="md:mb-8 mb-10">
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-1 text-hone">Aprendizaje basado en proyectos</h3>
            <p class=" md:mb-8 mb-10"><b>Los estudiantes aplican lo que aprenden a proyectos reales,</b> lo que facilita el aprendizaje práctico y mejora la retención de conocimientos. Esta metodología fomenta habilidades como la resolución de problemas y el pensamiento crítico.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-1 text-hone">Aprendizaje colaborativo</h3>
            <p class=" md:mb-8 mb-10">El aprendizaje en línea también puede ser social. <b>Esta metodología implica trabajar en grupo para resolver problemas o completar proyectos,</b> lo cual es vital para desarrollar habilidades de comunicación y trabajo en equipo.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-1 text-hone">El microlearning o microaprendizaje</h3>
            <p class=" md:mb-8 mb-10">Es un método donde <b>los contenidos se dividen en cápsulas de información</b> para que los conceptos que pueden ser muy difíciles, sean estudiados poco a poco, ayudando a los estudiantes a retener el conocimiento por más tiempo.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-1 text-hone">Aula invertida o Flipped school</h3>
            <p class=" md:mb-8 mb-10">También conocida como el aula al revés es un modelo de enseñanza en el cual invierte la forma de enseñar, es decir, <b>los alumnos estudian en casa pero en la clase en línea es cuando debaten,</b> resuelven sus dudas y participan con sus compañeros.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-1 text-hone">Aprendizaje condicional o adaptativo</h3>
            <p class=" md:mb-8 mb-10"><b>Esta metodología de aprendizaje en línea</b> se adapta a cada estudiante: <b>se ajusta el contenido del curso a sus necesidades y conocimientos previos.</b> Utiliza algoritmos, redes neuronales y aprendizaje automático para identificar lo que el estudiante ya sabe y lo que necesita aprender. De esa manera, se crea un plan de estudio que realmente funcione.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-1 text-hone">Educación basada en competencias (EBC)</h3>
            <p class=" md:mb-8 mb-10">Esta estrategia <b>se centra en capacitar a los estudiantes de acuerdo con las demandas específicas de sus roles laborales,</b> teniendo en cuenta también sus competencias con el fin de ampliar sus conocimientos y habilidades para mejorar su desempeño profesional.</p>
            <!-- Aqui va el componente de contacto, no se te olvide hacerlo manolo -->
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
              <Contacto class="m-3"/>
            </div>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Ventajas de las metodologías en línea</h2>
            <p class=" md:mb-8 mb-10"><b>El aprendizaje en línea ofrece una flexibilidad sin precedentes, permitiendo a los estudiantes manejar sus horarios y ritmos de aprendizaje.</b> La personalización del contenido y las metodologías adaptativas ayudan a atender las necesidades individuales de cada estudiante, mientras que la escalabilidad permite a instituciones educativas ofrecer cursos sin incrementar demasiado los recursos.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Desafíos del aprendizaje en línea</h2>
            <p class=" md:mb-4 mb-6">A pesar de sus ventajas, el aprendizaje en línea presenta desafíos como puede ser la limitación en la interacción cara a cara, que podría afectar la comunicación y el engagement de los estudiantes.</p>
            <p class=" md:mb-8 mb-10">En algunos casos, los problemas tecnológicos también pueden representar barreras significativas, además de los retos de autodisciplina y gestión del tiempo que enfrentan algunos estudiantes.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Conclusiones</h2>
            <p class=" md:mb-4 mb-6"><b>Las metodologías de aprendizaje en línea son diversas y cada día se actualizan más, y representan una oportunidad de educación abierta, dinámica, flexible y efectiva</b> para quienes quieren obtener los beneficios de  un acceso constante a la educación sin las limitaciones geográficas o temporales.</p>
            <p class=" md:mb-8 mb-10"><b>Estas metodologías</b> no sólo permiten una personalización que se adapta a distintos estilos y ritmos de aprendizaje, sino que <b>también abren puertas a una variedad de campos y disciplinas,</b> facilitando el desarrollo continuo de habilidades profesionales.</p>
            <p class="text-htwo text-left mb-6">Explora más: <a href="https://humanitas.edu.mx/" target="_blank" class="text-htwo">Universidad Humanitas</a></p>
            <p class="text-htwo text-left mb-20">Te puede interesar: <router-link to="/blog/academico/estudia-en-linea/estudia-tu-carrera-en-linea-beneficios"  class="text-htwo">Amplía tus horizontes y estudia tu carrera en línea en México con flexibilidad y calidad.</router-link></p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="true" :linkAnt="'/academico/licenciatura/universidades-sin-examen-admision'" :linkSig="'/ser-humanitas/responsabilidad-social/acciones-socialmente-responsables'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import Contacto from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue'
import SigArt from '../../pagesComponents/SigArt.vue';

export default {
    components: { Comentarios, ConComentarios, Contacto, LikesVue, ShareP, SigArt},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '022-ART-ACADEMICO-ESTUDIA-EN-LINEA-070524',
            ruta: '/academico/estudia-en-linea/metodologias-aprendizaje-linea',
            title: 'Conoce las metodologías del aprendizaje en línea.',
            image: 'https://blog.humanitas.edu.mx/images/Estudia-tu-carrera-en-linea.jpg',
            text: 'Explora las principales metodologías de aprendizaje en línea y descubre cómo pueden transformar la experiencia educativa.'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx'+this.ruta;

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>