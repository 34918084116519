<template>
  <section class="flex flex-col lg:px-0 px-4 items-center justify-center w-full pb-12 z-30 bg-white">
    <div class="flex flex-col items-center w-full justify-center">
        <div class="flex flex-col justify-start w-full max-w-4xl lg:max-w-[1080px]">
            <div class="flex flex-col justify-center my-12 w-full">
                <h2 class="text-hone font-bold text-[24px] md:text-[32px] lg:text-[34px] text-left mb-6 font-serif" >COMENTARIOS</h2>
                <div class="flex flex-col justify-center">
                  <div v-if="!showAllComments">
                    <div v-for="(comment, index) in visibleComments" :key="index" class="flex items-start mb-12">
                      <div class="lg:mr-4">
                          <img v-if="index % 2 === 0" src="../imagesComp/coment-red.png" alt="Icono Rojo" class="lg:w-28 w-20">
                          <img v-else src="../imagesComp/coment-gold.png" alt="Icono Dorado" class="lg:w-28 w-20">
                      </div>
                      <div class="text-left justify-start w-full lg:ml-16 ml-8" style="font-family: 'Helvetica Neue Regular', sans-serif;">
                          <p class="font-bold md:text-[20px] text-[18px] mb-3">{{ comment.Autor || 'Autor desconocido'}}</p>
                          <p class=" md:text-[18px] mb-2">{{ comment.Fecha || 'Fecha desconocida'}}</p>
                          <p class=" md:text-[18px] tracking-tighter text-justify">{{ comment.Contenido || 'Contenido no disponible'}}</p>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div v-for="(comment, index) in safeComments" :key="index" class="flex items-start mb-12">
                      <div class="lg:mr-4">
                          <img v-if="index % 2 === 0" src="../imagesComp/coment-red.png" alt="Icono Rojo" class="w-28">
                          <img v-else src="../imagesComp/coment-gold.png" alt="Icono Dorado" class="w-28">
                      </div>
                      <div class="text-left justify-start w-full lg:ml-16 ml-8" style="font-family: 'Helvetica Neue Regular', sans-serif;">
                          <p class="font-bold md:text-[20px] text-[18px] mb-3">{{ comment.Autor || 'Autor desconocido'}}</p>
                          <p class=" md:text-[18px] mb-2">{{ comment.Fecha || 'Fecha desconocida'}}</p>
                          <p class=" md:text-[18px] tracking-tighter text-justify">{{ comment.Contenido || 'Contenido no disponible'}}</p>
                      </div>
                    </div>
                  </div>
                  <a @click="toggleComments" v-if="comments.length>2" class="text-htwo select-none text-left cursor-pointer md:text-[18px] text-[16px]" style="font-family: 'Helvetica Neue Regular', sans-serif;">
                    {{ showAllComments ? 'Ocultar comentarios' : 'Ver más comentarios' }}
                    <span v-if="showAllComments"><i class="fas fa-arrow-up"></i></span>
                    <span v-else><i class="fas fa-arrow-down"></i></span>
                  </a>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script lang="js">
import axios from 'axios';
import { ConsultasBlog } from '@/main';
/**
 * @typedef {Object} Comment
 * @property {string} Autor - El nombre del autor del comentario.
 * @property {string} Fecha - La fecha del comentario en formato ISO.
 * @property {string} Contenido - El contenido del comentario.
 */
export default {
  props: {
      article: String
    },
    
  data() {
    return {
      comments: [], // @type {Comment[]}
      comments2: [
                    {
                        "ID": 1,
                        "Autor": "Blogman",
                        "Fecha": "5 de Mayo del 2024, 15:30",
                        "Contenido": "¡Sé el primero en comentar! Nos encantaría escuchar tus opiniones y sugerencias sobre este artículo."
                    },
                ],
      showAllComments: false, // Controla si se deben mostrar todos los comentarios
      maxVisibleComments: 2 // Número máximo de comentarios visibles inicialmente
    };
  },
  computed: {
    // Filtra los comentarios visibles
    visibleComments() {
      // Filtra los comentarios visibles y maneja datos incompletos
      if( this.comments.length == 0) {
        return this.comments2.slice(0, this.maxVisibleComments)
      } else
      return this.comments.slice(0, this.maxVisibleComments).map(comment => {
        return {
          // @ts-ignore
          Autor: comment.Autor || 'Autor desconocido',
          // @ts-ignore
          Fecha: comment.Fecha || 'Fecha desconocida',
          // @ts-ignore
          Contenido: comment.Contenido || 'Contenido no disponible'
        };
      });
    },
    // Filtra los comentarios ocultos
    hiddenComments() {
      return this.comments.slice(this.maxVisibleComments);
    },
    
    safeComments() {
    return this.comments.map(comment => ({
      // @ts-ignore
      Autor: comment.Autor || 'Autor desconocido',
      // @ts-ignore
      Fecha: comment.Fecha || 'Fecha desconocida',
      // @ts-ignore
      Contenido: comment.Contenido || 'Contenido no disponible'
    }));
  }
    
  },
  mounted() {
    // @ts-ignore
    this.cargarComentarios();
  },
  methods: {
    // Alternar la visibilidad de los comentarios adicionales
    toggleComments() {
      this.showAllComments = !this.showAllComments;
    },

    async cargarComentarios() {
      try {
        const response = await axios.get(ConsultasBlog, {
          params: {
            action: 'obtenerComentarios',
            params: JSON.stringify([this.article])
          }
        });
        if (response.data.error) {
          console.error(response.data.error);
        } else {
          this.comments = this.transformarComentarios(response.data);
        }
      } catch (error) {
        console.error('Error al cargar los comentarios:', error);
      }
    },

    // @ts-ignore
  
    transformarComentarios(comentarios) {
      
      // @ts-ignore
      return comentarios.map(comment => ({
        ID: comment.idComentarios,
        Autor: comment.AutorComent,
        Fecha: this.formatearFecha(comment.FechaComent),
        Contenido: comment.ContenidoComent
      }));
    },
    // @ts-ignore
    formatearFecha(fecha) {
      const date = new Date(fecha);
      const options = { day: 'numeric', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' };
      // @ts-ignore
      return date.toLocaleDateString('es-ES', options).replace(',', ' a las');
    }
  }
};
</script>

<style>

</style>