import art_003 from "../../pages/Blog/Aprende/Tips/03-ART-APRENDE-TIPS-240124.vue"
import art_013 from "../../pages/Blog/Aprende/Tips/13-ART-APRENDE-TIPS-150324.vue"
import art_016 from "../../pages/Blog/Aprende/Tips/16-ART-APRENDE-TIPS-020424.vue"
import art_0033 from "../../pages/Blog/Aprende/Tips/0033-ART-APRENDE-TIPS-280524.vue"
import art_0055 from "../../pages/Blog/Aprende/Tips/0055-ART-APRENDE-TIPS-050724.vue"
import art_0057 from "../../pages/Blog/Aprende/Tips/0057-ART-APRENDE-TIPS-310724.vue"
import art_0058 from "../../pages/Blog/Aprende/Tips/0058-ART-APRENDE-TIPS-010824.vue"

export default [
    {
        path: '/blog/aprende/tips/que-hacer-si-no-te-quedaste-en-el-ipn',
        name: '¿Qué hacer si no te quedaste en el IPN?, aquí la respuesta',
        meta: {
          title: "¿Qué hacer si no te quedaste en el IPN?, aquí la respuesta",
          description: "¿Qué hacer si no te quedaste en el IPN? Descubre las mejores opciones educativas públicas y privadas de educación superior.",
          keywords: "¿Qué hacer si no te quedaste en el IPN?, Opciones educativas privadas, Opciones educativas públicas, ¿Cuáles son las mejores universidades privadas?",
          metaTags:
          {
            "og:title": "¿Qué hacer si no te quedaste en el IPN?, aquí la respuesta",
            "og:description": "¿Qué hacer si no te quedaste en el IPN? Descubre las mejores opciones educativas públicas y privadas de educación superior.",
            "og:keywords": "¿Qué hacer si no te quedaste en el IPN?, Opciones educativas privadas, Opciones educativas públicas, ¿Cuáles son las mejores universidades privadas?",
          },
          requireAuth: false,
        },
        component: art_0058
    },
    {
        path: '/blog/aprende/tips/no-te-quedaste-en-la-unam-descubre-estas-alternativas',
        name: '¿No te quedaste en la UNAM? Descubre estas alternativas',
        meta: {
          title: "¿No te quedaste en la UNAM? Descubre estas alternativas",
          description: "Si presentaste tu examen de admisión a la UNAM y no fuiste seleccionado, te damos alternativas para continuar tu educación universitaria. ¡Conócelas!",
          keywords: "que hacer si no te quedaste en la unam, que hacer si no te quedaste en la UNAM, opciones educativas públicas, opciones educativas privadas, que hago si no me quedé en la UNAM, alternativas universitarias",
          metaTags:
          {
            "og:title": "¿No te quedaste en la UNAM? Descubre estas alternativas",
            "og:description": "Si presentaste tu examen de admisión a la UNAM y no fuiste seleccionado, te damos alternativas para continuar tu educación universitaria. ¡Conócelas!",
            "og:keywords": "que hacer si no te quedaste en la unam, que hacer si no te quedaste en la UNAM, opciones educativas públicas, opciones educativas privadas, que hago si no me quedé en la UNAM, alternativas universitarias",
          },
          requireAuth: false,
        },
        component: art_0057
    },
    {
        path: '/blog/aprende/tips/ansiedad-es-lo-mismo-que-angustia',
        name: '¿Ansiedad es lo mismo que angustia?: Aquí la respuesta',
        meta: {
          title: "¿Ansiedad es lo mismo que angustia?: Aquí la respuesta",
          description: "Alguna vez te haz preguntado: ¿Ansiedad es lo mismo que angustia?, ¿Haz experimentado estos padecimientos?, te decimos cómo evitarlos.",
          keywords: "ataque de ansiedad, cómo evitar la angustia y el miedo, diferencia entre estrés y ansiedad, la ansiedad es una emoción o un sentimiento, diferencia entre angustia y ansiedad",
          metaTags:
          {
            "og:title": "¿Ansiedad es lo mismo que angustia?: Aquí la respuesta",
            "og:description": "Alguna vez te haz preguntado: ¿Ansiedad es lo mismo que angustia?, ¿Haz experimentado estos padecimientos?, te decimos cómo evitarlos.",
            "og:keywords": "ataque de ansiedad, cómo evitar la angustia y el miedo, diferencia entre estrés y ansiedad, la ansiedad es una emoción o un sentimiento, diferencia entre angustia y ansiedad",
          },
          requireAuth: false,
        },
        component: art_0055
    },
    {
        path: '/blog/aprende/tips/puntos-clave-para-evaluar-la-calidad-de-una-universidad-privada',
        name: '¿Cómo evaluar la calidad de una universidad privada?',
        meta: {
          title: "¿Cómo evaluar la calidad de una universidad privada?",
          description: "Conoce los puntos clave para elegir la mejor opción educativa y responde a la pregunta: ¿Cómo evaluar la calidad de una universidad privada?",
          image: "https://blog.humanitas.edu.mx/images/meta/como-evaluar-calidad-universidad-privada-estudiantes.webp",
          keywords: "¿Cómo evaluar la calidad de una universidad privada?,Criterios para evaluar mejores universidades, profesores y docentes, evaluación institucional.",
          metaTags:
          {
            "og:title": "¿Cómo evaluar la calidad de una universidad privada?",
            "og:description": "Conoce los puntos clave para elegir la mejor opción educativa y responde a la pregunta: ¿Cómo evaluar la calidad de una universidad privada?",
            "og:image": "https://blog.humanitas.edu.mx/images/meta/como-evaluar-calidad-universidad-privada-estudiantes.webp",
            "og:keywords": "¿Cómo evaluar la calidad de una universidad privada?,Criterios para evaluar mejores universidades, profesores y docentes, evaluación institucional.",
          },
          requireAuth: false,
        },
        component: art_0033
    },
    {
        path: '/blog/aprende/tips/mejores-tecnicas-estudio-para-universidad',
        name: 'Domina tus estudios: técnicas y consejos para tu éxito universitario',
        meta: {
          title: "Domina tus estudios: técnicas y consejos para tu éxito universitario",
          description: "Descubre las mejores técnicas de estudio y consejos para maximizar tu rendimiento en la universidad. Estudia de manera más inteligente y eficaz.",
          image: "https://blog.humanitas.edu.mx/images/meta/Las-mejores-tecnicas-estudio-triunfar-universidad.jpg",
          metaTags:
          {
            "og:title": "Domina tus estudios: técnicas y consejos para tu éxito universitario",
            "og:description": "Descubre las mejores técnicas de estudio y consejos para maximizar tu rendimiento en la universidad. Estudia de manera más inteligente y eficaz.",
            "og:image": "https://blog.humanitas.edu.mx/images/meta/Las-mejores-tecnicas-estudio-triunfar-universidad.jpg",
          },
          requireAuth: false,
        },
        component: art_003
    },
    {
        path: '/blog/aprende/tips/estoicismo-para-estudiantes-universitarios-y-beneficios',
        name: 'Estoicismo: cómo puede ayudar a los estudiantes a superar desafíos',
        meta: {
          title: "Estoicismo: cómo puede ayudar a los estudiantes a superar desafíos",
          description: "Explora los principios del Estoicismo y cuáles son sus beneficios en tu vida universitaria.",
          image: "https://blog.humanitas.edu.mx/images/meta/Que-es-estoicismo-y-como-ayuda-a-universitarios-Marco-Aurelio.jpg",
          metaTags:
          {
            "og:title": "Estoicismo: cómo puede ayudar a los estudiantes a superar desafíos",
            "og:description": "Explora los principios del Estoicismo y cuáles son sus beneficios en tu vida universitaria.",
            "og:image": "https://blog.humanitas.edu.mx/images/meta/Que-es-estoicismo-y-como-ayuda-a-universitarios-Marco-Aurelio.jpg",
          },
          requireAuth: false,
        },
        component: art_013
    },
    {
        path: '/blog/aprende/tips/maximiza-tu-productividad-estilo-bill-gates',
        name: 'Maximiza tu productividad al estilo de Bill Gates',
        meta: {
          title: "Maximiza tu productividad al estilo de Bill Gates",
          description: "Descubre la técnica de Bill Gates y potencia tu productividad, ¡lee más!",
          image: "https://blog.humanitas.edu.mx/images/meta/Aumenta-tu-productividad-tecnica-trabajo-profundo-Bill-Gates.jpg",
          metaTags:
          {
            "og:title": "Maximiza tu productividad al estilo de Bill Gates",
            "og:description": "Descubre la técnica de Bill Gates y potencia tu productividad, ¡lee más!",
            "og:image": "https://blog.humanitas.edu.mx/images/meta/Aumenta-tu-productividad-tecnica-trabajo-profundo-Bill-Gates.jpg",
          },
          requireAuth: false,
        },
        component: art_016
    },
]