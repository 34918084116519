<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
        <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">¿Qué hacer si no te quedaste en el IPN?</h1>
        <div class="flex flex-col justify-center items-center w-full">
            <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-200">
                <img src="/images/que-hacer-quedaste-ipn.webp" alt="¿Qué hacer si no te quedaste en el IPN?, no te desanimes, hay muchas opciones educativas." class="mb-6">
                <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
                    <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/aprende" class=" text-hone">Aprende</router-link> > <router-link to="/blog/aprende/tips" class=" text-hone">Tips</router-link></p>
                    <div class="flex items-start justify-start">
                        <ul class="lg:flex text-left list-disc pl-5 italic text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">01 de agosto de 2024</li>  <li>Iván Solís Rivera</li></ul>
                    </div>
                    <p class="md:mb-4 mb-6"><b>¿Qué hacer si no te quedaste en el IPN?,</b> es la pregunta que se plantea un gran porcentaje de aspirantes que, por alguna razón, <b>no alcanzaron la puntuación mínima o no obtuvieron el acceso a la educación superior</b> que ofrece el Instituto Politécnico Nacional (IPN).</p>
                    <p class="md:mb-4 mb-6"><b>1. Estudiar un curso propedéutico:</b><br> <b>Algunas instituciones ofrecen cursos propedéuticos que pueden ayudarte a mejorar tu rendimiento</b> en futuros exámenes de admisión. Además, de desarrollar tus habilidades y conocimientos en el área de estudio que deseas especializarte.</p>
                    <p class="md:mb-4 mb-6"><b>2. Asesoramiento vocacional:</b><br> Si aún no lo has hecho, considera buscar asesoramiento vocacional. <b>Esto te puede ayudar a aclarar tus intereses, habilidades y opciones de carrera,</b> así como a explorar alternativas que tal vez no habías considerado.</p>
                    <p class="md:mb-4 mb-6"><b>3. Prepara tu estrategia para el siguiente examen:</b><br> <b>Considera inscribirte a una carrera afín o menos competitiva y luego solicitar un cambio de licenciatura</b> una vez que estés cursando. De la misma manera, se pueden revalidar materias o asignaturas toda vez que las materias tengan un tronco común de estudio.</p>
                    <p class="md:mb-4 mb-6"><b>4. Participa en actividades extracurriculares:</b><br> <b>Voluntariado, prácticas profesionales y actividades extracurriculares pueden enriquecer tu currículum</b> y darte experiencia valiosa mientras te preparas para el próximo intento.</p>
                    <p class="md:mb-4 mb-6"><b>5. Explora otras universidades:</b><br> Para muchos estudiantes, <b>ser admitido en la universidad es una validación del esfuerzo, la dedicación y la preparación</b> que han invertido durante su educación secundaria o preparatoria.</p>
                    <p class="md:mb-4 mb-6">Sin embargo, <b>resalta la problemática de la oferta educativa en las instituciones de educación superior,</b> Este dato hace evidente la diferencia entre la oferta y la demanda en la educación superior pública.</p>
                    <p class="md:mb-4 mb-6">Ante este panorama, <b>los datos correspondientes al ingreso al ciclo 2022-2023 indican que aproximadamente 21 mil estudiantes ingresaron al IPN (30 por ciento), pero 56 mil 541 no lo lograron.</b> especialmente las <router-link to="/blog/academico/licenciatura/universidades-sin-examen-admision" class=" text-htwo hover:text-hone">universidades públicas</router-link>, que tienen una capacidad limitada para admitir estudiantes.</p>
                    <p class="md:mb-4 mb-6"><b>Los altos niveles de rechazo de aspirantes a la educación superior pueden generar desmotivación y desesperanza</b> entre los jóvenes, afectando su autoestima y sus aspiraciones educativas y profesionales.</p>
                    <p class="md:mb-4 mb-6">De la misma forma, <b>muchos estudiantes que no logran cursar la educación superior abandonan sus estudios y optan por empleos informales o de baja calificación,</b> perpetuando el ciclo de pobreza y desigualdad.</p>
                    <p class=" md:mb-8 mb-10">Por lo anterior, debes de considerar cuáles son las mejores universidades privadas en las que puedes desarrollar tu potencial y continuar con los estudios en el nivel superior.</p>
                    <img src="/images/opciones-educativas-publicas.webp" alt="Existen diversas opciones educativas públicas si no te quedaste en el IPN." class="md:mb-8 mb-10">
                    <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">¿Cuáles son las mejores universidades privadas?</h2>
                    <p class="md:mb-4 mb-6">En México, varias <router-link to="/blog/aprende/tips/puntos-clave-para-evaluar-la-calidad-de-una-universidad-privada" class=" text-htwo hover:text-hone">universidades privadas</router-link> son reconocidas por su alta calidad educativa, programas innovadores y fuerte vinculación con el sector empresarial.</p>
                    <p class=" md:mb-8 mb-10">A continuación, <b>se enumeran algunas de las mejores universidades privadas en el país, destacadas por su prestigio, infraestructura, calidad académica</b> y oferta educativa:</p>
                    <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Tecnológico de Monterrey (ITESM)</h3>
                    <p class="md:mb-8 mb-10">Cuenta con campus en algunas zonas de México, el principal se ubica en Monterrey, <b>tiene programas en Ingeniería, Administración, Ciencias sociales, Medicina y Tecnología.</b></p>
                    <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Instituto Tecnológico Autónomo de México (ITAM)</h3>
                    <p class="md:mb-8 mb-10"><b>La universidad cuenta una oferta educativa que abarca las siguientes áreas de conocimiento: Economía, Administración,</b> Derecho, Ciencias Políticas, Ingeniería y Matemáticas.</p>
                    <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Universidad Humanitas</h3>
                    <p class="md:mb-4 mb-6">La <router-link to="/" target="_blank" class=" text-htwo hover:text-hone">universidad Humanitas</router-link> tiene presencia en diversas ciudades de México con campus como Tijuana, Mérida, Querétaro, Guadalajara, Chihuahua, entre otras. <b>Su oferta académica abarca los niveles de licenciatura, maestría y doctorado.</b></p>
                    <p class="md:mb-8 mb-10">La institución destaca por su formación integral, valores éticos y amplios programas académicos.</p>
                    <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Anáhuac</h3>
                    <p class="md:mb-8 mb-10">Es una universidad privada que busca elevar la condición humana, <b>su oferta académica abarca: Administración, Derecho, Comunicación, Ingeniería, Diseño,</b> entre otras.</p>
                    <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Universidad de las Américas Puebla (UDLAP)</h3>
                    <p class="md:mb-8 mb-10"><b>La UDLAP es una universidad que busca el aprendizaje, la investigación y el desarrollo académico,</b> cuenta con un enfoque en educación liberal y programas de investigación.</p>
                    <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Universidad Panamericana (UP)</h3>
                    <p class="md:mb-8 mb-10"><b>La Universidad Panamericana tiene como objetivo formar egresados con un fuerte sentido de responsabilidad social,</b> que busquen alcanzar tanto la excelencia profesional como la plenitud en su vida personal.</p>
                    <img src="/images/cuales-son-las-mejores-universidades-privadas.webp" alt="¿Cuáles son las mejores universidades privadas?, Humanitas destaca entre la lista." class="md:mb-8 mb-10">
                    <p class="md:mb-8 mb-10">De la misma manera, hay instituciones universitarias que coinciden con los programas del Politécnico, como son las siguientes:</p>
                    <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Opciones educativas privadas</h3>
                    <p class="md:mb-4 mb-6">En México, varias universidades privadas ofrecen programas académicos similares a los del Instituto Politécnico Nacional (IPN), especialmente en las áreas de Ingeniería, Ciencias Exactas y Administración.</p>
                    <p class="md:mb-8 mb-10">A continuación, algunas de las universidades privadas que tienen programas comparables:</p>
                    <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Universidad Iberoamericana (IBERO)</h3>
                    <p class="md:mb-8 mb-10"><b>La Ibero cuenta con áreas de estudio como: Ingeniería, Ciencias de la Salud</b> y Ciencias Sociales y Administrativas.</p>
                    <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Universidad del Valle de México (UVM)</h3>
                    <p class="md:mb-8 mb-10">La Universidad del Valle de México (<b>UVM</b>) comparte áreas de conocimiento con el IPN, como son: Ingeniería; <b>Administración de Empresas, Ciencias de la Salud y Administrativas.</b></p>
                    <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Universidad La Salle</h3>
                    <p class="md:mb-8 mb-10">La universidad <b>La Salle cuenta con una oferta educativa similar al IPN</b> en áreas de estudio como: Ingeniería, Administración y Ciencias de la Salud.</p>
                    <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">UNITEC</h3>
                    <p class="md:mb-4 mb-6">La universidad <b>UNITEC ofrece un programa</b> educativo similar al del IPN <b>en áreas de conocimiento como son: Administración, Ciencias de la Salud e Ingeniería.</b></p>
                    <p class="md:mb-8 mb-10">Es importante recordar que cada institución universitaria tiene su propio enfoque y fortalezas, por lo que es recomendable que los estudiantes investiguen los planes de estudio específicos y las oportunidades adicionales.</p>
                    <div class="md:mb-8 mb-10 border border-gray-300 w-full">
                        <ContactoVue class="m-3"/>
                    </div>
                    <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Opciones educativas públicas</h2>
                    <p class="md:mb-8 mb-10">A continuación, <b>te presentamos una serie de universidades públicas que cuentan con un plan de estudios académicos similar,</b> o en algunos casos, tienen convenios de cooperación para la validación de estudios con el IPN.</p>
                    <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Universidad Nacional Autónoma de México (UNAM)</h3>
                    <p class="md:mb-4 mb-6">Tanto la UNAM como el IPN son instituciones de educación superior muy reconocidas en México y ofrecen una amplia variedad de programas académicos.</p>
                    <p class="md:mb-8 mb-10"><b>Entre algunas áreas de estudio que son similares en ambas instituciones, con: Ingeniería y tecnología; Ciencias Naturales y exactas;</b> Ciencias Químicas, además de Ciencias de la Computación.</p>
                    <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Universidad Autónoma Metropolitana</h3>
                    <p class="md:mb-8 mb-10"><b>La Universidad Autónoma Metropolitana (UAM)</b> y el Instituto Politécnico Nacional (IPN) <b>cuentan con áreas de estudio similares, como son: Ingeniería; Ciencias Biológicas</b> y de la Salud, Administración y Negocios.</p>
                    <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Universidad Autónoma del Estado de Hidalgo</h3>
                    <p class="md:mb-4 mb-6">La Universidad Autónoma del Estado de Hidalgo (<b>UAEH</b>) y el IPN <b>ofrecen programas académicos parecidos en áreas clave que son esenciales para el desarrollo tecnológico,</b> científico y social de México.</p>
                    <p class="md:mb-8 mb-10">Entre las áreas de estudio que comparten destacan: Ingenierías; Ciencias de la Salud; Ciencias Sociales y gestión, además de Ciencias Naturales.</p>
                    <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Universidad Autónoma de San Luis Potosí</h3>
                    <p class="md:mb-4 mb-6">La Universidad Autónoma de San Luis Potosí (UASLP) y el Instituto Politécnico Nacional (IPN) ofrecen una variedad de programas académicos, muchos de los cuales son similares debido a su enfoque en áreas técnicas y científicas.</p>
                    <p class="md:mb-8 mb-10">Entre <b>las áreas de conocimiento que comparten, destacan: Ingeniería, Ciencias de la Salud, Relaciones Internacionales, Tecnologías de la Información y Comunicación.</b></p>
                    <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Universidad Autónoma de Coahuila</h3>
                    <p class="md:mb-8 mb-10">La Universidad Autónoma de Coahuila (<b>UAdeC</b>) y el Instituto Politécnico Nacional (IPN) <b>comparten algunas carreras similares que se encuentran en las siguientes áreas de estudio: Ingeniería; Ciencias de la Salud;</b> Ciencias Sociales y Administrativas; Ciencias Naturales y Exactas.</p>
                    <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Universidad Politécnica de Pachuca</h3>
                    <p class="md:mb-8 mb-10">La Universidad Politécnica de Pachuca (<b>UPP</b>) y el Instituto Politécnico Nacional (IPN) <b>ofrecen programas educativos en áreas similares, especialmente en los campos de la Ingeniería, las Ciencias Exactas</b> y Tecnología.</p>
                    <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Universidad Autónoma Metropolitana</h3>
                    <p class="md:mb-8 mb-10">La Benemérita Universidad Autónoma de Puebla (<b>BUAP</b>) y el Instituto Politécnico Nacional (IPN) <b>cuentan con programas académicos en una variedad de disciplinas similares, particularmente en el área de las Ingenierías, Ciencias Exactas y Naturales, Ciencias de la Salud,</b> Ciencias Sociales y Administrativas.</p>
                    <img src="/images/opciones-educativas-privadas.webp" alt="Antes de elegir las opciones educativas privadas, considera los planes de estudio." class="md:mb-8 mb-10">
                    <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Conclusión</h3>
                    <p class="md:mb-4 mb-6"><b>No aprobar un examen de ingreso no define tu capacidad ni tu valor. Es solo un obstáculo temporal en tu camino hacia tus metas.</b> Con dedicación, planificación y apoyo, puedes superar este desafío y seguir adelante en tu trayectoria educativa y profesional.</p>
                    <p class="mb-20"><b>Considera la amplia oferta educativa superior con base en tus intereses y las habilidades que tienes.</b></p>
                    <div class="flex justify-between md:mb-8 mb-10">
                    <LikesVue :articleID="code"/>
                    <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
                    </div>
                </div>
                <div class="flex justify-start items-start">
                    <img src="/images/barra-art.png" class="w-[15%]">
                </div>
            </div>
            <SigArt :ant="true" :linkAnt="'/aprende/tips/no-te-quedaste-en-la-unam-descubre-estas-alternativas'" :linkSig="'/ser-humanitas/comunidad/cuanto-cuesta-mensualidad-universidad-humanitas'" :sig="false"/>
            <ConComentarios :article="code" class="pb-12"/>
            <Comentarios :article="code" class="pb-12"/>
        </div>
    </div>
</template>

<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import ContactoVue from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue';
import SigArt from '../../pagesComponents/SigArt.vue';
import VideoClip from '../../pagesComponents/videoClip.vue'

export default {
    components: { Comentarios, ConComentarios, ContactoVue, LikesVue, ShareP, SigArt, VideoClip},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '0058-ART-APRENDE-TIPS-010824',
            ruta: '/aprende/tips/puntos-clave-para-evaluar-la-calidad-de-una-universidad-privada',
            title: '¿Cómo evaluar la calidad de una universidad privada?',
            image: 'https://blog.humanitas.edu.mx/images/Costo-y-requisitos-estudiar-maestria.jpg',
            text: '¿Qué hacer si no te quedaste en el IPN? Descubre las mejores opciones educativas públicas y privadas de educación superior.'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx/academico/maestria/costo-y-requisitos-estudiar-maestria';

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>