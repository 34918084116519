<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">27 de marzo Día Mundial del Teatro 2024: <br class="md:block hidden"> celebra la paz y el arte</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-200">
          <img src="/images/A-27-marzo-2024-dia-mundial-teatro.jpg" class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/academico" class=" text-hone">Académico</router-link> > <router-link to="/blog/academico/licenciatura" class=" text-hone">Licenciatura</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex text-left list-disc pl-5 italic text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">27 de marzo de 2024</li>  <li>Carolina Reyes Martínez/Redacción Humanitas</li></ul>
            </div>
            <p class=" md:mb-4 mb-6"><b>Extracto:</b> El Día Mundial del Teatro ilumina escenarios y corazones cada 27 de marzo. Festejamos su universalidad y su impacto en la cultura. ¡Descubre por qué sigue siendo esencial!</p>
            <p class=" md:mb-8 mb-10">El 27 de marzo es un día en el cual se reconoce la labor y la dedicación de los actores, y se promueve a las artes escénicas en la sociedad.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">¿Cuál es el origen del Día Mundial del Teatro?</h2>
            <p class="md:mb-4 mb-6">Esta conmemoración tiene su origen en el año 1961, con el apoyo de la UNESCO (Organización de las Naciones Unidas para la Educación, la Ciencia y la Cultura). <b>Tiene por objetivo reconocer y fomentar el valor del arte dramático como una vía de paz entre las naciones.</b></p>
            <p class="md:mb-4 mb-6"><b>Se celebró por primera vez el 27 de marzo de 1962</b> por iniciativa del Instituto Internacional del Teatro, pues en París se llevó a cabo la temporada del “Teatro de las Naciones”. Este evento significó la unión de las artes escénicas de todos los países.</p>
            <p class="md:mb-8 mb-10"><b>Cada año, en esta fecha se celebra el teatro y su impacto en la cultura de la paz.</b> Se presentan obras de diferentes géneros como tragedia, comedia, farsa y melodrama, así como géneros musicales como ópera, zarzuela y opereta. También se incluyen obras musicales y danza. El diálogo dramatizado en el ballet también se considera teatro.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">La historia del teatro en Occidente</h2>
            <p class="md:mb-4 mb-6"><b>El teatro es el género artístico que une a la literatura y a las artes escénicas.</b> Todas las culturas antiguas representaron la vida cotidiana, o enseñaron a los jóvenes sobre las tradiciones, la religión o cómo se fundó su comunidad.</p>
            <p class="md:mb-4 mb-6"><b>En Occidente, en los siglos VI al IV a. C. los griegos entendieron estas representaciones como arte,</b> fue el origen del teatro como tal. <b>Un ejemplo: las bacanales o fiestas dedicadas a Dioniso, el Dios del Vino</b> y la fertilidad, donde se escenificaban momentos importantes de la mitología griega.</p>
            <p class="md:mb-4 mb-6">En estas representaciones no podían faltar el vino y el baile, por lo que Dioniso empezó a ser visto también como el dios del Teatro. <b>Un sacerdote suyo, Thespis, añadió diálogos a los rituales, que se escuchaban junto a la música y el coro. Él fue el primer actor de la historia</b> y ganó la primera competencia que se realizó en Atenas en el año 534 a. C.</p>
            <p class="md:mb-4 mb-6"><b>El teatro comenzó a florecer en Grecia</b> y además del tema religioso, abordó la educación de los jóvenes y los valores cívicos. <b>Así surgen tres de los grandes autores griegos: Esquilo, Sófocles y Eurípides,</b> quienes escribieron las tragedias sobre los mitos. Pero también, la comedia destacó y su representante fue Aristófanes.</p>
            <p class="md:mb-8 mb-10"><b>El teatro griego influyó en otros lugares como en Roma.</b> El teatro era muy importante para los romanos, ya que cuando había funciones, las actividades se detenían y toda la gente iba a verlas. <b>Era parte de su identidad y cultura.</b> Al llegar el cristianismo, el teatro romano decayó.</p>
            <img src="/images/A-dia-mundial-del-teatro-2024.jpg" class="md:mb-8 mb-10">
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">El teatro: de la Edad Media a la Modernidad</h3>
            <p class="md:mb-4 mb-6"><b>Después de la caída del Imperio Romano, el teatro perdió relevancia</b> pues el cristianismo lo rechazaba por su origen pagano.</p>
            <p class="md:mb-4 mb-6"><b>En el siglo X se comienza a representar la liturgia cristiana</b> y la Pascua. Las escenas eran de los pasajes de la Biblia. Se escenificaban en las iglesias y conventos pero, poco a poco, salieron del claustro para que más personas las conocieran, al aire libre.</p>
            <p class="md:mb-4 mb-6"><b>En España fueron llamados autos sacramentales o dramas de la Eucaristía</b> y sus principales exponentes fueron Lope de Vega, Calderón de la Barca y Tirso de Molina.</p>
            <p class="md:mb-4 mb-6">En Inglaterra, también estas funciones recorrían todos los lugares. Sin embargo, los protestantes y los humanistas del Renacimiento las condenaban por frívolas. Fueron prohibidas también en París.</p>
            <p class="md:mb-4 mb-6"><b>Pero en el Renacimiento, las obras teatrales se enfocaron en desligarse de la religión y retomar el legado de Aristóteles,</b> entre otros autores.</p>
            <p class="md:mb-4 mb-6"><b>La burguesía como principal clase social determinó nuevos estilos como lo serían el teatro barroco español y el isabelino inglés.</b> Las figuras principales fueron Miguel de Cervantes Saavedra y William Shakespeare, respectivamente.</p>
            <p class="md:mb-4 mb-6">Pero <b>la Comedia de Arte Italiana también fue un género muy reconocido,</b> ya que fue una forma de teatro callejero interpretada por actores profesionales. Se presentaban en las plazas de los pueblos y fueron muy populares. Nace el género de la tragicomedia y para el siglo XVII, aparece la ópera. Este “estilo italiano” finalmente recorre toda Europa.</p>
            <p class="md:mb-8 mb-10">Francia no se queda atrás: en este siglo, los autores Jean Racine, Pierre Corneille así como Molière, aportan grandes obras a la humanidad.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">La modernidad y el teatro</h3>
            <p class="md:mb-4 mb-6"><b>La siguiente etapa del teatro en Occidente, llegó con el romanticismo alemán en la segunda mitad del siglo XVIII,</b> que enfatizó el sentimentalismo y el dramatismo. Entre los autores más relevantes de esta época se encuentran Wolfang von Goethe, Friedrich Shiller con obras como Fausto y Guillermo Tell.</p>
            <p class="md:mb-4 mb-6">A su vez, el nacionalismo europeo influyó en más autores como Georg Büchner, Alfred de Musset, Víctor Hugo, el Duque de Rivas y José Zorrilla, entre otros.</p>
            <p class="md:mb-4 mb-6"><b>El paso a la modernidad se dio en el siglo XIX con el teatro realista:</b> en las representaciones teatrales, se describía a la naturaleza con mucho detalle. Aquí adquieren notoriedad autores como Björnstjerne Björnson, August Strindberg y Henrik Ibsen, Anton Chejov, Manuel Tamayo.</p>
            <p class="md:mb-4 mb-6"><b>El siglo XX dio paso a diversas escuelas teatrales o las vanguardias,</b> donde los personajes tenían mayor profundidad psicológica con técnicas interpretativas como el Método Stanislasvki, y con temas de militancia política y de denuncia.</p>
            <p class="md:mb-4 mb-6">Algunas corrientes fueron el expresionismo, el teatro épico, el teatro del absurdo vinculado al existencialismo, además se reflejaba un sentimiento antiburgués.</p>
            <p class="md:mb-4 mb-6"><b>A partir de los años 60, el teatro reconecta con las emociones del espectador</b> y lo hace partícipe de las obras.</p>
            <p class="md:mb-8 mb-10"><b>Se rompen las viejas estructuras teatrales por lo que se llevaron a cabo obras experimentales.</b> Este movimiento se propaga a Europa y a otros países, pues la intención era hacer teatro no comercial para un público más diverso.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">El teatro actual y sus tendencias</h3>
            <p class="md:mb-4 mb-6">Hoy en día, <b>el teatro físico, el teatro documental y el teatro de inmersión son algunas de las tendencias más relevantes.</b></p>
            <p class="md:mb-4 mb-6">El teatro físico se centra en la expresión corporal más que en los diálogos, por lo que los actores y el espacio toman fuerza.</p>
            <p class="md:mb-4 mb-6">El teatro documental presenta historias reales que se sustentan en datos, entrevistas, todo para poner a la luz la verdad.</p>
            <p class="md:mb-8 mb-10">En el teatro de inmersión, el público comparte el espacio con los actores y el escenario, participa en la acción.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">El papel del teatro en los niños y jóvenes</h2>
            <p class="md:mb-4 mb-6">El teatro <b>tiene un impacto positivo en el desarrollo de los niños y los jóvenes:</b> les ayuda a ver el mundo desde otras perspectivas, así desarrollan la creatividad y la imaginación.</p>
            <p class="md:mb-8 mb-10"><b>Fomenta la sociabilización y mejora la concentración,</b> sobre todo, en los adolescentes quienes pueden expresar sus sentimientos más fácilmente, <b>así como la coordinación corporal.</b> Llevar a los niños a obras de teatro infantil, o a los adolescentes a tomar clases, tiene estos beneficios.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">El mensaje anual del Día Mundial del Teatro</h2>
            <p class="md:mb-4 mb-6">Este año, <b>el escritor noruego Jon Fosse, ganador del Premio Nobel 2023,</b> nos habla de cómo todos somos únicos pero, al mismo tiempo, parecidos. Nos dice que el arte y en especial el teatro, ayuda a entender nuestras diferencias y lo que nos hace humanos.</p>
            <p class="md:mb-8 mb-10">El arte nos enseña a celebrar lo diferente y la belleza de lo que es desconocido. También <b>es una forma de establecer y promover la paz y el diálogo,</b> en contraste con las guerras que quieren eliminar lo diferente.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">¡Participa en el Día Mundial del Teatro!</h2>
            <p class="mb-2">Para unirte a las celebraciones puedes:</p> 
            <ul class="list-disc pl-6 md:mb-8 mb-10">
                <li><b>Asistir a una función </b> de teatro o participar en alguna obra en tu comunidad.</li>
                <li><b>Compartir en redes sociales </b>cómo ha sido tu experiencia con el teatro o unirte a eventos virtuales que te acerquen a personas de otras nacionalidades.</li>
                <li><b>Apoyar a los teatros locales: </b>acude a sus funciones o haz un voluntariado para promover las artes escénicas, o si en tu escuela o universidad se presentan obras de teatro, difúndelas, ¡participa! </li>
            </ul>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Conclusión</h2>
            <p class="md:mb-4 mb-6"><b>El Día Mundial del Teatro es un festejo a la creatividad, a la diversidad y al poder del arte.</b> A través de sus historias, el teatro une a las personas de todo el mundo.</p>
            <p class="md:mb-4 mb-6">Al participar en las actividades de este día, contribuimos a una sociedad más empática y conectada.</p>
            <p class="md:mb-8 mb-10">Este 27 de marzo de 2024, Día Mundial del Teatro, <b>¡descubre la magia que ofrece el escenario!</b></p>
            <!-- Aqui va el componente de contacto, no se te olvide hacerlo manolo -->
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
                <ContactoVue class="m-3"/>
            </div>
            <p class=" text-htwo text-left mb-6">Explora más: <a href="https://humanitas.edu.mx/" target="_blank" class="text-htwo">Universidad Humanitas</a></p>
            <p class=" text-htwo text-left mb-20">Te puede interesar: <a href="https://humanitas.edu.mx/licenciatura-en-arte-y-teatro" target="_blank" class="text-htwo">Licenciatura en arte y teatro</a></p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="true" :linkAnt="'/aprende/tips/estoicismo-para-estudiantes-universitarios-y-beneficios'" :linkSig="'/aprende/tendencias/tendencias-en-psicologia-2024-tecnologia-salud-mental'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import ContactoVue from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue';
import SigArt from '../../pagesComponents/SigArt.vue';

export default {
    components: { Comentarios, ConComentarios, ContactoVue, LikesVue, ShareP, SigArt},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '14-ART-ACADEMICO-LICENCIATURA-270324',
            ruta: '/academico/licenciatura/dia-mundial-del-teatro-2024',
            title: '27 de marzo Día Mundial del Teatro 2024: celebra la paz y el arte',
            image: 'https://blog.humanitas.edu.mx/images/Costo-y-requisitos-estudiar-maestria.jpg',
            text: 'Conoce la importancia y la belleza del Día Mundial del Teatro. Exploramos su historia, significado y cómo puedes unirte a este gran día, ¡participa!'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx/academico/maestria/costo-y-requisitos-estudiar-maestria';

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>