<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">Descubre las tendencias clave del Diseño  <br class="md:block hidden"> Gráfico para 2024 </h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-200">
          <img src="/images/tendencia-diseno-grafico-2024-colores-fluorescentes-neon-humanitas.webp" alt="Son diversas aptitudes y habilidades para estudiar Derecho: conócenos" class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tigh text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/academico" class=" text-hone">Académico</router-link> > <router-link to="/blog/academico/licenciatura" class=" text-hone">Licenciatura</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex text-left list-disc pl-5 italic text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">7 de Mayo de 2024</li>  <li>Carolina Reyes Martínez</li></ul>
            </div>
            <p class="md:mb-8 mb-10"><b>Extracto:</b> Conoce las tendencias más influyentes del diseño gráfico en 2024 y cómo éstas pueden transformar tu enfoque creativo, ya seas un estudiante o un profesional experimentado.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Tendencias visuales que amplifican el mensaje</h2>
            <p class="md:mb-8 mb-10"><b>El diseño gráfico es una disciplina dinámica:</b> va de la mano de la evolución tecnológica, de las tendencias del consumo, de las corrientes artísticas y gráficas. En 2024, <b>las tendencias</b> en el diseño gráfico <b>están marcadas por estilos visuales impactantes que intensifican la comunicación del mensaje.</b></p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Colores fluorescentes y vibrantes</h3>
            <p class="md:mb-4 mb-6"><b>La paleta de tonos neón llega no sólo a las pasarelas, también en los proyectos de diseño.</b> Los colores fluorescentes &#8211;con un guiño hacia los años 80&#8211; regresan para usarse con otros menos llamativos para generar contraste. Cuanto más saturado sea el color, mejor.</p>
            <p class="md:mb-8 mb-10">Con el avance en la tecnología en las pantallas, <b>las marcas también llevarán al extremo estos colores vibrantes</b> como el rojo eléctrico de YouTube hasta los tonos ultraluminosos que transmiten emoción y captan la atención del espectador.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Tendencia ecológica</h3>
            <p class="md:mb-4 mb-6">Conviviendo con la tendencia fluorescente, <b>la ecológica propone utilizar elementos naturales, orgánicos, mucho color verde</b> pero también con un concepto sustentable: usar la menor cantidad de tinta para no contaminar.</p>
            <p class="md:mb-8 mb-10">Lo que veremos serán más empaques con materiales reciclados o de papel craft . El diseño es básico y las tintas de colores terrosos. <b>Los stickers impresos le dan naturalidad a la marca.</b></p>
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
                <ContactoVue class="m-3"/>
            </div>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Tendencia experimental</h3>
            <p class="md:mb-8 mb-10">La fotografía y el diseño gráfico van hacia lo experimental: <b>el reto es ir más allá de un proyecto mezclando las técnicas</b> y donde la perspectiva es el foco de atención.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Degradados</h3>
            <p class="md:mb-8 mb-10">Hoy, <b>no importa si los colores combinan o no,</b> siempre cuando estos colores se vean bien en su conjunto. Por ello, los efectos de iluminación y degradados, pues le confieren profundidad y dimensión a los diseños.</p>
            <img src="/images/tendencia-diseno-grafico-2024-estilo-burbuja-sans-serif-groovy.webp" alt="Las tendencias del Diseño Gráfico 2024 aportan frescura y originalidad" class="md:mb-8 mb-10">
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Textos estilo burbuja</h3>
            <p class="md:mb-8 mb-10">Esta combinación de fuentes como manuscrita, <b>Sans- Serif y Groovy, experimentan su renacimiento</b> en este año, ya que <b>refleja la mentalidad individualista de la generación z,</b> aportando frescura y autenticidad que desafía las normas establecidas.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Bento Layout</h3>
            <p class="md:mb-8 mb-10">Esta tendencia del diseño gráfico <b>está inspirada en las cajas de comida japonesas,</b> ya que este enfoque se basa en organizar de manera clara la información. Apple se ha sumado a esta tendencia en sus nuevos productos.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Inspiración UI Design </h3>
            <p class="md:mb-8 mb-10">El diseño de interfaz de usuario (UI) nos lleva hacia el utilitarismo visual. <b>Este enfoque prioriza la funcionalidad sobre la estética,</b> al revelar el proceso de diseño de las cuadrículas y layouts, sin adornos y una atención al propósito antes que a las formas. Es muy limpio y directo.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Maximalismo</h3>
            <p class="md:mb-8 mb-10">Llega para dar un respiro al minimalismo esta tendencia del diseño gráfico: más colores, más expresión individual. <b>Una estética de excesos que contrasta contra lo simple,</b> desafía la norma y da rienda suelta a la creatividad sin restricciones. Los colores brillantes, patrones llamativos y abundancia son el antídoto para la sobriedad minimalista.</p>
            <img src="/images/tendencia-diseno-grafico-2024-logotipos-hechos-mano-tipografia.webp" alt="Una de las tendencias del Diseño Gráfico en 2024 es personalizar logotipos a mano" class="md:mb-8 mb-10">
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Logotipos hechos a mano</h3>
            <p class="md:mb-8 mb-10">Esta tendencia simplemente <b>se trata de escribir, no importa si queda bonito o deforme, lo importante es que lo escrito tenga personalidad y llame la atención.</b> La tipografía a mano o las ilustraciones vectoriales muy sencillas tienen un toque diferente: cuentan historias auténticas con fuentes personalizadas de las marcas. Añade carácter y autenticidad a cada diseño, valorando más los trazos orgánicos.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Inteligencia artificial y diseño gráfico</h3>
            <p class="md:mb-4 mb-6">La inteligencia artificial y el diseño forman ahora parte del proceso de creación: <b>los diseñadores pueden crear imágenes sorprendentes,</b> como ilustraciones para campañas publicitarias u otros proyectos; provee infinidad de diseños hiperrealistas que se basan en patrones y tendencias identificados en una gran cantidad de datos.</p>
            <p class="md:mb-8 mb-10"><b>Algunas herramientas de diseño gráfico han incorporado algoritmos de inteligencia artificial que son como asistentes virtuales,</b> mismos que pueden ayudar a los diseñadores en la toma de decisiones, siguiendo los análisis de datos y patrones.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Conclusiones</h2>
            <p class="md:mb-8 mb-10">Con esto, cerramos este artículo sobre las tendencias del diseño gráfico para 2024. <b>Que éstas te inspiren en tus proyectos y te den una visión única.</b> Atrévete a romper las reglas y abraza la extravagancia.</p>
            <!-- Aqui va el componente de contacto, no se te olvide hacerlo manolo -->
            <p class=" text-htwo text-left mb-6">Explora más: <a href="https://humanitas.edu.mx/" target="_blank" class="text-htwo">Universidad Humanitas</a></p>
            <p class="text-htwo text-left mb-20">Te puede interesar: <a href="https://www.humanitas.edu.mx/licenciatura-en-diseno-grafico" target="_blank" class="text-htwo">Licenciatura en Diseño Gráfico</a></p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="true" :linkAnt="'/academico/licenciatura/aptitudes-habilidades-para-estudiar-derecho'" :linkSig="'/academico/licenciatura/universidades-sin-examen-admision'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import ContactoVue from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue';
import SigArt from '../../pagesComponents/SigArt.vue';

export default {
    components: { Comentarios, ConComentarios, ContactoVue, LikesVue, ShareP, SigArt},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '020-ART-ACADEMICO-LICENCIATURA-290424',
            ruta: '/academico/licenciatura/tendencias-diseno-grafico-2024',
            title: '27 de marzo Día Mundial del Teatro 2024: celebra la paz y el arte',
            image: 'https://blog.humanitas.edu.mx/images/Costo-y-requisitos-estudiar-maestria.jpg',
            text: 'Explora las principales tendencias de diseño gráfico para 2024. Desde la realidad aumentada hasta la sostenibilidad, descubre cómo influirán en tu carrera y proyectos creativos.'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx/academico/maestria/costo-y-requisitos-estudiar-maestria';

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>