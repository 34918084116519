<script lang="ts">
    import EgresadosVid from './oferta/EgresadosHuma.vue';
    import CarruCasosExito from './oferta/CasosExito.vue';


    export default {
        components: {EgresadosVid, CarruCasosExito},
        data() {
            return {
                currentIndex: 0,
                alumnosEgresados: 0,
                animating: false,
            };
        },
        methods: {
            startAnimation() {
                if (!this.animating) {
                    this.animating = true;
                    let counter = 0;
                    const interval = setInterval(() => {
                        this.alumnosEgresados = counter;
                        counter += 111; // incremento
                        if (counter >= 100000) {
                            clearInterval(interval);
                            this.alumnosEgresados = 100000;
                        }
                    }, 2); // Velocidad
                }
            }
        }
    };
</script>


<template>
    <div class="flex flex-col justify-center" >
        <section id="ventajas" @mouseover.passive="startAnimation" class="flex justify-center mb-8 w-full h-fit" style="background-image: url(../pagesImages/ventajas-BG.jpg); position: relative; background-attachment: fixed; background-position: center; background-repeat: no-repeat; background-size: cover; overflow: hidden; transition: transform 0.3s ease;">
            <div class="flex flex-col w-full justify-center items-center max-w-4xl lg:max-w-[1200px]">
                <div class="flex flex-col container justify-center items-center mx-auto max-w-4xl lg:max-w-6xl my-20 w-full md:w-2/3 lg:w-2/5 text-white">
                    <h1 class="sr-only">Universidad Humanitas</h1>
                    <h2 class=" text-center text-3xl w-full">
                        Ventajas de estudiar en
                    </h2>
                    <h2 class=" text-center text-5xl w-full text-hgold font-['Times'] tracking-widest" style="">
                        Humanitas
                    </h2>
                    <h2 class=" text-center text-2xl w-full">
                        M&aacute;s de {{ alumnosEgresados.toLocaleString('es-MX') }}
                    </h2>
                    <h2 class=" text-center text-2xl  w-full">
                        alumnos y egresados
                    </h2>
                    <div class="flex flex-col justify-center items-center w-full">
                        <div class="flex justify-between items-center w-full">
                            <div class="flex flex-col justify-center items-center w-full">
                                <img  src="../pagesImages/programas.png" class="w-[100px] h-[100px]" alt="Programas">
                                <h2 class="text-xs w-full text-center">
                                    Modelo educativo
                                </h2>
                                <h2 class="text-xs w-full text-center">
                                    por especialidad
                                </h2>
                            </div>
                            <div class="flex flex-col justify-center items-center w-full">
                                <img  src="../pagesImages/excelencia.png" loading="lazy" class="w-[100px] h-[100px]" alt="Excelencia">
                                <h2 class="text-xs w-full text-center">
                                    Excelencia
                                </h2>
                                <h2 class="text-xs w-full text-center">
                                    acad&eacute;mica
                                </h2>
                            </div>
                            <div class="flex flex-col justify-center items-center w-full">
                                <img  src="../pagesImages/instalaciones.png" loading="lazy" class="w-[100px] h-[100px]" alt="Instalaciones">
                                <h2 class="text-xs w-full text-center">
                                    Instalaciones
                                </h2>
                                <h2 class="text-xs w-full text-center">
                                    extraordinarias
                                </h2>
                            </div>
                            <div class="flex flex-col justify-center items-center w-full">
                                <img  src="../pagesImages/costos.png" loading="lazy" class="w-[100px] h-[100px]" alt="Costos">
                                <h2 class="text-xs w-full text-center">
                                    Costos
                                </h2>
                                <h2 class="text-xs w-full text-center">
                                    accesibles
                                </h2>
                            </div>
                        </div>
                        <div class="w-full h-8 border-b-2 border-white"></div>
                        <div class="w-full h-8"></div>
                        <div class="flex justify-between items-center w-full">
                            <div class="flex flex-col justify-center items-center w-full">
                                <img  src="../pagesImages/sep.png" loading="lazy" class="w-[100px] h-[100px]" alt="SEP">
                                <h2 class="text-xs w-full text-center">
                                    Reconocimiento de
                                </h2>
                                <h2 class="text-xs w-full text-center">
                                    validez oficial
                                </h2>
                            </div>
                            <div class="flex flex-col justify-center items-center w-full">
                                <img  src="../pagesImages/esr.png" loading="lazy" class="w-[100px] h-[100px]" alt="ESR">
                                <h2 class="text-xs w-full text-center">
                                    Empresa
                                </h2>
                                <h2 class="text-xs w-full text-center">
                                    Socialmente
                                </h2>
                                <h2 class="text-xs w-full text-center">
                                    Responsable
                                </h2>
                            </div>
                            <div class="flex flex-col justify-center items-center w-full">
                                <img  src="../pagesImages/gtpw.png" loading="lazy" class="w-[100px] h-[100px]" alt="great place to work">
                                <h2 class="text-xs w-full text-center">
                                    Great Place to
                                </h2>
                                <h2 class="text-xs w-full text-center">
                                    Work
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="flex w-full items-center justify-center text-gray-700">
            <div class="flex flex-col w-full justify-center items-center">
                <!-- Licenciaturas -->
                <div class="flex flex-col md:flex-row items-center justify-center mb-10 w-full max-w-4xl lg:max-w-[1200px]">
                    <div class="order-2 md:order-1 flex w-full md:w-1/2 justify-center md:justify-end">
                        <img src="../pagesImages/square-lics.webp" loading="lazy" class="w-auto h-auto" alt="Explora las licenciaturas">
                    </div>
                    <div class="order-1 md:order-2 flex flex-col md:pl-14 px-4 py-7 md:py-0 w-full md:w-1/2 items-center md:items-start">
                        <p class="text-center md:text-left line-height-36 text-4xl text-hgold mb-2 font-serif">Licenciaturas</p>
                        <p class="text-center md:text-left text-[21px] leading-6 font-sans">El punto de partida de todo</p>
                        <p class="text-center md:text-left text-[21px] leading-6 font-sans">logro, es el deseo.</p>
                        <br>
                        <router-link to="/licenciaturas" >
                            <div class="flex items-center justify-center w-[140px] h-[35px] bg-hwine hover:bg-hgold text-white text-lg transition duration-300 ease-in-out">
                                <span>Explorar</span>
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="flex items-center justify-center">
                    <img src="/pagesImages/adorno.png" loading="lazy" class="w-auto h-auto" alt="45 aniversario" />
                </div>
                
                <!-- Maestrías -->
                <div class="flex flex-col md:flex-row items-center justify-center my-10 w-full max-w-4xl lg:max-w-[1200px]">
                    <div class="flex flex-col md:pr-14 px-4 pb-7 md:pb-0 w-full md:w-1/2 items-center md:items-end">
                        <p class="text-center md:text-right line-height-36 text-4xl text-hgold mb-2 font-serif">Maestr&iacute;as</p>
                        <p class="text-center md:text-right text-[21px] leading-6 font-sans">El &eacute;xito depende de la preparaci&oacute;n</p>
                        <p class="text-center md:text-right text-[21px] leading-6 font-sans">previa y sin ella, seguro que llega el</p>
                        <p class="text-center md:text-right text-[21px] leading-6 font-sans">fracaso.</p>
                        <br>
                        <router-link to="/maestrias" >
                            <div class="flex items-center justify-center w-[140px] h-[35px] bg-hwine hover:bg-hgold text-white text-lg transition duration-300 ease-in-out">
                                <span>Leer m&aacute;s...</span>
                            </div>
                        </router-link>
                    </div>
                    <div class="flex w-full md:w-1/2 justify-center md:justify-start">
                        <img src="../pagesImages/square-maes.webp" loading="lazy" class="w-auto h-auto" alt="Conoce las mestrías">
                    </div>
                </div>
                <div class="flex items-center justify-center">
                    <img src="/pagesImages/adorno.png" loading="lazy" class="w-auto h-auto" alt="45 aniversario" />
                </div>

                <!-- Doctorados -->
                <div class="flex flex-col md:flex-row items-center justify-center mt-10 w-full max-w-4xl lg:max-w-[1200px]">
                    <div class="order-2 md:order-1 flex w-full md:w-1/2 justify-center md:justify-end">
                        <img src="../pagesImages/square-docs.webp" loading="lazy" class="w-auto h-auto" alt="Conoce los doctorados">
                    </div>
                    <div class="order-1 md:order-2 flex flex-col md:pl-14 px-4 pb-7 md:pb-0 w-full md:w-1/2 items-center md:items-start">
                        <p class="text-center md:text-left line-height-36 text-4xl text-hgold mb-2 font-serif">Doctorados</p>
                        <p class="text-center md:text-left text-[21px] leading-6 font-sans">Identifica tus problemas, pero pon tu</p>
                        <p class="text-center md:text-left text-[21px] leading-6 font-sans">poder y energ&iacute;a en las soluciones.</p>
                        <br>
                        <router-link to="/doctorados" >
                            <div class="flex items-center justify-center w-[140px] h-[35px] bg-hwine hover:bg-hgold text-white text-lg transition duration-300 ease-in-out">
                                <span>Con&oacute;celos</span>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </section>

        <br>
        <br>

        <EgresadosVid class="mb-12"/>
        <CarruCasosExito/>
    </div>
</template>


<style scoped>
    
</style>