<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">¿Qué es un acto administrativo? <br class="md:block hidden">Concepto, clasificación y ejemplos</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-200">
          <img src="/images/que-es-acto-administrativo-concepto-ejemplos.webp" alt="El acto administrativo es esencial para la administración pública y para los ciudadanos" class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tigh text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/academico" class=" text-hone">Académico</router-link> > <router-link to="/blog/academico/licenciatura" class=" text-hone">Licenciatura</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex text-left list-disc pl-5 italic text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">06 de Junio de 2024</li>  <li>Carolina Reyes Martínez</li></ul>
            </div>
            <p class="md:mb-4 mb-6"><b>Extracto:</b> Aprende sobre el acto administrativo, desde su concepto hasta su clasificación, y cómo los actos administrativos impactan en el ámbito legal. Este artículo es ideal para universitarios y futuros estudiantes de maestrías en Derecho.</p>
            <p class="md:mb-4 mb-6"><b>Introducción</b><br> En el ámbito del Derecho Administrativo, <b>entender qué es un acto administrativo es esencial.</b> Estos actos son cruciales para el funcionamiento de la administración pública y tienen un impacto en la vida de los ciudadanos.</p>
            <p class="md:mb-8 mb-10"><b>Te ayudamos con esta guía a comprender los actos administrativos,</b> su concepto, clasificación, así como ejemplos prácticos.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">¿Qué es un acto administrativo?</h2>
            <p class="md:mb-8 mb-10"><b>Un acto administrativo es una manifestación de los poderes públicos</b> de un Estado, es una declaración de las facultades administrativas para imponer su voluntad o autoridad administrativa en ejercicio de sus funciones, destinada a producir efectos jurídicos individuales y concretos. Estos actos son esenciales para la administración pública, ya que a través de ellos se ejecutan las leyes y se administran los recursos y servicios públicos.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Características de los actos administrativos</h2>
            <ul class="list-decimal pl-6 md:mb-8 mb-10">
                <li><b>Legalidad:</b> Deben estar basados en la ley y ajustarse a ella.</li>
                <li><b>Finalidad:</b> Tienen un objetivo de interés público.</li>
                <li><b>Procedimiento:</b> Se deben seguir procedimientos establecidos por la normativa.</li>
                <li><b>Formalidad:</b> Necesitan cumplir con ciertas formalidades para ser válidos.</li>
                <li><b>Ejecutoriedad:</b> Tienen la capacidad de ser ejecutados de manera inmediata, incluso contra la voluntad del afectado, salvo en casos donde se suspenda su ejecución.</li>
            </ul>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Clasificación de los actos administrativo</h2>
            <p class="md:mb-8 mb-10">Los actos administrativos <b>se pueden clasificar de diversas maneras según su naturaleza, contenido y procedimiento</b> Esta clasificación es importante para entender mejor cómo funcionan y cómo se aplican en distintos contextos legales.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Según su naturaleza</h3>
            <ul class="list-decimal pl-6 md:mb-8 mb-10">
                <li><b>Actos reglamentarios:</b> Son aquellos que establecen normas de carácter general, abstracto e impersonal. Ejemplo: Un reglamento emitido por una autoridad administrativa.</li>
                <li><b>Actos individuales:</b> Se dirigen a personas concretas y producen efectos particulares. Ejemplo: La concesión de una licencia de construcción.</li>
            </ul>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Según su contenido</h3>
            <ul class="list-decimal pl-6 md:mb-8 mb-10">
                <li><b>Actos favorables:</b> Aquellos que otorgan un beneficio o derecho al destinatario. Ejemplo: Una autorización para el uso de un bien público.</li>
                <li><b>Actos degravatorios:</b> Imponen obligaciones o limitaciones al destinatario. Ejemplo: Una sanción administrativa por incumplimiento de normativas.</li>
            </ul>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Según su procedimiento</h3>
            <ul class="list-decimal pl-6 md:mb-8 mb-10">
                <li><b>Actos simples:</b> Emitidos por una sola autoridad administrativa.</li>
                <li><b>Actos complejos:</b> Requieren la intervención de varias autoridades para su emisión. Ejemplo: Un acto que necesita aprobación de diferentes departamentos dentro de una entidad pública.</li>
            </ul>
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
                <ContactoVue class="m-3"/>
            </div>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Clasificación de los actos administrativo</h2>
            <p class="md:mb-4 mb-6">Una resolución administrativa es un tipo específico de acto administrativo que <b>se caracteriza por ser una decisión formal emitida por una autoridad administrativa en respuesta</b> a una petición o procedimiento.</p>
            <p class="md:mb-8 mb-10">Estas resoluciones son fundamentales en el funcionamiento de la Administración Pública y tienen un impacto directo en la vida de los ciudadanos.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Tendencia experimental</h3>
            <ul class="list-decimal pl-6 md:mb-8 mb-10">
                <li><b>Formalidad:</b> Debe cumplir con ciertos requisitos formales para ser válida, como la firma de la autoridad competente.</li>
                <li><b>Motivación:</b> Debe estar debidamente motivada, es decir, fundamentada en hechos y en derecho.</li>
                <li><b>Notificación:</b> Debe ser notificada a los interesados para que produzca efectos jurídicos.</li>
            </ul>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[19px] mb-3">Ejemplos comunes</h4>
            <ul class="list-decimal pl-6 md:mb-8 mb-10">
                <li><b>Licencias:</b> Como las licencias de apertura de un negocio.</li>
                <li><b>Autorizaciones:</b> Como las autorizaciones para realizar eventos públicos.</li>
                <li><b>Sanciones:</b> Como multas por infracciones administrativas.</li>
            </ul>
            <img src="/images/clasificacion-actos-administrativos.webp" alt="La clasificación de los actos administrativos ayuda a entender en qué contextos deben ser aplicados" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Procedimiento para emitir un acto administrativo</h2>
            <p class="md:mb-8 mb-10">El procedimiento para emitir un acto administrativo <b>está regulado por la normativa vigente y debe seguir ciertos pasos para asegurar su validez y legitimidad.</b> Este procedimiento es crucial para garantizar que los actos administrativos se emitan de manera justa y transparente.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Pasos y requisitos</h3>
            <ul class="list-decimal pl-6 md:mb-8 mb-10">
                <li><b>Iniciación:</b> Puede iniciarse de oficio o a solicitud del interesado.</li>
                <li><b>Instrucción:</b> Fase en la que se recaban pruebas y se realizan las actuaciones necesarias para dictar el acto.</li>
                <li><b>Resolución:</b> Emisión del acto administrativo por la autoridad competente.</li>
                <li><b>Notificación:</b> Comunicación del acto a los interesados para que surta efectos.</li>
            </ul>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Ejemplos prácticos</h3>
            <ul class="list-decimal pl-6 md:mb-8 mb-10">
                <li><b>Solicitud de licencia:</b> Procedimiento para solicitar y obtener una licencia de construcción.</li>
                <li><b>Proceso sancionador:</b> Procedimiento para la imposición de una sanción administrativa por incumplimiento de normativas.</li>
            </ul>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Importancia de los actos administrativos en el Derecho público</h2>
            <p class="md:mb-8 mb-10">Los actos administrativos juegan un rol determinante, ya que permiten a las autoridades administrativas cumplir con sus funciones y ejecutar las leyes de manera efectiva. Estos actos son esenciales para la administración pública y tienen un impacto directo en la vida de los ciudadanos.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Rol en la Administración Pública</h3>
            <ul class="list-decimal pl-6 md:mb-8 mb-10">
                <li><b>Ejecución de políticas:</b> Los actos administrativos permiten la implementación de políticas públicas a nivel local, regional y nacional.</li>
                <li><b>Regulación y control:</b> Ayudan a regular actividades económicas y sociales, asegurando el cumplimiento de las leyes.</li>
                <li><b>Prestación de servicios:</b> Facilitan la entrega de servicios públicos a la ciudadanía, como educación, salud y seguridad.</li>
            </ul>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Impacto en la vida diaria de los ciudadanos</h3>
            <ul class="list-decimal pl-6 md:mb-8 mb-10">
                <li><b>Acceso a servicios:</b> Los actos administrativos como licencias y autorizaciones permiten a los ciudadanos acceder a diversos servicios y beneficios.</li>
                <li><b>Protección de derechos:</b> A través de resoluciones y sanciones, protegen los derechos de los ciudadanos y aseguran el cumplimiento de normativas.</li>
                <li><b>Transparencia y legalidad:</b> Garantizan que las actuaciones de la administración pública se realicen de acuerdo con la ley y de manera transparente.</li>
            </ul>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Diferencias entre acto administrativo y acto legislativo</h2>
            <p class="md:mb-8 mb-10">Es importante distinguir entre los actos administrativos y los actos legislativos, ya que ambos tienen características y funciones diferentes en el ámbito del Derecho.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Definiciones y ejemplos</h3>
            <ul class="list-decimal pl-6 md:mb-8 mb-10">
                <li><b>Acto administrativo:</b> Es una declaración de voluntad de una autoridad administrativa con efectos jurídicos individuales y concretos. Ejemplo: La concesión de una licencia de obra.</li>
                <li><b>Acto legislativo:</b> Es una norma jurídica general y abstracta dictada por un órgano legislativo. Ejemplo: Aprobación de una ley.</li>
            </ul>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Principales diferencias</h3>
            <ul class="list-decimal pl-6 md:mb-8 mb-10">
                <li><b>Emisor:</b> Los actos administrativos son emitidos por autoridades administrativas, mientras que los actos legislativos son dictados por órganos legislativos.</li>
                <li><b>Alcance:</b> Los actos administrativos tienen efectos jurídicos individuales y concretos, mientras que los actos legislativos tienen efectos generales y abstractos.</li>
                <li><b>Procedimiento:</b> Los actos administrativos siguen procedimientos administrativos, mientras que los actos legislativos siguen el procedimiento legislativo establecido.</li>
            </ul>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Ejemplos de actos administrativos</h2>
            <p class="md:mb-8 mb-10">Conocer ejemplos concretos de actos administrativos ayuda a entender mejor cómo funcionan y su aplicación en la vida real.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Casos prácticos</h3>
            <ul class="list-decimal pl-6 md:mb-8 mb-10">
                <li><b>Licencias:</b> Ejemplo de una licencia de construcción otorgada por una municipalidad.</li>
                <li><b>Autorizaciones:</b> Ejemplo de una autorización para la realización de un evento público.</li>
                <li><b>Sanciones:</b> Ejemplo de una multa impuesta por una infracción de tránsito.</li>
            </ul>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Análisis de situaciones reales</h3>
            <ul class="list-decimal pl-6 md:mb-8 mb-10">
                <li><b>Licencia de construcción:</b> Proceso y requisitos para obtener una licencia de construcción, y las consecuencias de no cumplir con las normativas.</li>
                <li><b>Autorización de evento público:</b> Procedimiento para obtener la autorización y las responsabilidades del organizador.</li>
                <li><b>Imposición de multa:</b> Proceso de notificación y recursos disponibles para impugnar una multa administrativa.</li>
            </ul>
            <img src="/images/acto-administrativo-pilar-derecho-y-administracion-publica.webp" alt="Los actos administrativos tienen efectos jurídicos individuales y concretos" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Consecuencias de la nulidad de un acto administrativo</h2>
            <p class="md:mb-8 mb-10">La nulidad de un acto administrativo es una situación en la que dicho acto carece de efectos jurídicos debido a la existencia de vicios o defectos graves. Es fundamental comprender las causas, el procedimiento y los efectos legales de la nulidad.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Causas de nulidad</h3>
            <ul class="list-decimal pl-6 md:mb-8 mb-10">
                <li><b>Falta de competencia:</b> Cuando la autoridad que emite el acto no tiene la competencia legal para hacerlo.</li>
                <li><b>Violación de la ley:</b> Cuando el acto se dicta en contra de lo establecido por la ley.</li>
                <li><b>Deficiencias en el procedimiento:</b> Cuando no se siguen los procedimientos administrativos obligatorios.</li>
            </ul>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Procedimiento de anulación</h3>
            <ul class="list-decimal pl-6 md:mb-8 mb-10">
                <li><b>Solicitud de revisión:</b> El afectado puede solicitar la revisión del acto ante la misma autoridad que lo dictó o una superior.</li>
                <li><b>Recurso administrativo:</b> Se puede interponer un recurso administrativo para solicitar la nulidad del acto.</li>
                <li><b>Acción judicial:</b> En caso de no obtener respuesta favorable, se puede acudir a la vía judicial.</li>
            </ul>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Efectos legales</h3>
            <ul class="list-decimal pl-6 md:mb-8 mb-10">
                <li><b>Retroactividad:</b> La nulidad implica que el acto nunca tuvo efectos jurídicos.</li>
                <li><b>Restitución:</b> Las partes afectadas deben ser restituidas a su situación anterior al acto.</li>
                <li><b>Responsabilidad administrativa:</b> La autoridad que dictó el acto nulo puede incurrir en responsabilidad administrativa.</li>
            </ul>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Recursos contra los actos administrativos</h2>
            <p class="md:mb-8 mb-10">Los ciudadanos tienen derecho a impugnar los actos administrativos que consideren lesivos de sus derechos o que adolezcan de vicios de legalidad. Existen varios tipos de recursos que pueden interponerse.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Tipos de recursos</h3>
            <ul class="list-decimal pl-6 md:mb-8 mb-10">
                <li><b>Recurso de alzada:</b> Se presenta ante el superior jerárquico de la autoridad que dictó el acto.</li>
                <li><b>Recurso de reposición:</b> Se interpone ante la misma autoridad que emitió el acto, solicitando su revisión.</li>
                <li><b>Recurso extraordinario de revisión:</b> Procede contra actos firmes en casos excepcionales, como el descubrimiento de documentos esenciales desconocidos en el momento de dictarse el acto.</li>
            </ul>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Procedimiento de interposición</h3>
            <ul class="list-decimal pl-6 md:mb-8 mb-10">
                <li><b>Presentación de recurso:</b> El interesado debe presentar el recurso dentro del plazo establecido por la ley.</li>
                <li><b>Motivación:</b> El recurso debe estar debidamente motivado, indicando las razones por las que se considera que el acto es ilegal o lesivo.</li>
                <li><b>Resolución:</b> La autoridad competente debe resolver el recurso dentro del plazo legal, notificando al interesado</li>
            </ul>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Conclusiones</h2>
            <p class="md:mb-4 mb-6">Los actos administrativos son decisivos para el funcionamiento de la administración pública y tienen una influencia significativa en la vida de los ciudadanos.</p>
            <p class="md:mb-8 mb-10">Conocer su concepto, clasificación y procedimiento es esencial para estudiantes y profesionales del Derecho por dos razones:</p>
            <!-- Aqui va el componente de contacto, no se te olvide hacerlo manolo -->
            <ul class="list-disc pl-6 mb-20">
                <li><b>Para estudiantes:</b> Es crucial para universitarios y futuros estudiantes de maestrías en Derecho, ya que forma parte del núcleo del Derecho Administrativo.</li>
                <li><b>Para profesionales:</b> Los profesionales del Derecho deben estar familiarizados con los actos administrativos para asesorar correctamente a sus clientes y gestionar los procedimientos correctamente.</li>
            </ul>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="true" :linkAnt="'/academico/licenciatura/tipos-de-contabilidad'" :linkSig="'/academico/estudia-en-linea/licenciatura-en-derecho-virtual'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import ContactoVue from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue';
import SigArt from '../../pagesComponents/SigArt.vue';

export default {
    components: { Comentarios, ConComentarios, ContactoVue, LikesVue, ShareP, SigArt},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '0039-ART-ACADEMICO-LICENCIATURA-060624',
            ruta: '/academico/licenciatura/que-es-un-acto-administrativo',
            title: '¿Qué es un acto administrativo? Guía para entenderlo',
            image: 'https://blog.humanitas.edu.mx/images/Costo-y-requisitos-estudiar-maestria.jpg',
            text: 'Entender qué es un acto administrativo es muy importante, ya que es esencial para el funcionamiento de la administración pública e impacta en la vida de los ciudadanos. '
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx/academico/maestria/costo-y-requisitos-estudiar-maestria';

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>