<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">Responsabilidad Social Individual: Descubre <br class="md:block hidden"> qué es e inspírate</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-200">
          <img src="/images/responsabilidad-social-individual-transforma-entorno.webp" alt="La Responsabilidad Social Individual: una manera de transformar tu entorno" class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/ser-humanitas" class=" text-hone">Ser Humanitas</router-link> > <router-link to="/blog/ser-humanitas/responsabilidad-social" class=" text-hone">Responsabilidad Social</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex text-left list-disc pl-5 italic text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">03 de junio de 2024</li>  <li>Carolina Reyes Martínez</li></ul>
            </div>
            <p class="md:mb-4 mb-6">En este artículo, te explicamos qué es la Responsabilidad Social Individual, los tipos que existen y ejemplos para inspirarte a ser un agente de cambio en tu comunidad.</p>
            <p class="md:mb-8 mb-10">Cada acción que tomamos puede tener un impacto significativo en nuestra comunidad y en el medio ambiente. Pero, <b>¿qué significa realmente ser socialmente responsable a nivel personal?</b> En este artículo, te explicamos qué es, los tipos que existen y ejemplos para que puedas hacer una diferencia en tu entorno.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">¿Qué es la Responsabilidad Social?</h2>
            <p class="md:mb-8 mb-10">Primero, es importante que sepas que la <b>Responsabilidad Social es el compromiso que se tiene con la sociedad y su bienestar.</b> Una de las formas más comunes es la <router-link to="/blog/ser-humanitas/responsabilidad-social/acciones-socialmente-responsables"  class="text-htwo">Responsabilidad Social Empresarial</router-link> (RSE) y se trata de restituir a la sociedad los beneficios que las organizaciones obtienen; es una perspectiva ética y legal que se puede aplicar en la gestión de las empresas y de las instituciones.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Tipos de Responsabilidad Social</h3>
            <p class="md:mb-4 mb-6">Algunos autores consideran que hay dos tipos:</p>
            <ul class="list-disc pl-6 md:mb-8 mb-10">
                <li><b>Responsabilidad positiva o proactiva:</b> Cuando <b>se obliga a actuar para mejorar la sociedad,</b> por ejemplo, si una empresa apoya a una comunidad brindando espacios o ayuda para construir casas en ese lugar pero para contribuir con el entorno, no sólo para promocionarse. Es una acción ética.</li>
                <li><b>Responsabilidad negativa o de abstención:</b> Es cuando <b>no se interviene para no modificar al entorno,</b> por ejemplo, una empresa no tira sus desechos a los ríos para que éstos se preserven limpios.</li>
            </ul>
            <img src="/images/responsabilidad-social-individual-recicla-reusa.webp" alt="La Responsabilidad Social Individual implica acciones para proteger al medio ambiente" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">¿Qué es la Responsabilidad Social Individual?</h2>
            <p class="md:mb-4 mb-6">En tanto, <b>la Responsabilidad Social Individual es aquella que tenemos como personas,</b> desde nuestra trinchera y es un compromiso <b>para actuar en bienestar de la sociedad.</b> Ya sea como hijos, familiares, compañeros de escuela, de trabajo, vecinos, todos con nuestras acciones, repercutimos en las personas y en la sociedad.</p>
            <p class="md:mb-8 mb-10"><b>No sólo es seguir las leyes y las normas, también es entender cómo lo que hacemos impacta al medio ambiente y a los demás.</b> Cada uno de nosotros podemos ayudar a construir una sociedad más sostenible y equitativa. Una forma de entender esto es preguntándote: ¿Qué puedo hacer para mejorar donde vivo, con las personas cercanas? ¿Cómo hacer un cambio?</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Los tipos de la Responsabilidad Social Individual</h2>
            <p class="md:mb-8 mb-10">Hay varios tipos que podemos incorporar en nuestra rutina:</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Responsabilidad ambiental</h3>
            <p class="md:mb-8 mb-10">Ésta implica acciones y decisiones que preservan y protegen el medio ambiente. Es el grado de compromiso que tenemos con nuestro entorno.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Responsabilidad económica</h3>
            <p class="md:mb-8 mb-10">Se refiere a la administración ética de nuestros recursos financieros y prácticas justas de negocios.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Responsabilidad comunitaria</h3>
            <p class="md:mb-8 mb-10">Se centra en <b>contribuir positivamente a la sociedad y a nuestras comunidades.</b> Participar en actividades de voluntariado, donar a causas benéficas y apoyar a los más necesitados son formas de ejercer esta responsabilidad.</p>
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
                <ContactoVue class="m-3"/>
            </div>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Ejemplos de Responsabilidad Social Individual</h2>
            <p class="md:mb-4 mb-6">Aquí te damos tres ejemplos de cómo puedes hacer un cambio y contribuir a tu entorno:</p>
            <ul class="list-decimal pl-6 md:mb-8 mb-10">
                <li><b>Reduce y recicla.</b> Puedes reducir el uso de plásticos al llevar tu propia botella de agua, o usar una bolsa de tela si vas al súper, evitar los productos de plástico de un solo uso. Recicla los envases de la leche, las botellas de cristal; puedes comprar ropa de segunda mano para ahorrar. Poco a poco puedes lograr un cambio.</li>
                <li><b>Participar en programas de voluntariado.</b> Ésta es una excelente manera de contribuir, ya sea ayudando en un comedor social, participando en campañas de limpieza o <a href="https://humanitas.edu.mx/responsabilidad" target="_blank" class="text-htwo">apoyando a organizaciones sin fines de lucro</a>, cada esfuerzo cuenta.</li>
                <li><b>Apoyar al comercio justo.</b> Comprar a comerciantes locales, conocer de dónde vienen los productos, conocer los procesos por los cuales tuvieron que pasar. Es una compra hecha de manera consciente y de respeto para los productores. Esto fomenta la sostenibilidad.</li>
            </ul>
            <img src="/images/responsabilidad-social-y-voluntariado-humanitas.webp" alt="Participar en programas de voluntariado es una gran manera de contribuir con la sociedad" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Conclusión</h2>
            <p class="mb-20"><b>La responsabilidad social individual es necesaria para construir una sociedad más justa y sostenible.</b> Cada pequeño esfuerzo cuenta y puede tener un gran impacto. Al adoptar prácticas responsables en la vida diaria, no sólo mejoramos nuestra calidad de vida, <b>también contribuimos al bienestar de nuestra comunidad y del planeta.</b> así como organizando actividades afines a sus áreas de interés.Sé parte del cambio.</p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArtVue :ant="true" :linkAnt="'/recursos/entrevistas-y-conferencias/inclusion-diversidad-en-derecho-por-mariel-cabanas'" :linkSig="'recursos/entrevistas-y-conferencias/caracteristicas-de-la-mediacion'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import ContactoVue from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue';
import SigArtVue from '../../pagesComponents/SigArt.vue';
import VideoClipVue from '../../pagesComponents/videoClip.vue';

export default {
    components: { Comentarios, ConComentarios, ContactoVue, LikesVue, ShareP, VideoClipVue, SigArtVue},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '0034-ART-SER-HUMANITAS-RESPONSABILIDAD-SOCIAL-030624',
            ruta: '/ser-humanitas/responsabilidad-social/responsabilidad-social-individual-tipos-ejemplos-inspiracion',
            title: 'Responsabilidad Social Individual: Descubre qué es e inspírate',
            image: 'https://blog.humanitas.edu.mx/images/Costo-y-requisitos-estudiar-maestria.jpg',
            text: 'Explora qué es la Responsabilidad Social Individual, qué  tipos existen y ejemplos. Aprende cómo puedes marcar la diferencia en tu comunidad y entorno.'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx/academico/maestria/costo-y-requisitos-estudiar-maestria';

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>