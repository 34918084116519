<script setup lang="ts">
</script>

<template>
    <div class="flex items-center justify-center">
        <img src="../pages/pagesImages/adorno.png" alt="45 aniversario" class="my-10">
    </div>
</template>

<style scoped>
</style>