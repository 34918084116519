<template>
    <div>
      <CataBueno :titulo="'Académico'" :subtitulo="'Maestría'" :filtros="filtros" :articles="articles"/>
    </div>
</template>
  
  <script lang="js">
  import CataBueno from '../pagesComponents/CataBueno.vue';
  
  export default {
    components: { CataBueno },
    data() {
      return {
        articles: [
          {
            "id": "0031-ART-ACADEMICO-MAESTRIA-230524",
            "titulo": "Derecho corporativo empresarial: aspectos fundamentales",
            "fecha": "23 de mayo de 2024",
            "autor": "Iván Solís Rivera",
            "descripcion": "El Derecho Corporativo Empresarial es una área jurídica especializada que conjuga diversas áreas de conocimiento, conócelas.",
            "portada": "/images/derecho-corporativo-empresarial-mercantil.webp",
            "enlace": "/academico/maestria/derecho-corporativo-empresarial"
          },
          {
            "id": "019-ART-ACADEMICO-MAESTRIA-260424",
            "titulo": "Maestría en Psicología: Guía completa",
            "fecha": "24 de abril de 2024",
            "autor": "Iván Solís Rivera",
            "descripcion": "Describimos todo lo que necesita saber de la Maestría en Psicología, cuyo principal objetivo es fortalecer habilidades y conocimientos en terrenos prácticos y teóricos.",
            "portada": "/images/A-maestria-psicologia-tipos-mente-humana.jpg",
            "enlace": "/academico/maestria/maestria-psicologia-guia-completa"
          },
          {
            "id": "02-ART-ACADEMICO-MAESTRIA-240124",
            "titulo": "Costo y requisitos para estudiar una maestría: entérate",
            "fecha": "24 de enero de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Eleva tus oportunidades laborales y alcanza tus metas profesionales. Te orientamos sobre el costo y los requisitos para estudiar una maestría.",
            "portada": "/images/Costo-y-requisitos-estudiar-maestria.jpg",
            "enlace": "/academico/maestria/costo-y-requisitos-estudiar-maestria"
          },
        ], // Array de artículos original
        filtros: {
          "0031-ART-ACADEMICO-MAESTRIA-230524": {
            Humanidades: [],
            Ciencias_sociales: ["Derecho"],
            Economico_administrativas: []
          },
          "019-ART-ACADEMICO-MAESTRIA-260424": {
            Humanidades: [],
            Ciencias_sociales: ["Derecho", "Ciencias Políticas", "Administración Pública"],
            Economico_administrativas: []
          },
          "02-ART-ACADEMICO-MAESTRIA-240124": {
            Humanidades: ["Educación", "Psicología"],
            Ciencias_sociales: ["Derecho"],
            Economico_administrativas: ["Administración", "Contabilidad"]
          },
        },
        filtrosSeleccionados: null, // Lista de filtros seleccionados
      };
    },
    methods: {
    }
  };
  </script>
  
  <style>
  /* Estilos CSS si los tienes */
  </style>