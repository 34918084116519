<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">Más allá del título: descubre cómo un<br class="md:block hidden"> diplomado transforma tu carrera</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-300">
          <img src="/images/Como-impacta-tu-vida-profesional-un-diplomado.jpg" class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/academico" class=" text-hone">Académico</router-link> > <router-link to="/blog/academico/educacion-continua" class=" text-hone">Educación continua</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex list-disc pl-5 italic text-left text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">20 de febrero de 2024</li>  <li>Carolina Reyes Martínez/Redacción Humanitas</li></ul>
            </div>
            <p class=" md:mb-4 mb-6"><b>Extracto:</b> Además del título universitario, <b>mantenerte al día en tu carrera es la clave</b> para crecer profesionalmente. Te decimos cómo esta opción académica te ayudará y conoce sus ventajas.</p>
            <p class=" md:mb-8 mb-10"><b>Los cambios constantes hacen necesaria la actualización.</b> Los cursos, seminarios y talleres, forman parte de la educación continua, pero nos enfocaremos en esta <b>alternativa,</b> si te interesa complementar tu formación, ampliar tus habilidades y conocimientos, o simplemente si sólo eres una persona que no se limita para seguir estudiando.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">¿Qué es y qué lo hace distinto y valioso?</h2>
            <p class=" md:mb-4 mb-6"><b>Un diplomado es un programa académico intensivo que se organiza por módulos de una disciplina.</b> Está <b>diseñado para actualizar tus habilidades</b> y profundizar en temas muy específicos de tu profesión, pero en un tiempo menor que si cursaras una maestría o un doctorado. Es de corta duración: algunos meses o máximo un año.</p>
            <p class=" md:mb-4 mb-6">No es necesario que realices un trabajo de investigación como una tesis, pero sí puedes presentar exámenes o hacer tareas para reafirmar lo aprendido. Las clases las imparten distintos docentes pero siempre hay una persona que coordina las actividades en la institución educativa que elijas.</p>
            <p class=" md:mb-4 mb-6">Su principal diferenciador de otros tipos de formación, es que <b>te ofrece la teoría aplicada y la práctica en tu vida laboral.</b> Esto lo hace muy valioso ya que te da herramientas técnicas indispensables que impactarán tu carrera profesional para impulsarla.</p>
            <p class=" md:mb-8 mb-10"><b><a href="https://humanitas.edu.mx/educacion-continua" target="_blank" class="text-htwo">Las universidades públicas o privadas,</a> desarrollan estos programas cuando identifican cambios importantes en las diversas profesiones.</b> Por ejemplo, en administración de empresas, derecho, psicología, medicina, odontología, mercadotecnia y otras más, constantemente se ofertan diplomados, ya que son áreas que cambian frecuentemente.</p>
            <!-- Aqui va el componente de contacto, no se te olvide hacerlo manolo -->
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
              <Contacto class="m-3"/>
            </div>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">¿Por qué estudiar un diplomado?</h2>
            <p class=" md:mb-8 mb-10">En primer lugar, <b>si eres una persona a la que le gusta la capacitación, un diplomado es para ti.</b> Si egresaste de tu carrera y ya estás trabajando, también es una alternativa que <b>te permitirá la actualización constante y oportunidades laborales.</b> Pero un diplomado requiere de disciplina, pues dependiendo de la institución en la que estudies, debes invertir tiempo para comprender cada tema propuesto por tus maestros.</p>
            <img src="/images/Por-que-estudiar-un-diplomado.jpg" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">¿Cómo impactará el diplomado a tu carrera?</h2>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Conoce las ventajas de estudiarlo:</h3>
            <ul class="list-decimal pl-6 md:mb-8 mb-10">
                <li><b>Adquirirás conocimientos avanzados y habilidades.</b> Aprendes nuevas técnicas, te actualizarás en herramientas y procedimientos que te brindarán nuevos skills en tu desarrollo cognitivo, social y motriz, pues tu mente estará analizando todo lo que tus maestros te enseñarán. Renueva tu currículum y nútrelo con un diplomado.</li>
                <li><b>Te abrirás a un mundo de posibilidades.</b> La interacción con otros estudiantes y maestros, te brindará otras perspectivas y crecerás profesional y culturalmente.</li>
                <li><b>Conocerás a nuevas personas.</b> Fortalecerás tu red de contactos pero también compartirás nuevas experiencias con colegas con intereses afines y en el futuro, incluso podrías hacer proyectos y alianzas.</li>
                <li><b>Ventajas competitivas.</b> El diplomado te impulsa en tu área laboral, pues lo aprendido lo puedes aplicar dándole un plus a tu carrera creando soluciones y nuevos proyectos.</li>
                <li><b>Formación en poco tiempo.</b> Es ideal para quienes quieran una especialización en algunos meses o hasta en un año.</li>
                <li><b>Realizarás una inversión menor.</b> Sí debes considerar que la mayoría de los diplomados son de pago, pero el costo no es el mismo que el de una maestría o un doctorado, el diplomado es más accesible. Incluso, si estás en una empresa en la que la capacitación es muy importante, puedes solicitar apoyo para estudiar un diplomado y lo aprendido, aplicarlo en tu día a día.</li>
            </ul>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">¿Cómo elegir el diplomado adecuado para ti?</h2>
            <p class=" md:mb-4 mb-6">Es muy importante que primero tengas claro el objetivo: si quieres hacer un diplomado para avanzar en tu trabajo o bien para actualizarte. Una vez que tengas la respuesta, investiga en qué universidades o instituciones tienen el diplomado de tu interés; revisa también la validez oficial del diplomado, de la escuela en donde lo harás o plataforma, ya que además de diplomados presenciales, existen los diplomados en línea.</p>
            <p class=" md:mb-8 mb-10">Revisa el enfoque de la enseñanza, ya que habrá algunos diplomados más teóricos, otros teóricos y también prácticos, o bien sólo prácticos para que lo aprendido lo integres rápidamente a tu área.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">El diplomado: un compromiso con tu desarrollo personal</h2>
            <p class=" md:mb-8 mb-10">En un mercado laboral que no se detiene, el verdadero impacto de un diplomado se encuentra en darte los conocimientos, competencias y habilidades que necesitas para impulsar no sólo tu carrera, también tu desarrollo personal. Invierte en tu futuro con un diplomado.</p>
            <p class="text-htwo mb-6">Explora más: <a href="https://humanitas.edu.mx/educacion-continua" target="_blank" class="text-htwo">Educación continua</a></p>
            <p class="text-htwo text-left leading-tight mb-20">Te puede interesar: <router-link class="text-htwo" to="/blog/academico/estudia-en-linea/estudia-tu-carrera-en-linea-beneficios">Estudia tu carrera en línea y prepárate para el futuro</router-link> </p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="true" :linkAnt="'/aprende/tendencias/tendencias-actuales-del-derecho-2024'" :linkSig="'/academico/estudia-en-linea/estudia-tu-carrera-en-linea-beneficios'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import Contacto from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue'
import SigArt from '../../pagesComponents/SigArt.vue';

export default {
    components: { Comentarios, ConComentarios, Contacto, LikesVue, ShareP, SigArt},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '08-ART-ACADEMICO-EDUCACION-CONTINUA-200224',
            ruta: '/academico/educacion-continua/como-un-diplomado-transforma-tu-carrera',
            title: 'El impacto de un diplomado en tu desarrollo profesional: entérate cómo.',
            image: 'https://blog.humanitas.edu.mx/images/Como-impacta-tu-vida-profesional-un-diplomado.jpg',
            text: 'Un diplomado amplía tus habilidades y transforma tu carrera profesional, conoce sus ventajas.'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx'+this.ruta;

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>