<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">Uso responsable de las redes sociales</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-300">
          <img src="/images/uso-responsable-redes-sociales-beneficios-jovenes.webp" alt="El uso responsable de las redes sociales implica identificar los impactos negativos" class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 text-left italic text-[18px] md:text-[20px]"><router-link to="/blog/aprende" class=" text-hone">Aprende</router-link> > <router-link to="/blog/aprende/cultura-general" class=" text-hone">Cultura General</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex list-disc pl-5 italic text-left text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">22 de mayo de 2024</li>  <li>Iván Solís Rivera</li></ul>
            </div>
            <p class=" md:mb-4 mb-6"><b>El Internet y las redes sociales han tenido un impacto profundo</b> en las sociedades, transformando prácticamente todos los aspectos de la vida moderna, por lo que es relevante conocer la forma responsable y adecuada para utilizar las nuevas tecnologías.</p>
            <p class=" md:mb-4 mb-6"><b>Entre los cambios más significativos</b> que ha tenido la era tecnológica en la vida cotidiana <b>destaca la comunicación y conectividad,</b> ya que las herramientas digitales han permitido el intercambio de mensajes en tiempo real, así como mantener conexiones personales de manera constante y directa, <b>eliminando barreras geográficas y temporales.</b></p>
            <p class=" md:mb-8 mb-10">Las nuevas formas de comunicación y el internet han tenido tal relevancia que fomentan la creación de <router-link to="/academico/estudia-en-linea/estudia-tu-carrera-en-linea-beneficios"  class="text-htwo">comunidades en línea</router-link> basadas en intereses compartidos. A su vez, <b>el internet ha sido un catalizador para la innovación tecnológica dando lugar al desarrollo de nuevas tecnologías y servicios, como la computación en la nube, Internet de las Cosas (IoT) y recientemente  la Inteligencia Artificial (IA).</b> Esta fuerza de innovación ha transformado industrias enteras, a la par de crear nuevas oportunidades y desafíos en términos de seguridad, privacidad y empleo.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Impacto negativo de las nuevas tecnologías en las sociedades modernas</h2>
            <p class=" md:mb-4 mb-6"><b>El internet y las redes sociales han aportado innumerables beneficios,</b> sin embargo, <b>también han generado una serie de impactos negativos</b> que han aumentado la preocupación colectiva.</p>
            <p class=" md:mb-4 mb-6">Entre las señales que han causado mayor preocupación resaltan los <b>efectos negativos que ha traído en la salud mental</b> de las personas causando ansiedad y depresión debido al uso excesivo de las redes sociales, especialmente entre los jóvenes. En ese sentido, <b>la comparación constante con los demás y la presión por presentar una imagen idealizada afectan la autoestima y el bienestar emocional.</b> También, el uso excesivo de estas herramientas pueden llevar al aislamiento social, ya que las interacciones en línea no siempre reemplazan las conexiones personales significativas.</p>
            <p class=" md:mb-8 mb-10">De la misma manera, <b>la velocidad y el alcance de la información en las redes sociales facilitan la difusión de noticias falsas y desinformación,</b> lo que puede influir negativamente en la opinión pública y afectar procesos democráticos de naciones enteras.</p>
            <img src="/images/recomendaciones-uso-redes-sociales-riesgos.webp" alt="Conoce algunas recomendaciones para el uso de las redes sociales" class="md:mb-8 mb-10">
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Riesgos al usar las redes sociales</h3>
            <p class=" md:mb-8 mb-10">El desarrollo de herramientas digitales, que permiten la interacción humana, han fomentado la creación de grandes comunidades virtuales con información de personas reales, lo cuál presenta diversos riesgos como son: </p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Ataques de phishing</h4>
            <p class=" md:mb-8 mb-10">Son una forma de ciberataque en la que <b>los usuarios reciben un mensaje que contiene un enlace que parece dirigir a un sitio web legítimo.</b> Una vez en el sitio, se les solicita su información personal, con datos sensibles para engañar a las víctimas y hacer que compartan su información.</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Uso indebido de datos personales</h4>
            <p class=" md:mb-8 mb-10">Generalmente <b>los ciberdelincuentes pueden robar datos personales,</b> como números de identificación fiscal, información de tarjetas de crédito y fechas de cumpleaños, con el <b>objetivo de realizar compras, obtener créditos o llevar a cabo otras acciones</b> no autorizadas en nombre de terceros.</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Violación de la privacidad</h4>
            <p class=" md:mb-8 mb-10">La recopilación masiva de datos personales por parte de las empresas de tecnología plantea serios problemas de privacidad, ya que <b>ha sucedido, que se utilizan para fines de marketing o se venden a terceros sin el consentimiento de los usuarios.</b></p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Ciberacoso y amenazas</h4>
            <p class=" md:mb-8 mb-10">La facilidad para ocultar nombres reales en redes sociales y el anonimato en línea <b>aumenta los comportamientos abusivos como el ciberacoso, el hostigamiento y las amenazas,</b> afectando la seguridad y el bienestar de las personas.</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Adicción a las redes sociales</h4>
            <p class=" md:mb-8 mb-10">El diseño de las plataformas para aumentar el tiempo de uso puede llevar al <b>exceso, interfiriendo con la productividad y las actividades cotidianas,</b> lo que puede ocasionar problemas psicológicos.</p>
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
              <Contacto class="m-3"/>
            </div>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Recomendaciones para el uso de las redes sociales</h2>
            <p class=" md:mb-8 mb-10">Tras haber analizado el impacto y los riesgos que presentan las redes sociales, los expertos coinciden en que es importante tomar ciertas precauciones y medidas a fin de blindar la seguridad en línea, <b>cuidar la integridad psicológica y navegar con seguridad en internet considerando las siguientes recomendaciones:</b></p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Ciberseguridad</h3>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Configura la privacidad de tu cuenta</h4>
            <p class=" md:mb-8 mb-10">Asegúrate de que tu <b>perfil solo sea visible para tus amigos</b> y contactos de confianza y así evitar que personas no autorizadas puedan acceder a la información personal.</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Revisa y ajusta las configuraciones regularmente</h4>
            <p class=" md:mb-8 mb-10">Las plataformas de redes sociales actualizan frecuentemente sus políticas de privacidad y configuraciones de seguridad. <b>Revisa estas opciones para asegurarte de que tus preferencias se mantengan en un buen nivel de ciberseguridad.</b></p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Autenticación en dos pasos</h4>
            <p class=" md:mb-8 mb-10"><b>Activa la verificación en dos pasos</b> para añadir una capa extra de seguridad a tu cuenta y así evitar que otras personas puedan usurpar con facilidad tu perfil.</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Compartir información con precaución</h4>
            <p class=" md:mb-8 mb-10">Se recomienda <b>no compartir información personal</b> sensible como tu dirección, número de teléfono, número de seguro social o información financiera.</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Selectivos con las fotos</h4>
            <p class=" md:mb-8 mb-10">Asegúrate de que <b>las fotos no muestren detalles privados</b> como la dirección de tu casa o la matrícula de tu coche.</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Revisar etiquetas</h4>
            <p class=" md:mb-8 mb-10"><b>Controla las etiquetas en fotos y publicaciones para evitar que información no deseada sea compartida sin tu consentimiento</b> como son los lugares que frecuentas o domicilios privados.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Consciencia sobre las conexiones</h3>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Pensar antes de publicar</h4>
            <p class=" md:mb-8 mb-10">Reflexiona sobre el impacto y la permanencia de lo que publicas. <b>Una vez <router-link to="/blog/academico/estudia-en-linea/metodologias-aprendizaje-linea"  class="text-htwo">en línea</router-link>, es difícil eliminar completamente cualquier contenido.</b></p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Ser respetuoso y considerado</h4>
            <p class=" md:mb-8 mb-10">No participes en acoso cibernético y <b>reporta cualquier comportamiento abusivo que observes sobre otras personas.</b></p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Desconfía de los extraños</h4>
            <p class=" md:mb-8 mb-10"><b>No te involucres en conversaciones privadas</b> con personas que no conoces, sobre todo con quienes tienen intención de conocer más sobre tus datos personales.</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Reportar suplantación de identidad</h4>
            <p class=" md:mb-8 mb-10">Si alguien está utilizando tu información o imágenes sin tu permiso, <b>repórtalo inmediatamente al soporte técnico de las plataformas.</b></p>
            <img src="/images/uso-adecuado-redes-sociales-ciberseguridad.webp" alt="Los pasos para el uso adecuado de las redes sociales" class="md:mb-8 mb-10">
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Educación a menores</h3>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Supervisar el uso de redes sociales de los menores</h4>
            <p class=" md:mb-8 mb-10"><b>Los padres y tutores deben supervisar la actividad en línea de los menores</b> y educarlos sobre los riesgos y las mejores prácticas de seguridad.</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Establecer límites y reglas</h4>
            <p class=" md:mb-8 mb-10"><b>Define con tus hijos reglas claras</b> sobre el uso de las redes sociales y fomenta la comunicación abierta sobre sus experiencias en línea.</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Cuidar con los enlaces y archivos adjuntos</h4>
            <p class=" md:mb-8 mb-10"><b>No hagas clic en enlaces que provengan de fuentes desconocidas o sospechosas,</b> ya que pueden ser enlaces maliciosos o virus cibernéticos.</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Reportar y bloquear</h4>
            <p class=" md:mb-8 mb-10">Si encuentras contenido o comportamientos inapropiados, usa las herramientas de la plataforma para <b>reportarlo y bloquear a la persona responsable.</b></p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Reflexión y autocuidado</h4>
            <p class=" md:mb-8 mb-10">Las redes sociales pueden ser abrumadoras, <b> enseña a tus hijos a desconectarse regularmente y así tener conciencia sobre el cuidado de su salud mental.</b></p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Habla con alguien de confianza</h4>
            <p class=" md:mb-8 mb-10">Si algo te hace sentir incómodo o inseguro en línea, <b>Controla las etiquetas en fotos y publicaciones para evitar que información no deseada sea compartida sin tu consentimiento</b></p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] font-serif mb-5 text-left leading-tight lg:text-justify text-hone">Conclusión</h2>
            <p class=" text-left md:mb-4 mb-6">El uso responsable de las redes sociales implica mantener seguros los datos e información privada de los usuarios evitando ciberdelitos y situaciones propias de los ambientes digitales como es el ciberacoso, amenazas y hostigamiento. <br>Es importante crear conciencia sobre los riesgos y los delitos que surgen en plataformas de redes sociales con el fin de tener espacios digitales seguros.</p>
            <p class=" text-left mb-20">Conoce a la <a href="https://humanitas.edu.mx/virtual" target="_blank" class="text-htwo">comunidad estudiantil virtual de Universidad Humanitas</a> y estudia una carrera en línea.</p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="true" :linkAnt="'/recursos/entrevistas-y-conferencias/desarrollo-humano-una-perspectiva-integral-helios-herrera'" :linkSig="'/recursos/entrevistas-y-conferencias/procedimientos-tecnicas-de-auditoria-y-ejemplos-ricardo-sandoval-huerta'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import Contacto from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue'
import SigArt from '../../pagesComponents/SigArt.vue';

export default {
    components: { Comentarios, ConComentarios, Contacto, LikesVue, ShareP, SigArt},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '0029-ART-APRENDE-CULTURA-GENERAL-210524',
            ruta: '/aprende/cultura-general/consejos-sobre-uso-responsable-de-redes-sociales',
            title: 'Uso responsable de las redes sociales: Guía para navegar de forma segura',
            image: 'https://blog.humanitas.edu.mx/images/Día-internacional-de-la-mujer-financiar-acelera-igualdad-genero-2024.jpg',
            text: 'El uso responsable de las redes sociales recae en el nivel de consciencia y educación de los usuarios respecto a los efectos y beneficios de estas plataformas.'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx'+this.ruta;

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>