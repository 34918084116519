<template>
    <div class="flex flex-col justify-start m-4 z-30 bg-white">
        <h2 class="text-[20px] lg:text-[24px] text-left font-serif mb-8 text-hone">Déjanos tus datos y mantente al día con nuestros últimos artículos</h2>
        <div class="flex flex-col login-box form-container relative ">
            <form autocomplete="on" @submit.prevent="">
            <div class="lg:flex justify-between mb-6 w-full">
                <div class="user-box lg:w-1/4 mb-3 mr-8 lg:mb-0">
                    <input v-model="nombre" type="text" id="name" name="nombre" required maxlength="50" class="">
                    <label for="nombre" class="italic">Nombre*</label>
                </div>
                
                <div class="user-box lg:w-1/4 mb-3 mr-8 lg:mb-0">
                    <input v-model="apellidos" type="text" id="lastname" name="apellidos" required maxlength="50" class="">
                    <label for="lastname" class="italic">Apellidos*</label>
                </div>
                <div class="user-box lg:w-1/4 mb-3 mr-8 lg:mb-0">
                    <input v-model="email" type="text" id="email" name="email" @change="handleEmailChange" required maxlength="50" class="">
                    <label for="email" class="italic">E-mail*</label>
                </div>

                <div class="user-box lg:w-1/4 mb-3 lg:mb-0">
                    <div class="flex text-[12px]">
                        <select v-model="selectedCountry" class="mr-1 rounded-none" style="width: 35%;padding: 10px 10px;font-size: 13px;color: black;margin-bottom: 8px;border: none;border-bottom: 1px solid #797979;outline: none;background: transparent;">
                            <option v-for="country in ladas" :value="country.lada" :key="country.codigo" class="rounded-none text-[14px]">
                                <img :src="getFlagUrl(country.codigo)"/> {{ country.lada }} 
                            </option>
                        </select>
                        <div>

                        </div>
                        <div class="w-2/3">
                            <input v-model="telefono" id="telefono" type="tel" name="telefono" required maxlength="10" class="">
                            <label for="telefono" class="italic" style="top: -20px;left: 0;color: black;font-size: 12px;">N&uacute;mero de Teléfono*</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="lg:flex items-center justify-between">
                <label class="text-[black] text-left lg:text-justify whitespace-nowrap italic text-[12px]">Campos requeridos*</label>
                <div class="lg:flex justify-end items-end w-full">
                    <div class="flex ">
                        <div class="flex items-center mb-2 mr-12">
                            <input type="checkbox" v-model="conditions" class=" cursor-pointer accent-htwo rounded-none w-5 h-5 bg-htwo border-gray-300 focus:ring-[#520400] focus:ring-1 mr-2"> 
                            <label class="text-[black] text-left lg:text-justify italic text-[12px]">
                            He le&iacute;do y acepto el <a href="https://humanitas.edu.mx/aviso-de-privacidad" target="_blank" class="text-black hover:text-hone">Aviso de privacidad</a>.
                            </label>
                        </div>
                    </div>
                    <div class="flex justify-end ">
                        <button @click.prevent="submitForm" class="text-white transition duration-300 hover:text-black focus:border-htwo focus:outline-none font-serif md:text-[18px] flex items-center justify-center rounded-none bg-hone hover:bg-white border-hone md:w-[140px] h-[45px]">Enviar</button>
                    </div>
                </div>
            </div>
            </form>
        </div>
    </div>
</template>

<script lang="js">
import Swal from 'sweetalert2';
import axios from 'axios';
import { SolicitudBlog } from '@/main';


export default {
    data() {
        return {
            toast : Swal.mixin({
                      toast: true,
                      position: 'top-end',
                      width: 500,
                      padding: '1em',
                      showConfirmButton: false,
                      timerProgressBar: true,
                      timer: 4000,
                      icon: 'error',
                      iconColor: '#881912'
                  }),
            selectedCountry: '+52', // Aquí se almacenará el país seleccionado
            nombre: '',
            apellidos: '',
            email: '',
            telefono: '',
            tokenizer: '',
            form: 'Newsletters',
            conditions: false,
            ladas: [
                { codigo: "mx", lada: "+52", nombre: "México" },
                { codigo: "us", lada: "+1", nombre: "Estados Unidos" },
                { codigo: "ca", lada: "+1", nombre: "Canadá" },
                { codigo: "gb", lada: "+44", nombre: "Reino Unido" },
                { codigo: "de", lada: "+49", nombre: "Alemania" },
                { codigo: "fr", lada: "+33", nombre: "Francia" },
                { codigo: "es", lada: "+34", nombre: "España" },
                { codigo: "it", lada: "+39", nombre: "Italia" },
                { codigo: "ar", lada: "+54", nombre: "Argentina" },
                { codigo: "br", lada: "+55", nombre: "Brasil" },
                { codigo: "cl", lada: "+56", nombre: "Chile" },
                { codigo: "co", lada: "+57", nombre: "Colombia" },
                { codigo: "pe", lada: "+51", nombre: "Perú" },
                { codigo: "ve", lada: "+58", nombre: "Venezuela" },
                { codigo: "ec", lada: "+593", nombre: "Ecuador" },
                { codigo: "bo", lada: "+591", nombre: "Bolivia" },
                { codigo: "py", lada: "+595", nombre: "Paraguay" },
                { codigo: "uy", lada: "+598", nombre: "Uruguay" },
                { codigo: "pa", lada: "+507", nombre: "Panamá" },
                { codigo: "cr", lada: "+506", nombre: "Costa Rica" },
                // Agrega más países según sea necesario
            ]
        };
    },
    methods: {
        /**
* @param {string} countryCode
*/
        getFlagUrl(countryCode) {
            return `https://flagcdn.com/64x48/${countryCode.toLowerCase()}.png`;
        },

        /**
* @param {string} mensaje
*/
        mostrarErrorSweetAlert(mensaje) {
          this.toast.fire({
          icon: 'error',
          title: 'Datos faltantes',
          text: mensaje,
          });
        },

        currentUrl() {
            return window.location.href;
        },

        validarDatos(){
            if(!/^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s]{1,30}$/.test(this.nombre)){
                this.mostrarErrorSweetAlert('Ingrese un nombre válido.');
            } else {
            if(!/^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s]{1,60}$/.test(this.apellidos)){
                this.mostrarErrorSweetAlert('Por favor ingresa un(os) apellido(s) válido(s).');
            } else {
                if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email)){
                    this.mostrarErrorSweetAlert('Ingrese un correo electrónico válido.')
                }else{
                    if(!/^\d{10}$/.test(this.telefono)){
                        this.mostrarErrorSweetAlert('Ingrese un número de teléfono válido (10 dígitos).');
                    } else {
                        return true
                    }
                }
            }
            }
            return false;
        },

        submitForm() {
            // Validar el formulario antes de enviar los datos
            if (this.validarDatos()) {
                // Crear un objeto FormData con los datos del formulario
                const formData = new FormData();
                formData.append('nombre', this.nombre );
                formData.append('apellidos', this.apellidos);
                formData.append('email', this.email);
                formData.append('telefono', this.selectedCountry + this.telefono);
                formData.append('newsletters', true+'')
                formData.append('form', this.form)
                formData.append('g-recaptcha-response',this.tokenizer)
                formData.append('url', this.currentUrl())
                /* Realizar la solicitud POST
                let peticion = {
                    method: 'POST',
                    body: formData,
                };*/
                Swal.fire({
                    title: 'ENVIANDO...',
                    text: 'ESTAMOS ENVIANDO SUS DATOS. NO CIERRE LA VENTANA',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    showConfirmButton: false,
                    didOpen: () => {
                        // @ts-ignore
                        Swal.showLoading();
                        axios.post(SolicitudBlog, formData, {
                        // axios.post(Solicitud, formData, {
                            headers: {'Content-Type': 'multipart/form-data',},
                            responseType: 'json', // Asegúrate de incluir esta línea
                        })
                            // @ts-ignore
                            .then((response) => {
                                    // if (response.data.error === false) {
                                        //@ts-ignore
                                        console.log(response);
                                        // if (dataLayer) {
                                        //     //@ts-ignore
                                        //     dataLayer.push({
                                        //     event: 'generate_lead',
                                        //     email: this.email,
                                        //     career: this.selectedOffer,
                                        //     });
                                        // }
                                        Swal.fire({
                                            title: '¡Gracias por suscribirte!',
                                            text: 'Te has unido al blog de Universidad Humanitas. ¡Nos alegra que seas parte de nuestra comunidad!',
                                            icon: 'success',
                                            iconColor: '#CAAB55',
                                            showConfirmButton: false,
                                            timer: 4000
                                        });
                                        this.nombre = '';
                                        this.apellidos = '';
                                        this.email = '';
                                        this.telefono = '';
                                        this.tokenizer = '';
                                        this.conditions = false;
                                    // } else {
                                    //     Swal.fire({
                                    //         text: `${response.data.descripcion}`,
                                    //         icon: "error",
                                    //         iconColor: '#881912',
                                    //         showConfirmButton: false,
                                    //         timer: 4000
                                    //     });
                                    //     this.tokenizer='';
                                    // }
                            // @ts-ignore
                            }).catch( (error) => console.log('error', error) );
                    }
                });
            }
        },

        handleEmailChange() {
            if (!this.tokenizer) {
                this.executeRecaptcha();
            }
        },

        executeRecaptcha() {
            //@ts-ignore
            grecaptcha.enterprise.execute('6Ldd0x0oAAAAAAaPVjMnVirgyfT6qahpksCqN0UX', { action: 'submit_lead' })
            // @ts-ignore
            .then((token) => {
                this.tokenizer = token;
            })
            // @ts-ignore
            .catch((error) => {
                console.error('Error al ejecutar reCAPTCHA:', error);
            });
        },

    }
}
</script>

<style scoped>
.login-box {
    max-width: 1200px;
    margin: 0 ;
    padding: 0px;
    box-sizing: border-box;
    border: black;
    border-radius: 10px;
    background: transparent;
    z-index: 1;
    
    
  }

  .checkbox-custom {
    transform: scale(2); /* Ajusta el valor según sea necesario para aumentar el tamaño */
  }

  

  .login-box h2 {
    margin: 0 0 0px;
    padding: 0;
    color: transparent;
    text-align: center;
  }

  .login-box .user-box {
    position: relative;
    z-index: 1;
  }

  .login-box .user-box i {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 10px;
    color: black;
  }

  .form-container {
    position: relative;
    z-index: 1;
  }

  .login-box .user-box input,
  .login-box .user-box select,
  .login-box .user-box textarea {
   width: 100%;
   padding: 10px 10px; /* Ajusta según tu preferencia */
   font-size: 14px;
   color: black;
   margin-bottom: 8px;
   border: none;
   border-bottom: 1px solid black;
   outline: none;
   background: transparent;
}

.login-box .user-box select option {
  color: black; /* Color del texto de las opciones del select cuando se despliegan */
}



  .login-box .user-box label {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    padding: 10px 0;
    font-size: 12px;
    color: black;
    pointer-events: none;
    transition: .5s;
  }

  .login-box .user-box input:focus ~ label,
  .login-box .user-box input:valid ~ label,
  .login-box .user-box select:focus ~ label,
  .login-box .user-box textarea:focus ~ label {
    top: -20px;
    left: 0;
    color: black;
    font-size: 12px;
  }

</style>