import { createApp } from 'vue'
import './style.css'
import router from './router';
import AppVue from './App.vue'
import LoadScript from 'vue-plugin-load-script';
import { metaTagPlugin } from "@m-media/vue3-meta-tags";
import { initializeApp } from "firebase/app";
import { createPinia } from 'pinia';
// Import the functions you need from the SDKs you need



const app = createApp(AppVue);
// const Solicitud = 'https://humanitas.edu.mx/assets/sendAPI.php';
const Solicitud = 'https://humanitas.edu.mx/assets/sendAPI.php';
const Solicitud2 = 'https://humanitas.edu.mx/assets/sendAPI2.php';
//const SolicitudBlog = 'https://humanitas.edu.mx/assets/sendAPIBlog.php';
const SolicitudBlog = 'https://humanitas.edu.mx/assets/sendAPIBlog.php';
const ConsultasBlog = 'https://humanitas.edu.mx/assets/Consultas.php'
// const Solicitud2 = 'http://localhost/js/scripts/sendAPI2.php';
const colegiaturaLicEjec = 2950;
const colegiaturaLicEsco = 4950;
const colegiaturaMtr = 4190;
const colegiaturaDoc = 0;
// const Solicitud = 'https://universidad.humanitas.edu.mx/assets/sendAPI.php';
// const Solicitud2 = 'https://universidad.humanitas.edu.mx/assets/sendAPI2.php';
declare const grecaptcha: any;
// Instala el plugin LoadScript
app.use(LoadScript);
// Agrega las claves de reCAPTCHA como variables de entorno
const recaptchaSiteKey = import.meta.env.VITE_RECAPTCHA_SITE_KEY;

// Configura la referencia a la API de reCAPTCHA
app.config.globalProperties.$recaptcha = {
	async execute(action: any) {
		return new Promise((resolve) => {
			grecaptcha.enterprise.ready(async () => {
				const token = await grecaptcha.enterprise.execute(recaptchaSiteKey, { action });
				resolve(token);
			});
		});
	},
};

// Declarar gtag para TypeScript
declare global {
	interface Window {
		gtag: (...args: any[]) => void;
	}
}

  // Inicializar Google Analytics después de cada navegación
router.afterEach((to) => {
	window.gtag('config', 'G-WG56YHP3SR', {
		page_path: to.fullPath,
	});
});

// Variable para almacenar el defaultName dinámico
let currentDefaultName = 'Universidad Humanitas';

// Función auxiliar para determinar el defaultName
function getDefaultName(path: string): string {
  if (path === '/') {
    return '45 años de excelencia académica';
  } else if (path === '/blog') {
    return 'Conocimiento que expande tu mente';
  } else if (path.startsWith('/blog/')) {
    return 'Blog Humanitas';
  } else {
    return 'Universidad Humanitas';
  }
}

// Hook afterEach para actualizar el defaultName después de la navegación
router.afterEach((to) => {
  currentDefaultName = getDefaultName(to.path);
});

// Configura el plugin metaTagPlugin
app.use(metaTagPlugin, {
  get defaultName() {
    return currentDefaultName;
  },
}, router);

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID
};

initializeApp(firebaseConfig);
//Configuración de pinia
const pinia = createPinia()

app.use(pinia);
app.use(router);
app.mount('#app');



export { Solicitud, Solicitud2, colegiaturaLicEjec, colegiaturaLicEsco, colegiaturaMtr, colegiaturaDoc, SolicitudBlog, ConsultasBlog };


//createApp(index).mount('#app')
