<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">Arquitectura: ¿Licenciatura o <br class="md:block hidden"> ingeniería? Aquí te decimos</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-200">
          <img src="/images/arquitectura-arte-de-disenar-y-construir.webp" alt="La Arquitectura abarca desde las ideas innovadoras hasta la creación de espacios funcionales" class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/academico" class=" text-hone">Académico</router-link> > <router-link to="/blog/academico/licenciatura" class=" text-hone">Licenciatura</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex text-left list-disc pl-5 italic text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">18 de Junio de 2024</li>  <li>Carolina Reyes Martínez</li></ul>
            </div>
            <!-- Images -->
            <p class="md:mb-4 mb-6">¿Te preguntas si la Arquitectura es una licenciatura o una ingeniería? Descubre el campo laboral, qué estudia esta carrera y cómo se compara con la ingeniería civil. Una guía completa para futuros arquitectos.</p>
            <p class="md:mb-8 mb-10"> La Arquitectura es una de las disciplinas más fascinantes y vitales en nuestra sociedad. Si alguna vez te has preguntado si la arquitectura es una licenciatura o una ingeniería, cuál es su campo laboral, o qué estudia exactamente esta carrera, este artículo es para ti. Aquí te decimos todo lo que  necesitas saber para tomar una decisión informada sobre tu futuro profesional.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">¿Qué es la Arquitectura?</h2>
            <p class="md:mb-4 mb-6">El nombre de Arquitectura proviene de los vocablos del griego antiguo <b>arch (jefe, autoridad) y techné (creación, construcción). La Arquitectura es el arte y la técnica de concebir, diseñar y construir edificaciones y otras estructuras.</b> Esta carrera abarca desde la concepción de ideas innovadoras hasta la creación de espacios funcionales y estéticamente agradables.</p>
            <p class="md:mb-4 mb-6">La arquitectura es inseparable al ser humano, ya que desde el momento en que comienza a buscar en donde vivir y cómo edificar su hogar: la elección de los materiales, las formas, cómo puede decorarlo; construyendo templos para adorar a los dioses. Los diversos modos y estilos de la arquitectura humana son el reflejo de los momentos históricos y de las corrientes de pensamiento.</p>
            <p class="md:mb-4 mb-6">Su conocimiento se basa en tres principios: belleza, utilidad y firmeza, y ha sido incluida como una de las Bellas Artes de la humanidad.</p>
            <p class="md:mb-8 mb-10">A través de la arquitectura, los profesionales transforman el entorno físico, influenciando cómo vivimos y trabajamos.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Los tipos de Arquitectura</h2>
            <p class="md:mb-4 mb-6">Se pueden definir éstos como funcional,  histórico y cultural.  Y se clasifica de las siguientes maneras: </p>
            <p class="mb-2"><b>Tipo funcional: </b></p>
            <ul class="list-disc pl-6 md:mb-4 mb-6">
                <li><b>Arquitectura religiosa</b> como templos, monumentos, iglesias.</li>
                <li><b>Arquitectura militar</b> como edificaciones estratégicas como los castillos, los cuarteles o las murallas.</li>
                <li><b>Arquitectura civil</b> que es la que concibe las estructuras y espacio para el uso de los ciudadanos, sean casas, unidades habitacionales, plazas, espacios culturales, teatros, parques, escuelas.</li>
            </ul>
            <p class="mb-2"><b>Tipo histórico o estilístico: </b></p>
            <ul class="list-disc pl-6 md:mb-4 mb-6">
                <li><b>Arquitectura popular.</b> Son construcciones realizadas por personas como los artesanos y se emplean materiales naturales.</li>
                <li><b>Arquitectura común o vulgar.</b> Aquella realizada por los arquitectos pero de manera práctica, de uso y funcionalidad, sin una tradición arquitectónica.</li>
            </ul>
            <p class="md:mb-8 mb-10">Según su período histórico o cultural. Pueden nombrarse tantos estilos arquitectónicos como momentos y contextos históricos, hay cientos de ellos. Por ejemplo, en Occidente, la arquitectura romana y su herencia griega; la arquitectura barroca; arquitectura neoclásica, etc.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">¿Qué estudia la Arquitectura?</h3>
            <p class="md:mb-4 mb-6"><router-link to="/licenciatura-en-arquitectura" target="_blank" class=" text-htwo hover:text-hone">La carrera de Arquitectura</router-link> abarca una variedad de áreas de estudio como son las siguientes:</p>
            <ul class="list-disc pl-6 md:mb-8 mb-10">
                <li><b>Diseño arquitectónico:</b> Creación de planos y modelos que integran funcionalidad y estética.</li>
                <li><b>Historia de la arquitectura:</b> Estudio de las tendencias arquitectónicas a lo largo del tiempo y su impacto en la sociedad.</li>
                <li><b>Tecnología de la construcción:</b> Materiales y técnicas de construcción modernas y eficientes.</li>
                <li><b>Arquitectura religiosa</b> como templos, monumentos, iglesias.</li>
                <li><b>Sostenibilidad:</b> Diseño de edificios ecológicos y eficientes energéticamente.</li>
                <li><b>Dibujo técnico:</b> Representación gráfica de proyectos arquitectónicos, utilizando herramientas manuales y digitales.</li>
                <li><b>Estructuras:</b> Principios básicos de ingeniería estructural aplicados en el diseño arquitectónico.</li>
                <li><b>Paisajismo:</b> Diseño de espacios exteriores y su integración con el entorno construido.</li>
                <li><b>Gestión de proyectos:</b> qPlanificación y administración de proyectos de construcción, desde su concepción hasta su finalización.</li>
            </ul>
            <!-- Form -->
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
                <ContactoVue class="m-3"/>
            </div>
            
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">¿Arquitectura es licenciatura o ingeniería?</h2>
            <p class="md:mb-4 mb-6">La Arquitectura se estudia como una licenciatura. A diferencia de las ingenierías, que se enfocan más en los aspectos técnicos, <b>la arquitectura combina elementos artísticos, creativos y técnicos.</b></p>
            <p class="md:mb-8 mb-10">Aunque comparte algunas similitudes con la Ingeniería Civil, la Arquitectura se centra más en el diseño, la estética y la funcionalidad de los espacios.</p>
            <ul class="list-disc pl-6 md:mb-8 mb-10">
                <li><b>Enfoque:</b> La Arquitectura se enfoca en el diseño y la estética, mientras que la Ingeniería Civil se centra en la construcción y la estabilidad estructural.</li>
                <li><b>DesarrolCampo laboral:</b> Los arquitectos trabajan en estudios de diseño, en empresas de construcción y pueden emprender proyectos independientes. Los ingenieros civiles suelen trabajar en empresas de construcción, infraestructuras públicas y consultoras de ingeniería.</li>
                <li><b>Responsabilidades:</b> Los arquitectos se encargan de la planificación y diseño de los espacios, mientras que los ingenieros civiles se enfocan en asegurar que las estructuras sean seguras y funcionales.</li>
            </ul>
  
            <!-- Images -->
            <img src="/images/diferencia-entre-arquitectura-ingenieria.webp" alt="La Arquitectura se enfoca en el diseño y la estética, y la Ingeniería Civil en la construcción y la estabilidad estructural." class="md:mb-8 mb-10">
  
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Campo laboral de la Arquitectura</h2>
            <p class="md:mb-4 mb-6">Los arquitectos tienen un amplio campo laboral que incluye:</p>
            <ul class="list-disc pl-6 md:mb-8 mb-10">
                <li><b>Estudios de Arquitectura:</b> Trabajando en el diseño y planificación de proyectos arquitectónicos.</li>
                <li><b>Construcción:</b> Supervisando la construcción de edificios y asegurando que los diseños se implementen correctamente.</li>
                <li><b>Urbanismo:</b> Planificando y diseñando ciudades y espacios públicos para mejorar la calidad de vida de los habitantes.</li>
                <li><b>Docencia:</b> Enseñando en universidades y escuelas técnicas, formando a futuros arquitectos.</li>
                <li><b>Consultoría:</b> Asesorando en proyectos de construcción y diseño, brindando su experiencia para optimizar los resultados.</li>
                <li><b>Restauración y conservación:</b> Trabajando en la restauración de edificios históricos y patrimoniales para preservarlos para futuras generaciones.</li>
                <li><b>Diseño de Interiores:</b> Creando espacios interiores funcionales y estéticamente agradables.</li>
            </ul>
  
  
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Arquitectura vs. Ingeniería Civil: ¿Cuál elegir?</h2>
            <p class="md:mb-4 mb-6">La elección entre arquitectura e ingeniería civil depende de tus intereses y habilidades:</p>
            <ul class="list-disc pl-6 md:mb-8 mb-10">
                <li><b>Si te apasiona el diseño y la creatividad:</b> La arquitectura es para ti. Te permitirá explorar tu lado artístico y transformar ideas en realidades tangibles.</li>
                <li><b>Si prefieres los aspectos técnicos y estructurales:</b>  La ingeniería civil puede ser tu mejor opción. Te enfocarías en la seguridad y funcionalidad de las estructuras.</li>
                <li><b>Si buscas una combinación de ambos:</b> Considera especializaciones o programas conjuntos que incluyan aspectos de ambas disciplinas. Algunos programas académicos ofrecen una formación integral que abarca tanto el diseño como la  ingeniería.</li>
            </ul>
  
            <!-- Images -->
            <img src="/images/arquitectura-o-ingenieria-te-decimos.webp" alt="Estudiar Arquitectura te ofrece una variedad de proyectos y la capacidad de transformar tu entorno" class="md:mb-8 mb-10">
  
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Cuáles son los beneficios de estudiar Arquitectura</h2>
            <p class="md:mb-4 mb-6">Estudiar Arquitectura ofrece estos beneficios:</p>
            <ul class="list-disc pl-6 md:mb-8 mb-10">
                <li><b>Desarrollo de habilidades creativas:</b> La arquitectura fomenta la creatividad y la innovación.</li>
                <li><b>Oportunidades de viaje:</b> Los arquitectos a menudo tienen la oportunidad de viajar para trabajar en proyectos en diferentes ubicaciones.</li>
                <li><b>Impacto social:</b> La Arquitectura tiene un impacto directo en la calidad de vida de las personas al mejorar los espacios y las comunidades.</li>
                <li><b>Diversidad de proyectos:</b> La carrera ofrece una amplia variedad de proyectos, desde viviendas residenciales hasta complejos comerciales y espacios públicos.</li>
                <li><b>Reconocimiento profesional:</b> Los arquitectos son altamente valorados y respetados por su capacidad para transformar el entorno construido.</li>
            </ul>
            
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Conclusión</h2>
            <p class="md:mb-8 mb-10"><b>La Arquitectura es una carrera apasionante y multifacética que combina el arte con la ciencia.</b> Si te interesa transformar espacios y dejar una huella en el espacio urbano, la arquitectura puede ser la carrera ideal para ti. No importa si eres un estudiante universitario buscando tu camino, <b>la arquitectura ofrece una amplia gama de oportunidades y desafíos.</b></p>
  
            
            <p class="text-htwo text-left mb-20">Explora más: <a href="https://humanitas.edu.mx/" target="_blank" class="text-htwo">Universidad Humanitas</a></p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="true" :linkAnt="'/academico/licenciatura/principales-corrientes-teorias-psicologicas-modelos-y-representantes'" :linkSig="'/academico/doctorado/doctorados-virtuales-en-mexico'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
  import Comentarios from '../../pagesComponents/Comentarios.vue';
  import ConComentarios from '../../pagesComponents/ConComentarios.vue';
  import ContactoVue from '../../pagesComponents/Contacto.vue';
  import LikesVue from '../../pagesComponents/Likes.vue';
  import ShareP from '../../pagesComponents/SharePoint.vue';
  import VideoClipVue from '../../pagesComponents/videoClip.vue';
  import SigArt from '../../pagesComponents/SigArt.vue';
  
  export default {
    components: { Comentarios, ConComentarios, ContactoVue, LikesVue, ShareP, VideoClipVue, SigArt},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '0043-ART-ACADEMICO-LICENCIATURA-130624',
            ruta: '/academico/licenciatura/principales-corrientes-teorias-psicologicas-modelos-y-representantes',
            title: 'Descubre cuáles son las principales corrientes y teorías psicológicas',
            image: 'https://blog.humanitas.edu.mx/images/Costo-y-requisitos-estudiar-maestria.jpg',
            text: 'Exploramos las principales corrientes y teorías psicológicas, sus modelos y representantes destacados. Ideal para ti que quieres saber más.'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx/academico/licenciatura/principales-corrientes-teorias-psicologicas-modelos-y-representantes';
  
        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';
  
        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;
  
        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;
  
        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;
  
        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
  }
</script>

<style>

</style>