<template>
  <div class="relative" @mouseover="openDropdown" @mouseleave="startCloseTimer">
    <router-link :to="`/blog/${path}`"><button class="inline-flex lg:text-[16px] text-[14px] items-center rounded-none  px-2 py-2 border border-transparent text-gray-700 bg-transparent hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white " style="font-family: 'Helvetica Neue Regular', sans-serif;">
      {{ name }}
      <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path fill-rule="evenodd" d="M10 12a1 1 0 01-.707-.293l-4-4a1 1 0 111.414-1.414L10 9.586l3.293-3.293a1 1 0 111.414 1.414l-4 4A1 1 0 0110 12z" clip-rule="evenodd" />
      </svg>
    </button></router-link>

    <div v-if="isOpen" class="absolute left-0 mt-0 lg:w-44 w-[38] italic rounded-none shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="options-menu" @mouseover="cancelCloseTimer" @mouseleave="closeDropdown">
      <router-link v-for="(item, index) in dropdownItems" :key="index" :to="`/blog/${path}/${item.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().replace(/ /g, '-')}`" class="dropdown-item block px-4 text-left py-2 lg:text-sm text-[12px] text-gray-700 hover:bg-[#881912] hover:text-gray-900" role="menuitem">{{ item }}</router-link>
    </div>
  </div>
</template>

<script lang="js">
export default {
  props: {
    name: String,
    path: String,
    dropdownItems: Array,
  },
  data() {
    return {
      isOpen: false,
      closeTimer: null,
    };
  },
  methods: {
    openDropdown() {
      // @ts-ignore
      clearTimeout(this.closeTimer); // Limpiar el temporizador si está activo
      this.isOpen = true;
    },
    closeDropdown() {
      this.isOpen = false;
    },
    startCloseTimer() {
      // @ts-ignore
      this.closeTimer = setTimeout(() => {
        this.closeDropdown();
      }, 120); // Espera 200ms antes de cerrar el menú
    },
    cancelCloseTimer() {
      // @ts-ignore
      clearTimeout(this.closeTimer); // Limpiar el temporizador si el usuario vuelve al menú antes de que se cierre
    }
  }
};
</script>

<style scoped>
.navbar-name {
  font-family: 'Helvetica Neue Regular', sans-serif;
}

.dropdown-item {
  font-family: 'Helvetica Neue Italic', sans-serif;
}

.dropdown-item:hover {
  background-color: #881912;
  color: white;
}
</style>