<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32  w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10"> Las más importantes competencias digitales <br class="md:block hidden"> para profesionales </h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-200">
          <img src="/images/competencias-digitales-profesionales-digitalizacion.webp" class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/aprende" class=" text-hone">Aprende</router-link> > <router-link to="/blog/aprende/tendencias" class=" text-hone">Tendencias</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex text-left list-disc pl-5 italic text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">17 de mayo de 2024</li>  <li>Iván Solís Rivera</li></ul>
            </div>
            <p class="md:mb-4 mb-6">La <b>capacidad innovadora de la tecnología está estrechamente vinculada al nivel de competencias digitales</b> de los profesionales.</p>
            <p class=" md:mb-8 mb-10">Actualmente existe una correlación significativa entre la educación, las habilidades, la adopción y el uso de las nuevas tecnologías en diversos aspectos de la vida. En este sentido, <b> el papel de la educación y las habilidades digitales es fundamental para promover la innovación.</b></p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">¿Qué son las habilidades digitales?</h2>
            <p class="md:mb-8 mb-10"><b>Las competencias digitales consisten en el desarrollo de técnicas y habilidades que consisten en el desarrollo y adquisición de conocimientos,</b> valores, actitudes, normativa y ética relacionados con las tecnologías de la información (TIC), con el fin de aprovechar al máximo su potencial y sus beneficios para la aplicación en diversos rubros.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Beneficios de las competencias digitales</h3>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Agiliza la reflexión, la creatividad y la innovación</h4>
            <p class="md:mb-8 mb-10">Las competencias digitales emplean herramientas tecnológicas que aceleran la capacidad de innovación de las personas, lo que <b>implica mayor <router-link to="/blog/aprende/tendencias/tendencias-administracion-empresas-2024" class="text-htwo">productividad</router-link>, flexibilidad y resolución de imprevistos.</b></p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Optimización de tiempos y costos</h4>
            <p class="md:mb-8 mb-10">Las habilidades digitales ayudan a cumplir con la entrega de proyectos profesionales dentro de los plazos y parámetros de calidad previamente establecidos, respetando el presupuesto, o en su caso, mejorándolos.</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Agiliza la reflexión, la creatividad y la innovaciónGestionar comunidades e identidad digital</h4>
            <p class="md:mb-8 mb-10"><b>La digitalización nos proporciona conocimientos, inteligencia empresarial y análisis de la competencia,</b> o que nos da criterio para manejar la información y contribuir al posicionamiento de la marca.</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Mejorar la eficacia y eficiencia laboral</h4>
            <p class="md:mb-8 mb-10"><b>Los dispositivos y medios electrónicos fomentan la coordinación y los entornos colaborativos,</b> además de facilitar los procesos de trabajo alineando los esfuerzos para cumplir un objetivo común.</p>
            <img src="/images/habilidades-digitales-educacion-virtual.webp" alt="Las habilidades digitales se basan en el modelo STEM" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Educación virtual: ¿cómo se relaciona con las competencias digitales?</h2>
            <p class="md:mb-4 mb-6"><b>La <router-link to="/blog/academico/estudia-en-linea/metodologias-aprendizaje-linea" class="text-htwo">educación virtual</router-link>,</b> también conocida como educación a distancia o en línea, es un modelo educativo que <b>utiliza tecnologías de la información y la comunicación (TIC) para facilitar el proceso de enseñanza y aprendizaje</b> a través de medios electrónicos y redes de internet.</p>
            <p class="md:mb-4 mb-6">Este modelo <b> permite a los estudiantes acceder a contenido educativo, interactuar con los maestros o pedagogos y compañeros,</b> para realizar actividades de aprendizaje sin la necesidad de estar físicamente presentes en un aula tradicional.</p>
            <p class="mb-2">Algunos hitos importantes en este desarrollo del modelo educativo online son los siguientes:</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Popularización de internet</h3>
            <p class="md:mb-8 mb-10"><b>A partir de 1990 surgieron las primeras formas de educación en línea,</b>  principalmente a través de correos electrónicos y sitios web estáticos.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Plataformas de gestión del aprendizaje (LMS)</h3>
            <p class="md:mb-8 mb-10">En las primeras dos décadas del siglo XXI, <b>surgieron plataformas específicas diseñadas para la entrega de cursos en línea, como Moodle, Blackboard y Canvas.</b>  Estas plataformas dieron acceso más efectivo del contenido, la interacción y la evaluación en entornos virtuales.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Adaptación de cursos, certificaciones y programas en línea</h3>
            <p class="md:mb-8 mb-10">A medida que la era digital se desarrollaba, <b>instituciones educativas a nivel internacional comenzaron a ofrecer una amplia gama de cursos y programas completos en línea,</b> desde educación básica hasta educación superior y formación profesional.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Aceptación y reconocimiento educativo</h3>
            <p class="md:mb-8 mb-10">Con el tiempo, la educación virtual ha ganado aceptación y reconocimiento tanto por parte de estudiantes como de empleadores. En la actualidad, <b>existen certificaciones válidas ante los principales sistemas educativos que son ampliamente aceptadas en muchos campos laborales.</b></p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Adaptación durante la pandemia de Covid-19</h3>
            <p class="md:mb-8 mb-10">La pandemia de Covid-19 aceleró aún más la adopción de la educación virtual, ya que <b>muchas instituciones educativas se vieron obligadas a cerrar sus campus físicos y trasladar sus operaciones al entorno en línea,</b> además de crear modelos híbridos que incluían la manera presencial y en línea.</p>
            <!-- Aqui va el componente de contacto, no se te olvide hacerlo manolo -->
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
                <ContactoVue class="m-3"/>
            </div>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Aprendizaje digital: la pieza que fortalece las competencias profesionales</h2>
            <p class="mb-2">El aprendizaje digital, también conocido como educación en línea o e-learning, se refiere a la parte del proceso para adquirir conocimientos y habilidades a través de herramientas digitales, entre sus ventajas y desventajas, destacan las siguientes:</p> 
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Ventajas del aprendizaje digital</h3>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Aprendizaje autodirigido</h4>
            <p class="md:mb-8 mb-10">Permite a los estudiantes asumir la responsabilidad de su propio aprendizaje y desarrollar habilidades de autogestión.</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Costo-efectividad</h4>
            <p class="md:mb-8 mb-10">A menudo, <b>el aprendizaje digital es más económico que la educación tradicional,</b> ya que elimina los gastos asociados con el transporte, las instalaciones y los materiales impresos.</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Actualización constante</h4>
            <p class="md:mb-8 mb-10">Los materiales de aprendizaje digital <b>pueden actualizarse fácilmente para reflejar los avances más recientes en el campo del conocimiento.</b></p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Personalización</h4>
            <p class="md:mb-8 mb-10">Los <b>estudiantes pueden trabajar a su propio ritmo y centrarse en áreas específicas que necesiten mejorar,</b> lo que puede conducir a un aprendizaje más efectivo y significativo.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Desventajas del aprendizaje digital</h3>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Falta de interacción cara a cara</h4>
            <p class="md:mb-8 mb-10">La ausencia de contacto físico con profesores y compañeros puede <b>dificultar la construcción de relaciones sólidas y la comunicación efectiva.</b></p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Necesidad de autodisciplina</h4>
            <p class="md:mb-8 mb-10">Al no tener una estructura de clase tradicional, <b>algunos estudiantes pueden luchar con la motivación</b> y la autodisciplina necesarias para completar con éxito los cursos en línea.</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Posibles problemas técnicos</h4>
            <p class="md:mb-8 mb-10">La dependencia de la tecnología puede dar lugar a problemas técnicos, como <b>fallas en el sistema, conectividad a Internet deficiente o problemas de compatibilidad de dispositivos.</b></p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Inmediatez de feedback</h4>
            <p class="md:mb-8 mb-10">En comparación con el entorno de aula tradicional, <b>puede ser más difícil para los estudiantes recibir retroalimentación inmediata</b> y personalizada de los instructores en el aprendizaje digital.</p>
            <img src="/images/docentes-virtuales-educacion-online.webp" alt="La educación virtual tiene como objetivo adaptarse a los hábitos del estudiante." class="md:mb-8 mb-10">
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Desventajas del aprendizaje digital</h3>
            <p class="md:mb-4 mb-6">En términos para identificar cuáles son las competencias digitales avanzadas en México y Latinoamérica, nos debemos referir a las políticas educativas que se establecen en la región que se han centrado tradicionalmente en <b>aumentar la participación en disciplinas de ciencia, tecnología, ingeniería y matemáticas (STEM).</b></p>
            <p class="md:mb-4 mb-6">Si bien los especialistas en STEM son importantes particularmente la innovación tecnológica, la política gubernamental debe tener una visión amplia de las competencias digitales avanzadas.</p>
            <p class="mb-2"><b>Estas competencias van más allá del simple manejo básico de herramientas digitales y abarcan un nivel más avanzado de dominio y comprensión.</b> Algunas de las competencias digitales avanzadas incluyen:</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Programación y desarrollo de software</h4>
            <p class="md:mb-8 mb-10">Capacidad para escribir código, desarrollar aplicaciones y comprender los principios fundamentales de la programación.</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Análisis de datos</h4>
            <p class="md:mb-8 mb-10">Habilidad para recopilar, analizar e interpretar datos utilizando herramientas y técnicas como análisis estadístico, visualización de datos y minería de datos.</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Seguridad informática</h4>
            <p class="md:mb-8 mb-10">Conocimiento de los principios de seguridad cibernética, incluyendo la protección de datos, prevención de ataques, y gestión de riesgos en entornos digitales.</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Gestión de proyectos tecnológicos</h4>
            <p class="md:mb-8 mb-10">Habilidad para planificar, ejecutar y controlar proyectos relacionados con tecnología de la información, utilizando metodologías como Agile o Scrum.</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Inteligencia artificial y aprendizaje automático</h4>
            <p class="md:mb-8 mb-10">Comprensión de los conceptos fundamentales de la inteligencia artificial, incluyendo el aprendizaje automático, redes neuronales, y procesamiento de lenguaje natural.</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Inteligencia artificial y aprendizaje automático</h4>
            <p class="md:mb-4 mb-6">Conocimiento de los principios subyacentes de la tecnología blockchain y su aplicación en áreas como criptomonedas, contratos inteligentes y cadena de suministro.</p>
            <p class="md:mb-8 mb-10">Estas competencias digitales avanzadas son cada vez más importantes en el mundo actual, donde la tecnología juega un papel central en casi todos los aspectos de la vida y el trabajo.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Conclusión</h2>
            <p class="mb-20">Para desarrollar las competencias digitales para profesionales la <a href="https://humanitas.edu.mx/virtual" target="_blank" class="text-htwo">Universidad Humanitas</a> cuenta con modelos educativos presenciales y online que se basan en una metodología establecida para que los estudiantes puedan desarrollar sus habilidades al máximo. </p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="true" :linkAnt="'/aprende/cultura-general/dia-del-maestro-2024'" :linkSig="'/recursos/entrevistas-y-conferencias/desarrollo-humano-una-perspectiva-integral-helios-herrera'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import ContactoVue from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue';
import SigArt from '../../pagesComponents/SigArt.vue';

export default {
    components: { Comentarios, ConComentarios, ContactoVue, LikesVue, ShareP, SigArt},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '0028-ART-SER-HUMANITAS-APRENDE-TENDENCIAS-160524',
            ruta: '/aprende/tendencias/competencias-digitales-para-profesionales',
            title: 'Mejores competencias digitales para profesionales',
            image: 'https://blog.humanitas.edu.mx/images/Costo-y-requisitos-estudiar-maestria.jpg',
            text: 'Conoce cuáles son las competencias digitales para profesionales más relevantes que debes desarrollar para ser un profesional.'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx/academico/maestria/costo-y-requisitos-estudiar-maestria';

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>