<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
        <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">¿No te quedaste en la UNAM? Descubre <br class="md:block hidden"> estas alternativas</h1>
        <div class="flex flex-col justify-center items-center w-full">
            <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-200">
                <img src="/images/no-te-quedaste-unam-universidad-humanitas.webp" alt="Hay más opciones educativas para quienes no lograron ingresar a la UNAM." class="mb-6">
                <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
                    <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/aprende" class=" text-hone">Aprende</router-link> > <router-link to="/blog/aprende/tips" class=" text-hone">Tips</router-link></p>
                    <div class="flex items-start justify-start">
                        <ul class="lg:flex text-left list-disc pl-5 italic text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">31 de julio de 2024</li>  <li>Carolina Reyes Martínez</li></ul>
                    </div>
                    <p class="md:mb-4 mb-6">Si no lograste ingresar a la UNAM, no te angusties. En este artículo te presentamos diversas alternativas y opciones para seguir avanzando en tu educación.</p>
                    <p class="md:mb-4 mb-6">No ser admitido en la Universidad Nacional Autónoma de México (UNAM)  te puede desalentar. Sin embargo, recuerda que no es la única vía para alcanzar tus metas educativas y profesionales. Existen otras universidades para continuar tus estudios sin perder el impulso.</p>
                    <p class=" md:mb-8 mb-10">En este artículo, exploraremos diversas opciones y alternativas por si no aprobaste el examen de admisión.</p>
                    <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Opciones educativas si no aprobaste el examen de admisión</h2>
                    <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Universidades privadas: conócelas</h3>
                    <p class="md:mb-4 mb-6">Si no lograste ingresar a la UNAM, existen universidades privadas que ofrecen programas de becas y financiamiento para estudiantes. Algunas de las mejores opciones en México son:</p>
                    <ul class="list-disc pl-6 md:mb-8 mb-10">
                        <li><b>Tec de Monterrey:</b> El Tec de Monterrey, con más de 80 años de historia, tiene 46 licenciaturas. Otorga becas que pueden cubrir hasta el 100% de la carrera para estudiantes destacados y de bajos recursos.</li>
                        <li><b>ITAM:</b> El Instituto Tecnológico Autónomo de México (ITAM) se especializa en licenciaturas en Economía, Finanzas y Ciencias Sociales. Tiene programas de becas y requiere examen de admisión.</li>
                        <li><b>Universidad Humanitas:</b> Con 45 años de experiencia, cuenta con nueve licenciaturas: Derecho, Psicología, Administración, Contabilidad, Ciencias Políticas y Administración Pública, Diseño Gráfico, Arquitectura, Arte y Teatro, y Educación.  Además de nueve maestrías y tres doctorados. Desde 1979 brinda un conocimiento humanista e impulsa la educación universitaria con horarios y costos accesibles. Cuenta con planteles en diferentes estados de la República Mexicana.</li>
                        <li><b>Universidad Panamericana:</b> Fundada en 1967, tiene 34 licenciaturas en diversas facultades como Ciencias Económicas y Administrativas, Ingeniería, Ciencias de la Salud, Derecho, Filosofía, y más. Ofrece programas de becas y necesitas hacer examen de admisión.</li>
                        <li><b>Universidad Iberoamericana:</b> También conocida como la Ibero, cuenta con 36 licenciaturas. Destaca por su prestigio y programas de becas para estudiantes destacados o de bajos recursos.</li>
                        <li><b>Universidad Anáhuac:</b> Con 49 licenciaturas en áreas como Ciencias de la salud, Ciencias jurídicas y sociales, Comunicación, Arquitectura, Diseño y Artes, Ingeniería, Negocios y Turismo, cuenta con programas de becas.</li>
                        <li><b>Universidad de las Américas de Puebla (UDLAP):</b> La UDLAP tiene 52 programas de licenciatura en áreas como Artes y Humanidades, Ciencia, Ciencias Sociales, Ingeniería, y Negocios y Economía.</li>
                        <li><b>Universidad Jesuita de México ITESO:</b> Ubicada en Guadalajara, se enfoca en programas de Ingeniería, Humanidades y Ciencias Sociales.</li>
                        <li><b>Universidad Autónoma de Guadalajara (UAG):</b> La UAG tiene 40 carreras en modalidades presencial y en línea. Tiene programas de becas.</li>
                        <li><b>Universidad La Salle:</b> Son 28 sus licenciaturas y destaca por el sistema de becas y diversas acreditaciones.</li>
                        <li><b>Universidad de Monterrey (UDEM):</b> Con 48 programas de licenciatura, cuenta con reconocimientos internacionales.</li>
                    </ul>
                    <div class="md:mb-8 mb-10 border border-gray-300 w-full">
                        <ContactoVue class="m-3"/>
                    </div>
                    <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Universidades públicas alternativas en México</h3>
                    <p class="md:mb-4 mb-6">También puedes considerar que hay universidades públicas de gran prestigio en México que ofrecen una educación de alta calidad. Checa estas opciones:</p>
                    <ul class="list-disc pl-6 md:mb-8 mb-10">
                        <li><b>Instituto Politécnico Nacional (IPN):</b> Reconocido por sus programas en Ingeniería y Ciencias Aplicadas, el IPN ofrece una variedad de carreras técnicas y profesionales. Su enfoque en la investigación y el desarrollo tecnológico lo convierte en una opción excelente para quienes buscan una educación sólida en estas áreas.</li>
                        <li><b>Universidad Autónoma Metropolitana (UAM):</b>  Con varios campus en la Ciudad de México, la UAM es conocida por su enfoque en la investigación y su oferta académica en Ciencias Sociales, Naturales, Ingenierías y Humanidades. Además, la UAM se destaca por su compromiso con la educación accesible y de calidad.</li>
                        <li><b>Universidad de Guadalajara (UDG):</b> La UDG es una de las universidades más importantes del occidente de México, con una amplia oferta educativa y un enfoque en el desarrollo cultural y social de la región. Su ambiente multicultural y sus programas de intercambio la convierten en una opción atractiva para muchos estudiantes.</li>
                        <li><b>Benemérita Universidad Autónoma de Puebla (BUAP):</b> La BUAP ofrece una amplia gama de programas de licenciatura y posgrado, y es reconocida por su calidad educativa y sus instalaciones modernas. Además, cuenta con una fuerte vinculación con el sector empresarial y gubernamental.</li>
                        <li><b>Universidad Autónoma de Nuevo León (UANL):</b> Con campus en todo el estado de Nuevo León, la UANL es una de las universidades más grandes y prestigiosas del norte de México. Su oferta educativa abarca todas las áreas del conocimiento, desde ciencias hasta humanidades.</li>
                    </ul>
                    <img src="/images/universidad-privada-costo-accesible-alternativas.webp" alt="Considera universidades privadas con costos y horarios accesibles." class="md:mb-8 mb-10">
                    <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Universidad Humanitas: Una alternativa que debes conocer</h2>
                    <p class="md:mb-4 mb-6"><b>Universidad Humanitas</b> es una institución privada con un enfoque en el desarrollo integral de sus estudiantes y, como su nombre lo indica, con una visión humanista.</p>
                    <p class="md:mb-8 mb-10">Dos puntos  importantes son que <router-link to="/blog/ser-humanitas/comunidad/cuanto-cuesta-mensualidad-universidad-humanitas" class=" text-htwo hover:text-hone">no hay una cuota de inscripción ni examen de admisión</router-link>. También te ofrece apoyo como promociones y descuentos al iniciar tu carrera.</p>
                    <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Un poco de historia…</h3>
                    <p class="md:mb-4 mb-6"><b>Universidad Humanitas fue fundada el 30 de abril de 1979</b> y tiene presencia en la Ciudad de México con el Campus Magno Del Valle y Campus Ejecutivo Santa Fe. En el Estado de México, los Campus Magnos Los Reyes y Presa Madín.</p>
                    <p class="md:mb-4 mb-6">Un Campus Ejecutivo en Guadalajara, Jalisco; el Campus Magno en Cancún, Quintana Roo; el Campus Magno Tijuana, Baja California; el Campus Magno Mérida, Yucatán; el Campus Magno Querétaro; un Campus Ejecutivo en Cuernavaca, Morelos, y actualmente está en construcción un Campus Magno en Chihuahua. También tiene un Campus Ejecutivo Virtual.</p>
                    <p class="md:mb-4 mb-6"><b>La flexibilidad</b> es la clave para que el estudiante adquiera los conocimientos teóricos y, en los últimos trimestres, podrá integrarse al mercado laboral de manera rápida. Cuenta con tres modalidades de estudio: <b>Escolarizado, Ejecutivo y Virtual.</b></p>
                    <p class="md:mb-8 mb-10">Unirse a Humanitas significa ser parte de una comunidad vibrante y cálida, donde cada estudiante puede encontrar su lugar y su voz.</p>
                    <img src="/images/oportunidades-universidad-humanitas-sin-examen-admision.webp" alt="Mantén una actitud positiva. Hay más oportunidades educativas sin examen de admisión." class="md:mb-8 mb-10">
                    <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Conclusión</h2>
                    <p class="md:mb-4 mb-6">No ser admitido en la universidad no es el fin del camino, ya que las oportunidades son vastas y diversas.</p>
                    <p class="md:mb-8 mb-10">Lo más importante es mantener una actitud positiva y perseverante. Cada desafío es una oportunidad para aprender y crecer. Con el apoyo adecuado y la determinación necesaria, puedes encontrar la opción que mejor se adapte a tus necesidades y aspiraciones. ¡No te rindas y sigue adelante!</p>
                    <p class="text-htwo text-left mb-20">Te puede interesar: <router-link to="/blog/aprende/tips/puntos-clave-para-evaluar-la-calidad-de-una-universidad-privada"  class="text-htwo">¿Cómo evaluar la calidad de una universidad privada?</router-link></p>
                    <div class="flex justify-between md:mb-8 mb-10">
                    <LikesVue :articleID="code"/>
                    <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
                    </div>
                </div>
                <div class="flex justify-start items-start">
                    <img src="/images/barra-art.png" class="w-[15%]">
                </div>
            </div>
            <SigArt :ant="true" :linkAnt="'/aprende/tips/ansiedad-es-lo-mismo-que-angustia'" :linkSig="'/aprende/tips/que-hacer-si-no-te-quedaste-en-el-ipn'" :sig="true"/>
            <ConComentarios :article="code" class="pb-12"/>
            <Comentarios :article="code" class="pb-12"/>
        </div>
    </div>
</template>

<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import ContactoVue from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue';
import SigArt from '../../pagesComponents/SigArt.vue';
import VideoClip from '../../pagesComponents/videoClip.vue'

export default {
    components: { Comentarios, ConComentarios, ContactoVue, LikesVue, ShareP, SigArt, VideoClip},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '0057-ART-APRENDE-TIPS-310724',
            ruta: '/aprende/tips/puntos-clave-para-evaluar-la-calidad-de-una-universidad-privada',
            title: '¿Cómo evaluar la calidad de una universidad privada?',
            image: 'https://blog.humanitas.edu.mx/images/Costo-y-requisitos-estudiar-maestria.jpg',
            text: 'Si presentaste tu examen de admisión a la UNAM y no fuiste seleccionado, te damos alternativas para continuar tu educación universitaria. ¡Conócelas!'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx/academico/maestria/costo-y-requisitos-estudiar-maestria';

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>