<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">Celebrando el Día del Maestro: reconozcamos<br class="md:block hidden"> a nuestros profesores</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-300">
          <img src="/images/dia-del-maestro-mexico-humanitas.webp" class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 text-left italic text-[18px] md:text-[20px]"><router-link to="/blog/aprende" class=" text-hone">Aprende</router-link> > <router-link to="/blog/aprende/cultura-general" class=" text-hone">Cultura General</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex list-disc pl-5 italic text-left text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">14 de mayo de 2024</li>  <li>Carolina Reyes Martínez</li></ul>
            </div>
            <p class=" md:mb-4 mb-6"><b>Extracto:</b> En el corazón de cada comunidad educativa brillan los maestros, cuyo compromiso y dedicación no sólo es enseñar materias, también es formar personalidades y forjar sueños.</p>
            <p class=" md:mb-4 mb-6">El Día del Maestro es una fecha muy especial porque se reconoce y valora la labor de los maestros de México y del mundo. Esta celebración varía según el país, pero su esencia es la misma: agradecer y honrar a quienes dedican su vida a la enseñanza.</p>
            <p class=" md:mb-8 mb-10">Desde actos formales y premiaciones hasta mensajes de agradecimiento y regalos simbólicos, cada escuela o comunidad tiene su manera de festejar a los maestros. En este artículo, te diremos cómo se originó este día, su significado y las diversas maneras en que podemos darles nuestra gratitud.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">¿Cuál es el origen del Día del Maestro en México?</h2>
            <p class=" md:mb-4 mb-6">El Día del Maestro refleja la diversidad cultural y las tradiciones educativas de cada país. En México, el 15 de mayo es el día para honrar a los maestros, pero, ¿por qué se celebra el Día del Maestro en México? ¿Quién lo decretó? La fecha se estableció durante el gobierno del presidente Venustiano Carranza en 1917.</p>
            <p class=" md:mb-4 mb-6">En ese año se promulgó la Carta Magna de nuestro país, pero también los legisladores Benito Ramírez y Enrique Viesca, promovieron una iniciativa para que un día del año se reconociera a los trabajadores de la educación.</p>
            <p class=" md:mb-4 mb-6">Varios de los legisladores que apoyaron ésta, eran docentes y se eligió el día 15 de mayo de 1867 se suscitó el nacimiento de la República cuando se llevó a cabo la Toma de Querétaro, cuando el Ejército doblegó a las fuerzas imperiales de Maximiliano de Habsburgo.Terminaban tres años del Imperio y se instituía el gobierno del presidente Benito Juárez.</p>
            <p class=" md:mb-4 mb-6">Así se instituye el 15 de mayo como Día del Maestro, por lo que en 1918 se celebra por primera vez. A nivel mundial, la Organización de las Naciones Unidas para la Educación, la Ciencia y la Cultura, estableció en el año de 1994, el Día Mundial de los Docentes.</p>
            <p class=" md:mb-8 mb-10">También esta fecha coincide con una celebración religiosa, ya que el 15 de mayo de 1950, en El Vaticano, el Papa Pío XII declaró la fecha como día de Juan Bautista de La Salle, patrono de los docentes.</p>
            <img src="/images/dia-del-maestro-reconocer-labor-docente.webp" alt="En el Día del Maestro se reconoce la labor de los docentes y su aportación al país" class="md:mb-8 mb-10">
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
              <Contacto class="m-3"/>
            </div>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">La evolución de la enseñanza en México</h2>
            <p class=" md:mb-4 mb-6">La forma en la que ha evolucionado la labor de los maestros es significativa. En la época Prehispánica existían dos lugares en los que se formaban los niños y los jóvenes: el Tepochcalli donde los jóvenes aprendían a ser útiles para la comunidad, aprendían un oficio y también se preparaban para la guerra. El Calmécac era el lugar a donde la elite mexica enviaba a sus hijos para formarse como líderes.</p>
            <p class=" md:mb-4 mb-6">Cuando llegaron los españoles, los sacerdotes se encargaron de educar a los indígenas para que se convirtieran al cristianismo. Les enseñaban la doctrina y el arte religioso, y también crearon colegios e internados para ellos.</p>
            <p class=" md:mb-4 mb-6">Es en el Siglo XIX cuando llega la corriente positivista que transmitió el orden y el progreso; para el siglo XX, si bien el artículo 3° contempla que todo ciudadano tiene el derecho a recibir educación, no se tenía una estructura para llevar a cabo este mandato, por lo cual se designa al intelectual José Vasconcelos Calderón, también llamado el Apóstol de la educación, para hacer realidad esto.</p>
            <p class=" md:mb-4 mb-6">Él buscaba que esta institución atendiera la educación, las bibliotecas públicas y la cultura, para preparar a las personas y que tuvieran un oficio o una profesión. Estos ideales tuvieron eco y así, el presidente Álvaro Obregón designa a José Vasconcelos como primer secretario de Educación Pública.</p>
            <p class=" md:mb-4 mb-6">Así se inició el camino de la educación y también de la alfabetización indígena. De 1919 a 1921 que se creó la SEP, la matrícula y el número de escuelas crecieron. Comenzaron las misiones culturales en las que los maestros recorrieron el país para enseñar a miles de alumnos.</p>
            <p class=" md:mb-4 mb-6">Los maestros eran vistos como agentes de cambio y de respeto. Actualmente, los docentes son honrados por su trabajo y dedicación, y se han enfrentado a los cambios tecnológicos que han ocurrido velozmente a raíz de la pandemia de Covid.</p>
            <p class=" md:mb-8 mb-10">Este día es visto no sólo como una oportunidad para agradecer a los maestros, sino también para reflexionar sobre el papel crucial que juegan en el desarrollo de las futuras generaciones.</p>
            <img src="/images/dia-del-maestro-humanitas-el-valor-social.webp" alt="El Día del Maestro es una oportunidad para reflexionar sobre el valor de los docentes" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Celebremos el Día del Maestro</h2>
            <p class=" md:mb-4 mb-6">Reconocer y agradecer el inmenso esfuerzo y compromiso de los maestros hacia la educación y formación, es fundamental. Los docentes son pilares fundamentales en la construcción de una sociedad más informada, crítica y preparada.</p>
            <p class=" md:mb-8 mb-10">El Día del Maestro es más que una simple fecha: es la oportunidad para reflexionar sobre el valor de los profesores en nuestras vidas y en la sociedad. ¡Celebremos a aquellos que nos enseñan no sólo conocimientos, también valores y sueños!</p>
            <p class="text-htwo text-left md:mb-4 mb-6">Explora más: <a href="https://humanitas.edu.mx/" target="_blank" class="text-htwo">Universidad Humanitas</a></p>
            <p class="text-htwo text-left mb-20">Te puede interesar: <router-link to="/blog/academico/estudia-en-linea/metodologias-aprendizaje-linea"  class="text-htwo">Conoce las metodologías del aprendizaje en línea</router-link></p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="true" :linkAnt="'/recursos/entrevistas-y-conferencias/liderazgo-femenino-empresarial-exitoso'" :linkSig="'/aprende/tendencias/competencias-digitales-para-profesionales'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import Contacto from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue'
import SigArt from '../../pagesComponents/SigArt.vue';

export default {
    components: { Comentarios, ConComentarios, Contacto, LikesVue, ShareP, SigArt},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '0025-ART-APRENDE-LICENCIATURA-CULTURA-GRAL-140524',
            ruta: '/aprende/cultura-general/dia-del-maestro-2024',
            title: 'Celebrando el Día del Maestro: reconozcamos a nuestros profesores',
            image: 'https://blog.humanitas.edu.mx/images/Día-internacional-de-la-mujer-financiar-acelera-igualdad-genero-2024.jpg',
            text: 'La importancia del Día del Maestro: cómo se celebra en México y la influencia de los profesores en la sociedad. ¡Descubre más!'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx'+this.ruta;

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>