<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">Cómo identificar y superar relaciones <br class="md:block hidden"> tóxicas: Lo que debes saber</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-200">
          <img src="/images/identifica-relaciones-toxicas.webp" alt="Las relaciones tóxicas merman la calidad de un noviazgo o de un vínculo emocional." class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/recursos" class=" text-hone">Recursos</router-link> > <router-link to="/blog/recursos/casos-reales" class=" text-hone">Casos Reales</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex text-left list-disc pl-5 italic text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">02 de julio de 2024</li>  <li>Carolina Reyes Martínez</li></ul>
            </div>
            <p class="md:mb-8 mb-10"><b>Extracto:</b> Identificar y superar una relación tóxica es necesario para tu bienestar emocional y mental.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">¿Qué es una relación tóxica?</h2>
            <p class="md:mb-4 mb-6"><b>Una relación tóxica es aquella que genera un daño o malestar a una o a más personas.</b> Se trata de relaciones destructivas de las que resulta difícil salir, pues hay una dependencia emocional.</p>
            <p class="md:mb-4 mb-6"><b>El sentimiento que define a este tipo de relación es el sufrimiento.</b> No siempre resulta sencillo identificarlo porque a veces las personas no lo quieren ver, pues ese malestar suele ser sutil y crece poco a poco.</p>
            <p class="md:mb-4 mb-6">Cuando hablamos de relaciones tóxicas también pueden existir en la familia, con los amigos o compañeros de trabajo, además del noviazgo.</p>
            <p class="md:mb-8 mb-10">A decir de <b>Alejandro Córdova Castañeda, psiquiatra y fundador de la Asociación Iberolatinoamericana de Neurociencias y Psiquiatría, “las posibilidades de salir de una relación tóxica</b> por sí solo es muy difícil, sin embargo, es posible volver a recuperar la autoestima, la confianza en uno mismo. <b>Es posible mediante un camino terapéutico,</b> tener la conciencia: ‘voy recuperando mi entorno propio, el contacto con mi familia, con los amigos y las amigas, una serie de actividades de restitución y reparación de uno mismo’”.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">¿Cuáles son las características de una relación tóxica?</h2>
            <p class=" md:mb-4 mb-6">Las principales red flags o advertencias son:</p>
            <ul class="list-disc pl-6 md:mb-8 mb-10">
                <li>Es una relación que te impide avanzar. Se te señalan todos tus defectos o lo que para la otra persona todo lo que “haces mal”, pero lo que realizas de una manera asertiva o positiva es ignorado.</li>
                <li>Constantemente te recuerda todo lo que hace por ti, pero menosprecia lo que tú has hecho por la persona, además de que de manera sutil o directa te dice que no serías nada sin él/ella.</li>
                <li>Usa el chantaje emocional.</li>
                <li>Quiere controlar todo: la manera en la que te vistes, quiénes y por qué sales con tus amigos, quiere planificar tu vida sin solicitar tu opinión.</li>
                <li>Cuestiona a las personas que son cercanas a ti, sea tu familia, tus amigos o amigas, los critica e intenta alejarlos.</li>
                <li>Los celos a menudo están presentes, desconfía de todos y quiere estar al tanto de tus redes sociales, de tu teléfono móvil, incluso te pide tus contraseñas para revisar con quién estás en contacto.</li>
                <li>Hace comparaciones de ti con sus anteriores parejas, quiere que sientas que te hace un favor al salir o estar contigo.</li>
                <li>Es una relación que se basa en el sufrimiento y eso conlleva a la falta de respeto: te critica tanto en el ámbito privado como en público. Los insultos van aumentando conforme va avanzando la relación.</li>
            </ul>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">¿Cómo se siente una persona que se encuentra en una relación tóxica?</h3>
            <p class=" md:mb-4 mb-6">Para el psiquiatra Alejandro Córdova, <b>se establece esta relación tóxica de sufrimiento, al responsabilizar o culpar al otro, pues poco a poco la persona que padece la relación, va sintiendo que no sirve,</b> que no vale, su autoestima e imagen se va deteriorando al pensar “no agrado, no soy inteligente”.</p>
            <p class=" md:mb-4 mb-6"><b>“Si se pierde la autoconfianza, la gente deja de hacer las cosas,</b> deja de desenvolverse o querer incluso salir; por el otro lado, hay culpa y ésta no ayuda en los vínculos. La culpa ayuda a establecer de qué forma la persona afectada tendrá que reparar un falso daño al otro, si añades que ya no hay una buena autoestima, que ya es suficientemente baja, eso establece un vínculo bastante tóxico”.</p>
            <p class=" md:mb-8 mb-10">La persona puede sentir que su felicidad ha disminuido desde que mantiene esta relación, que no puede decidir sobre lo que quiere ni expresarse como le gustaría, siente que a pesar de todo, no puede terminar con su pareja.</p>
            <!-- Aqui va el componente de contacto, no se te olvide hacerlo manolo -->
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
                <ContactoVue class="m-3"/>
            </div>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">¿Por qué se puede caer en una relación tóxica?</h3>
            <p class="md:mb-4 mb-6">La criminología se apoya en cuatro áreas:</p>
            <ul class="list-disc pl-6 md:mb-8 mb-10">
                <li>Tener una baja autoestima, pues se da más importancia a lo que piensen los demás y no a lo que realmente la persona quiere hacer y cómo expresarse tal como es, por lo que en una relación tóxica tomará una postura pasiva y complaciente.</li>
                <li>También si ya se ha tenido una o varias relaciones tóxicas, pensar que ésa es la única manera de vincularse emocionalmente y que se merece ese trato.</li>
                <li>Tener una idea errónea sobre lo qué significa el amor o tener una relación, por lo que algunas actitudes podrían verse como normales, sin embargo, en una relación sana no lo serían, como por ejemplo, burlarse de la persona o regañarla en público.</li>
            </ul>
            <img src="/images/como-superar-relaciones-toxicas.webp" alt="Reconocer que se está en una relación tóxica es el primer paso para salir de ella." class="md:mb-8 mb-10">
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Algunos tipos de relaciones tóxicas</h3>
            <p class="md:mb-4 mb-6">Si bien nos hemos centrado en la relación de noviazgo y de pareja, también hay otros vínculos que pueden tornarse tóxicos y generar un acompañamiento tóxico. Te informamos de algunos como:</p>
            <ul class="list-decimal pl-6 md:mb-4 mb-6">
                <li><b>Codependencia.</b> Las personas son pasivas y dependen uno del otro, las necesidades individuales no están claras y no se satisfacen, se tiende a hacer todo con la aprobación ya que de no tenerla, es imposible moverse. A largo plazo, inutiliza a las personas para tomar decisiones propias.</li>
                <li><b>Anclarse en el pasado.</b> Se inicia un nuevo vínculo pero sin haber cerrado uno anterior, por lo que todo el tiempo hay comparaciones.</li>
                <li><b>Relación de llenado.</b> La persona o personas sienten un vacío que pretenden “llenar” con otra u otras personas, evadiendo la responsabilidad de sentirse plenos o completos consigo mismos.</li>
                <li><b>Pasivo-agresiva.</b> La comunicación no es clara, no es abierta, se realiza a través de indirectas, dando por hecho las cosas, o que de alguna forma se tengan que adivinar. El resultado es que siempre hay malentendidos y conflictos.</li>
                <li><b>Relaciones basadas en las mentiras.</b> oda relación sana se basa en la confianza mutua, pero cuando existen engaños, mentiras, se oculta la información, sólo crean incertidumbre y dudas.</li>
                <li><b>Relación idealizada.</b> Generar expectativas que nunca se comparten o se hablan, ya que al ver que el otro o las otras personas no son lo que se esperaba o se imaginaba, hay confusión y decepción.</li>
                <li><b>Relación basada en el miedo.</b> Cuando el maltrato físico y psicológico están arraigados en el núcleo familiar o de pareja, las personas no pueden huir de ese entorno tan fácilmente. Es necesaria una intervención incluso hasta de las autoridades, ya que la agresión puede escalar.</li>
                <li><b>Roles establecidos.</b> En esta situación, hay una aceptación de quién hace qué, quién controla y quién es sumiso en el vínculo. Hay un equilibrio neurótico pero en realidad nadie gana.</li>
                <li><b>Relación unilateral.</b> Como su nombre indica, sólo una parte está interesada en que la relación funcione, esa persona es la que se esfuerza, mientras que la otra la ignora, es indiferente. No hay una mutua correspondencia en cuidado y apoyo.</li>
            </ul>
            <img src="/images/superar-relaciones-toxicas-terapia-bienestar-emocional.webp" alt="El apoyo terapéutico, familiar y social es muy importante para dejar atrás las relaciones tóxicas." class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">¿Cómo prevenir y salir de las relaciones tóxicas?</h2>
            <p class="md:mb-4 mb-6"><b>El primer paso es reconocer que algo no va bien,</b> después, <b>dejar la vergüenza y hablar con las personas con las que se tiene confianza</b> ayudará a tener otras perspectivas, así como recibir ayuda terapéutica.</p>
            <p class="md:mb-4 mb-6">De acuerdo con Alejandro Córdova, si la persona ya se dio cuenta que su relación es tóxica, puede acudir a una terapia psicológica. Si ambas personas o la familia ya tomó conciencia pueden trabajar su desarrollo emocional de manera individual.</p>
            <p class="md:mb-4 mb-6">“Si bien el problema no se va a resolver de la noche a la mañana, lo ideal es darse un tiempo y resolver este asunto de la vida. Si la relación no se destruyó lo suficiente, es probable tener un nuevo intento, una nueva oportunidad para una vida más sana. O si no pueden restablecer el vínculo, tener un cierre adecuado para no hacerse más daño”, comentó el especialista.</p>
            <p class="md:mb-4 mb-6"><b>El apoyo familiar, de amistad o social,</b> es una buena estrategia para evitar o afrontar las relaciones tóxicas, ya que provee a la persona de consejos, cuidado, afecto, consuelo y ayuda ante situaciones de conflicto.</p>
            <p class="md:mb-8 mb-10">La persona afectada puede tener esta orientación y auxilio de su red inmediata, pues ayuda a disminuir el estrés y la sensación de indefensión, favorece la autoestima, genera un sentimiento de pertenencia para afrontar la situación tóxica.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Y sobre todo, pon atención a estas red flags</h2>
            <p class="md:mb-4 mb-6">Toma en cuenta las actitudes de las personas potencialmente tóxicas como éstas:</p>
            <ul class="list-disc pl-6 md:mb-8 mb-10">
                <li>Cuando <b>no aprecian lo positivo</b> que sucede en su vida.</li>
                <li><b>Sólo se preocupan por sí mismos.</b></li>
                <li><b>Son autoritarios.</b></li>
                <li><b>Se victimizan</b> para conseguir lo que quieren.</li>
                <li><b>Lastiman</b> intencionalmente.</li>
                <li><b>Siempre culpan</b> a las otras personas.</li>
                <li><b>Engañan y manipulan</b> con tal de mantenerse en una posición de poder.</li>
                <li><b>Desprestigian</b> a otros.</li>
                <li><b>Prometen cosas y no las cumplen.</b></li>
                <li><b>Comparan a los demás</b> de manera lastimosa o cruel.</li>
            </ul>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Conclusión</h2>
            <p class="md:mb-8 mb-10">Enfrentar una relación tóxica no es fácil, <b>pero con el apoyo adecuado y un enfoque en el bienestar emocional,</b> es posible lograr una transformación positiva y encontrar un camino hacia relaciones más saludables y felices.</p>
            <p class=" text-htwo text-left mb-20">Si quieres saber más, ve la conferencia de Alejandro Córdova, psiquiatra: <a href="https://www.youtube.com/watch?v=H0OPoT9FLio" target="_blank" class="text-htwo hover:text-hone">La dependencia y la culpa como base en las relaciones tóxicas</a></p>
            <div class="flex justify-between items-center md:mb-6 mb-8">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="true" :linkAnt="'/aprende/cultura-general/dia-mundial-redes-sociales-30-junio-2024'" :linkSig="'/aprende/tips/ansiedad-es-lo-mismo-que-angustia'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import ContactoVue from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue';
import SigArt from '../../pagesComponents/SigArt.vue';

export default {
    components: { Comentarios, ConComentarios, ContactoVue, LikesVue, ShareP, SigArt},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '0053-ART-RECURSOS-CASOS-REALES-020724',
            ruta: '/recursos/casos-reales/perfil-criminologico-como-descifra-la-mente-criminal',
            title: '27 de marzo Día Mundial del Teatro 2024: celebra la paz y el arte',
            image: 'https://blog.humanitas.edu.mx/images/Costo-y-requisitos-estudiar-maestria.jpg',
            text: 'Aprende a reconocer los signos de una relación tóxica y cómo prevenirlos para vivir una vida más sana y feliz.'
        }
    },
    methods: {
    }
}
</script>

<style>

</style>