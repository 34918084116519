import SerHumActCamp from "../pages/Blog/SerHuma/SerHum-ActCamp.vue";
import SerHumCE from "../pages/Blog/SerHuma/SerHum-CE.vue";
import SerHumRespoS from "../pages/Blog/SerHuma/SerHum-RespoS.vue";
import SerHumTesti from "../pages/Blog/SerHuma/SerHum-Testi.vue";
import SerComun from "../pages/Blog/SerHuma/SerHum-Comunidad.vue" 
import actCampRoutes from "./SerHum/actCampRoutes.ts";
import casosERoutes from "./SerHum/casosERoutes.ts";
import respSocRoutes from "./SerHum/respSocRoutes.ts";
import testimoniosRoutes from "./SerHum/testimoniosRoutes.ts";
import comunidadRoutes from "./SerHum/comunidadRoutes.ts"

export default [
    {
        path: '/blog/ser-humanitas/actividades-en-campus',
        name: 'Ser Humanitas: Actividades en Campus',
        component: SerHumActCamp,
        meta: {
            requireAuth: false
        },
    },
    ...actCampRoutes,
    {
        path: '/blog/ser-humanitas/casos-de-exito',
        name: 'Ser Humanitas: Casos de Éxito',
        component: SerHumCE,
        meta: {
            requireAuth: false
        },
    },
    ...casosERoutes,
    {
        path: '/blog/ser-humanitas/responsabilidad-social',
        name: 'Ser Humanitas: Responsabilidad Social',
        component: SerHumRespoS,
        meta: {
            requireAuth: false
        },
    },
    ...respSocRoutes,
    {
        path: '/blog/ser-humanitas/testimonios',
        name: 'Ser Humanitas: Testimonios',
        component: SerHumTesti,
        meta: {
            requireAuth: false
        },
    },
    ...testimoniosRoutes,
    {
        path: '/blog/ser-humanitas/comunidad',
        name: 'Ser Humanitas: Comunidad',
        component: SerComun,
        meta: {
            requireAuth: false
        },
    },
    ...comunidadRoutes,
]