import { defineStore } from 'pinia';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';


const useAuth = defineStore('auth', {
    state: () => {
        return {
            user: '',
            baseURL: '',
            //baseURL: '',
            token: null,
            isAuthenticated: false,
        }
    },
    actions: {
        // async login(email:string, password:string) {
        //     //Lógica para iniciar sesión
        //     const url = `${this.baseURL}/blog/admin-page`
        //     const rawResponse = await fetch(url, {
        //         method: 'POST',
        //         headers: {
        //             'Content-type': 'Application/json',
        //             'Accept': 'Application/json'
        //         },
        //         body: JSON.stringify({
        //             'email': email,
        //             'password': password
        //         })
        //     })
        //     //@ts-ignore
        //     const response = await rawResponse.json()
        //     if(response.status == false){
        //         this.token = null
        //         return false
        //     } else {
        //         this.token = response.token
        //         return true
        //     }
        async login(email: string, password: string) {
            const auth = getAuth();
            try {
              const userCredential = await signInWithEmailAndPassword(auth, email, password);
              // @ts-ignore
              this.user = userCredential.user;
              return true;
            } catch (error) {
              console.error('Error during login:', error);
              return false;
            }
          },
    },
});

export default useAuth;

