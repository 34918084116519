import axios from 'axios';
<template>
    <div class="flex items-center">
      <img :src="likeIcon" alt="Like" class="like-icon cursor-pointer mr-4" @click="toggleLike">
      <div class="flex">
        <span class="ml-2 text-[#881912] text-[18px]" style="font-family: 'Helvetica Neue Regular', sans-serif;">{{ likeCount }} </span>
        <p class="hidden md:block text-[#881912] ml-2 text-[18px]"> Likes</p>
      </div>
    </div>
</template>
  
<script lang="js">
import axios from 'axios';
import { ConsultasBlog } from '@/main';

export default {
  props: {
    articleID: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      isLiked: false,
      likeCount: 0,
      likeIconInactive: '/images/icono-like-blanco.png',
      likeIconActive: '/images/icono-like.png'
    };
  },
  computed: {
    likeIcon() {
      return this.isLiked ? this.likeIconActive : this.likeIconInactive;
    }
  },
  mounted() {
    this.fetchLikes();
  },
  methods: {
    async toggleLike() {
      try {
        if (this.isLiked) {
          // Si ya está marcado como "like", removemos el like
          axios.get(ConsultasBlog, {
            params: {
              action: 'removeLike',
              params: JSON.stringify([this.articleID])
            }
          });
            this.likeCount--;
            this.isLiked = false;
        } else {
          // Si no está marcado como "like", añadimos el like
           axios.get(ConsultasBlog, {
            params: {
              action: 'addLike',
              params: JSON.stringify([this.articleID])
            }
          });
            this.likeCount++;
            this.isLiked = true;
        }
      } catch (error) {
        console.error('Error al cambiar el like:', error);
      }
    },
    async fetchLikes() {
      try {
        const response = await axios.get(ConsultasBlog, {
          params: {
            action: 'obtenerLikes',
            params: JSON.stringify([this.articleID])
          }
        });
        if (response.data.error) {
          console.error(response.data.error);
        } else {
          this.likeCount = response.data.likes;
        }
      } catch (error) {
        console.error('Error al obtener los likes:', error);
      }
    }
  }
};
</script>

<style scoped>
.like-icon {
  width: 30px;
}
</style>