import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import HomePage from '@/pages/HomePage.vue';
import NosotrosPage from '@/pages/NosotrosPage.vue';
import ResponPage from '@/pages/ResponPage.vue';
import ModeloEjecPage from '@/pages/ModeloEjecPage.vue';
import ModeloEscoPage from '@/pages/ModeloEscoPage.vue';
//import ModeloVirtPage from '@/pages/ModeloVirtPage.vue';

import OfertaLicPage from '@/pages/OfertaLicPage.vue';
//import OfertaLicVirtPage from '@/pages/OfertaLicVirtPage.vue'
import LicAdminPage from '@/pages/licenciatura/LicAdminPage.vue';
import LicArquiPage from '@/pages/licenciatura/LicArquiPage.vue';
import LicArtePage from '@/pages/licenciatura/LicArtePage.vue';
import LicCienciasPage from '@/pages/licenciatura/LicCienciasPage.vue';
import LicContaPage from '@/pages/licenciatura/LicContaPage.vue';
import LicDerechoPage from '@/pages/licenciatura/LicDerechoPage.vue';
import LicDisenoPage from '@/pages/licenciatura/LicDisenoPage.vue';
import LicEducaPage from '@/pages/licenciatura/LicEducaPage.vue';
import LicPsicPage from '@/pages/licenciatura/LicPsicPage.vue';
import LicRequisitosPage from '@/pages/licenciatura/LicRequisitosPage.vue';

import OfertaMtrPage from '@/pages/OfertaMtrPage.vue';
//import OfertaMtrVirtPage from '@/pages/OfertaMtrVirtPage.vue';
import MtrAltaDirPage from '@/pages/maestria/MtrAltaDirPage.vue';
import MtrContaImpPage from '@/pages/maestria/MtrContaImpPage.vue';
import MtrDerechoEmpPage from '@/pages/maestria/MtrDerechoEmpPage.vue';
import MtrDerechoFiscalPage from '@/pages/maestria/MtrDerechoFiscalPage.vue';
import MtrDerechoPenalPage from '@/pages/maestria/MtrDerechoPenalPage.vue';
import MtrEducaPage from '@/pages/maestria/MtrEducaPage.vue';
import MtrJusticiaPenalPage from '@/pages/maestria/MtrJusticiaPenalPage.vue';
import MtrMercadotecniaPage from '@/pages/maestria/MtrMercadotecniaPage.vue';
import MtrPsicoterapiaPage from '@/pages/maestria/MtrPsicoterapiaPage.vue';
import MtrRequisitosPage from '@/pages/maestria/MtrRequisitosPage.vue';

import OfertaDocPage from '@/pages/OfertaDocPage.vue';
import DocAltaDirPage from '@/pages/doctorado/DocAltaDirPage.vue';
import DocDerechoPage from '@/pages/doctorado/DocDerechoPage.vue';
import DocEducaPage from '@/pages/doctorado/DocEducaPage.vue';
import DocRequisitosPage from '@/pages/doctorado/DocRequisitosPage.vue';

import EducacionContinuaPage from '@/pages/diplomado/EducacionContinuaPage.vue';
import EnglishPage from '@/pages/idioma/EnglishPage.vue';
import AccesoUsrPage from '@/pages/AccesoUsrPage.vue';

import AvisoPrivPage from '@/pages/AvisoPrivPage.vue';
import BolsaTrabajoPage from '@/pages/BolsaTrabajoPage.vue';
import CampusPage from '@/pages/CampusPage.vue';
import ContactoPage from '@/pages/ContactoPage.vue';
import DespiertaPage from '@/pages/DespiertaPage.vue';

import mainRoutes from './router/mainRoutes.ts';
import LoginCommentPage from '@/pages/Blog/LoginCommentPage.vue';
import AdminCommentPage from '@/pages/Blog/AdminCommentPage.vue';

import { getAuth } from 'firebase/auth';

const routes: RouteRecordRaw[] = [
		{ path: '/', component: HomePage, name: 'Universidad Humanitas',
			meta: {
				title: "Universidad Humanitas | 45 años de excelencia académica",
				description: "Impulsamos tu futuro: Licenciaturas, posgrados y educación continua. Horarios y costos accesibles. Modalidades presencial, ejecutiva y virtual. Logra la mejor versión de ti con nosotros ¡Inscríbete!",
				image: "https://cloudinary-assets.dostuffmedia.com/res/dostuff-media/image/upload/venue-471080/1635014689.jpg",
				metaTags: {
					"og:title": "Universidad Humanitas - 45 años de excelencia académica",
					"og:description": "Impulsamos tu futuro: Licenciaturas, posgrados y educación continua. Horarios y costos accesibles. Modalidades presencial, ejecutiva y virtual. Logra la mejor versión de ti con nosotros ¡Inscríbete!",
					"og:image": "https://cloudinary-assets.dostuffmedia.com/res/dostuff-media/image/upload/venue-471080/1635014689.jpg",
				},
				requireAuth: false
			},
		},
		{ path: '/nosotros', component: NosotrosPage, name: 'Nosotros',
			meta: {
				title: "Universidad Humanitas | Nosotros",
				requireAuth: false
			},
		},
		{ path: '/responsabilidad', component: ResponPage, name: 'Responsabilidad',
			meta: {
				title: "Universidad Humanitas | Responsabilidad",
				requireAuth: false
			} 
		},
		{ path: '/ejecutivo', component: ModeloEjecPage, name: 'Modelo Ejecutivo',
			meta: {
				title: "Universidad Humanitas | Modelo ejecutivo",
				requireAuth: false
			} 
		},
		{ path: '/escolarizado', component: ModeloEscoPage, name: 'Modelo Escolarizado',
			meta: {
				title: "Universidad Humanitas | Modelo escolarizado",
				requireAuth: false
			} 
		},
		//{ path: '/virtual', component: ModeloVirtPage, name: 'Modelo Virtual' },
		{ path: '/licenciaturas', component: OfertaLicPage, name: 'Licenciaturas',
			meta: {
				title: "Universidad Humanitas | Licenciaturas",
				requireAuth: false
			} 
		},
		//{ path: '/licenciaturas-virtual', component: OfertaLicVirtPage, name: 'Licenciaturas Virtual' },
		{ path: '/licenciatura-en-administracion', component: LicAdminPage, name: 'Licenciatura en Administración',
			meta: {
				title: "Universidad Humanitas | Licenciatura en Administración",
				requireAuth: false
			} 
		},
		{ path: '/licenciatura-en-arquitectura', component: LicArquiPage, name: 'Licenciatura en Arquitectura',
			meta: {
				title: "Universidad Humanitas | Licenciatura en Arquitectura",
				requireAuth: false
			} 
		},
		{ path: '/licenciatura-en-arte-y-teatro', component: LicArtePage, name: 'Licenciatura en Arte y Teatro',
			meta: {
				title: "Universidad Humanitas | Licenciatura en Arte y Teatro",
				requireAuth: false
			} 
		},
		{ path: '/licenciatura-en-ciencias-politicas', component: LicCienciasPage, name: 'Licenciatura en Ciencias Políticas y Administración Pública',
			meta: {
				title: "Universidad Humanitas | Licenciatura en Ciencias Políticas y Administración Pública",
				requireAuth: false
			} 
		},
		{ path: '/licenciatura-en-contabilidad', component: LicContaPage, name: 'Licenciatura en Contabilidad',
			meta: {
				title: "Universidad Humanitas | Licenciatura en Contabilidad",
				requireAuth: false
			} 
		},
		{ path: '/licenciatura-en-derecho', component: LicDerechoPage, name: 'Licenciatura en Derecho',
			meta: {
				title: "Universidad Humanitas | Licenciatura en Derecho",
				requireAuth: false
			} 
		},
		{ path: '/licenciatura-en-diseno-grafico', component: LicDisenoPage, name: 'Licenciatura en Diseño Gráfico',
			meta: {
				title: "Universidad Humanitas | Licenciatura en Diseño Gráfico",
				requireAuth: false
			} 
		},
		{ path: '/licenciatura-en-educacion', component: LicEducaPage, name: 'Licenciatura en Educación',
			meta: {
				title: "Universidad Humanitas | Licenciatura en Educación",
				requireAuth: false
			} 
		},
		{ path: '/licenciatura-en-psicologia', component: LicPsicPage, name: 'Licenciatura en Psicología',
			meta: {
				title: "Universidad Humanitas | Licenciatura en Psicología",
				requireAuth: false
			} 
		},
		{ path: '/requisitos-licenciatura', component: LicRequisitosPage, name: 'Requisitos de Inscripción Licenciatura',
			meta: {
				title: "Universidad Humanitas | Requisitos de Inscripción Licenciatura",
				requireAuth: false
			} 
		},
		{ path: '/maestrias', component: OfertaMtrPage, name: 'Maestrías',
			meta: {
				title: "Universidad Humanitas | Maestrías",
				requireAuth: false
			} 
		},
		//{ path: '/maestrias-virtual', component: OfertaMtrVirtPage, name: 'Maestrías Virtual' },
		{ path: '/maestria-en-alta-direccion', component: MtrAltaDirPage, name: 'Maestría en Alta Dirección Corporativa',
			meta: {
				title: "Universidad Humanitas | Maestría en Alta Dirección Corporativa",
				requireAuth: false
			} 
		},
		{ path: '/maestria-en-contabilidad-e-impuestos', component: MtrContaImpPage, name: 'Maestría en Contabilidad con Especialidad en Impuestos',
			meta: {
				title: "Universidad Humanitas | Maestría en Contabilidad con especialidad en impuestos",
				requireAuth: false
			} 
		},
		{ path: '/maestria-en-derecho-corporativo', component: MtrDerechoEmpPage, name: 'Maestría en Derecho Empresarial Corporativo',
			meta: {
				title: "Universidad Humanitas | Maestría en Derecho Empresarial Corporativo",
				requireAuth: false
			} 
		},
		{ path: '/maestria-en-derecho-fiscal', component: MtrDerechoFiscalPage, name: 'Maestría en Derecho Fiscal',
			meta: {
				title: "Universidad Humanitas | Maestría en Derecho Fiscal",
				requireAuth: false
			} 
		},
		{ path: '/maestria-en-derecho-penal', component: MtrDerechoPenalPage, name: 'Maestría en Derecho Penal',
			meta: {
				title: "Universidad Humanitas | Maestría en Derecho Penal",
				requireAuth: false
			} 
		},
		{ path: '/maestria-en-educacion', component: MtrEducaPage, name: 'Maestría en Educación',
			meta: {
				title: "Universidad Humanitas | Maestría en Educación",
				requireAuth: false
			}
		},
		{ path: '/maestria-en-justicia-penal', component: MtrJusticiaPenalPage, name: 'Maestría en Justicia Penal y Seguridad Pública',
			meta: {
				title: "Universidad Humanitas | Maestría en Justicia Penal y Seguridad Pública",
				requireAuth: false
			} 
		},
		{ path: '/maestria-en-mercadotecnia', component: MtrMercadotecniaPage, name: 'Maestría en Mercadotecnia',
			meta: {
				title: "Universidad Humanitas | Maestría en Mercadotecnia",
				requireAuth: false
			} 
		},
		{ path: '/maestria-en-psicoterapia', component: MtrPsicoterapiaPage, name: 'Maestría en Psicoterapia con un Enfoque Psicoanalítico',
			meta: {
				title: "Universidad Humanitas | Maestría en Psicoterapia con un Enfoque Psicoanalítico",
				requireAuth: false
			} 
		},
		{ path: '/requisitos-maestria', component: MtrRequisitosPage, name: 'Requisitos de Inscripción Maestría',
			meta: {
				title: "Universidad Humanitas | Requisitos de Inscripción Maestría",
				requireAuth: false
			} 
		},
		{ path: '/doctorados', component: OfertaDocPage, name: 'Doctorados',
			meta: {
				title: "Universidad Humanitas | Doctorados",
				requireAuth: false
			} 
		},
		{ path: '/doctorado-en-alta-direccion', component: DocAltaDirPage, name: 'Doctorado en Alta Dirección',
			meta: {
				title: "Universidad Humanitas | Doctorado en Alta Dirección",
				requireAuth: false
			}
		},
		{ path: '/doctorado-en-derecho', component: DocDerechoPage, name: 'Doctorado en Derecho',
			meta: {
				title: "Universidad Humanitas | Doctorado en Derecho",
				requireAuth: false
			} 
		},
		{ path: '/doctorado-en-educacion', component: DocEducaPage, name: 'Doctorado en Educación',
			meta: {
				title: "Universidad Humanitas | Doctorado en Educación",
				requireAuth: false
			} 
		},
		{ path: '/requisitos-doctorado', component: DocRequisitosPage, name: 'Requisitos de Inscripción Doctorado',
			meta: {
				title: "Universidad Humanitas | Requisitos de Inscripción Doctorado",
				requireAuth: false
			} 
		},
		{ path: '/educacion-continua', component: EducacionContinuaPage, name: 'Educación Continua',
			meta: {
				title: "Universidad Humanitas | Educación Continua",
				requireAuth: false
			} 
		},
		{ path: '/english-kingdom', component: EnglishPage, name: 'English Kingdom',
			meta: {
				title: "Universidad Humanitas | English Kingdom",
				requireAuth: false
			} 
		},
		
		{ path: '/usuarios', component: AccesoUsrPage, name: 'Usuarios',
			meta: {
				title: "Universidad Humanitas | Usuarios",
				requireAuth: false
			} 
		},
		{ path: '/aviso-de-privacidad', component: AvisoPrivPage, name: 'Aviso de Privacidad',
			meta: {
				title: "Universidad Humanitas | Aviso de Privacidad",
				requireAuth: false
			} 
		},
		{ path: '/bolsa-de-trabajo', component: BolsaTrabajoPage, name: 'Bolsa de Trabajo',
			meta: {
				title: "Universidad Humanitas | Bolsa de Trabajo",
				requireAuth: false
			} 
		},
		{ path: '/campus', component: CampusPage, name: 'Campus',
			meta: {
				title: "Universidad Humanitas | Campus",
				requireAuth: false
			} 
		},
		{ path: '/contacto', component: ContactoPage, name: 'Contacto',
			meta: {
				title: "Universidad Humanitas | Contacto",
				requireAuth: false
			}
		},
		{ path: '/despierta', component: DespiertaPage, name: 'Despierta',
			meta: {
				title: "Universidad Humanitas | Despierta",
				requireAuth: false
			}
		},

		 //Rutas para el admid
		{ path: '/blog/login', component: LoginCommentPage, name: 'login',
			meta: {
				requireAuth: false
			}
		},
		{ path: '/blog/admin-page', component: AdminCommentPage, name: 'Administrador',
			meta: {
				requireAuth: true
			}
		},

		
		{ path: '/:pathMatch(.*)*', redirect: () => ({ name: 'Universidad Humanitas' }) },

		...mainRoutes
	]

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior() {
		return { top: 0 };
	},
});

router.beforeEach((to, _, next) => {
	const requiresAuth = to.matched.some(record => record.meta.requireAuth);
	const auth = getAuth();
	const isAuthenticated = !!auth.currentUser;

	if (requiresAuth && !isAuthenticated) {
		next('/blog/login');
	} else {
		next();
	}
});


export default router;
