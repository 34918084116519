<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">Tendencias en Psicología para 2024: <br class="md:block hidden"> tecnología en la salud mental</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-200">
          <img src="/images/tendencias-psicologia-2024-chico-sentado-meditando-frente-laptop.jpg" class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/aprende" class=" text-hone">Aprende</router-link> > <router-link to="/blog/aprende/tendencias" class=" text-hone">Tendencias</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex text-left list-disc pl-5 italic text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">28 de marzo de 2024</li>  <li>Carolina Reyes Martínez/Redacción Humanitas</li></ul>
            </div>
            <p class=" md:mb-4 mb-6"><b>Extracto:</b> Aplicaciones de salud mental, psicología positiva… Descubre las últimas tendencias de la Piscología en este año.</p>
            <p class=" mb-2"><b>Introducción:</b></p>
            <p class=" md:mb-4 mb-6">La Psicología, como ciencia que estudia el comportamiento humano y los trastornos mentales, no permanece estática. Siempre evoluciona para adaptarse y responder a las dinámicas sociales, tecnológicas y culturales.</p>
            <p class=" md:mb-4 mb-6">A medida que avanza 2024, es importante explorar cómo las innovaciones digitales están ayudando en el campo de la psicología y la salud mental en la vida de las personas.</p>
            <p class=" md:mb-8 mb-10">En este artículo, hablamos de la salud mental, de las aplicaciones digitales y del impacto de la psicología positiva, así como de la Realidad Virtual como opción terapéutica.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">La salud mental en 2024 y la digitalización</h2>
            <p class="md:mb-4 mb-6">La salud mental es el estado de bienestar que permite a las personas enfrentar el estrés, resolver sus problemas, desarrollar sus habilidades, aprender y trabajar adecuadamente. Es parte esencial de la salud que sostiene la vida cotidiana, además de ser un derecho humano que permite el desarrollo personal y socioeconómico.</p>
            <p class="md:mb-4 mb-6">En este contexto, la digitalización se ha extendido en todos los aspectos de nuestra vida, y la salud mental no es la excepción. La pandemia aceleró este proceso, donde las sesiones en línea se consolidaron y actualmente siguen vigentes.</p>
            <p class="md:mb-8 mb-10">Los avances en la tecnología se incorporan a la Psicología, al trabajo de los profesionales de la salud, y tienen beneficios, pero también oportunidades de mejora.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Beneficios de la digitalización para apoyar a la salud mental</h3>
            <p class="mb-2">Sentirse conectado con las personas es una característica humana, por ello, los beneficios de estar en un mundo digital son los siguientes:</p> 
            <ul class="list-disc pl-6 md:mb-8 mb-10">
                <li><b>Accesibilidad: </b> Las aplicaciones y las plataformas digitales eliminan barreras físicas y económicas, y permiten a las personas acceder a servicios de salud mental desde cualquier lugar y a cualquier hora. Por ejemplo, si el paciente tiene agorafobia, ansiedad o ataques de pánico.</li>
                <li><b>Personalización: </b>La tecnología permite una mayor personalización del tratamiento, con programas y herramientas adaptadas a las necesidades específicas de cada usuario. Si el paciente prefiere esta modalidad porque siente más confianza y el terapeuta le brinda horarios y atención adecuada.</li>
                <li><b>Anonimato: </b>Para las personas que todavía se enfrentan al estigma asociado con la búsqueda de ayuda psicológica, las soluciones digitales ofrecen privacidad, cuidando su identidad. </li>
            </ul>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Oportunidades de mejora de la digitalización</h3>
            <p class="md:mb-4 mb-6">Como ya mencionamos, crear vínculos con otras personas ya sea de manera física o digital, permite el sentido de pertenencia, pero aún hay oportunidades de mejora:</p>
            <p class="pl-2 mb-2"><b>Privacidad de datos:</b> La gestión de la privacidad y la seguridad de los datos del usuario es una preocupación creciente, especialmente en aplicaciones que manejan información sensible.</p>
            <p class="pl-2 mb-2">Según algunos estudios, hay data brokers que se encargan de recabar los datos sobre salud mental que los pacientes dan.</p>
            <p class="pl-2 md:mb-4 mb-6">¿Qué podemos hacer ante esto? Como usuarios de internet, tener consciencia acerca de los datos que vamos a compartir, sobre todo, qué permisos le vamos a dar a las aplicaciones. Si observas que una app te solicita muchos datos, será mejor que no la bajes o instales en tus dispositivos.</p>
            <p class="pl-2 mb-2"><b>Despersonalización:</b> Para algunas personas, la falta de contacto humano en la terapia digital podría ser un factor limitante, y encontrarán más beneficioso el apoyo emocional directo.</p>
            <p class="pl-2 mb-2">En este contexto, es importante reconocer la diversidad de necesidades terapéuticas y la importancia de adaptar las intervenciones para satisfacer a cada paciente de manera efectiva.</p>
            <p class="pl-2 md:mb-8 mb-10">La elección entre la terapia digital y el contacto humano debe recomendarse por cómo la persona experimenta su problemática.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Tres apps destacadas para la salud mental</h2>
            <p class="md:mb-4 mb-6">La tecnología ha permitido que los principios de la psicología se integren de manera efectiva en aplicaciones y plataformas de salud mental.</p>
            <p class="md:mb-4 mb-6">Estas herramientas digitales no sólo ofrecen recursos para enfrentar desafíos psicológicos, también ofrecen prácticas saludables que pueden aumentar el bienestar general.</p>
            <p class="md:mb-8 mb-10">En el vasto ecosistema de aplicaciones dedicadas a ayudar a la salud mental, algunas destacan por su innovación, efectividad y facilidad de uso. A continuación, te presentamos tres aplicaciones para el bienestar emocional y psicológico, están disponibles en iOS y Android.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Calm: Respira y recupera la tranquilidad</h3>
            <p class="md:mb-8 mb-10"><b>Calm</b> se presenta como un oasis enmedio del bullicio de la vida moderna. Con ejercicios de mindfulness (que es la capacidad de prestar atención de manera consciente en el presente) y meditación, puede ayudar a los usuarios a manejar episodios de estrés en tiempo real. Ofrece audios con expertos que te guían paso a paso.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">HappyMind: Tranquilidad y psicología positiva</h3>
            <p class="md:mb-8 mb-10">Las meditaciones de esta app están basadas en estudios de Psicología positiva y neurociencia. A través de actividades diarias, retos y meditaciones guiadas, fomenta hábitos para incrementar la resiliencia, la gratitud y la satisfacción con la vida. Es una aplicación que impacta positivamente a las personas.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Yana: acompañamiento digital</h3>
            <p class="md:mb-8 mb-10">Yana es una aplicación que incorpora Inteligencia Digital para dar acompañamiento a las personas. Usa las herramientas de la terapia cognitivo-conductual en las sesiones y puede canalizar en línea con más profesionales en el manejo de crisis.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">El impacto de la Psicología positiva</h2>
            <p class="md:mb-4 mb-6">La Psicología positiva es un nuevo enfoque que estudia lo que hace que la vida merezca ser vivida. Es el análisis de lo que va bien en la vida, desde que naces hasta el momento de morir. Es todo aquello que es óptimo y te ha brindado felicidad, emociones positivas.</p>
            <p class="md:mb-4 mb-6">Su premisa es que la excelencia y bondad humanas son tan auténticas como el sufrimiento, los trastornos o el desequilibrio.</p>
            <p class="mb-2">Una buena vida, según los autores Martin Seligman y Park Peterson proponen este esquema:</p> 
            <ul class="list-decimal pl-6 md:mb-4 mb-6">
                <li>Experiencias subjetivas positivas: felicidad, fluir, sentirse pleno.</li>
                <li>Rasgos únicos o individuales positivos: fortaleza de carácter, talentos, intereses, valores.</li>
                <li>Relaciones interpersonales positivas: amistad, compañerismo, vida en pareja.</li>
                <li>Instituciones positivas: familia, escuela, trabajo, comunidad.</li>
            </ul>
            <p class="md:mb-4 mb-6">Identificar qué hacemos bien es una manera para la resolución de problemas, tanto psicológicos como de la vida cotidiana.</p>
            <p class="md:mb-8 mb-10">La Psicología positiva no tiene la intención de reemplazar a la psicología clínica, sino ser un complemento para un equilibrio en la condición humana, siendo una tendencia en este 2024. Fomentar relaciones positivas, establecer objetivos alcanzables y practicar la gratitud son algunos ejemplos que podrían ayudarte en tu día a día.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Influencia de las redes sociales</h2>
            <p class="md:mb-4 mb-6">Actualmente, las redes sociales se han convertido en parte de la vida cotidiana, ya que hay millones de personas que tienen acceso a éstas. Sí influyen en la salud mental y emocional; un ejemplo de ello, es la percepción de nosotros mismos y cómo nos ven los demás.</p>
            <p class="md:mb-4 mb-6">En algunas personas, sobre todo los jóvenes, puede ocasionar ansiedad o disminuir su autoestima al compararse con otras personas. Además existe el miedo a perderse de las cosas que ocurren en internet (en inglés se le conoce como FOMO).</p>
            <p class="md:mb-4 mb-6">Por ello, es importante promover el uso consciente y sano de las redes sociales, enfocándose en la autenticidad y la construcción de una comunidad de apoyo.</p>
            <p class="md:mb-4 mb-6">Las redes sociales también ofrecen una plataforma para crear consciencia sobre la salud mental, para romper estigmas y fomentar un diálogo sobre temas que han sido prohibidos.</p>
            <p class="md:mb-8 mb-10">Iniciativas como campañas de sensibilización y comunidades de apoyo en línea, juegan un papel fundamental en la difusión y el apoyo en temas de salud mental.</p>
            <img src="/images/A-Salud-mental-auto-cuidado-La-mujer-VR-.jpg" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">La Realidad Virtual en la psicología</h2>
            <p class="md:mb-4 mb-6">El futuro de la psicología y la salud mental está ligado a la evolución de la tecnología. Las tendencias actuales apuntan hacia una integración aún mayor de herramientas digitales, como la realidad virtual (VR) y la realidad aumentada (AR), en el tratamiento de las condiciones psicológicas.</p>
            <p class="md:mb-4 mb-6">La VR y la AR ofrecen entornos controlados y seguros para enfrentar y procesar traumas, fobias y otros trastornos. Le permite a los pacientes experimentar y gestionar sus respuestas emocionales en tiempo real, para la terapia efectiva.</p>
            <p class="md:mb-4 mb-6">La técnica de Realidad Virtual se basa en la proyección de gráficos en tercera dimensión que son interactivos, a través de un aparato como pueden ser lentes o auriculares.</p>
            <p class="md:mb-4 mb-6">En un inicio, se le permite a la persona familiarizarse con el equipo. Se le colocan sensores para revisar su presión arterial u otros parámetros que muestran cómo recibe los estímulos que están controlados por el psicólogo.</p>
            <p class="md:mb-4 mb-6">Después de las sesiones, al paciente se le brinda espacio para que procese lo vivido. El terapeuta le dará las técnicas o herramientas que le ayuden a gestionar sus emociones.</p>
            <p class="md:mb-8 mb-10">Para que esta tecnología alcance su potencial, es fundamental una inversión continua en investigación, esto ayudará a validar su efectividad y permitirá la personalización para cada paciente.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Conclusión</h2>
            <p class="md:mb-4 mb-6">Las tendencias en psicología para 2024 reflejan un panorama desafiante, donde la tecnología se convierte en un aliado fundamental de la salud mental. La digitalización es una herramienta poderosa que, utilizada ética y efectivamente, puede transformar la manera en que abordamos el bienestar psicológico.</p>
            <p class="md:mb-8 mb-10">Aunque el camino hacia una integración total de estas innovaciones presenta desafíos, el potencial para un futuro más saludable sí es posible. </p>
            <!-- Aqui va el componente de contacto, no se te olvide hacerlo manolo -->
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
                <ContactoVue class="m-3"/>
            </div>
            <p class=" text-htwo text-left mb-6">Explora más: <a href="https://humanitas.edu.mx/licenciatura-en-psicologia" target="_blank" class="text-htwo">Licenciatura en Psicología</a></p>
            <p class="text-htwo text-left mb-20">Te puede interesar: <router-link to="/blog/recursos/casos-reales/perfil-criminologico-como-descifra-la-mente-criminal"  class="text-htwo">Perfil criminológico: descifrando la mente detrás del crimen</router-link></p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="true" :linkAnt="'/academico/licenciatura/dia-mundial-del-teatro-2024'" :linkSig="'/aprende/tips/maximiza-tu-productividad-estilo-bill-gates'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import ContactoVue from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue';
import SigArt from '../../pagesComponents/SigArt.vue';

export default {
    components: { Comentarios, ConComentarios, ContactoVue, LikesVue, ShareP, SigArt},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '15-ART-APRENDE-TENDENCIAS-270324',
            ruta: '/aprende/tendencias/tendencias-en-psicologia-2024-tecnologia-salud-mental',
            title: '27 de marzo Día Mundial del Teatro 2024: celebra la paz y el arte',
            image: 'https://blog.humanitas.edu.mx/images/Costo-y-requisitos-estudiar-maestria.jpg',
            text: 'Conoce las tendencias clave en Psicología en 2024, la salud mental y las aplicaciones digitales. Averigua cómo la tecnología puede ayudar al bienestar.'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx/academico/maestria/costo-y-requisitos-estudiar-maestria';

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>