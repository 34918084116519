<script lang="ts">
    import PortadaInd from '../components/PortadaInd.vue';
	import NavbarInd from '../components/NavbarInd.vue';
	import FooterInd from '../components/FooterInd.vue';
    import Carru10Camp from './components/Carru10Camp.vue';
    import EgresadosVid from './components/oferta/EgresadosHuma.vue';
    import CarruFechasVue from './components/CarruFechas.vue';
    import VideoHiLVue from './components/VideoHiL.vue';
    // import Botones from './components/Botones.vue';
    import { defineComponent } from "vue";

    export default defineComponent({
        components: { PortadaInd, NavbarInd, FooterInd, Carru10Camp, EgresadosVid, CarruFechasVue, VideoHiLVue },
        data() {
            return {
            showImage: true,
            showImage2: true,
            showImage3: true,
            videoUrl: "https://www.youtube.com/embed/766G4yP1Y2w?si=m-X0wZGudiQFu1Q_",
            videoUrl2: "https://www.youtube.com/embed/G0nRBBZiZZQ?si=5bKZPQIeH-9Opx62",
            videoUrl3: "https://www.youtube.com/embed/G9EKcqfBp24?si=yG9GSge2J21d-Jfm",
            image1: "./pagesImages/decisiones.jpg",
            image2: "./pagesImages/play-icon.png",
            image3: "./pagesImages/play-2.png",
            // pathImgs: '/pagesImages/',
            // currentCucamanImage: 'cuco-1.png',
            // currentLeusImage: 'leus-1.png',
            // showCucamanDiv: false,
            // showLeusDiv: false,
            // buttons: {
            //         button1: { imageUrl: "/pagesImages/explorar_off.png", hoverImageUrl: "/pagesImages/explorar_on.png" },
            //         button2: { imageUrl: "/pagesImages/conocelos_off.png", hoverImageUrl: "/pagesImages/conocelos_on.png" },
            //         button3: { imageUrl: "/pagesImages/leer_off.png", hoverImageUrl: "/pagesImages/leer_on.png" },
            //         button4: { imageUrl: "/pagesImages/inscribete_off.png", hoverImageUrl: "/pagesImages/inscribete_on.png" },
            //         button5: { imageUrl: "/pagesImages/visit-trasp.png", hoverImageUrl: "/pagesImages/visitanos_on.png" },
            //     },
            };
        },
        methods: {
            showVideo() {
                this.showImage = false;
            },
            showVideo2() {
                this.showImage2 = false;
            },
            showVideo3() {
                this.showImage3 = false;
            },
            // changeImage(type: string, isHover: boolean) {
            //     if (type === 'cucaman') {
            //         this.currentCucamanImage = isHover ? 'cuco-2.png' : 'cuco-1.png';
            //         this.showCucamanDiv = isHover;
            //     } else if (type === 'leus') {
            //         this.currentLeusImage = isHover ? 'leus-2.png' : 'leus-1.png';
            //         this.showLeusDiv = isHover;
            //     }
            // },
            // getCucamanImage() {
            //     return this.pathImgs + this.currentCucamanImage;
            // },
            // getLeusImage() {
            //     return this.pathImgs + this.currentLeusImage;
            // },
        },
    });
</script>

<template>
    <div class="mb-12">
        <img src="./pagesImages/banner-top.webp" alt="portada" class="object-cover object-center h-[400px] md:h-auto w-screen pt-[88px] lg:pt-[143px]">
    </div>
    <section class="flex w-full justify-center">
        <div class="flex flex-col items-center justify-center w-full max-w-4xl lg:max-w-[1200px]">
            <div class="flex flex-col mb-12 items-center justify-center w-full px-4">
                <p class="text-[36px] leading-tight text-hgold font-serif mb-4">FUNDADA EN 1979</p>
                <p class="text-[24px] leading-tight text-hgray">S&oacute;lo el Conocimiento Hace Superior al Hombre</p>
                <img src="./pagesImages/adorno.png" alt="45 aniversario" class="my-6">
                <p class="mb-0 w-full text-[18px] leading-tight lg:w-1/2">Impulsamos la educaci&oacute;n con costos y horarios accesibles; adem&aacute;s, nuestros programas acad&eacute;micos son compatibles con profesionistas que buscan la superaci&oacute;n continua.</p>
            </div>
            <div class="flex justify-center items-center w-full">
                <div class="grid grid-cols-1 lg:grid-cols-3 gap-4 mb-12 px-4 items-center">
                    <div class="flex flex-col justify-center items-center relative text-white text-left w-full max-w-[480px] ">
                        <img src="./pagesImages/mision.webp" alt="Misión" class="">
                        <div class="absolute bottom-8 md:bottom-12 lg:bottom-2 xl:bottom-5 mx-4">
                            <p class=" text-center font-bold text-[28px] mb-2">MISI&Oacute;N</p>
                            <p class="leading-5 text-center w-full">Enaltecer la dignidad humana a trav&eacute;s de la educaci&oacute;n y el ejercicio de nuestros valores.</p>
                        </div>
                    </div>
                    <div class="flex flex-col justify-center items-center relative text-white text-left w-full max-w-[480px] ">
                        <img src="./pagesImages/vision.webp" alt="Visión">
                        <div class="absolute bottom-7 md:bottom-12 lg:bottom-2 xl:bottom-5 mx-4">
                            <p class="text-center font-bold text-[28px]">VISI&Oacute;N</p>
                            <p class="leading-5 text-center w-full">Impulsar la educaci&oacute;n apeg&aacute;ndonos a nuestra filosof&iacute;a, con sistemas pr&aacute;cticos y excelencia acad&eacute;mica.</p>
                        </div>
                    </div>
                    <div class="flex flex-col justify-center items-center relative text-white text-left w-full max-w-[480px] ">
                        <img src="./pagesImages/valores.webp" alt="Valores">
                        <div class="absolute bottom-4 md:bottom-8 lg:bottom-2 xl:bottom-3 mx-4">
                            <p class="text-center font-bold text-[28px]">VALORES</p>
                            <ul class="leading-5 w-full list-disc">
                                <li>Disciplina</li>
                                <li>Justicia</li>
                                <li>Respeto</li>
                                <li>Responsabilidad</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="flex flex-col w-full h-full items-center justify-center mb-12" style="background-image: url(/pagesImages/columnas-bg.jpg); position: relative; background-attachment: fixed; background-position: center; background-repeat: no-repeat; background-size: cover; overflow: hidden; transition: transform 0.3s ease;">
        <div class="w-full max-w-4xl lg:max-w-[1200px]">
            <div class="flex items-center mt-20 mb-16 justify-center w-full">
                <p class="font-serif text-gray-600 text-4xl  w-full">Humanitas a trav&eacute;s del tiempo</p>
            </div>
            <div class="flex items-center justify-center mb-12 w-full">
                <div class="hidden lg:flex items-center justify-center h-full">
                    <img src="./pagesImages/fechas.png" loading="lazy" alt="Linea del tiempo"/>
                </div>
                <div class="hidden flex-col items-center flex-wrap h-full justify-between mr-4 lg:mr-12">
                </div>
                <div class="flex flex-col text-left text-gray-600 justify-start px-4">
                    <div class="flex-col mb-6">
                        <div class="flex lg:hidden items-center mb-4">
                            <div class="flex md:hidden justify-center w-[40%]">
                                <img src="./pagesImages/buho.png" loading="lazy" alt="Buho" class="h-[60px]">
                            </div>
                            <p class="font-times text-4xl w-[60%] md:w-full text-center">1979</p>
                        </div>
                        <div class="flex w-full items-center">
                            <div class="hidden md:flex justify-center w-[40%] lg:w-[176px]">
                                <img src="./pagesImages/buho.png" loading="lazy" alt="Buho" class="h-[60px] md:h-full mx-5">
                            </div>
                            <div class="text-[15px] w-full lg:w-[500px]">
                                <p>●&nbsp;&nbsp;El 30 de abril se fund&oacute; la universidad Humanitas.</p>
                                <p>●&nbsp;&nbsp;Su fundador es el Dr. Eduardo Johnson Okhuysen.</p>
                                <p>●&nbsp;&nbsp;Se inaugura el primer campus en la Ciudad de M&eacute;xico.</p>
                                <p>●&nbsp;&nbsp;Se definen los valores, la misi&oacute;n, la visi&oacute;n y el lema institucional "S&oacute;lo el conocimiento hace superior al hombre".</p>
                            </div>
                        </div>
                    </div>
                    <div class="flex-col mb-6">
                        <div class="flex lg:hidden items-center mb-4">
                            <div class="flex md:hidden justify-center w-[40%]">
                                <img src="./pagesImages/escribano.png" loading="lazy" alt="Escribano" class="h-[60px]">
                            </div>
                            <p class="font-times text-4xl w-[60%] md:w-full text-center">80's</p>
                        </div>
                        <div class="flex w-full items-center">
                            <div class="hidden md:flex justify-center w-[40%] lg:w-[176px]">
                                <img src="./pagesImages/escribano.png" loading="lazy" alt="Escribano" class="h-[60px] md:h-full mx-5">
                            </div>
                            <div class="text-[15px] w-full lg:w-[500px]">
                                <p>●&nbsp;&nbsp;Se crea el Centro de investigaci&oacute;n de Estudios.</p>
                                <p>●&nbsp;&nbsp;Se desarrollan los planes de estudio de Posgrado.</p>
                                <p>●&nbsp;&nbsp;La Secretar&iacute;a de Educaci&oacute;n P&uacute;blica otorga el reconocimiento oficial de Posgrados.</p>
                                <p>●&nbsp;&nbsp;Se inicia la publicaci&oacute;n de libros de derecho, econom&iacute;a negocios, pol&iacute;tica y finanzas.</p>
                                <p>●&nbsp;&nbsp;Se crea el despacho jur&iacute;dico Johnson Okhuysen Asociados con fines sociales.</p>
                            </div>
                        </div>
                    </div>
                    <div class="flex-col mb-6">
                        <div class="flex lg:hidden items-center mb-4">
                            <div class="flex md:hidden justify-center w-[40%]">
                                <img src="./pagesImages/escribano2.png" loading="lazy" alt="Escribano 2" class="h-[60px]">
                            </div>
                            <p class="font-times text-4xl w-[60%] md:w-full text-center">90's</p>
                        </div>
                        <div class="flex w-full items-center">
                            <div class="hidden md:flex justify-center w-[40%] lg:w-[176px]">
                                <img src="./pagesImages/escribano2.png" loading="lazy" alt="Escribano 2" class="h-[60px] md:h-full mx-5">
                            </div>
                            <div class="text-[15px] w-full lg:w-[500px]">
                                <p>●&nbsp;&nbsp;Se crea el sistema educativo abierto.</p>
                                <p>●&nbsp;&nbsp;Se desarrollan los planes de estudio para la Licenciatura.</p>
                                <p>●&nbsp;&nbsp;La Secretar&iacute;a de Educaci&oacute;n P&uacute;blica otorga <br>oficial de Licenciaturas.</p>
                                <p>●&nbsp;&nbsp;Se implementan las gu&iacute;as de estudio para las licenciaturas.</p>
                                <p>●&nbsp;&nbsp;El 12 de julio de 1996 fallece el fundador.</p>
                                <p>●&nbsp;&nbsp;En 1999 se nombra sucesor de la Direcci&oacute;n General a <br>Andr&eacute;s Johnson Islas.</p>
                            </div>
                        </div>
                    </div>
                    <div class="flex-col mb-6">
                        <div class="flex lg:hidden items-center mb-4">
                            <div class="flex md:hidden justify-center w-[40%]">
                                <img src="./pagesImages/escudo.png" loading="lazy" alt="Escudo" class="h-[60px]">
                            </div>
                            <p class="font-times text-4xl w-[60%] md:w-full text-center">2000's</p>
                        </div>
                        <div class="flex w-full items-center">
                            <div class="hidden md:flex justify-center w-[40%] lg:w-[176px]">
                                <img src="./pagesImages/escudo.png" loading="lazy" alt="Escudo" class="h-[60px] md:h-full mx-5">
                            </div>
                            <div class="text-[15px] w-full lg:w-[500px]">
                                <p>●&nbsp;&nbsp;Se actualiza el logotipo institucional.</p>
                                <p>●&nbsp;&nbsp;Se reestructura el modelo educativo.</p>
                                <p>●&nbsp;&nbsp;Se define el modelo arquitect&oacute;nico neocl&aacute;sico.</p>
                                <p>●&nbsp;&nbsp;Expande su presencia en Tijuana y Canc&uacute;n.</p>
                                <p>●&nbsp;&nbsp;Se define la estructura del Corporativo Humanitas.</p>
                                <p>●&nbsp;&nbsp;Se amplian los programas educativos.</p>
                            </div>
                        </div>
                    </div>
                    <div class="flex-col mb-6">
                        <div class="flex lg:hidden items-center mb-4">
                            <div class="flex md:hidden justify-center w-[40%]">
                                <img src="./pagesImages/capitel.png" loading="lazy" alt="Capitel" class="h-[60px]">
                            </div>
                            <p class="font-times text-4xl w-[60%] md:w-full text-center">2010's</p>
                        </div>
                        <div class="flex w-full items-center">
                            <div class="hidden md:flex justify-center w-[40%] lg:w-[176px]">
                                <img src="./pagesImages/capitel.png" loading="lazy" alt="Capitel" class="h-[60px] md:h-full mx-5">
                            </div>
                            <div class="text-[15px] w-full lg:w-[500px]">
                                <p>●&nbsp;&nbsp;Se crean las mascotas Leus y Cucaman.</p>
                                <p>●&nbsp;&nbsp;Se desarrolla el modelo educativo English Kingdom y el concepto <br>Deli Despierta.</p>
                                <p>●&nbsp;&nbsp;Se estructura el modelo educativo por bloques.</p>
                                <p>●&nbsp;&nbsp;Expande su presencia en Los Reyes, Presa Mad&iacute;n, Quer&eacute;taro, M&eacute;rida, Guadalajara, Cuernavaca y Santa Fe.</p>
                                <p>●&nbsp;&nbsp;Inicia el programa Virtual con Licenciaturas y Posgrados.</p>
                                <p>●&nbsp;&nbsp;Nacen la Fundaci&oacute;n Fuerza H y Humanitas Vive.</p>
                                <p>●&nbsp;&nbsp;En 2015 inicia la revista Capitel.</p>
                                <p>●&nbsp;&nbsp;Se obtienen certificaciones en ESR y GPTW.</p>
                            </div>
                        </div>
                    </div>
                    <div class="flex-col mb-6">
                        <div class="flex lg:hidden items-center mb-4">
                            <div class="flex md:hidden justify-center w-[40%]">
                                <img src="./pagesImages/trascendi.png" loading="lazy" alt="Trascendi" class="h-[60px]">
                            </div>
                            <p class="font-times text-4xl w-[60%] md:w-full text-center">2020's</p>
                        </div>
                        <div class="flex w-full items-center">
                            <div class="hidden md:flex justify-center w-[40%] lg:w-[176px]">
                                <img src="./pagesImages/trascendi.png" loading="lazy" alt="Trascendi" class="h-[60px] md:h-full mx-5">
                            </div>
                            <div class="text-[15px] w-full lg:w-[500px]">
                                <p>●&nbsp;&nbsp;Se crean los canales Trascendi y Legatum.</p>
                                <p>●&nbsp;&nbsp;Se desarrolla el plan de estudio para Doctorado.</p>
                                <p>●&nbsp;&nbsp;La Secretar&iacute;a de Educaci&oacute;n P&uacute;blica otorga el reconocimiento oficial de Doctorado.</p>
                                <p>●&nbsp;&nbsp;Expande su modelo de arquitectura neocl&aacute;sica en los campus ya establecidos.</p>
                                <p>●&nbsp;&nbsp;Expande su presencia a Chihuahua.</p>
                                <p>●&nbsp;&nbsp;Expande su presencia del modelo virtual en Bogot&aacute;, Colombia.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="flex flex-col w-full items-center justify-center">
        <div class="flex items-center w-full justify-center max-w-4xl lg:max-w-[1200px] mb-12">
            <div class="lg:flex w-full items-center justify-between"> 
                <div class="flex items-center justify-center">
                    <VideoHiLVue :thumbnailUrl="image1" :videoUrl="videoUrl" class="lg:mr-12 w-full mb-2"/>
                </div>
                <div class="flex flex-col justify-center lg:w-[50%] w-[100%] text-left px-4">
                    <div class="flex lg:flex-col justify-center lg:items-start items-center my-4 w-full">
                        <p class="text-left text-[28px] text-gray-500 leading-none mr-2 lg:mr-0">Filosof&iacute;a</p>
                        <p class="text-left text-[42px] text-hgold leading-none font-serif">De vida</p>
                    </div>
                    <p>● Cultivar la mente por medio del estudio y el trabajo</p>
                    <p>● Cuidar el cuerpo por medio de una sana alimentaci&oacute;n y ejercicio.</p>
                    <p class="mb-2">● Trabajar el alma y esp&iacute;ritu a trav&eacute;s de los valores, pensamientos y acciones positivas con la humanidad, los animales y el medio ambiente.</p>
                    <p class="mt-4 mb-2 text-lg font-bold">C&Oacute;DIGO HUMANITAS</p>
                    <p><span class="font-bold">1.</span> Vivo y act&uacute;o con respeto.</p>
                    <p><span class="font-bold">2.</span> Soy congruente en pensamiento, acci&oacute;n y palabra.</p>
                    <p><span class="font-bold">3.</span> Todas mis actividades las realizo con orgullo, pasi&oacute;n y responsabilidad.</p>
                    <p><span class="font-bold">4.</span> Encuentro mi camino hacia la superaci&oacute;n en la educaci&oacute;n.</p>
                    <p><span class="font-bold">5.</span> Los obst&aacute;culos me permiten caminar cada vez m&aacute;s firme.</p>
                    <p><span class="font-bold">6.</span> Lo imposible lo convierto en posible.</p>
                    <p><span class="font-bold">7.</span> S&eacute; que mis acciones de hoy determinar&aacute;n el ma&ntilde;ana.</p>
                    <p><span class="font-bold">8.</span> Llegar al &eacute;xito y concretar mis sue&ntilde;os depende de mi actitud.</p>
                </div>
            </div>
        </div>

        <div class="flex flex-col lg:flex-row items-center justify-between max-w-4xl lg:max-w-6xl mb-12">
            <div class="order-2 lg:order-1 flex flex-col lg:w-[50%] w-[100%] lg:mr-12 px-4">
                <div class="flex flex-col justify-center items-center lg:items-end my-4 w-full">
                    <p class="text-left text-[28px] text-gray-500 leading-none mr-2 lg:mr-0">¿Por qu&eacute; estudiar en</p>
                    <p class="text-left text-[42px] text-hgold leading-none font-serif">Humanitas?</p>
                </div>
                <p class=" text-justify mb-2">Impulsamos y formamos personas con valores y conocimientos profesionales en diferentes ramas de estudio, con la filosof&iacute;a de respeto al entorno y una superaci&oacute;n continua.</p>
                <p class=" text-justify mb-2">Tenemos presencia en diversos estados de la Rep&uacute;blica Mexicana. Nuestros campus cuentan con una arquitectura neocl&aacute;sica, con jardines y fuentes dise&ntilde;ados para fomentar el estudio y la reflexi&oacute;n.</p>
                <p class=" text-justify mb-2">Nuestros modelos educativos son dise&ntilde;ados por especialistas y supervisados por el consejo acad&eacute;mico, con la finalidad de que las horas en el aula, las actividades y los horarios contribuyan al desarrollo de nuestros alumnos.</p>
                <br>
                <div class="flex items-center justify-center lg:justify-end">
                    <router-link to="/contacto">
                        <div class="flex items-center justify-center w-[140px] h-[35px] bg-hwine hover:bg-hgold text-white text-lg transition duration-300 ease-in-out">
                            <span>Inscr&iacute;bete</span>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="order-1 lg:order-2 flex items-center justify-center">
                <img src="./pagesImages/graduados.webp" loading="lazy" alt="Graduados">
            </div>
        </div>

        <div class="lg:flex justify-between w-full max-w-4xl lg:max-w-[1200px] items-center mb-16">
            <div class="lg:flex w-full items-center justify-between"> 
                <div class="flex items-center justify-center">
                    <VideoHiLVue :thumbnailUrl="'./pagesImages/docente.webp'" :videoUrl="videoUrl2" class="lg:mr-12 w-full mb-2"/>
                </div>
                <div class="flex flex-col justify-center lg:w-[50%] w-[100%] text-left px-4">
                    <div class="flex lg:flex-col justify-center lg:items-start items-center my-4 w-full">
                        <p class="text-left text-[28px] text-gray-500 leading-none mr-2 lg:mr-0">Acci&oacute;n</p>
                        <p class="text-left text-[42px] text-hgold leading-none font-serif">Docente</p>
                    </div>
                    <p class="mb-2">En Universidad Humanitas contamos con reconocidos catedr&aacute;ticos, expertos en su profesi&oacute;n, los cuales laboran, tanto en dependencias gubernamentales, como privadas. Comparten nuestra vocaci&oacute;n por la ense&ntilde;anza, transmitiendo sus conocimientos con la filosof&iacute;a de razonar y resolver para alcanzar el aprendizaje, con una metodolog&iacute;a pr&aacute;ctica y calidad acad&eacute;mica.</p>
                    <p class="mb-2">Define sus acciones y las del estudiante en el sistema institucional, para lograr los objetivos y afrontar las lecturas de forma pr&aacute;ctica por medio de experiencias laborales y casos que se controlan a partir del proceso de evaluaci&oacute;n.</p>
                    <p class="mb-2">Su comportamiento es activo y va moldeando las acciones de los alumnos hasta que les transmite su conocimiento. La exigencia es alta debido a que el compromiso del estudiante es con un s&oacute;lo bloque y un docente por trimestre.</p>
                    <p>Los catedr&aacute;ticos de Universidad Humanitas son directivos y/o socios de reconocidos Despachos, Bufetes, Empresas e Instituciones de alto nivel.</p>
                </div>
            </div>
        </div>
    </section>

    <EgresadosVid/>

    <section class="flex flex-col items-center justify-center bg-cover bg-[top_center] bg-no-repeat" style="background-image: url(/pagesImages/mascotas.jpg);">
        <div class="flex flex-col justify-center items-center mb-4 mt-12">
            <p class="font-serif text-hgold text-4xl">Nuestras Mascotas</p>
            <p class=" text-lg">Representa la dualidad que vive en nuestro interior. Entre el bien y el mal,</p>
            <p class=" text-lg">¿Qui&eacute;n ganar&aacute;? Al que alimentes m&aacute;s.</p>
        </div>
        <div class="flex flex-col w-full max-w-4xl lg:max-w-[1200px]  h-full  justify-start mb-60 mt-4">
            <div class="flex flex-col md:flex-row justify-center md:justify-between px-4">
                <div id="leus-div" class="flex-col w-full lg:w-[25%] bg-white/90 border border-hwine text-base p-3 rounded-lg mb-4 md:mb-0 md:mr-4 lg:mr-0">
                    <h1 class="font-acuminbold mb-3 text-4xl text-center font-bold text-hwine  tracking-widest">Leus</h1>
                    <p class="font-montse text-justify text-sm mb-4 text-black">
                        Representa los valores, las virtudes y la importancia de vivir bajo una filosof&iacute;a que nos permita crecer y desarrollarnos como seres humanos y como profesionistas manteniendo un equilibrio entre cuerpo, mente y esp&iacute;ritu.
                    </p>
                </div>
                <div id="cucaman-div"  class="flex-col w-full lg:w-[25%] bg-white/90 border border-hwine text-base p-3 rounded-lg">
                    <h1 class="font-acuminbold mb-3 text-4xl font-bold text-center text-hwine tracking-widest">Cucaman</h1>
                    <p class="font-montse text-justify text-sm mb-4 text-black">
                        Fue creado con el prop&oacute;sito de hacer conciencia de aquellas cosas que cotidianamente hacemos mal. Es una visi&oacute;n exagerada de la actitud que d&iacute;a con d&iacute;a nos molesta y que en ocasiones somos capaces de adoptar sin darnos cuenta.
                    </p>
                </div>
            </div>
        </div>
    </section>

    <section class="flex items-center justify-center bg-cover bg-[center] bg-no-repeat" style="background-image: url(/pagesImages/despierta.jpg);">
        <div class="flex flex-col w-full max-w-4xl h-full justify-start my-14 md:my-24 text-white px-4">
            <div class="flex flex-col justify-center items-center lg:items-start">
                <img src="./pagesImages/logotipo-despierta-gde.png" loading="lazy" alt="Despierta" class="max-w-[394px] max-h-[172px]">
                <p class="text-center lg:text-justify w-full max-w-[460px] text-[19px] mb-3">Un concepto de cafeter&iacute;as, donde combinamos una sana alimentaci&oacute;n sin dejar de lado el sabor casero de una comida saludable.</p>
                <router-link to="/despierta">
                    <div class="flex items-center justify-center w-[140px] h-[35px] bg-white/70 hover:bg-hgold text-hwine border-2 border-hwine text-lg font-semibold transition duration-300 ease-in-out">
                        <span>Vis&iacute;tanos</span>
                    </div>
                </router-link>
            </div>
        </div>
    </section>

    <section class="flex items-center w-full justify-center">
        <div class="lg:flex items-center max-w-4xl lg:max-w-[1200px]">
            <div class="flex items-center w-full justify-center">
                <img src="./pagesImages/shield-tr.png" loading="lazy" alt="Escudo">
            </div>
            <div class="flex justify-center w-full items-center px-4 mb-10">
                <div class="flex flex-col justify-center items-center lg:items-start w-full">
                    <p class="text-[28px] leading-none">Plataforma</p>
                    <p class="text-hgold leading-none mb-2 font-serif text-[42px]">Humanitas</p>
                    <p class="text-justify">Consultas de acceso a horarios, finanzas, historial acad&eacute;mico, avisos, calendarios, expediente personal, eventos y redes sociales.<br><br>
                    Apoyo en plataformas de alto desempe&ntilde;o, como Google Empresarial, con la cual alumnos y docentes tienen acceso a un correo personalizado, as&iacute; como a distintas herramientas pr&aacute;cticas de comunicaci&oacute;n.<br><br>
                    Ingreso a la Biblioteca Virtual para consultar o adquirir libros electr&oacute;nicos, que se pueden descargar en cualquier dispositivo.</p>
                    <br>
                    <router-link to="/acceso-a-usuarios">
                        <div class="flex items-center justify-center w-[140px] h-[35px] bg-hwine hover:bg-hgold text-white text-lg transition duration-300 ease-in-out">
                            <span>Acceder</span>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </section>

    <section class="flex w-full h-full items-center justify-center bg-cover bg-[right] bg-no-repeat mb-12" style="background-image: url(/pagesImages/pelotas-bg.jpg);">
        <div class="flex items-center w-full max-w-4xl lg:max-w-[1200px] justify-center ">
            <div class="lg:flex justify-start w-full my-12 items-center">
                <div class="flex flex-col justify-start lg:w-[90%] px-4">
                    <div class="text-center lg:text-left mb-4 leading-none">
                        <p class="text-[28px]">Las 12 Pelotas</p>
                        <p class="text-hgold text-[42px] font-serif">De la Felicidad</p>
                    </div>
                    <div class="text-justify mb-6 text-black md:text-hgray">
                        <p class="mb-4">La vida es como un juego de malabares en el que dispones de 12 pelotas para lograr la felicidad.</p>
                        <p>Es normal que siempre se caiga una o m&aacute;s pelotas… lo importante es contar con la actitud y perseverancia de volver a levantarlas para mantener un equilibrio en la vida y, como consecuencia, obtener la felicidad con la filosof&iacute;a de estar siempre en un continuo movimiento.</p>
                    </div>
                </div>
                <div class="flex  w-full items-center md:pl-28 lg:justify-start justify-center">
                    <VideoHiLVue :thumbnailUrl="image3" :videoUrl="videoUrl3" class=""/>
                </div>
            </div> 
        </div>
    </section>
</template>

<style scoped>
</style>