<template>
    <div>
      <CataBueno :titulo="'Recursos'" :subtitulo="''" :filtros="filtros" :articles="articles"/>
    </div>
</template>

<script lang="js">
import CataBueno from './pagesComponents/CataBueno.vue';

export default {
components: { CataBueno },
data() {
    return {
    articles: [
        {
          "id": "0053-ART-RECURSOS-CASOS-REALES-020724",
          "titulo": "Cómo identificar y superar relaciones tóxicas: Lo que debes de saber",
          "fecha": "02 de julio de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "Aprende a reconocer los signos de una relación tóxica y cómo prevenirlos para vivir una vida más sana y feliz.",
          "portada": "/images/identifica-relaciones-toxicas.webp",
          "enlace": "/recursos/casos-reales/como-identificar-y-superar-relaciones-toxicas"
        },
        {
          "id": "0036-ART-RECURSOS-ENTREVISTAS-Y-CONFERENCIAS-060624",
          "titulo": "Características de la mediación: la forma para evitar los tribunales",
          "fecha": "04 de junio de 2024",
          "autor": "Iván Solís Rivera",
          "descripcion": "Respondemos a la pregunta: ¿Qué es y cuáles son las características de la mediación; la manera alternativa de evitar largos juicios.",
          "portada": "/images/caracteristicas-mediacion-litigio-negociacion.webp",
          "enlace": "/recursos/entrevistas-y-conferencias/caracteristicas-de-la-mediacion"
        },
        {
          "id": "0032-ART-RECURSOS-ENTREVISTAS-Y-CONFERENCIAS-300524",
          "titulo": "La inclusión de las mujeres y la diversidad en el Derecho por Mariel Cabañas",
          "fecha": "30 de mayo de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "Descubre cómo la inclusión y la diversidad en los ámbitos del Derecho y educativo enriquecen a la sociedad, con la experiencia de Mariel Cabañas, presidenta de Abogadas MX.",
          "portada": "/images/inclusion-mujeres-diversidad-derecho-impacto-benefico-sociedad.webp",
          "enlace": "/recursos/entrevistas-y-conferencias/inclusion-diversidad-en-derecho-por-mariel-cabanas"
        },
        {
          "id": "0030-ART-RECURSOS-ENTREVISTAS-Y-CONFERENCIAS-220524",
          "titulo": "Procedimientos y técnicas de auditoría: Pruebas clave y ejemplos",
          "fecha": "22 de mayo de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "Descubre los procedimientos y técnicas de la auditoría, y algunos ejemplos de Ricardo Sandoval Huerta, Coordinador de Auditoría de Corporativo Humanitas.",
          "portada": "/images/procedimientos-tecnicas-auditoria-precision-universidad-humanitas.webp",
          "enlace": "/recursos/entrevistas-y-conferencias/procedimientos-tecnicas-de-auditoria-y-ejemplos-ricardo-sandoval-huerta"
        },
        {
          "id": "0027-ART-RECURSOS-ENTREVISTAS-Y-CONFERENCIAS-200524",
          "titulo": "Desarrollo humano: Una perspectiva integral por Helios Herrera",
          "fecha": "20 de mayo de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "Descubre el enfoque único del consultor Helios Herrera sobre el desarrollo humano.",
          "portada": "../images/desarrollo-humano-integra-crecimiento-personal-profesional.webp",
          "enlace": "/recursos/entrevistas-y-conferencias/desarrollo-humano-una-perspectiva-integral-helios-herrera"
        },
        {
          "id": "0023-ART-RECURSOS-ENTREVISTAS-Y-CONFERENCIAS-100524",
          "titulo": "Liderazgo femenino empresarial: la clave del éxito",
          "fecha": "10 de mayo de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "El liderazgo femenino está transformando las empresas globalmente. Descubre cómo en este artículo.",
          "portada": "../images/liderazgo-femenino-empresarial-exitoso-mujer-vs-liderazgo-masculinoempresaria.webp",
          "enlace": "/recursos/entrevistas-y-conferencias/liderazgo-femenino-empresarial-exitoso"
        },
        {
          "id": "17-ART-RECURSOS-CASOSREALES-090424",
          "titulo": "Perfil criminológico: cómo los expertos descifran la mente criminal.",
          "fecha": "09 de abril de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "El perfil criminológico permite internarse en la mente de los delincuentes para resolver casos complejos. Explora qué es.",
          "portada": "/images/Perfil-criminologico-detras-mente-criminal-investigadores.jpg",
          "enlace": "/recursos/casos-reales/perfil-criminologico-como-descifra-la-mente-criminal"
        },
        {
            "id": "01-ART-RECURSOS-EYCONF-180124",
            "titulo": "El liderazgo visionario de Albert Bosch en tu vida universitaria",
            "fecha": "18 de enero de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Descubre cómo emprender tu vida universitaria con el liderazgo visionario de Albert Bosch y vive tu vida como una aventura.",
            "portada": "../images/Emprende-aventura-liderazgo-visionario-albert-bosch.jpg",
            "enlace": "/recursos/entrevistas-y-conferencias/emprende-aventura-de-tu-vida-liderazgo-visionario-albert-bosch"
        },
        
    ], // Array de artículos original
    filtros: {
        "0053-ART-RECURSOS-CASOS-REALES-020724": {
          Humanidades: ["Psicología", "Educación"],
          Ciencias_sociales: [],
          Economico_administrativas: []
        },
        "0036-ART-RECURSOS-ENTREVISTAS-Y-CONFERENCIAS-060624": {
          Humanidades: [],
          Ciencias_sociales: ["Derecho", "Ciencias Políticas y Administración Pública"],
          Economico_administrativas: []
        },
        "0032-ART-RECURSOS-ENTREVISTAS-Y-CONFERENCIAS-300524": {
          Humanidades: [],
          Ciencias_sociales: ["Derecho"],
          Economico_administrativas: []
        },
        "0030-ART-RECURSOS-ENTREVISTAS-Y-CONFERENCIAS-220524": {
          Humanidades: [],
          Ciencias_sociales: [],
          Economico_administrativas: ["Administración", "Contabilidad"]
        },
        "01-ART-RECURSOS-EYCONF-180124": {
            Humanidades: [],
            Ciencias_sociales: [],
            Economico_administrativas: ["Administración"]
        },
        "17-ART-RECURSOS-CASOSREALES-090424": {
            Humanidades: ["Psicología"],
            Ciencias_sociales: ["Derecho"],
            Economico_administrativas: []
        },
        "0023-ART-RECURSOS-ENTREVISTAS-Y-CONFERENCIAS-100524": {
          Humanidades: [],
          Ciencias_sociales: [],
          Economico_administrativas: ["Administración"]
        },
        "0027-ART-RECURSOS-ENTREVISTAS-Y-CONFERENCIAS-200524": {
          Humanidades: [],
          Ciencias_sociales: [],
          Economico_administrativas: ["Administración"]
        },
    },
    filtrosSeleccionados: null, // Lista de filtros seleccionados
    };
},
methods: {
    // Método para aplicar los filtros seleccionados
    
}
};
</script>

<style>
/* Estilos CSS si los tienes */
</style>