<script lang="ts">
    import PortadaInd from '../components/PortadaInd.vue';
	import NavbarInd from '../components/NavbarInd.vue';
	import FooterInd from '../components/FooterInd.vue';
    import Carru10Camp from './components/Carru10Camp.vue';
    import CarruVideosEscol from './components/CarruVideosEscol.vue';
    import EgresadosVid from './components/oferta/EgresadosHuma.vue';
    import VideoHiL from './components/VideoHiL.vue';
    import Botones from './components/Botones.vue';

    export default {
        components: { PortadaInd, NavbarInd, FooterInd, Carru10Camp, CarruVideosEscol, EgresadosVid, VideoHiL, Botones },
        data(){
            return {
                img: "/pagesImages/video-esco.jpg",
                img2: "/pagesImages/escolarizado-video2.jpg",
                img3: "/pagesImages/esco-vid.jpg",
                videoUrl: "https://www.youtube.com/embed/1EoW9eU5PYE?si=fowb1N8ytyCwNSbt",
                videoUrl2: "https://www.youtube.com/embed/jL83vP0u6hw?si=paaXGiAAKH7k_qb_",
                videoUrl3: "https://www.youtube.com/embed/GVCjqPfqJT8?si=qAcmICSR7VKJc3Bm",
                buttons: {
                    button1: { imageUrl: "/pagesImages/explorar_off.png", hoverImageUrl: "/pagesImages/explorar_on.png" },
                    button2: { imageUrl: "/pagesImages/conocelos_off.png", hoverImageUrl: "/pagesImages/conocelos_on.png" },
                    button3: { imageUrl: "/pagesImages/leer_off.png", hoverImageUrl: "/pagesImages/leer_on.png" },
                    button4: { imageUrl: "/pagesImages/inscribete_white.png", hoverImageUrl: "/pagesImages/inscribete_on.png" },
                    button5: { imageUrl: "/pagesImages/explorar_off.png", hoverImageUrl: "/pagesImages/explorar_on.png" },
                },
            }
        }
    }
</script>

<template>
    <section class="flex flex-col w-full pt-[88px] lg:pt-[143px]">
        <div class="flex items-center justify-center w-full bg-center bg-cover md:bg-[center-right] bg-no-repeat" style="background-image: url(/pagesImages/escolarizado-banner.jpg);">
            <div class="flex justify-center w-full px-4 bg-white/50 lg:bg-transparent">
                <div class="w-full items-end flex md:mb-56 my-8 md:my-0 md:mt-32 flex-col max-w-4xl lg:max-w-[1200px] text-black lg:text-white">
                    <h1 class="text-[30px]">Modelo</h1>
                    <h1 class=" font-serif text-hgold text-[50px] mb-2">Escolarizado</h1>
                    <p class="font-serif text-[19px] text-right mb-8 w-full max-w-[430px]">Si lo vives con pasi&oacute;n, tendr&aacute;s la suficiente motivaci&oacute;n como para dar lo mejor de ti.</p>
                </div>
            </div>
        </div>
    </section>

    <section class="flex w-full items-center justify-center">
        <div class="flex flex-col justify-center items-center w-full mt-16 max-w-4xl lg:max-w-[1200px] px-4">
            <div class="flex flex-col text-center w-full max-w-[650px]">
                <p class="mb-4 font-serif text-hgold md:text-[36px] text-3xl leading-tight">"Es una gran ventaja graduarse y tener experiencia laboral a la vez".</p>
            </div>
            <div class="flex flex-col lg:flex-row w-full my-12">
                <div class="text-justify lg:w-1/2 lg:pr-6">
                    <p class="mb-4">El sistema escolarizado dise&ntilde;ado en Humanitas es verdaderamente &uacute;nico. Enfoc&aacute;ndonos en la singularidad de cada licenciatura, nuestras materias est&aacute;n dise&ntilde;adas con objetividad y se complementan con talleres destinados al desarrollo humano y profesional de los j&oacute;venes. Adem&aacute;s, ofrecemos seminarios de vanguardia para acentuar a&uacute;n m&aacute;s las herramientas necesarias para su futura profesi&oacute;n.</p>
                    <p>Nuestra filosof&iacute;a no solo se limita al &aacute;mbito acad&eacute;mico, sino que tambi&eacute;n aborda la realidad del mundo laboral.<br><br>
                    Al impulsar a los estudiantes a trabajar desde temprana edad, se les brinda una perspectiva consciente de las responsabilidades y desaf&iacute;os que enfrentar&aacute;n como adultos.<br><br>
                    </p>
                </div>
                <div class="text-justify lg:w-1/2 lg:pl-6">
                    <p class="mb-4">
                        La reducci&oacute;n de costos tambi&eacute;n es una medida estrat&eacute;gica, alineada con la realidad econ&oacute;mica de nuestro pa&iacute;s y los sueldos disponibles, permitiendo que los estudiantes asuman la responsabilidad financiera de sus propios estudios.<br><br>
                        Estamos firmemente convencidos de que la combinaci&oacute;n de estudiar y trabajar es la clave para formar verdaderos profesionales exitosos. <br><br>
                        Este enfoque no solo enriquece su educaci&oacute;n, sino que tambi&eacute;n les proporciona habilidades pr&aacute;cticas, experiencia laboral valiosa y una comprensi&oacute;n m&aacute;s profunda de la realidad del mundo profesional.
                    </p>
                </div>
            </div>
        </div>
    </section>

    <section class="mb-12 flex flex-col w-screen border boder-black items-center justify-center bg-cover bg-center bg-no-repeat" style="background-image: url(/pagesImages/bgs-column.jpg);">
        <div class="flex justify-center w-full my-16">
            <div class="flex flex-col items-center justify-center w-full max-w-4xl lg:max-w-[1200px]">
                <div class="grid grid-cols-1 lg:grid-cols-3 gap-4 w-full">
                    <VideoHiL :thumbnailUrl="img" :videoUrl="videoUrl" class="cursor-pointer"/>
                    <VideoHiL :thumbnailUrl="img2" :videoUrl="videoUrl2" class="cursor-pointer"/>
                    <VideoHiL :thumbnailUrl="img3" :videoUrl="videoUrl3" class="cursor-pointer"/>
                </div>
            </div>
        </div>
    </section>
    
    <section class="flex flex-col justify-center items-center w-full mb-20">
        <div class="flex flex-col justify-center w-full max-w-4xl lg:max-w-[1200px]">
            <div class="lg:flex justify-center items-center w-full">
                <div class="flex flex-col w-full lg:w-1/2 text-left px-4 mb-6 lg:mb-0">
                    <div class="w-full flex flex-col items-center lg:items-start">
                        <h1 class="font-serif text-[42px] text-hgold">Etapa I</h1>
                        <div class="flex items-center text-gray-400 text-left">
                            <p class="text-xl">8 Trimestres, 2 a&ntilde;os</p>
                        </div>
                        <hr class="border-hgray w-[70%] mt-2 mb-4">
                    </div>
                    <p class="font-bold text-hgold text-xl">Objetivos:</p>
                    <ul class="list-disc text-left pl-4 mb-4">
                        <li class="mb-2"> Acreditar 24 asignaturas del plan de estudios dise&ntilde;adas espec&iacute;ficamente para el desarrollo &oacute;ptimo de la licenciatura.</li>
                        <li class="mb-2">Acreditar 5 talleres profesionales; cinco con el objetivo de fortalecer los valores y el desarrollo emocional de los estudiantes.</li>
                        <li class="mb-2">Acreditar 5 seminarios con temas espec&iacute;ficos de la Licenciatura para complementar la formaci&oacute;n profesional.</li>
                        <li class="mb-2">Preparar al alumno para obtener la certificaci&oacute;n del idioma ingl&eacute;s.</li>
                        <li class="">Acreditar el programa social "Humanitas Vive", el cual tiene por objetivo la ayuda y conciencia en nuestro entorno, abarcando aspectos relacionados con grupos vulnerables, animales y el medio ambiente.</li>
                    </ul>
                    <p class="font-bold text-hgold text-xl">Metodolog&iacute;a:</p>
                    <p class="text-justify">Las clases se imparten de lunes a jueves, y los viernes se promueve la participaci&oacute;n en actividades deportivas y actividades del programa vive para un equilibrio integral.</p>
                </div>
                <div class="flex w-full lg:w-1/2 justify-center items-center">
                    <img src="./pagesImages/etapa-1.jpg" loading="lazy" alt="Etapa 1" class="w-full max-w-[494px]">
                </div>
            </div>
            <div class="flex items-center justify-center">
                <img src="../pages/pagesImages/adorno.png" loading="lazy" alt="45 aniversario" class="my-10">
            </div>
            <div class="flex flex-col lg:flex-row justify-center items-center w-full">
                <div class="order-2 lg:order-1 flex w-full lg:w-1/2 justify-center items-center">
                    <img src="./pagesImages/etapa-2.jpg" loading="lazy" alt="Etapa 2" class="w-full max-w-[494px]">
                </div>
                <div class="order-1 lg:order-2 flex flex-col w-full lg:w-1/2 text-left px-4 mb-6 lg:mb-0">
                    <div class="w-full flex flex-col items-center lg:items-start">
                        <h1 class=" font-serif text-[42px] text-hgold">Etapa II</h1>
                        <div class="flex items-center text-gray-400 text-left">
                            <p class=" text-xl">2 Trimestres, 6 meses</p>
                        </div>
                        <hr class="border-hgray w-[70%] mt-2 mb-4">
                    </div>
                    <p class="font-bold text-hgold text-xl">Objetivos:</p>
                    <ul class="list-disc text-left pl-4 mb-4">
                        <li class="mb-2">Acreditar 6 asignaturas del plan de estudios dise&ntilde;adas espec&iacute;ficamente en el &aacute;rea del conocimiento de la licenciatura.</li>
                        <li class="mb-2">Acreditar 2 seminarios con temas espec&iacute;ficos de la licenciatura para complementar la formaci&oacute;n profesional.</li>
                        <li class="mb-2">Concientizar e impulsar a los alumnos para iniciar pr&aacute;cticas profesionales.</li>
                        <li class="">Acreditar la certificaci&oacute;n de Cambridge como parte de sus requisitos de titulaci&oacute;n/perfil de egreso.</li>
                    </ul>
                    <p class="font-bold text-justify text-hgold text-xl">Metodolog&iacute;a:</p>
                    <p class="mb-2 text-justify">Los estudiantes realizan una transici&oacute;n al turno vespertino, con el objetivo de centrarse en su trabajo o proyectos profesionales. La jornada se mantiene de lunes a jueves. Aquellos que lo deseen podr&aacute;n continuar con sus actividades culturales y deportivas los viernes.</p>
                    
                </div>
            </div>
            <div class="flex items-center justify-center">
                <img src="../pages/pagesImages/adorno.png" loading="lazy" alt="45 aniversario" class="my-10">
            </div>
            <div class="lg:flex justify-center items-center w-full">
                <div class="flex flex-col w-full lg:w-1/2 text-left px-4 mb-6 lg:mb-0">
                    <div class="w-full flex flex-col items-center lg:items-start">
                        <h1 class=" font-serif text-[42px] text-hgold">Etapa III</h1>
                        <div class="flex items-center text-gray-400 text-left">
                            <p class="text-xl">4 Trimestres, 1 a&ntilde;o</p>
                        </div>
                        <hr class="border-hgray w-[70%] mt-2 mb-4">
                    </div>
                    <p class="font-bold text-hgold text-xl">Objetivos:</p>
                    <ul class=" list-disc text-left pl-4 mb-4">
                        <li class="mb-2">Acreditar 12 asignaturas del plan de estudios dise&ntilde;adas espec&iacute;ficamente en el &aacute;rea del conocimiento de la licenciatura.</li>
                        <li class="mb-2">Concientizar e impulsar a los alumnos para que inicien su vida profesional, promoviendo la importancia del trabajo y estudio.</li>
                        <li class="mb-2">Iniciar los tr&aacute;mites de egreso para obtener el t&iacute;tulo de licenciatura y la c&eacute;dula profesional.</li>
                    </ul>
                    <p class="font-bold text-hgold text-xl">Metodolog&iacute;a:</p>
                    <p class=" text-justify">Los estudiantes realizan una transici&oacute;n al sistema ejecutivo para que puedan concentrarse en su trabajo y se reduce el costo de la colegiatura, esto crea la oportunidad de que el alumno pueda cubrir sus propios gastos educativos. El horario se ajusta a una jornada de 6 horas, un d&iacute;a por semana en turno vespertino.</p>
                </div>
                <div class="flex w-full lg:w-1/2 justify-center items-center">
                    <img src="./pagesImages/etapa-3.jpg" loading="lazy" alt="Etapa 3" class="w-full max-w-[494px]">
                </div>
            </div>
        </div>
    </section>

    <section class="flex justify-center items-center w-screen h-full lg:h-[600px] bg-cover lg:bg-center bg-no-repeat my-28" style="background-image: url(../pagesImages/perfil-escolarizado.jpg); ">
        <div class="lg:flex justify-between w-full max-w-4xl lg:max-w-[1200px] items-center">
            <div class="flex flex-col lg:justify-start justify-center items-center lg:items-start lg:w-[45%] w-full lg:my-36 my-12 text-justify px-4">
                <h1 class="text-[white]  text-left text-[28px]">Perfil del</h1>
                <h1 class="text-hgold text-left font-serif mb-8 text-[42px]">Alumno Escolarizado</h1>
                <div class="text-white text-base">
                    <p class="pb-4">
                        Personas poseen una mente anal&iacute;tica y cr&iacute;tica, que les permite abordar problemas complejos con creatividad y soluciones innovadoras.
                    </p>
                    <p class="pb-4">
                        Comunicadora habilidosos, capaces de expresar sus ideas de manera clara y persuasiva tanto verbalmente como por escrito, siendo poseedores de una mentalidad abierta y curiosa, siempre dispuesta a aprender y explorar nuevos conceptos y perspectivas.
                    </p>
                    <p class="pb-4">
                        Motivados por su deseo de contribuir al mundo de manera significativa y hacer una diferencia en la vida de los dem&aacute;s.
                    </p>
                    <p>
                        Con deseos de aprovechar al m&aacute;ximo su educaci&oacute;n universitaria para seguir desarrollando sus habilidades y conocimientos en su &aacute;rea de inter&eacute;s, con la esperanza de eventualmente dedicarse a una carrera que le permita hacer un impacto positivo en la sociedad.
                    </p>
                </div>
            </div>
        </div>
    </section>
    
    <section class="flex justify-center w-full">
        <div class="flex justify-end w-full max-w-4xl lg:max-w-[1200px] bg-cover bg-[top_center] bg-no-repeat" style="background-image: url(/pagesImages/Lcis-Escolarizadas.jpg);">
            <div class="flex flex-col justify-center rounded-md">
                <div class="flex flex-col items-center text-left text-white justify-end md:my-20" >
                    <div class="flex flex-col lg:mr-32 w-full items-center p-4">
                        <h1 class="text-center leading-tight text-[28px] ">Licenciaturas</h1>
                        <p class="text-center mb-4 font-serif text-hgold leading-tight text-[36px] tracking-[2px]">Escolarizadas</p>
                        <hr class="border-white  w-[42%] mb-4">
                        <ol class="pl-14 text-[20px] mb-8 text-left list-disc">
                            <li class="hover:text-hgold"><router-link class="text-white hover:text-hgold" to="/licenciatura-en-derecho">Derecho</router-link></li>
                            <li class="hover:text-hgold"><router-link class="text-white hover:text-hgold" to="/licenciatura-en-administracion">Administraci&oacute;n</router-link></li>
                            <li class="hover:text-hgold"><router-link class="text-white hover:text-hgold" to="/licenciatura-en-contabilidad">Contabilidad</router-link></li>
                            <li class="hover:text-hgold"><router-link class="text-white hover:text-hgold" to="/licenciatura-en-ciencias-politicas">Ciencias Pol&iacute;ticas</router-link></li>
                            <li class="hover:text-hgold"><router-link class="text-white hover:text-hgold" to="//licenciatura-en-psicologia">Psicolog&iacute;a</router-link></li>
                            <li class="hover:text-hgold"><router-link class="text-white hover:text-hgold" to="/licenciatura-en-educacion">Educaci&oacute;n</router-link></li>
                            <li class="hover:text-hgold"><router-link class="text-white hover:text-hgold" to="/licenciatura-en-diseno-grafico">Dise&ntilde;o Gr&aacute;fico</router-link></li>
                            <li class="hover:text-hgold"><router-link class="text-white hover:text-hgold" to="/licenciatura-en-arquitectura">Arquitectura</router-link></li>
                            <li class="hover:text-hgold"><router-link class="text-white hover:text-hgold" to="/licenciatura-en-arte-y-teatro">Arte y Teatro</router-link></li>
                        </ol>
                        <div class="flex items-center justify-center">
                            <router-link to="/licenciaturas">
                                <div class="flex items-center justify-center w-[140px] h-[35px] bg-white/70 hover:bg-hgold text-hwine border-2 border-hwine text-lg transition duration-300 ease-in-out">
                                    <span>Inscr&iacute;bete</span>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="flex items-center justify-center">
        <img src="../pages/pagesImages/adorno.png" loading="lazy" alt="45 aniversario" class="my-10">
    </div>

    <section class="flex items-center justify-center w-full">
        <div class="flex flex-col lg:flex-row justify-start w-full max-w-4xl lg:max-w-[1200px] items-center mb-20">
            <div class="order-2 lg:order-1 flex items-center justify-center">
                <img src="./pagesImages/voluntariado.jpg" loading="lazy" alt="Voluntariado">
            </div>
            <div class="order-1 lg:order-2 flex flex-col lg:w-[50%] items-center text-justify lg:ml-12">
                <div class="mb-8 text-justify w-full px-4">
                    <div class="mb-6 w-full text-center lg:text-left  leading-tight">
                        <p class="text-gray-700 font-normal text-[28px]">Programa</p>
                        <p class="text-hgold font-serif text-[42px]">Humanitas Vive</p>
                    </div>
                    <p>Es un programa de voluntariado en el cual las acciones y actividades est&aacute;n orientadas a la responsabilidad social de Universidad Humanitas, mediante el cual los alumnos pueden liberar su servicio social.</p>
                    <br>
                    <p>Estas actividades pueden ser:</p>
                    <br>
                    <p class="pl-2">• Sociales</p>
                    <p class="pl-2">• Culturales</p>
                    <p class="pl-2">• De altruismo</p>
                    <p class="pl-2">• Deportivas</p>
                    <p class="pl-2">• Ambientales</p>
                    <br>
                    <p>Humanitas Vive trabaja con instituciones autorizadas a las cuales se les brinda ayuda mediante donativos (sea en especie o en dinero) o mediante la organizaci&oacute;n de alguna actividad que vaya acorde con su ramo.</p>
                </div>
                
            </div>
            
        </div>
    </section>
    
    <section class="flex flex-col w-full">
        <div class="flex flex-col justify-center w-screen h-[400px] lg:h-[500px] items-center bg-cover bg-[center] bg-no-repeat" style="background-image: url(/pagesImages/cambridge.jpg);">
            <div class="flex w-full bg-black/70 justify-center items-center">
                <div class="flex flex-col md:flex-row justify-center lg:justify-start items-center w-full h-[400px] lg:h-[500px] max-w-4xl lg:max-w-[1200px] px-4">
                    <div class="flex justify-center md:justify-start text-left h-auto">
                        <router-link to="/english-kingdom" class="px-2">
                            <img src="./pagesImages/shield.png" loading="lazy" alt="Escudo" class="w-[100px] h-full md:w-full md:max-w-[210px] md:h-auto">
                        </router-link>
                        <div class="h-auto md:hidden">
                            <h1 class="lg:text-[48px] text-[28px] font-serif">English</h1>
                            <h1 class="lg:text-[72px] text-[42px] mb-2 font-serif text-hgold">Kingdom</h1>
                            <hp class="lg:text-[21px] text-[19px] mb-4 font-serif">Certified by the University of Cambridge</hp>
                        </div>
                    </div>
                    <div class="flex flex-col text-left md:my-8 text-white w-full lg:w-[40%]">
                        <div class="hidden md:flex md:flex-col h-auto">
                            <h1 class="lg:text-[48px] text-[28px] font-serif">English</h1>
                            <h1 class="lg:text-[72px] text-[42px] mb-2 font-serif text-hgold">Kingdom</h1>
                            <hp class="lg:text-[21px] text-[19px] mb-4 font-serif">Certified by the University of Cambridge</hp>
                        </div>
                        <p class="w-full mb-4 text-center md:text-left">Nuestros cursos de English Kingdom est&aacute;n basados en la estructura propuesta por el Marco Com&uacute;n Europeo de Referencia para Idiomas.</p>
                        <div class="flex justify-center w-full md:justify-start">
                            <router-link to="/english-kingdom">
                                <div class="flex items-center justify-center w-[140px] h-[35px] bg-hwine hover:bg-hgold text-white text-lg transition duration-300 ease-in-out">
                                    <span>Explorar</span>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <EgresadosVid/>

        <div class="flex flex-col justify-center w-screen h-[400px] lg:h-[500px] bg-cover bg-[center] bg-no-repeat" style="background-image: url(/pagesImages/actividades.jpg);">
            <div class="flex justify-center w-full">
                <div class="flex text-white justify-end w-full max-w-4xl lg:max-w-[1200px]">
                    <div class="flex flex-col items-center justify-center text-justify px-4 w-full h-[400px] lg:h-[500px] md:w-[70%] lg:w-[50%] bg-black/60 md:bg-transparent">
                        <p class="text-right text-3xl">Talleres y Actividades</p>
                        <h1 class="text-right mb-2 font-serif text-[42px] text-hgold">Deportivas  y Culturales</h1>
                        <p>Forma parte de nuestras actividades y talleres. Tenemos diversas opciones para que tu tiempo se convierta en algo &uacute;nico. Preg&uacute;nta en tu campus por las actividades que esten vigentes. Te esperamos.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
</template>

<style>

</style>