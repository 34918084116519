<script setup lang="ts">
</script>

<template>
    <section class="flex justify-center items-center w-screen h-full bg-cover bg-[top_center] bg-no-repeat" style="background-image: url(../pagesImages/bannerkingdom.jpg); ">
        <div class="lg:flex justify-between w-full max-w-4xl lg:max-w-[1200px] items-center">
            <div class="flex flex-col lg:justify-start justify-center items-center lg:items-start lg:w-[50%] w-full my-20 text-justify md:px-4 px-12 text-white">
                <h1 class="text-[white]  text-left text-[28px]" style="">M&eacute;todo Educativo</h1>
                <h1 class="text-hgold text-left font-serif mb-2 text-[42px]">Semi - Abierto</h1>
                <p class="mb-4">
                    Tres horas al d&iacute;a de clases presenciales, una vez por semana.
                </p>
                <p class="mb-4">
                    • Curso  -  8 Trimestres.
                </p>
                <p class="mb-4">
                    • Preparaci&oacute;n para certificaciones Cambridge seg&uacute;n el nivel que est&eacute;s cursando.
                </p>
                <p class="mb-4">
                    • Es obligatorio presentarse en Campus el d&iacute;a y hora establecidos para los ex&aacute;menes.
                </p>
                <p class="mb-4">
                    En English Kingdom ingresas al nivel que te corresponda, mediante un examen de colocaci&oacute;n, mismo que nos ayudar&aacute; a determinar si te encuentras en un nivel B&aacute;sico, Intermedio o Avanzado y asimismo el tipo de certificaci&oacute;n que corresponde a tu nivel.
                </p>
                <router-link to="/contacto">
                    <div class="flex items-center justify-center w-[140px] h-[35px] bg-white/70 hover:bg-hgold text-hwine border-2 border-hwine text-lg transition duration-300 ease-in-out">
                        <span>Inscr&iacute;bete</span>
                    </div>
                </router-link>
            </div>
        </div>
    </section>
</template>

<style>

</style>