<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">Estoicismo: cómo puede ayudar a los<br class="md:block hidden"> estudiantes a superar desafíos</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-300">
          <img src="/images/Que-es-estoicismo-y-como-ayuda-a-universitarios.jpg" class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/aprende" class=" text-hone">Aprende</router-link> > <router-link to="/blog/aprende/tips" class=" text-hone">Tips</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex list-disc pl-5 italic text-left text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">15 de marzo de 2024</li>  <li>Carolina Reyes Martínez/Redacción Humanitas</li></ul>
            </div>
            <p class=" md:mb-8 mb-10"><b>Extracto:</b> "¿Estoicismo? ¿Qué es eso?" Si tienes estas dudas, no te preocupes, aquí te explicamos y te diremos cómo puede serte útil.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">¿Qué es el Estoicismo?</h2>
            <p class=" md:mb-4 mb-6">Al principio, la palabra puede sonar extraña, pero el <b>Estoicismo viene del griego Stoa Poikile que significa portón pintado, e ismo que significa doctrina.</b> Y se le denominó así porque durante el siglo III A. C. en la antigua Grecia, concretamente en Atenas, Zenón de Citio (336-264 A. C), un filósofo de origen fenicio, propuso una ética personal en la cual, si bien no se puede controlar todo lo que ocurre en la vida, sí se puede controlar la manera en la que se toman las cosas. Así, la filosofía estoica fue fundada.</p>
            <p class=" md:mb-4 mb-6"><b>Se basa en un sistema lógico donde todo tiene una causa y un efecto. </b>Por eso, es importante ser disciplinado, tolerante y tener autocontrol. La razón y el coraje al enfrentar los problemas diarios nos llevan a alcanzar la felicidad y a una vida armoniosa.</p>
            <p class=" md:mb-4 mb-6">Zenón de Citio enseñaba en el Stoa Poikile, un portón adornado con escenas de batallas históricas que se encontraba al este de la ciudad de Atenas. Por esa razón, a él y a sus alumnos se les comenzó a decir estoicos.</p>
            <p class=" md:mb-8 mb-10">Entre los más destacados de la escuela estoica están el sucesor de Zenón, <b>Cleantes de Aso,</b> quien a su vez fue maestro de Crisipo de Solos. Cuando Crisipo falleció, lo sucedió <b>Zenón de Tarso.</b> A esta época se le conoció como <b>Estoicismo Antiguo.</b></p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Los principales pensadores estoicos romanos y sus doctrinas</h2>
            <p class=" md:mb-4 mb-6"><b>El Estoicismo tuvo mucho éxito no sólo en Grecia, también en Roma </b>a donde se generó el Estoicismo romano. A esta etapa se le conoce como Estoicismo Ecléctico o Medio.</p>
            <p class=" md:mb-4 mb-6">Los filósofos <b>Panesio de Rodas y Posidonio fueron sus exponentes. </b>A esta corriente se le conoce como ecléctica porque se adaptó a los ciudadanos romanos y tomó algunas influencias del platonismo, el aristotelismo y epicureísmo.</p>
            <p class=" md:mb-4 mb-6"><b>Esta doctrina fue el modelo para los dirigentes romanos, </b>quienes valoraron la imparcialidad y el autocontrol para la acción política.</p>
            <p class=" md:mb-4 mb-6">Después, vendría <b>la tercera etapa conocida como Estoicismo romano o imperial, </b>que abandona la parte ecléctica y <b>se concentra en los valores morales. </b>Coincidió con los primeros siglos del cristianismo.</p>
            <p class=" mb-2">El corazón del Estoicismo late gracias a las enseñanzas de tres figuras principales: <b>Séneca, Epicteto y Marco Aurelio.</b></p>
            <ul class="list-decimal pl-12 md:mb-4 mb-6">
                <li><b>Séneca,</b> Afilósofo, dramaturgo y estadista, enfatizó la importancia de la virtud, la ética y el control sobre las propias emociones.</li>
                <li><b>Epicteto</b> era un esclavo liberado que se convirtió en un filósofo estoico influyente. Enseñaba que no podemos controlar lo que sucede afuera, pero sí podemos elegir cómo reaccionar ante ello.</li>
                <li><b>Marco Aurelio</b>fue un emperador romano y filósofo. Escribió Meditaciones, un libro donde comparte reflexiones sobre los principios estoicos en el liderazgo y la vida cotidiana, cómo fomentar la empatía y responder ante las adversidades.</li>
            </ul>
            <p class=" md:mb-8 mb-10">Después de la muerte de Marco Aurelio, el Estoicismo y otras escuelas filosóficas fueron cerradas. A pesar de esto, influyeron en el cristianismo en la Edad Media, especialmente en pensadores como <b>Santo Tomás de Aquino y San Agustín de Hipona.</b></p>
            <img src="/images/estoicismo-beneficios-en-universitarios-A.jpg" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Cómo pueden ayudarte los principios del Estoicismo en la escuela</h2>
            <p class=" md:mb-8 mb-10">La vida universitaria está llena de desafíos: presión académica, entrega de tareas en determinado tiempo; el manejo de relaciones personales y profesionales. <b>Esta filosofía de vida te ofrece estos principios, chécalos.</b></p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Si no lo puedes cambiar, acéptalo</h3>
            <p class=" md:mb-8 mb-10">Concentra tus energías en la acción, si tienes un examen, sabes que debes estudiar para hacerlo. No significa resignación, sino reconocer la realidad para trabajar eficazmente dentro de ella.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">El que persevera, alcanza</h3>
            <p class=" md:mb-8 mb-10">El Estoicismo no sólo trata sobre la aceptación, <b>también sobre el esfuerzo activo</b> en lo que sí está en nuestras manos. La perseverancia y dedicación te ayudarán a mejorar en el estudio. Cada obstáculo al que te enfrentes es una oportunidad para crecer.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Vive en el presente</h3>
            <p class=" md:mb-8 mb-10">A menudo nos preocupamos por el futuro, o bien, por todo lo que hicimos mal. Esto puede llevar a situaciones estresantes o a ansiedad. <b>El estoicismo te enseña a vivir aquí y ahora</b> para tomar decisiones conscientes, disfrutar de los logros y aprender de los errores.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Beneficios del Estoicismo para ti</h2>
            <p class=" md:mb-8 mb-10">El estoicismo, dentro de muchas corrientes filosóficas, es una herramienta práctica. Veamos cuáles son sus beneficios.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Manejo del estrés y la ansiedad</h3>
            <p class=" md:mb-4 mb-6">El ambiente universitario puede ocasionar problemas de salud mental, como trastornos de ansiedad y estrés: desde la carga escolar hasta la presión de cumplir con las expectativas, tuyas o de otros.</p>
            <p class=" md:mb-8 mb-10"><b>Aprender a enfocarse sólo en tus acciones, disminuye la preocupación excesiva </b>por los resultados, lo que te lleva a un estado mental más tranquilo y productivo.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Mejora en la toma de decisiones y la resiliencia</h3>
            <p class=" md:mb-8 mb-10">Decisiones como elegir tu carrera o la planificación de tu tiempo, son importantes, por lo que la práctica estoica de la reflexión y del conocimiento propio te fortalece. <b>Adaptarse y enfrentar los problemas te ayudará a ser más fuerte</b> y superar los desafíos de forma exitosa.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Relaciones más sanas y productivas</h3>
            <p class=" md:mb-8 mb-10">El estoicismo propone la empatía: cada uno lucha su propia batalla. <b>Una visión estoica te ayuda a desarrollar mayor paciencia y comprensión hacia los demás.</b> Al hacer amigos en la universidad, te sentirás mejor emocionalmente gracias al apoyo mutuo. Los lazos que crees serán más sólidos.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Autoestima y mejora continua</h3>
            <p class=" md:mb-8 mb-10">Finalmente, el estoicismo <b>impulsa un compromiso con tu desarrollo personal.</b> Esta filosofía te enseña a ser crítico contigo mismo, pero de una manera constructiva, buscando siempre cómo crecer y mejorar. Un enfoque proactivo potencia tu autoestima en la universidad.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Conclusión</h2>
            <p class="md:mb-8 mb-10"><b>El Estoicismo, </b>más que una filosofía antigua, <b>es una herramienta que te prepara</b> para enfrentar las adversidades con fortaleza y sabiduría. Vive el presente, confía en ti y en tus decisiones. ¡Conoce más sobre el Estoicismo!</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Bonus: Sugerencias</h3>
            <p class=" mb-2">Si quieres saber más, puedes leer y ver:</p>
            <ul class="list-disc pl-6 md:mb-4 mb-6">
                <li><a href="https://elibro.net/es/lc/humanitas/login_usuario/?next=/es/ereader/humanitas/25781/?clave=&carrera=&tipodeusuario=teambook&nombreusuario=&libroId=" target="_blank" class="text-htwo">En biblioteca virtual de Humanitas: El estoicismo como una propuesta alternativa para la contemporaneidad. Jaime Alberto Restrepo Rozo.</a></li>
                <li>En YouTube: <a href="https://www.youtube.com/watch?v=9ldXznMe1W8" target="_blank" class="text-htwo">TED Talk: La filosofía del estoicismo por Massimo Pigliucci</a></li>
            </ul>
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
              <Contacto class="m-3"/>
            </div>
            
            <p class="text-htwo text-left mb-6">Explora más: <a href="https://humanitas.edu.mx/" target="_blank" class="text-htwo">Universidad Humanitas</a></p>
            <p class="text-htwo text-left mb-20">Te puede interesar: <router-link to="/blog/aprende/tendencias/tendencias-en-psicologia-2024-tecnologia-salud-mental"  class="text-htwo">Tendencias en Psicología para 2024: Tecnología en la salud mental</router-link></p>
            <p class="text-htwo text-left font-bold mb-2">Fuente:</p>
            <p class="text-left mb-20 text-base"><a href="https://humanitas.edu.mx/licenciatura-en-arte-y-teatro" target="_blank" class="text-black">Licenciatura en Arte y Teatro</a></p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="true" :linkAnt="'/ser-humanitas/responsabilidad-social/por-que-es-importante-la-responsabilidad-social-universitaria'" :linkSig="'/academico/licenciatura/dia-mundial-del-teatro-2024'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import Contacto from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue'
import SigArt from '../../pagesComponents/SigArt.vue';

export default {
    components: { Comentarios, ConComentarios, Contacto, LikesVue, ShareP, SigArt},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '13-ART-APRENDE-TIPS-150324',
            ruta: '/aprende/tips/estoicismo-para-estudiantes-universitarios-y-beneficios',
            title: 'Estoicismo: cómo puede ayudar a los estudiantes a superar desafíos',
            image: 'https://blog.humanitas.edu.mx/images/Que-es-estoicismo-y-como-ayuda-a-universitarios.jpg',
            text: 'Explora los principios del Estoicismo y cuáles son sus beneficios en tu vida universitaria.'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx'+this.ruta;

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>