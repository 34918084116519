<template>
    <div class="text-[18px] hidden md:block" style="font-family: 'Helvetica Neue Regular', sans-serif;">
      <div class="cursor-pointer flex flex-col items-center">
        <div class="flex flex-wrap justify-center mb-1 gap-3">
            <img src="/images/new-icon-feis.png" @click="shareFacebook" alt="Facebook" class="w-[32px] relative cursor-pointer hover:scale-125 transition-transform duration-280">
            <img src="/images/new-icon-w.png" @click="shareWhatsapp" alt="Whatsapp" class="w-[32px] relative cursor-pointer hover:scale-125 transition-transform duration-280" />
            <!-- <button @click="shareInstagram" class="bg-transparent border border-[#999999] rounded-none focus:outline-none flex flex-col items-center">
              <img src="../images/ins-icon.png" alt="Instagram" class="w-12 h-12">
              <span>Instagram</span>
            </button> -->
            <img src="/images/new-icon-lin.png" @click="shareLinkedin" alt="Linkedin" class="w-[32px] relative cursor-pointer hover:scale-125 transition-transform duration-280">
            <img src="/images/new-icon-x.png" @click="shareX" alt="X" class="w-[32px] relative cursor-pointer hover:scale-125 transition-transform duration-280">
          </div>
        <span class="text-[black] hidden md:block">Compartir en redes</span>
      </div>
    </div>
    <div class="md:hidden">
      <div class="fixed md:bottom-16 bottom-16 md:left-2 left-3 z-50"> 
        <div @mouseover="openFilter" @mouseleave="startCloseTimer">
          <transition-group name="fade" tag="div" class="flex flex-col items-center space-y-4 mb-4">
            <img v-for="(image, index) in images" :key="index" v-if="isOpen" :src="image.src" :alt="image.alt" @click="share(image.option)" class="w-[48px] cursor-pointer transition-transform duration-280 hover:scale-110">
          </transition-group>
          <img src="/images/icono-compartir-boton.png" alt="share-icon" class="w-[48px] cursor-pointer hover:scale-125 transition-transform duration-280">
        </div>
      </div>
    </div>
</template>
  
<script lang="js">
export default {
  props: {
        articleLink: {
            type: String,
            required: true
        },
        articleText: {
            type: String,
            required: true
        },
        articleImage: {
            type: String,
            required: true
        }
  },
  data() {
    return {
      isOpen: false,
      domain: 'https://humanitas.edu.mx/blog/',
      images: [
        { src: '/images/new-icon-feis.png', alt: 'Facebook icon movil', onClick: this.shareFacebook, option: 'facebook' },
        { src: '/images/new-icon-w.png', alt: 'Whatsapp icon movil', onClick: this.shareWhatsapp, option: 'whatsapp'},
        { src: '/images/new-icon-lin.png', alt: 'Linkedin icon movil', onClick: this.shareLinkedin, option: 'linkedin'},
        { src: '/images/new-icon-x.png', alt: 'X icon movil', onClick: this.shareX, option: 'x'}
      ]

    };
  },
  methods: {
    closeFilter()  {
      // @ts-ignore
      this.isOpen=false
    },
    startCloseTimer() {
      // @ts-ignore
        this.closeTimer = setTimeout(() => {
            this.closeFilter();
        }, 200); // Espera 200ms antes de cerrar el filtro
    },
    cancelCloseTimer() {
      // @ts-ignore
        clearTimeout(this.closeTimer); // Limpiar el temporizador si el usuario vuelve al filtro antes de que se cierre
    },
    openFilter() {
      // @ts-ignore
        clearTimeout(this.closeTimer); // Limpiar el temporizador si está activo
        // @ts-ignore
        this.isOpen = true;
    },
    // @ts-ignore
    share(socialMedia) {
        const url = window.location.href;
        const message = `${url}`;
        let shareUrl = '';
        switch (socialMedia) {
            case 'facebook':
                shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(message)}&picture=${encodeURIComponent(this.articleImage)}`;
                break;
            case 'whatsapp':
                shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
                break;
            case 'linkedin':
                const linkedinMessage = `${this.articleText} ${url}`;
                shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}&title=${encodeURIComponent(this.articleText)}&summary=${encodeURIComponent(linkedinMessage)}&source=${encodeURIComponent(this.domain)}&image=${encodeURIComponent(this.articleImage)}`;
                break;
            case 'x':
                const tweetText = `${this.articleText}`;
                shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}&url=${encodeURIComponent(url)}`;
                break;
            default:
                console.error('Error');
                return;
        }

        window.open(shareUrl, '_blank');
        this.isOpen = false;
    },
    shareFacebook() {
        const message = `${window.location.href}`;
        const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}&quote=${encodeURIComponent(message)}&picture=${encodeURIComponent(this.articleImage)}`;
        window.open(url, '_blank');
        this.isOpen=false
    },
    shareWhatsapp() {
        const message = `${window.location.href}`;
        const url = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
        window.open(url, '_blank');
        this.isOpen=false
    },
    shareLinkedin() {
        const message = `${this.articleText} ${window.location.href}`;
        const url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent(this.articleText)}&summary=${encodeURIComponent(message)}&source=${encodeURIComponent(this.domain)}&image=${encodeURIComponent(this.articleImage)}`;
        window.open(url, '_blank');
        this.isOpen=false
    },
    shareX() {
        const tweetText = `${this.articleText}`;
        const shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}&url=${encodeURIComponent(window.location.href)}`;
        window.open(shareUrl, '_blank');
        this.isOpen=false
    },
    toggleOpen() {
      this.isOpen = !this.isOpen;
    },
  }
};
</script>

<style scoped>
/* Estilos del lightbox */
</style>