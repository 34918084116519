<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">Derecho corporativo empresarial: <br class="md:block hidden"> ¿cuál es su origen?</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-200">
          <img src="/images/derecho-corporativo-empresarial-mercantil.webp" alt="Una maestría en Psicología tiene un amplio campo de especialización" class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/academico" class=" text-hone">Académico</router-link> > <router-link to="/blog/academico/maestria" class=" text-hone">Maestría</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex text-left list-disc pl-5 italic text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">23 de mayo de 2024</li>  <li>Iván Solís Rivera</li></ul>
            </div>
            <p class=" md:mb-4 mb-6"><b>El Derecho Corporativo Empresarial reúne tres ramas de conocimiento</b> y que refieren a la especialización en el sector jurídico, cuyo origen se basa en las normas y principios que regulan el comportamiento humano en sociedad.</p>
            <p class=" md:mb-4 mb-6">Uno de los aspectos más importantes del <router-link to="/blog/academico/licenciatura/aptitudes-habilidades-para-estudiar-derecho" class="text-htwo">Derecho</router-link> o abogacía es que, con base, en las leyes y reglamentos establecidos de manera colectiva, <b> establece qué es lo justo y lo injusto, lo permitido y lo prohibido, para los humanos dentro de la sociedad.</b></p>
            <p class=" md:mb-8 mb-10">Es de resaltar que <b>las normas y las legislaciones</b> son creadas por instituciones reconocidas para <b>garantizar la convivencia pacífica</b> y ordenada entre los individuos y grupos sociales.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Abogacía: ¿cuál es su objeto de estudio?</h2>
            <p class="md:mb-4 mb-6">El Derecho ha existido desde las primeras civilizaciones, <b> se fundamenta en el ordenamiento jurídico que comprende principios y normas jurídicas vigentes en un Estado.</b></p>
            <p class="md:mb-8 mb-10">Esta rama de conocimiento <b>se vincula estrechamente con la Ciencia Política, la Economía, la Sociología y la Historia,</b> abordando problemas humanos complejos, como definir el significado de conceptos como igualdad, libertad o justicia en casos concretos. Las cuestiones más generales sobre el Derecho han sido objeto de estudio por la Filosofía, la historia y la teoría del Derecho.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">¿Cómo se inició el Derecho y se establecieron las leyes?</h3>
            <p class="md:mb-4 mb-6">La palabra "derecho" proviene del término latino "directum", que <b>significa "lo que está conforme a la regla, a la ley, a la norma".</b></p>
            <p class="md:mb-4 mb-6">El Derecho <b>se origina en las sociedades agrícolas de la antigüedad,</b>  con base en normas y leyes que se transmitían de manera oral, <b> consistiendo en fórmulas breves que regulaban el intercambio</b> y resolvían disputas sobre propiedad o problemas entre las personas.</p>
            <p class="md:mb-4 mb-6">A medida que las sociedades crecieron, las leyes se hicieron más complejas, lo que generó la necesidad de registrarlas por escrito y gestionarlas adecuadamente, a fin de garantizar la paz social y un orden productivo. Así, el derecho habría surgido junto con las primeras formas primitivas de Estado.</p>
            <p class="md:mb-4 mb-6"><b>Uno de los principales pilares que acuñó el concepto de Derecho fue en Occidente, con la antigua República Romana</b> que tenía un sistema legal que estaba respaldado en la Ley de las XII Tablas y la compilación jurídica realizada por el emperador Justiniano en el siglo VI d.C. (el Corpus Iuris Civilis).</p>
            <p class="md:mb-4 mb-6"><b>El Derecho romano amplió su aplicación distinguiendo entre Derecho público y Derecho privado,</b> regulando tanto los asuntos relacionados con el Estado como aquellos referidos a la vida privada.</p>
            <p class="md:mb-4 mb-6">Este sistema consideraba las decisiones del senado romano, los pronunciamientos de los magistrados romanos, los plebiscitos y las opiniones de los jurisconsultos.</p>
            <p class="md:mb-4 mb-6">La importancia del Derecho romano fue tan significativa en Occidente y en el mundo que gran parte del sistema legal actual de muchas naciones tiene sus raíces en éste. </p>
            <img src="/images/caracteristicas-derecho-empresarial-empresas.webp" alt="Las características del derecho empresarial se basan en la estructura de empresas." class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Fundamentos del Derecho Corporativo y el Derecho Empresarial</h2>
            <p class="md:mb-4 mb-6">A lo largo de la historia, el Derecho ha ido evolucionando y ampliando su campo de estudio. Tanto el <router-link to="/blog/academico/licenciatura/mejores-universidades-derecho-mexico-guia" class="text-htwo">Derecho Corporativo como el Derecho Empresarial</router-link> <b>la formalización de las corporaciones y el comercio en la edad media, así como la compleja regulación actual que gobierna las actividades comerciales y corporativas del mundo globalizado sentaron los precedentes</b> para que el Derecho Corporativo sea importante para el funcionamiento eficiente y ético de las empresas.</p>
            <p class="md:mb-4 mb-6">Las primeras asociaciones comerciales en la antigüedad, <b>se han desarrollado a partir de necesidades prácticas y económicas</b> de las sociedades modernas.</p>
            <p class="md:mb-8 mb-10">Actualmente <b>este concepto proporciona el marco necesario para la creación, operación, y disolución de las corporaciones,</b>  protegiendo los intereses de las partes involucradas, fomentando un ambiente propicio para la inversión y el crecimiento económico.</p>
            <!-- Formulario -->
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
                <ContactoVue class="m-3"/>
            </div>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Derecho Corporativo y Derecho Empresarial: ¿Cuál es la diferencia?</h3>
            <p class="md:mb-4 mb-6">Mientras que <b>el Derecho Corporativo se especializa en la estructura y gobernanza de las entidades corporativas, el Derecho Empresarial cubre un espectro más amplio</b> de asuntos legales que una empresa puede enfrentar en sus operaciones cotidianas.</p>
            <p class="md:mb-4 mb-6">Entre las diferencias particulares de ambas ramas de conocimiento, se encuentran las siguientes:</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Derecho Corporativo</h4>
            <p class="md:mb-4 mb-6"><b>Se centra en las cuestiones legales relacionadas con la formación, operación y disolución de las corporaciones,</b> entre las que se encuentran: sociedades anónimas, sociedades de responsabilidad limitada, etc.</p>
            <h5 class="text-left font-serif text-hone text-[18px] mb-2">Objetivo</h5>
            <p class="md:mb-4 mb-6">El objetivo de este tipo de Derecho es <b>mantener la integridad y la buena gestión de las entidades corporativas, protegiendo los intereses de los accionistas</b> y asegurando el cumplimiento legal.</p>
            <h5 class="text-left font-serif text-hone text-[18px] mb-2">Características del Derecho Corporativo</h5>
            <p class="md:mb-4 mb-6">Su campo de aplicación <b>incluye la creación de entidades corporativas; redacción y presentación de documentos de incorporación,</b> así como la conformidad con los requisitos regulatorios.</p>
            <p class="md:mb-4 mb-6">Dentro de este ámbito abarca la compra, venta, y fusión de empresas, incluyendo la negociación y redacción de acuerdos, así como su debida diligencia, además, toca <b>temas de gobierno corporativo que se enfoca a la estructura y el funcionamiento interno de la empresa,</b> incluyendo los derechos y responsabilidades de los directores, ejecutivos y accionistas.</p>
            <p class="md:mb-8 mb-10">De manera paralela <b>busca cumplir la normatividad</b> y asegurar que las corporaciones cumplen con las leyes y regulaciones aplicables, también conocido como compliance.</p>
            <div class="flex items-center w-full h-full">
              <iframe src="https://www.youtube.com/embed/v6i4z-xwmmQ?si=u7e5hNgqoNDJpRU4" class="w-full lg:h-[540px] md:h-[480px] h-[240px] md:mb-8 mb-10" title="YouTube video player"
                frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen>
              </iframe>
            </div>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[18px] mb-3">Derecho Empresarial</h4>
            <p class="md:mb-4 mb-6">Su aplicación abarca los <b>aspectos legales que afectan a una empresa,</b> sin importar su estructura corporativa específica.</p>
            <h5 class="text-left font-serif text-hone text-[18px] mb-2">Objetivo</h5>
            <p class="md:mb-4 mb-6">El Derecho Empresarial <b>busca facilitar la operación eficiente y legal de una empresa en todos sus aspectos,</b> ayudando a gestionar riesgos y aprovechar oportunidades comerciales.</p>
            <h5 class="text-left font-serif text-hone text-[18px] mb-2">Características del Derecho Empresarial</h5>
            <p class="md:mb-4 mb-6">Entre los temas que abarca este tipo de Derecho, se encuentra <b>la facultad para redactar y revisar contratos comerciales, acuerdos de distribución,</b> licencias, y otros documentos legales relacionados con la operación diaria de la empresa.</p>
            <p class="md:mb-4 mb-6">De la misma forma aplica en la propiedad intelectual con el registro de marcas, patentes, derechos de autor y secretos comerciales. <b>Abarca las cuestiones relacionadas con los empleados, incluyendo contratos laborales,</b> cumplimiento de leyes laborales y resolución de disputas laborales.</p>
            <p class="md:mb-8 mb-10">También <b>aplica en litigios comerciales, en situaciones como la representación en disputas legales que surgen en el curso de los negocios</b> o ya sea en el asesoramiento sobre financiamiento, inversiones, y reestructuración o liquidación en casos de insolvencia.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Derecho Corporativo Empresarial y sus ramas de conocimiento</h2>
            <p class="md:mb-4 mb-6"><b>El Derecho Corporativo Empresarial es una rama integral del Derecho que se enlaza estrechamente con el Derecho Civil, Mercantil y Fiscal,</b> cada uno aportando un marco legal esencial para la operación y regulación de las empresas.</p>
            <p class="md:mb-4 mb-6">Respecto al Derecho Civil, proporciona las bases fundamentales para la constitución y funcionamiento de las empresas. <b>En México, a través del Código Civil, se establecen principios y normas sobre la propiedad, contratos y obligaciones.</b></p>
            <p class="md:mb-4 mb-6">Finalmente, <b>se vincula mediante la ley general de sociedades mercantiles y el código de comercio que brindan un marco legal detallado para las sociedades mercantiles.</b></p>
            <p class="md:mb-8 mb-10">De la misma forma, prepara a <b>el Derecho Fiscal asegura el cumplimiento de las leyes fiscales mediante el Código Fiscal de la Federación con base en leyes específicas como la del ISR y la del IVA.</b> Estas leyes determinan las responsabilidades fiscales de las empresas, incluyendo la correcta declaración y pago de impuestos</p>
            <img src="/images/derecho-empresa-contratos-comerciales.webp" alt="El Derecho de la empresa tiene que ver con cumplimiento de normas y contratos" class="md:mb-8 mb-10">
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Conclusión</h3>
            <p class="md:mb-4 mb-6">En la Universidad Humanitas contamos con la <b><a href="https://humanitas.edu.mx/maestria-en-derecho-corporativo" target="_blank" class="text-htwo">Maestría en Derecho Empresarial Corporativo</a>, que tiene como objetivo impartir el conocimiento de las normas y actividades jurídicas fundamentales para la planeación legal y el desarrollo de las empresas.</b></p>
            <p class="md:mb-8 mb-10">Brindamos los conocimientos teórico-prácticos necesarios para alcanzar el dominio, la habilidad, la comprensión, la interpretación y la aplicación del sistema corporativo general, asegurando su implementación eficiente en las organizaciones. Conócenos.</p>
            <!-- Aqui va el componente de contacto, no se te olvide hacerlo manolo -->
            <p class=" text-htwo text-left mb-20"><a href="https://humanitas.edu.mx/maestrias" target="_blank" class="text-htwo">Conoce las maestrías de la Universidad Humanitas</a></p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="true" :linkAnt="'/recursos/entrevistas-y-conferencias/procedimientos-tecnicas-de-auditoria-y-ejemplos-ricardo-sandoval-huerta'" :linkSig="'/aprende/tips/puntos-clave-para-evaluar-la-calidad-de-una-universidad-privada'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import ContactoVue from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue';
import SigArt from '../../pagesComponents/SigArt.vue';

export default {
    components: { Comentarios, ConComentarios, ContactoVue, LikesVue, ShareP, SigArt},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '0031-ART-ACADEMICO-MAESTRIA-230524',
            ruta: '/academico/maestria/derecho-corporativo-empresarial',
            title: 'Derecho corporativo empresarial: aspectos fundamentales',
            image: 'https://blog.humanitas.edu.mx/images/Costo-y-requisitos-estudiar-maestria.jpg',
            text: 'El Derecho Corporativo Empresarial es una área jurídica especializada que conjuga diversas áreas de conocimiento, conócelas.'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx/academico/maestria/costo-y-requisitos-estudiar-maestria';

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>