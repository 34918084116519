import art_001 from "../../pages/Blog/Recursos/EntreConf/01-ART-RECURSOS-EYCONF.vue"
import art_0023 from "../../pages/Blog/Recursos/EntreConf/0023-ART-RECURSOS-ENTREVISTAS-Y-CONFERENCIAS-100524.vue"
import art_0027 from "../../pages/Blog/Recursos/EntreConf/0027-ART-RECURSOS-ENTREVISTAS-Y-CONFERENCIAS-200524.vue"
import art_0030 from "../../pages/Blog/Recursos/EntreConf/0030-ART-RECURSOS-ENTREVISTAS-Y-CONFERENCIAS-220524.vue"
import art_0032 from "../../pages/Blog/Recursos/EntreConf/0032-ART-RECURSOS-ENTREVISTAS-Y-CONFERENCIAS-300524.vue"
import art_0036 from "../../pages/Blog/Recursos/EntreConf/0036-ART-RECURSOS-ENTREVISTAS-Y-CONFERENCIAS-060624.vue"

export default [
    {
      path: '/blog/recursos/entrevistas-y-conferencias/caracteristicas-de-la-mediacion',
      meta: {
        title: "Características de la mediación: la forma para evitar los tribunales",
        keywords: "Características de la mediación, Qué es la mediación, mediación de conflictos, Derecho",
        metaTags:
        {
          "og:keywords": "Características de la mediación, Qué es la mediación, mediación de conflictos, Derecho"
        },
        requireAuth: false,
      },
      name: 'Características de la mediación: la forma para evitar los tribunales',
      component: art_0036
    },
    {
      path: '/blog/recursos/entrevistas-y-conferencias/inclusion-diversidad-en-derecho-por-mariel-cabanas',
      meta: {
        title: "La inclusión de las mujeres y la diversidad en el Derecho por Mariel Cabañas",
        description: "Descubre cómo la inclusión y la diversidad en los ámbitos del Derecho y educativo enriquecen a la sociedad, con la experiencia de Mariel Cabañas, presidenta de Abogadas MX.",
        image: "https://blog.humanitas.edu.mx/images/meta/inclusion-mujeres-diversidad-derecho-impacto-benefico-sociedad.webq",
        keywords: "inclusión, diversidad, estrategias para la inclusión y diversidad, estrategias para la inclusión educativa, actividades de inclusión, cómo promover la inclusión: MARIEL CABANAS, cómo ayudar a una persona con discapacidad, discapacidad e inclusión",
        metaTags:
        {
          "og:title": "La inclusión de las mujeres y la diversidad en el Derecho por Mariel Cabañas",
          "og:description": "Descubre cómo la inclusión y la diversidad en los ámbitos del Derecho y educativo enriquecen a la sociedad, con la experiencia de Mariel Cabañas, presidenta de Abogadas MX.",
          "og:image": "https://blog.humanitas.edu.mx/images/meta/inclusion-mujeres-diversidad-derecho-impacto-benefico-sociedad.webp",
          "og:keywords": "inclusión, diversidad, estrategias para la inclusión y diversidad, estrategias para la inclusión educativa, actividades de inclusión, cómo promover la inclusión: MARIEL CABANAS, cómo ayudar a una persona con discapacidad, discapacidad e inclusión"
        },
        requireAuth: false,
      },
      name: 'La inclusión de las mujeres y la diversidad en el Derecho por Mariel Cabañas',
      component: art_0032
    },
    {
      path: '/blog/recursos/entrevistas-y-conferencias/procedimientos-tecnicas-de-auditoria-y-ejemplos-ricardo-sandoval-huerta',
      meta: {
        title: "Procedimientos y técnicas de auditoría: Pruebas clave y ejemplos",
        description: "Descubre los procedimientos y técnicas de la auditoría, y algunos ejemplos de Ricardo Sandoval Huerta, Coordinador de Auditoría de Corporativo Humanitas.",
        image: "https://blog.humanitas.edu.mx/images/meta/procedimientos-tecnicas-auditoria-precision-universidad-humanitas.webp",
        keywords: "Procedimientos de la auditoría, ejemplos de procedimientos de auditoría, que son los procedimientos de auditoría, técnicas de auditoría, técnicas de auditoría, procedimientos de auditoría",
        metaTags:
        {
          "og:title": "Procedimientos y técnicas de auditoría: Pruebas clave y ejemplosa",
          "og:description": "Descubre los procedimientos y técnicas de la auditoría, y algunos ejemplos de Ricardo Sandoval Huerta, Coordinador de Auditoría de Corporativo Humanitas.",
          "og:image": "https://blog.humanitas.edu.mx/images/meta/procedimientos-tecnicas-auditoria-precision-universidad-humanitas.webp",
          "og:keywords": "Procedimientos de la auditoría, ejemplos de procedimientos de auditoría, que son los procedimientos de auditoría, técnicas de auditoría, técnicas de auditoría, procedimientos de auditoría"
        },
        requireAuth: false,
      },
      name: 'Procedimientos y técnicas de auditoría: Pruebas clave y ejemplos',
      component: art_0030
    },
    {
      path: '/blog/recursos/entrevistas-y-conferencias/desarrollo-humano-una-perspectiva-integral-helios-herrera',
      meta: {
        title: "Desarrollo humano: Una perspectiva integral por Helios Herrera",
        description: "Descubre el enfoque único del consultor Helios Herrera sobre el desarrollo humano.",
        image: "https://blog.humanitas.edu.mx/images/meta/desarrollo-humano-integra-crecimiento-personal-profesional.webq",
        keywords: "que es el desarrollo humano, crecimiento humano, en qué consiste el desarrollo humano, desarrollo humano características",
        metaTags:
        {
          "og:title": "Desarrollo humano: Una perspectiva integral por Helios Herrera",
          "og:description": "Descubre el enfoque único del consultor Helios Herrera sobre el desarrollo humano.",
          "og:image": "https://blog.humanitas.edu.mx/images/meta/desarrollo-humano-integra-crecimiento-personal-profesional.webp",
          "og:keywords": "que es el desarrollo humano, crecimiento humano, en qué consiste el desarrollo humano, desarrollo humano características"
        },
        requireAuth: false,
      },
      name: 'Desarrollo humano: Una perspectiva integral por Helios Herrera',
      component: art_0027
    },
    {
      path: '/blog/recursos/entrevistas-y-conferencias/liderazgo-femenino-empresarial-exitoso',
      meta: {
        title: "Liderazgo femenino empresarial: la clave del éxito",
        description: "El liderazgo femenino está transformando las empresas globalmente. Descubre cómo en este artículo.",
        image: "https://blog.humanitas.edu.mx/images/meta/liderazgo-femenino-empresarial-exitoso-mujer-vs-liderazgo-masculinoempresaria.webp",
        keywords: "liderazgo femenino empresarial, la mujer y el liderazgo empresarial, liderazgo mujeres, liderazgo femenino, liderazgo femenino exitoso",
        metaTags:
        {
          "og:title": "Liderazgo femenino empresarial: la clave del éxito",
          "og:description": "El liderazgo femenino está transformando las empresas globalmente. Descubre cómo en este artículo.",
          "og:image": "https://blog.humanitas.edu.mx/images/meta/liderazgo-femenino-empresarial-exitoso-mujer-vs-liderazgo-masculinoempresaria.webq",
          "og:keywords": "liderazgo femenino empresarial, la mujer y el liderazgo empresarial, liderazgo mujeres, liderazgo femenino, liderazgo femenino exitoso"
        },
        requireAuth: false,
      },
      name: 'Liderazgo femenino empresarial: la clave del éxito',
      component: art_0023
    },
    {
      path: '/blog/recursos/entrevistas-y-conferencias/emprende-aventura-de-tu-vida-liderazgo-visionario-albert-bosch',
      meta: {
        title: "El liderazgo visionario de Albert Bosch en tu vida universitaria",
        description: "Descubre cómo emprender tu vida universitaria con el liderazgo visionario de Albert Bosch y vive tu vida como una aventura.",
        image: "https://blog.humanitas.edu.mx/images/meta/Emprende-aventura-liderazgo-visionario-albert-bosch.jpg",
        keywords: "lider visionario, emprender, tipos de liderazgo, business plan, liderazgo estratégico, aventura, liderazgo visionario",
        metaTags:
        {
          "og:title": "El liderazgo visionario de Albert Bosch en tu vida universitaria",
          "og:description": "Descubre cómo emprender tu vida universitaria con el liderazgo visionario de Albert Bosch y vive tu vida como una aventura.",
          "og:image": "https://blog.humanitas.edu.mx/images/meta/Emprende-aventura-liderazgo-visionario-albert-bosch.jpg",
          "og:keywords": "lider visionario, emprender, tipos de liderazgo, business plan, liderazgo estratégico, aventura, liderazgo visionario"
        },
        requireAuth: false,
      },
      name: 'El liderazgo visionario de Albert Bosch en tu vida universitaria',
      component: art_001
      
    },
]