<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">Licenciatura en Derecho virtual: <br class="md:block hidden">todo lo que necesitas saber</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-300">
          <img src="/images/licenciatura-derecho-virtual-corporativo.webp" alt="La Licenciatura en Derecho virtual fomenta la capacidad de analizar la normatividad jurídica." class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/academico" class=" text-hone">Académico</router-link> > <router-link to="/blog/academico/estudia-en-linea" class=" text-hone">Estudia en L&iacute;nea</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex list-disc pl-5 italic text-left text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">11 de junio de 2024</li>  <li>Iván Solís Rivera</li></ul>
            </div>
            <p class=" md:mb-4 mb-6">El término <b>"licenciatura"</b> proviene de la palabra latín medieval "licentiatura", derivado de "licentiare", <b>que significa "licenciar" o "autorizar".</b></p>
            <p class=" md:mb-4 mb-6">En la época actual, las instituciones de educación superior y universidades tienen <b>la facultad para autorizar a los estudiantes,</b> para que desempeñen ciertas tareas en el campo laboral, habilitándolos para ejercer profesionalmente.</p>
            <p class=" md:mb-8 mb-10">Respecto a <b>la <router-link to="/blog/academico/licenciatura/aptitudes-habilidades-para-estudiar-derecho" class=" text-htwo hover:text-hone">Licenciatura en Derecho</router-link></b> en específico, <b>tiene como objetivo formar especialistas en el conocimiento, análisis y aplicación de las normativas legales que funcionan como aspectos racionales para regular las relaciones sociales</b> y económicas entre el Estado y los ciudadanos, promoviendo valores como el orden, la seguridad, la justicia y la libertad.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">¿Quiénes pueden estudiar la carrera de abogacía?</h2>
            <p class=" md:mb-8 mb-10"><b>La carrera de abogacía está dirigida a los interesados en comprender la estructura jurídica del Estado, las interacciones</b> y dinámicas entre sus instituciones y la población, así como los recursos legales disponibles para <b>aquellos que buscan hacer valer sus derechos y cumplir con sus obligaciones dentro del marco jurídico,</b> brindando certeza en el ámbito local, estatal y federal.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">¿Qué ramas de conocimiento tiene el Derecho y qué se estudia?</h2>
            <p class=" md:mb-8 mb-10"><b>La <router-link to="/blog/academico/licenciatura/beneficios-carreras-universitarias-cortas" class=" text-htwo hover:text-hone">licenciatura</router-link> de Derecho virtual debe de contar con un programa diseñado para proporcionar una formación integral en el campo jurídico,</b> los principales componentes y áreas de estudio que los estudiantes abordarán durante la carrera, como son los siguientes:</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Fundamentos del Derecho</h3>
            <p class=" md:mb-8 mb-10">Introducción al Derecho: Conceptos básicos, historia y evolución del Derecho.<br>Teoría del Derecho: Análisis de las teorías jurídicas y su aplicación.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Derecho Constitucional</h3>
            <p class=" md:mb-8 mb-10">Derecho Constitucional: Estudio de la Constitución, derechos fundamentales, y estructura del estado.<br>Derecho Administrativo: Funcionamiento y regulación de la administración pública.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Derecho Civil</h3>
            <p class=" md:mb-8 mb-10">Obligaciones y contratos: Principios y normas que regulan las relaciones contractuales.<br>Derecho de familia y sucesiones: Normativas sobre relaciones familiares y herencias.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Derecho Penal</h3>
            <p class=" md:mb-8 mb-10">Derecho Penal general y especial: Análisis del delito, penas y procedimientos penales.<br>Criminología: Estudio de las causas y consecuencias del comportamiento delictivo.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Derecho Mercantil</h3>
            <p class=" md:mb-8 mb-10">Derecho Corporativo: Legislaciones y normas que regulan a las empresas y sociedades mercantiles tanto locales como internacionales.<br>Propiedad Intelectual: Protección legal de las creaciones del intelecto.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Derecho Laboral</h3>
            <p class=" md:mb-8 mb-10">Derecho del Trabajo y Seguridad Social: Normas que regulan las relaciones laborales y sistemas de seguridad social.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Derecho Internacional</h3>
            <p class=" md:mb-8 mb-10">Derecho Internacional Público y Privado: Marcos legales que rigen las relaciones entre Estados o naciones y particulares en el ámbito internacional.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Asignaturas complementarias y electivas</h3>
            <p class=" md:mb-8 mb-10">Derecho Ambiental, Derechos Humanos, Derecho Informático, entre otras, que permiten a los estudiantes especializarse en áreas de interés específico.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Metodología de estudio virtual</h3>
            <p class=" md:mb-8 mb-10">Plataformas virtuales: Acceso a clases, materiales de estudio, y recursos digitales.<br>Clases sincrónicas y asincrónicas: Participación en clases en tiempo real o visualización de grabaciones.</p>
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
              <Contacto class="m-3"/>
            </div>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">¿Quiénes pueden estudiar la carrera de abogacía?</h2>
            <p class=" md:mb-4 mb-6"><b>La Licenciatura en Derecho virtual se basa en un programa de estudios universitarios diseñado para formar profesionales</b> en el ámbito jurídico a través de una plataforma digital.<br> <b>Los estudiantes aprenden las mismas materias que en una licenciatura presencial, pero con la flexibilidad que ofrece el aprendizaje a distancia.</b> Este programa suele estar estructurado en trimestres, cuatrimestres y semestres que pueden durar entre cuatro y cinco años.</p>
            <p class=" md:mb-8 mb-10"><b>Dentro de este modelo de aprendizaje se incluyen clases virtuales que pueden ser en tiempo real (sincrónicas) o grabadas (asincrónicas),</b> lo que permite a los estudiantes verlas en el horario que más les convenga. <b>También involucra evaluaciones y tareas que se envían virtual.</b> De la misma forma los estudiantes tienen acceso a bibliotecas digitales, bases de datos jurídicas y otros recursos en línea.</p>
            <img src="/images/carrera-derecho-virtual-abogacia-estudiante.webp" alt="La carrera de Derecho virtual puede durar entre tres y cuatro años." class="md:mb-8 mb-10">
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone"><h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Metodología de estudio virtual</h3></h3>
            <p class=" md:mb-4 mb-6">Entre los beneficios y ventajas de estudiar la Licenciatura de abogacía virtual se encuentran:</p>
            <p class=" md:mb-4 mb-6">Flexibilidad<br><b>Permite a los estudiantes organizar su tiempo</b> y combinar los estudios con otras responsabilidades o actividades.</p>
            <p class=" md:mb-4 mb-6">Accesibilidad<br><b>Elimina barreras geográficas,</b> permitiendo que personas de diversas localidades accedan a la educación superior sin necesidad de desplazarse.</p>
            <p class=" md:mb-4 mb-6">Ahorro de costos<br><b>Reduce gastos asociados al transporte, alojamiento y materiales impresos,</b> ya que muchos recursos están disponibles digitalmente.</p>
            <p class=" md:mb-4 mb-6">Autoaprendizaje<br><b>Fomenta habilidades de autogestión, disciplina y responsabilidad,</b> cualidades valiosas en el ámbito profesional.</p>
            <p class=" md:mb-8 mb-10">Tecnología y modernización<br><b>Familiariza a los estudiantes con herramientas digitales y tecnológicas,</b> esenciales en el mundo moderno.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">¿Qué tan bueno es estudiar Derecho en modo virtual?</h2>
            <p class=" md:mb-8 mb-10">La Licenciatura en Derecho virtual es una opción educativa moderna que combina la formación jurídica tradicional con la flexibilidad de la educación digital. Si bien presenta desafíos, también ofrece ventajas que pueden adaptarse a las necesidades de una variedad de estudiantes, por lo tanto, <b>es una buena forma para adquirir conocimientos de Derecho.</b></p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone"><router-link to="/blog/academico/licenciatura/beneficios-carreras-universitarias-cortas" class=" text-htwo hover:text-hone">¿Dónde conviene estudiar Derecho?</router-link></h3>
            <p class=" md:mb-4 mb-6">A los aspirantes para ser abogados <b>les conviene estudiar en Universidad Humanitas, ya que forman expertos en el conocimiento, análisis y aplicación de las normas jurídicas</b> que constituyen un instrumento racional de ordenación de las relaciones tanto sociales como económicas entre el Estado, los ciudadanos, así como las personas entre sí, a fin de fomentar en la sociedad valores tales como el orden, la seguridad, la justicia y la libertad.</p>
            <p class=" md:mb-4 mb-6"><b>Los egresados de la Licenciatura de Derecho de la Universidad Humanitas desarrollarán las siguientes habilidades:<br>Analizar los efectos de los contratos sociales y mercantiles, reconocer los incumplimientos de contratos civiles, analizar las resoluciones,</b> y suspensión en el amparo, interpretar contratos civiles y mercantiles, indagar y examinar ante la elaboración de demandas penales, civiles y administrativas.</p>
            <p class=" md:mb-4 mb-6">De la misma manera, <b>los estudiantes tendrán la capacidad de investigación en el campo jurídico, colaboración en la redacción de amparos y demandas,</b> interés en el desarrollo de la legislación jurídica del país, redacción de demandas y sentencias, responsabilidad ética en la representación legal de sus clientes, entre otras.</p>
            <p class=" md:mb-8 mb-10">Entre las destrezas de los estudiantes de la licenciatura en Derecho en Humanitas, destacan:<br><b>Asesorar y diseñar la expedición de normas jurídicas, aplicar las herramientas para interposición de demandas,</b> diseñar y dirigir estrategias legales corporativas o individuales e interpretar contratos civiles y mercantiles, entre otras.</p>
            <img src="/images/estudios-derecho-virtual.webp" alt="Los estudios de Derecho abarcan una amplia rama de conocimientos." class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Modelo virtual de Universidad Humanitas</h2>
            <p class=" md:mb-8 mb-10"><b>En Humanitas hemos desarrollado un innovador modelo virtual que permite cursar licenciaturas y maestrías de manera más eficiente,</b> facilitando el logro de las metas desde cualquier lugar.</p>
            <VideoClip :link="'https://www.youtube.com/embed/o_8wbcH-xs8?si=SBkQcAGr8_xdoCkD'" />
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">¿Qué ofrece el plan virtual de Humanitas?</h3>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[19px] mb-3">Sesiones en alta definición</h4>
            <p class=" md:mb-8 mb-10"><b>Las clases están grabadas en alta definición,</b> optimizadas para un aprovechamiento total del tiempo y contenido.</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[19px] mb-3">Acceso en cualquier momento y lugar</h4>
            <p class=" md:mb-8 mb-10"><b>Las sesiones están disponibles en diversos formatos,</b> lo que permite aprender mientras se puede realizar diversas tareas diarias.</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[19px] mb-3">Expertos al alcance</h4>
            <p class=" md:mb-8 mb-10"><b>Los programas son impartidos por especialistas de renombre en sus áreas,</b> garantizando una educación de primer nivel con acompañamiento continuo a lo largo del curso.</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[19px] mb-3">Foros de discusión semanales</h4>
            <p class=" md:mb-8 mb-10"><b>Se habilitan foros, enriqueciendo el aprendizaje</b> a través del intercambio de ideas y experiencias.</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[19px] mb-3">Tareas y proyectos de investigación</h4>
            <p class=" md:mb-8 mb-10"><b>El conocimiento y habilidades se refuerzan con tareas prácticas</b> y proyectos de investigación que complementan las clases teóricas.</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[19px] mb-3">Examen final</h4>
            <p class=" md:mb-8 mb-10"><b>Se culmina cada bloque con un examen final</b> que evalúa y certifica el aprendizaje.</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[19px] mb-3">Biblioteca virtual</h4>
            <p class=" text-left mb-20"><b>La <a href="https://humanitas.edu.mx/licenciatura-en-derecho" target="_blank" class="text-htwo hover:text-hone">Universidad Humanitas</a> cuenta con una vasta colección de recursos y materiales de estudio virtual,</b> disponibles las 24 horas, los 7 días de la semana.</p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="true" :linkAnt="'/academico/licenciatura/que-es-un-acto-administrativo'" :linkSig="'/aprende/cultura-general/cuantos-pisos-tiene-la-torre-de-pemex'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import Contacto from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue'
import SigArt from '../../pagesComponents/SigArt.vue';
import VideoClip from '../../pagesComponents/videoClip.vue'


export default {
    components: { Comentarios, ConComentarios, Contacto, LikesVue, ShareP, SigArt, VideoClip},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '0042-ART-ESTUDIA-EN-LINEA-DERECHO-110624',
            ruta: '/academico/estudia-en-linea/licenciatura-en-derecho-virtual',
            title: 'Licenciatura en Derecho virtual: cómo tener éxito',
            image: 'https://blog.humanitas.edu.mx/images/Estudia-tu-carrera-en-linea.jpg',
            text: 'La Licenciatura en Derecho virtual ofrece una formación integral sobre la normatividad jurídica e institucional de la abogacía y sus ramas de conocimiento.'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx'+this.ruta;

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style></style>