import RecursosCasosR from "../pages/Blog/Recursos/Recursos-CasosR.vue";
import RecursosEbooks from "../pages/Blog/Recursos/Recursos-Ebooks.vue";
import RecursosEntrevistasC from "../pages/Blog/Recursos/Recursos-EntrevistasC.vue";
import RecursosPotcast from "../pages/Blog/Recursos/Recursos-Potcast.vue";
import RecursosWebinars from "../pages/Blog/Recursos/Recursos-Webinars.vue";
import webinarsRoutes from "./Recursos/webinarsRoutes.ts"
import podcastRoutes from "./Recursos/podcastRoutes.ts";
import entrevistasRoutes from "./Recursos/entrevistasRoutes.ts";
import ebooksRoutes from "./Recursos/ebooksRoutes.ts";
import casosRRoutes from "./Recursos/casosRRoutes.ts"

export default [
    {
        path: '/blog/recursos/casos-reales',
        name: 'Recursos: Casos Reales',
        component: RecursosCasosR,
        meta: {
            requireAuth: false
        },
    },
    ...casosRRoutes,
    {
        path: '/blog/recursos/ebooks',
        name: 'Recursos: Ebooks',
        component: RecursosEbooks,
        meta: {
            requireAuth: false
        },
    },
    ...ebooksRoutes,
    {
        path: '/blog/recursos/entrevistas-y-conferencias',
        name: 'Recursos: Entrevistas y Conferencias',
        component: RecursosEntrevistasC,
        meta: {
            requireAuth: false
        },
    },
    ...entrevistasRoutes,
    {
        path: '/blog/recursos/podcast',
        name: 'Recursos: Podcast',
        component: RecursosPotcast,
        meta: {
            requireAuth: false
        },
    },
    ...podcastRoutes,
    {
        path: '/blog/recursos/webinars',
        name: 'Recursos: Webinars',
        component: RecursosWebinars,
        meta: {
            requireAuth: false
        },
    },
    ...webinarsRoutes,
]