<script lang="ts">
import PersonaHumanitas from './components/PersonaHumanitas.vue';
import TopBannerRight from './components/oferta/TopBannerRight.vue';

export default {
    components: {
        PersonaHumanitas,
        TopBannerRight
    },
    data() {
        return {
            scriptLoaded: false,
        };
    },
    mounted() {
        this.initIntersectionObserver();
    },
    methods: {
        initIntersectionObserver() {
            const target = document.getElementById('bolsaT');
            if (target) {
                const observer = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && !this.scriptLoaded) {
                    this.loadScript();
                    observer.disconnect(); // Desconectar después de cargar el script
                }
                });
                observer.observe(target);
            }
        },
        loadScript() {
            const script = document.createElement('script');
            script.id = 'bolsa-widget';
            script.type = 'text/javascript';
            script.charset = 'UTF-8';
            script.src = 'https://jobdiscovery-widget-occ.occ.com.mx/vertical-bundle.js';
            script.setAttribute('key', '2Nl8jyI2ufhVyI9aTYwjoEJFjBC');
            const target = document.getElementById('bolsaT');
            if (target) {
                target.appendChild(script);
                this.scriptLoaded = true;
            }
        },
    },
};
</script>

<template>
    <TopBannerRight
        :img="'background-image: linear-gradient(transparent, rgba(255, 255, 255, 0.95)), url(/pagesImages/docs-bolsa.jpg);'"
        :nivel="'EL &Eacute;XITO PROFESIONAL'"
        :carrera="'EST&Aacute; A UN CLICK'"
        :complemento="'DE DISTANCIA'"
    />

    <section class="flex items-center justify-center w-full mb-16">
        <div class="flex flex-col lg:flex-row items-center justify-end w-full max-w-4xl lg:max-w-[1200px]">
        <div id="bolsaT" class="order-2 z-30 lg:order-1 flex flex-col items-center justify-center lg:z-10" v-once>
            <div id="occ-widget">
            <img src="https://jobdiscovery-widget-occ.occ.com.mx/loadspinner.gif" alt="Cargando">
            Cargando OCC Widget
            </div>
        </div>
        <div class="order-1 lg:order-2 flex flex-col w-full px-4 lg:w-[600px] lg:h-[640px] justify-center text-justify lg:pl-8 my-8 lg:my-0 text-lg">
            <p>En Universidad Humanitas nos esforzamos cada d&iacute;a por brindarte un mejor servicio. La bolsa de Trabajo Humanitas es prueba de este compromiso, con &eacute;l, buscamos retribuir la confianza que nos brindas al permitirnos formar parte de esta etapa de tu vida. Nuestro objetivo es impulsar el desarrollo profesional a trav&eacute;s de la oferta de vacantes exclusivas con empresas vinculadas con la Universidad. Reg&iacute;strate y sube tu curr&iacute;culum para que tu perfil laboral se encuentre activo y a la vista de los reclutadores.</p>
        </div>
        </div>
    </section>

    <PersonaHumanitas />
</template>

<style>
/* Agrega cualquier estilo adicional aquí */
</style>