<template>
  <div class="flex flex-col justify-center items-center  w-full mb-8 lg:mt-[83px] md:mt-32 mt-24 font-sans text-black">
    <div class="flex flex-col justify-start max-w-4xl bg-gray-100 lg:p-12 md:p-6 p-2 pt-6 lg:max-w-[1200px] w-full">
        <div class="lg:flex justify-between mb-4 items-center">
          <div class="flex flex-col justify-start text-left ">
            <h2 ref="titulo" class="text-left pl-2 uppercase  text-hone font-bold text-[24px] md:text-[32px] lg:text-[36px]" style="font-family: Georgia, serif;">{{ titulo }}</h2>
            <hr ref="linea"  class="my-2 border-[#797979] hidden lg:block">
            <hr v-if="variableEnMayusculas === 'SER HUMANITAS'" class="my-2 border-[#797979] lg:hidden md:w-[42%] w-[50%]">
            <hr v-if="variableEnMayusculas != 'SER HUMANITAS'" class="my-2 border-[#797979] lg:hidden md:w-[35%] w-[40%]">
            <p class="uppercase pl-2 text-[14px] md:text-[20px] text-black mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;">{{ subtitulo }}</p>
          </div>
          <div class="relative w-fit" style="font-family: 'Helvetica Neue Regular', sans-serif;" @mouseover="openFilter" @mouseleave="startCloseTimer">
            <!-- Botón para abrir/cerrar el filtro -->
            <div class="flex items-center w-fit">
              <p class="text-[18px] mr-2"></p>
              <button  class="filter-btn whitespace-nowrap focus:outline-none bg-white flex items-center border border-htwo rounded-none text-[20px]">Filtrar por &aacute;rea
                <svg :class="{ 'rotate-180': showFilter }" class="-mr-1 ml-2 h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M10 12a1 1 0 01-.707-.293l-4-4a1 1 0 111.414-1.414L10 9.586l3.293-3.293a1 1 0 111.414 1.414l-4 4A1 1 0 0110 12z" clip-rule="evenodd" />
                </svg>
              </button>
            </div>
            <!-- Lista de opciones del filtro -->
            <div v-if="showFilter" class="filter-options z-40 flex flex-col justify-center text-[16px] absolute left-[5%] rounded-none w-64 italic shadow-lg bg-white ring-1 ring-black border border-htwo ring-opacity-5 focus:outline-none">
              <div class="flex m-4 flex-col justify-start ">
                <div class="flex flex-col mb-2 cursor-pointer"  >
                  <label class="filter-label items-center flex justify-between text-left ml-6 pr-4 cursor-pointer select-none text-[18px]" @click="// @ts-ignore
toggleCheckboxVisibility('humanidades', !this.isHovered.humanidades)">Humanidades <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fill-rule="evenodd" d="M10 12a1 1 0 01-.707-.293l-4-4a1 1 0 111.414-1.414L10 9.586l3.293-3.293a1 1 0 111.414 1.414l-4 4A1 1 0 0110 12z" clip-rule="evenodd" /></svg></label>
                  <div v-show="isHovered.humanidades" class="mb-0 ml-2 text-[#797979] italic">
                    <div v-for="(option, index) in filterOptions.humanidades" :key="index" @click="// @ts-ignore
toggleCheckboxVisibility('humanidades', this.isHovered.humanidades)">
                      <label class="flex mb-1 items-center select-none">
                        <input type="checkbox" :value="option" @change="aplicarFiltros" v-model="selectedOptions.humanidades" class="cursor-pointer text-[#797979] italic accent-gray-300 rounded-none w-5 h-5 bg-white border border-gray-300 focus:ring-[#520400] focus:ring-1 mr-2"> {{ option }}
                      </label>
                    </div>
                  </div>
                  <hr class="w-[80%] mt-1">
                </div>
                <div class="flex flex-col mb-2 cursor-pointer" >
                  <label class="filter-label items-center justify-between flex text-left cursor-pointer select-none ml-6 pr-4 text-[18px]" @click="// @ts-ignore
toggleCheckboxVisibility('ciencias_sociales', !this.isHovered.ciencias_sociales)">Ciencias Sociales <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fill-rule="evenodd" d="M10 12a1 1 0 01-.707-.293l-4-4a1 1 0 111.414-1.414L10 9.586l3.293-3.293a1 1 0 111.414 1.414l-4 4A1 1 0 0110 12z" clip-rule="evenodd" /></svg></label>
                  <div v-show="isHovered.ciencias_sociales" class="mb-1 ml-2 text-[#797979] italic">
                    <div v-for="(option, index) in filterOptions.ciencias_sociales" :key="index">
                      <label class="flex mb-2 items-center select-none">
                        <input type="checkbox" :value="option" @change="aplicarFiltros" v-model="selectedOptions.ciencias_sociales" class="cursor-pointer text-[#797979] italic accent-gray-300 rounded-none w-5 h-5 bg-white border border-gray-300 focus:ring-[#520400] focus:ring-2 mr-2"> {{ option }}
                      </label>
                    </div>
                    <label class="flex mb-0 text-left select-none">
                      <input type="checkbox" :value="option" @change="aplicarFiltros" v-model="selectedOptions.ciencias_sociales" class="cursor-pointer text-[#797979] italic accent-gray-300 rounded-none w-5 h-5 bg-white border border-gray-300 focus:ring-[#520400] focus:ring-2 mr-2"> Ciencias Políticas <br> y Administración Pública
                    </label>
                  </div>
                  <hr class="w-[80%] mt-1">
                </div>
                <div class="flex flex-col mb-0 cursor-pointer"  >
                  <label class="filter-label items-center flex justify-between text-left cursor-pointer select-none ml-6 pr-4 text-[18px]" @click="// @ts-ignore
toggleCheckboxVisibility('economico_administrativas', !this.isHovered.economico_administrativas)">Económico-<br>Administrativas <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fill-rule="evenodd" d="M10 12a1 1 0 01-.707-.293l-4-4a1 1 0 111.414-1.414L10 9.586l3.293-3.293a1 1 0 111.414 1.414l-4 4A1 1 0 0110 12z" clip-rule="evenodd" /></svg></label>
                  <div v-show="isHovered.economico_administrativas" class="mb-1 ml-2 text-[#797979] italic">
                    <div v-for="(option, index) in filterOptions.economico_administrativas" :key="index">
                      <label class="flex mb-0 items-center select-none">
                        <input type="checkbox" @change="aplicarFiltros" :value="option" v-model="selectedOptions.economico_administrativas" class="cursor-pointer text-[#797979] italic accent-gray-300 rounded-none w-5 h-5 bg-white border border-gray-300 focus:ring-[#520400] focus:ring-1 mr-2"> {{ option }}
                      </label>
                    </div>
                  </div>
                  <hr class="w-[80%] mt-1 mb-2">
                  <label class="flex mb-0 items-center ml-2 select-none text-[#797979] italic">
                    <input type="checkbox" @change="selectAll" v-model="selectAllChecked" class="cursor-pointer text-[#797979] italic accent-gray-300 rounded-none w-5 h-5 bg-htwo border border-gray-300 focus:ring-[#520400] focus:ring-1 mr-2"> Seleccionar todos
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container md:p-0 p-4 w-full">
            <!-- Aquí va el contenido de cada artículo -->
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 justify-start mb-12 max-w-6xl lg:max-w-[1600px] w-full">
                <div v-for="(article, index) in visibleArticles" :key="index" class="grid gap-4 bg-white rounded p-4  transition duration-300 ease-in-out transform hover:shadow-md text-justify w-full">
                    <!-- Aplica estilos para que las imágenes tengan una altura fija y se ajusten al ancho del contenedor -->
                    <router-link :to="'/blog'+article.enlace">
                      <img :src="article.portada" :alt="article.titulo" class="w-full mb-2 h-[300px] object-cover">
                    </router-link>
                    <div class="flex flex-col justify-start items-start w-full">
                        <h2 class="font-serif text-left text-hone lg:text-[22px] md:text-[20px] lg:h-[80px] md:h-[75px] leading-tight mb-6 text-[18px]">{{ article.titulo }}</h2>
                        <!-- Limita el número de líneas para el texto y agrega puntos suspensivos al final si es demasiado largo -->
                        <p class=" text-left text-[16px] lg:h-[120px] md:h-[140px] mb-6 overflow-hidden" >{{ article.descripcion }}</p>
                        <div class="flex justify-start items-start">
                            <router-link :to="'/blog'+article.enlace">
                                <button class="text-white hover:text-black transition duration-300 focus:border-htwo focus:outline-none font-serif md:text-[16px] flex items-center justify-center rounded-none bg-htwo hover:bg-white border-htwo md:w-[140px] h-[45px]">Leer más &#8594;</button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Aquí iría la paginación -->
            <div class="flex justify-end items-end mb-4 right-0 relative">   
              <span class="italic text-[18px] text-[#999999]">
                Página <button @click="previousPage" class="focus:outline-none rounded-full w-3 bg-gray-100  ">&#8249;</button>
                <span class="inline-block py-1">{{ currentPage }} de {{ totalPages }}</span>
                <button @click="nextPage" class="focus:outline-none w-3 rounded-full bg-gray-100  ">&#8250;</button>
              </span>
            </div>
        </div>
    </div>
  </div>
</template>

<script lang="js">
export default {
    props: {
    titulo: String,
    subtitulo: String,
    articles: {
      type: Array,
      required: true
    },
    filtros: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tituloWidth: 0,
      currentPage: 1, // Página actual
      itemsPerPage: 9, // Número de artículos por página
      showFilter: false,
      filterOptions: {
        humanidades: ['Educación', 'Psicología', 'Diseño Gráfico', 'Arquitectura', 'Arte y Teatro'],
        ciencias_sociales: ['Derecho'],
        economico_administrativas: ['Administración', 'Contabilidad']
      },
      isHovered: {
        humanidades: false,
        ciencias_sociales: false,
        economico_administrativas: false
      },
      selectedOptions: {
        humanidades: [],
        ciencias_sociales: [],
        economico_administrativas: []
      },
      filteredArticles: [],
      selectAllChecked: false
    };
  },
  methods: {
    updateWidth() {
      // @ts-ignore
      const tituloWidth = this.$refs.titulo.offsetWidth;
      // @ts-ignore
      this.$refs.linea.style.width = `${tituloWidth}px`;
    },
    onResize() {
      this.updateWidth();
    },
    openFilter() {
      // @ts-ignore
        clearTimeout(this.closeTimer); // Limpiar el temporizador si está activo
        // @ts-ignore
        this.showFilter = true;
    },
    closeFilter()  {
      // @ts-ignore
        this.showFilter = false;
    },
    selectAll() {
      // Si el checkbox de "Seleccionar todo" está marcado, selecciona todas las opciones
      // @ts-ignore
      if (this.selectAllChecked) {
        // @ts-ignore
        this.selectedOptions.humanidades = [...this.filterOptions.humanidades];
        // @ts-ignore
        this.selectedOptions.ciencias_sociales = [...this.filterOptions.ciencias_sociales];
        // @ts-ignore
        this.selectedOptions.economico_administrativas = [...this.filterOptions.economico_administrativas];
      } else {
        // Si el checkbox de "Seleccionar todo" no está marcado, deselecciona todas las opciones
        // @ts-ignore
        for (let key in this.selectedOptions) {
          // @ts-ignore
          this.selectedOptions[key] = []; // Vaciar todas las selecciones
        }
      }
      this.aplicarFiltros();
    },
    // @ts-ignore
    toggleCheckboxVisibility(section, value) {
      // @ts-ignore
      this.isHovered[section] = value;
    },
    startCloseTimer() {
      // @ts-ignore
        this.closeTimer = setTimeout(() => {
            this.closeFilter();
        }, 200); // Espera 200ms antes de cerrar el filtro
    },
    cancelCloseTimer() {
      // @ts-ignore
        clearTimeout(this.closeTimer); // Limpiar el temporizador si el usuario vuelve al filtro antes de que se cierre
    },
    clearFilters() {
      // @ts-ignore
      this.selectedOptions = {
        humanidades: [],
        ciencias_sociales: [],
        economico_administrativas: []
      };
      this.aplicarFiltros(); // Llamar a la función para aplicar filtros después de limpiarlos
    },
    
    aplicarFiltros() {
        // @ts-ignore
        const filtrosSeleccionadosList = Object.values(this.selectedOptions).flat();
        // console.log('Valores del filtro cambiados:', filtrosSeleccionadosList);

        // Filtrar los artículos según las opciones seleccionadas
        // @ts-ignore
        const filteredArticleIds = [];
        // @ts-ignore
        this.articles.forEach(article => {
          // @ts-ignore
            const articleFilters = this.filtros[article.id];
            // @ts-ignore
            if (Object.values(articleFilters).some(categoryFilters => categoryFilters.some(filter => filtrosSeleccionadosList.includes(filter)))) {
            filteredArticleIds.push(article.id);
            }
        });
        // console.log('Artículos filtrados:', filteredArticleIds);
        this.filteredArticles = [];
        // Formar un array con los datos correspondientes de los artículos filtrados
        // @ts-ignore
        setTimeout(() => {this.filteredArticles = this.articles.filter(article => filteredArticleIds.includes(article.id));}, 250); 
        // console.log('Datos de los artículos filtrados:', this.filteredArticles);
        // @ts-ignore
        this.currentPage = 1; // Resetear la página actual a 1
    },
    previousPage() {
      // @ts-ignore
      if (this.currentPage > 1) {
        // @ts-ignore
        this.currentPage--;
        this.scrollToTop(); // Llamamos a la función de scroll
      }
    },
    nextPage() {
      // @ts-ignore
      if (this.currentPage < this.totalPages) {
        // @ts-ignore
        this.currentPage++;
        this.scrollToTop(); // Llamamos a la función de scroll
      }
    },
    // Método para calcular el índice del primer artículo en la página actual
    calculateStartIndex() {
      // @ts-ignore
      return (this.currentPage - 1) * this.itemsPerPage;
    },
    // Método para calcular el índice del último artículo en la página actual
    calculateEndIndex() {
      // @ts-ignore
      if (this.articles && this.articles.length > 0) {
        // @ts-ignore
        return Math.min(this.currentPage * this.itemsPerPage, this.articles.length);
      } else {
        console.log('arrayArt is empty or undefined');
        return 0; // Si no hay artículos, devolver 0 como índice final
      }
    },
    // Función para hacer scroll hacia arriba
    scrollToTop() {
      window.scrollTo({
        top: 100,
        behavior: 'smooth' // Para un desplazamiento suave
      });
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWidth);
  },
  computed: {
    // Cálculo del número total de páginas
    // @ts-ignore
    totalPages() {
      // @ts-ignore
        return Math.ceil(this.filteredArticles.length / this.itemsPerPage);
    },
    /**
   * @returns {Array<{ enlace: string, portada: string, titulo: string, descripcion: string }>} Array de artículos visibles
   */
    visibleArticles() {
      const startIndex = this.calculateStartIndex();
      const endIndex = this.calculateEndIndex();
      // @ts-ignore
      return this.filteredArticles.slice(startIndex, endIndex);
    },
    computedWidth() {
      return this.width;
    },
    variableEnMayusculas() {
      return this.titulo.toUpperCase(); // Convertir la variable a mayúsculas
    }
  },
  mounted() {
    // Seleccionar todas las opciones al iniciar el componente
    //this.selectedOptions.humanidades = [...this.filterOptions.humanidades];
    //this.selectedOptions.ciencias_sociales = [...this.filterOptions.ciencias_sociales];
    //this.selectedOptions.economico_administrativas = [...this.filterOptions.economico_administrativas];
    this.updateWidth();
    window.addEventListener('resize', this.updateWidth);

    this.filteredArticles=this.articles; 
  },
};
</script>

<style scoped>
/* Estilos CSS para los elementos del filtro */
.filter-label {
  margin-bottom: 0.5rem;
}

.filter-category {
  margin-bottom: 1rem;
}
</style>