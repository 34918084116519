<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">Maestría en Psicología: <br class="md:block hidden"> todo lo que necesitas saber</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-200">
          <img src="/images/A-maestria-psicologia-tipos-mente-humana.jpg" alt="Una maestría en Psicología tiene un amplio campo de especialización" class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/academico" class=" text-hone">Académico</router-link> > <router-link to="/blog/academico/maestria" class=" text-hone">Maestría</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex text-left list-disc pl-5 italic text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">24 de abril de 2024</li>  <li>Iván Solís Rivera</li></ul>
            </div>
            <p class=" md:mb-4 mb-6">Compartimos todo lo que necesitas saber de <b>la <router-link to="/academico/maestria/costo-y-requisitos-estudiar-maestria" class="text-htwo">maestría</router-link> en Psicología,</b> que <b>representa</b> para los profesionales la <b>fortaleza de sus conocimientos y habilidades,</b> además de completar su formación académica.</p>
            <p class=" md:mb-4 mb-6">El programa académico de una maestría en Psicología <b>implica el estudio de teorías avanzadas, metodologías innovadoras y su aplicación en contextos prácticos,</b> inherente al análisis crítico y la capacidad de investigación.</p>
            <p class=" md:mb-4 mb-6">También abarca la especialización de <b>los profesionales dentro de un área específica de interés, como Psicología Forense, Aplicada, Educativa, Organizacional,</b> entre otras ramas de conocimiento.</p>
            <p class=" md:mb-8 mb-10">Los estudiantes de una maestría en Psicología tienden a <b>mejorar gradualmente sus habilidades prácticas que permiten aplicar los conocimientos</b> de manera efectiva en situaciones reales.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">¿Cuál es la relevancia actual de la Psicología para la sociedad actual?</h2>
            <p class="md:mb-4 mb-6">La <router-link to="/blog/aprende/tendencias/tendencias-en-psicologia-2024-tecnologia-salud-mental" class="text-htwo">Psicología</router-link> <b>es una rama</b> del conocimiento humano importante, cuyo objeto de estudio es <b>comprender los procesos mentales y emocionales que caracterizan al ser humano.</b></p>
            <p class="md:mb-4 mb-6"><b>Este ámbito de conocimiento es relevante en la sociedad actual en diversos ámbitos,</b> ya que estudia desde la salud mental hasta el desarrollo organizacional.</p>
            <p class="md:mb-8 mb-10">Los profesionales en Psicología desempeñan un papel fundamental debido a que <b>abonan al bienestar social; ayudan a individuos y a sociedades a superar dificultades provenientes de las acciones, pensamientos y sentimientos.</b> De la misma forma, ayudan a mejorar las relaciones interpersonales y alcanzar el máximo potencial cognitivo.</p>
            <img src="/images/A-psicologia-aplicada-clasificaciones-profesional.jpg" alt="La Psicología Aplicada resuelve problemas en diversos ámbitos de conocimiento" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Tipos de maestría en Psicología: ¿Cuál elegir?</h2>
            <p class="md:mb-4 mb-6">Para elegir una maestría en Psicología <b>es esencial que los profesionales en esta materia consideren sus intereses profesionales</b> y objetivos de carrera, así como establecer  sus metas profesionales.</p>
            <p class="md:mb-8 mb-10">A continuación, describimos algunos <b>tipos de maestría en Psicología:</b></p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Forense</h3>
            <p class="md:mb-4 mb-6">La Psicología Forense <b>aplica los principios y conocimientos psicológicos en procesos legales y en el ámbito judicial.</b> Se encarga de evaluar, analizar y clasificar el comportamiento humano en contextos legales y criminales.</p>
            <p class="md:mb-4 mb-6">En terrenos prácticos la Psicología Forense se presenta <b>en la evaluación, la cual se realiza para determinar la competencia mental,</b> la capacidad parental, la credibilidad del testimonio, entre otros.</p>
            <p class="md:mb-4 mb-6">De la misma forma se aplica dentro de la <b>intervención forense, que consiste en la prestación de tratamiento psicológico</b> a individuos involucrados en el sistema legal.</p>
            <div class="flex items-center w-full h-full">
              <iframe src="https://www.youtube.com/embed/L4HR8Ec_zyM?si=CwHMKxrTU3vN0cCy" class="w-full lg:h-[540px] md:h-[480px] h-[240px] md:mb-8 mb-10" title="YouTube video player"
                frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen>
              </iframe>
            </div>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Psicología aplicada: Una amplia rama de conocimientos</h2>
            <p class="md:mb-8 mb-10">Dentro de los tipos de Psicología, resalta la <b>Aplicativa, que utiliza los principios y métodos psicológicos para abordar problemas y mejorar la vida cotidiana,</b> por ende, aumentar la calidad de vida de las personas en diversos contextos. Algunos tipos de este ámbito del conocimiento, son los siguientes:</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Psicología Clínica</h3>
            <p class="md:mb-8 mb-10"><b>Se centra en la evaluación, diagnóstico y tratamiento de trastornos mentales o emocionales.</b> Los psicólogos clínicos trabajan en centros de salud, aplicando su conocimiento para resolver problemas de pacientes que sufren de depresión, ansiedad o trastornos de la alimentación.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Psicología Educativa</h3>
            <p class="md:mb-8 mb-10">Está enfocada al estudio del comportamiento humano en el contexto educativo. <b>Los psicólogos educativos trabajan para mejorar los programas de intervención para estudiantes con necesidades especiales</b> y ayudar a los maestros a crear entornos de aprendizaje efectivos.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Psicología Organizacional</h3>
            <p class="md:mb-8 mb-10"><b>Comprende el comportamiento humano en los centros de trabajo.</b> Los profesionales de esta área pueden trabajar en áreas como selección de personal, desarrollo de liderazgo, gestión del cambio organizacional y mejora del ambiente laboral, además, tienen la capacidad de realizar encuestas de ambiente laboral y con base en los resultados, mejorar la comunicación interna y externa.</p>
            <!-- Formulario -->
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
                <ContactoVue class="m-3"/>
            </div>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Maestría en Orientación Psicológica</h2>
            <p class="md:mb-4 mb-6">La Maestría en Orientación Psicológica <b>prepara a profesionales para ayudar a individuos, familias y grupos a enfrentar desafíos emocionales, sociales y educativos.</b> Este programa combina teoría psicológica con técnicas de intervención para facilitar el desarrollo personal y el bienestar emocional.</p>
            <p class="md:mb-4 mb-6"><b>Los orientadores psicológicos trabajan en escuelas, centros comunitarios, clínicas y empresas,</b> brindando apoyo emocional, asesoramiento académico y orientación vocacional.</p>
            <p class="md:mb-8 mb-10"><b>El desempeño de un orientador escolar se puede medir a partir del nivel de ayuda a superar la ansiedad antes de un examen,</b> o trabajar con padres para mejorar la comunicación y resolver conflictos en el hogar.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Maestría en Psicología Clínica de niños y adolescentes</h2>
            <p class="md:mb-4 mb-6">Una Maestría en Psicología Clínica de niños y adolescentes <b>implica la comprensión de los aspectos teóricos, prácticos y éticos de la evaluación, diagnóstico e intervención psicológica</b> con poblaciones infantiles y juveniles. Este tipo de maestría suele combinar una sólida formación teórica con experiencias prácticas de trabajo clínico supervisado.</p>
            <p class="md:mb-4 mb-6"><b>Dentro de su programa educativo, resalta el desarrollo infantil y adolescente, los trastornos psicológicos comunes en estas etapas de la vida,</b> técnicas de evaluación y diagnóstico específicas para niños y adolescentes, así como diversas estrategias de intervención terapéutica adaptadas a sus necesidades.</p>
            <p class="md:mb-8 mb-10">De la misma forma, prepara a <b>los estudiantes para abordar una amplia gama de desafíos relacionados con la salud mental de los jóvenes,</b> ofreciéndoles las habilidades y el conocimiento necesarios para trabajar de manera efectiva en diversos contextos profesionales.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Maestría en Psicología de la Alimentación</h2>
            <p class="md:mb-4 mb-6">Una Maestría en <b>Psicología de la Alimentación presenta un campo multidisciplinario, que combina conocimientos de psicología, nutrición, salud pública</b> y otros campos relacionados para abordar los aspectos psicológicos, emocionales y conductuales asociados con la alimentación, la nutrición y los hábitos alimenticios de las personas.</p>
            <p class="md:mb-8 mb-10">Algunas de las <b>áreas en las que pueden trabajar los estudiantes de este programa incluye: asesoramiento nutricional que fomentan los hábitos alimenticios saludables y a superar trastornos alimenticios como la bulimia, la anorexia o el trastorno por gula.</b> De la misma forma, pueden realizar investigaciones sobre la relación entre la psicología y la alimentación, como la influencia de los factores emocionales, así como brindar servicios de <b>consultoría en empresas diseñando programas de bienestar para empleados,</b> y ofreciendo asesoramiento sobre marketing de alimentos desde una perspectiva psicológica.</p>
            <img src="/images/A-maestria-orientacion-psicologica-asesoramiento.jpg" alt="Los profesionales de Orientación Psicológica pueden ofrecer asesoramiento" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">¿Cuáles son los requisitos para cursar una maestría en Psicología?</h2>
            <p class="mb-2">La Universidad Humanitas cuenta con una <b>Maestría en Psicoterapia con un enfoque Psicoanalítico</b> y para solicitar ingreso al modelo de aprendizaje ejecutivo se debe seguir un proceso que incluye una entrevista. Los requisitos para los alumnos con estudios en México son los siguientes</p>
            <ul class="list-disc pl-6 md:mb-4 mb-6">
                <li>Presentar el Título de Licenciatura.</li>
                <li>Mostrar la Cédula de Licenciatura.</li>
                <li>Exhibir la Credencial del Instituto Nacional Electoral (INE).</li>
                <li>Proporcionar la CURP actualizada.</li>
            </ul>
            <p class="md:mb-4 mb-6">En caso de que exista equivalencia de estudios, el aspirante debe presentar, además de los requisitos mencionados anteriormente, un certificado parcial de estudios emitido por la institución educativa de origen, con reconocimiento de validez oficial. La Universidad Humanitas también admite alumnos con estudios en el extranjero cumpliendo diversos requisitos.</p>
            <p class="md:mb-4 mb-6">La maestría en <a href="https://www.humanitas.edu.mx/maestria-en-psicoterapia" target="_blank" class="text-htwo">Psicoterapia con un enfoque Psicoanalítico</a> <b>desarrolla las competencias de los profesionales para ofrecer consultas de psicoterapia responsables y de alta calidad.</b> Además, capacita a los estudiantes para ejercer como psicoterapeutas, lo que implica la habilidad para realizar diagnósticos que les permitan formular la línea de tratamiento adecuada para cada paciente, utilizando el enfoque psicoanalítico como herramienta beneficiosa.</p>
            <p class="md:mb-8 mb-10">En conclusión, una Maestría en Psicología representa un paso importante en el desarrollo profesional, además de ser una inversión en su crecimiento personal y en su capacidad para contribuir de manera significativa a la sociedad.</p>
            <!-- Aqui va el componente de contacto, no se te olvide hacerlo manolo -->
            <p class=" text-htwo text-left mb-20"><a href="https://humanitas.edu.mx/maestrias" target="_blank" class="text-htwo">Conoce las maestrías de la Universidad Humanitas</a></p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="true" :linkAnt="'/recursos/casos-reales/perfil-criminologico-como-descifra-la-mente-criminal'" :linkSig="'/academico/licenciatura/aptitudes-habilidades-para-estudiar-derecho'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import ContactoVue from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue';
import SigArt from '../../pagesComponents/SigArt.vue';

export default {
    components: { Comentarios, ConComentarios, ContactoVue, LikesVue, ShareP, SigArt},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '019-ART-ACADEMICO-MAESTRIA-260424',
            ruta: '/academico/maestria/maestria-psicologia-guia-completa',
            title: 'Costo y requisitos para estudiar una maestría: entérate',
            image: 'https://blog.humanitas.edu.mx/images/Costo-y-requisitos-estudiar-maestria.jpg',
            text: 'Describimos todo lo que necesita saber de la Maestría en Psicología, cuyo principal objetivo es fortalecer habilidades y conocimientos en terrenos prácticos y teóricos.'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx/academico/maestria/costo-y-requisitos-estudiar-maestria';

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>