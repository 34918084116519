<script lang="ts">
    import { defineComponent } from "vue";
    import VideoHiL from "../VideoHiL.vue";

    export default defineComponent({
        components: { VideoHiL },
        data() {
            return {
                showImage: true,
                videoUrl: "https://www.youtube.com/embed/NjSfeAvwBZg?si=wx0vkCCV-m0V19pu",
                buttons : {
                    button1: {
                        imageUrl: "../pagesImages/inscribete_white.png",
                        hoverImageUrl: "../pagesImages/inscribete_on.png"
                    },
                },
                videoThumbnail: "../pagesImages/play-icon.png",   
            };
        },
        methods: {
            showVideo() {
                this.showImage = false;
            },
        },
    });
</script>

<template>
    <section class="flex justify-center items-center w-screen h-full lg:h-[500px] bg-cover bg-[top_center] bg-no-repeat" style="background-image: url(../pagesImages/inscribete.jpg); ">
        <div class="lg:flex justify-between w-full max-w-4xl lg:max-w-[1200px] items-center">
            <div class="flex flex-col lg:justify-start justify-center items-center lg:items-start lg:w-[40%] w-full lg:my-36 my-12 text-justify md:px-4 px-12">
                <h2 class="text-[white] leading-none text-left text-[28px]" style="">Egresados</h2>
                <h2 class="text-hgold text-left font-serif mb-2 text-[42px]">Humanitas</h2>
                <p class="text-white text-base">
                    Una persona decidida a triunfar es capaz de superar hasta el obst&aacute;culo m&aacute;s dif&iacute;cil. Tu nos has demostrado que querer es poder, y por eso estamos orgullosos porque hayas egresado de la universidad.
                </p>
                <br>
                <router-link to="/contacto" class="">
                    <div class="flex items-center justify-center w-[140px] h-[35px] bg-white/70 hover:bg-hgold text-hwine border-2 border-hwine text-lg transition duration-300 ease-in-out">
                        <span>Inscr&iacute;bete</span>
                    </div>
                </router-link>
            </div>
            <div class="flex flex-col items-center justify-center lg:w-[30%] w-full my-16 ">
                <div class="flex justify-center w-full h-full items-center ">
                    <VideoHiL :thumbnailUrl="videoThumbnail" :videoUrl="videoUrl" class="w-[100%]  md:px-16 lg:px-0"/>
                </div>
            </div>
        </div>
    </section>
</template>

<style>

</style>