<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">Perfil criminológico: descifrando la mente <br class="md:block hidden"> detrás del crimen</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-200">
          <img src="/images/Perfil-criminologico-detras-mente-criminal-investigadores.jpg" class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/recursos" class=" text-hone">Recursos</router-link> > <router-link to="/blog/recursos/casos-reales" class=" text-hone">Casos Reales</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex text-left list-disc pl-5 italic text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">09 de abril de 2024</li>  <li>Carolina Reyes Martínez/Redacción Humanitas</li></ul>
            </div>
            <p class="md:mb-8 mb-10"><b>Extracto:</b> Explora el fascinante mundo del perfil criminológico, donde la ciencia y la intuición se unen para revelar los secretos detrás de los delitos. Sumérgete en la ciencia de descifrar la mente criminal.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">¿Qué es la Criminología y por qué es importante en la resolución de los delitos?</h2>
            <p class="md:mb-4 mb-6">La palabra criminología proviene de la lengua latina criminis que significa “delito” y de la palabra griega logos que significa “tratado”. <b>La criminología es una ciencia interdisciplinaria,</b> cercana a las ciencias sociales y humanistas como la sociología, la psicología, la antropología, el derecho penal cuyo objetivo es el estudio del crimen desde un punto de vista teórico o filosófico.</p>
            <p class="md:mb-4 mb-6"><b>La criminología estudia las razones detrás de un crimen.</b> Analiza la motivación de un criminal y su relación con la sociedad. Su objetivo es comprender y prevenir el delito.</p>
            <p class="md:mb-4 mb-6">El origen de esta ciencia <b>inicia en la Ilustración,</b> no así el interés por la conducta criminal que se remonta hacia la antigüedad, por ejemplo, Sócrates, Platón y Aristóteles ya se interesaban en este comportamiento humano.</p>
            <p class="md:mb-4 mb-6">En la Edad Media, <b>Santo Tomás de Aquino concilió la idea de la fe y la razón, y reflexionó sobre “la predisposición del crimen”</b> diciendo que “el hombre tiene voluntad y está dotado por la razón, y que el valor de los castigos impuestos por el hombre tiene un valor medicinal”.</p>
            <p class="md:mb-8 mb-10">Se interesó por la conducta delictiva y comportamiento criminal, pero sin dejar de lado la voluntad de quienes han cometido un delito.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Las escuelas de pensamiento antes de la Criminología del siglo XX</h3>
            <p class=" md:mb-4 mb-6">La escuela clásica nace a raíz de los postulados de la Ilustración francesa donde los seres humanos ejercen el libre albedrío y son seres iguales, racionales, y sujetos por la responsabilidad ya que todos pueden cometer un acto criminal.</p>
            <p class=" md:mb-8 mb-10">En tanto, la escuela también llamada biológico-positivista busca en la biología el origen de la conducta en los humanos. Para esta escuela, el criminal nace con este instinto y se centra en estudiar al delincuente; las diferencias entre éste y un ciudadano respetable se pueden estudiar con el método científico.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Criminología en el siglo XX</h3>
            <p class=" md:mb-8 mb-10"><b>La criminología moderna surgiría como heredera de ambas escuelas</b> y aprovechó que ya había avances tanto en la psicología y sociología, abriéndose a nuevas ideas, ya que no sólo se centró en el delincuente y el delito, también en el entorno social y en la víctima.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Las áreas de la criminología que debes conocer</h2>
            <p class="mb-2">La criminología se apoya en cuatro áreas:</p>
            <ul class="list-decimal pl-6 md:mb-8 mb-10">
                <li><b>Antropología.</b> La antropología estudia al ser humano de manera integral, de esta manera los factores antropológicos son: la herencia, la disposición, genotipo y fenotipo, y personalidad.</li>
                <li><b>Biología.</b> Se enfoca en la relación que hay entre los factores biológicos del ser humano con la criminalidad, así como en la genética.</li>
                <li><b>Psicología.</b> Por medio de ella se estudia el comportamiento y los procesos mentales de los criminales, para descubrir las conductas psicológicas que influyen en una persona para cometer un ilícito.</li>
                <li><b>Sociología.</b> Estudia los delitos como fenómenos sociales y se enfoca en los recursos preventivos, sean políticos y sociales.</li>
            </ul>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Diferencia entre la criminología y la criminalística</h2>
            <p class="md:mb-4 mb-6">Si bien ambas estudian el crimen, el enfoque de cada una es distinto: <b>la criminalística es la disciplina que estudia la escena del crimen con base en métodos científicos.</b></p>
            <p class="md:mb-8 mb-10">Trabaja con otras ciencias (biología, química, psicología forense, medicina legal, dactiloscopía, planimetría) para reconstruirlo a través de las distintas técnicas y procedimientos, y saber cómo ocurrió, quién o quiénes participaron y de qué manera en el ilícito. Ahora ya sabes cuál es la diferencia.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">¿Qué es un perfil criminológico?</h2>
            <p class="md:mb-4 mb-6"><b>Es un grupo de inferencias</b> (inferencia es el proceso lógico de una premisa para llegar a una conclusión) <b>sobre las cualidades que reúne una persona que comete un acto delictivo</b> o varios, desde sus características físicas hasta detalles demográficos.</p>
            <p class="md:mb-4 mb-6">La perfilación criminal o criminal profiling es una de las herramientas de gran ayuda a la investigación. <b>El objetivo de esta técnica de apoyo es reducir el número de sospechosos de manera argumentada y científica,</b> cuando se ha producido un delito y seguir una línea de investigación lo más clara posible.</p>
            <p class="md:mb-4 mb-6">Para comenzar a hacer el perfil criminológico, se estudia el escenario donde ocurrió el delito, cómo se cometió, el estado y la conducta de la víctima.</p>
            <p class="md:mb-4 mb-6"><b>Los profesionales pueden comprender la mente del agresor</b> y elaborar los estudios científicos de las características de su personalidad, qué lo motivó a llevar a cabo el delito, si éste fue planeado o lo realizó de manera impulsiva.</p>
            <p class="mb-2">Un perfil criminológico debe:</p>
            <ul class="list-decimal pl-6 md:mb-4 mb-6">
                <li><b>Ser elaborado mediante el método científico</b> que implica la observación, hacer una hipótesis y la experimentación para comprobar la hipótesis.</li>
                <li><b>Basarse en la evidencia</b> y el razonamiento.</li>
                <li>Llegar a <b>conclusiones lógicas.</b></li>
            </ul>
            <p class="md:mb-8 mb-10">Este proceso lo siguen los expertos para detectar patrones, rasgos comunes, motivación, trayectoria personal y familiar, datos que ayudan a determinar el perfil criminal que se pueden verificar y probar.</p>
            <img src="/images/A-investigadora-perfil-criminologico-eliminar-sospechosos.jpg" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Descifrando la mente del criminal</h2>
            <p class="mb-2">El objetivo principal de la perfilación criminal, como ya lo mencionamos, no es señalar a un culpable, sino eliminar a los sospechosos con una investigación, sin embargo, también existen otros objetivos:</p>
            <ul class="list-disc pl-6 md:mb-4 mb-">
                <li><b>Conectar actos violentos,</b> si éstos han sido realizados por la misma persona incluso aunque se hayan llevado a cabo en distintos lugares geográficos. Al conocer el modus operandi y la personalidad del delincuente puede haber conexión criminal.</li>
                <li>Otro objetivo es <b>el análisis psicológico del autor y en qué estado mental estaba cuando realizó el delito.</b> Es muy normal que cuando se captura al delincuente, al juzgarlo diga que tenía algún trastorno mental o simule una enfermedad, por eso es importante el papel de perfilador.</li>
                <li>También <b>es relevante determinar el perfil de la víctima</b> (Perfilación criminal aplicada a la Victimología). Esto se refiere a que el especialista realiza una “autopsia psicológica” y también, la simulación de la escena del crimen. <b>Si se conoce a la víctima, se puede conocer al autor del delito.</b></li>
                <li>El cuarto objetivo, <b>el estudio en "frío", que es recopilar, preservar y actualizar la información</b> de perfiles criminales. Esta información se utiliza para realizar análisis en otros delitos. Se incluye la personalidad y el modus operandi de los delincuentes.</li>
            </ul>
            <p class="md:mb-4 mb-6"><b>La técnica de la Perfilación criminal se nutre de las demás ciencias forenses,</b> por lo que el perfilador si bien no es experto en todas, sí debe conocerlas para poder comprender los informes de los peritos criminalísticos (por ejemplo, estudios de ADN, balística, autopsias, pericias grafológicas, y más).</p>
            <p class="md:mb-8 mb-10">Hay que remarcar que <b>la perfilación criminal no se usa para todo, se enfoca en un hecho que se ha repetido tres veces,</b> al menos. Algunos dicen que dos, pero hay que tomar en cuenta que un asesino es considerado serial a partir de su tercera víctima.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Conclusión</h2>
            <p class="md:mb-4 mb-6">Tal vez recuerdes la película de <b><em>El silencio de los inocentes</em></b> o bien, si no la has visto y te interesa el tema del perfil criminológico, <b>mira este video de la <a href="https://www.youtube.com/watch?v=mLjN39hmGrg" target="_blank" class="text-htwo">Maestra en Psicología Criminal, Angélica Victoria Anaya,</a></b> que explica cómo la Psicología y la Psiquiatría trabajan en conjunto para poder elaborar un perfil criminológico.</p>
            <p class="md:mb-8 mb-10">Esta película es una buena referencia para visualizar aquellos elementos que se analizan para crear un perfil criminal que permita la captura de un asesino desconocido.</p>
            <!-- Aqui va el componente de contacto, no se te olvide hacerlo manolo -->
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
                <ContactoVue class="m-3"/>
            </div>
            <p class=" text-htwo text-left md:mb-4 mb-6">Explora más: <a href="https://humanitas.edu.mx/licenciatura-en-psicologia" target="_blank" class="text-htwo">Licenciatura en Psicología</a></p>
            <p class=" text-htwo text-left mb-20">Te puede interesar: <a href="https://humanitas.edu.mx/educacion-continua" target="_blank" class="text-htwo">Educación continua</a></p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="true" :linkAnt="'/aprende/tips/maximiza-tu-productividad-estilo-bill-gates'" :linkSig="'/academico/maestria/maestria-psicologia-guia-completa'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import ContactoVue from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue';
import SigArt from '../../pagesComponents/SigArt.vue';

export default {
    components: { Comentarios, ConComentarios, ContactoVue, LikesVue, ShareP, SigArt},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '17-ART-RECURSOS-CASOSREALES-090424',
            ruta: '/recursos/casos-reales/perfil-criminologico-como-descifra-la-mente-criminal',
            title: '27 de marzo Día Mundial del Teatro 2024: celebra la paz y el arte',
            image: 'https://blog.humanitas.edu.mx/images/Costo-y-requisitos-estudiar-maestria.jpg',
            text: 'El perfil criminológico permite internarse en la mente de los delincuentes para resolver casos complejos. Explora qué es.'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx/academico/maestria/costo-y-requisitos-estudiar-maestria';

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>