<script lang="ts">
	import Botones from './Botones.vue';
	import VideoHiL from './VideoHiL.vue';

	export default {
		components: {  Botones, VideoHiL },
		data() {
			return {
				images: [
					'../pagesImages/muestra-1.jpg',
					'../pagesImages/muestra-2.jpg',
					'../pagesImages/muestra-3.jpg',
					'../pagesImages/muestra-4.jpg',
				],
				videos: [
					'https://www.youtube.com/embed/RlBSrAyGa4o?si=V5KyeMTMtRMl8Miw',
					'https://www.youtube.com/embed/hIV691ryl90?si=zKuJJVpH74aQutHz',
					'https://www.youtube.com/embed/LkAyGgORQJo?si=ByCGvAHS5kdONKw5',
					'https://www.youtube.com/embed/6H8nKbdNVh8?si=ZPqaXU6Bxk9lsOYA',
				],
				currentIndex: 0,
				transitionName: 'fade',
				showImage: true,
				buttons: {
					button1: {
						imageUrl: "../pagesImages/atras-off.png", hoverImageUrl: "../pagesImages/atras-on.png"
					},
					button2: {
						imageUrl: "../pagesImages/siguiente-off.png", hoverImageUrl: "../pagesImages/siguiente-on.png"
					}
				},
			};
		},
		methods: {
			nextSlide(): void {
				this.currentIndex = (this.currentIndex + 1) % this.images.length;
				this.transitionName = 'fade';
				this.showImage = true;
			},
			prevSlide(): void {
				this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
				this.transitionName = 'fade';
				this.showImage = true;
			},
			showVideo(): void {
				this.showImage = false;
			},
		},
	};
</script>

<template>
	<div class="flex items-center w-full">
		<div class="flex items-center justify-center cursor-pointer rounded-sm border border-gray-800 w-[50px] h-[80px] bg-hgray text-white hover:bg-hwine transition duration-300 ease-in-out" @click="prevSlide">
			<svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M10.707 3.293a1 1 0 011.414 1.414L7.414 10l4.707 4.707a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 010 1.414z" clip-rule="evenodd"></path></svg>
		</div>
		<div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full px-2 md:px-4">
			<VideoHiL :thumbnailUrl="currentIndex-1 === -1 ? images[images.length-1] : images[currentIndex-1]" :videoUrl="currentIndex-1 === -1 ? videos[images.length-1] : videos[currentIndex-1]" class="w-full cursor-pointer"/>
			<VideoHiL :thumbnailUrl="images[currentIndex]" :videoUrl="videos[currentIndex]" class="hidden md:block w-full cursor-pointer"/>
			<VideoHiL :thumbnailUrl="currentIndex+1 === images.length ? images[0]: images[currentIndex+1]" :videoUrl="currentIndex+1 === images.length ? videos[0] : videos[currentIndex+1]" class="hidden lg:block cursor-pointer"/>
		</div>
		<div class="flex items-center justify-center cursor-pointer rounded-sm border border-gray-800 w-[50px] h-[80px] bg-hgray text-white hover:bg-hwine transition duration-300 ease-in-out" @click="nextSlide">
			<svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M9.293 16.707a1 1 0 01-1.414-1.414L12.586 10 7.879 5.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
		</div>
	</div>
</template>

<style scoped>
</style>