<template>
  <div>
    <CataBueno :titulo="'Académico'" :subtitulo="'Licenciatura'" :filtros="filtros" :articles="articles"/>
  </div>
</template>

<script lang="js">
import CataBueno from '../pagesComponents/CataBueno.vue';

export default {
  components: { CataBueno },
  data() {
    return {
      articles: [
        {
          "id": "0049-ART-ACADEMICO-LICENCIATURA-250624",
          "titulo": "Identifica las mejores universidades para estudiar psicología en México",
          "fecha": "25 de junio de 2024",
          "autor": "Iván Solís Rivera",
          "descripcion": "Reconoce las mejores universidades para estudiar psicología en México tomando en cuenta aspectos como validez educativa, infraestructura y planes de estudio.",
          "portada": "/images/mejores-universidades-estudiar-psicologia-mexico.webp",
          "enlace": "/academico/licenciatura/mejores-universidades-para-estudiar-psicologia-en-mexico"
        },
        {
          "id": "0048-ART-ACADEMICO-LICENCIATURA-200624",
          "titulo": "Licenciatura en Educación y Administración de centros educativos: ¿Qué es?",
          "fecha": "20 de junio de 2024",
          "autor": "Iván Solís Rivera",
          "descripcion": "La licenciatura en Educación y Administración de centros educativos combina conocimientos de pedagogía, gestión administrativa y liderazgo educativo.",
          "portada": "/images/licenciatura-educacion-administracion-centros-educativos.webp",
          "enlace": "/academico/licenciatura/licenciatura-en-educacion-y-administracion-de-centros-educativos"
        },
        {
          "id": "0046-ART-ACADEMICO-LICENCIATURA-180624",
          "titulo": "Arquitectura: ¿Licenciatura o ingeniería? Aquí te decimos",
          "fecha": "18 de junio de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "Conoce si la arquitectura es licenciatura o ingeniería, qué estudia y su diferencia con la ingeniería civil.",
          "portada": "/images/arquitectura-arte-de-disenar-y-construir.webp",
          "enlace": "/academico/licenciatura/arquitectura-licenciatura-o-ingenieria-te-decimos"
        },
        {
          "id": "0043-ART-ACADEMICO-LICENCIATURA-130624",
          "titulo": "Descubre cuáles son las principales corrientes y teorías psicológicas",
          "fecha": "13 de junio de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "Exploramos las principales corrientes y teorías psicológicas, sus modelos y representantes destacados. Ideal para ti que quieres saber más.",
          "portada": "/images/corrientes-psicologicas-y-principales-representantes.webp",
          "enlace": "/academico/licenciatura/principales-corrientes-teorias-psicologicas-modelos-y-representantes"
        },
        {
          "id": "0039-ART-ACADEMICO-LICENCIATURA-060624",
          "titulo": "¿Qué es un acto administrativo? Guía para entenderlo",
          "fecha": "06 de junio de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "Entender qué es un acto administrativo es muy importante, ya que es esencial para el funcionamiento de la administración pública e impacta en la vida de los ciudadanos.",
          "portada": "/images/que-es-acto-administrativo-concepto-ejemplos.webp",
          "enlace": "/academico/licenciatura/que-es-un-acto-administrativo"
        },
        {
          "id": "0040-ART-LICENCIATURA-CONTABILIDAD-060624",
          "titulo": "Tipos de Contabilidad que permiten una mejor planificación de capital",
          "fecha": "06 de junio de 2024",
          "autor": "Iván Solís Rivera",
          "descripcion": "Reconocer los tipos de Contabilidad ayudan a la toma de decisiones informadas y mejorar la planificación de recursos.",
          "portada": "/images/tipos-contabilidad-financiero.webp",
          "enlace": "/academico/licenciatura/tipos-de-contabilidad"
        },
        {
            "id": "021-ART-ACADEMICO-LICENCIATURA-070524",
            "titulo": "Universidades sin examen de admisión",
            "fecha": "07 de mayo de 2024",
            "autor": "Iván Solís Rivera",
            "descripcion": "Universidades sin examen de admisión privadas y públicas tienen un objetivo común: brindar continuidad de estudios.",
            "portada": "/images/universidades-examen-admision.webp",
            "enlace": "/academico/licenciatura/universidades-sin-examen-admision"
        },
        {
            "id": "020-ART-ACADEMICO-LICENCIATURA-290424",
            "titulo": "Dominando el futuro: Descubre las tendencias clave del Diseño Gráfico para 2024",
            "fecha": "07 de mayo de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Explora las principales tendencias de diseño gráfico para 2024. Desde la realidad aumentada hasta la sostenibilidad, descubre cómo influirán en tu carrera y proyectos creativos.",
            "portada": "/images/tendencia-diseno-grafico-2024-colores-fluorescentes-neon-humanitas.webp",
            "enlace": "/academico/licenciatura/tendencias-diseno-grafico-2024"
        },
        {
            "id": "18-ART-ACADEMICO-LICENCIATURA-260424",
            "titulo": "Qué aptitudes y habilidades necesitas para estudiar Derecho.",
            "fecha": "25 de abril de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Descubre las aptitudes y habilidades clave que necesitas para estudiar Derecho. Prepárate para enfrentar los desafíos de esta carrera. ¡Conócelas!",
            "portada": "/images/descubre-aptitudes-habilidades-clave-estudiar-derecho.jpg",
            "enlace": "/academico/licenciatura/aptitudes-habilidades-para-estudiar-derecho"
        },
        {
            "id": "14-ART-ACADEMICO-LICENCIATURA-270324",
            "titulo": "27 de marzo Día Mundial del Teatro 2024: celebra la paz y el arte",
            "fecha": "27 de marzo de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Conoce la importancia y la belleza del Día Mundial del Teatro. Exploramos su historia, significado y cómo puedes unirte a este gran día, ¡participa!",
            "portada": "/images/A-27-marzo-2024-dia-mundial-teatro.jpg",
            "enlace": "/academico/licenciatura/dia-mundial-del-teatro-2024"
        },
        {
            "id": "10-GUIA-ACADEMICO-LICENCIATURA-010324",
            "titulo": "Guía de mejores universidades para estudiar Derecho en México: ¡infórmate!",
            "fecha": "07 de marzo de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "¿Quieres estudiar Derecho? Esta guía te ayudará a tomar una decisión informada. Conoce cuáles son las mejores opciones e inicia tu camino profesional.",
            "portada": "/images/mejores-universidades-estudiar-derecho-Mexico-guia.jpg",
            "enlace": "/academico/licenciatura/mejores-universidades-derecho-mexico-guia"
        },
        {
            "id": "05-ART-ACADEMICO-LICENCIATURA-060224",
            "titulo": "Estudia una licenciatura en menor tiempo para impulsar tu éxito profesional.",
            "fecha": "06 de febrero de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Conoce los beneficios de las carreras universitarias cortas y cómo pueden ser una opción ideal si quieres iniciar tu vida laboral más rápido.",
            "portada": "/images/carreras-cortas-beneficios.jpg",
            "enlace": "/academico/licenciatura/beneficios-carreras-universitarias-cortas"
        },
      ], // Array de artículos original
      filtros: {
        "0049-ART-ACADEMICO-LICENCIATURA-250624": {
          Humanidades: ["Psicología"],
          Ciencias_sociales: [],
          Economico_administrativas: []
        },
        "0048-ART-ACADEMICO-LICENCIATURA-200624": {
          Humanidades: ["Educación"],
          Ciencias_sociales: [],
          Economico_administrativas: []
        },
        "0046-ART-ACADEMICO-LICENCIATURA-180624": {
          Humanidades: ["Arquitectura"],
          Ciencias_sociales: [],
          Economico_administrativas: []
        },
        "0043-ART-ACADEMICO-LICENCIATURA-130624": {
          Humanidades: ["Psicología"],
          Ciencias_sociales: [],
          Economico_administrativas: []
        },
        "0039-ART-ACADEMICO-LICENCIATURA-060624": {
          Humanidades: [],
          Ciencias_sociales: ["Derecho", "Ciencias Políticas", "Administración Pública"],
          Economico_administrativas: ["Administración"]
        },
        "0040-ART-LICENCIATURA-CONTABILIDAD-060624": {
          Humanidades: [],
          Ciencias_sociales: [],
          Economico_administrativas: ["Administración", "Contabilidad"]
        },
        "021-ART-ACADEMICO-LICENCIATURA-070524": {
          Humanidades: [],
          Ciencias_sociales: ["Derecho", "Ciencias Políticas", "Administración Pública"],
          Economico_administrativas: []
        },
        "020-ART-ACADEMICO-LICENCIATURA-290424": {
          Humanidades: ["Diseño Gráfico"],
          Ciencias_sociales: [],
          Economico_administrativas: []
        },
        "18-ART-ACADEMICO-LICENCIATURA-260424": {
          Humanidades: [],
          Ciencias_sociales: ["Derecho", "Ciencias Políticas", "Administración Pública"],
          Economico_administrativas: ["Administración"]
        },
        "14-ART-ACADEMICO-LICENCIATURA-270324": {
          Humanidades: ["Arte y Teatro"],
          Ciencias_sociales: [],
          Economico_administrativas: []
        },
        "10-GUIA-ACADEMICO-LICENCIATURA-010324": {
          Humanidades: [],
          Ciencias_sociales: ["Derecho"],
          Economico_administrativas: []
        },
        "05-ART-ACADEMICO-LICENCIATURA-060224": {
          Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y Teatro"],
          Ciencias_sociales: ["Derecho", "Ciencias Políticas", "Administración Pública"],
          Economico_administrativas: ["Administración", "Contabilidad"]
        },
      },
      filtrosSeleccionados: null, // Lista de filtros seleccionados
    };
  },
  methods: {
  }
};
</script>

<style>
/* Estilos CSS si los tienes */
</style>