<template>
    <div class="relative" @click="playVideo">
      <img :src="image" alt="Thumbnail" class="w-full h-auto">
      <div class="absolute inset-0 flex justify-center items-center">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="w-16 h-16 text-white fill-current">
          <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"/>
        </svg>
      </div>
      <iframe v-if="showVideo" :src="videoSrc" class="absolute inset-0 w-full h-full" title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>
    </div>
</template>
  
<script lang="js">
export default {
props: {
    image: String,
    videoSrc: String
},
data() {
    return {
    showVideo: false
    };
},
methods: {
    playVideo() {
    this.showVideo = true;
    }
}
};
</script>