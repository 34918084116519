<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">Día Mundial de las Redes Sociales: <br class="md:block hidden"> Celebrando el 30 de junio</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-300">
          <img src="/images/dia-mundial-redes-sociales-30-junio.webp" alt="Las redes sociales han revolucionado la vida de miles de personas." class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 text-left italic text-[18px] md:text-[20px]"><router-link to="/blog/aprende" class=" text-hone">Aprende</router-link> > <router-link to="/blog/aprende/cultura-general" class=" text-hone">Cultura General</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex list-disc pl-5 italic text-left text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">27 de junio de 2024</li>  <li>Carolina Reyes Martínez</li></ul>
            </div>
            <p class=" md:mb-4 mb-6">Las redes sociales han transformado la manera en que nos comunicamos, aprendemos y compartimos información.</p>
            <p class="md:mb-8 mb-10">El 30 de junio se celebra el Día Mundial de las Redes Sociales, una fecha dedicada a reconocer y reflexionar sobre su impacto en la sociedad. Exploramos la historia, la importancia y las diversas maneras en que se celebra este día, además de los beneficios y desafíos que presentan las redes sociales.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Historia del Día de las Redes Sociales</h2>
            <p class="md:mb-4 mb-6">El Día Mundial de las Redes Sociales <b>fue creado en 2010 por Mashable, una de las principales fuentes de noticias en tecnología y redes sociales.</b> Su fundador, Peter Cashmore, subrayó el auge que tuvieron ese año y la importancia de esta forma de comunicación que ha transformado la vida cotidiana del mundo.</p>
            <p class="md:mb-4 mb-6">La intención detrás de esta celebración era <b>reconocer la revolución que las redes sociales han traído a la comunicación global</b> y fomentar su uso responsable y positivo.</p>
            <p class="md:mb-8 mb-10">Desde su creación, <b>el Día Mundial de las Redes Sociales ha ganado popularidad y relevancia.</b> Cada año, más personas y organizaciones se unen a la celebración, reconociendo su influencia en la comunicación, la educación, los negocios y más. Este día se ha convertido en una oportunidad para discutir tanto las ventajas como los desafíos de estas plataformas.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Importancia de las Redes Sociales en la Sociedad Moderna</h2>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Impacto en la comunicación: Educación y la universidad</h3>
            <p class="md:mb-4 mb-6">Al permitir una conexión instantánea y global, <b>las redes sociales facilitan el intercambio de ideas y de las noticias en tiempo real. Para los estudiantes universitarios, las redes sociales son una herramienta esencial</b> para mantenerse en contacto con compañeros, profesores y profesionales de su campo de estudio.</p>
            <p class="md:mb-8 mb-10">En el ámbito educativo <b>se han abierto nuevas oportunidades para el aprendizaje y la colaboración. Plataformas como Facebook, Twitter, YouTube y LinkedIn se utilizan para compartir recursos educativos,</b> participar en debates académicos y formar grupos de estudio. Además, las universidades emplean las redes sociales para relacionarse con los estudiantes y promover eventos y actividades.</p>
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
              <Contacto class="m-3"/>
            </div>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Influencia en los negocios y el marketing</h3>
            <p class="md:mb-8 mb-10">Las redes sociales <b>también han transformado el mundo de los negocios y el marketing.</b> Las empresas utilizan estas plataformas <b>para alcanzar a sus clientes,</b> promover sus productos y construir su marca.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Cómo se celebra el Día Mundial de las Redes Sociales</h2>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Actividades y eventos globales</h3>
            <p class="md:mb-4 mb-6">El Día Mundial de las Redes Sociales <b>se celebra con una variedad de actividades y eventos en todo el mundo, que incluyen conferencias, talleres y paneles de discusión</b> organizados por universidades, empresas y organizaciones sin fines de lucro.</p>
            <p class="md:mb-8 mb-10">Estas actividades <b>tienen el objetivo de educar a los participantes sobre el uso efectivo y seguro de las redes sociales,</b> además de explorar las últimas tendencias y herramientas en este campo.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Iniciativas en universidades y comunidades educativas</h3>
            <p class="md:mb-4 mb-6">Las universidades y comunidades juegan un gran papel en la celebración del Día Mundial de las Redes Sociales. <b>Muchas instituciones organizan eventos especiales como seminarios y mesas redondas,</b> donde expertos en redes sociales comparten sus conocimientos y experiencias con los estudiantes.</p>
            <p class="md:mb-8 mb-10">Estas iniciativas no sólo ayudan a los estudiantes a comprender mejor el impacto de las redes sociales, sino que también fomentan un uso responsable y ético de estas plataformas.</p>
            <img src="/images/redes-sociales-influyen-en-vida-diaria.webp" alt="Las redes sociales facilitan el networking para colaborar en proyectos creativos o laborales." class="md:mb-8 mb-10">
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Participación de marcas y empresas</h3>
            <p class=" md:mb-8 mb-10">A menudo, <b>lanzan campañas especiales en sus perfiles para interactuar con sus seguidores</b> y destacar la importancia de las redes sociales en sus estrategias de marketing. Estas campañas incluyen concursos, promociones y publicaciones informativas que resaltan cómo las redes han transformado sus negocios.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Beneficios de las Redes Sociales</h2>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Conexión y networking</h3>
            <p class="md:mb-4 mb-6">Uno de los mayores beneficios de las redes sociales es la capacidad de conectar a personas de todo el mundo; <b>para los estudiantes universitarios, esto significa la oportunidad de construir una red de contactos profesionales, colaborar en proyectos académicos y mantenerse al día con las tendencias</b> y noticias de su campo de estudio.</p>
            <p class=" md:mb-8 mb-10">Las redes sociales <b>facilitan la creación de relaciones valiosas</b> que pueden influir positivamente en la vida profesional y personal.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Plataformas para la expresión y la creatividad</h3>
            <p class="md:mb-4 mb-6">Las redes sociales <b>proporcionan un espacio para la expresión personal y la creatividad.</b> Las personas pueden compartir sus pensamientos, ideas y proyectos con una audiencia en distintas partes del mundo.</p>
            <p class=" md:mb-8 mb-10">Plataformas como Instagram, TikTok y Pinterest permiten a los usuarios mostrar su creatividad a través de fotos, videos y diseños. Este aspecto de las redes sociales fomenta la innovación y permite mostrar los talentos únicos de quienes las usan.</p>
            <img src="/images/redes-sociales-educacion-interaccion-global.webp" alt="Las redes sociales enfrentan desafíos como asegurar la privacidad y seguridad de los usuarios." class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] font-serif mb-5 text-left leading-tight lg:text-justify text-hone">Los desafíos de las Redes Sociales</h2>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Privacidad y seguridad</h3>
            <p class=" text-left md:mb-4 mb-6"><b>Uno de los principales desafíos de las redes sociales es la privacidad y la seguridad.</b> Los usuarios deben ser conscientes de todos los riesgos al compartir información personal en línea. <b>Esto significa proteger los datos personales y ser cautelosos con la información que publicamos.</b></p>
            <p class=" md:mb-8 mb-10">Las plataformas de redes sociales han implementado diversas medidas de seguridad. Los usuarios deben estar informados y tomar precauciones adicionales para proteger su privacidad.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Impacto en la salud mental</h3>
            <p class=" md:mb-8 mb-10">El uso excesivo de las redes sociales puede tener un impacto negativo en la salud mental. La comparación constante con los demás, la presión para mantener una imagen perfecta y la exposición a contenido negativo podrían causar estrés, ansiedad y depresión. <b>Es importante establecer límites en el uso de las plataformas.</b></p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Manejo del tiempo y productividad</h3>
            <p class=" md:mb-8 mb-10">Éste es otro desafío. <b>Las redes sociales pueden ser una gran distracción y afectar la productividad, por lo que es importante <router-link to="/blog/aprende/tips/maximiza-tu-productividad-estilo-bill-gates" class=" text-htwo hover:text-hone">desarrollar habilidades de gestión del tiempo</router-link></b> y establecer límites claros en el uso de las redes sociales puede ayudar a mitigar este problema y mejorar la productividad.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px]font-serif mb-5 text-left leading-tight lg:text-justify text-hone">El futuro de las Redes Sociales</h2>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Tendencias emergentes</h3>
            <p class=" md:mb-8 mb-10">Entre estas tendencias se encuentran: <b>el aumento del contenido efímero como las Stories de Instagram, la realidad aumentada y virtual, y el uso de inteligencia artificial para personalizar la experiencia del usuario, así como el uso de las redes sociales de nicho,</b> como por ejemplo, Twitch para los gamers, Tik Tok para algunas marcas B2B, así como el incremento de las ventas sociales o sea en las comunidades donde la venta minorista crecerá más.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Innovaciones tecnológicas</h3>
            <p class=" md:mb-8 mb-10">Desde nuevas funciones y herramientas hasta mejoras en la seguridad y privacidad, estas innovaciones están diseñadas para mejorar la experiencia del usuario. <b>Los avances en la tecnología móvil, el desarrollo de aplicaciones más intuitivas y la integración de nuevas tecnologías</b> como el blockchain son sólo algunas de las innovaciones que se esperan en el futuro de las redes sociales.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Cambios en el uso y la percepción social</h3>
            <p class=" md:mb-8 mb-10">A medida que las redes sociales evolucionan, también lo hacen las maneras en que las usamos y las percepciones que tenemos sobre ellas. <b>La generación más joven está redefiniendo cómo se utilizan las redes sociales, priorizando la autenticidad y la transparencia.</b> Además, hay un creciente énfasis en el uso responsable y ético de estas plataformas, lo que refleja una mayor conciencia sobre los desafíos y beneficios de las redes sociales.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px]font-serif mb-5 text-left leading-tight lg:text-justify text-hone">El futuro de las Redes Sociales</h2>
            <p class=" text-left md:mb-4 mb-6">Según el reporte de Data Reportal, <b>el número de internautas en México se incrementó un 6.6%, pasando a 107.3 millones de mexicanos conectados, es un 83% de la población.</b></p>
            <p class=" text-left md:mb-4 mb-6"><b>Facebook</b> es la red social que en México es la número uno, ya que el 93.2% de las personas son usuarios activos de Facebook. <b>Tik Tok</b> cuenta con un 76.5% de usuarios activos en México, es la quinta red más utilizada en México, pero destaca porque en ella es donde más tiempo pasan los usuarios.</p>
            <ul class="list-decimal pl-6 md:mb-4 mb-6">
                <li><b>Facebook</b>  90.2 millones de usuarios activos</li>
                <li><b>YouTube</b> 83.1 millones de usuarios activos</li>
                <li><b>Tik Tok</b> 74.2  millones de usuarios activos</li>
                <li><b>FB Messenger</b>  58.7 millones de usuarios activos</li>
                <li><b>Instagram</b> 44.8 millones de usuarios activos</li>
                <li><b>LinkedIn</b> 22  millones de usuarios activos</li>
                <li><b>X (Twitter)</b> 18 millones de usuarios activos</li>
                <li><b>Snapchat</b> 16 millones de usuarios activos</li>
            </ul>
            <p class=" text-left md:mb-4 mb-6"><b>Conclusión<br></b> El Día Mundial de las Redes Sociales <b>es una oportunidad para reflexionar sobre el impacto significativo que estas plataformas tienen en nuestras vidas.</b></p>
            <p class=" md:mb-8 mb-10">A medida que avanzamos hacia el futuro, <b>es esencial que los usuarios,</b> especialmente los más jóvenes, comprendan tanto los beneficios como los desafíos de estas herramientas y <b>utilicen las redes sociales de manera responsable y efectiva.</b></p>
            <p class="text-htwo text-left mb-20">Te puede interesar: <router-link to="/blog/aprende/tips/puntos-clave-para-evaluar-la-calidad-de-una-universidad-privada"  class="text-htwo hover:text-hone">¿Cómo evaluar la calidad de una universidad privada?</router-link></p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="true" :linkAnt="'/academico/educacion-continua/educacion-continua-que-es'" :linkSig="'/recursos/entrevistas-y-conferencias/como-identificar-y-superar-relaciones-toxicas'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import Contacto from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue'
import SigArt from '../../pagesComponents/SigArt.vue';
import VideoClip from '../../pagesComponents/videoClip.vue';


export default {
    components: { Comentarios, ConComentarios, Contacto, LikesVue, ShareP, SigArt, VideoClip},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '0051-ART-APRENDE-CULTURA-GENERAL-270624',
            ruta: '/aprende/cultura-general/cuantos-pisos-tiene-la-torre-de-pemex',
            title: '¿Cuántos pisos tiene la torre de Pemex y cuál es su historia?',
            image: 'https://blog.humanitas.edu.mx/images/Día-internacional-de-la-mujer-financiar-acelera-igualdad-genero-2024.jpg',
            text: 'Conoce cuántos pisos tiene la torre de Pemex y cuál es la historia de este edificio, que es símbolo de la arquitectura moderna mexicana.'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx'+this.ruta;

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>