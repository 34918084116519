<template>
    <div>
      <CataBueno :titulo="'ser humanitas'" :subtitulo="'comunidad'" :filtros="filtros" :articles="articles"/>
    </div>
</template>
    
  <script>
  import CataBueno from '../pagesComponents/CataBueno.vue';
  
  export default {
    components: { CataBueno },
    data() {
      return {
        articles: [
            {
                "id": "0035-ART-SER-HUMANITAS-COMUNIDAD-30052",
                "titulo": "¿Cuánto cuesta una mensualidad en la Universidad Humanitas?",
                "fecha": "30 de mayo de 2024",
                "autor": "Redacción Humanitas",
                "descripcion": "Descubre cuánto cuesta una mensualidad en la Universidad Humanitas y el nivel de la calidad educativa de reconocimiento internacional.",
                "portada": "/images/cuanto-cuesta-mensualidad-universidad-humanitas-instalaciones.webp",
                "enlace": "/ser-humanitas/comunidad/cuanto-cuesta-mensualidad-universidad-humanitas"
            },
        ], // Array de artículos original
        filtros: {
            "0035-ART-SER-HUMANITAS-COMUNIDAD-30052": {
                Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y Teatro"],
                Ciencias_sociales: ["Derecho", "Ciencias Políticas", "Administración Pública"],
                Economico_administrativas: ["Administración", "Contabilidad"]
            },
        },
        filtrosSeleccionados: null, // Lista de filtros seleccionados
      };
    },
    methods: {
      
    }
  };
  </script>
  
  <style>
  /* Estilos CSS si los tienes */
  </style>