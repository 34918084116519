<template>
  <div>
    <CataBueno :titulo="'Aprende'" :subtitulo="'Tendencias'" :filtros="filtros" :articles="articles"/>
  </div>
</template>
  
<script>
import CataBueno from '../pagesComponents/CataBueno.vue';

export default {
  components: { CataBueno },
  data() {
    return {
      articles: [
        {
          "id": "0028-ART-SER-HUMANITAS-APRENDE-TENDENCIAS-160524",
          "titulo": "Mejores competencias digitales para profesionales",
          "fecha": "17 de mayo de 2024",
          "autor": "Iván Solís Rivera",
          "descripcion": "Conoce cuáles son las competencias digitales para profesionales más relevantes que debes desarrollar para ser un profesional.",
          "portada": "/images/competencias-digitales-profesionales-digitalizacion.webp",
          "enlace": "/aprende/tendencias/competencias-digitales-para-profesionales"
        },
        {
          "id": "15-ART-APRENDE-TENDENCIAS-270324",
          "titulo": "Tendencias en Psicología para 2024: Tecnología en la salud mental",
          "fecha": "28 de marzo de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "Conoce las tendencias clave en Psicología en 2024, la salud mental y las aplicaciones digitales. Averigua cómo la tecnología puede ayudar al bienestar.",
          "portada": "/images/tendencias-psicologia-2024-chico-sentado-meditando-frente-laptop.jpg",
          "enlace": "/aprende/tendencias/tendencias-en-psicologia-2024-tecnologia-salud-mental"
        },
        {
          "id": "07-ART-APRENDE-TENDENCIAS-190224",
          "titulo": "Infórmate acerca de las tendencias actuales del Derecho en 2024.",
          "fecha": "19 de febrero de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "Entérate cuáles son las principales tendencias actuales del Derecho en 2024. Mantente al día con los cambios e innovaciones que conforman el futuro legal.",
          "portada": "/images/Tendencias-actuales-derecho-2024.jpg",
          "enlace": "/aprende/tendencias/tendencias-actuales-del-derecho-2024"
        },
        {
          "id": "04-ART-APRENDE-TENDENCIAS-310124",
          "titulo": "Estas son las tendencias clave en la administración en 2024, descúbrelas.",
          "fecha": "31 de enero de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "Entérate de las tendencias más relevantes que dan forma al futuro de la administración de empresas en 2024.",
          "portada": "/images/Tendencias-administración-empresarial-2024-multinube.jpg",
          "enlace": "/aprende/tendencias/tendencias-administracion-empresas-2024"
        },
      ], // Array de artículos original
      filtros: {
        "0028-ART-SER-HUMANITAS-APRENDE-TENDENCIAS-160524": {
          Humanidades: [],
          Ciencias_sociales: [],
          Economico_administrativas: ["Administración"]
        },
        "04-ART-APRENDE-TENDENCIAS-310124": {
          Humanidades: [],
          Ciencias_sociales: [],
          Economico_administrativas: ["Administración"]
        },
        "07-ART-APRENDE-TENDENCIAS-190224": {
          Humanidades: [],
          Ciencias_sociales: ["Derecho"],
          Economico_administrativas: []
        },
        "15-ART-APRENDE-TENDENCIAS-270324": {
          Humanidades: ["Psicología"],
          Ciencias_sociales: [],
          Economico_administrativas: []
        },
      },
      filtrosSeleccionados: null, // Lista de filtros seleccionados
    };
  },
  methods: {
  }
};
</script>

<style>
/* Estilos CSS si los tienes */
</style>