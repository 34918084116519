<template>
  <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
    <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">Descubre cuáles son las principales<br class="md:block hidden"> corrientes y teorías psicológicas </h1>
    <div class="flex flex-col justify-center items-center w-full">
      <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-200">
        <img src="/images/corrientes-psicologicas-y-principales-representantes.webp" alt="Las corrientes psicológicas son perspectivas que explican el comportamiento" class="mb-6">
        <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
          <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/academico" class=" text-hone">Académico</router-link> > <router-link to="/blog/academico/licenciatura" class=" text-hone">Licenciatura</router-link></p>
          <div class="flex items-start justify-start">
              <ul class="lg:flex text-left list-disc pl-5 italic text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">13 de Junio de 2024</li>  <li>Carolina Reyes Martínez</li></ul>
          </div>
          <!-- Images -->
          <p class="md:mb-4 mb-6">Si te has preguntado cuántas corrientes psicológicas existen y cuáles son las más relevantes, este artículo es para ti.</p>
          <p class="md:mb-4 mb-6"><b>La Psicología</b>, según la Asociación Americana de Psicología (APA según sus siglas en inglés) se define como <b>la ciencia que estudia la mente, cómo funciona y cómo afecta al comportamiento, desde la infancia hasta la vejez.</b></p>
          <p class="md:mb-4 mb-6">La Psicología es relativamente joven: al separarse de la Filosofía a mediados del siglo XIX, se considera que <b>su nacimiento se dio cuando se instituyó el laboratorio de investigaciones psicológicas por Wilhelm Wundt</b>, en 1879 en la Universidad de Leipzig. Wundt es conocido como el padre de la Psicología.</p>
          <p class="md:mb-8 mb-10"> Esta disciplina ha evolucionado significativamente a lo largo de los años, por lo que se han desarrollado diversas corrientes y teorías que han permitido estudiar la conducta humana.</p>
          <!-- <p class="md:mb-8 mb-10"><b>Las <router-link to="/blog/academico/licenciatura/mejores-universidades-derecho-mexico-guia"  class="text-htwo">universidades</router-link> que no aplican exámenes de admisión cuentan con sistemas de evaluación alternativos</b> o forman parte de planes educativos de gobierno cuyo principal objetivo es impulsar la educación a nivel medio y superior.</p> -->
          <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">¿Qué son las corrientes psicológicas?</h2>
          <p class="md:mb-4 mb-6"><b>Las corrientes psicológicas son enfoques o perspectivas que explican el comportamiento y los procesos mentales desde diferentes puntos de vista.</b> Éstas han surgido a lo largo del tiempo, cada una aportando teorías y modelos que contribuyen a la comprensión global de la Psicología.</p>
          <p class="md:mb-8 mb-10">Si bien estas teorías se comenzaron a cimentar en el área de la Filosofía, el estudio de la mente humana se dio en el área científica. Estas corrientes se actualizan y reflejan los avances en la investigación y el pensamiento científico. A continuación te compartimos las corrientes y modelos más relevantes, así como sus principales exponentes.</p>
          <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Las corrientes psicológicas y sus representantes</h2>
          <p class="md:mb-8 mb-10">Los psicólogos cuyas investigaciones y teorías han tenido un impacto significativo en la disciplina, han sido clave en el desarrollo de las corrientes y teorías psicológicas:</p>
          <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Wilhelm Wundt, padre de la Psicología</h3>
          <p class="md:mb-8 mb-10">Como ya habíamos mencionado, <b>es considerado el padre de la Psicología</b>, ya que fue un fisiólogo y psicólogo, pionero en estudiar la mente con el método científico. Fue quien diferenció a la Psicología de la Filosofía, y dio paso al Estructuralismo.</p>
          
          <!-- Form -->
          <div class="md:mb-8 mb-10 border border-gray-300 w-full">
              <ContactoVue class="m-3"/>
          </div>
          
          <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Estructuralismo, Edward Titchener y Wilhelm Wundt</h3>

          <p class="md:mb-4 mb-6">Los precursores de esta corriente son <b>Edward Titchener y Wilhelm Wundt</b>. El Estructuralismo consiste en descubrir <b>cuáles son los elementos básicos y fisiológicos de la consciencia y cómo interactúan para generar los procesos mentales.</b></p>

          <p class="md:mb-4 mb-6">Decía que como la mente es tan compleja, se debe estudiar por partes y con base en el método científico. Como era muy académica y rigurosa, dio paso al Funcionalismo.</p>
          <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Funcionalismo, William James</h3>

          <p class="md:mb-4 mb-6">El Funcionalismo ofrecía herramientas útiles para entender el funcionamiento y cómo resolver los problemas cotidianos. Su precursor fue William James.</p>

          <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Psicoanálisis, Sigmund Freud</h3>

          <p class="md:mb-4 mb-6">Sigmund Freud es conocido como el padre del Psicoanálisis, <b>una teoría que explora la influencia del inconsciente en el comportamiento.</b> Sus conceptos de la estructura de la personalidad (ello, yo y superyó) y las etapas del desarrollo psicosexual son fundamentales.</p>
          <p class="md:mb-4 mb-6">Freud introdujo conceptos revolucionarios <b>como el inconsciente, los mecanismos de defensa y las etapas del desarrollo psicosexual</b>, que han influido profundamente en la psicología y la cultura popular.</p>
          <p class="md:mb-8 mb-10">El modelo del inconsciente de Freud incluye estructuras como <b>el ello, el yo y el superyó</b>, que interactúan para formar la personalidad y dirigir el comportamiento humano.</p>

          <!-- Images -->
          <img src="/images/corrientes-psicologicas-para-entender-la-mente-humana.webp" alt="Cada corriente psicológica ha desarrollado enfoques terapéuticos específicos" class="md:mb-8 mb-10">

          <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Conductismo, John B. Watson y B.F. Skinner</h3>


          <p class="md:mb-4 mb-6">Watson es considerado el fundador del Conductismo, <b>una corriente que se centra en el estudio del comportamiento observable.  </b> Skinner, por su parte, es conocido por su teoría del condicionamiento operante, que explica cómo las consecuencias de una conducta afectan la probabilidad de que esta se repita.</p>
          <p class="md:mb-4 mb-6">En contraposición con el Psicoanálisis, <b>el Conductismo subraya la importancia de la investigación en elementos observables del comportamiento, la conducta.</b></p>
          <p class="md:mb-8 mb-10">Experimentos famosos del conductismo incluyen el experimento del pequeño Albert de Watson y las cajas de Skinner utilizadas para estudiar el condicionamiento operante en animales.</p>


          <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Humanismo, Carl Rogers y Abraham Maslow</h3>

          <p class="md:mb-4 mb-6"><b>Rogers desarrolló la terapia centrada en el paciente</b>, enfatizando la importancia de la empatía y la relación terapéutica, en tanto,<b>Abraham Maslow propuso la jerarquía de necesidades</b>, un modelo que describe cómo las personas buscan satisfacer una serie de necesidades escalonadas, desde las básicas (como alimento y seguridad) hasta las superiores, como la autorrealización. </p>
          <p class="md:mb-8 mb-10">Maslow argumentó que sólo cuando se satisfacen las necesidades más básicas, las personas pueden alcanzar su máximo potencial.</p>

          <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Cognitivismo, Jean Piaget</h3>

          <p class="md:mb-8 mb-10">Jean Piaget es uno de los psicólogos más influyentes en el campo del cognitivismo.<b>Su teoría del desarrollo cognitivo describe cómo los niños pasan por una serie de etapas cualitativamente diferentes</b> en su forma de pensar, desde la infancia hasta la adolescencia. Estas etapas incluyen la sensoriomotora, preoperacional, operacional concreta y operacional formal.</p>

          <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Psicología Gestalt, Kurt Koffka y Wolfgang Köhler</h3>

          <p class="md:mb-4 mb-6">Kurt Koffka y Wolfgang Köhler fueron dos de los principales representantes de la psicología Gestalt. Sus investigaciones y teorías han influido en áreas como la percepción, el aprendizaje y la resolución de problemas.</p>
          <p class="md:mb-4 mb-6"><b>La psicología Gestalt se centra en la percepción y la experiencia consciente</b>, argumentando que los seres humanos perciben los objetos como patrones completos y unificados en lugar de como la suma de sus partes.</p>
          <p class="md:mb-8 mb-10">Los principios de la psicología Gestalt incluyen la figura y fondo, la proximidad, la similitud, la continuidad y el cierre. Estos principios explican cómo las personas organizan la información visual en patrones coherentes.</p>

          <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Psicología positiva, Martin Seligman</h3>

          <p class="md:mb-8 mb-10">Martin Seligman es uno de los principales promotores de la psicología positiva. <b>Ha desarrollado conceptos como el bienestar subjetivo</b>, la teoría del bienestar PERMA (Positive Emotion, Engagement, Relationships, Meaning, and Accomplishment) <b>y la idea de que el florecimiento humano puede ser cultivado a través de la práctica de fortalezas personales y la construcción de relaciones positivas.</b></p>

          <!-- Images -->
          <img src="/images/corrientes-psicologicas-futuro-y-perspectivas.webp" alt="Las corrientes psicológicas se aplican en áreas como la salud mental, la educación, el desarrollo organizacional" class="md:mb-8 mb-10">

          <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Terapias y tratamientos basados en las diferentes corrientes psicológicas</h2>

          <p class="md:mb-8 mb-10">Cada corriente psicológica ha desarrollado enfoques terapéuticos específicos. Por ejemplo, el psicoanálisis ha dado lugar a la terapia psicoanalítica, el conductismo a la terapia de conducta, el cognitivismo a la terapia cognitivo-conductual (TCC) y el humanismo a la terapia centrada en el cliente.</p>
    
          <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Relevancia en contextos actuales</h2>

          <p class="md:mb-8 mb-10">En el contexto actual,<b>las corrientes psicológicas se aplican en áreas como la salud mental, la educación, el desarrollo organizacional y la resolución de conflictos.</b>Los psicólogos utilizan estos enfoques para ayudar a las personas a superar dificultades, mejorar su bienestar y alcanzar su potencial.</p>

          <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Comparación entre las corrientes psicológicas</h2>
          <p class="md:mb-8 mb-10">Comparar las diferentes corrientes psicológicas es esencial para entender sus fortalezas, limitaciones y cómo se complementan entre sí en la práctica y la investigación.</p>

          <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Universidad del Valle de México (UVM)</h3>
          <p class="md:mb-8 mb-10">La Universidad del Valle de México cuenta con una oferta educativa que busca adaptarse a las tendencias laborales.</p>
          
          <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Diferencias y similitudes</h3>
          <p class="md:mb-8 mb-10"><b>Cada corriente psicológica tiene su enfoque único y sus métodos específicos</b> para estudiar y tratar el comportamiento humano. 
          Por ejemplo, el psicoanálisis se centra en el inconsciente y las experiencias infantiles, mientras que el conductismo se enfoca en el comportamiento observable y medible. 
          <b>A pesar de sus diferencias, algunas corrientes comparten similitudes</b>; por ejemplo, tanto el humanismo como la psicología positiva enfatizan el potencial humano y el bienestar.</p>

          <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Futuro de las corrientes psicológicas</h2>
          <p class="md:mb-8 mb-10">El campo de la Psicología continúa evolucionando, y es probable que surjan nuevas corrientes y teorías que amplíen nuestra comprensión del comportamiento humano.</p>


          <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Tendencias emergentes</h3>
          <p class="md:mb-8 mb-10"><router-link to="/blog/aprende/tendencias/tendencias-en-psicologia-2024-tecnologia-salud-mental"  class="text-htwo">Algunas de las tendencias emergentes en la Psicología </router-link>incluyen la neurociencia cognitiva, que combina la psicología y la neurociencia para entender cómo el cerebro influye en el comportamiento, y la Psicología cultural, que examina cómo la cultura afecta la mente y el comportamiento.</p>

          
          <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Investigaciones actuales</h3>
          <p class="md:mb-8 mb-10">Las investigaciones actuales en Psicología están explorando áreas como la genética del comportamiento, el impacto de la tecnología en la salud mental y las intervenciones basadas en la evidencia para mejorar el bienestar.</p>

          <p class="md:mb-8 mb-10">Estas investigaciones están sentando las bases para nuevas teorías y prácticas que pueden revolucionar a la Psicología en el futuro.</p>

          <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Conclusión</h2>
          <p class="md:mb-4 mb-6">En resumen, <b>las diferentes corrientes y teorías psicológicas han proporcionado marcos valiosos para entender el comportamiento humano y desarrollar tratamientos efectivos.</b> Desde el psicoanálisis hasta la psicología positiva, cada enfoque ha contribuido a la evolución de la Psicología como disciplina.</p>

          <p class="md:mb-8 mb-10">Con el continuo avance de la investigación y la práctica, es probable que sigamos viendo nuevas y emocionantes corrientes que expandan aún más nuestro conocimiento del comportamiento humano.</p>

          
          <p class="text-htwo text-left mb-20">Explora más: <a href="https://humanitas.edu.mx/" target="_blank" class="text-htwo">Universidad Humanitas</a></p>
          <div class="flex justify-between md:mb-8 mb-10">
            <LikesVue :articleID="code"/>
            <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
          </div>
        </div>
        <div class="flex justify-start items-start">
          <img src="/images/barra-art.png" class="w-[15%]">
        </div>
      </div>
      <SigArt :ant="true" :linkAnt="'/academico/educacion-continua/que-es-un-seminario-todo-lo-que-quieres-saber'" :linkSig="'/academico/licenciatura/arquitectura-licenciatura-o-ingenieria-te-decimos'" :sig="true"/>
      <ConComentarios :article="code" class="pb-12"/>
      <Comentarios :article="code" class="pb-12"/>
    </div>
  </div>
</template>

<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import ContactoVue from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue';
import VideoClipVue from '../../pagesComponents/videoClip.vue';
import SigArt from '../../pagesComponents/SigArt.vue';

export default {
  components: { Comentarios, ConComentarios, ContactoVue, LikesVue, ShareP, VideoClipVue, SigArt},
  mounted() {
    // Agregar metadatos Open Graph al DOM cuando el componente se monta
    //this.setOpenGraphMetaTags();
  },
  data(){
      return {
          code: '0043-ART-ACADEMICO-LICENCIATURA-130624',
          ruta: '/academico/licenciatura/principales-corrientes-teorias-psicologicas-modelos-y-representantes',
          title: 'Descubre cuáles son las principales corrientes y teorías psicológicas',
          image: 'https://blog.humanitas.edu.mx/images/Costo-y-requisitos-estudiar-maestria.jpg',
          text: 'Exploramos las principales corrientes y teorías psicológicas, sus modelos y representantes destacados. Ideal para ti que quieres saber más.'
      }
  },
  methods: {
    setOpenGraphMetaTags() {
      // Crear elementos meta para los metadatos Open Graph
      const ogUrl = document.createElement('meta');
      ogUrl.setAttribute('property', 'og:url');
      ogUrl.content = 'https://blog.humanitas.edu.mx/academico/licenciatura/principales-corrientes-teorias-psicologicas-modelos-y-representantes';

      const ogType = document.createElement('meta');
      ogType.setAttribute('property', 'og:type');
      ogType.content = 'article';

      const ogTitle = document.createElement('meta');
      ogTitle.setAttribute('property', 'og:title');
      ogTitle.content = this.title;

      const ogDescription = document.createElement('meta');
      ogDescription.setAttribute('property', 'og:description');
      ogDescription.content = this.text;

      const ogImage = document.createElement('meta');
      ogImage.setAttribute('property', 'og:image');
      ogImage.content = this.image;

      // Agregar los elementos meta al head del documento
      document.head.appendChild(ogUrl);
      document.head.appendChild(ogType);
      document.head.appendChild(ogTitle);
      document.head.appendChild(ogDescription);
      document.head.appendChild(ogImage);
    }
  }
}
</script>

<style>

</style>