<script lang="js">
  import Swal from 'sweetalert2';
  import axios from 'axios';
  import { SolicitudBlog } from '@/main';
  

  export default {
    props: {
      article: String,
    },
    data() {
      return {
        toast : Swal.mixin({
                      toast: true,
                      position: 'top-end',
                      width: 500,
                      padding: '1em',
                      showConfirmButton: false,
                      timerProgressBar: true,
                      timer: 4000,
                      icon: 'error',
                      iconColor: '#881912'
                  }),
        texto: '',
        nombre: '',
        email: '',
        telefono: '',
        tokenizer: '',
        form: 'Comentarios',
        selectedCountry: '+52',
        ladas: [
                { codigo: "mx", lada: "+52", nombre: "México" },
                { codigo: "us", lada: "+1", nombre: "Estados Unidos" },
                { codigo: "ca", lada: "+1", nombre: "Canadá" },
                { codigo: "gb", lada: "+44", nombre: "Reino Unido" },
                { codigo: "de", lada: "+49", nombre: "Alemania" },
                { codigo: "fr", lada: "+33", nombre: "Francia" },
                { codigo: "es", lada: "+34", nombre: "España" },
                { codigo: "it", lada: "+39", nombre: "Italia" },
                { codigo: "ar", lada: "+54", nombre: "Argentina" },
                { codigo: "br", lada: "+55", nombre: "Brasil" },
                { codigo: "cl", lada: "+56", nombre: "Chile" },
                { codigo: "co", lada: "+57", nombre: "Colombia" },
                { codigo: "pe", lada: "+51", nombre: "Perú" },
                { codigo: "ve", lada: "+58", nombre: "Venezuela" },
                { codigo: "ec", lada: "+593", nombre: "Ecuador" },
                { codigo: "bo", lada: "+591", nombre: "Bolivia" },
                { codigo: "py", lada: "+595", nombre: "Paraguay" },
                { codigo: "uy", lada: "+598", nombre: "Uruguay" },
                { codigo: "pa", lada: "+507", nombre: "Panamá" },
                { codigo: "cr", lada: "+506", nombre: "Costa Rica" },
                // Agrega más países según sea necesario
        ],
        preferences: {
          news: false,
          conditions: false
        },
        malasPalabras: [
              "Abadol", "Abejarruca", "Abejarruco", "Aberracion", "Aberración", "Aberrante", "Ablanda brevas", "Abollao", "Aborto", "Abrazafarolas",
              "Abrillantaglandes", "Abulto", "Afilasables", "Aguafiestas", "Alacrán", "Alcachofo", "Alcornoke", "Alcornoque", "Alela", "Alelada",
              "Alelado", "Alelao", "Alfeñique", "Alicate", "Alimaña", "Almojonao", "Almorrana", "Alobao", "Alsarrabo", "Ambercocat", "Amochao",
              "Amorfo", "Anacoluto", "Analfabestia", "Animal de acequia", "Anormal", "Antancanciao", "Apollardao", "Apollardar", "Aragán",
              "Arracacho", "Arrastramantas", "Arrastrazapatillas", "Arreplegat", "Arrollapasto", "Artosopas", "Asalta asilos", "Asaltacunas",
              "Asaltapozos", "Ase assolellat", "Aspamao", "Asustatrenes", "Atonta", "Atontada", "Atontado", "Atontao", "Atroplella platos",
              "Baballo", "Babau", "Baboso", "Bacin", "Bacterio", "Badanas", "Bailabainas", "Bailaferias", "Bajoca", "Bambol", "Bandarra",
              "Barjaula", "Barriobajero", "Bastarda", "Bastardo", "Bebecharcos", "Bebesinsed", "Bellaco", "Bergante", "Berzotas", "Besugo",
              "Betzol", "Bigardo", "Bigotezorra", "Biruta", "Bleda", "Boba", "Bobo", "Bobolaverga", "Bobomierda", "Boborremoto", "Bocabuzón",
              "Bocachancla", "Bocachocho", "Bocafiemo", "Boiano", "Bolascombro", "Boludo", "Bordegàs", "Borinot", "Borracha", "Borracho",
              "Borrico", "Bosses tristes", "Bosuso", "Botabancals", "Boñiga", "Brasas", "Brincacepas", "Brètol", "Bufavidres", "Bujarra",
              "Bujarron", "Bujarrón", "Bulebule", "Burreras", "Burro de set soles", "Burxa orelles", "Busagre", "Buscabregues", "Butarut",
              "Butrill", "Buzefalo", "Cabestro", "Cabeza alberca", "Cabeza de chorlito", "Cabezabuque", "Cabezakiko", "Cabezalpaca",
              "Cabezantorcha", "Cabezarrucho", "Cabezon", "Cabezón", "Cabron", "Cabrona", "Cabronazo", "Cabrón", "Cacho mierda",
              "Cachomierda", "Cade de fava", "Cafre", "Caga bandurrias", "Cagablando", "Cagadubtes", "Cagaestacas", "Cagalindes",
              "Cagamandurries", "Cagamandúrries", "Caganio", "Cagapoc", "Cagaportales", "Cagarro", "Cagarruta", "Cagarrutas", "Caguiñas",
              "Caharrán", "Calamidad", "Calenturas", "Calvo", "Calzonazos", "Camanduleiro", "Camastron", "Canalla", "Candonga", "Cansa almas",
              "Cansaliebres", "Cansina", "Cansino", "Cantamañanas", "Cap d'ase", "Cap de fava", "Cap de polla", "Cap de ruc", "Cap de suro",
              "Capat mental", "Capdepesol", "Capdetrons", "Capillita", "Capsigrany", "Capsot", "Capulla", "Capullo", "Capuyo",
              "Cara de cona", "Carabassa", "Caracabello", "Caracandao", "Caracartón", "Carachancla", "Caracoño", "Caracul", "Caraculo",
              "Caraespatula", "Caraestaca", "Caraestufa", "Caraflema", "Carahogaza", "Carajaula", "Carajote", "Carallot", "Caramierda",
              "Caramirlo", "Caranabo", "Carantigua", "Carapan", "Carapapa", "Carapena", "Caraperro", "Carapolla", "Caraputa", "Carasapo",
              "Carasuela", "Caratapena", "Cardat del cap", "Carnuz", "Cascavalero", "Castroja", "Cateto", "Cavallot", "Caxomierda",
              "Cazurra", "Cazurro", "Cebollino", "Cenizo", "Cenutrio", "Ceporro", "Cercho", "Cercopiteco", "Cerillita", "Cero a la izquierda",
              "Chafacharcos", "Chafalotodo", "Chalada", "Chalado", "Chalao", "Chamba na cona", "Chanflameja", "Chavacana", "Chavacano",
              "Chavea", "Cheo de moscas", "Chichon", "Chimpa", "Chimpajosé", "Chimpamonas", "Chimpin", "Chingada", "Chorlito", "Chosco",
              "Chupacables", "Chupacharcos", "Chupaescrotos", "Chupamela", "Chupamingas", "Chupasangre", "Chupatintas", "Chupóptero",
              "Chusmón", "Cierrabares", "Ciervo", "Cimborrio", "Cipote", "Cobachero", "Cobarde", "Coentrao", "Coix de cervell",
              "Colla de feixistes", "Collonera", "Comebolsas", "Comechapas", "Comemierda", "Comemierdas", "Comemocos", "Comepollas",
              "Comeprepucios", "Comerreglas", "Conacho", "Corgao", "Cornudo", "Correveidile", "Cretina", "Cretino", "Crollo", "Cuerdalpaca",
              "Cuerpoescombro", "Cui de caronge", "Culo panadera", "Culoalberca", "Culotrapo", "Curmaco", "Desaborio", "Descalzaputas",
              "Descastat", "Descerebrado", "Descosealpargatas", "Desgracia", "Desgraciada", "Desgraciado", "Desgraciao", "Despreciable",
              "Destiñe rubias", "Desvarata bailes", "Desvirgagallines", "Don nadie", "Donnadie", "Down", "Durdo", "Empaellao", "Empana",
              "Empanao", "Empujamierda", "En Garrovillas", "Enderezaplátanos", "Energumena", "Energumeno", "Energúmena", "Energúmeno",
              "Engaña baldosas", "Engendro", "Enjuto", "Ennortao", "Enrreda bailes", "Enze", "Escoria", "Escuerzo", "Escuincle", "Esgarramantas",
              "Esjarramantas", "Esmirriao", "Espantacriatures", "Espantajo", "Espanyaportes", "Esperpento", "Estas como un manojo de vergas",
              "Estorbo", "Estripa gasones", "Estupida", "Estupido", "Estòtil", "Estúpida", "Estúpido", "Eunuco", "Exelol", "Facha", "Facinerosa",
              "Facineroso", "Faino ti lanjran", "Falfañote", "Fanegas", "Fanequero", "Fanicerós", "Fantoche", "Farfoya", "Fariseo", "Farraguas",
              "Farsante", "Fato", "Fazañeiro", "Fea", "Feminazi", "Feo", "Feodoble", "Fervemasber", "Feto", "Figa tova", "Figa-molla", "Figaflor",
              "Figamustia", "Fill de monja", "Fitigote", "Follacabras", "Follagatos", "Follácaros", "Forrotimple", "Fune", "Funesto", "Futrul",
              "Gabacho", "Gallimarsot", "Galocho", "Gamarús", "Ganapán", "Gandul", "Gandula", "Gandúl", "Gansa", "Ganso", "Garibolo", "Garraibat",
              "Garroso", "Garrulo", "Gaznapiro", "Gañan", "Gañán", "Gili", "Gilicoño", "Gilipichi", "Gilipichis", "Gilipipas", "Gilipollas",
              "Gilipoyas", "Gilipuerta", "Golismero", "Gonorrea", "Gordinflón", "Gordo", "Gorrino", "Gra de pus", "Granuja", "Grosera", "Grosero",
              "Guarnío", "Guarral", "Guarrilla", "Gumias", "Gurmaco", "Gusano", "Hartosopa", "Hartosopas", "Hdp", "Hdtpm", "Hijo de puta",
              "Hijo de", "Hijo de chacal", "Hijo de cura", "Hijo de hiena", "Hijo de obispo", "Hijueputa", "Hilh de gaudumela", "Horripilante",
              "Huelebragas", "Huevon", "Huevón", "Idiota", "Ignorante", "Imbecil", "Imbécil", "Impertinente", "Inculto", "Inelegante", "Infraser",
              "Innortao", "Inoperante", "Insulso", "Inutil", "Inútil", "Jartible", "Jodido", "Julai", "Lacazan", "Ladilla", "Lagumán", "Lamecharcos",
              "Lamecirios", "Lameculos", "Lamehuevos", "Lameplatos", "Lamerajas", "Lameñordos", "Langran", "Langrán", "Lapa", "Lareta", "Larry",
              "Lechuguino", "Lechuzo", "Lela", "Lelo", "Lento", "Lercho", "Lerda", "Lerdo", "Ligera de cascos", "Ligero de cascos", "Llepafils",
              "Llimpiatubos", "Lumia", "Machirulo", "Machorra", "Machote", "Machuno", "Maganto", "Mahara", "Mahareta", "Majadero", "Majarón",
              "Mal pelat", "Malafoya", "Malandra", "Malandrin", "Malandro", "Malasangre", "Maleduca", "Maleducada", "Maleducado", "Maleducao",
              "Malfollada", "Malnacida", "Malnacido", "Malparida", "Malparido", "Mamacallos","mamada","mamado", "Mamahostias", "Mamarracho", "Mameluco", "Mamon",
              "Mamona", "Mamporrero", "Mamón", "Mangarranas", "Mangurrian", "Mangurrián", "Manos Gachas", "Manos de arbol", "Mantecas", "Margarito",
              "Maricón", "Marimacho", "Mascachapas", "Mascaxapas", "Mastuerzo", "Masturbamulos", "Masturbaperros", "Masturbavacas", "Matao",
              "Mataperros", "Mazaroco", "Me cago en tu madre", "Me cago en tu padre", "Me cago en tu puta madre", "Me cago en tu puto padre",
              "Me cago en tus muertos", "Meapilas", "Media mierda", "Meketrefe", "Melindrós", "Melona", "Melón", "Mema", "Membrillo", "Memo",
              "Mendrugo", "Mentecato", "Mequetrefe", "Merda", "Merda d'oca", "Merda seca", "Merdaseca", "Merdellón", "Merluzo", "Meuca",
              "Microbi", "Mierda", "Mierdaseca", "Milhomes", "Mindundi", "Mitjamerda", "Modorro", "Mofeta", "Mojigato", "Monchi", "Mondongo",
              "Moniato", "Monigote de feria", "Monstrenco", "Monstruenco", "Monte de esterco", "Morlaco", "Morral", "Morroescopeta", "Morroesfinge",
              "Morroestufa", "Morrofiemo", "Morroperca", "Morroputa", "Morros de haba", "Muerdealmohadas", "Muggle", "Mugrosa", "Mugroso", "Napbuf",
              "Necia", "Necio", "Niñato", "Notas", "Nugallan", "Oligofrenico", "Oligofrénico", "Ollosdeboto", "Osobuco", "Otaco", "Otako",
              "Oxigenado", "Pachucho", "Paletos", "Pamplinas", "Pancetones", "Panoli", "Pantana", "Pantuflo", "Papanatas", "Papayo", "Papón",
              "Papusa", "Pardilla", "Pardillo", "Parguela", "Parguelas", "Parguelo", "Parguelón", "Parguela", "Pargüela", "Pargüelo",
              "Pargüelón", "Parrapapa", "Parrulo", "Pasmado", "Patochada", "Patocho", "Pavarraco", "Pavo", "Pavon", "Pavonearse", "Payaso",
              "Pechofrio", "Pedante", "Pedazo de mamón", "Pedazo de mierda", "Pedazo de panoli", "Pedazo de sandía", "Pegameflor", "Pegarapos",
              "Pego", "Pelele", "Pelengas", "Pelotas", "Pelotari", "Pendeja", "Pendejo", "Percebe", "Perico", "Perjurio", "Perro", "Perroflauta",
              "Pervertido", "Pez de colores", "Picha", "Pichabrava", "Pichacorta", "Pichacorto", "Pichabrava", "Pichagüevo", "Pichaimar",
              "Pichapobre", "Pichatecla", "Pichateclazos", "Pichaveneno", "Pichazo", "Pichibarra", "Pichimierda", "Pichintunado", "Pichintún",
              "Pichirrino", "Pichita", "Pichitonto", "Pichón", "Picoleto", "Picoletas", "Picón", "Pidolas", "Pijavera", "Pijipandi", "Pilindrajo",
              "Piltrafa", "Piltrafas", "Pimpo", "Pimpollo", "Pincaflores", "Pinchacacas", "Pinchaglobos", "Pinchaputas", "Pinchaventos",
              "Pinchochamorro", "Pinchon","pinga", "Pingo", "Pingorota", "Piojoso", "Piojuelo", "Pipirisnais", "Pipollo", "Pipón", "Pirao", "Pirata",
              "Piruja", "Pirulo", "Pisa mojones", "Pisaapretados", "Pisacacas", "Pisalotodo", "Pisamierda", "Pisapatatas", "Pisaverdes",
              "Pisaverdes de verga", "Piscinas", "Pisotapapeles", "Pistachudo", "Pitarte", "Pito", "Pitopausia", "Pitorra", "Pitoste", "Pitoño",
              "Plasta", "Platanera", "Plomo", "Polichinela", "Polla", "Pollagorda", "Pollajacara", "Pollatonta", "Pollavieja", "Pollo de cloaca",
              "Pollo sin cabeza", "Ponzonias", "Porquería", "Porreta", "Porretas", "Porreta", "Porreto", "Porreta", "Potorrero", "Potorro", "Pucelano",
              "Puerca", "Puerco", "Puercos", "Puerka", "Puerkas", "Puto", "Pulguero", "Puta", "Putada", "Putaflautas", "Putakumbre", "Putazo",
              "Puteiro", "Puto", "Puton", "Putrefacto", "Putrida", "Putrido", "Puuuuto", "Puzas de la vida", "Quemacoños", "Quincalla",
              "Rabaco", "Rabalarga", "Rabán", "Rabán", "Rabudo", "Rabudo", "Rafaeles", "Raimunda", "Rajael", "Rajao", "Rasputín", "Rastrero",
              "Rastuza", "Rataplán", "Rataputa", "Rebaño de camellas", "Rebasudo", "Reberendo carajo", "Reberendo carajo", "Reberendo pendejo",
              "Reberendo pendejo", "Reberendo tarado", "Reberendo tarado", "Rebuznaperas", "Recachapapas", "Recambió", "Recoge colillas",
              "Recogecondones", "Redomado", "Regordete", "Rematada", "Rematado", "Repanchingado", "Repelente", "Repugnante", "Resquemor",
              "Retardado", "Ridiculo", "Ridículo", "Rocazurras", "Rodaja", "Rodajón", "Rodamon", "Rolin", "Rompecojones", "Rompeculos",
              "Rompepoyas", "Rompetechos", "Rompetubos", "Ronquete", "Rubicundo", "Ruina", "Ruinero", "Rumana", "Rumano", "Sacajuegos",
              "Sacamantecas", "Sacañizas", "Sagabardon", "Sagüeño", "Salamero", "Salchichon", "Salido", "Sangana", "Sanganos",
              "Sanguinario", "Sarasa", "Sarasa", "Sargento chusquero", "Sarnoso", "Sobarate", "Sobrancero", "Sobrancelha", "Sobrancelha",
              "Sobrasa", "Sobrasada", "Sobreasada", "Sobreasado", "Sobregrasa", "Sobregrasas", "Sobresada", "Sobresadum", "Sobrino de",
              "Socarrón", "Soficón", "Solochorizos", "Soplagaitas", "Soplapitos", "Soplavelas", "Sorbenabo", "Sotacaballo", "Sotacaballo",
              "Sotacaballo", "Sotacantos", "Sotacapaz", "Sotacapaces", "Sotacara", "Sotacaras", "Sotacaspa", "Sotacaspos", "Sotacésped",
              "Sotachichos", "Sotacollas", "Sotacuerda", "Sotaflores", "Sotagarbillo", "Sotagrajo", "Sotahierro", "Sotajuego", "Sotamadre",
              "Sotamierda", "Sotamoraco", "Sotamuerto", "Sotapapas", "Sotaplanos", "Sotaplumas", "Sotaquesos", "Sotarranas", "Sotarro",
              "Sotatercios", "Sotaterrón", "Sotatieso", "Sotatrastos", "Sotatruños", "Sotavacas", "Sotazas", "Sotazo", "Sotillas", "Sotobarro",
              "Sotocapa", "Sotocasco", "Sotocoleto", "Sotocomún", "Sotocris", "Sotogangas", "Sotojueces", "Sotolodos", "Sotolongo", "Sotomil",
              "Sotonabos", "Sotonó", "Sotorraja", "Sotorrojos", "Sotovinos", "Sotovinos", "Subnornales", "Subnormal", "Subnornales", "Sueron",
              "Tarambana", "Tarado", "Tarugo", "Tasajo", "Te cagas", "Te cagas en tu puta madre", "Te cagas en tus muertos", "Te chingo", "Te jodes",
              "Tetepereza", "Tio de la vara", "Tio de la vara", "Titiritero", "Toallín", "Todas calvas", "Tolai", "Tonto", "Tonto el que lo lea",
              "Torpe", "Torreznos", "Tragachicos", "Tragaldabas", "Tragaleches", "Tragamierda", "Tragantúa", "Tragaperras", "Tragaquinielas",
              "Tragalibros", "Tragalumbres", "Tragasables", "Tragasables", "Tragatorijas", "Tragatorronchos", "Tragasandías", "Tragatintas",
              "Tragatorijas", "Tragatorronchos", "Tragavainas", "Tragaviaños", "Tragaventanas", "Tragaverduras", "Tragavinos", "Tragavivas",
              "Tres peludos", "Tribilín", "Trincafarlopa", "Troglodita", "Tronado", "Tronco", "Tronco", "Tuercebotas", "Tuerceojos", "Tuerceorejas",
              "Tunante", "Turra", "Turras", "Turrón", "Tuturutú", "Tuturú", "Vaca lechera", "Vacasflacas", "Vacilón", "Vago", "Vagoneta",
              "Vahido", "Vajilla", "Vaselina", "Vaso de agua", "Vaso de leche", "Vegana", "Vegano", "Vellocino", "Vergajazo", "Vergajo", "Vergón",
              "Vandalo", "Vendehumos", "Verga", "Vidroid", "Vigardo", "Votante del PP", "Vuelcalitros", "Vándalo","Xafandino", "Xafarder", "Xafaxarcos", "Xarrupaescrots", "Xingaflautas", "Xisgarabis", "Xupaxarcos", "Yanflameja", "Yaro", "Yiyon","Zaborrero", "Zafio", "Zalapastrán", "Zamacuco", "Zamarraco", "Zamarro", "Zambergo", "Zampabollos", "Zanahorio", "Zangüango", "Zangüángano", "Zarandajo", "Zarrapastrosa", "Zarrapastroso", "Zarrio",
              "Zascandil", "Zopenco", "Zoquete", "Zornicalo", "Zorra", "Zote", "Zumayo", "Zumbado", "Zumbao","Zurcemierdas", "Zurmaco", "Zurremierdas" 
        ]
      };
    },
    methods: {
      /**
* @param {string} countryCode
*/
      getFlagUrl(countryCode) {
            return `https://flagcdn.com/64x48/${countryCode.toLowerCase()}.png`;
      },

      /**
* @param {string} texto
*/
      filtrarPalabras(texto) {
          const textoNormalizado = texto.toLowerCase();
          return this.malasPalabras.some(palabra => textoNormalizado.includes(palabra.toLowerCase()));
      },

      /**
* @param {string} icon
* @param {string} title
* @param {string} text
*/
      mostrarAlerta(icon, title, text) {
        // @ts-ignore
        Swal.fire({ 
          icon: icon,
          title: title,
          text: text,
          confirmButtonText: 'Entendido',
          didOpen: () => {
            const confirmButton = Swal.getConfirmButton();
            // @ts-ignore
            confirmButton.style.backgroundColor = '#881912';  
            // @ts-ignore
            confirmButton.style.color = 'white';
            // @ts-ignore  
            confirmButton.style.border = 'none';
            // @ts-ignore  
            confirmButton.style.borderRadius = '0';
          }
        });
      },

      /**
* @param {string} icon
* @param {string} title
* @param {string} text
*/
      mostrarTostada(icon, title, text) {
        // @ts-ignore
        this.toast.fire({
          icon: icon,
          title: title,
          text: text,
        });
      },

      executeRecaptcha() {
        //@ts-ignore
        grecaptcha.enterprise.execute('6Ldd0x0oAAAAAAaPVjMnVirgyfT6qahpksCqN0UX', { action: 'submit_lead' })
        // @ts-ignore
        .then((token) => {
        this.tokenizer = token;
        })
        // @ts-ignore
        .catch((error) => {
        console.error('Error al ejecutar reCAPTCHA:', error);
        });
      },

      currentUrl() {
        return window.location.href;
      },

      handleEmailChange() {
        if (!this.tokenizer) {
            this.executeRecaptcha();
        }
      },

      submitForm() {
        const formData = new FormData();
        const condimento = this.preferences.news
        formData.append('nombre', this.nombre);
        formData.append('email', this.email);
        formData.append('apellidos', '');
        formData.append('telefono', this.selectedCountry + this.telefono);
        formData.append('newsletters', condimento+'');
        formData.append('g-recaptcha-response',this.tokenizer)
        formData.append('form', this.form)
        formData.append('url', this.currentUrl())
        formData.append('comentario', this.texto)
        formData.append('article', this.article)
        axios.post(SolicitudBlog, formData, {
        // axios.post(Solicitud, formData, {
            headers: {'Content-Type': 'multipart/form-data',},
            responseType: 'json', // Asegúrate de incluir esta línea
            }).then((response) => {
                // if (response.data.error === false) {
                    //@ts-ignore
                    console.log(response);
                    // if (dataLayer) {
                    //     //@ts-ignore
                    //     dataLayer.push({
                    //     event: 'generate_lead',
                    //     email: this.email,
                    //     career: this.selectedOffer,
                    //     });
                    // }
                    this.mostrarAlerta('success', 'Comentario enviado', 'El comentario se ha enviado correctamente.');
                    this.nombre = '';
                    this.apellidos = '';
                    this.email = '';
                    this.telefono = '';
                    this.texto = '';
                    this.tokenizer = '';
                    this.preferences.conditions = false;
                    this.preferences.news = false;
                // } else {
                //     Swal.fire({
                //         text: `${response.data.descripcion}`,
                //         icon: "error",
                //         iconColor: '#881912',
                //         showConfirmButton: false,
                //         timer: 4000
                //     });
                //     this.tokenizer='';
                // }
        // @ts-ignore
        }).catch( (error) => console.log('error', error) );

      },

      analizarTexto() {
        if(this.validarDatos()){
          if(this.texto.trim() !== ""){
            if (this.filtrarPalabras(this.texto)) {
              this.mostrarTostada('error', 'Palabra no permitida', 'El comentario contiene una palabra no permitida.');
            } else {
              if(this.preferences.conditions){
                this.submitForm()
                // Aquí podrías enviar el comentario a tu servidor o realizar otras acciones necesarias
              } else {
                this.mostrarTostada('error','Aviso de privacidad', 'Debes estar de acuerdo con el aviso de privacidad.')
              }
            }
          } else {
            this.mostrarTostada('error', 'Comentario Vacio', 'No puedes enviar un comentario vacio.');
            console.log(this.texto.trim() !== "")
          }
        }
      },

      validarNombre() {
        if (!this.nombre) {
            this.nombreError = 'El nombre es requerido.';
        } else if (!/^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s]{1,90}$/.test(this.nombre)) {
            this.nombreError = 'Ingrese un nombre válido (máximo 30 caracteres).';
        } else {
            this.nombreError = '';
        }
      },

        /**
* @param {string} mensaje
*/
        mostrarErrorSweetAlert(mensaje) {
          this.toast.fire({
          icon: 'error',
          title: 'Validación incorrecta',
          text: mensaje,
          });
        },

        validarDatos(){
          if(!/^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s]{1,90}$/.test(this.nombre)){
              this.mostrarErrorSweetAlert('Ingrese un nombre válido.');
          } else {
              if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email)){
                  this.mostrarErrorSweetAlert('Ingrese un correo electrónico válido.')
              } else {
                  if(!/^\d{10}$/.test(this.telefono)){
                    this.mostrarErrorSweetAlert('Ingrese un número de teléfono válido (10 dígitos).');
                  } else {
                    if (!/^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s,.:;_\-\(\)]{1,120}$/.test(this.texto)){
                      this.mostrarErrorSweetAlert('El comentario contiene un carácter no permitido.');
                    } else {
                        return true
                    }
                  } 
              }
          }
          return false;
        },
    }
};
</script>

<template>
    <section class="flex flex-col items-center justify-center z-30 w-full bg-gray-100 lg:mx-0 px-4 pb-12">
      <div class="flex flex-col items-center w-full justify-center z-30">
        <div class="flex flex-col justify-start w-full max-w-4xl lg:max-w-[1080px] z-30">
          <div class="flex flex-col my-12 w-full z-30">
            <div class="flex flex-col md:w-[40%] w-[60%]">
              <p class="text-left pl-0 uppercase text-black text-[16px] md:text-[20px]" style="font-family: 'Helvetica Neue Regular', sans-serif;">únete a la</p>
              <h2 class="text-left pl-0 uppercase text-hone font-bold text-[24px] md:text-[32px] lg:text-[34px]" style="font-family: Georgia, serif;">Conversación</h2>
              <hr class="mb-4 border-[#797979] lg:w-[320px] md:w-[282px] w-[240px]">
            </div>
            <p class="text-left italic mb-6 text-[14px] text-[#797979]">Tu e-mail y tel&eacute;fono no ser&aacute;n publicados.<br> Campos requeridos *</p>
            <div class="flex flex-col login-box form-container relative z-30">
              <form autocomplete="on" @submit.prevent="">
                <div class="lg:flex justify-between mb-6 w-full">
                  <div class="user-box lg:w-1/4 mb-3 lg:mb-0">
                      <input v-model="nombre" type="text" id="name" name="nombre" required maxlength="30" class="">
                      <label for="nombre" class="italic">Nombre*</label>
                  </div>
                  <div class="user-box lg:w-1/4 mb-3 lg:mb-0">
                      <input v-model="email" type="text" id="email" name="email" @change="handleEmailChange" required class="">
                      <label for="email" class="italic">E-mail*</label>
                  </div>
                  <div class="user-box lg:w-1/4 mb-3 lg:mb-0">
                    <div class="flex text-[12px]">
                        <select v-model="selectedCountry" class="mr-1 rounded-none" style="width: 35%;padding: 10px 10px;font-size: 13px;color: #797979;margin-bottom: 8px;border: none;border-bottom: 1px solid #797979;outline: none;background: transparent;">
                            <option v-for="country in ladas" :value="country.lada" :key="country.codigo" class="rounded-none text-[14px]">
                                <img :src="getFlagUrl(country.codigo)"/> {{ country.lada }} 
                            </option>
                        </select>
                        <div>

                        </div>
                        <div class="w-2/3">
                            <input v-model="telefono" id="telefono" type="tel" name="telefono" required maxlength="10" class="">
                            <label for="telefono" class="italic" style="top: -24px;left: 0;color: #797979;font-size: 12px;">N&uacute;mero de Teléfono*</label>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col mb-4 justify-start">
                  <label for="comentario" class="uppercase mb-1 text-[14px] text-left text-[#797979]">comentario:*</label>
                  <textarea v-model="texto" id="comentario" rows="4" cols="40" maxlength="120" class="border rounded-none p-2.5 w-full bg-white border-htwo"></textarea>
                </div>
                <div class="flex justify-between items-center w-full">
                  <div class="lg:flex lg:justify-between lg:w-[60%] text-sm">
                    <div class="flex items-center lg:mb-0 mb-2">
                      <input type="checkbox" v-model="preferences.news" class="cursor-pointer bg-white accent-htwo rounded-none w-5 h-5 border-gray-300 focus:ring-[rgb(82,4,0)] focus:ring-1 mr-2"> 
                      <label class="text-[#797979] italic text-left">
                        Mantente al día con nuestros últimos artículos.
                      </label>
                    </div>
                    <div class="flex lg:items-center">
                      <input type="checkbox" v-model="preferences.conditions" class=" cursor-pointer accent-htwo rounded-none w-5 h-5 bg-white border-gray-300 focus:ring-[#520400] focus:ring-1 mr-2"> 
                      <label class="text-[#797979] text-left  italic">
                        He le&iacute;do y acepto el <a href="https://humanitas.edu.mx/aviso-de-privacidad" target="_blank" class="text-[#797979] hover:text-[#c4a96c]">Aviso de privacidad</a>.
                      </label>
                    </div>
                  </div>
                  <div class="flex">
                    <button @click.prevent="analizarTexto" class="text-white hover:text-black focus:border-htwo focus:outline-none font-serif md:text-[18px] lg:text-[18px] flex items-center justify-center rounded-none bg-hone transition duration-300 hover:bg-white border-hone md:w-[140px] h-[45px]">Publicar</button>
                  </div>
                </div>
              </form>
            </div>
          </div>      
        </div>
      </div>
    </section>
</template>
  

<style scoped>
.login-box {
    max-width: 1200px;
    margin: 0 ;
    padding: 0px;
    box-sizing: border-box;
    border: black;
    border-radius: 10px;
    background: transparent;
    z-index: 1;
    
    
  }

  .checkbox-custom {
    transform: scale(2); /* Ajusta el valor según sea necesario para aumentar el tamaño */
  }

  

  .login-box h2 {
    margin: 0 0 0px;
    padding: 0;
    color: transparent;
    text-align: center;
  }

  .login-box .user-box {
    position: relative;
    z-index: 1;
  }

  .login-box .user-box i {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 10px;
    color: #797979;
  }

  .form-container {
    position: relative;
    z-index: 1;
  }

  .login-box .user-box input,
  .login-box .user-box select,
  .login-box .user-box textarea {
   width: 100%;
   padding: 10px 10px; /* Ajusta según tu preferencia */
   font-size: 14px;
   color: black;
   margin-bottom: 8px;
   border: none;
   border-bottom: 1px solid #797979;
   outline: none;
   background: transparent;
}

.login-box .user-box select option {
  color: black; /* Color del texto de las opciones del select cuando se despliegan */
}



  .login-box .user-box label {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    padding: 10px 0;
    font-size: 14px;
    color: #797979;
    pointer-events: none;
    transition: .5s;
  }

  .login-box .user-box input:focus ~ label,
  .login-box .user-box input:valid ~ label,
  .login-box .user-box select:focus ~ label,
  .login-box .user-box textarea:focus ~ label {
    top: -24px;
    left: 0;
    color: #797979;
    font-size: 12px;
  }

</style>