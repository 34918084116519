import art_012 from "../../pages/Blog/SerHuma/RespoSocial/12-ART-SERHUMANITAS-RESPSOCIAL-130324.vue"
import art_024 from "../../pages/Blog/SerHuma/RespoSocial/0024-ART-SER-HUMANITAS-RESPONSABILIDAD-SOCIAL-090524.vue"
import art_0034 from "../../pages/Blog/SerHuma/RespoSocial/0034-ART-SER-HUMANITAS-RESPONSABILIDAD-SOCIAL-030624.vue"

export default [
    {
        path: '/blog/ser-humanitas/responsabilidad-social/responsabilidad-social-individual-tipos-ejemplos-inspiracion',
        name: 'Responsabilidad Social Individual: Descubre qué es e inspírate',
        meta: {
          title: "Responsabilidad Social Individual: Descubre qué es e inspírate",
          description: "Explora qué es la Responsabilidad Social Individual, qué  tipos existen y ejemplos. Aprende cómo puedes marcar la diferencia en tu comunidad y entorno.",
          image: "https://blog.humanitas.edu.mx/images/meta/responsabilidad-social-individual-transforma-entorno.webp",
          keywords: "Responsabilidad social individual, ejemplos de responsabilidad, tipos de responsabilidad social, responsabilidad social personal o individual, 3 ejemplos de responsabilidad, ejemplos de responsabilidad social individual",
          metaTags:
          {
            "og:title": "Responsabilidad Social Individual: Descubre qué es e inspírate",
            "og:description": "Explora qué es la Responsabilidad Social Individual, qué  tipos existen y ejemplos. Aprende cómo puedes marcar la diferencia en tu comunidad y entorno.",
            "og:image": "https://blog.humanitas.edu.mx/images/meta/responsabilidad-social-individual-transforma-entorno.jpg",
            "og:keywords": "responsabilidad social, responsabilidad social, universitaria, universidad"
          },
          requireAuth: false,
        },
        component: art_0034
    },
    {
        path: '/blog/ser-humanitas/responsabilidad-social/por-que-es-importante-la-responsabilidad-social-universitaria',
        name: '¿Por qué es importante la Responsabilidad Social Universitaria? Entérate',
        meta: {
          title: "¿Por qué es importante la Responsabilidad Social Universitaria? Entérate",
          description: "Aprende cómo la Responsabilidad Social Universitaria impulsa el cambio social, la sostenibilidad y también el desarrollo de tus habilidades profesionales.",
          image: "https://blog.humanitas.edu.mx/images/meta/Por-que-es-importante-responsabilidad-social-universitaria-para-ti.jpg",
          keywords: "responsabilidad social, responsabilidad social, universitaria, universidad",
          metaTags:
          {
            "og:title": "¿Por qué es importante la Responsabilidad Social Universitaria? Entérate",
            "og:description": "Aprende cómo la Responsabilidad Social Universitaria impulsa el cambio social, la sostenibilidad y también el desarrollo de tus habilidades profesionales.",
            "og:image": "https://blog.humanitas.edu.mx/images/meta/Por-que-es-importante-responsabilidad-social-universitaria-para-ti.jpg",
            "og:keywords": "responsabilidad social, responsabilidad social, universitaria, universidad"
          },
          requireAuth: false,
        },
        component: art_012
    },
    {
      path: '/blog/ser-humanitas/responsabilidad-social/acciones-socialmente-responsables',
      name: '¿Qué es una acción socialmente responsable?',
      meta: {
        title: "¿Qué es una acción socialmente responsable?",
        description: "Para saber qué es una acción socialmente responsable, recurrimos a los estándares internacionales y a ejemplos concretos.",
        image: "https://blog.humanitas.edu.mx/images/meta/accion-socialmente-responsable.webp",
        keywords: "Responsabilidad ejemplos, requisitos para ser una empresa socialmente responsable, responsabilidad social con los clientes ejemplos, ejemplos de responsabilidad social y actividades de responsabilidad social.",
        metaTags:
        {
          "og:title": "¿Qué es una acción socialmente responsable?",
          "og:description": "Para saber qué es una acción socialmente responsable, recurrimos a los estándares internacionales y a ejemplos concretos.",
          "og:image": "https://blog.humanitas.edu.mx/images/meta/accion-socialmente-responsable.webp",
          "og:keywords": "Responsabilidad ejemplos, requisitos para ser una empresa socialmente responsable, responsabilidad social con los clientes ejemplos, ejemplos de responsabilidad social y actividades de responsabilidad social.",
        },
        requireAuth: false,
      },
      component: art_024
    },
]