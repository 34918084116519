<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">Tendencias actuales del Derecho en 2024: <br class="md:block hidden"> descúbrelas</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-300">
          <img src="/images/Tendencias-actuales-derecho-2024.jpg" class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/aprende" class=" text-hone">Aprende</router-link> > <router-link to="/blog/aprende/tendencias" class=" text-hone">Tendencias</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex list-disc pl-5 italic text-left text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">19 de febrero de 2024</li>  <li>Carolina Reyes Martínez/Redacción Humanitas</li></ul>
            </div>
            <p class=" md:mb-4 mb-6"><b>Extracto:</b> El Derecho en 2024 está cambiando rápidamente gracias a las nuevas tecnologías, leyes y maneras distintas de ejercerlo. Te informamos cómo estas tendencias están definiendo la esfera legal.</p>
            <p class=" md:mb-8 mb-10"><b>Este 2024 marca un momento crucial donde la tecnología, la globalización y los cambios socioeconómicos coinciden para reformar las prácticas legales</b> y las teorías jurídicas. Estar al tanto de estas tendencias es importante no sólo para los abogados, también para las personas y empresas que buscan comprender las complejidades legales.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">El impacto de la Inteligencia Artificial en la práctica legal</h2>
            <p class=" md:mb-4 mb-6"><b>La Inteligencia Artificial (IA) está transformando la práctica legal:</b> desde la predicción de resultados judiciales, mediante la medición del algoritmo y patrones para tomar decisiones, hasta la personalización de documentos legales. La IA se convierte en un asistente virtual.</p>
            <p class=" md:mb-4 mb-6"><b>La IA puede identificar cláusulas importantes, términos y condiciones,</b> lo que permite encontrar errores como datos duplicados, cláusulas ambiguas, pero también oportunidades de mejorar un contrato.</p>
            <p class=" md:mb-4 mb-6"><b>La inteligencia artificial busca información en grandes bases de datos, con el fin de señalar precedentes de casos similares,</b> así los abogados pueden revisar las estrategias que se siguieron para pronosticar el éxito o no, de un caso legal <b>y decidir qué pasos seguir.</b></p>
            <p class=" md:mb-4 mb-6"><b>Con respecto a la automatización, la IA puede elaborar documentos</b> acomo contratos, cartas, notificaciones, mediante plantillas para que puedan usarse de manera estandarizada. <b>Ayuda a optimizar los tiempos de elaboración</b> de estos documentos que pueden ser muy repetitivos.</p>
            <p class=" md:mb-4 mb-6"><b>La Inteligencia Artificial también permite realizar investigaciones de manera muy rápida para contrastar datos</b> de diversas fuentes, y <b>esto es muy útil en la ejecución de transacciones comerciales, adquisiciones y fusiones de empresas;</b> también para investigar leyes y regulaciones, proporcionando más perspectivas a los abogados y a sus clientes</p>
            <p class=" md:mb-4 mb-6"><b>En cuanto a la gestión de proyectos, con la IA es posible programar plazos, citas, recordatorios para dar seguimiento</b> eficaz a los casos o proyectos que se tengan. Así como el monitoreo de estos procesos. Con todo programado es más sencillo dar continuidad a los temas legales.</p>
            <p class=" md:mb-8 mb-10"><b>La Inteligencia Artificial aprende de los procesos y va acumulando conocimiento</b> derivado de las decisiones tomadas, a su vez, mejora sus respuestas dada esta experiencia, y ayuda a los profesionales del derecho a enfocarse en sus estrategias. </p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Desafíos de la IA en el derecho</h2>
            <p class=" md:mb-4 mb-6">Si bien la Inteligencia Artificial es una gran herramienta, <b>también pone sobre la mesa preguntas éticas y de regulación que se deben abordar como la privacidad de los datos y la responsabilidad sobre el uso de los sistemas automatizados, así como el sesgo de los algoritmos,</b> ya que si la información que se les proporciona es incorrecta, esto puede ocasionar decisiones discriminatorias, por ejemplo, en algunos procesos de selección, en dar préstamos y toma de decisiones judiciales.</p>
            <p class=" md:mb-8 mb-10"><b>Para evitar esto, los datos deben ser diversos para entrenar a la IA y realizar pruebas muy rigurosas</b> por ello, es muy importante desarrollar marcos legales claros para el tema de la responsabilidad en el uso de la inteligencia artificial.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Blockchain, smart contracts y su aplicación en el Derecho </h2>
            <p class=" md:mb-4 mb-6"><b>El blockchain o “cadena de bloques” es la tecnología descentralizada que funciona en grupos o bloques de información</b> o datos que están entrelazados a través de algoritmos, y la información que está guardada en esta cadena <b>no puede ser modificada o hackeada.</b></p>
            <p class=" md:mb-4 mb-6">Al ser descentralizado, las transacciones quedan registradas en nodos que forman esta red o blockchain y que usan un mismo software para el intercambio de datos de forma segura y rápida.</p>
            <p class=" md:mb-4 mb-6">Ahora bien, <b>los contratos inteligentes (smart contracts), son aquellos que se hacen de manera digita</b> y <b>mediante el lenguaje de programación del blockchain.</b></p>
            <p class=" md:mb-4 mb-6"><b>Esto permite que el contrato se ejecute de forma segura y automática,</b> evitando incumplimientos. Al dar mayor confianza en las relaciones jurídicas, se reducen los riesgos de fraude y de disputas.</p>
            <p class=" md:mb-8 mb-10">Aunque es un gran avance tecnológico, <b>todavía es necesaria la asesoría de los profesionales del derecho</b> para su implementación.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Privacidad de datos y ciberseguridad</h2>
            <p class=" md:mb-4 mb-6">Con toda la revolución digital, <b>la información es el principal activo que tiene que protegerse de los riesgos</b> que puedan afectar su confiabilidad, su privacidad, integridad y autenticidad.</p>
            <p class=" md:mb-4 mb-6"><b>La alta dirección y los representantes legales de las empresas y bufetes jurídicos, tienen que desarrollar políticas y procedimientos para la gestión de riesgos de ciberseguridad,</b> así como establecer controles de supervisión sobre la adquisición de tecnologías y servicios de desarrollo, y el mantenimiento de las redes y sistemas de información para la protección de los datos.</p>
            <p class=" md:mb-8 mb-10"><b>Es esencial la capacitación sobre el tema de ciberseguridad y crear áreas de técnicos y abogados</b> para combatir conjuntamente, las amenazas que se pudieran presentar.</p>
            <img src="/images/La-importancia-de-sostenibilidad-en-derecho.jpg" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Tendencias actuales en el Derecho civil</h2>
            <p class=" md:mb-8 mb-10">El Derecho civil <b>es uno de los pilares de los sistemas jurídicos y en 2024, la resolución de conflictos se ha vuelto más accesible por ser la mediación y la conciliación,</b> los métodos ante los litigios tradicionales, reduciendo los costos y el tiempo para aligerar la carga en los sistemas judiciales.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Las nuevas legislaciones y su impacto en el Derecho familiar</h2>
            <p class=" md:mb-8 mb-10">El derecho familiar se refiere a la regulación de las relaciones de carácter personal y patrimonial en la familia y con terceros. <b>En México se han presentado iniciativas para actualizar las legislaciones dada la evolución de la familia,</b> en temas como la paternidad responsable, custodia de los hijos, el matrimonio igualitario, los derechos de sucesión, cambios sustanciales para que el derecho civil siga siendo relevante y equitativo.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">La importancia de la sostenibilidad en la práctica legal</h2>
            <p class=" md:mb-4 mb-6"><b>Las empresas ya están implementando prácticas legales que respetan el medio ambiente: cuidar los recursos naturales y considerar factores Ambientales, Sociales y de liderazgo o Gobernanza</b> (ASG) al tomar decisiones importantes. Esto muestra la importancia de que la sostenibilidad es esencial para asegurar el futuro y la fortaleza de cualquier organización.</p> 
            <p class=" md:mb-8 mb-10">Para 2024, <b>el Derecho ambiental se fortalecerá con normas más rigurosas</b> sobre las emisiones de carbono, el uso de plásticos desechables y el impulso hacia las energías renovables. <b>El objetivo de estas acciones es reducir la huella ambiental</b> y, al mismo tiempo, impulsar una economía que sea tanto verde como sostenible.</p>
            <!-- Aqui va el componente de contacto, no se te olvide hacerlo manolo -->
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
              <Contacto class="m-3"/>
            </div>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">El factor humano: el bienestar en el ámbito legal</h2>
            <p class=" md:mb-4 mb-6">Si bien hemos hablado de los avances y tendencias, <b>el factor humano sigue siendo primordial y la salud mental es un tema que debe observarse,</b> así como la mejora de las condiciones laborales, planes de crecimiento y de carrera atractivos para los profesionales del derecho.</p> 
            <p class=" md:mb-8 mb-10"><b>La flexibilidad del trabajo remoto también seguirá en este 2024</b> y las firmas legales tendrán que implementar políticas que garanticen la igualdad de oportunidades en este entorno.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Compliance y la generación de confianza</h2>
            <p class=" md:mb-8 mb-10">El compliance es el conjunto de normas y buenas prácticas que impulsan la creación de estrategias que identifican riesgos operativos y legales en las organizaciones. <b>Es un tema que debe consolidarse, pues una empresa con una cultura ética se vuelve atractiva</b> para nuevos inversionistas, se convierte en una organización sostenible y da un sentido de pertenencia entre sus colaboradores.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Cooperación internacional en asuntos legales</h2>
            <p class=" md:mb-4 mb-6"><b>La cooperación global está alcanzando otros niveles en 2024,</b> con estados y organizaciones trabajando en asuntos como el cambio climático, la ciberseguridad y la lucha contra el terrorismo.</p> 
            <p class=" md:mb-8 mb-10">Un ejemplo es la firma de la Convención Liubliana-La Haya para la investigación y persecusión del crimen de genocidio, lesa humanidad y otros crímenes internacionales <b>Esta colaboración se refleja en la creación de tratados multilaterales, la armonización de normas legales y el fortalecimiento de mecanismos internacionales</b> de resolución de disputas.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Conclusión</h2>
            <p class=" md:mb-4 mb-6"><b>Las tendencias actuales del derecho en 2024 ofrecen una visión del futuro legal, influenciado por la tecnología, la globalización y un compromiso con la equidad y la sostenibilidad.</b></p> 
            <p class=" md:mb-8 mb-10">Para los abogados, esto representa tanto un desafío como una oportunidad: el reto de adaptarse a los cambios y la oportunidad de contribuir para tener un sistema legal más justo para las próximas generaciones.</p>
            <p class="text-htwo text-left mb-6">Explora más: <a href="https://humanitas.edu.mx/licenciatura-en-derecho" target="_blank" class="text-htwo">Licenciatura en derecho</a></p>
            <p class="text-htwo text-left leading-tight mb-20">Te puede interesar: <router-link class="text-htwo" to="/blog/aprende/tendencias/tendencias-administracion-empresas-2024">Tendencias de la Administración en 2024</router-link> </p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="true" :linkAnt="'/academico/doctorado/guia-todo-sobre-doctorado-perfil-y-competencias'" :linkSig="'/academico/educacion-continua/como-un-diplomado-transforma-tu-carrera'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import Contacto from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue'
import SigArt from '../../pagesComponents/SigArt.vue';

export default {
    components: { Comentarios, ConComentarios, Contacto, LikesVue, ShareP, SigArt},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '07-ART-APRENDE-TENDENCIAS-190224',
            ruta: '/aprende/tendencias/tendencias-actuales-del-derecho-2024',
            title: 'Infórmate acerca de las tendencias actuales del Derecho en 2024.',
            image: 'https://blog.humanitas.edu.mx/images/Tendencias-actuales-derecho-2024.jpg',
            text: 'Entérate cuáles son las principales tendencias actuales del Derecho en 2024. Mantente al día con los cambios e innovaciones que conforman el futuro legal.'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx'+this.ruta;

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>