<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">¿Cuánto cuesta una mensualidad en<br class="md:block hidden"> la Universidad Humanitas? </h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-200">
          <img src="/images/cuanto-cuesta-mensualidad-universidad-humanitas-instalaciones.webp" alt="Conoce cuánto cuesta una mensualidad en la Universidad Humanitas" class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/ser-humanitas" class=" text-hone">Ser Humanitas</router-link> > <router-link to="/blog/ser-humanitas/comunidad" class=" text-hone">Comunidad</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex text-left list-disc pl-5 italic text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">30 de mayo de 2024</li>  <li>Redacción Humanitas</li></ul>
            </div>
            <p class="md:mb-4 mb-6">Los aspirantes y en general, la <router-link to="/blog/ser-humanitas/responsabilidad-social/por-que-es-importante-la-responsabilidad-social-universitaria"  class="text-htwo">comunidad universitaria</router-link>, constantemente tienen la interrogante de ¿Cuánto cuesta una mensualidad en la Universidad Humanitas?, debido a que <b>Humanitas es una de las mejores universidades, cuya misión es enaltecer la dignidad humana a través del conocimiento.</b></p>
            <p class="md:mb-8 mb-10">Saber cuánto cuesta una mensualidad en la Universidad Humanitas es un tema que le interesa a los estudiantes, tutores, ejecutivos, investigadores, académicos, entre otros, que <b>buscan adquirir conocimientos y alcanzar un nuevo nivel de desarrollo personal y profesional.</b></p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">¿Por qué los costos son un criterio de evaluación al momento de elegir educación universitaria privada?</h2>
            <p class="md:mb-8 mb-10">Los costos suelen ser un <router-link to="/blog/aprende/tips/puntos-clave-para-evaluar-la-calidad-de-una-universidad-privada"  class="text-htwo">criterio de evaluación al momento de elegir una institución de educación superior privada</router-link> por las siguientes principales razones:</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Presupuesto familiar y ajuste a la situación personal</h3>
            <p class="md:mb-4 mb-6"><b>Cada estudiante y familia tiene una situación financiera particular, por lo que se deben evaluar los costos</b> ayuda a determinar qué opciones son viables sin comprometer la estabilidad financiera familiar.</p>
            <p class="md:mb-8 mb-10">Algunos tutores o padres tienen mayor capacidad económica de asumir costos más altos, mientras que otros necesitan <b>buscar alternativas más asequibles o con mejores opciones de financiamiento.</b></p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Costos alternos e impacto financiero general</h3>
            <p class="md:mb-8 mb-10"><b>La educación superior representa una inversión, por lo que es importante considerar los costos asociados que se pueden suscitar,</b> como pueden ser: libros, materiales didácticos, transporte y alimentación. Este tipo de gasto puede tener un impacto financiero en los estudiantes y sus familias, se debe considerar con base en un presupuesto y planificar financieramente para evitar deudas.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Retorno de la inversión</h3>
            <p class="md:mb-4 mb-6">Analizar los costos en relación con los beneficios a largo plazo es primordial. <b>Quienes vayan a financiar la carrera universitaria deben considerar cómo la educación superior puede influir en las futuras oportunidades</b> laborales y potencial de ingresos.</p>
            <p class="md:mb-8 mb-10">Algunas <b>universidades cuentan con programas que ofrecen conexiones con la industria, prácticas profesionales y tasas de colocación laboral</b> altas, lo que justifica la inversión inicial.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Calidad de la educación</h3>
            <p class="md:mb-8 mb-10">Aunque los costos pueden ser un indicador de la calidad, es crucial evaluar si la inversión se traduce en una <b>educación de alta calidad, infraestructura adecuada, profesorado calificado y recursos académicos sólidos.</b></p>
            <img src="/images/universidad-humanitas-precios-alumnos.webp" alt="Los precios de la Universidad Humanitas varían de acuerdo al nivel educativo" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Universidad Humanitas: precios</h2>
            <p class="md:mb-4 mb-6">La misión de <b>Universidad Humanitas</b> es impulsar la educación en México, por lo cuál  <b>mantiene tarifas competitivas sin arriesgar la alta calidad educativa que ofrece a la comunidad.</b></p>
            <p class="md:mb-8 mb-10">Los precios de las mensualidades en Universidad Humanitas se catalogan de acuerdo a las licenciaturas, maestrías y doctorados.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Precios de licenciaturas en Universidad Humanitas</h3>
            <p class="md:mb-8 mb-10">Las licenciaturas de Derecho, Administración, Contabilidad, Ciencias Políticas, Psicología, Educación, Arquitectura, Diseño Gráfico, Arte y Teatro tienen una duración de 3 años con 6 meses.  En el modelo ejecutivo <b>las mensualidades van desde 2 mil 950 pesos MXN, mientras que en el modelo escolarizado van desde 4 mil 950 pesos MXN.</b></p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Precios de maestrías</h3>
            <p class="md:mb-4 mb-6">La oferta de maestrías de la Universidad Humanitas se compone de las siguientes áreas de conocimiento: Derecho Penal, Fiscal, <router-link to="/blog/academico/maestria/derecho-corporativo-empresarial"  class="text-htwo">Empresarial Corporativo</router-link>, Justicia Penal y Seguridad Pública, Alta Dirección Corporativa, Contabilidad con Especialidad en Impuestos, Mercadotecnia, Psicoterapia con un Enfoque Psicoanalítico y Educación.</p>
            <p class="md:mb-8 mb-10">La duración de estas especializaciones es de 1 año y 6 meses con mensualidades que van desde <b>4 mil 190 pesos MXN en el modelo ejecutivo que consta de 6 ciclos modulares trimestrales, 10 semanas de clase y una semana de evaluación final.</b></p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Precios de doctorados</h3>
            <p class="md:mb-4 mb-6">La Universidad Humanitas cuenta con tres principales doctorados: Alta Dirección, Derecho y Educación, con un plan de estudio ejecutivo que consta de 6 ciclos modulares trimestrales, 10 semanas de clase y una semana de evaluación final.</p>
            <p class="md:mb-8 mb-10"><b>La mensualidad de los doctorados es de 6 mil 400 pesos MXN y 200 pesos MXN de un pago único de credencial digital.</b> También se deben de considerar los costos de título, el certificado de doctorado, los cuáles pueden variar.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Certificado de inglés</h3>
            <p class="md:mb-8 mb-10">Nuestros cursos del idioma inglés se alinean con el modelo English Kingdom, que está diseñado según la estructura del Marco Común Europeo de Referencia para las Lenguas. <b>Actualmente la certificación tiene un costo de 5 mil pesos MXN.</b></p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Costos en Universidad Humanitas</h3>
            <ul class="list-disc pl-6 md:mb-8 mb-10">
                <li>No hay costo de inscripción.</li>
                <li>Sin costos de reinscripciones.</li>
                <li>Colegiaturas fijas con ajuste pequeño del 8 por ciento de manera anual.</li>
                <li>La duración de la licenciatura es de 42 meses (tres años y medio), en caso de que el alumno no termine sus estudios en el periodo programado, se pagará la colegiatura de tres mensualidades por trimestre adicional.</li>
                <li>La duración de la maestría es de 15 meses (1 año tres meses), en caso de que el alumno no termine sus estudios en el periodo programado, se pagará la colegiatura de tres mensualidades por trimestre adicional.</li>
                <li>En la colegiatura Todo Incluido se considera el pago de los trámites de Titulación (Certificado total de estudios de licenciatura y título registrado ante Secretaría de Educación Pública).</li>
            </ul>
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
                <ContactoVue class="m-3"/>
            </div>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Modelo ejecutivo</h2>
            <p class="md:mb-4 mb-6"><b>La Universidad Humanitas es pionera en el Sistema por Bloques, donde tres materias se consolidan en un trimestre. Cada asignatura es impartida por un profesor responsable durante todo el trimestre, que consta de 11 sesiones.</b> Las disciplinas cuentan con el reconocimiento oficial de la Secretaría de Educación Pública. Los programas son diseñados por especialistas en la materia y supervisados por el consejo académico.</p>
            <p class="md:mb-8 mb-10"><b>Los aspirantes al Modelo Ejecutivo son personas que están dispuestas a asumir la responsabilidad de gestionar su tiempo y compromisos para equilibrar trabajo y estudios.</b> Poseen la autodisciplina necesaria para mantenerse motivados y enfocados en sus objetivos académicos, incluso en un entorno de aprendizaje menos estructurado.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Modelo escolarizado</h2>
            <p class="md:mb-4 mb-6">El sistema escolarizado de Humanitas se distingue por su diseño único, enfocado en la singularidad de cada licenciatura. <b>Las materias están objetivamente estructuradas y se complementan con talleres destinados al desarrollo humano y profesional de los estudiantes.</b> Además, se imparten seminarios de vanguardia para proporcionar herramientas esenciales para su futura profesión.</p>
            <p class="mb-2">La estructura del programa escolarizado se compone de las siguientes etapas:</p>
            <ul class="list-disc pl-6 md:mb-4 mb-6">
                <li><b>Etapa I:</b> 8 trimestres, 2 años.</li>
                <li><b>Etapa II:</b> 2 trimestres, 6 meses.</li>
                <li><b>Etapa III:</b> 4 trimestres, 1 año.</li>
            </ul>
            <p class="md:mb-8 mb-10">El perfil de los alumnos escolarizados cuentan con una mente analítica y crítica, permitiéndoles abordar problemas complejos con creatividad e innovación. <b>Son comunicadores habilidosos, capaces de expresar sus ideas de manera clara y persuasiva, tanto verbalmente como por escrito.</b> Poseen una mentalidad abierta y curiosa, siempre dispuestos a aprender y explorar nuevos conceptos y perspectivas.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Modelo virtual</h2>
            <p class="md:mb-4 mb-6">Humanitas ha desarrollado un modelo virtual por especialidad, estructurado en bloques trimestrales, con el objetivo de reemplazar el sistema tradicional de estudio por materias seriadas. <b>Este modelo está diseñado específicamente para personas que trabajan, tienen familia u otras actividades que les impiden asistir a clases presenciales semanalmente y que son autodidactas.</b></p>
            <p class="md:mb-4 mb-6">La estructura de este modelo se concentra en el contenido global de tres materias relacionadas, permitiendo una mayor concentración en el aprendizaje. El trimestre consta de 11 semanas, finalizando con una evaluación en la última semana.</p>
            <p class="md:mb-8 mb-10">Los aspirantes a este modelo suelen ser personas con responsabilidades laborales que buscan un sistema educativo flexible que les permita estudiar a su propio ritmo, compatible con un trabajo a tiempo completo.</p>
            <img src="/images/modelo-escolarizado-universidad-humanitas.webp" alt="El modelo escolarizado de Universidad Humanitas se adapta a las necesidades actuales" class="md:mb-8 mb-10">
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Conclusión</h3>
            <p class="mb-20"><b>La <a href="https://humanitas.edu.mx/" target="_blank" class="text-htwo">Universidad Humanitas</a> tiene una oferta educativa que forma a las personas con valores y conocimientos profesionales en diversas ramas de estudio.</b> Su filosofía se basa en el respeto al entorno y la superación continua, garantizando una formación integral para enfrentar los desafíos del mundo moderno.</p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArtVue :ant="true" :linkAnt="'/aprende/tips/puntos-clave-para-evaluar-la-calidad-de-una-universidad-privada'" :linkSig="'/recursos/entrevistas-y-conferencias/inclusion-diversidad-en-derecho-por-mariel-cabanas'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import ContactoVue from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue';
import SigArtVue from '../../pagesComponents/SigArt.vue';
import VideoClipVue from '../../pagesComponents/videoClip.vue';

export default {
    components: { Comentarios, ConComentarios, ContactoVue, LikesVue, ShareP, VideoClipVue, SigArtVue},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '0035-ART-SER-HUMANITAS-COMUNIDAD-300524',
            ruta: '/ser-humanitas/comunidad/cuanto-cuesta-mensualidad-universidad-humanitas',
            title: '¿Cuánto cuesta una mensualidad en la Universidad Humanitas?',
            image: 'https://blog.humanitas.edu.mx/images/Costo-y-requisitos-estudiar-maestria.jpg',
            text: 'Descubre cuánto cuesta una mensualidad en la Universidad Humanitas y el nivel de la calidad educativa de reconocimiento internacional.'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx/academico/maestria/costo-y-requisitos-estudiar-maestria';

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>