<script lang="ts">
	import { defineComponent, computed } from 'vue';
	import { useRoute } from 'vue-router';
	import NavbarTs from './components/NavbarInd.vue';
	import FooterTs from './components/FooterInd.vue';
	import NavbarJs from './components/NavbarBlog.vue';
	import FooterJs from './components/FooterBlog.vue';

	export default defineComponent({
	name: 'App',
	components: {
		NavbarTs,
		FooterTs,
		NavbarJs,
		FooterJs
	},
	setup() {
		const route = useRoute();

		const currentNavbar = computed(() => {
			// Muestra NavbarJs si la ruta es /blog, de lo contrario muestra NavbarTs
			return route.path.startsWith('/blog') ? 'NavbarJs' : 'NavbarTs';
		});

		const currentFooter = computed(() => {
			// Muestra FooterJs si la ruta es /blog, de lo contrario muestra FooterTs
			return route.path.startsWith('/blog') ? 'FooterJs' : 'FooterTs';
		});

		return {
			currentNavbar,
			currentFooter
		};
	},
	methods: {
		showBanner() {
			const _hsp = (window as any)._hsp || [];
			_hsp.push(['showBanner']);
		},
	},
	});
</script>

<template class="flex flex-col no-scroll w-full bg-white">
	<transition name="fade" v-if="!$route.path.startsWith('/blog')">
		<div :key="$route.path" class="flex flex-col h-full no-scroll justify-center w-full bg-white">
			<component :is="currentNavbar" />
			<main>
				<router-view v-slot="{ Component }">
					<keep-alive>
						<component :is="Component" />
					</keep-alive>
				</router-view>
			</main>
			<component :is="currentFooter" />
			<div class="fixed bottom-2 right-2 z-50">
				<a href="https://api.whatsapp.com/send?phone=5215611422526" target="_blank">
					<img src="./pages/Blog/imagesComp/wha-icon.png" alt="WhatsApp" class="w-[56px]"> 
				</a>
			</div>
			<div class="flex justify-center items-center fixed bottom-2 left-2 z-50" @click="showBanner" title="Configuración de cookies">
				<img src="./static/img/logo-h-white.png" alt="Cookies Humanitas" class="w-[45px]"> 
			</div>
		</div>
	</transition>
	<div v-else class="flex flex-col h-full no-scroll justify-center w-full bg-white">
		<component :is="currentNavbar" />
		<main>
			<router-view />
		</main>
		<component :is="currentFooter" />
		<div class="fixed bottom-2 right-2 z-50">
			<a href="https://api.whatsapp.com/send?phone=5215611422526" target="_blank">
				<img src="./pages/Blog/imagesComp/wha-icon.png" alt="WhatsApp" class="w-[55px]"> 
			</a>
		</div>
		<div class="flex justify-center items-center fixed bottom-2 left-2 z-50" @click="showBanner" title="Configuración de cookies">
			<img src="./static/img/logo-h-white.png" alt="Cookies Humanitas" class="w-[45px]"> 
		</div>
	</div>
</template>

<style scoped>
	.no-scroll {
		overflow-x: hidden; /* Esto desactiva el desplazamiento horizontal */
	}
	.fade-enter-active, .fade-leave-active {
	transition: opacity 0.175s ease-in-out;
	}

	.fade-enter, .fade-leave-to /* .fade-leave-active en versiones de Vue anteriores */ {
		opacity: 0;
		
	}
</style>
