<template>
    <div>
      <CataBueno :titulo="'Enero 2024'" :subtitulo="''" :filtros="filtros" :articles="articles"/>
    </div>
</template>

<script lang="js">
import CataBueno from '../../pagesComponents/CataBueno.vue';

export default {
components: { CataBueno },
data() {
    return {
    articles: [
        {
            "id": "04-ART-APRENDE-TENDENCIAS-310124",
            "titulo": "Estas son las tendencias clave en la administración en 2024, descúbrelas",
            "fecha": "31 de enero de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Entérate de las tendencias más relevantes que dan forma al futuro de la administración de empresas en 2024.",
            "portada": "/images/Tendencias-administración-empresarial-2024-multinube.jpg",
            "enlace": "/aprende/tendencias/tendencias-administracion-empresas-2024"
        },
        {
            "id": "03-ART-APRENDE-TIPS-240124",
            "titulo": "Domina tus estudios: técnicas y consejos para tu éxito universitario",
            "fecha": "24 de enero de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Descubre las mejores técnicas de estudio y consejos para maximizar tu rendimiento en la universidad. Estudia de manera más inteligente y eficaz.",
            "portada": "/images/Las-mejores-tecnicas-estudio-triunfar-universidad.jpg",
            "enlace": "/aprende/tips/mejores-tecnicas-estudio-para-universidad"
        },
        {
            "id": "02-ART-ACADEMICO-MAESTRIA-240124",
            "titulo": "Costo y requisitos para estudiar una maestría: entérate",
            "fecha": "24 de enero de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Eleva tus oportunidades laborales y alcanza tus metas profesionales. Te orientamos sobre el costo y los requisitos para estudiar una maestría.",
            "portada": "/images/Costo-y-requisitos-estudiar-maestria.jpg",
            "enlace": "/academico/maestria/costo-y-requisitos-estudiar-maestria"
        },
        {
            "id": "01-ART-RECURSOS-EYCONF-180124",
            "titulo": "El liderazgo visionario de Albert Bosch en tu vida universitaria",
            "fecha": "18 de enero de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Descubre cómo emprender tu vida universitaria con el liderazgo visionario de Albert Bosch y vive tu vida como una aventura.",
            "portada": "/images/Emprende-aventura-liderazgo-visionario-albert-bosch.jpg",
            "enlace": "/recursos/entrevistas-y-conferencias/emprende-aventura-de-tu-vida-liderazgo-visionario-albert-bosch"
        },

        
    ], // Array de artículos original
    filtros: {
        "01-ART-RECURSOS-EYCONF-180124": {
        Humanidades: [],
        Ciencias_sociales: [],
        Economico_administrativas: ["Administración"]
        },
        "02-ART-ACADEMICO-MAESTRIA-240124": {
        Humanidades: ["Educación", "Psicología"],
        Ciencias_sociales: ["Derecho"],
        Economico_administrativas: ["Administración", "Contabilidad"]
        },
        "03-ART-APRENDE-TIPS-240124": {
        Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y Teatro"],
        Ciencias_sociales: ["Derecho", "Ciencias Políticas", "Administración Pública"],
        Economico_administrativas: ["Administración", "Contabilidad"]
        },
        "04-ART-APRENDE-TENDENCIAS-310124": {
        Humanidades: [],
        Ciencias_sociales: [],
        Economico_administrativas: ["Administración"]
        },
    },
    filtrosSeleccionados: null, // Lista de filtros seleccionados
    };
},
methods: {

}
};
</script>

<style>
/* Estilos CSS si los tienes */
</style>