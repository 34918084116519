import art_008 from '../../pages/Blog/Academico/EduCon/08-ART-ACADEMICO-EDUCACION-CONTINUA-200224.vue'
import art_0041 from '../../pages/Blog/Academico/EduCon/0041-ART-ACADEMICO-EDUCACION-CONTINUA-130624.vue'
import art_0052 from '../../pages/Blog/Academico/EduCon/0052-ART-ACADEMICO-EDUCACION-CONTINUA-270624.vue'

export default [
    {
        path: '/blog/academico/educacion-continua/educacion-continua-que-es',
        name: 'Educación continua: ¿Qué es y porqué es importante?',
        meta: {
          title: "Educación continua: ¿Qué es y porqué es importante?",
          description: "Descubre qué es la educación continua y cómo puede ayudarte a mantenerte actualizado en tu carrera profesional.",
          image: "https://humanitas.edu.mx/images/meta/educacion-continua-universitaria.webp",
          metaTags:
          {
            "og:title": "Educación continua: ¿Qué es y porqué es importante?",
            "og:description": "Descubre qué es la educación continua y cómo puede ayudarte a mantenerte actualizado en tu carrera profesional.",
            "og:image": "https://humanitas.edu.mx/images/meta/educacion-continua-universitaria.webp",
          },
          requireAuth: false,
        },
        component: art_0052
    },
    {
        path: '/blog/academico/educacion-continua/que-es-un-seminario-todo-lo-que-quieres-saber',
        name: 'Qué es un seminario: todo lo que quieres saber',
        meta: {
          title: "Qué es un seminario: todo lo que quieres saber",
          description: "Conoce más sobre el seminario de investigación, su importancia en la educación continua y cómo potencia tu conocimiento. ¡Infórmate!",
          image: "https://humanitas.edu.mx/images/meta/que-es-seminario-todo-lo-que-debes-saber.webp",
          metaTags:
          {
            "og:title": "Qué es un seminario: todo lo que quieres saber",
            "og:description": "Conoce más sobre el seminario de investigación, su importancia en la educación continua y cómo potencia tu conocimiento. ¡Infórmate!",
            "og:image": "https://humanitas.edu.mx/images/meta/que-es-seminario-todo-lo-que-debes-saber.webp",
          },
          requireAuth: false,
        },
        component: art_0041
    },
    {
        path: '/blog/academico/educacion-continua/como-un-diplomado-transforma-tu-carrera',
        name: 'El impacto de un diplomado en tu desarrollo profesional: entérate cómo',
        meta: {
          title: "El impacto de un diplomado en tu desarrollo profesional: entérate cómo",
          description: "Un diplomado amplía tus habilidades y transforma tu carrera profesional, conoce sus ventajas.",
          image: "https://blog.humanitas.edu.mx/images/meta/Como-impacta-tu-vida-profesional-un-diplomado-.jpg",
          metaTags:
          {
            "og:title": "El impacto de un diplomado en tu desarrollo profesional: entérate cómo",
            "og:description": "Un diplomado amplía tus habilidades y transforma tu carrera profesional, conoce sus ventajas.",
            "og:image": "https://blog.humanitas.edu.mx/images/meta/Como-impacta-tu-vida-profesional-un-diplomado-.jpg",
          },
          requireAuth: false,
        },
        component: art_008
    },
]