import art_009 from '../../pages/Blog/Academico/EsLinea/09-ART-ACADEMICO-ESTUDIA-EN-LINEA-220224.vue'
import art_022 from '../../pages/Blog/Academico/EsLinea/022-ART-ACADEMICO-ESTUDIA-EN-LINEA-070524.vue'
import art_0037 from '../../pages/Blog/Academico/EsLinea/0037-ART-ACADEMICO-EDUCACION-EN-LINEA-040624.vue'
import art_0042 from '../../pages/Blog/Academico/EsLinea/0042-ART-ESTUDIA-EN-LINEA-DERECHO-110624.vue'

export default [
    {
        path: '/blog/academico/estudia-en-linea/licenciatura-en-derecho-virtual',
        name: 'Licenciatura en Derecho virtual: cómo tener éxito',
        meta: {
          title: "Licenciatura en Derecho virtual: cómo tener éxito",
          description: "La Licenciatura en Derecho virtual ofrece una formación integral sobre la normatividad jurídica e institucional de la abogacía y sus ramas de conocimiento.",
          image: "https://humanitas.edu.mx/images/meta/licenciatura-derecho-virtual-corporativo.webp",
          keywords: "Licenciatura en Derecho virtual, Carrera de Derecho virtual, Estudiar derecho virtual, Estudio de derecho",
          metaTags:
          {
            "og:title": "Licenciatura en Derecho virtual: cómo tener éxito",
            "og:description": "La Licenciatura en Derecho virtual ofrece una formación integral sobre la normatividad jurídica e institucional de la abogacía y sus ramas de conocimiento.",
            "og:image": "https://humanitas.edu.mx/images/meta/licenciatura-derecho-virtual-corporativo.webp",
            "og:keywords": "Licenciatura en Derecho virtual, Carrera de Derecho virtual, Estudiar derecho virtual, Estudio de derecho",
          },
          requireAuth: false,
        },
        component: art_0042
    },
    {
        path: '/blog/academico/estudia-en-linea/licenciatura-psicologia-en-linea',
        name: 'Descubre la licenciatura de Psicología en línea y por qué puede ser para ti',
        meta: {
          title: "Descubre la licenciatura de Psicología en línea y por qué puede ser para ti",
          description: "¿Te interesa estudiar Psicología en línea? Entérate por qué puede ser la mejor opción para ti.",
          image: "https://humanitas.edu.mx/images/meta/licenciatura-psicologia-en-linea-una-opcion-humanitas.webp",
          keywords: "Licenciatura en psicología en línea, estudiar psicologia en linea, plan de estudios psicología, modalidad virtual, virtual",
          metaTags:
          {
            "og:title": "Descubre la licenciatura de Psicología en línea y por qué puede ser para ti",
            "og:description": "¿Te interesa estudiar Psicología en línea? Entérate por qué puede ser la mejor opción para ti.",
            "og:image": "https://humanitas.edu.mx/images/meta/licenciatura-psicologia-en-linea-una-opcion-humanitas.webp",
            "og:keywords": "Licenciatura en psicología en línea, estudiar psicologia en linea, plan de estudios psicología, modalidad virtual, virtual",
          },
          requireAuth: false,
        },
        component: art_0037
    },
    {
        path: '/blog/academico/estudia-en-linea/metodologias-aprendizaje-linea',
        name: 'Conoce las metodologías del aprendizaje en línea',
        meta: {
          title: "Conoce las metodologías del aprendizaje en línea",
          description: "Explora las principales metodologías de aprendizaje en línea y descubre cómo pueden transformar la experiencia educativa.",
          image: "https://blog.humanitas.edu.mx/images/meta/metodologias-aprendizaje-linea-.webp",
          url: "https://blog.humanitas.edu.mx/academico/estudia-en-linea/metodologias-aprendizaje-linea",
          type: "article",
          keywords: "metodología, metodología del aprendizaje, educación en línea",
          metaTags:
          {
            "og:title": "Conoce las metodologías del aprendizaje en línea",
            "og:description": "Explora las principales metodologías de aprendizaje en línea y descubre cómo pueden transformar la experiencia educativa.",
            "og:image": "https://blog.humanitas.edu.mx/images/meta/metodologias-aprendizaje-linea-.webp",
            "og:url": "https://blog.humanitas.edu.mx/academico/estudia-en-linea/metodologias-aprendizaje-linea",
            "og:type": "article",
            "og:keywords": "metodología, metodología del aprendizaje, educación en línea",
          },
          requireAuth: false,
        },
        component: art_022
    },
    {
        path: '/blog/academico/estudia-en-linea/estudia-tu-carrera-en-linea-beneficios',
        name: 'Amplía tus horizontes y estudia tu carrera en línea en México con flexibilidad y calidad',
        meta: {
          title: "Amplía tus horizontes y estudia tu carrera en línea en México con flexibilidad y calidad",
          description: "La educación universitaria en línea te ofrece carreras flexibles y accesibles. Explora los beneficios de esta modalidad.",
          image: "https://blog.humanitas.edu.mx/images/meta/Estudia-tu-carrera-en-linea.jpg",
          url: "https://blog.humanitas.edu.mx/academico/estudia-en-linea/estudia-tu-carrera-en-linea-beneficios",
          type: "article",
          metaTags:
          {
            "og:title": "Amplía tus horizontes y estudia tu carrera en línea en México con flexibilidad y calidad",
            "og:description": "La educación universitaria en línea te ofrece carreras flexibles y accesibles. Explora los beneficios de esta modalidad.",
            "og:image": "https://blog.humanitas.edu.mx/images/meta/Estudia-tu-carrera-en-linea.jpg",
            "og:url": "https://blog.humanitas.edu.mx/academico/estudia-en-linea/estudia-tu-carrera-en-linea-beneficios",
            "og:type": "article",
          },
            requireAuth: false,
        },
        component: art_009
      },
]