<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">¿Cuántos pisos tiene la torre de Pemex?</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-300">
          <img src="/images/cuantos-pisos-tiene-torre-pemex.webp" alt="¿Cuántos pisos tiene la torre de Pemex? es una cuestión que surge al admirar el rascacielos" class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 text-left italic text-[18px] md:text-[20px]"><router-link to="/blog/aprende" class=" text-hone">Aprende</router-link> > <router-link to="/blog/aprende/cultura-general" class=" text-hone">Cultura General</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex list-disc pl-5 italic text-left text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">13 de junio de 2024</li>  <li>Iván Solís Rivera</li></ul>
            </div>
            <p class=" md:mb-4 mb-6">En esta ocasión respondemos a la pregunta: ¿Cuántos pisos tiene la torre de Pemex?, que durante años fue uno de los edificios más altos de México y América Latina.</p>
            <p class=" md:mb-4 mb-6">El <b>edificio principal de Pemex cuenta con 53 pisos</b> construidos y habilitados de la siguiente manera:</p>
            <ul class="list-disc pl-6 md:mb-4 mb-6">
                <li>Sótano</li>
                <li>Planta baja</li>
                <li>Piso 1 y 2-mezzanine</li>
                <li>Piso 2 al 44-oficinas</li>
                <li>Piso 45-comedor</li>
                <li>Piso 46 al 49-oficinas de la dirección general</li>
                <li>Piso 50-helipuerto</li>
            </ul>
            <p class=" md:mb-4 mb-6">El <router-link to="/blog/aprende/tips/mejores-tecnicas-estudio-para-universidad" class=" text-htwo hover:text-hone">proyecto</router-link> de construcción del edificio de la principal paraestatal de México combinó la innovación tecnológica con un diseño vanguardista, además de reflejar el espíritu de una nación en crecimiento y la búsqueda para posicionarse como referencia en el sector energético a nivel internacional.</p>
            <p class="md:mb-4 mb-6">A lo largo de las décadas, <b>la torre de Pemex ha sido testigo de numerosos cambios en la dinámica económica y política del país,</b> consolidándose como un símbolo perdurable de la ingeniería y el desarrollo urbano mexicano.</p>
            <p class="md:mb-8 mb-10">Desde sus cimientos hasta el fin de la edificación, <b>la historia de esta torre está impregnada de desafíos técnicos, avances ingenieriles nacionales</b> y un profundo simbolismo nacional que la convierte en una de los edificios más icónicos de México.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">El edificio más alto de México</h2>
            <p class="md:mb-4 mb-6"><b>Durante 19 años la torre de Pemex se posicionó como la más alta del país con un total de 214 m de altura.</b> En 2003, la Torre Mayor la rebasó con 230 m de altura.</p>
            <p class="md:mb-8 mb-10">Actualmente se encuentra dentro del ranking de los primeros 10 edificios más altos de México con 211 metros de altura, sólo abajo de la torre Mayor e Insignia con 225 mts.<br>Le sigue el Hotel Safi Metropolitan ubicado en San Pedro Garza García que tiene 233 m, Torre BBVA con 234.9, Chapultepec Uno con 240.5 m. <b>El edificio más alto en México es la  Torre Obispado que se ubica en Monterrey con 305.3 m.</b></p>
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
              <Contacto class="m-3"/>
            </div>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">¿Quién fue el que construyó la torre de Pemex?</h2>
            <p class="md:mb-8 mb-10"><b>El ingeniero Roberto Ramírez Guevara (1933-2020), fue quien estuvo a cargo de la construcción de la torre de Pemex.</b> Oriundo de Tehuacán, Puebla, recuerda que vivió una infancia con su familia y tíos, quienes lo apoyaron.</p>
            <img src="/images/edificios-altos-mexico-roberto-ramirez.webp" alt="Uno de los edificios más altos de México lo construyó Roberto Ramírez Guevara" class="md:mb-8 mb-10">
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">¿Cómo fue la vida de Roberto Ramírez Guevara?</h3>
            <p class="md:mb-4 mb-6"><b>En los primeros años de su vida y después de cursar la primaria, trabajó como chofer y peón,</b> que fue la forma para obtener dinero para comer, ya que sufría de carencias. Posteriormente, <b>conoció a personal de la Secretaría de Recursos Hidráulicos, quienes lo invitaron a colaborar en la institución,</b> donde entró al área de análisis y observaciones del comportamiento del piso; esta experiencia lo marcó un antecedente en su carrera profesional.</p>
            <p class="md:mb-4 mb-6">Después de terminar el proyecto con la Secretaría, <b>el ingeniero tuvo que regresar al oficio de chófer de camiones de carga.</b></p>
            <p class="md:mb-4 mb-6"><b>El parteaguas para continuar sus estudios, se dio cuando sus ex compañeros de escuela, lo invitaron a entrar al Instituto Politécnico Nacional (IPN),</b> en la Ciudad de México. Ramírez Guevara habló con sus tíos, quienes le dieron 100 pesos y le otorgaron permiso para que <router-link to="/" class=" text-htwo hover:text-hone">estudiara la universidad.</router-link></p>
            <p class="md:mb-4 mb-6"><b>Las facilidades que otorgaba el IPN en aquella época, con el respaldo de la Secretaría de Educación Pública (SEP), dieron la oportunidad de que el ingeniero pudiera cursar la secundaria y vocacional,</b> ayudando a servir comida dentro del comedor del instituto y alojandose en sus instalaciones a manera de internado.</p>
            <p class="md:mb-4 mb-6">Fue así como intercalaba el estudio y el trabajo, sin embargo, <b>requería dinero para sostenerse, por lo que a través del sindicato de Pemex, obtuvo contratos temporales en puestos operativos.</b></p>
            <p class="md:mb-4 mb-6">En reiteradas ocasiones, solicitó una oportunidad de trabajo a los sindicatos de Pemex, aunque no tuvo éxito, sino hasta después de haber cursado la ingeniería. <b>Entre los primeros proyectos de construcción en los que estuvo involucrado fue la construcción de un puerto en Guaymas, Sonora.</b></p>
            <p class=" md:mb-8 mb-10">Después se involucró en trabajos de restablecimiento en refinerías como la de Minatitlán habilitando un muelle, para condensados y aceites. También estuvo a cargo de la construcción de edificios para construir torres de enfriamiento.</p>
            <VideoClip :link="'https://www.youtube.com/embed/Nm-EoFSZsQo?si=ikzq7-9-kKRZtSvF'"/>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">La historia detrás de la construcción de la torre de Pemex</h2>
            <p class="md:mb-4 mb-6"><b>El proyecto de construcción de la torre de Pemex obedeció al sobrecupo en los edificios de la paraestatal,</b> la cual contaba con los edificios A, B, C y otro alternativo, por lo que había personas que no tenían dónde trabajar.</p>
            <p class="md:mb-4 mb-6">Para solucionar esta problemática, se alquilaban edificios y los arrendamientos eran costosos, ante ello, el director <b>Jorge Díaz Serrano, ex director de Pemex, dio comienzo a la construcción de un edificio de primer nivel.</b></p>
            <p class="md:mb-4 mb-6"><b>El arquitecto Pedro Moctezuma Díaz Infante (1923 - 2011) fue quien realizó el esbozo y los planos originales de la construcción.</b></p>
            <p class="md:mb-4 mb-6">Una vez designados los responsables, incluyeron al ingeniero <b>Ramírez Guevara “me sorprendió que me dieran ese proyecto,</b> ya que no tenía mucha experiencia en la construcción de torres, pero enfrenté el reto”, señaló.</p>
            <p class="md:mb-4 mb-6"><b>“Tuvimos que cavar 32 metros de profundidad para encontrar suelo estable, inmediatamente empezamos a hacer unos pilares alrededor</b> de dónde se iba a encontrar la torre para que cuando lloviera, no se metiera el agua”, recordó el ingeniero.</p>
            <p class="md:mb-4 mb-6"><b>La primera etapa del proyecto fue construir un cajón de concreto gigante de 10 metros de altura,</b> una vez hecha esta estructura, se colocaron 32 pilotes de 48 pulgadas que llegaron hasta el suelo para después colocar placas de acero.</p>
            <p class="md:mb-4 mb-6">“Las placas de acero eran especiales y <b>no encontramos en México a algún proveedor y tuvimos que hacer un pedimento a nivel internacional,</b> bajo un concurso de licitación, el cual ganó Japón, España y Estados Unidos”, explicó.</p>
            <p class=" md:mb-8 mb-10">A manera de anécdota, el ingeniero comentó que <b>los cercanos al entonces presidente, José López Portillo no tenían una buena relación con los titulares del proyecto,</b> entre los cuales figuraba Díaz Serrano, por lo que impidieron que se construyeran dos pisos al proyecto original y promovieron la sustitución de los <router-link to="/blog/aprende/tips/estoicismo-para-estudiantes-universitarios-y-beneficios" class=" text-htwo hover:text-hone">líderes</router-link> del proyecto.</p>
            <img src="/images/torre-alta-mexico.webp" alt="La torre más alta de México se encuentra en la CDMX" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] font-serif mb-5 text-left leading-tight lg:text-justify text-hone">Torre a prueba de temblores</h2>
            <p class=" text-left md:mb-4 mb-6"><b>La estructura de la edificación es tan resistente que la cúspide de la torre se puede ladear hasta 1.60 mts para ambos lados sin posibilidad de derrumbarse,</b> además de que en la parte de afuera tiene contraventeos, que son elementos estructurales que se utilizan para aumentar la rigidez lateral de un edificio.</p>
            <p class=" md:mb-8 mb-10">“El espesor que le pusimos a los postes y las trabes son muy gruesos y fuertes. Uno de mis profesores dio seguimiento al proyecto, porque no utilizamos soldaduras, en vez de ello, usamos tornillos y roscas”, enfatizó Ramírez Guevara.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Características y curiosidades de la torre</h3>
            <ul class="list-disc pl-6 mb-20">
                <li>Cuenta con 27 ascensores, de los cuales diez son de alta velocidad.</li>
                <li><b>El rascacielos abarca un área total de 94 mil 600 metros cuadrados y ocupa un espacio de 15 mil 800 m2.</b> Dispone de 4 escaleras de emergencia presurizadas, unidades automáticas de aire acondicionado, y sistemas mecánicos, eléctricos y de telecomunicaciones en cada piso.</li>
                <li><b>El edificio tiene una capacidad de hasta 7 mil personas.</b></li>
                <li>Los materiales que se utilizaron en su construcción fueron: acero y concreto.</li>
                <li>Los elevadores de la torre están equipados con un detector que, al percibir cualquier movimiento telúrico, detiene su trayecto.</li>
                <li><b>Cuenta con cristales reflejantes "inteligentes" que regulan la transmisión de calor de los rayos solares.</b></li>
                <li><b>La gestión de la torre Pemex está a cargo del Building Management System (BMS), un sistema inteligente que integra los sistemas eléctricos,</b> hidro-sanitarios, de elevadores y de protección contra incendios.</li>
                <li>Se construyó en el sexenio del expresidente José López Portillo.</li>
            </ul>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="true" :linkAnt="'/academico/estudia-en-linea/licenciatura-en-derecho-virtual'" :linkSig="'/academico/educacion-continua/que-es-un-seminario-todo-lo-que-quieres-saber'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import Contacto from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue'
import SigArt from '../../pagesComponents/SigArt.vue';
import VideoClip from '../../pagesComponents/videoClip.vue';


export default {
    components: { Comentarios, ConComentarios, Contacto, LikesVue, ShareP, SigArt, VideoClip},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '0044-ART-APRENDE-CULTURA-GENERAL-130624',
            ruta: '/aprende/cultura-general/cuantos-pisos-tiene-la-torre-de-pemex',
            title: '¿Cuántos pisos tiene la torre de Pemex y cuál es su historia?',
            image: 'https://blog.humanitas.edu.mx/images/Día-internacional-de-la-mujer-financiar-acelera-igualdad-genero-2024.jpg',
            text: 'Conoce cuántos pisos tiene la torre de Pemex y cuál es la historia de este edificio, que es símbolo de la arquitectura moderna mexicana.'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx'+this.ruta;

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>