<template>
    <div>
      <CataBueno :titulo="'Académico'" :subtitulo="'Doctorado'" :filtros="filtros" :articles="articles"/>
    </div>
</template>
  
  <script lang="js">
  import CataBueno from '../pagesComponents/CataBueno.vue';
  
  export default {
    components: { CataBueno },
    data() {
      return {
        articles: [
          {
            "id": "0047-ART-ACADEMICO-DOCTORADO-240624",
            "titulo": "Descubre las mejores opciones de universidades con doctorado en México",
            "fecha": "24 de junio de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Explora estas alternativas de las mejores universidades para estudiar un doctorado en México.",
            "portada": "/images/doctorado-humanitas-para-alcanzar-tus-metas.webp",
            "enlace": "/academico/doctorado/universidades-con-doctorado-en-mexico-opciones"
          },
          {
              "id": "0045-ART-ACADEMICO-DOCTORADO-180624",
              "titulo": "Doctorados virtuales en México: especialización y conocimiento",
              "fecha": "19 de junio de 2024",
              "autor": "Iván Solís Rivera",
              "descripcion": "Los doctorados virtuales en México representan la culminación de los estudios universitarios que contemplan investigación y conocimiento profundo sobre un tema.",
              "portada": "/images/doctorados-virtuales-mexico-estudiantes.webp",
              "enlace": "/academico/doctorado/doctorados-virtuales-en-mexico"
          },
          {
              "id": "06-ART-ACADEMICO-DOCTORADO-140224",
              "titulo": "Si quieres hacer el doctorado, esta guía te ayudará, te decimos todo lo que necesitas.",
              "fecha": "14 de febrero de 2024",
              "autor": "Carolina Reyes Martínez",
              "descripcion": "Descubre todo sobre el doctorado: las cualidades del estudiante ideal, estrategias para destacar y las competencias que adquirirás para tu vida.",
              "portada": "/images/Todo-sobre-el-doctorado-guia.jpg",
              "enlace": "/academico/doctorado/guia-todo-sobre-doctorado-perfil-y-competencias"
          },
          
        ], // Array de artículos original
        filtros: {
          "0047-ART-ACADEMICO-DOCTORADO-240624": {
            Humanidades: ["Educación"],
            Ciencias_sociales: ["Derecho"],
            Economico_administrativas: ["Administración"]
          },
          "0045-ART-ACADEMICO-DOCTORADO-180624": {
            Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y Teatro"],
            Ciencias_sociales: ["Derecho", "Ciencias Políticas", "Administración Pública"],
            Economico_administrativas: ["Administración", "Contabilidad"]
          },
          "06-ART-ACADEMICO-DOCTORADO-140224": {
            Humanidades: ["Educación"],
            Ciencias_sociales: ["Derecho"],
            Economico_administrativas: ["Administración"]
          },
        },
        filtrosSeleccionados: null, // Lista de filtros seleccionados
      };
    },
    methods: {
    }
  };
  </script>
  
  <style>
  /* Estilos CSS si los tienes */
  </style>