<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">¿Cómo surge la licenciatura en Educación y <br class="md:block hidden">Administración de centros educativos?</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-200">
          <img src="/images/licenciatura-educacion-administracion-centros-educativos.webp" alt="Licenciatura en Educación y Administración de centros educativos: respuesta de los desafíos educativos." class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/academico" class=" text-hone">Académico</router-link> > <router-link to="/blog/academico/licenciatura" class=" text-hone">Licenciatura</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex text-left list-disc pl-5 italic text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">20 de Junio de 2024</li>  <li>Iván Solís Rivera</li></ul>
            </div>
            <p class="md:mb-4 mb-6">La licenciatura en Educación y Administración de centros educativos surge de la necesidad de formar <b>profesionales que no solo dominen los aspectos pedagógicos, sino que también estén capacitados para gestionar y liderar instituciones educativas.</b></p>
            <p class="md:mb-8 mb-10">Ante tal contexto, los estudiantes de esta <router-link to="/blog/academico/licenciatura/beneficios-carreras-universitarias-cortas" class=" text-htwo hover:text-hone">licenciatura</router-link> deben de contar con una sólida base en pedagogía y habilidades administrativas.</p>
            <!-- <p class="md:mb-8 mb-10"><b>Las <router-link to="/blog/academico/licenciatura/mejores-universidades-derecho-mexico-guia"  class="text-htwo">universidades</router-link> que no aplican exámenes de admisión cuentan con sistemas de evaluación alternativos</b> o forman parte de planes educativos de gobierno cuyo principal objetivo es impulsar la educación a nivel medio y superior.</p> -->
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Licenciatura en Educación y Administración de centros educativos: ¿Qué es?</h2>
            <p class="md:mb-4 mb-6">La carrera de la <router-link to="/blog/academico/estudia-en-linea/licenciatura-en-derecho-virtual" class=" text-htwo hover:text-hone">licenciatura</router-link> en Educación y Administración de Centros Educativos es una <b>conjugación entre la pedagogía y conocimientos de gestión administrativa enfocada en instituciones educativas.</b></p>
            <p class="md:mb-4 mb-6"><b>La creación de esta licenciatura surge para adaptarse a la globalización y la incorporación de nuevas tecnologías en el ámbito educativo,</b> considerando un entorno dinámico que demanda profesionales versátiles y preparados para adaptarse a cambios rápidos.</p>
            <p class="md:mb-4 mb-6">Es de resaltar que el contexto actual, la educación enfrenta constantes desafíos y requiere adaptarse a nuevas demandas, por lo que <b>se hizo evidente la importancia de contar con líderes que comprendan tanto el proceso de enseñanza-aprendizaje como las complejidades administrativas de una institución educativa.</b></p>
            <p class="md:mb-4 mb-6">En los últimos años, se ha observado mayor complejidad de las estructuras administrativas dentro de los centros educativos, que <b>requieren no solo habilidades pedagógicas, sino también competencias en gestión, liderazgo y organización.</b></p>
            <p class="md:mb-4 mb-6">Las instituciones educativas, desde preescolares hasta <router-link to="/blog/academico/licenciatura/universidades-sin-examen-admision" class=" text-htwo hover:text-hone">universidades</router-link>, demandan directivos capaces de manejar recursos, coordinar equipos, implementar programas educativos innovadores y asegurar la calidad del proceso de enseñanza-aprendizaje, al respecto, <b>esta carrera universitaria es una respuesta innovadora a las necesidades contemporáneas del sector educativo, formando profesionales versátiles y preparados para contribuir al avance y transformación de la educación.</b></p>
            <p class="md:mb-8 mb-10">Esta carrera <b>combina conocimientos teóricos con experiencias prácticas, permitiendo a los estudiantes desarrollar competencias en áreas clave</b> como planificación educativa, gestión de recursos humanos y financieros, y evaluación de programas educativos.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Perfiles de los egresados</h3>
            <p class="md:mb-4 mb-6"><b>El perfil de los estudiantes de esta licenciatura es el de aquellos que buscan adquirir competencias en el diseño y desarrollo curricular,</b> evaluación educativa y técnicas de enseñanza innovadoras. Además tienen interés en áreas clave de la administración como gestión de recursos humanos, planificación estratégica, administración financiera y manejo de proyectos.</p>
            <p class="md:mb-8 mb-10">Una de las principales <b>características de esta licenciatura es ofrecer una formación integral que combina conocimientos en pedagogía, gestión administrativa y liderazgo educativo.<br> Entre las habilidades de gestión y liderazgo de los estudiantes, destacan: la planificación estratégica y operativa de instituciones educativas,</b> así como habilidades en la gestión de recursos humanos y financieros.</p>
            
            <!-- Form -->
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
                <ContactoVue class="m-3"/>
            </div>
            
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Licenciatura en Educación: ¿de qué se trata?</h2>
            <p class="md:mb-4 mb-6">En <router-link to="/" target="_blank" class="text-htwo hover:text-hone">Universidad Humanitas,</router-link> <b>la licenciatura en Educación tiene como propósito desarrollar en los estudiantes habilidades y una formación humanista</b> que les permitan participar en proyectos educativos innovadores y de alta calidad.</p>
            <p class="md:mb-8 mb-10">Dicha formación se basa en su <b>capacidad para investigar, reflexionar críticamente y utilizar tecnologías, siempre con un firme compromiso social,</b> bajo un enfoque integral, esta carrera asegura un manejo óptimo de los procesos educativos, fomentando una actitud de liderazgo y visión prospectiva.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Perfil de los aspirantes</h3>
            <p class="md:mb-4 mb-6"><b>Este programa está dirigido a quienes tienen interés en comprender los procesos sociales y en interpretar la conducta humana.</b> Es ideal para aquellos que disfrutan de la investigación y la lectura, y que tienen un gusto por establecer relaciones interpersonales.</p>
            <p class="md:mb-4 mb-6"><b>Los aspirantes deben mostrar habilidad para la observación, la dirección de grupos, la búsqueda y consulta de información,</b> así como para el manejo y comprensión del lenguaje escrito y verbal.</p>
            <p class="md:mb-8 mb-10">De la misma forma, <b>deben demostrar un fuerte compromiso social, vocación de servicio, y habilidades de cooperación y responsabilidad.</b> Se valora la apertura, la ética y una visión de futuro, características esenciales para enfrentar los retos del ámbito educativo con éxito.</p>
            <!-- Images -->
            <img src="/images/carrera-educacion-maestros-estudiantes.webp" alt="La carrera de educación tiene un enfoque integral de los procesos educativos." class="md:mb-8 mb-10">
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[19px] mb-3">Planes de estudio de la licenciatura en educación</h4>
            <p class="md:mb-8 mb-10"><b>La Universidad Humanitas cuenta con las modalidades: ejecutivo, escolarizado y virtual que comprenden 14 ciclos modulares trimestrales</b> que contempla 10 semanas de clase y 1 semana de evaluación final.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Universidades con licenciatura en educación</h2>
            <p class="md:mb-4 mb-6">Algunas de las universidades en México que ofrecen la licenciatura en Educación son las siguientes:</p>
            <ul class="list-decimal pl-6 md:mb-4 mb-6">
                <li>Universidad Nacional Autónoma de México (UNAM): <br> 
                  <ul class="list-disc pl-12 ">
                      <li>Facultad de Filosofía y Letras</li>
                      <li>Facultad de Estudios Superiores Aragón</li>
                      <li>Facultad de Estudios Superiores Acatlán</li>
                  </ul>
                </li>
                <li>Universidad Pedagógica Nacional (UPN): <br>
                  <ul class="list-disc pl-12 ">
                      <li>Sede Central en la Ciudad de México</li>
                  </ul>
                </li>
                <li>Universidad de Guadalajara (UDG): <br>
                  <ul class="list-disc pl-12 ">
                      <li>Centro Universitario del Norte</li>
                      <li>Centro Universitario de Los Valles</li>
                  </ul>
                </li>
                <li>Benemérita Universidad Autónoma de Puebla (BUAP): <br>
                  <ul class="list-disc pl-12 ">
                      <li>Puebla (Facultad de Filosofía y Letras)</li>
                      <li>Chiautla de Tapia (Complejo Regional Mixteca)</li>
                      <li>Regional Atlixco (Complejo Regional Mixteca)</li>
                  </ul>
                </li>
                <li>Universidad Autónoma del Estado de México (UAEM): <br>
                  <ul class="list-disc pl-12 ">
                      <li>Campus Norte</li>
                  </ul>
                </li>
                <li>Universidad Autónoma de Nuevo León (UANL): <br>
                  <ul class="list-disc pl-12 ">
                      <li>Facultad de Filosofía y Letras</li>
                      <li>Facultad de Ciencias de la Educación</li>
                  </ul>
                </li>
                <li>Tecnológico de Monterrey (ITESM): <br>
                  <ul class="list-disc pl-12 ">
                      <li>Campus Sinaloa, Sonora Norte, Aguascalientes, Santa Fe, Monterrey, Chihuahua y Laguna.</li>
                  </ul>
                </li>
                <li>Universidad Iberoamericana (IBERO)</li>
                <li><router-link to="/" class=" text-htwo hover:text-hone" target="_blank">Universidad Humanitas</router-link></li>
            </ul>
            <p class="md:mb-8 mb-10">Estas universidades ofrecen programas de licenciatura en Educación que incluyen diversas especialidades y enfoques.</p>
            <!-- Images -->
            <img src="/images/licenciatura-educacion-maestro-graduados.webp" alt="Licenciatura en educación: de qué se trata, es una pregunta constante entre los aspirantes." class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Conclusión</h2>
            <p class="mb-20"><b>La licenciatura en Administración Pedagógica forma profesionales con una combinación única de habilidades pedagógicas y administrativas,</b> preparados para liderar y transformar instituciones del sector educativo con un enfoque innovador.</p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="true" :linkAnt="'/academico/educacion-continua/que-es-un-seminario-todo-lo-que-quieres-saber'" :linkSig="'/academico/doctorado/universidades-con-doctorado-en-mexico-opciones'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import ContactoVue from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue';
import VideoClipVue from '../../pagesComponents/videoClip.vue';
import SigArt from '../../pagesComponents/SigArt.vue';

export default {
components: { Comentarios, ConComentarios, ContactoVue, LikesVue, ShareP, VideoClipVue, SigArt},
mounted() {
    // Agregar metadatos Open Graph al DOM cuando el componente se monta
    //this.setOpenGraphMetaTags();
},
data(){
    return {
        code: '0048-ART-ACADEMICO-LICENCIATURA-200624',
        ruta: '/academico/licenciatura/licenciatura-en-educacion-y-administracion-de-centros-educativos',
        title: 'Licenciatura en Educación y Administración de centros educativos: ¿Qué es?',
        image: 'https://blog.humanitas.edu.mx/images/Costo-y-requisitos-estudiar-maestria.jpg',
        text: 'La licenciatura en Educación y Administración de centros educativos combina conocimientos de pedagogía, gestión administrativa y liderazgo educativo.'
    }
},
methods: {
    setOpenGraphMetaTags() {
    // Crear elementos meta para los metadatos Open Graph
    const ogUrl = document.createElement('meta');
    ogUrl.setAttribute('property', 'og:url');
    ogUrl.content = 'https://blog.humanitas.edu.mx/academico/licenciatura/principales-corrientes-teorias-psicologicas-modelos-y-representantes';

    const ogType = document.createElement('meta');
    ogType.setAttribute('property', 'og:type');
    ogType.content = 'article';

    const ogTitle = document.createElement('meta');
    ogTitle.setAttribute('property', 'og:title');
    ogTitle.content = this.title;

    const ogDescription = document.createElement('meta');
    ogDescription.setAttribute('property', 'og:description');
    ogDescription.content = this.text;

    const ogImage = document.createElement('meta');
    ogImage.setAttribute('property', 'og:image');
    ogImage.content = this.image;

    // Agregar los elementos meta al head del documento
    document.head.appendChild(ogUrl);
    document.head.appendChild(ogType);
    document.head.appendChild(ogTitle);
    document.head.appendChild(ogDescription);
    document.head.appendChild(ogImage);
    }
}
}
</script>

<style>

</style>