<script lang="ts">
import { defineComponent } from 'vue';
import Botones from './Botones.vue';

export default defineComponent({
  components: { Botones },
  data() {
    return {
      buttons: {
        button1: {
          imageUrl: "../pagesImages/atras-off.png", hoverImageUrl: "../pagesImages/atras-on.png"
        },
        button2: {
          imageUrl: "../pagesImages/siguiente-off.png", hoverImageUrl: "../pagesImages/siguiente-on.png"
        }
      },
      images: [
        {
          src: '../pagesImages/licenciaturasbanner.jpg',
          alt: 'Descripción 1',
          textoEnlace: 'Conoce las Licenciaturas...',
          enlace: '/licenciaturas', // Reemplaza con la ruta deseada
        },
        {
          src: '../pagesImages/maestrias-banners.jpg',
          alt: 'Descripción 2',
          textoEnlace: 'Conoce las Maestrías...',
          enlace: '/maestrias', // Reemplaza con la ruta deseada
        },
        {
          src: '../pagesImages/doctorados-banners.jpg',
          alt: 'Descripción 3',
          textoEnlace: 'Conoce los Doctorados...',
          enlace: '/doctorados', // Reemplaza con la ruta deseada
        },
      ],
      currentIndex: 0,
      timer: null,
    };
  },
  methods: {
    nextSlide(): void {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
      this.resetTimer();
    },
    prevSlide(): void {
      this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
      this.resetTimer();
    },
    resetTimer(): void {
      //@ts-ignore
      clearInterval(this.timer);
      this.startTimer();
    },
    startTimer(): void {
      //@ts-ignore
      this.timer = setInterval(() => {
        this.nextSlide();
      }, 5000); // Cambia el intervalo según sea necesario (en milisegundos)
    },
  },
  mounted() {
    this.startTimer();
  },
  beforeUnmount() {
    //@ts-ignore
    clearInterval(this.timer);
  },
});
</script>

<template>
  <div class="carousel-container flex max-w-4xl lg:max-w-[1200px] flex-col items-center">
    <div class="carousel-item">
      <img :src="images[currentIndex].src" :alt="images[currentIndex].alt" class="carousel-image" />
      <router-link :to="images[currentIndex].enlace" class="carousel-overlay">
        <span class="carousel-text-link text-[#A69A68]">{{ images[currentIndex].textoEnlace }}</span>
      </router-link>
    </div>
    <Botones :button="buttons.button1" class="cursor-pointer carousel-button left" @click="prevSlide"></Botones>
    <Botones :button="buttons.button2" class="cursor-pointer carousel-button right" @click="nextSlide"></Botones>
  </div>
</template>

<style scoped>
.carousel-container {
  position: relative;
  width: 100%;
}

.carousel-image {
  width: 100%;
  height: auto;
}

.carousel-item {
  overflow: hidden;
}

.carousel-text-link {
  position: absolute;
  bottom: 0;
  left: 70%;
  font-size: 16px;
  font-weight: bold;
  padding: 10px; /* Añade un relleno al texto para mayor legibilidad */
  text-decoration: none;
  transition: color 0.3s;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  cursor: pointer;
}

.left {
  left: 0px;
}

.right {
  right: 0px;
}

.carousel-text-link:hover {
  color: #520400;
}
</style>