<script lang="ts">
    import PortadaInd from '../components/PortadaInd.vue';
	import NavbarInd from '../components/NavbarInd.vue';
	import FooterInd from '../components/FooterInd.vue';
    import VideoHiL from './components/VideoHiL.vue';
    import { defineComponent } from 'vue';
    import Botones from './components/Botones.vue';
    import UnaP from './components/UnaP.vue';

    export default defineComponent({
        components: { PortadaInd, NavbarInd, FooterInd, VideoHiL, Botones, UnaP  },
        data() {
            return {
                showImage: true,
                showImage2: true,
                videoUrl: 'https://www.youtube.com/embed/kM6yarkMYr8?si=b8-0nC3xH0j-7yEn',
                videoUrl2: 'https://www.youtube.com/embed/V4rNh6adVDg?si=gl-Dqt9j5meVMA1Y',
                img: './pagesImages/god.jpg',
                img2: './pagesImages/video1.jpg',
                buttons: {
                    button2: { imageUrl: './pagesImages/inscribete_white.png', hoverImageUrl: './pagesImages/inscribete_on.png' },
                },
            };
        },
        methods: {
            showVideo() {
                this.showImage = false;
            },
            showVideo2() {
                this.showImage2 = false;
            },
        },
    });
</script>

<template>
    <section class="flex items-center justify-center w-screen bg-cover bg-[center] bg-no-repeat mb-12 mt-20 lg:mt-32" style="background-image: url(/pagesImages/respons-banner.jpg); position: relative; background-attachment: fixed; background-position: center; background-repeat: no-repeat; background-size: cover; overflow: hidden; transition: transform 0.3s ease;">
        <div class="flex justify-center w-full max-w-4xl lg:max-w-[1200px] px-4">
            <div class="flex flex-col items-center lg:text-justify lg:items-start my-14 lg:my-44 w-full text-white">
                <p class="text-[42px] text-white lg:leading-none font-serif">HUMANITAS</p>
                <p class=" text-white relative -top-4 lg:leading-none text-[100px] font-bold tracking-[1.2rem]">VIVE</p>
                <p class="font-serif text-[19px] relative lg:static -top-12 lg:-top-6">Ense&ntilde;ar a valorar el medio ambiente es ense&ntilde;ar<br> a valorar la vida.</p>
            </div>
        </div>
    </section>

    <section class="flex items-center mb-12 justify-center w-full">
        <div class="lg:flex items-center justify-center w-full max-w-4xl lg:max-w-[1200px]">
            <div class="flex items-center lg:w-1/2 justify-center px-4">
                <VideoHiL :thumbnailUrl="img" :videoUrl="videoUrl2" class="w-full lg:mr-12 cursor-pointer"/>
            </div>
            <div class="flex flex-col lg:w-1/2 px-4">
                <div class="leading-none my-5">
                    <p class="lg:text-left text-gray-500 text-[26px]">Responsabilidad</p>
                    <p class="lg:text-left font-serif text-[54px] text-hgold">Social</p>
                </div>
                <p class="text-justify ">En Universidad Humanitas, creemos en el compromiso como institución frente a la sociedad en general mundial y con el ambiente, por lo que nos involucramos en campañas y programas que promueven impactos positivos y que van de la mano con nuestra misión, visión y valores institucionales, haciendo de la Responsabilidad Social parte fundamental de nuestro crecimiento y de nuestro futuro como Institución.</p>
            </div>
        </div>
    </section>

    <UnaP class="mb-16"/>

    <section class="flex items-center justify-center w-full">
        <div class="lg:flex justify-start w-full max-w-4xl lg:max-w-6xl items-center mb-12 px-4">
            <div class="flex flex-col lg:w-[50%] items-center text-justify lg:mr-12">
                <div class="text-justify">
                    <div class="text-center lg:text-left leading-tight">
                        <p class="text-gray-700 text-[36px]">Programa</p>
                        <p class="text-hgold mb-5 font-serif text-[45px] md:text-[52px]">Humanitas Vive</p>
                    </div>
                    <p class="mb-5">Es un programa de voluntariado en el cual las acciones y actividades están orientadas a la responsabilidad social de Universidad Humanitas, mediante el cual los alumnos pueden liberar su servicio social.</p>
                    <p class="mb-5">Estas actividades pueden ser:</p>
                    <p class="pl-2">• Sociales</p>
                    <p class="pl-2">• Culturales</p>
                    <p class="pl-2">• De altruismo</p>
                    <p class="pl-2">• Deportivas</p>
                    <p class="pl-2">• Ambientales</p>
                    <br>
                    <p>Humanitas Vive trabaja con instituciones autorizadas a las cuales se les brinda ayuda mediante donativos (sea en especie o en dinero) o mediante la organización de alguna actividad que vaya acorde con su ramo.</p>
                </div>
            </div>
            <div class="flex items-center justify-center"><img src="./pagesImages/fh_large.png" loading="lazy" alt="Mundito"></div>
        </div>
    </section>

    <section class="hidden lg:flex w-full h-[600px] items-center border justify-center bg-contain bg-[center] bg-no-repeat" style="background-image: url(/pagesImages/fundaciones.jpg);">
        <div class="flex items-center justify-start max-w-4xl lg:max-w-[1200px] w-full">
            <div class="flex flex-col  justify-start lg:text-justify my-44">
                <p class="font-serif pl-4 text-hgold text-[28px]">Algunas Instituciones con las</p>
                <p class="font-serif pl-4 text-hgold text-[28px]">que trabajamos...</p>
                <hr class="border-hgold my-4">
                <p class="font-bold pl-4 text-[22px] font-times text-black">"Una vida no vivida por los demás no es una vida".</p>
                <p class="text-right pl-4 text-[20px] font-times italic text-black">-Madre Teresa-</p>
            </div>
        </div>
    </section>

    <section class="flex flex-col lg:hidden w-full items-center border justify-center my-20">
        <div class="flex items-center justify-start max-w-4xl lg:max-w-[1200px] w-full px-4">
            <div class="flex flex-col justify-start lg:text-justify">
                <p class="font-serif text-hgold text-[28px]">Algunas Instituciones con las que trabajamos...</p>
                <hr class="border-hgold my-4">
            </div>
        </div>
        <div class="w-screen">
            <img src="./pagesImages/fundaciones.jpg" loading="lazy" alt="Fundaciones" class="object-cover object-right h-[360px] min-[500px]:h-[420px]">
        </div>
        <div class="flex items-center justify-start max-w-4xl lg:max-w-[1200px] w-full px-4">
            <div class="flex flex-col justify-start lg:text-justify">
                <p class="font-bold text-[22px] font-times text-black">"Una vida no vivida por los demás no es una vida".</p>
                <p class="text-right text-[20px] font-times italic text-black">-Madre Teresa-</p>
            </div>
        </div>
    </section>
</template>

<style scoped>
</style>