<template>
  <div>
    <CataBueno :titulo="'Aprende'" :subtitulo="'Tips'" :filtros="filtros" :articles="articles"/>
  </div>
</template>
  
<script>
import CataBueno from '../pagesComponents/CataBueno.vue';

export default {
  components: { CataBueno },
  data() {
    return {
      articles: [
        {
          "id": "0058-ART-APRENDE-TIPS-010824",
          "titulo": "¿Qué hacer si no te quedaste en el IPN?, aquí la respuesta",
          "fecha": "01 de agosto de 2024",
          "autor": "Iván Solís Rivera",
          "descripcion": "¿Qué hacer si no te quedaste en el IPN? Descubre las mejores opciones educativas públicas y privadas de educación superior.",
          "portada": "/images/que-hacer-quedaste-ipn.webp",
          "enlace": "/aprende/tips/que-hacer-si-no-te-quedaste-en-el-ipn"
        },
        {
          "id": "0057-ART-APRENDE-TIPS-310724",
          "titulo": "¿No te quedaste en la UNAM? Descubre estas alternativas",
          "fecha": "31 de julio de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "¿Si presentaste tu examen de admisión a la UNAM y no fuiste seleccionado, te damos alternativas para continuar tu educación universitaria. ¡Conócelas!",
          "portada": "/images/no-te-quedaste-unam-universidad-humanitas.webp",
          "enlace": "/aprende/tips/no-te-quedaste-en-la-unam-descubre-estas-alternativas"
        },
        {
          "id": "0055-ART-APRENDE-TIPS-050724",
          "titulo": "¿Ansiedad es lo mismo que angustia?: Aquí la respuesta",
          "fecha": "08 de julio de 2024",
          "autor": "Iván Solís Rivera",
          "descripcion": "Alguna vez te haz preguntado: ¿Ansiedad es lo mismo que angustia?, ¿Haz experimentado estos padecimientos?, te decimos cómo evitarlos.",
          "portada": "/images/ansiedad-mismo-angustia.webp",
          "enlace": "/aprende/tips/ansiedad-es-lo-mismo-que-angustia"
        },
        {
          "id": "0033-ART-APRENDE-TIPS-280524",
          "titulo": "¿Cómo evaluar la calidad de una universidad privada?",
          "fecha": "28 de mayo de 2024",
          "autor": "Iván Solís Rivera",
          "descripcion": "Conoce los puntos clave para elegir la mejor opción educativa y responde a la pregunta: ¿Cómo evaluar la calidad de una universidad privada?",
          "portada": "/images/como-evaluar-calidad-universidad-privada-estudiantes.webp",
          "enlace": "/aprende/tips/puntos-clave-para-evaluar-la-calidad-de-una-universidad-privada"
        },
        {
          "id": "16-ART-APRENDE-TIPS-020424",
          "titulo": "Maximiza tu productividad al estilo de Bill Gates.",
          "fecha": "02 de febrero de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "Descubre la técnica de Bill Gates y potencia tu productividad, ¡lee más!",
          "portada": "/images/aumenta-tu-productividad-tecnica-trabajo-profundo-Bill-Gates.jpg",
          "enlace": "/aprende/tips/maximiza-tu-productividad-estilo-bill-gates"
        },
        {
          "id": "13-ART-APRENDE-TIPS-150324",
          "titulo": "Estoicismo: cómo puede ayudar a los estudiantes a superar desafíos",
          "fecha": "15 de marzo de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "Explora los principios del Estoicismo y cuáles son sus beneficios en tu vida universitaria.",
          "portada": "/images/Que-es-estoicismo-y-como-ayuda-a-universitarios.jpg",
          "enlace": "/aprende/tips/estoicismo-para-estudiantes-universitarios-y-beneficios"
        },
        {
          "id": "03-ART-APRENDE-TIPS-240124",
          "titulo": "Domina tus estudios: técnicas y consejos para tu éxito universitario",
          "fecha": "24 de enero de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "Descubre las mejores técnicas de estudio y consejos para maximizar tu rendimiento en la universidad. Estudia de manera más inteligente y eficaz.",
          "portada": "/images/Las-mejores-tecnicas-estudio-triunfar-universidad.jpg",
          "enlace": "/aprende/tips/mejores-tecnicas-estudio-para-universidad"
        },
      ], // Array de artículos original
      filtros: {
        "0058-ART-APRENDE-TIPS-010824": {
          Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y teatro"],
          Ciencias_sociales: ["Derecho", "Ciencias Políticas y Administración Pública"],
          Economico_administrativas: ["Administración", "Contabilidad"]
        },
        "0057-ART-APRENDE-TIPS-310724": {
          Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y teatro"],
          Ciencias_sociales: ["Derecho", "Ciencias Políticas y Administración Pública"],
          Economico_administrativas: ["Administración", "Contabilidad"]
        },
        "0055-ART-APRENDE-TIPS-050724": {
          Humanidades: ["Psicología"],
          Ciencias_sociales: [],
          Economico_administrativas: []
        },
        "0033-ART-APRENDE-TIPS-280524": {
          Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y Teatro"],
          Ciencias_sociales: ["Derecho", "Ciencias Políticas", "Administración Pública"],
          Economico_administrativas: ["Administración", "Contabilidad"]
        },
        "03-ART-APRENDE-TIPS-240124": {
          Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y Teatro"],
          Ciencias_sociales: ["Derecho", "Ciencias Políticas y Administración Pública"],
          Economico_administrativas: ["Administración", "Contabilidad"]
        },
        "13-ART-APRENDE-TIPS-150324": {
          Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y Teatro"],
          Ciencias_sociales: ["Derecho", "Ciencias Políticas", "Administración Pública"],
          Economico_administrativas: []
        },
        "16-ART-APRENDE-TIPS-020424": {
          Humanidades: [],
          Ciencias_sociales: [],
          Economico_administrativas: ["Administración"]
        },
      },
      filtrosSeleccionados: null, // Lista de filtros seleccionados
    };
  },
  methods: {
    // Método para aplicar los filtros seleccionados
  }
};
</script>

<style>
/* Estilos CSS si los tienes */
</style>