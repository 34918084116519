<template>
  <div>
    <CataBueno :titulo="'Ser Humanitas'" :subtitulo="'Actividades en Campus'" :filtros="filtros" :articles="articles"/>
  </div>
</template>
  
<script>
import CataBueno from '../pagesComponents/CataBueno.vue';

export default {
  components: { CataBueno },
  data() {
    return {
      articles: [
        
      ], // Array de artículos original
      filtros: {
        
      },
      filtrosSeleccionados: null, // Lista de filtros seleccionados
    };
  },
  methods: {
    
  }
};
</script>

<style>
/* Estilos CSS si los tienes */
</style>