<script lang="ts">
	export default {
		data() {
			return {
				images: [
					{ src: '../pagesImages/licenciaturas.jpg', hoverSrc: '../pagesImages/licenciaturas.jpg', link: '/licenciaturas' },
					{ src: '../pagesImages/maestrias.jpg', hoverSrc: '../pagesImages/maestrias.jpg', link: '/maestrias' },
					{ src: '../pagesImages/doctorados.jpg', hoverSrc: '../pagesImages/doctorados.jpg', link: '/doctorados' },
				],
				activeIndex: -1,
			};
		},
	};
</script>


<template>
	<div class="flex justify-center w-full">
		<div class="lg:flex items-center justify-center lg:mb-12 w-full max-w-4xl lg:max-w-[1200px]">
			<div
				v-for="(image, index) in images"
				class="image-wrapper flex items-center justify-center"
			>
				<router-link :to="image.link">
					<img
						:src="activeIndex === index ? image.hoverSrc : image.src"
						:alt="'Imagen ' + (index + 1)"
						@mouseover="activeIndex = index"
						@mouseleave="activeIndex = -1"
						class="image w-auto h-auto"
					/>
				</router-link>
			</div>
		</div>
	</div>
</template>


<style scoped>
	.image-wrapper {
		margin: 16px 16px; /* Espacio entre las imágenes */
	}

	.image {
		transition: transform 0.7s ease; /* Transición para el efecto hover */
	}
</style>