<script lang="ts">
	export default {
		data() {
			return {
				images: [
					{ src: '../pagesImages/ejecutivo.webp', hoverSrc: '../pagesImages/ejecutivo.webp', link: '/ejecutivo' },
					{ src: '../pagesImages/escolarizado.webp', hoverSrc: '../pagesImages/escolarizado.webp', link: '/escolarizado' },
				],
				activeIndex: -1,
			};
		},
	};
</script>


<template>
	<div class="flex justify-center w-full">
		<div class="lg:flex items-center justify-center lg:mb-12 w-full max-w-4xl lg:max-w-[1200px]">
			<div
				v-for="(image, index) in images"
				class="image-wrapper flex items-center justify-center max-w-4xl lg:max-w-[1200px]"
			>
				<router-link :to="image.link">
					<img
						:src="activeIndex === index ? image.hoverSrc : image.src"
						:alt="'Imagen ' + (index + 1)"
						@mouseover="activeIndex = index"
						@mouseleave="activeIndex = -1"
						class="image  w-auto h-auto"
					/>
				</router-link>
			</div>
			<div class="image-wrapper flex items-center justify-center">
				<a href="https://virtual.humanitas.edu.mx/" target="_blank">
					<img
						src="../pagesImages/virtual2.webp"
						alt="Imagen virtual"
						class="image w-auto h-auto"
					/>
				</a>
			</div>
		</div>
	</div>
</template>


<style scoped>
	.image-wrapper {
		margin: 16px 16px; /* Espacio entre las imágenes */
	}

	.image {
		transition: transform 0.7s ease; /* Transición para el efecto hover */
	}
</style>