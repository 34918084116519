<script lang="ts">
    import { defineComponent } from "vue";
    import Botones from "./Botones.vue";

    export default defineComponent({
        components: { Botones},
        data() {
            return {
                showImage: true,
                videoUrl: "https://www.youtube.com/embed/2kjhBopG1jM?si=kwfzJUF-FPOsd0kM",
                buttons : {
                    button1: { imageUrl: "../pagesImages/inscribete_off.png", hoverImageUrl: "../pagesImages/inscribete_on.png" },
                },   
            };
        },
        methods: {
            showVideo() {
                this.showImage = false;
            },
            
        },
    });
</script>

<template>
    <section class="flex justify-center w-full h-full bg-cover bg-[center] bg-no-repeat" style="background-image: url(../pagesImages/banner-egresados.jpg); ">
        <div class="flex justify-between w-full max-w-[1200px] items-center">
            <div class="flex flex-col justify-start lg:w-[40%] w-[45%] my-36 text-justify px-2">
                <h1 class="text-white  text-left text-[40px]" style="letter-spacing: 10px;">EGRESADOS</h1>
                <h2 class="text-hgold font-serif text-[52px]">HUMANITAS</h2>
                <p class="text-white">
                    Una persona decidida a triunfar es capaz de superar hasta el obstáculo más difícil. Tu nos has demostrado que querer es poder, y por eso estamos alegres y agradecidos porque hayas egresado de la universidad.
                </p>
                <br>
                <router-link to="/contacto">
                    <Botones :button="buttons.button1"/>
                </router-link>
            </div>

            <div class="flex flex-col items-center justify-center my-16 ">
                <div class="flex justify-center items-center">
                    <div v-if="showImage" @click="showVideo">
                        <img src="../pagesImages/play-icon.png" alt="Play" class="play-icon cursor-pointer" />
                    </div>
                    <div v-else>
                        <iframe
                        width="80%"
                        height="100%"
                        :src="videoUrl"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                        class="lg:ml-24 ml-6 lg:w-[500px] lg:h-[300px] md:w-[350px] md:h-[200px]"
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style>

</style>