<template>
    <div>
      <CataBueno :titulo="'Marzo 2024'" :subtitulo="''" :filtros="filtros" :articles="articles"/>
    </div>
</template>

<script lang="js">
import CataBueno from '../../pagesComponents/CataBueno.vue';

export default {
components: { CataBueno },
data() {
    return {
    articles: [
        {
            "id": "15-ART-APRENDE-TENDENCIAS-270324",
            "titulo": "Tendencias en Psicología para 2024: Tecnología en la salud mental",
            "fecha": "28 de marzo de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Conoce las tendencias clave en Psicología en 2024, la salud mental y las aplicaciones digitales. Averigua cómo la tecnología puede ayudar al bienestar.",
            "portada": "/images/tendencias-psicologia-2024-chico-sentado-meditando-frente-laptop.jpg",
            "enlace": "/aprende/tendencias/tendencias-en-psicologia-2024-tecnologia-salud-mental"
        },
        {
            "id": "14-ART-ACADEMICO-LICENCIATURA-270324",
            "titulo": "27 de marzo Día Mundial del Teatro 2024: celebra la paz y el arte",
            "fecha": "27 de marzo de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Conoce la importancia y la belleza del Día Mundial del Teatro. Exploramos su historia, significado y cómo puedes unirte a este gran día, ¡participa!",
            "portada": "/images/A-27-marzo-2024-dia-mundial-teatro.jpg",
            "enlace": "/academico/licenciatura/dia-mundial-del-teatro-2024"
        },
        {
            "id": "13-ART-APRENDE-TIPS-150324",
            "titulo": "Estoicismo: cómo puede ayudar a los estudiantes a superar desafíos",
            "fecha": "15 de marzo de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Explora los principios del Estoicismo y cuáles son sus beneficios en tu vida universitaria.",
            "portada": "/images/Que-es-estoicismo-y-como-ayuda-a-universitarios.jpg",
            "enlace": "/aprende/tips/estoicismo-para-estudiantes-universitarios-y-beneficios"
        },
        {
            "id": "12-ART-SERHUMANITAS-RESPSOCIAL-130324",
            "titulo": "¿Por qué es importante la Responsabilidad Social Universitaria? Entérate",
            "fecha": "13 de marzo de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Aprende cómo la Responsabilidad Social Universitaria impulsa el cambio social, la sostenibilidad y también el desarrollo de tus habilidades profesionales.",
            "portada": "/images/Por-que-es-importante-responsabilidad-social-universitaria-para-ti.jpg",
            "enlace": "/ser-humanitas/responsabilidad-social/por-que-es-importante-la-responsabilidad-social-universitaria"
        },
        {
            "id": "11-ART-APRENDE-CULTURA-GENERAL-080324",
            "titulo": "Día de la Mujer 2024: invertir para tener igualdad de género",
            "fecha": "08 de marzo de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Día Internacional de la Mujer: por qué es importante y cómo en 2024, financiar los derechos de las mujeres puede acelerar la igualdad de género.",
            "portada": "/images/Dia-internacional-de-la-mujer-financiar-acelera-igualdad-genero-2024.jpg",
            "enlace": "/aprende/cultura-general/dia-de-la-mujer-financiar-derechos-acelerar-igualdad-de-genero-2024"
        },
        {
            "id": "10-GUIA-ACADEMICO-LICENCIATURA-010324",
            "titulo": "Guía de mejores universidades para estudiar Derecho en México: ¡infórmate!",
            "fecha": "07 de marzo de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "¿Quieres estudiar Derecho? Esta guía te ayudará a tomar una decisión informada. Conoce cuáles son las mejores opciones e inicia tu camino profesional.",
            "portada": "/images/mejores-universidades-estudiar-derecho-Mexico-guia.jpg",
            "enlace": "/academico/licenciatura/mejores-universidades-derecho-mexico-guia"
        },
    ], // Array de artículos original
    filtros: {
        "10-GUIA-ACADEMICO-LICENCIATURA-010324": {
          Humanidades: [],
          Ciencias_sociales: ["Derecho"],
          Economico_administrativas: []
        },
        "11-ART-APRENDE-CULTURA-GENERAL-080324": {
          Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y Teatro"],
          Ciencias_sociales: ["Derecho", "Ciencias Políticas", "Administración Pública"],
          Economico_administrativas: ["Administración", "Contabilidad"]
        },
        "12-ART-SERHUMANITAS-RESPSOCIAL-130324": {
          Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y Teatro"],
          Ciencias_sociales: ["Derecho", "Ciencias Políticas", "Administración Pública"],
          Economico_administrativas: ["Administración", "Contabilidad"]
        },
        "13-ART-APRENDE-TIPS-150324": {
          Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y Teatro"],
          Ciencias_sociales: ["Derecho", "Ciencias Políticas", "Administración Pública"],
          Economico_administrativas: []
        },
        "14-ART-ACADEMICO-LICENCIATURA-270324": {
          Humanidades: ["Arte y Teatro"],
          Ciencias_sociales: [],
          Economico_administrativas: []
        },
        "15-ART-APRENDE-TENDENCIAS-270324": {
          Humanidades: ["Psicología"],
          Ciencias_sociales: [],
          Economico_administrativas: []
        },
    },
    filtrosSeleccionados: null, // Lista de filtros seleccionados
    };
},
methods: {
}
};
</script>

<style>
/* Estilos CSS si los tienes */
</style>