<script setup lang="ts">
    import VideoHiL from '../VideoHiL.vue';

    interface Props {
        img?: string;
        url?: string;
    }

    const props = withDefaults(defineProps<Props>(), {
        img: '../pagesImages/ejecutivo-video.jpg',
        url: 'https://www.youtube.com/embed/bA3DNFmpeIs?si=J3d97hR3yZQHlF9y',
    });
</script>

<template>
    <section class="lg:flex items-center justify-center bg-cover bg-[center] bg-no-repeat" style="background-image: url(/pagesImages/reyes1.jpg); position: relative; background-attachment: fixed; background-position: left; background-repeat: no-repeat; background-size: cover; overflow: hidden; transition: transform 0.3s ease;">
        <VideoHiL :thumbnailUrl="props.img" :videoUrl="props.url" class="lg:p-0 md:px-16 px-6 pt-6"/>
        <div class="flex flex-col lg:justify-start justify-center items-center lg:items-start lg:w-[40%] w-full lg:my-24 py-12 text-justify md:px-4 px-12">
            <p class=" text-[28px] text-white leading-none text-center md:text-left">Modelo</p>
            <p class="text-hgold text-[42px] mb-4 leading-tight text-center md:text-left font-serif ">Ejecutivo</p>
            <p class="text-justify text-white">Universidad Humanitas es uno de los pioneros en el Sistema por Bloques, en el cual se consolidan tres materias en un trimestre; cada asignatura es impartida en un mes de clase y un profesor es el responsable de impartir la c&aacute;tedra con los contenidos de las tres materias durante todo el trimestre, que consta de 11 sesiones.</p>
            <br>
            <router-link to="/contacto">
                <div class="flex items-center justify-center w-[140px] h-[35px] bg-hwine hover:bg-hgold text-white  text-lg transition duration-300 ease-in-out">
                    <span>Inscr&iacute;bete</span>
                </div>
            </router-link>
        </div>
    </section>
</template>

<style lang="scss" scoped>
</style>