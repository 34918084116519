<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">Tendencias de la Administración en 2024: <br class="md:block hidden"> conócelas</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-300">
          <img src="/images/Tendencias-administración-empresarial-2024-multinube.jpg" class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-[18px] text-left md:text-[20px]"><router-link to="/blog/aprende" class=" text-hone">Aprende</router-link> > <router-link to="/blog/aprende/tendencias" class=" text-hone">Tendencias</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex list-disc text-left pl-5 italic text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">31 de enero de 2024</li>  <li>Carolina Reyes Martínez/Redacción Humanitas</li></ul>
            </div>
            <p class=" md:mb-8 mb-10"><b>Extracto:</b> En este artículo, exploraremos <b>cuáles son las tendencias de la administración en 2024</b> y te guiaremos sobre cómo puedes prepararte y transformar tu empresa. </p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Entendiendo el contexto de los profesionales de la administración</h2>
            <p class=" md:mb-4 mb-6"><b>La Administración de empresas</b> o también conocida como Administración de negocios, <b>es la ciencia social que gestiona los recursos, las personas y el tiempo en una organización.</b></p>
            <p class=" md:mb-8 mb-10">La gestión empresarial se encarga de fijar los objetivos que se alcanzarán por medio de la administración de los procesos para garantizar una operación de la empresa de manera eficaz.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">¿Para qué sirve la administración?</h2>
            <p class=" md:mb-4 mb-6"><b>La administración es esencial para lograr el máximo rendimiento para una empresa</b> y emplea los principios de organización, planificación, dirección y control de los recursos. Establece las estructuras de organización y los procedimientos para lograr los objetivos.</p>
            <p class=" md:mb-4 mb-6"><b>Los administradores deben liderar de una forma efectiva, equilibrada y productiva;</b> tienen que supervisar los sistemas de gestión, los costos y los aspectos económicos de la empresa.</p>
            <p class=" md:mb-4 mb-6">En <b>la gestión de personal o recursos humanos,</b> un administrador promueve <b>un ambiente positivo para los empleados</b> y fomenta la colaboración entre todas las áreas para lograr las metas establecidas por la dirección.</p>
            <p class=" md:mb-8 mb-10">Ahora, te diremos qué son <b>las tendencias actuales en la administración de empresas en este 2024</b> y cómo pueden ayudarte. </p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">La digitalización en la Administración</h2>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Integración de datos: nube híbrida y multinubes</h3>
            <p class=" md:mb-4 mb-6"><b>Las tendencias son las corrientes o patrones que se siguen en un periodo de tiempo.</b> Son las nuevas prácticas que suman procesos tecnológicos para hacer más efectiva la gestión empresarial. En la actualidad, <b>la digitalización de los datos es primordial</b> y ésta se puede llevar a cabo en 2024 de esta manera.</p>
            <p class=" md:mb-4 mb-6"><b>Integración de datos en la nube. La nube es la red de servidores a los que se accede a través de Internet,</b> así como la base de datos y los programas con los que esos servidores funcionan. Los servidores de la nube están por todo el mundo en centros de datos.</p>
            <p class=" md:mb-4 mb-6"><b>Para las empresas ha sido beneficioso este cambio,</b> pues bajan los costos de mantenimiento en sus equipos y pueden crecer a nivel internacional porque su información es fácil de consultar por empleados que están en otros países.</p>
            <p class=" md:mb-4 mb-6">Como ejemplo, el estudio de Gartner Analistas muestra que <b>el 85% de las organizaciones tendrán como prioridad estar en la nube en 2025,</b> en la Unión Europea.</p>
            <p class=" md:mb-4 mb-6">Por ello, <b>las empresas implementarán nuevas soluciones de integración en 2024 como la nube híbrida,</b> que consiste en aprovechar los servidores locales y también los que están en la nube para guardar datos con seguridad.</p>
            <p class=" md:mb-4 mb-6">Así, se asegura la continuidad del trabajo y una mayor escalabilidad, pues los datos importantes pueden estar en la nube y los menos sensibles, en los servidores de las empresas.</p>
            <p class=" md:mb-8 mb-10">En cuanto a <b>la estrategia multinubes consiste en usar varios servicios de computación en la nube de diferentes empresas.</b> Con ello, se tienen distintas opciones y se elimina la dependencia a un sólo proveedor de servicios.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Inteligencia Artificial y la automatización en las empresas</h3>
            <p class=" md:mb-4 mb-6"><b>La Inteligencia Artificial (IA) es una tecnología que ha revolucionado al mundo</b> en este siglo XXI, y cada vez más empresas la suman a sus estrategias.</p>
            <p class=" md:mb-4 mb-6">La Unión Europea prevé que para el año 2030, el 75% de empresas ya habrán incluido servicios en la nube, Inteligencia Artificial y Big Data.</p>
            <p class=" md:mb-4 mb-6"><b>En las empresas, la IA permite a los empleados centrarse en labores creativas y estratégicas, ya que automatiza los procesos</b> procesos rutinarios. También genera análisis con grandes cantidades de datos para la toma de decisiones para mejorar la experiencia de los usuarios y ayuda a disminuir errores.</p>
            <p class=" md:mb-4 mb-6"><b>La Inteligencia Artificial se puede incluir en diferentes aspectos de una empresa:</b> en el análisis de datos, en el marketing digital, en la atención al cliente, en las cadenas de suministro y en Recursos Humanos.</p>
            <p class=" md:mb-4 mb-6"><b>Es muy importante que la empresa considere el cambio cultural al sumar a la Inteligencia Artificial,</b> ya que a sus colaboradores debe brindarles capacitación y mostrarles cómo va a ser de utilidad en su trabajo.</p>
            <p class=" md:mb-8 mb-10">También un punto muy relevante es que <b>la empresa utilice a los modelos de IA de manera adecuada y ética para evitar sesgos y discriminación.</b> Ésos son los desafíos.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Big Data y análisis avanzado</h3>
            <p class=" md:mb-4 mb-6"><b>Otra tendencia es el análisis de datos en tiempo real.</b> Esto se lleva a cabo mediante transmisiones o feeds, conectados a fuentes de datos conversacionales.</p>
            <p class=" md:mb-8 mb-10"><b>Este análisis rápido y profundo de la información detecta patrones,</b> lo que permite tomar decisiones importantes. Es útil en empresas de e-commerce o tiendas online, bancos y en servicios de atención médica.</p>
            <img src="/images/Tendencias-administracion-empresas-2024-recursos-humanos.jpg" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Las tendencias online en los Recursos Humanos</h2>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">La automatización en los flujos de trabajo o workflow</h3>
            <p class=" md:mb-4 mb-6"><b>Éste es un modelo que permite organizar una actividad en pequeños pasos y reducir el tiempo de ejecución</b> para que sea más precisa. Cada flujo de trabajo se le atribuye a una persona o a un grupo de personas para la automatización de los procesos.</p>
            <p class=" md:mb-8 mb-10"><b>En Recursos Humanos, los flujos de trabajo automáticos gestionan tareas</b> desde la incorporación de empleados, manejo de vacaciones y horarios, hasta el reclutamiento y la evaluación de su desempeño.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Tendencia de contratación online</h3>
            <p class=" md:mb-8 mb-10">La transformación digital ha simplificado la selección de personal. <b>La contratación en línea es eficaz y una solución ágil:</b> ofrece flexibilidad en el horario de entrevistas y que los candidatos se conecten desde cualquier lugar. En este 2024 seguirá en auge.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Inducción en línea u onboarding</h3>
            <p class=" md:mb-4 mb-6"><b>El onboarding digital es el proceso de integración de un nuevo empleado a la empresa,</b> pero de manera remota y segura. Ofrece la confianza de una entrevista presencial pero en línea, en un proceso automatizado que asegura la incorporación de la persona de manera ágil.</p> 
            <p class=" md:mb-4 mb-6"><b>Existen programas que verifican la identidad</b> mediante selfies y videos con programas de detección en vivo. Se realizan grabaciones y se validan después.</p> 
            <p class=" md:mb-8 mb-10">Esto agiliza el trabajo y personaliza la experiencia del candidato, dándole información sobre la empresa, sus políticas, funciones y estructura.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Modelos lingüísticos (LLM) en la empresa</h3>
            <p class=" md:mb-4 mb-6"><b>Los Grandes Modelos Lingüísticos o de Lenguaje,</b> conocidos como Large Language Models (LLM) son herramientas de Inteligencia Artificial y Ciencia de Datos. <b>Son grandes redes neuronales que pueden leer, traducir y escribir textos;</b> crean frases y también las predicen como si las estuviera escribiendo un humano.</p> 
            <p class=" md:mb-4 mb-6"><b>Un ejemplo es ChatGPT,</b> que es muy popular y puede crear textos con la estructura que desees.</p> 
            <p class=" md:mb-4 mb-6"><b>¿Cómo se implementan en la empresa? Los asistentes virtuales</b> se integran a través de servidores y una Interfaz de Programación de Aplicaciones (API). Generan respuestas rápidas y mejoran la interacción con el cliente, gracias a su capacidad de personalización.</p> 
            <p class=" md:mb-8 mb-10"><b>Muchas empresas ya tienen chatbots pero es indispensable que los profesionales de la ciencia de datos e ingeniería estén actualizados.</b> Las empresas también deben cumplir con la normatividad de la Privacidad de datos para mejorar la experiencia del cliente.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Sostenibilidad y responsabilidad social corporativa</h2>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Elige la integridad: Políticas Ambientales, Sociales y de Gobernanza (ASG)</h3>
            <p class=" md:mb-4 mb-6"><b>La responsabilidad social y la sostenibilidad corporativa</b> no sólo mejoran la reputación de una empresa al identificar riesgos, sino que también fomentan un cambio social positivo.</p>
            <p class=" md:mb-4 mb-6">Además, <b>la dedicación de la empresa a los derechos laborales, la inclusión, el voluntariado y la equidad salarial</b> son importantes para ganar el respaldo y la lealtad de sus empleados.</p>
            <p class=" md:mb-4 mb-6"><b>En cuanto a las políticas ambientales,</b> una empresa debe ser responsable con su entorno: no generar desechos, evitar la deforestación. Debe comprender que el cambio climático tiene consecuencias en el mundo. Cada vez más empresas consideran que hay que ser parte de la solución y no del problema.</p>
            <p class=" md:mb-4 mb-6">Y por último, <b>la gobernanza responsable se refiere a los esfuerzos que hace la empresa para una gestión transparente</b> y de cumplimiento. Esto se logra con procesos contables precisos, diversidad en el liderazgo y no por favoritismos, y la rendición de cuentas de los accionistas.</p>
            <p class=" md:mb-8 mb-10">Actualmente, estos tres ejes tienen un papel central en las empresas, pues los empleados, los inversionistas y la comunidad exigen que éstas rindan cuentas.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Las habilidades que requiere la Administración</h2>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Educación continua y aprendizaje adaptativo</h3>
            <p class=" md:mb-8 mb-10">La actualización es la clave del éxito. <b>Considera las plataformas de aprendizaje en línea, o las certificaciones especializadas y diplomados,</b> para desarrollarte profesionalmente y tener ventajas competitivas en el mundo de los negocios.</p> 
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Adaptación a la cultura de innovación y cambio</h3>
            <p class=" md:mb-8 mb-10">El cambio cultural, la capacidad para innovar y adaptarse no es sólo deseable, hoy es fundamental. <b>Ten apertura a nuevas ideas y experimenta las tendencias actuales de la administración.</b> La flexibilidad es muy importante.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Las redes profesionales y colaboraciones estratégicas</h3>
            <p class=" md:mb-4 mb-6">Construir redes sólidas <b>potencia tu impacto en el campo de la administración y la colaboración fomenta un intercambio de conocimientos,</b> experiencias y oportunidades.</p> 
            <p class=" md:mb-8 mb-10"><b>Las tendencias actuales de la administración en 2024</b> ofrecen oportunidades y desafíos. Tú puedes ser parte de la transformación y dejar tu huella en el futuro del sector.</p>
            <!-- Aqui va el componente de contacto, no se te olvide hacerlo manolo -->
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
              <Contacto class="m-3"/>
            </div>
            <p class="text-htwo text-left mb-6">Explora más: <a href="https://humanitas.edu.mx/licenciatura-en-administracion" target="_blank" class="text-htwo">Licenciatura en Administración</a></p>
            <p class="text-htwo text-left mb-20">Te puede interesar: <router-link to="/blog/aprende/tendencias/tendencias-actuales-del-derecho-2024"  class="text-htwo">Tendencias actuales del Derecho en 2024</router-link></p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="true" :linkAnt="'/aprende/tips/mejores-tecnicas-estudio-para-universidad'" :linkSig="'/academico/licenciatura/beneficios-carreras-universitarias-cortas'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import Contacto from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue'
import SigArt from '../../pagesComponents/SigArt.vue';

export default {
    components: { Comentarios, ConComentarios, Contacto, LikesVue, ShareP, SigArt},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '04-ART-APRENDE-TENDENCIAS-310124',
            ruta: '/aprende/tendencias/tendencias-administracion-empresas-2024',
            title: 'Estas son las tendencias clave en la administración en 2024, descúbrelas.',
            image: 'https://blog.humanitas.edu.mx/images/Tendencias-administración-empresarial-2024-multinube.jpg',
            text: 'Entérate de las tendencias más relevantes que dan forma al futuro de la administración de empresas en 2024.'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Ruta relativa de la imagen
        const relativeImageUrl = this.image;

        // Convertir la ruta relativa en una URL absoluta
        const imageUrl = relativeImageUrl;

        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx/aprende/tendencias/tendencias-administracion-empresas-2024';

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = imageUrl;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>