import enero from "../../pages/Blog/Historico/2024/1-enero2024.vue"
import febrero from "../../pages/Blog/Historico/2024/2-febereo2024.vue"
import marzo from "../../pages/Blog/Historico/2024/3-marzo2024.vue"
import abril from "../../pages/Blog/Historico/2024/4-abril2024.vue"
import mayo from "../../pages/Blog/Historico/2024/5-mayo2024.vue"
import junio from "../../pages/Blog/Historico/2024/6-junio2024.vue"
import julio from "../../pages/Blog/Historico/2024/7-julio2024.vue"
import agosto from "../../pages/Blog/Historico/2024/8-agosto2024.vue"

export default [
    {
        path: '/blog/historico/2024/enero',
        name: 'Enero 2024',
        component: enero,
        meta: {
            requireAuth: false
        },
    },
    {
        path: '/blog/historico/2024/febrero',
        name: 'Febrero 2024',
        component: febrero,
        meta: {
            requireAuth: false
        },
    },
    {
        path: '/blog/historico/2024/marzo',
        name: 'Marzo 2024',
        component: marzo,
        meta: {
            requireAuth: false
        },
    },
    {
        path: '/blog/historico/2024/abril',
        name: 'Abril 2024',
        component: abril,
        meta: {
            requireAuth: false
        },
    },
    {
        path: '/blog/historico/2024/mayo',
        name: 'Mayo 2024',
        component: mayo,
        meta: {
            requireAuth: false
        },
    },
    {
        path: '/blog/historico/2024/junio',
        name: 'Junio 2024',
        component: junio,
        meta: {
            requireAuth: false
        },
    },
    {
        path: '/blog/historico/2024/julio',
        name: 'Julio 2024',
        component: julio,
        meta: {
            requireAuth: false
        },
    },
    {
        path: '/blog/historico/2024/agosto',
        name: 'Agosto 2024',
        component: agosto,
        meta: {
            requireAuth: false
        },
    },
]