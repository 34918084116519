<template>
  <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] py-16">
    <div class="flex justify-between italic text-[18px] text-[#999999]">
        <div><router-link v-if="ant" :to="'/blog'+linkAnt" class="italic text-[18px] flex items-center text-[#999999] hover:text-htwo"><span class="less-than">&lt;</span> Artículo Anterior</router-link></div>
        <div><router-link v-if="sig" :to="'/blog'+linkSig" class="italic text-[18px] flex items-center text-[#999999] hover:text-htwo">Siguiente Artículo <span class="greater-than">&gt;</span></router-link></div>
    </div>
  </div>
</template>

<script lang="js">
export default {
    props: {
        linkSig: String,
        linkAnt: String,
        sig: Boolean,
        ant: Boolean
    }
}
</script>

<style scoped>
.greater-than, .less-than {
  font-size: 1.2em; /* Ajusta el tamaño según sea necesario */
  letter-spacing: 0.1em; /* Ajusta el espaciado entre letras según sea necesario */
}
</style>