<script lang="ts" setup>
    // import { Ref } from 'vue';
    import { ref } from 'vue';
    import router from '@/router';
    import useAuth from '@/store/AuthStore';
    
    const store = useAuth()
    const email = ref('')
    const password = ref('')
    const feedback = ref('')

    const loginUser = async () => {
        const response = await store.login(email.value, password.value)
        console.log(response)
        if(response == false) {
            feedback.value = 'Login error'
        } else {
            router.push({name: 'Administrador'})
        }
    }
</script>


<template>
    <section class="flex justify-center items-center w-full">
        <div class="flex items-center justify-center flex-col my-32 md:px-0 w-full max-w-[420px]">
            <label class="mt-12 md:mt-4 mb-4 text-black text-2xl">Login</label>
            <section class="login-box form-container border w-full border-black relative z-10">
                <form id="form-comments">
                    <div>
                        <div class="user-box">
                            <i class="fas fa-user"></i>
                            <input type="email" name="email" autocomplete="off" id="email" required maxlength="60" class="ml-[25px]" v-model="email">
                            <label class="ml-[25px]">Email</label>
                        </div>
                        <div class="user-box">
                            <i class="fas fa-lock pr-2"></i>
                            <input type="password" name="password" autocomplete="off" id="password" required maxlength="12" class="ml-[25px]" v-model="password">
                            <label class="ml-[25px]">Contrase&ntilde;a</label>
                        </div>
                        <div class="flex flex-col ml-10 items-center justify-center">
                            <button type="submit" class="bg-hwine rounded-none w-[160px] h-[45px] items-center justify-center text-white hover:bg-hgold hover:text-white text-[16px] focus:outline-none transition duration-300 ease-in-out" @click.prevent="loginUser">Ingresar</button>
                        </div>
                    </div>
                </form>
            </section>
        </div>
    </section>
</template>


<style scoped>
    .login-box {
        box-sizing: border-box;
        border: #797979;
        background: transparent;
        z-index: 1;
        margin-right: 45px;
    }

    .login-box h2 {
        padding: 0;
        color: transparent;
        text-align: center;
    }

    .login-box .user-box {
        position: relative;
        z-index: 1;
    }

    .login-box .user-box i {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 10px;
        color: #881912;
    }

    .form-container {
        position: relative;
        z-index: 1;
    }

    .login-box .user-box input,
    .login-box .user-box select,
    .login-box .user-box textarea {
        width: 100%;
        padding: 10px 10px; /* Ajusta según tu preferencia */
        font-size: 16px;
        color: #797979;
        margin-bottom: 30px;
        border: none;
        border-bottom: 1px solid #797979;
        outline: none;
        background: transparent;
    }

    .login-box .user-box label {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        padding: 10px 0;
        font-size: 16px;
        color: black;
        pointer-events: none;
        transition: .5s;
    }

    .login-box .user-box input.error-border,
    .login-box .user-box select.error-border,
    .login-box .user-box textarea.error-border {
        border-bottom: 1px solid #881912 !important;
    }

    .login-box .user-box label.error{
        color: #881912;
        font-size: 12px;
    }

    input:focus:not(:placeholder-shown) {
        background-color: transparent; 
    }

    .login-box .user-box input:focus ~ label,
    .login-box .user-box input:valid ~ label,
    .login-box .user-box select:focus ~ label,
    .login-box .user-box textarea:focus ~ label {
        top: -25px;
        left: 0;
        color: #CAAB55;
        font-size: 12px;
    }
</style>