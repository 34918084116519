<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">Licenciatura de Psicología en línea: una opción <br class="md:block hidden">para ti</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-300">
          <img src="/images/licenciatura-psicologia-en-linea-una-opcion-humanitas.webp" alt="La licenciatura en Psicología en línea te ofrece una formación integral y flexible" class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/academico" class=" text-hone">Académico</router-link> > <router-link to="/blog/academico/estudia-en-linea" class=" text-hone">Estudia en L&iacute;nea</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex list-disc pl-5 italic text-left text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">04 de junio de 2024</li>  <li>Carolina Reyes Martínez</li></ul>
            </div>
            <p class=" md:mb-4 mb-6">La licenciatura en Psicología en línea o virtual, ofrece una formación integral y flexible para quienes estén interesados en comprender la mente humana en toda su amplitud. Aquí te decimos todo sobre esta modalidad.</p>
            <p class=" md:mb-4 mb-6"><b>La Psicología es una disciplina fascinante que nos permite entender mejor el comportamiento humano y los procesos mentales:</b> desde el desarrollo infantil hasta la vejez; desde las funciones del cerebro hasta el comportamiento de las naciones.</p>
            <p class=" md:mb-8 mb-10">Con la evolución de la educación en línea, ahora <b>es posible obtener la licenciatura de Psicología desde tu hogar o de manera virtual,</b> ya sea porque eres una persona autodidacta o porque ya estás trabajando y quieres tener un mejor futuro pero no tienes el tiempo para estar en la modalidad presencial.</p>
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
              <Contacto class="m-3"/>
            </div>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">¿Por qué estudiar Psicología en línea?</h2>
            <p class=" md:mb-4 mb-6"><b>Estudiar Psicología en línea (o de manera virtual) ofrece ventajas para quienes buscan una educación flexible y de calidad.</b> Esta modalidad permite compaginar los estudios con otras actividades, como el trabajo o la familia. Además, proporciona el acceso a recursos educativos de vanguardia y la posibilidad de aprender a tu propio ritmo.</p>
            <p class=" md:mb-4 mb-6">Y es que existen algunos mitos sobre la educación virtual, especialmente en las áreas de la salud, debido a que se cree que es mejor adquirir el conocimiento de forma presencial, sin embargo, <b>estudiar Psicología online hoy en día es posible gracias a los avances en comunicación y los métodos de enseñanza,</b> que facilitan una transmisión más efectiva de la información.</p>
            <p class=" md:mb-8 mb-10">La Psicología, al estudiar y recopilar los hechos sobre la conducta y la experiencia humana mediante el método científico, es una licenciatura que se adapta a la modalidad virtual.</p>
            <img src="/images/licenciatura-psicologia-linea-beneficios-para-ti.webp" alt="La licenciatura en Psicología en línea o virtual tiene planes de estudio adecuados a tus necesidades" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Plan de estudios de la licenciatura de Psicología en línea</h2>
            <p class=" md:mb-4 mb-6">Con respecto al contenido de los programas en esta modalidad, también se cree que no son tan completos como en el sistema escolarizado, sin embargo, <b>el contenido de los planes de estudio en esta modalidad, está diseñado para ofrecer las herramientas que permitan un correcto desempeño profesional.</b></p>
            <p class=" md:mb-4 mb-6">Al tener acceso a internet y a la naturaleza de las plataformas digitales, es posible tener a la mano materiales actualizados de forma más rápida y constante.</p>
            <p class=" md:mb-4 mb-6"><b>Un estudiante de licenciatura en línea también tiene el apoyo de un profesor</b> a quien puede contactar para resolver sus dudas o simplemente, para reforzar el conocimiento adquirido con el material proporcionado por la universidad.</p>
            <p class=" md:mb-8 mb-10">Los estudiantes adquieren conocimientos teóricos y prácticos que les permiten entender y aplicar los principios psicológicos en diferentes contextos</p>
            <img src="/images/licenciatura-psicologia-linea-desarrollo-profesional.webp" alt="La licenciatura de Psicología en línea o virtual te proporciona una sólida base profesional" class="md:mb-8 mb-10">
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-1 text-hone">Materias clave en la licenciatura en Psicología</h3>
            <p class=" md:mb-4 mb-6">Entre las materias que componen el Plan de estudios de la licenciatura en Psicología, se encuentran:</p>
            <ul class="list-disc pl-6 md:mb-4 mb-6">
                <li>Introducción a la Psicología</li>
                <li>Metodología de la investigación</li>
                <li>Procesos biológicos</li>
                <li>Procesos psicológicos</li>
                <li>Desarrollo humano</li>
                <li>Psicología social y comunitaria</li>
                <li>Psicoanálisis</li>
                <li>Psicología Educativa</li>
                <li>Psicología jurídica</li>
                <li>Psicología clínica</li>
                <li>Neuropsicología</li>
            </ul>
            <p class=" md:mb-8 mb-10">Estas materias, entre otras, proporcionan una base sólida para los futuros psicólogos.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Beneficios de estudiar Psicología a distancia</h2>
            <p class=" md:mb-4 mb-6">La modalidad de educación a distancia ofrece varios beneficios:</p>
            <ul class="list-disc pl-6 md:mb-8 mb-10">
                <li><b>Flexibilidad de horarios:</b> Puedes estudiar en los momentos que mejor se adapten a tu rutina.</li>
                <li><b>Menor inversión económica:</b> A diferencia del sistema escolarizado, las inscripciones son más accesibles y sólo inviertes en lo que necesitas.</li>
                <li><b>Te ahorrarás en el traslado:</b> No necesitas acudir, pues las clases en línea son ideales si estás en otra ciudad e incluso en otro país.</li>
                <li><b>Acceso a recursos digitales:</b> Materiales de estudio disponibles en cualquier momento, hora y lugar gracias a internet y a las plataformas educativas diseñadas para esta modalidad.</li>
                <li><b>Networking global:</b> Conéctate con estudiantes y profesionales de todo el mundo.</li>
            </ul>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Conclusión</h2>
            <p class=" md:mb-8 mb-10">Estudiar una licenciatura en Psicología en línea <router-link to="/blog/academico/estudia-en-linea/estudia-tu-carrera-en-linea-beneficios"  class="text-htwo">es una excelente opción para quienes buscan una educación de calidad con la flexibilidad</router-link> que ofrece la modalidad a distancia. Con un plan de estudios muy completo y materias que cubren todos los aspectos fundamentales de la Psicología,te prepararás para una profesión enriquecedora y fundamental para la sociedad.</p>
            <p class="text-htwo text-left mb-20">Explora más: <a href="https://www.humanitas.edu.mx/virtual" target="_blank" class="text-htwo">Modelo virtual</a></p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="true" :linkAnt="'/recursos/entrevistas-y-conferencias/caracteristicas-de-la-mediacion'" :linkSig="'/academico/licenciatura/tipos-de-contabilidad'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import Contacto from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue'
import SigArt from '../../pagesComponents/SigArt.vue';

export default {
    components: { Comentarios, ConComentarios, Contacto, LikesVue, ShareP, SigArt},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '0037-ART-ACADEMICO-EDUCACION-EN-LINEA-040624',
            ruta: '/academico/estudia-en-linea/licenciatura-psicologia-en-linea',
            title: 'Descubre la licenciatura de Psicología en línea y por qué puede ser para ti',
            image: 'https://blog.humanitas.edu.mx/images/Estudia-tu-carrera-en-linea.jpg',
            text: '¿Te interesa estudiar Psicología en línea? Entérate por qué puede ser la mejor opción para ti.'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx'+this.ruta;

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style></style>