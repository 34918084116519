<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">Educación continua: ¿Qué es?</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-300">
          <img src="/images/educacion-continua-universitaria.webp" alt="¿Qué es la educación continua?, una pregunta clave para profesionales." class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/academico" class=" text-hone">Académico</router-link> > <router-link to="/blog/academico/educacion-continua" class=" text-hone">Educación continua</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex list-disc pl-5 italic text-left text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">27 de junio de 2024</li>  <li>Iván Solís Rivera</li></ul>
            </div>
            <p class=" md:mb-4 mb-6">La comunidad universitaria continuamente se encuentra ante el cuestionamiento: Educación continua: ¿Qué es?, ya que consideran importante la <b>constante actualización de conocimientos y habilidades para aplicarlos en un ámbito práctico y profesional.</b></p>
            <p class=" md:mb-8 mb-10"><b>La educación continua representa una serie de experiencias de aprendizaje que se llevan a cabo después de completar la educación formal,</b> particularmente en la formación universitaria.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Objetivos de la educación continua</h2>
            <p class=" md:mb-4 mb-6">Generalmente, la educación continua es para aquellos <b>interesados que buscan ampliar su conocimiento en áreas complementarias en su desarrollo laboral o profesional.</b></p>
            <p class=" md:mb-4 mb-6">Esta forma de educación <b>funciona como una extensión de los espacios formales de aprendizaje y responde a las necesidades de actualización de conocimientos.</b></p>
            <p class=" md:mb-8 mb-10">Uno de sus principales objetivos es fomentar la profesionalización en su respectiva área profesional, ya que <b>permite explorar nuevos campos del conocimiento que complementan la formación de los alumnos para concentrarse en el desarrollo de nuevas competencias, conocimientos y habilidades.</b></p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Modalidades educativas</h3>
            <p class=" md:mb-4 mb-6">Las <router-link to="/blog/academico/licenciatura/universidades-sin-examen-admision" class=" text-htwo hover:text-hone">universidades privadas y públicas</router-link> con un cuerpo docente plenamente capacitado tienen la facultad de ofrecer la educación extensiva especializada.</p>
            <p class=" md:mb-4 mb-6">En la época actual, <b>la educación continua se apalanca de herramientas y plataformas tecnológicas para concentrarse en el desarrollo de nuevas competencias, conocimientos y habilidades.</b></p>
            <p class=" md:mb-8 mb-10">Ante tal contexto, <b>las instituciones educativas ofrecen diplomados, seminarios y cursos virtuales que se presentan como una opción educativa fuera del sistema formal, pero ofrece alta calidad académica y flexibilidad,</b> adaptándose a las necesidades de personas que generalmente ya están integradas en el ámbito laboral.</p>
            <!-- Aqui va el componente de contacto, no se te olvide hacerlo manolo -->
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
              <Contacto class="m-3"/>
            </div>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">¿Qué es la educación permanente y cuál es su diferencia con la educación continua?</h2>
            <p class=" md:mb-4 mb-6">Por una parte, <b>la educación permanente se refiere a un sistema integral educativo que abarca toda la vida del individuo,</b> por lo que se basa en que la educación es un proceso continuo y constante que <b>no se limita a una etapa específica de la vida,</b> sino que se extiende desde la infancia hasta la vejez.</p>
            <p class=" md:mb-4 mb-6">Este concepto parte de la premisa en que las personas deben tener oportunidades para aprender y desarrollarse a lo largo de todas las fases de su vida. <b>La educación permanente abarca dos principales rubros: educación formal</b> (escuela, bachillerato, universidad) y <b>educación informal</b> (aprendizaje autodidacta, experiencias de vida, aprendizaje en el trabajo).</p>
            <p class=" md:mb-4 mb-6">Por otro lado, <b>la educación continua se centra específicamente en la educación y formación que se realizan después de haber completado la educación formal,</b> está orientada hacia el perfeccionamiento de conocimientos y habilidades en un contexto profesional.</p>
            <p class=" md:mb-8 mb-10"><b>Los programas de educación continua están diseñados para: Adquirir nuevas competencias y habilidades relevantes para el campo profesional;</b> explorar áreas complementarias y especializadas para el desarrollo profesional, así como adaptarse a cambios, avances tecnológicos, científicos y metodológicos en el sector laboral.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Ventajas de la educación continua</h3>
            <p class=" md:mb-4 mb-6">Uno de los <b>beneficios que tiene la educación continua es que facilita la adquisición de nuevas competencias y habilidades</b> que pueden abrir nuevas oportunidades de carrera.</p>
            <p class=" md:mb-4 mb-6">Los programas de educación continua <b>suelen ofrecer horarios flexibles y modalidades de estudio variadas (presencial, en línea o híbrido)</b>, lo cual es ideal para personas que ya están trabajando.</p>
            <p class=" md:mb-8 mb-10">De la misma forma, <b>proporciona una oportunidad para el desarrollo intelectual, además de abrir la posibilidad de conocer a otros profesionales del mismo campo,</b> lo cual puede conducir a la creación de redes de contacto valiosas para futuras oportunidades laborales y colaboraciones.</p>
            <img src="/images/diplomados-tienen-valor-curricular.jpg" alt="Los diplomados tienen valor curricular y son valiosos en el campo laboral." class="md:mb-8 mb-10">
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Desventajas</h3>
            <p class=" md:mb-4 mb-6">Los programas de educación continua tienen pocas desventajas, entre ellas, se encuentra que los <b><router-link to="/blog/academico/educacion-continua/que-es-un-seminario-todo-lo-que-quieres-saber" class=" text-htwo hover:text-hone">cursos y seminarios</router-link> pueden ser costosos,</b> o cual puede ser una barrera para algunos estudiantes y profesionales.</p>
            <p class=" md:mb-8 mb-10"><b>De la misma forma, la calidad de los programas de educación continua puede variar entre diferentes instituciones y proveedores,</b> lo que puede afectar la efectividad del aprendizaje.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Valor curricular y la educación continua</h2>
            <p class=" md:mb-4 mb-6"><b>Valor curricular y la educación continua van correlacionadas,</b> el primer término equivale a la acreditación del titular que demuestra que ha desarrollado habilidades, competencias y conocimientos significativos en una área particular.</p>
            <p class=" md:mb-4 mb-6"><b>La acreditación de las competencias y habilidades son respaldadas por autoridades educativas,</b> en el caso de México, se da a través de la Secretaría de Educación Pública (SEP). Al momento de que se extienden las constancias o documentos que acrediten el cumplimiento de las competencias y habilidades, se le da un valor curricular a los cursantes.</p>
            <p class=" md:mb-8 mb-10"><b>El valor curricular equivale a la capacidad y preparación de una persona en un campo específico,</b> por lo que el participante ha adquirido habilidades, competencias y conocimientos específicos en el área en cuestión.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Centro de Educación Continua Humanitas</h3>
            <p class=" md:mb-8 mb-10"><b>El <router-link to="/educacion-continua" target="_blank" class=" text-htwo hover:text-hone">Centro de Educación Continua de la Universidad Humanitas</router-link> ofrece una oportunidad permanente para aquellos que desean actualizarse o capacitarse</b> en un área de especialización profesional. Esto se realiza a través de diplomados, cursos, talleres, seminarios y conferencias impartidos por especialistas reconocidos.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Áreas de estudio</h2>
            <p class=" md:mb-4 mb-6">La educación continua <b>abarca una amplia gama de conocimientos y habilidades diseñados para satisfacer las necesidades de desarrollo profesional</b> y personal de la comunidad universitaria.</p>
            <p class=" md:mb-8 mb-10">Existen programas en <b>áreas como negocios, tecnología, salud, artes, idiomas, y ciencias sociales, entre otras.</b> Estos programas están estructurados para proporcionar conocimientos actualizados y relevantes, así como para mejorar las competencias prácticas necesarias en el mercado laboral actual.</p>
            <img src="/images/educacion-permanente-habilidades-universitarios.webp" alt="El valor curricular respalda las competencias y habilidades de los estudiantes." class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Conclusión</h2>
            <p class="mb-20"><b>La educación continua implica la oferta de cursos para abordar las tendencias emergentes</b> y las demandas específicas de diferentes industrias, asegurando así que nuestros estudiantes se mantengan competitivos y bien preparados en sus respectivos campos.</p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="true" :linkAnt="'/academico/licenciatura/mejores-universidades-para-estudiar-psicologia-en-mexico'" :linkSig="'/aprende/cultura-general/dia-mundial-redes-sociales-30-junio-2024'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import Contacto from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue'
import SigArt from '../../pagesComponents/SigArt.vue';

export default {
    components: { Comentarios, ConComentarios, Contacto, LikesVue, ShareP, SigArt},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '0052-ART-ACADEMICO-EDUCACION-CONTINUA-270624',
            ruta: '/academico/estudia-en-linea/educacion-continua-que-es',
            title: 'Educación continua: ¿Qué es y porqué es importante?',
            image: 'https://blog.humanitas.edu.mx/images/Como-impacta-tu-vida-profesional-un-diplomado.jpg',
            text: 'Descubre qué es la educación continua y cómo puede ayudarte a mantenerte actualizado en tu carrera profesional.'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx'+this.ruta;

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>