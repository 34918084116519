<script lang="ts">
    import { defineComponent } from "vue";
    import Botones from "./Botones.vue";
    import VideoHiL from "./VideoHiL.vue";

    export default defineComponent({
        components: { Botones, VideoHiL},
        data() {
            return {
                showImage: true,
                videoUrl: "https://www.youtube.com/embed/kM6yarkMYr8?si=b8-0nC3xH0j-7yEn",
                buttons : {
                    button1: { imageUrl: "../pagesImages/inscribete_white.png", hoverImageUrl: "../pagesImages/inscribete_on.png" },
                },
                videoThumbnail: "../pagesImages/play-icon.png",   
            };
        },
        methods: {
            showVideo() {
                this.showImage = false;
            },
            
        },
    });
</script>

<template>
    <section class="flex justify-center items-center w-full h-full bg-cover bg-[center] bg-no-repeat" style="background-image: url(../pagesImages/persona.jpg);">
        <div class="lg:flex justify-between w-full max-w-4xl lg:max-w-[1200px] items-center">
            <div class="flex flex-col lg:justify-start justify-center items-center lg:items-start lg:w-[40%] w-full lg:my-36 my-12 text-justify px-4">
                <h1 class="text-[white]  text-left text-[28px]" style="">Ser una persona en</h1>
                <h1 class="text-[#c4a96c] text-left font-serif mb-2 text-[46px]" style="font-family: Georgia, Palatino, Palatino Linotype, Times, Times New Roman, serif;">Humanitas</h1>
                <p class="text-white text-base">
                    Dignidad humana significa que un individuo siente respeto por s&iacute; mismo y se valora al mismo tiempo que es respetado y valorado. Implica la necesidad de que todos los seres humanos sean tratados en un pie de igualdad y que puedan gozar de los derechos fundamentales que de ellos derivan.
                </p>
                <br>
                <router-link to="/contacto" class="">
                    <div class="flex items-center justify-center w-[140px] h-[35px] bg-white/70 hover:bg-hgold text-hwine border-2 border-hwine text-lg transition duration-300 ease-in-out">
                        <span>Inscr&iacute;bete</span>
                    </div>
                </router-link>
            </div>
            <div class="flex flex-col items-center justify-center lg:w-[30%] w-full my-16 ">
                <div class="flex justify-center w-full h-full items-center ">
                    <VideoHiL :thumbnailUrl="videoThumbnail" :videoUrl="videoUrl" class="w-[100%]"/>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
</style>