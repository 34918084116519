<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">Emprende la aventura de tu vida<br class="md:block hidden"> con liderazgo visionario</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-200">
          <EntrevistasVue :image="'/images/A-Emprende-aventura-liderazgo-visionario-albert-bosch.jpg'" :videoSrc="videoSrc" class="mb-6 cursor-pointer"/>
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/recursos" class=" text-hone">Recursos</router-link> > <router-link to="/blog/recursos/entrevistas-y-conferencias" class=" text-hone">Entrevistas y Conferencias</router-link></p>
            <div class="lg:flex text-left items-start justify-start">
                <ul class="lg:flex list-disc pl-5 italic text-left text-[18px] md:text-[20px] mb-6 font-helvetica"><li class="mr-16 text-htwo">18 de enero de 2024</li>  <li>Carolina Reyes Martínez</li></ul>
            </div>
            <p class=" md:mb-8 mb-10"><b>Extracto:</b> Albert Bosch, aventurero y líder visionario, muestra cómo la visión y la pasión pueden guiar a equipos y proyectos hacia metas extraordinarias. Tú también puedes aplicar estas lecciones durante tus estudios universitarios.<br>
            <b>Resumen:</b> La universidad es una etapa de retos y desafíos. Descubre cómo el liderazgo visionario puede ayudarte a ser el emprendedor de tu propia vida, con el ejemplo de Albert Bosch, ya que puede ayudarte en tu viaje académico. Inspírate</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">¿Quién es Albert Bosch?</h2>
            <p class="md:mb-4 mb-6">Albert Bosch <b>es un aventurero, emprendedor y escritor catalán</b> muy reconocido y su historia inicia en Sant Joan de les Abadesses en Girona, España, una localidad cerca de Los Pirineos, en donde escalar montañas y estar libre en la naturaleza lo llenó de pasión por los deportes, <b>de pasión por emprender en la vida real: de ser el protagonista y no sólo un espectador.</b></p>
            <p class="md:mb-8 mb-10">Así, <b>él comenzó a competir en diversos deportes y proyectos extremos:</b> ha cruzado la Antártida sin asistencia hasta el Polo Sur y en solitario; ha realizado el 7 Summits, que es escalar la montaña más alta de cada continente con el Everest como punto final. Participó en nueve Dakars (dos en moto y siete en auto). Habitualmente participa en ultramaratones y continúa desarrollando nuevos proyectos.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">El liderazgo visionario y la aventura</h2>
            <p class="md:mb-4 mb-6">Al participar en diversos eventos, a Albert lo presentaban a veces como triatleta, como piloto de rallies en Dakar, como escalador de montañas, sin embargo, obtuvo la denominación de aventurero por un periodista. Fue cuando él pensó que sí lo era al dedicarse a los deportes extremos y decidió agregar a la aventura en su filosofía de vida.</p>
            <p class="md:mb-4 mb-6">Sin embargo, un día, sintió frustración porque si bien tenía un trabajo, sentía que no podía vivir de lo que realmente le apasionaba y no iba a renunciar a que el deporte fuera sólo un hobby. Por ello, decidió aplicar otras capacidades y así, <b>Albert incorporó a sus proyectos el liderazgo visionario, el marketing, la gestión y la comunicación.</b> Estas habilidades le permitieron liderar sus proyectos <b>sumando el concepto que le dio aún más motivación: la aventura.</b></p>
            <p class="md:mb-4 mb-6">También se dio cuenta que el deporte es muy competitivo y hasta cierto punto con resultados estables, sin embargo, <b>la aventura es más extensa y le ha permitido un liderazgo visionario: liderar sus proyectos realizando un business plan,</b> o sea la hoja de ruta que le ayudó a articular una estrategia con los pasos a seguir, así como un cronograma de trabajo para sus expediciones, por ejemplo, para ir al Polo Sur.</p>
            <p class="md:mb-4 mb-6">A esta filosofía la denomina <b>ser el emprendedor de tu vida al cumplir tus sueños,</b> que se pueden bajar a objetivos y así desarrollar un proyecto con gestión y organización, con una visión realista y de anticipación a circunstancias adversas. </p>
            <p class="md:mb-8 mb-10"><b>Y si bien Albert sabe que existen riesgos, eso es la aventura: asumirlos y aprender de ellos, así como de los fracasos,</b> ya que éstos también deben ser considerados para obtener grandes aprendizajes.</p>
            <img src="/images/Liderazgo-visionario-trabajo-equipo.jpg" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">¿Qué es el liderazgo visionario?</h2>
            <p class="md:mb-4 mb-6">Al hablar de las habilidades de Albert y cómo las ha aplicado en sus proyectos de aventura y deportes extremos, éstas <b>coinciden con el término de liderazgo visionario</b> que consiste, según Daniel Goleman, en lo siguiente: es un tipo de liderazgo en el cual la visión es la brújula que <b>llevará al equipo a alcanzar las metas</b> y por eso se requiere de un líder que comprenda las necesidades actuales de motivación, inspiración y visión comunitaria que ayudará a crear los resultados para destacar entre los demás.</p>
            <p class="md:mb-8 mb-10">Este tipo de liderazgo propone una visión realista del futuro. <b>El líder visionario debe buscar un equilibrio entre el ideal de una empresa y la realidad que puede alcanzar.</b> Este estilo de liderazgo exige que el líder siempre esté innovando y motivando a trabajar con una actitud de perseverancia y persuasión.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Características de un líder visionario:</h2>
            <ul class="list-disc pl-6 md:mb-8 mb-10">
                <li>Tienen metas claras y determinadas.</li>
                <li>Son coherentes con lo que dicen y hacen.</li>
                <li>Inspiran confianza en los demás porque son percibidas como personas responsables y capaces de cumplir sus promesas.</li>
                <li>Son considerados como un modelo a seguir. Es un guía para que el equipo logre sus metas.</li>
                <li>Es un líder ideal para el trabajo en equipo ya que sabe escuchar, detecta las fortalezas de las personas por lo que reconoce a las personas valiosas y las integra a su equipo.</li>
                <li>Son persuasivos, saben cómo transmitir sus ideas con claridad.</li>
                <li>Se involucran totalmente con la meta. Los líderes visionarios confían en el objetivo propuesto se puede realizar si se preparan y siguen las estrategias y la planificación.</li>
                <li>Son personas íntegras ya que es necesario que sean honestos y confiables.</li>
            </ul>
            <!-- Aqui va el componente de contacto, no se te olvide hacerlo manolo -->
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
                <Contacto class="m-3"/>
            </div>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">¿Cómo podrías incorporar la aventura y el liderazgo visionario en tu vida? </h2>
            <p class="mb-6">Primero, <b>dale play a la entrevista completa con Albert Bosch</b> y para complementarla, te damos <b>estas claves adicionales:</b></p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Perseverancia y resiliencia</h3>
            <p class="md:mb-4 mb-6">Si tienes un sueño, no lo dejes de lado sólo porque pienses que es inaccesible. <b>La clave está en la resiliencia:</b> la capacidad de recuperarte frente a los obstáculos.</p>
            <h3 class="text-[20px] lg:text-[24px] leading-tight mb-5 text-left font-serif text-hone">Trabaja en tus sueños, reconoce tus capacidades y canalízalas hacia tu objetivo.</h3>
            <p class="md:mb-4 mb-6">Recuerda que la vida es más rica cuando eres constante en lograr tus metas. <b>Usa esta etapa de la universidad para potenciar tus habilidades.</b> No olvides que los mayores desafíos se convierten en oportunidades.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">La aventura universitaria y el liderazgo estratégico</h3>
            <p class="mb-2">Así como Albert Bosch ha viajado por la Antártida, <b>tu paso por la universidad es una aventura épica</b> donde no sólo compites académicamente, también tomas las riendas de tu destino.</p>
            <p class="md:mb-4 mb-6"><b>Desarrolla tus capacidades de liderazgo estratégico y colaboración, haz trabajo en equipo.</b> Del liderazgo visionario atrévete a asumir riesgos, acepta que existe la incertidumbre y conoce otras disciplinas. Como Albert, acepta los fracasos ya que éstos son lecciones valiosas, ya que en el mundo real te enfrentarás a ellos. Sé el protagonista y no sólo un espectador.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">El liderazgo visionario y el aprendizaje</h3>
            <p class="md:mb-4 mb-6"><b>Este tipo de liderazgo</b> como ya lo mencionamos con el caso de Albert Bosch, no se limita a los negocios y conferencias: <b>es esencial en tu viaje educativo.</b> Encuentra lo que te apasiona y establece metas alineadas con ello. Para ello, <b>encontrar tu propósito y hacer el match con tu carrera universitaria</b> te ayudará a saber cuáles son tus talentos.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Conecta con la naturaleza y la comunidad</h3>
            <p class="md:mb-4 mb-6">Albert Bosch es un aventurero amante de la naturaleza y le interesa preservarla, <b>tú como él puedes encontrar en este aspecto de sustentabilidad la consciencia de cuidar de tu entorno</b> y conectar con él y con las personas a tu alrededor.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Vivir la aventura y el liderazgo auténtico</h3>
            <p class="md:mb-8 mb-10">La historia de Albert Bosch <b>te puede inspirar a ver la vida como una aventura en la cual tú decides los pasos a seguir,</b> a arriesgarte pero sobre todo, a vivirla al máximo. ¡Sé el emprendedor de tu vida y lidera con visión!</p>
            <p class="text-htwo text-left mb-6">Explora más: <a href="https://humanitas.edu.mx/" target="_blank" class="text-htwo">Universidad Humanitas</a></p>
            <p class="text-htwo text-left pb-12">Te puede interesar: <router-link to="/blog/aprende/tips/maximiza-tu-productividad-estilo-bill-gates"  class="text-htwo">Maximiza tu productividad al estilo de Bill Gates</router-link></p>
            <div class="">
              <p class="text-htwo text-left font-bold mb-2">Fuentes:</p>
              <p class="text-left mb-1 text-base"><a href="https://www.youtube.com/watch?v=vZlt2VGNsoc" target="_blank" class="text-black">Entrevista a Albert Bosch</a></p>
            </div>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="false" :linkAnt="''" :linkSig="'/academico/maestria/costo-y-requisitos-estudiar-maestria'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import Contacto from '../../pagesComponents/Contacto.vue';
import EntrevistasVue from '../../pagesComponents/Entrevistas.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue'
import SigArt from '../../pagesComponents/SigArt.vue';


export default {
    components: { Comentarios, ConComentarios, Contacto, EntrevistasVue, LikesVue, ShareP, SigArt},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '01-ART-RECURSOS-EYCONF-180124',
            videoSrc: 'https://www.youtube.com/embed/vZlt2VGNsoc?si=lJmIbz_sTSZfjQyL',
            image: 'https://blog.humanitas.edu.mx/images/Emprende-aventura-liderazgo-visionario-albert-bosch-min.jpg',
            ruta: '/recursos/entrevistas-y-conferencias/emprende-aventura-de-tu-vida-liderazgo-visionario-albert-bosch',
            text: 'Descubre cómo emprender tu vida universitaria con el liderazgo visionario de Albert Bosch y vive tu vida como una aventura.',
            memo: ' (No sirve el link ✧ʕ◔౪◔ʔ✧)'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Ruta relativa de la imagen
        const relativeImageUrl = 'https://blog.humanitas.edu.mx/images/Emprende-aventura-liderazgo-visionario-albert-bosch-min.jpg';

        // Convertir la ruta relativa en una URL absoluta
        const imageUrl = relativeImageUrl;

        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx/recursos/entrevistas-y-conferencias/emprende-aventura-de-tu-vida-liderazgo-visionario-albert-bosch';

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = 'El liderazgo visionario de Albert Bosch en tu vida universitaria';

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = 'Descubre cómo emprender tu vida universitaria con el liderazgo visionario de Albert Bosch y vive tu vida como una aventura.';

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = imageUrl;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>