<script setup lang="ts">
    import VideoHiL from '../VideoHiL.vue';

    interface Props {
        img?: string;
        url?: string;
    }

    const props = withDefaults(defineProps<Props>(), {
        img: '../pagesImages/escolarizado-video2.jpg',
        url: 'https://www.youtube.com/embed/jL83vP0u6hw?si=qy88aP4z-yAp1lYD',
    });
</script>

<template>
    <section class="lg:flex items-center justify-center bg-cover bg-[top_center] bg-no-repeat" style="background-image: url(../pagesImages/madin3.jpg); position: relative; background-attachment: fixed; background-position: center; background-repeat: no-repeat; background-size: cover; overflow: hidden; transition: transform 0.3s ease;">
        <div class="m-12 my-24 lg:w-[30%]">
            <br>
            <p class=" text-[28px] text-left leading-none">Excelencia</p>
            <p class="text-hgold text-[48px] mb-3 text-left font-serif leading-none">Acad&eacute;mica</p>
            <p class="text-justify text-black">La educaci&oacute;n que se imparte en Universidad Humanitas se replantea constantemente metas y expectativas a fin de responder a las necesidades del contexto actual, sirvi&eacute;ndose de diferentes recursos, estrategias y herramientas que permiten el desarrollo de un trabajo acad&eacute;mico independiente y con un alto grado de flexibilidad para desarrollar en sus alumnos j&oacute;venes o adultos las capacidades intelectuales indispensables para el aprendizaje de la profesi&oacute;n.</p>
            <br>
            <router-link to="/contacto">
                <div class="flex items-center justify-center w-[140px] h-[35px] bg-hwine hover:bg-hgold text-white  text-lg transition duration-300 ease-in-out">
                    <span>Inscr&iacute;bete</span>
                </div>
            </router-link>
        </div>
        <VideoHiL :thumbnailUrl="props.img" :videoUrl="props.url" class="lg:p-0 md:px-16"/>
    </section>
</template>

<style lang="scss" scoped>
</style>