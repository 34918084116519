<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">¿Cómo son los doctorados virtuales en México?</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-200">
          <img src="/images/doctorados-virtuales-mexico-estudiantes.webp" alt="Los doctorados virtuales en México son el máximo grado de estudios universitarios" class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/academico" class=" text-hone">Académico</router-link> > <router-link to="/blog/academico/doctorado" class=" text-hone">Doctorado</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex list-disc pl-5 italic text-left text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">19 de junio de 2024</li>  <li>Iván Solís Rivera</li></ul>
            </div>
            <p class=" md:mb-4 mb-6">Explicamos cómo son los <router-link to="/blog/academico/doctorado/guia-todo-sobre-doctorado-perfil-y-competencias" class=" text-htwo hover:text-hone">doctorados</router-link> virtuales en México, considerando que esta modalidad ha tomado cada vez mayor fuerza entre los estudiantes y aspirantes.</p>
            <p class=" md:mb-8 mb-10">También conocido como <b>doctorado en línea, es un programa académico de posgrado que permite a los estudiantes obtener el grado de doctor a través de plataformas digitales,</b> sin necesidad de asistir frecuentemente a una universidad.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Grado de doctor o PhD: ¿Qué significa?</h2>
            <p class=" md:mb-4 mb-6">El doctorado es el título académico más alto que un estudiante o profesional puede obtener. Se le conoce como "Doctor of Philosophy" (PhD, por sus siglas en inglés), <b>refleja un amplio conocimiento y especialización en su campo de estudio.</b></p>
            <p class=" md:mb-4 mb-6"><b>Los estudiantes suelen optar por un doctorado cuando están interesados en una carrera académica o de investigación,</b> o cuando desean alcanzar el máximo nivel educativo posible.</p>
            <p class=" md:mb-8 mb-10">La palabra “Doctor” proviene del latín, docere (lit. ‘enseñar’), lo que significa profesor o docente. Es de recordar que <b>durante la licenciatura, el estudiante aplica, durante la maestría comparte y en el doctorado, genera conocimiento.</b></p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Breve historia del surgimiento del grado de doctor</h2>
            <p class="md:mb-4 mb-6"><b>El título de doctor se originó en la Edad Media y solo se otorgaba en tres áreas de estudio: teología, medicina y leyes.</b></p>
            <p class="md:mb-4 mb-6"><b>Con el tiempo, las universidades comenzaron a extender su variedad de programas de estudio.</b> Esto llevó a que el título de doctor se otorgara también en otras áreas, como las humanidades, las ciencias y las ingenierías.</p>
            <p class="md:mb-4 mb-6">A medida que el título de doctor se generalizaba, surgieron preocupaciones sobre su uso indebido. <b>En particular, en países de habla inglesa, era relativamente fácil para las personas hacerse pasar por médicos,</b> ya que no existían muchas regulaciones en este campo de conocimiento.</p>
            <p class="md:mb-4 mb-6">Se estableció que sólo los médicos podían usar el título de "doctor" en un ámbito público, mientras que los <b>académicos y abogados pasaron a ser llamados de forma coloquial con otros títulos, como "profesor".</b></p>
            <p class="md:mb-8 mb-10">Esta distinción ha persistido hasta el día de hoy, y <b>el título de doctor sigue siendo un indicador de alto nivel de conocimiento</b> y experiencia en campos de conocimiento específicos.</p>
            <img src="/images/doctorado-distancia-ventajas-universidades.webp" alt="El doctorado a distancia implica una investigación original y valiosa para la sociedad." class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Características del doctorado en México</h2>
            <p class="md:mb-8 mb-10">Entre las principales características de este grado académico en México, destacan los siguientes puntos:</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Nivel de estudio más alto</h3>
            <p class="md:mb-8 mb-10">Un doctorado representa el punto cumbre de la formación académica dentro del sistema universitario. <b>Se ubica por encima de la licenciatura y la maestría, exigiendo un compromiso intelectual alto.</b></p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Investigación original</h3>
            <p class="md:mb-8 mb-10">El eje central de un doctorado radica en la realización de una investigación original y significativa, por lo que <b>se requiere desarrollar la capacidad de investigación, diseñar metodologías rigurosas, analizar datos de manera crítica</b> y generar aportaciones valiosas al campo de estudio.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Desarrollo de habilidades</h3>
            <p class="md:mb-8 mb-10">Un doctorado no solo implica el fortalecimiento de habilidades esenciales para la investigación y la vida académica. Entre ellas destacan: <b>Pensamiento crítico y analítico; habilidades de comunicación; gestión del tiempo y organización; autonomía y responsabilidad.</b></p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Duración y dedicación</h3>
            <p class="md:mb-8 mb-10"><b>La duración de un doctorado varía según la institución y el programa, pero generalmente oscila entre tres y seis años de estudio a tiempo completo.</b> Requiere una inversión de tiempo, esfuerzo y recursos para alcanzar las metas.</p>
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
                <ContactoVue class="m-3"/>
            </div>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Beneficios y ventajas del doctorado virtual en México</h2>
            <p class="md:mb-4 mb-6">Un doctorado virtual es un programa académico de posgrado que permite a los estudiantes obtener el grado de Doctor a través de plataformas digitales, sin necesidad de asistir físicamente a una universidad.</p>
            <p class="md:mb-8 mb-10"><b>Este formato ofrece flexibilidad en términos de tiempo y ubicación,</b> lo que lo hace ideal para profesionales que necesitan encontrar un equilibrio entre sus estudios con responsabilidades laborales y personales.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Flexibilidad y accesibilidad</h3>
            <p class="md:mb-8 mb-10"><b>Un doctorado virtual se caracteriza por su flexibilidad, permitiendo a los estudiantes organizar sus estudios de acuerdo a su ritmo y necesidades.</b> Esto lo hace ideal para profesionales que ya tienen compromisos laborales o familiares y que buscan continuar su formación académica sin sacrificar otros aspectos de su vida.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Modalidad a distancia</h3>
            <p class="md:mb-8 mb-10"><b>Los programas virtuales se imparten a través de <router-link to="/blog/academico/estudia-en-linea/metodologias-aprendizaje-linea" class=" text-htwo hover:text-hone">Algunas de las tendencias emergentes en la Psicología</router-link> utilizando diversas herramientas digitales como videoconferencias, aulas virtuales y materiales descargables.</b> Esto permite a los estudiantes acceder a los contenidos y participar en las actividades desde cualquier lugar con conexión a internet.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Interacción y colaboración</h3>
            <p class="md:mb-8 mb-10">A pesar de ser un programa a distancia, un doctorado virtual no significa una experiencia de aprendizaje aislada. <b>Los estudiantes tienen la oportunidad de interactuar con sus compañeros y profesores a través de las plataformas en línea, participando en debates, realizando trabajos grupales y recibiendo tutoría personalizada.</b></p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Rigor académico</h3>
            <p class="md:mb-8 mb-10">Un doctorado virtual mantiene el mismo rigor académico que un programa presencial. Los estudiantes deben cumplir con los mismos requisitos de admisión, <b>desarrollar investigaciones originales y defender su tesis doctoral ante una variedad de expertos en los temas asignados.</b></p>
            <img src="/images/universidades-doctorado-humanitas.webp" alt="Humanitas es una de las universidades con doctorado que ofrece mayores ventajas" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Tipos de doctorados en la Universidad Humanitas</h2>
            <p class="md:mb-8 mb-10"><b>El programa de <router-link to="/doctorados" target="_blank" class=" text-htwo hover:text-hone">Doctorado de la Universidad Humanitas</router-link> tiene como objetivo formar profesionales que buscan especializarse,</b> potenciar competencias y habilidades necesarias para resolver problemas específicos del rubro profesional donde se desempeñan, generando soluciones innovadoras a los retos actuales. Los tipos de doctorados que ofrece la universidad son los siguientes:</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Derecho</h3>
            <p class="md:mb-8 mb-10">Tiene como finalidad fomentar el desarrollo en áreas contemporáneas del Derecho, <b>fortalecer habilidades como la resolución de conflictos, la agilización de procesos y la administración de justicia, así como contribuir al crecimiento y fortalecimiento de la nación.</b></p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Alta dirección</h3>
            <p class="md:mb-8 mb-10">Su objetivo es desarrollar y ampliar sus conocimientos en la gestión a nivel directivo, con un enfoque integral que abarque <b>desde la planificación y estrategia corporativa hasta la operación y estrategia financiera de las organizaciones, complementado con un programa de investigación en temas relevantes de gestión directiva.</b></p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Educación</h3>
            <p class="md:mb-8 mb-10"><b>Los estudiantes de este programa de estudios son alentados a adoptar una postura crítica e innovadora para elaborar diagnósticos y propuestas de mejora, con el fin de impulsar la calidad en los procesos educativos e institucionales,</b> así como desarrollar propuestas para resolver problemas en los ámbitos profesional y docente a nivel educativo. Duración y modalidad:<br>El programa tiene una duración de 4 años y se desarrolla en modalidad presencial.</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[19px] mb-3">Requisitos de admisión</h4>
            <ul class="list-disc pl-6 md:mb-8 mb-10">
                <li>Contar con título de maestro en áreas afines.</li>
                <li>Demostrar un sólido expediente académico.</li>
                <li>Aprobar un examen de admisión.</li>
                <li>Presentar un proyecto de investigación preliminar.</li>
                <li>Entrevista con el Comité de Admisiones.</li>
            </ul>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Conclusión</h2>
            <p class="mb-20"><b>Un doctorado en México no solo significa un alto nivel de conocimiento general en un campo,</b> sino también una profunda especialización en un área específica, lo que prepara a los graduados para carreras académicas, de investigación y otras profesiones avanzadas.</p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArtVue :ant="true" :linkAnt="'/academico/licenciatura/beneficios-carreras-universitarias-cortas'" :linkSig="'/aprende/tendencias/tendencias-actuales-del-derecho-2024'" :sig="false"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import ContactoVue from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue'
import SigArtVue from '../../pagesComponents/SigArt.vue';


export default {
    components: { Comentarios, ConComentarios, ContactoVue, LikesVue, ShareP, SigArtVue},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '0045-ART-ACADEMICO-DOCTORADO-180624',
            ruta: '/academico/doctorado/doctorados-virtuales-en-mexico',
            title: 'Doctorados virtuales en México: especialización y conocimiento',
            image: 'https://blog.humanitas.edu.mx/images/Todo-sobre-el-doctorado-guia.jpg',
            text: 'Los doctorados virtuales en México representan la culminación de los estudios universitarios que contemplan investigación y conocimiento profundo sobre un tema.'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx'+this.ruta;

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>



