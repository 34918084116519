<template>
  <div>
    <CataBueno :titulo="'Recursos'" :subtitulo="'Casos Reales'" :filtros="filtros" :articles="articles"/>
  </div>
</template>
  
<script>

import CataBueno from '../pagesComponents/CataBueno.vue';

export default {
  components: { CataBueno },
  data() {
    return {
      articles: [
        {
          "id": "0053-ART-RECURSOS-CASOS-REALES-020724",
          "titulo": "Cómo identificar y superar relaciones tóxicas: Lo que debes de saber",
          "fecha": "02 de julio de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "Aprende a reconocer los signos de una relación tóxica y cómo prevenirlos para vivir una vida más sana y feliz.",
          "portada": "/images/identifica-relaciones-toxicas.webp",
          "enlace": "/recursos/casos-reales/como-identificar-y-superar-relaciones-toxicas"
        },
        {
          "id": "17-ART-RECURSOS-CASOSREALES-090424",
          "titulo": "Perfil criminológico: cómo los expertos descifran la mente criminal",
          "fecha": "09 de abril de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "El perfil criminológico permite internarse en la mente de los delincuentes para resolver casos complejos. Explora qué es.",
          "portada": "/images/Perfil-criminologico-detras-mente-criminal-investigadores.jpg",
          "enlace": "/recursos/casos-reales/perfil-criminologico-como-descifra-la-mente-criminal"
        },
      ], // Array de artículos original
      filtros: {
        "0053-ART-RECURSOS-CASOS-REALES-020724": {
          Humanidades: ["Psicología", "Educación"],
          Ciencias_sociales: [],
          Economico_administrativas: []
        },
        "17-ART-RECURSOS-CASOSREALES-090424": {
          Humanidades: ["Psicología"],
          Ciencias_sociales: ["Derecho"],
          Economico_administrativas: []
        },
      },
      filtrosSeleccionados: null, // Lista de filtros seleccionados
    };
  },
  methods: {
  
  }
};
</script>

<style>
/* Estilos CSS si los tienes */
</style>