<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">Conoce los tipos de Contabilidad y cómo se aplican </h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-200">
          <img src="/images/tipos-contabilidad-financiero.webp" alt="Los tipos de Contabilidad establecen los parámetros para la información financiera." class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/academico" class=" text-hone">Académico</router-link> > <router-link to="/blog/academico/licenciatura" class=" text-hone">Licenciatura</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex text-left list-disc pl-5 italic text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">06 de Junio de 2024</li>  <li>Iván Solís Rivera</li></ul>
            </div>
            <p class="md:mb-4 mb-6">Es importante conocer los tipos de Contabilidad partiendo de la relevancia que tiene esta asignatura para las organizaciones y las personas en la época actual.</p>
            <p class="md:mb-4 mb-6"><b>La Contabilidad es un sistema de información que se encarga del registro, la elaboración y la comunicación de datos, principalmente de carácter económico-financiero,</b> sobre una persona o entidad. Esta información es esencial para que los usuarios puedan tomar decisiones racionales en el ámbito de las actividades económicas.</p>
            <p class="md:mb-8 mb-10">Esta asignatura ayuda a las empresas y personas a tomar decisiones, sobre inversiones, operaciones y estrategias <router-link to="/blog/aprende/tendencias/tendencias-administracion-empresas-2024"  class="text-htwo">universidades</router-link></p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">La importancia de la contabilidad para las organizaciones y personas</h2>
            <p class="md:mb-4 mb-6"><b>Una de las principales características de la Contabilidad es que proporciona un registro preciso de las transacciones financieras de una organización</b> o persona, lo que garantiza la transparencia y facilita la rendición de cuentas ante los inversores, acreedores y otros interesados.</p>
            <p class="md:mb-4 mb-6">En ese sentido, <b>permite la planificación financiera a corto y largo plazo al proporcionar información sobre la salud financiera de la empresa,</b> su capacidad para generar ingresos y su nivel de endeudamiento.</p>
            <p class="md:mb-8 mb-10"><b>Contar con un sistema contable,</b> además de darle control y organización a las empresas y personas sobre sus finanzas, <b>ayuda a cumplir con las obligaciones legales y fiscales</b> al mantener registros precisos y generar informes financieros que cumplan con los requisitos reglamentarios.</p>
            <img src="/images/clasificacion-contabilidad-analisis.webp" alt="La clasificación de la Contabilidad propicia el análisis y presentación de resultados." class="md:mb-8 mb-10">
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Tipos de Contabilidad</h3>
            <p class="md:mb-4 mb-6">De acuerdo con los expertos, <b>la clasificación de la Contabilidad busca abordar de manera más efectiva las diversas necesidades de información</b> que surgen en diferentes contextos económicos y organizacionales.</p>
            <p class="md:mb-4 mb-6"><b>Esta clasificación permite una mejor <router-link to="/blog/recursos/entrevistas-y-conferencias/procedimientos-tecnicas-de-auditoria-y-ejemplos-ricardo-sandoval-huerta"  class="text-htwo">organización, análisis y presentación de datos</router-link> financieros,</b>  lo cual es fundamental para la toma de decisiones informadas y la gestión eficiente de recursos. A continuación, explicamos las principales razones por las cuales se clasifica la Contabilidad:</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[19px] mb-3">Contabilidad financiera</h4>
            <p class="md:mb-8 mb-10">Se centra en el registro y la presentación de información financiera de una entidad de acuerdo con los Principios Contables Generalmente Aceptados (PCGA). <b>Su objetivo principal es proporcionar información útil para usuarios externos, como inversionistas, acreedores y reguladores,</b> para la toma de decisiones económicas.</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[19px] mb-3">Contabilidad de gestión o administrativa</h4>
            <p class="md:mb-8 mb-10">La Contabilidad de gestión <b>se enfoca en proporcionar información útil para la toma de decisiones internas dentro de una organización,</b> ayuda a los gerentes y otros responsables en la planificación, control y toma de decisiones estratégicas. <b>Esto incluye la preparación de presupuestos, análisis de costos, evaluación de proyectos y rendimiento,</b> entre otros aspectos.</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[19px] mb-3">Contabilidad de costos</h4>
            <p class="md:mb-8 mb-10"><b>Se centra en el registro, análisis y control de los costos relacionados con la producción y la distribución de bienes y servicios,</b> busca determinar el costo de producción de los productos o servicios de una empresa, identificar áreas de mejora de eficiencia y apoyar la toma de decisiones relacionadas con la fijación de precios y la rentabilidad.</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[19px] mb-3">Contabilidad fiscal</h4>
            <p class="md:mb-8 mb-10">La Contabilidad fiscal se alinea a las obligaciones fiscales y normativas establecidas por las autoridades tributarias, <b>de la misma forma busca calcular y reportar correctamente los impuestos a pagar por la entidad.</b></p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[19px] mb-3">Contabilidad pública o gubernamental</h4>
            <p class="md:mb-4 mb-6">Se aplica en entidades gubernamentales y organizaciones sin fines de lucro de acuerdo con los principios contables gubernamentales o normas específicas para este tipo de entidades. <b>de la misma forma busca calcular y reportar correctamente los impuestos a pagar por la entidad.</b></p>
            <p class="md:mb-8 mb-10">Abarca el sector público, tales como gobiernos, ministerios, municipios y otras instituciones estatales <b>permitiendo a los ciudadanos y a los órganos de control verificar cómo se están utilizando los recursos del Estado.</b></p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Ejemplo de Contabilidad administrativa: presupuesto de operaciones</h2>
            <p class="md:mb-8 mb-10">A continuación un ejemplo de Contabilidad administrativa, la cuál muestra la utilidad y la aplicación de un caso práctico, en este caso, es un <b>ejemplo de un emprendimiento sobre una agencia de viajes, por lo tanto, las cifras presentadas son de un capital inicial</b> considerando lo que se requiere para impulsar el proyecto.</p>
            <img src="/images/ejemplo-contabilidad-administrativa-publica.webp" alt="Un ejemplo de Contabilidad administrativa permite el análisis de información." class="md:mb-8 mb-10">
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Cálculo del presupuesto de operaciones</h3>
            <p class="md:mb-4 mb-6">De acuerdo con el proyecto de emprendimiento se determinó de las necesidades de operaciones y producción mensual:</p>
            <table class="md:mb-8 mb-10">
                <thead>
                    <tr>
                        <th>Activo</th>
                        <th></th>
                        <th>Pasivo</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <!-- Activo Circulante y Pasivo a Corto Plazo -->
                    <tr>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                    </tr>
                    <tr>
                        <td colspan=""><strong>Activo Circulante</strong></td>
                        <td></td>
                        <td colspan=""><strong>Pasivo a corto plazo</strong></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Caja</td>
                        <td>$200.00</td>
                        <td>Proveedores</td>
                        <td>$10,000.00</td>
                    </tr>
                    <tr>
                        <td>Bancos</td>
                        <td>$2,000.00</td>
                        <td>Créditos bancarios</td>
                        <td>$0.00</td>
                    </tr>
                    <tr>
                        <td>Inversiones en valores</td>
                        <td>$1,300.00</td>
                        <td>Impuestos por pagar</td>
                        <td>$0.00</td>
                    </tr>
                    <tr>
                        <td>Clientes</td>
                        <td>$16,000.00</td>
                        <td>Acreedores diversos</td>
                        <td>$5,000.00</td>
                    </tr>
                    <tr>
                        <td>Deudores diversos</td>
                        <td>$0.00</td>
                        <td>Documentos por pagar</td>
                        <td>$2,300.00</td>
                    </tr>
                    <tr>
                        <td>Rentas pagadas por anticipado</td>
                        <td>$0.00</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Inventarios (almacén)</td>
                        <td>$0.00</td>
                        <td><strong class="uppercase">Total Pasivo a Corto Plazo</strong></td>
                        <td><strong>$17,300.00</strong></td>
                    </tr>
                    <tr>
                        <td><strong class="uppercase">Total Activo Circulante</strong></td>
                        <td><strong>$19,500.00</strong></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <!-- Activo Fijo y Pasivo a Largo Plazo -->
                    <tr>
                        <td colspan=""></td>
                        <td></td>
                        <td><strong>Pasivo a largo plazo</strong></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colspan=""><strong>Activo Fijo</strong></td>
                        <td></td>
                        <td>Préstamos bancarios</td>
                        <td>$0.00</td>
                    </tr>
                    <tr>
                        <td>Terrenos</td>
                        <td>$0.00</td>
                        <td>Acreedores diversos</td>
                        <td>$0.00</td>
                    </tr>
                    <tr>
                        <td>Edificios</td>
                        <td>$0.00</td>
                        <td><strong class="uppercase">Total Pasivo a Largo Plazo</strong></td>
                        <td><strong>$0.00</strong></td>
                    </tr>
                    <tr>
                        <td>Maquinaria y equipo</td>
                        <td>$45,000.00</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Equipo de oficina</td>
                        <td>$45,000.00</td>
                        <td><strong>Créditos diferidos</strong></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Equipo de transporte</td>
                        <td>$15,000.00</td>
                        <td>Rentas recibidas por anticipado</td>
                        <td>$0.00</td>
                    </tr>
                    <tr>
                        <td><strong>Total Activo Fijo</strong></td>
                        <td><strong>$105,000.00</strong></td>
                        <td>Intereses cobrados por anticipado</td>
                        <td>$0.00</td>
                    </tr>
                    <!-- Activo Diferido y Créditos Diferidos -->
                    <tr>
                        <td colspan="2"></td>
                        <td>Anticipos de clientes</td>
                        <td>$8,000.00</td>
                    </tr>
                    <tr>
                        <td colspan="2"><strong>Activo Diferido</strong></td>
                        <td><strong class="uppercase">Total Créditos Diferidos</strong></td>
                        <td><strong>$8,000.00</strong></td>
                    </tr>
                    <tr>
                        <td>Gastos de organización</td>
                        <td>$3,000.00</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Gastos de instalación</td>
                        <td>$0.00</td>
                        <td><strong class="uppercase">Total pasivo</strong></td>
                        <td><strong>$25,300.00</strong></td>
                    </tr>
                    <tr>
                        <td>Patentes y marcas</td>
                        <td>$0.00</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td><strong class="uppercase">Total Activo Diferido</strong></td>
                        <td><strong>$3,000.00</strong></td>
                        <td><strong>Capital contable o patrimonio</strong></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td colspan="2"></td>
                        <td>Capital social</td>
                        <td>$30,000.00</td>
                    </tr>
                    <tr>
                        <td colspan=""><strong class="uppercase">total activo</strong></td>
                        <td colspan=""><strong>$108,000.00</strong></td>
                        <td>Resultado de ejercicios anteriores</td>
                        <td>$5,000.00</td>
                    </tr>
                    <tr>
                        <td colspan="2"></td>
                        <td>Resultado del ejercicio</td>
                        <td>$0.00</td>
                    </tr>
                    <tr>
                        <td colspan="2"></td>
                        <td><strong class="uppercase">total capital</strong></td>
                        <td><b>$35,000.00</b></td>
                    </tr>
                    <tr>
                        <td colspan="4">&nbsp;</td>
                    </tr>
                    <tr>
                        <td colspan="2"></td>
                        <td><strong class="uppercase">total pasivo + capital</strong></td>
                        <td><b>$60,300.00</b></td>
                    </tr>
                </tbody>
            </table>
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
                <ContactoVue class="m-3"/>
            </div>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[19px] mb-3">Necesidades financieras</h4>
            <p class="md:mb-4 mb-6">Este proyecto requiere un volumen de financiación global que hemos establecido en los siguientes grupos:</p>
            <ul class="list-decimal pl-6 md:mb-4 mb-6">
                <li>Adquisición de activos (equipo de cómputo y equipo de oficina): $45,500.00</li>
                <li>Alojamiento de la página web: $500.00 anual</li>
                <li>Necesidad operativa de fondos (primeros meses): $45,000.00</li>
            </ul>
            <p class="md:mb-8 mb-10">Necesidad total de fondos: $180,000.00</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[19px] mb-3">Plan de financiación</h4>
            <p class="md:mb-4 mb-6">1º Capital social: <br>Hemos previsto un capital social total de $38,000 que aportarán los socios de la compañía. Dicho capital cubrirá el 60 por ciento de las necesidades totales de fondos.</p>
            <p class="md:mb-8 mb-10">2º Préstamos:<br>El resto de la financiación necesaria se prevé de forma externa y mediante préstamos a corto y medio plazo.</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[19px] mb-3">Flujo de efectivo</h4>
            <p class="md:mb-8 mb-10">Maquinaria + Equipo de oficina +Transporte= (17,800.00+45,000.00+15,500.00)-(2,300.00)= $14,800.00<br>Capital social 	$30,000.00<br>Dividendos pagados	$3,000.00<br>Total	$47,800.00</p>
            <h4 class="text-left font-serif text-hone md:text-[20px] text-[19px] mb-3">Estado de resultados</h4>
            <p class="md:mb-4 mb-6">Ingreso operativos netos	$16,000.00 - $8,000.00 = $8,000.00<br>Resultado bruto 	$8,000<br>Resultado de las operaciones ordinarias 	$3,000.00<br>Resultado antes de impuesto a las ganancias	$11,000.00<br>Resultado neto	$11,000.00</p>
            <p class="md:mb-8 mb-10">Punto de equilibrio	$37,000.00 / $16,000.00 = $2.3125<br>$37,000.00 / $16,000.00 - $8,000.00= $4.625</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Conclusión</h2>
            <p class="mb-20">La diversidad de tipos de <b><a href="https://humanitas.edu.mx/licenciatura-en-contabilidad" target="_blank" class="text-htwo">Contabilidad</a> refleja la complejidad y las variadas necesidades de información financiera</b> en la vida de las empresas y personas en el mundo empresarial, por ello la correcta aplicación y comprensión es esencial. En el ejemplo podemos ver que la información y datos sobre las necesidades económicas del proyecto son esenciales para su supervivencia.</p>
            <!-- Aqui va el componente de contacto, no se te olvide hacerlo manolo -->
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="true" :linkAnt="'/academico/estudia-en-linea/licenciatura-psicologia-en-linea'" :linkSig="'/academico/licenciatura/que-es-un-acto-administrativo'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import ContactoVue from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue';
import VideoClipVue from '../../pagesComponents/videoClip.vue';
import SigArt from '../../pagesComponents/SigArt.vue';

export default {
    components: { Comentarios, ConComentarios, ContactoVue, LikesVue, ShareP, VideoClipVue, SigArt},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '0040-ART-LICENCIATURA-CONTABILIDAD-060624',
            ruta: '/academico/licenciatura/tipos-de-contabilidad',
            title: 'Tipos de Contabilidad que permiten una mejor planificación de capital',
            image: 'https://blog.humanitas.edu.mx/images/Costo-y-requisitos-estudiar-maestria.jpg',
            text: 'Reconocer los tipos de Contabilidad ayudan a la toma de decisiones informadas y mejorar la planificación de recursos.'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx/academico/maestria/costo-y-requisitos-estudiar-maestria';

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>