<script lang="ts">
  import { defineComponent } from 'vue';
  import Botones from './Botones.vue';

  export default defineComponent ({
    components: {  Botones },
    data() {
      return {
        currentIndex: 0,
        buttons: {
          button1: {
            imageUrl: "../pagesImages/atras-off.png", hoverImageUrl: "../pagesImages/atras-on.png"
          },
          button2: {
            imageUrl: "../pagesImages/siguiente-off.png", hoverImageUrl: "../pagesImages/siguiente-on.png"
          }
        },
        images: [
          '../pagesImages/1979.png',
          '../pagesImages/80s.png',
          '../pagesImages/90s.png',
          '../pagesImages/2000s.png',
          '../pagesImages/2010s.png',
          '../pagesImages/2020s.png',
          
        ] as string[],
        autoplayInterval: null as ReturnType<typeof setInterval> | null,
      };
    },
    methods: {
      prevSlide() {
        this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
      },
      nextSlide() {
        this.currentIndex = (this.currentIndex + 1) % this.images.length;
      },
      startAutoplay() {
        this.autoplayInterval = setInterval(this.nextSlide, 3000); // Cambia la duración según sea necesario
      },
      stopAutoplay() {
        if (this.autoplayInterval) {
          clearInterval(this.autoplayInterval);
        }
      },
    },
    mounted() {
      this.startAutoplay();
    },
    beforeUnmount() {
      this.stopAutoplay();
    },
  });
  </script>

<template>
    <section class="flex items-center mb-12 justify-center w-full bg-cover bg-[center] bg-no-repeat" style="background-image: url(../pagesImages/bgs-column.jpg);">
      <div class="max-w-7xl mt-16 mb-6 flex">
            <div class="flex flex-col items-center justify-center space-x-4">
                <p class="text-[32px] font-serif font-bold" style="letter-spacing: 8px;">HUMANITAS A TRAVÉS DEL TIEMPO</p>
                <div class="flex items-center justify-center">
                    <Botones :button="buttons.button1" class="cursor-pointer" @click="prevSlide"></Botones>
                    <img :src="images[currentIndex]" alt="Descripción de la imagen" class="">
                    <Botones :button="buttons.button2" class="cursor-pointer" @click="nextSlide"></Botones>
                </div>
                
            </div>
        </div>
  
    </section>
  </template>
  
  
  
  <style scoped>
    .img-carru {
        width: 728px;
        aspect-ratio: auto 728 / 416;
        height: 416px;
    }
  </style>