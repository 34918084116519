<template>
    <div>
      <CataBueno :titulo="'Aprende'" :subtitulo="'Cultura General'" :filtros="filtros" :articles="articles"/>
    </div>
</template>
  
  <script lang="js">
  import CataBueno from '../pagesComponents/CataBueno.vue';
  
  export default {
    components: { CataBueno },
    data() {
      return {
        articles: [
          {
            "id": "0051-ART-APRENDE-CULTURA-GENERAL-270624",
            "titulo": "Día Mundial de las Redes Sociales: Celebrando el 30 de junio",
            "fecha": "27 de junio de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Día Mundial de las Redes Sociales: conoce por qué se celebra y cómo las redes sociales influyen en nuestras vidas.",
            "portada": "/images/dia-mundial-redes-sociales-30-junio.webp",
            "enlace": "/aprende/cultura-general/dia-mundial-redes-sociales-30-junio-2024"
          },
          {
            "id": "0044-ART-APRENDE-CULTURA-GENERAL-130624",
            "titulo": "¿Cuántos pisos tiene la torre de Pemex y cuál es su historia?",
            "fecha": "13 de junio de 2024",
            "autor": "Iván Solís Rivera",
            "descripcion": "Conoce cuántos pisos tiene la torre de Pemex y cuál es la historia de este edificio, que es símbolo de la arquitectura moderna mexicana.",
            "portada": "/images/cuantos-pisos-tiene-torre-pemex.webp",
            "enlace": "/aprende/cultura-general/cuantos-pisos-tiene-la-torre-de-pemex"
          },
          {
            "id": "0029-ART-APRENDE-CULTURA-GENERAL-210524",
            "titulo": "Uso responsable de las redes sociales: Guía para navegar de forma segura",
            "fecha": "22 de mayo de 2024",
            "autor": "Iván Solís Rivera",
            "descripcion": "El uso responsable de las redes sociales recae en el nivel de consciencia y educación de los usuarios respecto a los efectos y beneficios de estas plataformas.",
            "portada": "/images/uso-responsable-redes-sociales-beneficios-jovenes.webp",
            "enlace": "/aprende/cultura-general/consejos-sobre-uso-responsable-de-redes-sociales"
          },
          {
            "id": "25-ART-APRENDE-LICENCIATURA-CULTURA-GRAL-140524",
            "titulo": "Celebrando el Día del Maestro: reconozcamos a nuestros profesores",
            "fecha": "15 de mayo de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "La importancia del Día del Maestro: cómo se celebra en México y la influencia de los profesores en la sociedad. ¡Descubre más!",
            "portada": "/images/dia-del-maestro-mexico-humanitas.webp",
            "enlace": "/aprende/cultura-general/dia-del-maestro-2024"
          },
          {
            "id": "11-ART-APRENDE-CULTURA-GENERAL-080324",
            "titulo": "Día de la Mujer 2024: invertir para tener igualdad de género",
            "fecha": "08 de marzo de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Día Internacional de la Mujer: por qué es importante y cómo en 2024, financiar los derechos de las mujeres puede acelerar la igualdad de género.",
            "portada": "/images/Dia-internacional-de-la-mujer-financiar-acelera-igualdad-genero-2024.jpg",
            "enlace": "/aprende/cultura-general/dia-de-la-mujer-financiar-derechos-acelerar-igualdad-de-genero-2024"
          },
        ], // Array de artículos original
        filtros: {
          "0051-ART-APRENDE-CULTURA-GENERAL-270624": {
            Humanidades: ["Educación", "Psicología"],
            Ciencias_sociales: ["Administración"],
            Economico_administrativas: []
          },
          "0044-ART-APRENDE-CULTURA-GENERAL-130624": {
            Humanidades: ["Arquitectura"],
            Ciencias_sociales: [],
            Economico_administrativas: []
          },
          "0029-ART-APRENDE-CULTURA-GENERAL-210524": {
            Humanidades: ["Educación", "Psicología"],
            Ciencias_sociales: [],
            Economico_administrativas: []
          },
          "25-ART-APRENDE-LICENCIATURA-CULTURA-GRAL-140524": {
            Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y Teatro"],
            Ciencias_sociales: ["Derecho", "Ciencias Políticas", "Administración Pública"],
            Economico_administrativas: ["Administración", "Contabilidad"]
          },
          "11-ART-APRENDE-CULTURA-GENERAL-080324": {
            Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y Teatro"],
            Ciencias_sociales: ["Derecho", "Ciencias Políticas", "Administración Pública"],
            Economico_administrativas: ["Administración", "Contabilidad"]
          },
        },
        filtrosSeleccionados: null, // Lista de filtros seleccionados
      };
    },
    methods: {
      // Método para aplicar los filtros seleccionados
    }
  };
  </script>
  
  <style>
  /* Estilos CSS si los tienes */
  </style>