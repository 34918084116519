<script lang="ts">
	import FooterInd from '../components/FooterInd.vue';
	import HoverModelos from './components/HoverModelos.vue';
	import CompIndex from './components/CompIndex.vue';
	import NewSlide from './components/newSlide.vue';

	export default {
		components: { FooterInd, HoverModelos, CompIndex, NewSlide },
	};
</script>


<template class="w-full flex justify-center items-center">
	<div class="flex flex-col w-full items-center justify-center bg-white">
		<NewSlide />
		<div class="flex flex-col w-full items-center justify-center text-center px-4 z-10">
			<p class="text-gray-700 font-serif text-4xl leading-9 mb-2">S&oacute;lo el conocimiento hace superior al hombre.</p>
			<p class="text-2xl leading-11 text-hgold text-center font-serif font-medium tracking-[.75em]">Desde 1979</p>
			<img src="/pagesImages/adorno.png" alt="45 aniversario" class="my-6 w-auto h-auto">
			<p class="text-2xl leading-7 text-gray-700 font-sans">Conoce m&aacute;s acerca de nuestros</p>
			<p class="text-hwine font-serif font-bold text-3xl leading-10">Modelos Acad&eacute;micos</p>
		</div>
	</div>
	<HoverModelos class="z-10" />
	<CompIndex  class="z-10"/>
</template>


<style scoped>
</style>