<template>
    <div>
      <CataBueno :titulo="'Académico'" :subtitulo="'Educación Continua'" :filtros="filtros" :articles="articles"/>
    </div>
</template>
  
  <script lang="js">
  import CataBueno from '../pagesComponents/CataBueno.vue';
  
  
  
  export default {
    components: { CataBueno },
    data() {
      return {
        articles: [
          {
            "id": "0052-ART-ACADEMICO-EDUCACION-CONTINUA-270624",
            "titulo": "Educación continua: ¿Qué es y porqué es importante?",
            "fecha": "27 de junio de 2024",
            "autor": "Iván Solís Rivera",
            "descripcion": "Descubre qué es la educación continua y cómo puede ayudarte a mantenerte actualizado en tu carrera profesional.",
            "portada": "/images/educacion-continua-universitaria.webp",
            "enlace": "/academico/educacion-continua/educacion-continua-que-es"
          },
          {
            "id": "0041-ART-ACADEMICO-EDUCACION-CONTINUA-130624",
            "titulo": "Qué es un seminario: todo lo que quieres saber",
            "fecha": "13 de junio de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Conoce más sobre el seminario de investigación, su importancia en la educación continua y cómo potencia tu conocimiento. ¡Infórmate!",
            "portada": "/images/que-es-seminario-todo-lo-que-debes-saber.webp",
            "enlace": "/academico/educacion-continua/que-es-un-seminario-todo-lo-que-quieres-saber"
          },
          {
            "id": "08-ART-ACADEMICO-EDUCACION-CONTINUA-200224",
            "titulo": "El impacto de un diplomado en tu desarrollo profesional: entérate cómo.",
            "fecha": "20 de febrero de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Un diplomado amplía tus habilidades y transforma tu carrera profesional, conoce sus ventajas.",
            "portada": "/images/Como-impacta-tu-vida-profesional-un-diplomado.jpg",
            "enlace": "/academico/educacion-continua/como-un-diplomado-transforma-tu-carrera"
          },
        ], // Array de artículos original
        filtros: {
          "0041-ART-ACADEMICO-EDUCACION-CONTINUA-130624": {
            Humanidades: ["Psicología"],
            Ciencias_sociales: [],
            Economico_administrativas: []
          },
          "08-ART-ACADEMICO-EDUCACION-CONTINUA-200224": {
            Humanidades: ["Psicología"],
            Ciencias_sociales: ["Derecho"],
            Economico_administrativas: ["Administración", "Contabilidad"]
          },
          "0052-ART-ACADEMICO-EDUCACION-CONTINUA-270624": {
            Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y Teatro"],
            Ciencias_sociales: ["Derecho", "Ciencias Políticas", "Administración Pública"],
            Economico_administrativas: ["Administración", "Contabilidad"]
          },
        },
        filtrosSeleccionados: null, // Lista de filtros seleccionados
      };
    },
    methods: {
      
    }
  };
  </script>
  
  <style>
  /* Estilos CSS si los tienes */
  </style>