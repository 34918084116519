<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent ({
        data() {
            return {
                currentIndex: 0,
                carouselItems: [
                    {
                        image: '../pagesImages/t1-nv.jpg',
                        nombre: 'ANA MARÍA',
                        campus: 'Campus Tijuana',
                        descript: '"Me llamó mucho la atención el Código Humanitas, tanto, que me alentó a inscribirme a la Licenciatura en psicología."',
                        link: 'https://capitel.humanitas.edu.mx/entrevista-a-ana-maria-reynoso-anorga-la-psicologia-y-el-psicoanalisis-historicamente-han-estado-ligados-al-estudio-de-la-motivacion/'
                    },
                    {
                        image: '../pagesImages/t3-nv.jpg',
                        nombre: 'JAVIER RAMÍREZ',
                        campus: 'Campus Del Valle',
                        descript: '"Una institución que inspira no sólo al estudio y generación de conocimiento, sino también a cultivar una filosofía de vida."',
                        link: 'https://capitel.humanitas.edu.mx/entrevista-a-javier-ramirez-fragoso-universidad-humanitas-la-semilla-que-dio-genesis-a-la-motivacion-de-estudiar/'
                    },
                    {
                        image: '../pagesImages/t2-nv.jpg',
                        nombre: 'ERIKA ANDRADE',
                        campus: 'Campus Del Valle',
                        descript: '"A diferencia de otras escuelas, Humanitas tiene un enfoque objetivo; con instalaciones que inspiran al estudio. "',
                        link: 'https://capitel.humanitas.edu.mx/entrevista-a-erika-andrade-compromiso-e-innovacion-para-una-verdadera-transformacion/'
                    },
                    {
                        image: '../pagesImages/t5-nv.jpg',
                        nombre: 'ISABEL CECILIA',
                        campus: 'Campus Cancún',
                        descript: '"Siempre conté con profesores dispuestos a compartir sus conocimientos y compañeros expertos en distintas áreas."',
                        link: 'https://capitel.humanitas.edu.mx/entrevista-a-isabel-cecilia-gonzalez-glennie-liderazgo-para-mostrar-a-los-demas-el-camino-de-la-excelencia/'
                    },
                    {
                        image: '../pagesImages/t6-nv.jpg',
                        nombre: 'JESÚS FIDEL',
                        campus: 'Campus Del Valle',
                        descript: '"Tiene la flexibilidad para quienes desarrollamos un trabajo profesional con jornadas laborales completas."',
                        link: 'https://capitel.humanitas.edu.mx/entrevista-a-jesus-fidel-hernandez-rodriguez-amor-a-todo-aquello-que-realicemos/'
                    },
                    {
                        image: '../pagesImages/t4-nv.jpg',
                        nombre: 'BIBIANA JIMÉNEZ',
                        campus: 'Campus Tijuana',
                        descript: '"Aprendí que cuando algo sucede tienes varias opciones: dejar que te marque, que te destruya o que te fortalezca. "',
                        link: 'https://capitel.humanitas.edu.mx/entrevista-a-bibiana-jimenez-ramirez-es-necesario-estar-actualizada-investigar-y-prepararse/'
                    },
                ],
            };
        },
        methods: {
            prevSlide() {
                this.currentIndex = (this.currentIndex - 1 + this.carouselItems.length) % this.carouselItems.length;
            },
            nextSlide() {
                this.currentIndex = (this.currentIndex + 1) % this.carouselItems.length;
            }
        }
    });
</script>

<template>
    <section class="flex flex-col mt-4 items-center justify-center mx-auto">
        <div class="w-full px-4 mb-4">
            <h2 class="text-[28px] text-gray-500">El mejor momento es ahora...</h2>
            <h2 class="text-[42px] font-serif text-hgold font-medium" style="font-family: Georgia, Palatino, Palatino Linotype, Times, Times New Roman, serif;">Humanitas en la vida</h2>
        </div>
        <div class="carousel flex justify-center items-center mb-12 ">
            <div class="flex justify-center mt-4">
                <div class="flex items-center justify-center cursor-pointer rounded-sm border border-gray-800 w-[50px] h-[80px] bg-hgray text-white hover:bg-hwine transition duration-300 ease-in-out" @click.passive="prevSlide">
                    <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M10.707 3.293a1 1 0 011.414 1.414L7.414 10l4.707 4.707a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 010 1.414z" clip-rule="evenodd"></path></svg>
                </div>
            </div>
            <div class="flex justify-center w-full">
                <div class="lg:w-4/12 hidden lg:m-3 m-1 p-4 border-2 border-gray-300 lg:flex flex-col items-center">
                    <img :src="currentIndex-1 === -1 ? carouselItems[carouselItems.length-1].image : carouselItems[currentIndex-1].image" :alt="currentIndex-1 === -1 ? carouselItems[carouselItems.length-1].nombre : carouselItems[currentIndex-1].nombre" class="w-[120px] h-[120px] my-8" loading="lazy">
                    <p class="text-lg font-bold text-gray-700">{{currentIndex-1 === -1 ? carouselItems[carouselItems.length-1].nombre : carouselItems[currentIndex-1].nombre }}</p>
                    <p class="text-lg mb-4 text-gray-700">{{currentIndex-1 === -1 ? carouselItems[carouselItems.length-1].campus : carouselItems[currentIndex-1].campus }}</p>
                    <p class="mb-4 gx:mx-4 text-lg leading-tight">{{currentIndex-1 === -1 ? carouselItems[carouselItems.length-1].descript : carouselItems[currentIndex-1].descript }}</p>
                    <a class="text-hgold text-lg font-bold mb-12" :href="currentIndex-1 === -1? carouselItems[carouselItems.length-1].link : carouselItems[currentIndex-1].link">Leer m&aacute;s...</a>
                </div>
                <div class="lg:w-4/12 w-full md:w-1/2 lg:m-3 m-1 p-4 border-2 border-gray-300 flex flex-col items-center">
                    <img :src="carouselItems[currentIndex].image" :alt="carouselItems[currentIndex].nombre" class="w-[120px] h-[120px] my-8" loading="lazy">
                    <p class="text-lg font-bold text-gray-700">{{ carouselItems[currentIndex].nombre }}</p>
                    <p class="text-lg mb-4 text-gray-700">{{ carouselItems[currentIndex].campus }}</p>
                    <p class="mb-4 gx:mx-4 text-lg leading-tight">{{ carouselItems[currentIndex].descript }}</p>
                    <a class="text-hgold text-lg font-bold mb-12" :href="carouselItems[currentIndex].link">Leer m&aacute;s...</a>
                </div>
                <div class="lg:w-4/12 lg:m-3 hidden md:w-1/2 m-1 p-4 border-2 border-gray-300 md:flex flex-col items-center">
                    <img :src="currentIndex+1 === carouselItems.length ? carouselItems[0].image : carouselItems[currentIndex+1].image" :alt="currentIndex+1 === carouselItems.length ? carouselItems[0].nombre : carouselItems[currentIndex+1].nombre" class="w-[120px] h-[120px] my-8" loading="lazy">
                    <p class="text-lg font-bold text-gray-700">{{currentIndex+1 === carouselItems.length ? carouselItems[0].nombre : carouselItems[currentIndex+1].nombre}}</p>
                    <p class="text-lg mb-4 text-gray-700">{{currentIndex+1 === carouselItems.length ? carouselItems[0].campus : carouselItems[currentIndex+1].campus }}</p>
                    <p class="mb-4 gx:mx-4 text-lg leading-tight">{{currentIndex+1 === carouselItems.length ? carouselItems[0].descript : carouselItems[currentIndex+1].descript }}</p>
                    <a class="text-hgold text-lg font-bold mb-12" :href="currentIndex+1 === carouselItems.length ? carouselItems[0].link : carouselItems[currentIndex+1].link">Leer m&aacute;s...</a>
                </div>
            </div>
            <div class="flex justify-center mt-4">
                <div class="flex items-center justify-center cursor-pointer rounded-sm border border-gray-800 w-[50px] h-[80px] bg-hgray text-white hover:bg-hwine transition duration-300 ease-in-out" @click.passive="nextSlide">
                    <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M9.293 16.707a1 1 0 01-1.414-1.414L12.586 10 7.879 5.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
    .carousel {
        max-width: 1200px;
        text-align: center;
    }
</style>
