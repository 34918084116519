<template>
    <div class="flex flex-col pt-4 items-center justify-center md:w-screen h-full z-30 bg-white">
        <div class="flex items-center justify-center text-center w-full relative">
            <!-- Primera mitad de la línea -->
            <div class="w-[70%]">
                <div class="h-7 border-b-[1px] border-[#999999]">&nbsp;</div>
                <div class="h-7">&nbsp;</div>
            </div>
            <!-- Primera imagen -->
            <div class="md:w-[6%] w-[20%]">
                <img src="../pages/images/Logotipo-gris-oie-de-pagina-Universidad-Humanitas.png" alt="h-logo" class=" z-20 inline-block align-middle" />	
            </div>
            <!-- Segunda mitad de la línea -->
            <div class="w-[70%]">
                <div class="h-7 border-b-[1px] border-[#999999]">&nbsp;</div>
                <div class="h-7">&nbsp;</div>
            </div>
		</div>
        <div  class="flex flex-col items-center justify-center w-full">
            <div class="flex justify-between relative lg:-top-2 items-center max-w-6xl lg:max-w-[1300px] w-full">
                <div class="hidden md:block w-full">
                    <div class="flex flex-col w-full justify-center text-gray-700 items-center ">
                        <div class="flex flex-col justify-center w-[70%]">
                            <p class="lg:text-[18px] text-[16px] text-gray-700" style="font-family: Georgia, serif;">S&iacute;guenos en Redes Sociales</p>
                            <hr class="border-gray-700 my-2">
                        </div>
                        <div class="flex justify-center">
                            <a href="https://www.facebook.com/universidadhumanitas/" target="_blank"><img src="../pages/images/feis-icon.png" alt="facebook icon" class="lg:mr-2 h-10 mr-2 relative cursor-pointer hover:scale-125 transition-transform duration-280"></a>
                            <a href="https://www.instagram.com/universidadhumanitas/" target="_blank"><img src="../pages/images/ins-icon.png" alt="instagram icon" class="lg:mr-2 h-10 mr-2 relative cursor-pointer hover:scale-125 transition-transform duration-280"></a>
                            <a href="https://twitter.com/UHumanitas" target="_blank"><img src="../pages/images/x-icon.png" alt="x icon" class="lg:mr-2 h-10 mr-2 relative cursor-pointer hover:scale-125 transition-transform duration-280"></a>
                            <a href="https://www.linkedin.com/school/universidad-humanitas/mycompany/" target="_blank"><img src="../pages/images/link-icon.png" alt="linkedin icon" class="lg:mr-2 h-10 mr-2 relative cursor-pointer hover:scale-125 transition-transform duration-280"></a>
                            <a href="https://mx.pinterest.com/uhumanitasoficial/" target="_blank"><img src="../pages/images/pin-icon.png" alt="pinterest icon" class="lg:mr-2 h-10 relative cursor-pointer hover:scale-125 transition-transform duration-280"></a>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col w-full items-center justify-center text-gray-700 my-2 text-[10px] lg:text-[12px]" style="font-family: Georgia, serif;">
                    <p class="mb-1">Estudios con reconocimiento de Validez Oficial</p>
                    <p>Universidad Humanitas Copyright. Todos los derechos reservados 2024.</p>
                </div>
                <div class="hidden md:block w-full">
                    <div class="flex justify-center w-full items-center ">
                        <div class="flex items-center justify-center w-[60%]">
                            <a href="https://www.youtube.com/c/HumanitasEduMx?sub_confirmation=1" target="_blank">
                                <img src="../pages/images/youtube suscribete.png" alt="youtube_suscribete_M">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="flex items-start m-0 w-full h-full justify-start">
                <img src="../pages/images/barra-pie.png" class="w-[35%]">
            </div> -->
        </div>
        <div class="w-full mx-4 md:hidden">
            <div class="flex justify-between mb-8 w-full">
                <div class="flex flex-col w-full justify-center text-gray-700 items-center">
                    <div class="flex flex-col justify-center w-[85%]">
                        <p class="text-[16px] text-gray-700" style="font-family: Georgia, serif;">S&iacute;guenos en Redes Sociales</p>
                        <hr class="border-gray-700 my-1">
                    </div>
                    <div class="flex justify-center mt-2">
                        <a href="https://www.facebook.com/universidadhumanitas/" target="_blank"><img src="../pages/images/feis-icon.png" alt="facebook icon movil" class="lg:mr-4 h-8 mr-1 relative cursor-pointer hover:scale-125 transition-transform duration-280"></a>
                        <a href="https://www.instagram.com/universidadhumanitas/" target="_blank"><img src="../pages/images/ins-icon.png" alt="instagram icon movil" class="lg:mr-4 h-8 mr-1 relative cursor-pointer hover:scale-125 transition-transform duration-280"></a>
                        <a href="https://twitter.com/UHumanitas" target="_blank"><img src="../pages/images/x-icon.png" alt="x icon movil" class="lg:mr-4 h-8 mr-1 relative cursor-pointer hover:scale-125 transition-transform duration-280"></a>
                        <a href="https://www.linkedin.com/school/universidad-humanitas/mycompany/" target="_blank"><img src="../pages/images/link-icon.png" alt="linkedin icon movil" class="lg:mr-4 h-8 mr-1 relative cursor-pointer hover:scale-125 transition-transform duration-280"></a>
                        <a href="https://mx.pinterest.com/uhumanitasoficial/" target="_blank"><img src="../pages/images/pin-icon.png" alt="pinterest icon movil" class="lg:mr-4 h-8 relative cursor-pointer hover:scale-125 transition-transform duration-280"></a>
                    </div>
                </div>
                <div class="flex justify-center w-full items-center">
                    <div class="flex items-center justify-center w-[70%]">
                        <a href="https://www.youtube.com/c/HumanitasEduMx?sub_confirmation=1" target="_blank">
                            <img src="../pages/images/youtube suscribete.png" alt="youtube_suscribete" >
                        </a>
                    </div>
                </div>
            </div>
            <FormMovil/>
        </div>
    </div>
</template>

<script>
    import FormMovil from '../pages/Blog/pagesComponents/FormMovil.vue';
    export default {
        components: {FormMovil},
    }
</script>

<style scoped>

</style>