<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">¿Qué es una acción socialmente responsable?</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-200">
          <img src="/images/accion-socialmente-responsable.webp" alt="Las acciones socialmente responsables obedecen a estándares internacionales" class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/ser-humanitas" class=" text-hone">Ser Humanitas</router-link> > <router-link to="/blog/ser-humanitas/responsabilidad-social" class=" text-hone">Responsabilidad Social</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex text-left list-disc pl-5 italic text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">09 de mayo de 2024</li>  <li>Iván Solís Rivera</li></ul>
            </div>
            <p class="md:mb-4 mb-6">La respuesta a la pregunta: ¿Qué es una acción socialmente responsable? Está vinculada <b>al modelo de <router-link to="/blog/ser-humanitas/responsabilidad-social/por-que-es-importante-la-responsabilidad-social-universitaria"  class="text-htwo">Responsabilidad Social Corporativa</router-link> (RSC) que se refiere a la forma de interactuar de las empresas dentro del entorno y la comunidad.</b> También conocida como RS, por sus siglas en inglés, la acción socialmente responsable es un compromiso, generalmente favorable, con la sociedad en general y el medio ambiente.</p>
            <p class="md:mb-4 mb-6">Uno de sus objetivos de la responsabilidad social es <b>disminuir su impacto negativo, tanto sobre la naturaleza como en la sociedad.</b> Actualmente son muchas las instituciones y organizaciones que ponen en marcha acciones de Responsabilidad Social Corporativa.</p>
            <p class="md:mb-8 mb-10">Es de resaltar que una <b>acción socialmente responsable está vinculada con la ética individual y grupal,</b> la cual es una disciplina filosófica que se dedica al estudio de la conducta humana y las interacciones sociales, examinando conceptos como lo correcto, lo incorrecto, la felicidad, la virtud y el deber, entre otros aspectos. </p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">¿Cuál es la diferencia entre acción socialmente responsable y responsabilidad social?</h2>
            <p class="md:mb-4 mb-6"><b>La Responsabilidad Social Empresarial (RSE) se refiere al compromiso voluntario que asumen las empresas</b> para mejorar el ámbito económico, social y ambiental de la sociedad, además de cumplir con sus obligaciones legales y éticas. De esta manera, <b>las empresas no solo buscan ganancias monetarias, sino que también consideran el impacto de sus acciones</b> en la comunidad y en el medio ambiente.</p>
            <p class="md:mb-4 mb-6">Por otro lado, <b>la Acción Social comprende las iniciativas puntuales que lleva a cabo una empresa para contribuir al bienestar de la sociedad.</b> Estas acciones pueden incluir donaciones, patrocinios, programas de voluntariado, proyectos de desarrollo comunitario, entre otros. </p>
            <p class="md:mb-8 mb-10">A diferencia de la RSE, <b>las acciones sociales suelen ser específicas, focalizadas en un área o proyecto en particular,</b> y pueden o no estar relacionadas con la actividad principal de la empresa. Mientras que la RSE es un enfoque integral que guía la forma en que una empresa opera y se relaciona con su entorno, las acciones sociales son intervenciones específicas que forman parte de esa estrategia más amplia de responsabilidad social.</p>
            <img src="/images/responsabilidad-social-ejemplos-humanitas.webp" alt="La Responsabilidad Social se presenta mediante programas de voluntariado" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Responsabilidad Social: ejemplos</h2>
            <p class="md:mb-8 mb-10">Los principales ejecutores de una acción socialmente responsable son las instituciones y empresas cuyas iniciativas se enfocan a los cambios sociales y ambientales. Algunos ejemplos de la Responsabilidad Social son:</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Programas de voluntariado corporativo</h3>
            <p class="md:mb-8 mb-10">Las empresas u <b>organizaciones pueden fomentar la participación de sus empleados</b> en actividades de voluntariado que beneficien a la comunidad, como ayuda en comedores sociales o programas educativos para niños.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Reducción del impacto medioambiental</h3>
            <p class="md:mb-8 mb-10">Implementar acciones para <b>reducir la huella medioambiental de la empresa,</b> como el uso de energías renovables, la optimización de procesos para reducir el consumo de recursos naturales y la gestión adecuada de residuos.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Iniciativas de inclusión y diversidad</h3>
            <p class="md:mb-8 mb-10"><b>Promover la igualdad de oportunidades</b> en el lugar de trabajo, fomentando la diversidad y la inclusión de personas de diferentes géneros, etnias, orientaciones sexuales y capacidades.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Ética en la cadena de suministro</h3>
            <p class="md:mb-8 mb-10">Son las <b>prácticas que hacen que los proveedores de la empresa cumplan con estándares éticos y laborales</b> adecuados, evitando la explotación laboral y garantizando condiciones de trabajo justas en toda la cadena de suministro.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Transparencia y buen gobierno corporativo</h3>
            <p class="md:mb-8 mb-10"><b>Implementar prácticas de transparencia en la gestión empresarial,</b> garantizando la divulgación de información relevante para los diferentes grupos de interés, así como el cumplimiento de normativas y regulaciones aplicables.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Requisitos para ser una empresa socialmente responsable</h2>
            <p class="md:mb-4 mb-6">Para ser considerada una empresa socialmente responsable a nivel internacional, existen diferentes estándares y marcos de referencia que las empresas pueden utilizar para guiar sus esfuerzos en responsabilidad social, como <b>los Principios del Pacto Mundial de las Naciones Unidas y la normatividad ISO 26000.</b></p>
            <p class="md:mb-4 mb-6"><b>El Pacto Mundial de las Naciones Unidas es una iniciativa internacional que busca promover la adopción de principios universalmente aceptados</b> en áreas clave, como derechos humanos, estándares laborales, medio ambiente y anticorrupción.</p>
            <p class="md:mb-4 mb-6">Estos principios proporcionan un marco ético para las empresas y organizaciones que desean alinear sus prácticas con valores universales y contribuir positivamente al desarrollo sostenible.</p>
            <p class="md:mb-4 mb-6">En lo que se refiere a México, <b>las empresas que buscan ser socialmente responsables deben de cumplir con los requisitos que marcan la Norma Mexicana en Responsabilidad Social (NMX-R-025-SCFI-2015) o el distintivo ESR,</b> que lo emite el Centro Mexicano para la Filantropía (Cemefi) y la Alianza por la Responsabilidad Social Empresarial en México (AliaRSE).</p>
            <p class="md:mb-4 mb-6"><b>Este distintivo acredita a las empresas, que asumen voluntaria y públicamente el compromiso de implantación y mejora continua</b> de una gestión socialmente responsable, como parte de su cultura y estrategia de negocio.</p>
            <p class="md:mb-8 mb-10">Tanto nacional como internacional, <b>el proceso de las empresas para ser socialmente responsables implica enfocarse en compromiso con la comunidad</b> y el entorno; respeto a los derechos humanos; prácticas laborales justas; cuidado del medio ambiente; transparencia y ética en los negocios; reporte y rendición de cuentas.</p>
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
                <ContactoVue class="m-3"/>
            </div>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Responsabilidad social con los clientes: Ejemplos</h2>
            <p class="md:mb-8 mb-10">A continuación describimos algunos ejemplos de empresas que demuestran responsabilidad social con sus clientes:</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Dove</h3>
            <p class="md:mb-8 mb-10">La marca es conocida por sus campañas de marketing centradas en la autoestima y la belleza real, una de sus campañas titulada: <b>"Dove Real Beauty" promueve la aceptación y la diversidad,</b> lo que refleja su compromiso con el bienestar emocional de sus clientes.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Ben &amp; Jerry's</h3>
            <p class="md:mb-8 mb-10">La especialista en helados no solo se preocupa por ofrecer productos de alta calidad, sino que también se involucra en temas sociales y ambientales. Por ejemplo, apoyan campañas de justicia social y utilizan ingredientes sostenibles, lo que demuestra su compromiso con valores éticos y sociales.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">IKEA</h3>
            <p class="md:mb-8 mb-10">La transnacional se enfoca en ofrecer productos de calidad a precios accesibles. Además, <b>la compañía se compromete a mejorar la vida cotidiana de sus clientes a través de soluciones innovadoras</b> y sostenibles para el hogar.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Costco</h3>
            <p class="md:mb-8 mb-10">La cadena de supermercados está comprometida con la calidad de sus productos y servicios, <b>ofreciendo a sus clientes una política de devolución muy flexible.</b> Además, la empresa trata de mantener precios bajos y ofrece salarios.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Coca-Cola</h3>
            <p class="md:mb-8 mb-10">La Estrategia de Sostenibilidad de Coca-Cola FEMSA se centra en <b>contribuir a los Objetivos de Desarrollo Sostenible de las Naciones Unidas</b> y sus iniciativas se alinean con el uso eficiente del agua y el tratamiento adecuado de las aguas residuales.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Ejemplos de responsabilidad social</h2>
            <p class="md:mb-4 mb-6">Un ejemplo de responsabilidad social es el modelo que adapta la <b><a href="https://humanitas.edu.mx/" target="_blank" class="text-htwo">Universidad Humanitas</a></b> cuyo <b>compromiso como institución es con la sociedad global y el medio ambiente.</b></p>
            <p class="md:mb-8 mb-10">Las campañas y programas de la universidad buscan generar impactos positivos, en línea con la misión, visión y valores institucionales. La Responsabilidad Social es un pilar fundamental en el crecimiento y en el futuro como institución.</p>
            <img src="/images/requisitos-empresa-socialmente-responsable-programas.webp" alt="Existen diversos requisitos para ser una empresa socialmente responsable" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Actividades de responsabilidad social en Humanitas</h2>
            <p class="md:mb-4 mb-6"><b>Las actividades de responsabilidad social se basan en el modelo <a href="https://humanitas.edu.mx/responsabilidad" target="_blank" class="text-htwo">“Humanitas Vive”</a>, que durante 10 años ha fomentado la Responsabilidad Social</b> qen los universitarios, con el objetivo de reducir las brechas de desigualdad social y ayuden al medio ambiente.</p>
            <p class="md:mb-8 mb-10"><b>El programa “Humanitas Vive” surgió en 2014 con el objetivo de generar una sinergia entre los estudiantes universitarios y las Organizaciones de la Sociedad Civil</b> liberar su Servicio Social con acciones de impacto. Ante este contexto se han impulsado las siguientes actividades de Responsabilidad Social:</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Semillero Humanitas</h3>
            <p class="md:mb-8 mb-10"><b>La Universidad enaltece el sentido humano en el desarrollo profesional de los alumnos</b> con el programa de Fuerza H, “Humanitas Vive”.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Fuerza H y Humanitas Vive</h3>
            <p class="md:mb-8 mb-10">Estos modelos apoyan mediante <b>donativos monetarios, en especie y actividades sociales</b> de voluntariado tanto ambiental, social, deportivo y de salud.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Humanitas Vive</h3>
            <p class="md:mb-4 mb-6">Durante 2023 se apoyaron a 11 asociaciones, gracias a más de 2 mil 600 alumnos inscritos en el programa “Humanitas Vive” de todos los campus. <b>En 2023, se impulsaron a alrededor de 10 mil 188 personas en situación vulnerable</b> y 2 mil 240 animales de diversas OSC gracias a la Colecta Nacional de este programa.</p>
            <p class="md:mb-4 mb-6">De 2014 a 2024, <b>más de 11 mil 700 alumnos han liberado su Servicio Social realizando actividades de impacto social</b> a través de este modelo.</p>
            <p class="md:mb-8 mb-10">De la misma forma, se han ejecutado actividades para mejorar el medio ambiente, entre ellas: <b>reforestaciones de bosques, desove de tortugas, preservación del cangrejo, limpieza de presas, apoyo a comunidades,</b> entre otras.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Conclusiones</h2>
            <p class="md:mb-4 mb-6">La Universidad Humanitas promueve la responsabilidad social <b>a través de Humanitas Vive, ofreciendo a sus alumnos la oportunidad de cumplir con su servicio social.</b></p>
            <p class="mb-20">La Universidad colabora con instituciones autorizadas, <b>brindándoles apoyo mediante donaciones en especie o monetarias,</b> así como organizando actividades afines a sus áreas de interés.</p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArtVue :ant="true" :linkAnt="'/academico/estudia-en-linea/metodologias-aprendizaje-linea'" :linkSig="'/recursos/entrevistas-y-conferencias/liderazgo-femenino-empresarial-exitoso'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import ContactoVue from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue';
import SigArtVue from '../../pagesComponents/SigArt.vue';
import VideoClipVue from '../../pagesComponents/videoClip.vue';

export default {
    components: { Comentarios, ConComentarios, ContactoVue, LikesVue, ShareP, VideoClipVue, SigArtVue},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '0024-ART-SER-HUMANITAS-RESPONSABILIDAD-SOCIAL-090524',
            ruta: '/ser-humanitas/responsabilidad-social/acciones-socialmente-responsables',
            title: '¿Qué es una acción socialmente responsable?',
            image: 'https://blog.humanitas.edu.mx/images/Costo-y-requisitos-estudiar-maestria.jpg',
            text: 'Para saber qué es una acción socialmente responsable, recurrimos a los estándares internacionales y a ejemplos concretos.'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx/academico/maestria/costo-y-requisitos-estudiar-maestria';

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>