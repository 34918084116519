<template>
    <div>
      <CataBueno :titulo="'Julio 2024'" :subtitulo="''" :filtros="filtros" :articles="articles"/>
    </div>
</template>

<script>
import CataBueno from '../../pagesComponents/CataBueno.vue';

export default {
components: { CataBueno },
data() {
    return {
    articles: [
      {
        "id": "0057-ART-APRENDE-TIPS-310724",
        "titulo": "¿No te quedaste en la UNAM? Descubre estas alternativas",
        "fecha": "31 de julio de 2024",
        "autor": "Carolina Reyes Martínez",
        "descripcion": "¿Si presentaste tu examen de admisión a la UNAM y no fuiste seleccionado, te damos alternativas para continuar tu educación universitaria. ¡Conócelas!",
        "portada": "/images/no-te-quedaste-unam-universidad-humanitas.webp",
        "enlace": "/aprende/tips/no-te-quedaste-en-la-unam-descubre-estas-alternativas"
      },
      {
        "id": "0055-ART-APRENDE-TIPS-050724",
        "titulo": "¿Ansiedad es lo mismo que angustia?: Aquí la respuesta",
        "fecha": "08 de julio de 2024",
        "autor": "Iván Solís Rivera",
        "descripcion": "Alguna vez te haz preguntado: ¿Ansiedad es lo mismo que angustia?, ¿Haz experimentado estos padecimientos?, te decimos cómo evitarlos.",
        "portada": "/images/ansiedad-mismo-angustia.webp",
        "enlace": "/aprende/tips/ansiedad-es-lo-mismo-que-angustia"
      },
      {
        "id": "0053-ART-RECURSOS-CASOS-REALES-020724",
        "titulo": "Cómo identificar y superar relaciones tóxicas: Lo que debes de saber",
        "fecha": "02 de julio de 2024",
        "autor": "Carolina Reyes Martínez",
        "descripcion": "Aprende a reconocer los signos de una relación tóxica y cómo prevenirlos para vivir una vida más sana y feliz.",
        "portada": "/images/identifica-relaciones-toxicas.webp",
        "enlace": "/recursos/casos-reales/como-identificar-y-superar-relaciones-toxicas"
      },
        
    ], // Array de artículos original
    filtros: {
      "0057-ART-APRENDE-TIPS-310724": {
        Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y teatro"],
        Ciencias_sociales: ["Derecho", "Ciencias Políticas y Administración Pública"],
        Economico_administrativas: ["Administración", "Contabilidad"]
      },
      "0055-ART-APRENDE-TIPS-050724": {
        Humanidades: ["Psicología"],
        Ciencias_sociales: [],
        Economico_administrativas: []
      },
      "0053-ART-RECURSOS-CASOS-REALES-020724": {
        Humanidades: ["Psicología", "Educación"],
        Ciencias_sociales: [],
        Economico_administrativas: []
      },
    },
    filtrosSeleccionados: null, // Lista de filtros seleccionados
    };
},
methods: {
    
}
};
</script>

<style>
/* Estilos CSS si los tienes */
</style>