import AprendeCulturaG from "../pages/Blog/Aprende/Aprende-CulturaG.vue";
import AprendeTendencias from "../pages/Blog/Aprende/Aprende-Tendencias.vue";
import AprendeTips from "../pages/Blog/Aprende/Aprende-Tips.vue";
import cultuGenRoutes from "./Aprende/cultuGenRoutes.ts"
import tipsRoutes from "./Aprende/tipsRoutes.ts";
import tendenciasRoutes from "./Aprende/tendenciasRoutes.ts";

export default [
    {
        path: '/blog/aprende/cultura-general',
        name: 'Aprende: Cultura General',
        component: AprendeCulturaG,
        meta: {
            requireAuth: false
        },
    },
    ...cultuGenRoutes,
    {
        path: '/blog/aprende/tendencias',
        name: 'Aprende: Tendencias',
        component: AprendeTendencias,
        meta: {
            requireAuth: false
        },
    },
    ...tipsRoutes,
    {
        path: '/blog/aprende/tips',
        name: 'Aprende: Tips',
        component: AprendeTips,
        meta: {
            requireAuth: false
        },
    },
    ...tendenciasRoutes,
]