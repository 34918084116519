import Home from "../pages/Blog/Home.vue";
import Academico from "../pages/Blog/Academico.vue";
import Aprende from "../pages/Blog/Aprende.vue";
import Recursos from "../pages/Blog/Recursos.vue";
import SerHum from "../pages/Blog/SerHum.vue";
import AcademicoRoutes from "./AcademicoRoutes.ts";
import AprendeRoutes from "./AprendeRoutes.ts";
import RecursosRoutes from "./RecursosRoutes.ts";
import SerHumRoutes from "./SerHumRoutes.ts";
import Hist2024 from "./Historico/Hist2024.ts";

export default 
    [
        {
            path: '/blog',
            name: 'Blog Humanitas',
            component: Home,
            meta: {
                title: "Blog Humanitas | Conocimiento que expande tu mente",
                requireAuth: false,
            }
        },
        ...Hist2024,
        {
            path: '/blog/academico',
            name: 'Académico',
            component: Academico,
            meta: {
				requireAuth: false
			},
        },
        ...AcademicoRoutes,
        {
            path: '/blog/aprende',
            name: 'Aprende',
            component: Aprende,
            meta: {
				requireAuth: false
			},
        },
        ...AprendeRoutes,
        {
            path: '/blog/recursos',
            name: 'Recursos',
            component: Recursos,
            meta: {
				requireAuth: false
			},
        },
        ...RecursosRoutes,
        {
            path: '/blog/ser-humanitas',
            name: 'Ser Humanitas',
            component: SerHum,
            meta: {
				requireAuth: false
			},
        },
        ...SerHumRoutes,
        
    ]

