<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">Las mejores técnicas de estudio: triunfa en la <br class="md:block hidden"> universidad</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-300">
          <img src="/images/Las-mejores-tecnicas-estudio-triunfar-universidad.jpg" class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica" >
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/aprende" class=" text-hone">Aprende</router-link> > <router-link to="/blog/aprende/tips" class=" text-hone">Tips</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex list-disc text-left pl-5 italic text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">24 de enero de 2024</li>  <li>Carolina Reyes Martínez</li></ul>
            </div>
            <p class=" md:mb-4 mb-6"><b>Extracto:</b> Aprende a estudiar con creatividad, a mantener un bienestar óptimo y a utilizar <b>las mejores técnicas</b> a tu favor para maximizar tu rendimiento académico y disfrutar cada momento de tu vida universitaria.</p>
            <p class=" md:mb-4 mb-6">La universidad puede ser emocionante pero también es un desafío. <b>Dominar el arte de estudiar es crucial para tener éxito</b> en este entorno académico competitivo. </p>
            <p class=" md:mb-8 mb-10">En este artículo, <b>te compartimos las mejores técnicas para optimizar tu estudio e impulsar tu desempeño escolar.</b> Prepárate para transformar tu forma de estudiar y sácale el máximo partido a tu vida universitaria.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Descubre estas técnicas que te ayudarán</h2>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">El método Pomodoro: optimiza tu concentración </h3>
            <p class=" md:mb-4 mb-6"><b>La técnica del tomate o Pomodoro la inventó Francisco Cirillo,</b> un estudiante italiano que veía que él y sus compañeros no avanzaban en sus estudios: podían desvelarse toda la noche tomando café y repasando sus apuntes, y al otro día se daban cuenta de que poco o nada habían comprendido.</p>
            <p class=" md:mb-4 mb-6"><b>Un día, él toma un reloj pequeño de cocina o temporizador que tenía forma de tomate -pomodoro en italiano- y lo programa por diez minutos,</b> con ello se propuso en concentrarse a estudiar, sólo eso, y darse una pequeña recompensa terminado ese tiempo. Fue aumentando el tiempo para ver qué tal le funcionaba.</p>
            <p class=" md:mb-4 mb-6">Poco a poco, <b>Francisco se dio cuenta que a los 30 minutos comenzaba a distraerse,</b> por lo que comenzó a estudiar en lapsos de 25 minutos. <b>Así nació el método Pomodoro: bloques de 25 minutos con descansos de cinco a diez minutos.</b></p>
            <p class=" md:mb-4 mb-6">Esta técnica te ayudará si te cuesta mucho trabajo mantenerte enfocado en una tarea. <b>Sus beneficios: calidad en tu concentración</b> porque en un periodo pequeño puedes realizar tu tarea y avanzar.</p>
            <p class=" md:mb-8 mb-10">También, <b>te da una mejor gestión del tiempo,</b> así sabrás cuánto puedes dedicarle a una tarea o a estudiar y aumentará tu productividad. Esto a su vez, te mantendrá motivado al ver que logras tus metas. ¡Pruébalo! <b>Es la mejor técnica si requieres mayor esfuerzo de concentración.</b></p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Técnica de Feynman: aprender enseñando</h3>
            <p class=" md:mb-4 mb-6"><b>Esta técnica de estudio la creó Richard Feynman,</b> ganador del Nobel de Física en el año 1965, y la enseñaba a sus alumnos que asistían a la Universidad de Cornell, Estados Unidos.</p>
            <p class=" md:mb-4 mb-6">Esta metodología es muy innovadora y eficaz, ya que <b>consiste en simplificar conceptos difíciles, o sea pasarlos a palabras sencillas y comprensibles</b> para todos. Ya que tienes la información puedes transmitirla a tus compañeros, de esa manera aprendes y enseñas los conocimientos a los demás de manera más ágil y ejercitas tu memoria.</p>
            <p class="mb-2"><b>Consta de 4 pasos: </b></p>
            <ul class="list-decimal pl-6 md:mb-8 mb-10">
                <li><b>Selecciona un tema o definición</b> que quieras aprender. Ahora escríbelo en un papel o en tu tablet. De esta manera le dices a tu cerebro que eso es lo que vas a aprender.</li>
                <li><b>Lee sobre este tema</b> o definición, después <b>haz un resumen de lo que para ti fue importante, lo que aprendiste de él con tus palabras,</b> de manera sencilla para que después, en voz alta, expliques ese tema. Visualiza esto como si lo explicaras a un niño o a una persona que no sabe nada sobre él. </li>
                <li><b>Ya que tienes lo anterior, complementa con la información que te haga falta.</b> Lee de nuevo tu tema, en este momento, mientras más completo esté, tu calificación será mejor.</li>
                <li>Por último, dale otra revisión y reescríbelo si es necesario, <b>luego imagina que le hablarás de ese tema a una persona.</b> En este punto, ya lo habrás comprendido bien y podrás transmitir ese conocimiento a alguien más.</li>
            </ul>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Técnicas de memorización efectivas: reglas mnemotécnicas</h3>
            <p class=" mb-2">Aunque suena muy complicado, <b>estas técnicas de estudio son sencillas y su finalidad es aumentar y potenciar tu memoria. </b> Estas reglas mnemotécnicas <b>consisten en que todo el conocimiento nuevo se retenga por asociación con </b>algo conocido:</p>
            <ul class="list-decimal pl-6 md:mb-8 mb-10">
                <li><b>Establece un vínculo entre dos términos. </b> Por ejemplo, el autor de <em>La Odisea</em> es Homero, y existe una calle de la ciudad con ese nombre, entonces puede ser Homero calle de Polanco y autor de <em>La Odisea</em>. Juega con nombres que te sean familiares.</li>
                <li><b>Técnica de los acrósticos. </b>Si quieres memorizar algunos países de América del Norte, puedes elaborar este acróstico: <b>CaMeEU,</b> que significa <b>Ca</b>nadá, <b>Mé</b>xico, <b>E</b>stados <b>U</b>nidos. </li>
                <li><b>Rimas. Imagina que lo que quieres memorizar es una canción, </b>por ejemplo, si quieres aprender qué es la sinapsis, puedes inventar algo así: una neurona que manda un mensaje hace una sinapsis para comunicar, y la canción sería alguna que esté de moda y la tengas en mente. </li>
                <li><b>Técnica del acrónimo: </b>Si por ejemplo quieres recordar que un proyecto tiene que ser único, genial y novedoso, puedes probar con <b>U</b> de “único”, luego puedes poner <b>N</b> de “novedoso” y para que sea efectivo este acrónimo, puedes sustituir “genial” por <b>I</b>mpresionante, y quedará: <b>UNI,</b> tiene que ser fácil de recordar, así que puedes usar sinónimos. Así puedes generar tus acrónimos que deben ser fáciles de recordar. </li>
                <li><b>Método de Loci o palacio de los recuerdos.</b> Es una técnica que se usa desde los griegos y los romanos, y <b>consiste en la asociación del texto que quieres memorizar con un lugar físico,</b> por ejemplo, <b>tu casa puede ser este palacio de Loci:</b> la entrada la puedes asignar a capítulo uno, y ahí <b>puedes asignar lo más relevante del capítulo a un objeto:</b> las llaves colgadas en la entrada son la introducción de ese capítulo que habla sobre la historia del personaje, sus características, etc. Y así, a <b>cada habitación de tu casa y a cada objeto, le asignas los demás capítulos.</b> Es una manera de acordarte más fácilmente de lo que trata ese libro o lo que quieras memorizar. </li>
            </ul>
            <img src="/images/Las-mejores-tecnicas-estudio-triunfar-universidad-espacio-adecuado.jpg" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Consejos prácticos para estudiar mejor en la universidad</h2>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Creación de un entorno de estudio óptimo</h3>
            <p class=" md:mb-4 mb-6">Además de las técnicas, <b>crear hábitos de estudio es primordial. El espacio en el que vas a estudiar es muy importante,</b> organízalo con una mesa o escritorio adecuados, así como una silla que sea cómoda.</p> 
            <p class=" md:mb-8 mb-10">Ten en cuenta una buena iluminación, natural o artificial, puedes usar una lámpara de luz cálida que no te canse la vista. Es importante que no tengas distracciones en la medida de lo posible. Esto te puede ayudar mucho en tu rendimiento académico.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Adoptando hábitos de estudios saludables y sostenibles</h2>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Gestión del tiempo y planificación</h3>
            <p class=" md:mb-8 mb-10">Administrar tu tiempo es todo un arte y puedes aprenderlo. <b>La técnica o Ley de Pareto consiste en la regla 80/20</b> que aplicada al estudio sería que <b>el 20% que dedicas a estudiar, te aporta el 80% de aprendizaje,</b> o sea que en vez de estudiar el día entero, puedes concentrar todo tu esfuerzo en ese 20% de tu tiempo que te dará el 80% de tus resultados. <b>Estudia menos horas pero sí bien aprovechadas</b> y enfocándote en la materia, sin distracciones.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">La música: tu aliada en los estudios</h3>
            <p class=" md:mb-8 mb-10"><b>La música puede ser una gran compañera</b> a la hora de estudiar, pero es importante elegir la que te ayude a concentrarte.  <b>La música clásica, por ejemplo, ha demostrado tener efectos positivos en la concentración y la memoria.</b> También puedes optar por música instrumental o ambiental, y la que te guste pero que permita que puedas poner tu atención en lo que estás estudiando. <b>Ajusta el volumen para preservar tu audición.</b></p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Manteniendo el bienestar físico y mental</h3>
            <p class=" md:mb-8 mb-10"><b>El bienestar es un estilo de vida: puedes incorporar</b> a tu rutina, <b>ejercicios de respiración o meditación, ya que te ayudarán a concentrarte</b> y a manejar el estrés. Además, <b>cuidar tu alimentación, realizar otras actividades o hobbies, benefician a tu cuerpo pero también nutren tu mente,</b> preparándote para enfrentar los retos académicos con energía y claridad.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Técnicas creativas para reforzar el aprendizaje</h2>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Los mapas mentales y los mapas conceptuales</h3>
            <p class=" md:mb-4 mb-6"><b>Un mapa mental es una representación gráfica o diagrama de algunos conceptos que van relacionados</b> y te ayuda a ordenar las ideas de un tema, el cual lo pones al centro y se va ramificando en subtemas.</p> 
            <p class=" md:mb-8 mb-10"><b>En tanto, los mapas conceptuales además de ser representaciones gráficas de ideas, también de flujos de trabajo</b> y en eso se diferencian de los mapas mentales porque hay una jerarquización de temas o procesos.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Deja que tu creatividad fluya con el Sketchnoting</h3>
            <p class=" md:mb-4 mb-6">Y por último, <b>esta técnica se llama Sketchnoting donde combinas dibujos y notas al tomar tus apuntes en clases,</b> es una manera divertida de aprender y retar a tu mente, ya que ambos hemisferios del cerebro trabajan al utilizarla. Si tú crees que no sabes dibujar, ve poco a poco para encontrar tu estilo, <b>no te preocupes cómo van quedando tus dibujos, la idea es que todo fluya y te diviertas</b> en este proceso.</p>
            <p class=" md:mb-8 mb-10"><b>Con estas técnicas de estudio y consejos, estás listo para transformar tu experiencia universitaria.</b> No se trata sólo de obtener buenas notas, sino de disfrutar el proceso de aprendizaje, cuidar de ti mismo y prepararte para el futuro.</p>
            <!-- Aqui va el componente de contacto, no se te olvide hacerlo manolo -->
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
                <Contacto class="m-3"/>
            </div>
            <p class="text-htwo text-left mb-6">Explora más: <a href="https://humanitas.edu.mx/" target="_blank" class="text-htwo">Universidad Humanitas </a></p>
            <p class="text-htwo text-left mb-20">Te puede interesar: <router-link to="/blog/aprende/tips/estoicismo-para-estudiantes-universitarios-y-beneficios"  class="text-htwo">Estoicismo: cómo puede ayudar a los estudiantes a superar desafíos</router-link></p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="true" :linkAnt="'/academico/maestria/costo-y-requisitos-estudiar-maestria'" :linkSig="'/aprende/tips/mejores-tecnicas-estudio-para-universidad'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import Contacto from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue'
import SigArt from '../../pagesComponents/SigArt.vue';

export default {
    components: { Comentarios, ConComentarios, Contacto, LikesVue, ShareP, SigArt},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '03-ART-APRENDE-TIPS-240124',
            ruta: '/aprende/tips/mejores-tecnicas-estudio-para-universidad',
            title: 'Domina tus estudios: técnicas y consejos para tu éxito universitario',
            image: 'https://blog.humanitas.edu.mx/images/Las-mejores-tecnicas-estudio-triunfar-universidad.jpg',
            text: 'Descubre las mejores técnicas de estudio y consejos para maximizar tu rendimiento en la universidad. Estudia de manera más inteligente y eficaz.'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Ruta relativa de la imagen
        const relativeImageUrl = this.image;

        // Convertir la ruta relativa en una URL absoluta
        const imageUrl = relativeImageUrl;

        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx/aprende/tips/mejores-tecnicas-estudio-para-universidad';

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = imageUrl;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>