<template>
    <div>
      <CataBueno :titulo="'Mayo 2024'" :subtitulo="''" :filtros="filtros" :articles="articles"/>
    </div>
</template>

<script>
import CataBueno from '../../pagesComponents/CataBueno.vue';

export default {
components: { CataBueno },
data() {
    return {
    articles: [
        {
          "id": "0032-ART-RECURSOS-ENTREVISTAS-Y-CONFERENCIAS-300524",
          "titulo": "La inclusión de las mujeres y la diversidad en el Derecho por Mariel Cabañas",
          "fecha": "30 de mayo de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "Descubre cómo la inclusión y la diversidad en los ámbitos del Derecho y educativo enriquecen a la sociedad, con la experiencia de Mariel Cabañas, presidenta de Abogadas MX.",
          "portada": "/images/inclusion-mujeres-diversidad-derecho-impacto-benefico-sociedad.webp",
          "enlace": "/recursos/entrevistas-y-conferencias/inclusion-diversidad-en-derecho-por-mariel-cabanas"
        },
        {
          "id": "0035-ART-SER-HUMANITAS-COMUNIDAD-30052",
          "titulo": "¿Cuánto cuesta una mensualidad en la Universidad Humanitas?",
          "fecha": "30 de mayo de 2024",
          "autor": "Redacción Humanitas",
          "descripcion": "Descubre cuánto cuesta una mensualidad en la Universidad Humanitas y el nivel de la calidad educativa de reconocimiento internacional.",
          "portada": "/images/cuanto-cuesta-mensualidad-universidad-humanitas-instalaciones.webp",
          "enlace": "/ser-humanitas/comunidad/cuanto-cuesta-mensualidad-universidad-humanitas"
        },
        {
          "id": "0033-ART-APRENDE-TIPS-280524",
          "titulo": "¿Cómo evaluar la calidad de una universidad privada?",
          "fecha": "28 de mayo de 2024",
          "autor": "Iván Solís Rivera",
          "descripcion": "Conoce los puntos clave para elegir la mejor opción educativa y responde a la pregunta: ¿Cómo evaluar la calidad de una universidad privada?",
          "portada": "/images/como-evaluar-calidad-universidad-privada-estudiantes.webp",
          "enlace": "/aprende/tips/puntos-clave-para-evaluar-la-calidad-de-una-universidad-privada"
        },
        {
          "id": "0031-ART-ACADEMICO-MAESTRIA-230524",
          "titulo": "Derecho corporativo empresarial: aspectos fundamentales",
          "fecha": "23 de mayo de 2024",
          "autor": "Iván Solís Rivera",
          "descripcion": "El Derecho Corporativo Empresarial es una área jurídica especializada que conjuga diversas áreas de conocimiento, conócelas.",
          "portada": "/images/derecho-corporativo-empresarial-mercantil.webp",
          "enlace": "/academico/maestria/derecho-corporativo-empresarial"
        },
        {
          "id": "0030-ART-RECURSOS-ENTREVISTAS-Y-CONFERENCIAS-220524",
          "titulo": "Procedimientos y técnicas de auditoría: Pruebas clave y ejemplos",
          "fecha": "22 de mayo de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "Descubre los procedimientos y técnicas de la auditoría, y algunos ejemplos de Ricardo Sandoval Huerta, Coordinador de Auditoría de Corporativo Humanitas.",
          "portada": "/images/procedimientos-tecnicas-auditoria-precision-universidad-humanitas.webp",
          "enlace": "/recursos/entrevistas-y-conferencias/procedimientos-tecnicas-de-auditoria-y-ejemplos-ricardo-sandoval-huerta"
        },
        {
          "id": "0029-ART-APRENDE-CULTURA-GENERAL-210524",
          "titulo": "Uso responsable de las redes sociales: Guía para navegar de forma segura",
          "fecha": "22 de mayo de 2024",
          "autor": "Iván Solís Rivera",
          "descripcion": "El uso responsable de las redes sociales recae en el nivel de consciencia y educación de los usuarios respecto a los efectos y beneficios de estas plataformas.",
          "portada": "/images/uso-responsable-redes-sociales-beneficios-jovenes.webp",
          "enlace": "/aprende/cultura-general/consejos-sobre-uso-responsable-de-redes-sociales"
        },
        {
          "id": "0027-ART-RECURSOS-ENTREVISTAS-Y-CONFERENCIAS-200524",
          "titulo": "Desarrollo humano: Una perspectiva integral por Helios Herrera",
          "fecha": "20 de mayo de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "Descubre el enfoque único del consultor Helios Herrera sobre el desarrollo humano.",
          "portada": "/images/desarrollo-humano-integra-crecimiento-personal-profesional.webp",
          "enlace": "/recursos/entrevistas-y-conferencias/desarrollo-humano-una-perspectiva-integral-helios-herrera"
        },
        {
          "id": "0028-ART-SER-HUMANITAS-APRENDE-TENDENCIAS-160524",
          "titulo": "Mejores competencias digitales para profesionales",
          "fecha": "17 de mayo de 2024",
          "autor": "Iván Solís Rivera",
          "descripcion": "Conoce cuáles son las competencias digitales para profesionales más relevantes que debes desarrollar para ser un profesional.",
          "portada": "/images/competencias-digitales-profesionales-digitalizacion.webp",
          "enlace": "/aprende/tendencias/competencias-digitales-para-profesionales"
        },
        {
          "id": "25-ART-APRENDE-LICENCIATURA-CULTURA-GRAL-140524",
          "titulo": "Celebrando el Día del Maestro: reconozcamos a nuestros profesores",
          "fecha": "15 de mayo de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "La importancia del Día del Maestro: cómo se celebra en México y la influencia de los profesores en la sociedad. ¡Descubre más!",
          "portada": "/images/dia-del-maestro-mexico-humanitas.webp",
          "enlace": "/aprende/cultura-general/dia-del-maestro-2024"
        },
        {
          "id": "0023-ART-RECURSOS-ENTREVISTAS-Y-CONFERENCIAS-100524",
          "titulo": "Liderazgo femenino empresarial: la clave del éxito",
          "fecha": "10 de mayo de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "El liderazgo femenino está transformando las empresas globalmente. Descubre cómo en este artículo.",
          "portada": "/images/liderazgo-femenino-empresarial-exitoso-mujer-vs-liderazgo-masculinoempresaria.webp",
          "enlace": "/recursos/entrevistas-y-conferencias/liderazgo-femenino-empresarial-exitoso"
        },
        {
          "id": "0024-ART-SER-HUMANITAS-RESPONSABILIDAD-SOCIAL-090524",
          "titulo": "¿Qué es una acción socialmente responsable?",
          "fecha": "09 de mayo de 2024",
          "autor": "Iván Solís Rivera",
          "descripcion": "Para saber qué es una acción socialmente responsable, recurrimos a los estándares internacionales y a ejemplos concretos.",
          "portada": "/images/accion-socialmente-responsable.webp",
          "enlace": "/ser-humanitas/responsabilidad-social/acciones-socialmente-responsables"
        },
        {
            "id": "022-ART-ACADEMICO-ESTUDIA-EN-LINEA-070524",
            "titulo": "Conoce las metodologías del aprendizaje en línea",
            "fecha": "07 de mayo de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Explora las principales metodologías de aprendizaje en línea y descubre cómo pueden transformar la experiencia educativa.",
            "portada": "/images/metodologias-aprendizaje-linea-.webp",
            "enlace": "/academico/estudia-en-linea/metodologias-aprendizaje-linea"
        },
        {
            "id": "021-ART-ACADEMICO-LICENCIATURA-070524",
            "titulo": "Universidades sin examen de admisión",
            "fecha": "07 de mayo de 2024",
            "autor": "Iván Solís Rivera",
            "descripcion": "Universidades sin examen de admisión privadas y públicas tienen un objetivo común: brindar continuidad de estudios.",
            "portada": "/images/universidades-examen-admision.webp",
            "enlace": "/academico/licenciatura/universidades-sin-examen-admision"
        },
        {
            "id": "020-ART-ACADEMICO-LICENCIATURA-290424",
            "titulo": "Dominando el futuro: Descubre las tendencias clave del Diseño Gráfico para 2024",
            "fecha": "07 de mayo de 2024",
            "autor": "Carolina Reyes Martínez/Redacción Humanitas",
            "descripcion": "Explora las principales tendencias de diseño gráfico para 2024. Desde la realidad aumentada hasta la sostenibilidad, descubre cómo influirán en tu carrera y proyectos creativos.",
            "portada": "/images/tendencia-diseno-grafico-2024-colores-fluorescentes-neon-humanitas.webp",
            "enlace": "/academico/licenciatura/tendencias-diseno-grafico-2024"
        },
    ], // Array de artículos original
    filtros: {
        "0035-ART-SER-HUMANITAS-COMUNIDAD-30052": {
          Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y Teatro"],
          Ciencias_sociales: ["Derecho", "Ciencias Políticas", "Administración Pública"],
          Economico_administrativas: ["Administración", "Contabilidad"]
        },
        "0033-ART-APRENDE-TIPS-280524": {
          Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y Teatro"],
          Ciencias_sociales: ["Derecho", "Ciencias Políticas", "Administración Pública"],
          Economico_administrativas: ["Administración", "Contabilidad"]
        },
        "0031-ART-ACADEMICO-MAESTRIA-230524": {
          Humanidades: [],
          Ciencias_sociales: ["Derecho"],
          Economico_administrativas: []
        },
        "0032-ART-RECURSOS-ENTREVISTAS-Y-CONFERENCIAS-300524": {
          Humanidades: [],
          Ciencias_sociales: ["Derecho"],
          Economico_administrativas: []
        },
        "0030-ART-RECURSOS-ENTREVISTAS-Y-CONFERENCIAS-220524": {
          Humanidades: [],
          Ciencias_sociales: [],
          Economico_administrativas: ["Administración", "Contabilidad"]
        },
        "0029-ART-APRENDE-CULTURA-GENERAL-210524": {
          Humanidades: ["Educación", "Psicología"],
          Ciencias_sociales: [],
          Economico_administrativas: []
        },
        "0027-ART-RECURSOS-ENTREVISTAS-Y-CONFERENCIAS-200524": {
          Humanidades: ["Educación"],
          Ciencias_sociales: [],
          Economico_administrativas: []
        },
        "0028-ART-SER-HUMANITAS-APRENDE-TENDENCIAS-160524": {
          Humanidades: [],
          Ciencias_sociales: [],
          Economico_administrativas: ["Administración"]
        },
        "25-ART-APRENDE-LICENCIATURA-CULTURA-GRAL-140524": {
            Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y Teatro"],
            Ciencias_sociales: ["Derecho", "Ciencias Políticas", "Administración Pública"],
            Economico_administrativas: ["Administración", "Contabilidad"]
        },
        "0023-ART-RECURSOS-ENTREVISTAS-Y-CONFERENCIAS-100524": {
          Humanidades: [],
          Ciencias_sociales: [],
          Economico_administrativas: ["Administración"]
        },
        "0024-ART-SER-HUMANITAS-RESPONSABILIDAD-SOCIAL-090524": {
          Humanidades: [],
          Ciencias_sociales: [],
          Economico_administrativas: ["Administración"]
        },
        "022-ART-ACADEMICO-ESTUDIA-EN-LINEA-070524": {
          Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y Teatro"],
          Ciencias_sociales: ["Derecho", "Ciencias Políticas", "Administración Pública"],
          Economico_administrativas: ["Administración", "Contabilidad"]
        },
        "021-ART-ACADEMICO-LICENCIATURA-070524": {
          Humanidades: [],
          Ciencias_sociales: ["Derecho", "Ciencias Políticas", "Administración Pública"],
          Economico_administrativas: []
        },
        "020-ART-ACADEMICO-LICENCIATURA-290424": {
          Humanidades: ["Diseño Gráfico"],
          Ciencias_sociales: [],
          Economico_administrativas: []
        },
    },
    filtrosSeleccionados: null, // Lista de filtros seleccionados
    };
},
methods: {
}
};
</script>

<style>
/* Estilos CSS si los tienes */
</style>