<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">Procedimientos y técnicas de<br class="md:block hidden">auditoría: Pruebas clave y ejemplos</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col md:tracking-normal tracking-tight w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-200">
            <img src="/images/procedimientos-tecnicas-auditoria-precision-universidad-humanitas.webp" alt="Los procedimientos y las técnicas de auditoría ayudan a establecer una reputación empresarial" class="mb-6">
          <div class="flex flex-col justify-center text-justify  text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/recursos" class=" text-hone">Recursos</router-link> > <router-link to="/blog/recursos/entrevistas-y-conferencias" class=" text-hone">Entrevistas y Conferencias</router-link></p>
            <div class="lg:flex text-left items-start justify-start">
                <ul class="lg:flex list-disc pl-5 italic text-left text-[18px] md:text-[20px] mb-6 font-helvetica"><li class="mr-16 text-htwo">22 de mayo de 2024</li>  <li>Carolina Reyes Martínez</li></ul>
            </div>
            <p class="md:mb-4 mb-6"><b>Extracto:</b> La competitividad en los diversos mercados hace necesaria la implementación del proceso de auditoría en las empresas y, más que buscar errores, lo relevante es implementar mejoras. Ricardo Sandoval Huerta, Coordinador de Auditoría de Corporativo Humanitas, nos comparte que una buena inversión de tiempo en optimizar los procedimientos trae los mejores resultados. Lee más.</p>
            <p class="md:mb-8 mb-10"><b>La auditoría</b> en las empresas se ha convertido en una herramienta que genera confianza en sus colaboradores, pero también con sus aliados, ya que mantiene la salud financiera y la actualización constante.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">¿Qué son los procedimientos de auditoría?</h2>
            <p class="md:mb-8 mb-10"><b>Una auditoría es el proceso de revisión y verificación del funcionamiento de una empresa.</b> Estos procedimientos de auditoría se pueden hacer de manera global o bien, de un área específica. Se lleva a cabo con una metodología para determinar eventualidades, incidencias, diagnósticos y áreas de oportunidad.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">¿Por qué son importantes los procedimientos y las técnicas de auditoría?</h3>
            <p class="md:mb-4 mb-6">Estas técnicas, procesos y métodos de auditoría aplicados de manera regular <b>ayudan a establecer una reputación financiera de una empresa y a reforzar esta confianza</b> con sus clientes, con el mercado al cual pertenece y a los futuros inversionistas.</p>
            <p class="md:mb-8 mb-10">Examinan, evalúan, controlan y dan seguimiento a la parte económica y operativa de una organización: cómo opera, cuáles son sus procesos y si éstos son efectivos. <b>Al tener este diagnóstico se pueden elaborar estrategias de mejora</b> en las áreas que así lo requieran.</p>
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
                <Contacto class="m-3"/>
            </div>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">¿Cuáles son las técnicas de auditoría?</h2>
            <p class="md:mb-4 mb-6">Todo proceso de auditoría estará definido por el auditor. Hay empresas que contratan a auditores externos ya que hay certificaciones y normas que así lo requieren, por ejemplo, <router-link to="/blog/ser-humanitas/responsabilidad-social/acciones-socialmente-responsables"  class="text-htwo">si la empresa tiene programas sociales,</router-link> entonces necesita certificarse.</p>
            <p class="md:mb-8 mb-10"><b>También existen las auditorías internas,</b> de esta manera la organización verifica que todo funcione correctamente. Los auditores pueden trabajar dentro de la misma compañía aunque hay casos en los que se contrata a personal externo para evitar conflicto de intereses.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">¿Por qué una empresa realizaría una auditoría?</h3>
            <p class="md:mb-8 mb-10">Para <b> asegurar la correcta implementación de mejoras</b> como puede ser una reestructuración o cambio de modelo de negocio, o si se genera alguna sinergia con nuevos proveedores o socios. La periodicidad varía: desde cada cuatro meses, seis meses o un año, esto depende de cada área o proceso a revisar.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">¿Qué beneficios tiene realizar una auditoría?</h2>
            <p class="md:mb-4 mb-6">Una auditoría <b>brinda información esencial para la organización,</b> ya que los resultados de ésta permitirán tomar decisiones, realizar correcciones a tiempo, identificar riesgos e implementar mejoras en los procesos.</p>
            <p class="md:mb-8 mb-10"><b>Las compañías pueden elaborar estrategias para una gestión adecuada</b> así como para el control financiero, lo que permite una planificación eficiente, mayor colaboración entre los equipos de trabajo, incremento en la productividad y la optimización de los procesos, entre otros.</p>
            <img src="/images/procedimientos-pruebas-ejemplos-transparencia-operativa.webp" alt="Los procedimientos y técnicas de auditoría son fundamentales en los procesos de organización" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">La evaluación del control interno y las pruebas de auditoría</h2>
            <p class="md:mb-8 mb-10">Esta valoración se realiza mediante exámenes o baterías que ponen a prueba los procesos de un área para identificar errores en la operación de la empresa, por ejemplo, si hay o no manejos ilícitos, o que no se malgasten los recursos. Este documento permitirá hacer el seguimiento de los temas o puntos que generen conflictos y que no permitan que la empresa cumpla con sus objetivos financieros.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">¿Cuáles son las pruebas de auditoría?</h2>
            <p class="md:mb-4 mb-6">Existen diversas pruebas que son utilizadas por los auditores para obtener evidencia suficiente y adecuada sobre la información financiera y operativa de una organización. <b>Su objetivo principal es evaluar la exactitud y validez con una metodología de planificación, ejecución y documentación,</b> asegurando que se cumplan las normativas y políticas establecidas. Éstas son algunas de ellas:</p>
            <ul class="list-disc pl-6 md:mb-8 mb-10">
                <li><b>Inspección física</b> de los bienes y recursos de la empresa.</li>
                <li><b>Revisión de cálculos.</b> Se verifica que estén correctas las obligaciones fiscales de la organización.</li>
                <li><b>Revisión de conciliaciones.</b> Se cruza la información fiscal o de los libros fiscales con el estado financiero real de la empresa.</li>
                <li><b>Pasivos no registrados.</b> Se identifican los gastos que no hayan sido incorporados de la compañía en el periodo de auditoría.</li>
                <li><b>Confirmación con terceras partes.</b> Se cruzan los datos con otras personas que hayan sido parte de los negocios de la organización.</li>
                <li><b>Pruebas de corte.</b> Comprobación de la integridad de los datos auditados, o sea se comprueban las facturas de venta y compra, y demás documentos.</li>
                <li><b>Pruebas de operatividad.</b> Consisten en verificar las prácticas de la empresa, si existen riesgos financieros. Para ello es importante tener un <router-link to="/blog/aprende/tendencias/tendencias-administracion-empresas-2024"  class="text-htwo">flujo de trabajo</router-link> adecuado.</li>
                <li><b>Calificación de desempeño</b> de los colaboradores para determinar qué áreas pueden mejorar.</li>
                <li><b>Delimitación de funciones.</b> Cada colaborador debe estar en el área que le corresponde para un óptimo desempeño.</li>
                <li><b>Pruebas de diseño.</b> Es la comprobación de que los exámenes para realizar la auditoría sean los correctos, que no tengan fallas en su implementación.</li>
            </ul>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Las técnicas de auditoría: las aliadas de los auditores</h3>
            <p class="md:mb-4 mb-6">En este punto, ya que vimos qué es una auditoría y las pruebas que se realizan, falta saber qué son las técnicas de auditoría y por qué son herramientas necesarias. <b>Son los métodos que analizan la información de una empresa</b> y comprueban que todo esté correcto en ella:</p>
            <ul class="list-disc pl-6 md:mb-8 mb-10">
                <li><b>Valoración general.</b> Es el análisis de las cuentas, operaciones, funciones de la empresa y su desempeño.</li>
                <li><b>Análisis específico</b> de un área o procedimiento.</li>
                <li><b>Inspección.</b> Seguimiento de los activos, inventarios y demás aspectos.</li>
                <li><b>Recopilación de información</b> por medio de encuestas y entrevistas al personal de la organización.</li>
                <li><b>Validación</b> para corroborar los resultados de la compañía.</li>
                <li><b>Evaluación.</b> Consiste en calificar los procesos y a la empresa en su vida diaria.</li>
                <li><b>Inspección.</b> Referida al control y el análisis de los inventarios y centrada en el seguimiento de los activos y demás aspectos.</li>
                <li><b>Certificación.</b> Es la fase final de la auditoría en la cual se cumplen los requisitos, normas y especificaciones técnicas para mejorar sus procesos operativos.</li>
            </ul>
            <img src="/images/procedimientos-auditoria-motivacion-trabajo-colaborativo.webp" alt="Los procedimientos de auditoría generan mejoras en los equipos de trabajo" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Los procedimientos de auditoría y la experiencia de Ricardo Sandoval Huerta</h2>
            <p class="md:mb-4 mb-6">La implementación de las pruebas de auditoría se basa en la planificación, ejecución y documentación. De acuerdo a la experiencia de <b>Ricardo Sandoval Huerta, Coordinador de Auditoría de Corporativo Humanitas</b> y con más de 18 años de experiencia, <b>una adecuada documentación es esencial para proporcionar evidencia de la conclusión de la auditoría.</b> Esto no sólo asegura la calidad del trabajo, también facilita futuras revisiones y exámenes.</p>
            <p class="md:mb-4 mb-6">Respecto a las técnicas de auditoría, Ricardo menciona que elegir alguna o algunas de éstas dependerá del área y objetivos a auditar para lograr resultados óptimos.</p>
            <p class="md:mb-4 mb-6">Algunos ejemplos de procedimientos de auditoría que Ricardo comparte son:</p>
            <ul class="list-decimal pl-6 md:mb-8 mb-10">
                <li><b>En la industria farmacéutica</b> estos procedimientos son muy rigurosos debido a las estrictas reglas, por lo cual es importante revisar cómo se elaboran los productos y control de calidad. Esto es relevante para asegurar el cumplimiento de las Buenas Prácticas de Manufactura (BPM).</li>
                <li><b>En la industria de los espectáculos.</b> Al haber trabajado en Operadora de Centros de Espectáculos, Ricardo explica que uno de los procedimientos es la auditoría forense que es la investigación de fraudes y auda a garantizar la transparencia financiera.</li>
                <li><b>En el sector retail,</b> los procedimientos van desde la verificación de inventarios, análisis de ventas y revisión de controles de efectivo. En su paso por Grupo Julio, Ricardo implementó procedimientos para auditar más de cien tiendas en el país para asegurar la integridad de registros y eficiencia operativa.</li>
            </ul>
            <p class="md:mb-8 mb-10">A decir del Coordinador de Auditoría de Corporativo Humanitas, <b>la disciplina y la imparcialidad son fundamentales,</b> uno para ser competitivo y dos, para emitir juicios objetivos. Destaca el respeto a los demás, la confianza, la honestidad, la responsabilidad, la justicia y la lealtad como pilares en su labor diaria que fomentan un ambiente de trabajo colaborativo, así como trabajar con grandes líderes. <br>Le hacemos la cordial invitación de revisar la siguiente entrevista en nuestra página de Trascendi: <a href="https://capitel.humanitas.edu.mx/entrevista-a-ricardo-sandoval-huerta-coordinador-de-auditoria-en-el-corporativo-humanitas-la-importancia-de-instaurar-procesos/" target="_blank" class="text-htwo">Entrevista a Ricardo Sandoval Huerta, “La importancia de instaurar procesos”.</a></p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">La motivación: el motor principal del crecimiento</h2>
            <p class="md:mb-8 mb-10"><b>Para Ricardo Sandoval Huerta, la motivación surge de los resultados obtenidos y del crecimiento de la institución,</b> así como de la labor de su equipo pues cree que: “la motivación nace en nosotros mismos, transmitirla es nuestra responsabilidad y si la recibimos, debemos agradecerla”, finaliza.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Conclusiones</h2>
            <p class="md:mb-4 mb-6">La auditoría y sus procedimientos requieren de conocimientos técnicos y habilidades analíticas para garantizar la precisión e integridad de los procesos de auditoría.</p>
            <p class="md:mb-8 mb-10">A través de la valiosa perspectiva de Ricardo Sandoval Huerta, Coordinador de Auditoría en el Corporativo Humanitas, vemos cómo se pueden implementar y ejecutar estos procedimientos, pues en el mundo empresarial es importante mantener la transparencia y eficiencia operativa.</p>
            <p class="text-htwo text-left mb-6">Explora más: <a href="https://humanitas.edu.mx/licenciatura-en-administracion" target="_blank" class="text-htwo">Licenciatura en Administración</a></p>
            <p class="text-htwo text-left pb-20">Te puede interesar: <router-link to="/blog/ser-humanitas/responsabilidad-social/acciones-socialmente-responsables"  class="text-htwo">¿Qué es una acción socialmente responsable?</router-link></p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="true" :linkAnt="'/aprende/cultura-general/consejos-sobre-uso-responsable-de-redes-sociales'" :linkSig="'/academico/maestria/derecho-corporativo-empresarial'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import VideoClipVue from '../../pagesComponents/videoClip.vue';
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import Contacto from '../../pagesComponents/Contacto.vue';
import EntrevistasVue from '../../pagesComponents/Entrevistas.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue'
import SigArt from '../../pagesComponents/SigArt.vue';



export default {
    components: { Comentarios, ConComentarios, Contacto, EntrevistasVue, LikesVue, ShareP, SigArt, VideoClipVue},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '0030-ART-RECURSOS-ENTREVISTAS-Y-CONFERENCIAS-220524',
            image: 'https://blog.humanitas.edu.mx/images/Emprende-aventura-liderazgo-visionario-albert-bosch-min.jpg',
            ruta: '/recursos/entrevistas-y-conferencias/procedimientos-tecnicas-de-auditoria-y-ejemplos-ricardo-sandoval-huerta',
            text: 'Descubre el enfoque único del consultor Helios Herrera sobre el desarrollo humano.',
            memo: ' (No sirve el link ✧ʕ◔౪◔ʔ✧)'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Ruta relativa de la imagen
        const relativeImageUrl = 'https://blog.humanitas.edu.mx/images/Emprende-aventura-liderazgo-visionario-albert-bosch-min.jpg';

        // Convertir la ruta relativa en una URL absoluta
        const imageUrl = relativeImageUrl;

        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx/recursos/entrevistas-y-conferencias/emprende-aventura-de-tu-vida-liderazgo-visionario-albert-bosch';

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = 'El liderazgo visionario de Albert Bosch en tu vida universitaria';

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = 'Descubre cómo emprender tu vida universitaria con el liderazgo visionario de Albert Bosch y vive tu vida como una aventura.';

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = imageUrl;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>