import art_0035 from "../../pages/Blog/SerHuma/Comunidad/0035-ART-SER-HUMANITAS-COMUNIDAD-300524.vue"

export default [
    {
        path: '/blog/ser-humanitas/comunidad/cuanto-cuesta-mensualidad-universidad-humanitas',
        name: '¿Cuánto cuesta una mensualidad en la Universidad Humanitas?',
        meta: {
          title: "¿Cuánto cuesta una mensualidad en la Universidad Humanitas?",
          description: "Descubre cuánto cuesta una mensualidad en la Universidad Humanitas y el nivel de la calidad educativa de reconocimiento internacional.",
          image: "https://blog.humanitas.edu.mx/images/meta/cuanto-cuesta-mensualidad-universidad-humanitas-instalaciones.webp",
          keywords: "¿Cuánto cuesta una mensualidad en la Universidad Humanitas?, Universidad Humanitas precios, modelo escolarizado, modelo ejecutivo, modelo virtual",
          metaTags:
          {
            "og:title": "¿Cuánto cuesta una mensualidad en la Universidad Humanitas?",
            "og:description": "Descubre cuánto cuesta una mensualidad en la Universidad Humanitas y el nivel de la calidad educativa de reconocimiento internacional.",
            "og:image": "https://blog.humanitas.edu.mx/images/meta/cuanto-cuesta-mensualidad-universidad-humanitas-instalaciones.webp",
            "og:keywords": "¿Cuánto cuesta una mensualidad en la Universidad Humanitas?, Universidad Humanitas precios, modelo escolarizado, modelo ejecutivo, modelo virtual"
          },
          requireAuth: false,
        },
        component: art_0035
    },
]