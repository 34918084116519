<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">Día de la Mujer 2024: invertir para tener<br class="md:block hidden"> igualdad de género</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-300">
          <img src="/images/Dia-internacional-de-la-mujer-financiar-acelera-igualdad-genero-2024.jpg" class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 text-left italic text-[18px] md:text-[20px]"><router-link to="/blog/aprende" class=" text-hone">Aprende</router-link> > <router-link to="/blog/aprende/cultura-general" class=" text-hone">Cultura General</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex list-disc pl-5 italic text-left text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">08 de marzo de 2024</li>  <li>Carolina Reyes Martínez/Redacción Humanitas</li></ul>
            </div>
            <p class=" md:mb-4 mb-6"><b>Extracto:</b> A través de una mirada histórica del Día Internacional de la Mujer, su lema en 2024 y algunas acciones para lograr la igualdad de género, te decimos cómo acelerar un futuro más equitativo.</p>
            <p class=" md:mb-8 mb-10">El 8 de marzo se celebra el Día Internacional de la Mujer en muchos países. ¿Sabes de dónde viene y por qué la Organización de las Naciones Unidas (ONU) eligió el lema Financiar los derechos de las mujeres: acelerar la igualdad para el 2024? En este artículo, te decimos.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">¿Por qué se celebra el 8 de marzo? Un poco de historia</h2>
            <p class=" md:mb-4 mb-6">La ONU comenzó a celebrar el Día Internacional de la Mujer en 1975, durante el Año Internacional de la Mujer. Lo estableció dos años después. Sin embargo, la lucha comenzó mucho antes.</p>
            <p class=" md:mb-4 mb-6">Un ejemplo: en el siglo IV A. C. las mujeres del sur de los Balcanes y de Grecia no podían participar en las actividades principales de la sociedad. Entonces, Aristófanes, un escritor de la época, escribió la comedia Lisístrata en la cual expone esta desigualdad de género.</p>
            <p class=" md:mb-4 mb-6">Esta comedia mostraba cómo las mujeres se fastidiaban porque los hombres siempre estaban en guerra y ellas tenían que tener hijos para que la guerra continuara. Por eso deciden hacer una huelga sexual encabezada por Lisístrata. A este movimiento se unen las mujeres de Esparta y de Atenas. Toman el poder de la ciudad y deciden parar la guerra y tener igualdad de derechos.</p>
            <p class=" md:mb-4 mb-6">Al final, concilian sus puntos de vista y hombres y mujeres firman la paz. Los varones depusieron las armas y prefirieron al amor. Esta obra representa el triunfo de la razón y el diálogo femenino sobre la actitud de guerra masculina.</p>
            <p class=" md:mb-8 mb-10">Casi 2, 500 años después, esta reflexión y las acciones para la igualdad continúan para lograr la equidad de género y la igualdad de oportunidades.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Las mujeres se movilizan: 8 de marzo de 1857</h3>
            <p class=" md:mb-4 mb-6">La Revolución Industrial (1762-1847) transformó a la economía y al trabajo, pero con un alto costo: la explotación laboral, que siguió hasta las primeras décadas del siglo XX.  Las mujeres y los niños trabajaban en condiciones deplorables y no había leyes que los protegieran.</p>
            <p class=" md:mb-4 mb-6">En Estados Unidos, en la ciudad de Nueva York, un 8 de marzo de 1857, las trabajadoras de la fábrica Cotton Textil Factory hicieron una huelga para pedir salarios justos y mejores condiciones de trabajo. La policía las reprimió y también se suscitó un incendio en la fábrica que dejó el saldo de 120 mujeres muertas.</p>
            <p class=" md:mb-8 mb-10">El funeral de las víctimas se convirtió en una marcha silenciosa que conmocionó a Nueva York, siendo un precedente en el movimiento obrero. Dos años después, las mujeres que sobrevivieron al incendio crearon un sindicato para exigir sus derechos.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Dinamarca y el Día Internacional de la Mujer</h3>
            <p class=" md:mb-4 mb-6">La lucha de las mujeres se extendió a varios países. En agosto de 1910, en Copenhague, se llevó a cabo la Segunda Conferencia Internacional de Mujeres Socialistas, organizada por Clara Zetkin, activista alemana dedicada a la política y a los derechos de la mujer.</p>
            <p class=" md:mb-8 mb-10">Se discutieron temas como las condiciones laborales, la educación, los seguros sociales para los niños y las mujeres, así como estar en contra de la guerra. También se propuso un Día Internacional de las Mujeres, coincidiendo en que fuera el 8 de marzo.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">El incendio que cambiaría todo: Triangle Shirtwaist</h3>
            <p class=" md:mb-4 mb-6">Mientras tanto, en Rusia, el Día Internacional de la Mujer se celebró el último domingo de febrero de 1913, tras varios mítines a favor de la paz, pues se avecinaba la Primera Guerra Mundial.</p>
            <p class=" md:mb-4 mb-6">Sin embargo, ésta estalla en 1914 y es hasta 1917, y como una reacción a los soldados rusos muertos, que las mujeres vuelven a manifestarse pidiendo “Pan y paz”, se declaran en huelga y los trabajadores metalúrgicos se unen a la protesta. El zar Nicolás II insta a la represión pero esta medida fracasa y él abdica.</p>
            <p class=" md:mb-4 mb-6">Con ello, las mujeres obtienen el derecho a votar, por lo que si bien la huelga inició el domingo 23 de febrero de 1917 del calendario juliano, en el gregoriano, la fecha corresponde al 8 de marzo, por lo que este día se reconoce oficialmente como el Día Internacional de la Mujer.</p>
            <p class=" md:mb-8 mb-10">Como puedes leer, la lucha por lograr la igualdad de género, unió a las mujeres de varios países y brindó las bases para tener un Día Mundial de la Mujer.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Retos y propuestas de la ONU para este 2024</h2>
            <p class=" md:mb-4 mb-6">Hoy, todavía hay mucho por hacer, por lo que el lema de este año es Financiar los derechos de las mujeres para acelerar la igualdad. De acuerdo a los datos, aún faltan 131 años para cerrar la brecha de género y en lo económico y social, así como erradicar las formas de discriminación.</p>
            <p class=" md:mb-4 mb-6">Garantizar los derechos de las mujeres y de las niñas es fundamental, ya que se prevé que un 8% de la población femenina en el año 2030 vivirá en pobreza extrema, si no se toman decisiones para evitarlo.</p>
            <p class=" md:mb-4 mb-6">La ONU propone una recaudación fiscal y tasas de interés con perspectiva de género, ya que en 2025 habrá recortes en el gasto público de hasta un 75%, y la población femenina es más vulnerable ante la inflación y el desempleo. Fomentar la economía real amortiguaría el impacto económico.</p>
            <p class=" md:mb-4 mb-6">También es importante crear alternativas a un sistema económico en el que hay desigualdad, pobreza y que está deteriorando a los ecosistemas.</p>
            <p class=" md:mb-8 mb-10">Es necesario apoyar opciones impulsadas por mujeres hacia una sociedad de cuidados y desarrollo sostenible. Sumar las voces de las mujeres indígenas que por generaciones han cuidado del entorno, compartir conocimiento, así como financiar y potenciar el desarrollo local, para una justicia económica y climática.</p>
            <img src="/images/Día-internacional-de-la-mujer-las-niñas-son-valiosas.jpg" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Apoyar a esta causa es posible: tu voz importa</h2>
            <p class=" md:mb-8 mb-10">La ONU Mujeres dice que las organizaciones feministas luchan contra la desigualdad con pocos recursos. Es necesario financiar a estas organizaciones que ayudan a las mujeres más vulnerables.</p>
            <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">¿Qué podrías hacer desde tu trinchera?</h3>
            <ul class="list-decimal pl-6 md:mb-8 mb-10">
                <li><b>Enseñarles a las niñas lo valiosas que son, </b> ya que desde muy pequeñas se les han enseñado estereotipos de género, pero afortunadamente éstos pueden desaprenderse. Recuérdale a las niñas que forman parte de tu vida que son capaces, que son fuertes y merecen respeto; elogia su inteligencia, su creatividad. Anímalas a alzar la voz.</li>
                <li><b>Informarte </b>sobre cómo erradicar la violencia hacia las mujeres, así como la desigualdad en el trabajo y en la formación profesional. No cerrar los ojos ante estos temas.</li>
                <li><b>Pon tu granito de arena en el cuidado del medio ambiente: </b>recicla, usa productos ecológicos, no gastes agua. El cambio climático y humanitario afecta en mayor parte a las mujeres y a las niñas, pues al acabarse los recursos, mujeres y hombres tienen que emigrar, pero ellas quedan más expuestas a la violencia y a la desigualdad. </li>
                <li>Recuerda que la información es poder: <b>amplía tu conocimiento leyendo a autoras, </b>viendo películas dirigidas por mujeres, podcasts, vlogs; comparte contenido informativo en tus redes sociales. Puedes encontrarlo en páginas de la ONU o de organizaciones que hablen sobre la igualdad de género.</li>
            </ul>
            <p class="md:mb-8 mb-10">En este 2024, "invertir en las mujeres: acelerar el progreso”, es imperativo para un futuro mejor y tu voz es importante para compartir más sobre este tema.</p>
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
              <Contacto class="m-3"/>
            </div>
            
            <p class="text-htwo text-left md:mb-4 mb-6">Explora más: <a href="https://humanitas.edu.mx/" target="_blank" class="text-htwo">Universidad Humanitas</a></p>
            <p class="text-htwo text-left mb-20">Te puede interesar: <router-link to="/blog/aprende/tips/mejores-tecnicas-estudio-para-universidad"  class="text-htwo">Las mejores técnicas de estudio para triunfar en la universidad</router-link></p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="true" :linkAnt="'/academico/licenciatura/mejores-universidades-derecho-mexico-guia'" :linkSig="'/ser-humanitas/responsabilidad-social/por-que-es-importante-la-responsabilidad-social-universitaria'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import Contacto from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue'
import SigArt from '../../pagesComponents/SigArt.vue';

export default {
    components: { Comentarios, ConComentarios, Contacto, LikesVue, ShareP, SigArt},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '11-ART-APRENDE-CULTURA-GENERAL-080324',
            ruta: '/aprende/cultura-general/dia-de-la-mujer-financiar-derechos-acelerar-igualdad-de-genero-2024',
            title: 'Día de la Mujer 2024: invertir para tener igualdad de género',
            image: 'https://blog.humanitas.edu.mx/images/Día-internacional-de-la-mujer-financiar-acelera-igualdad-genero-2024.jpg',
            text: 'Día Internacional de la Mujer: por qué es importante y cómo en 2024, financiar los derechos de las mujeres puede acelerar la igualdad de género.'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx'+this.ruta;

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>