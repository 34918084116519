<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">¿Por qué es importante la Responsabilidad<br class="md:block hidden"> Social Universitaria? Entérate</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-300">
          <img src="/images/Por-que-es-importante-responsabilidad-social-universitaria-para-ti.jpg" class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/ser-humanitas" class=" text-hone">Ser Humanitas</router-link> > <router-link to="/blog/ser-humanitas/responsabilidad-social" class=" text-hone">Responsabilidad Social</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex list-disc pl-5 italic text-left text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">13 de marzo de 2024</li>  <li>Carolina Reyes Martínez/Redacción Humanitas</li></ul>
            </div>
            <p class=" md:mb-4 mb-6"><b>Extracto:</b> La universidad te equipa para ser más que un profesional: te forma para ser un agente de cambio, impulsando el desarrollo de tu país mediante la Responsabilidad Social Universitaria.</p>
            <p class=" md:mb-8 mb-10">La universidad es importante para tu formación en la carrera que elegiste. Es donde haces amigos, networking pero también, aprendes sobre valores éticos y valores sociales que son importantes dentro y fuera de la universidad. Te decimos por qué. </p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">¿Qué es la Responsabilidad Social Universitaria?</h2>
            <p class=" md:mb-4 mb-6">La universidad no sólo te forma profesionalmente, también te enseña a ser una persona responsable y a contribuir al desarrollo económico y social de tu país.</p>
            <p class=" md:mb-4 mb-6">Para ello, se ha retomado el concepto de la responsabilidad social corporativa, que promueve la integración del respeto por las personas, los valores éticos, la comunidad y el medio ambiente en la gestión de una empresa, independientemente de los productos o servicios que ésta ofrece, del sector al que pertenece o de su tamaño o nacionalidad.</p>
            <p class=" md:mb-4 mb-6">Llevando este concepto empresarial a la educación superior, <b>la Responsabilidad Social Universitaria (RSU) es un compromiso institucional con la comunidad.</b> Es un enfoque que integra en la toma de decisiones, el desarrollo sostenible, la justicia social y el bienestar económico en todas las actividades de la institución.</p>
            <p class=" md:mb-4 mb-6">Busca crear consciencia crítica, promover la enseñanza e investigación, y difundir descubrimientos científicos y técnicos. Todo esto va más allá del voluntariado y contribuye a la transformación del entorno social.</p>
            <p class=" md:mb-8 mb-10">Cuando salgas de la universidad, seas consciente de los retos a nivel global pero tengas la preparación para contribuir como profesional a dar soluciones sostenibles y con principios éticos.</p>
            <p class=" md:mb-4 mb-6">En nuestro país, hay <b>ocho principios</b> que tienen que ver con la RSU</p>
            <ul class="list-decimal pl-6 md:mb-8 mb-10">
                <li>Congruencia de la universidad con su objetivo académico.</li>
                <li>Consciencia sobre las necesidades y los problemas sociales del país.</li>
                <li>La equidad para el bienestar social.</li>
                <li>El humanismo y comunidades incluyentes.</li>
                <li>El compromiso para construir una sociedad mejor.</li>
                <li>La autonomía responsable.</li>
                <li>Presentar cuentas claras así como estructuras y operación ejemplar.</li>
                <li>La sustentabilidad en el entorno académico.</li>
            </ul>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Así puedes participar en la Responsabilidad Social Universitaria</h2>
            <p class=" md:mb-8 mb-10">Si te interesa la RSU, el primer paso <a href="https://humanitas.edu.mx/responsabilidad" targe="_blank" class="text-htwo">es informarte sobre las iniciativas existentes en tu universidad.</a> Participar en clubes ambientales, grupos de trabajo social, o proyectos de investigación con un enfoque en la sostenibilidad son excelentes para integrarte. Además, siempre hay espacio para nuevas ideas: proponer tus proyectos puede ser una forma poderosa de contribuir en la vida cotidiana de la comunidad.</p>
            <img src="/images/Responsabilidad-social-universitaria-exito-profesional.jpg" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">¿Cuáles son los beneficios de la Responsabilidad Social Universitaria en los estudiantes?</h2>
            <p class=" md:mb-4 mb-6">Participar en proyectos sociales y comunitarios te permite:</p>
            <ul class="list-disc pl-6 md:mb-8 mb-10">
                <li><b>Desarrollar empatía y consciencia social:</b> Al enfrentarte a realidades distintas a la tuya, desarrollas una profunda comprensión de los desafíos que enfrentan las personas y comunidades.</li>
                <li><b>Adquirir habilidades blandas cruciales:</b> La colaboración en los proyectos te brinda comunicación efectiva, trabajo en equipo, resolución de conflictos y el liderazgo. Estos skills son muy valorados en el entorno profesional y te preparan para una amplia gama de roles y responsabilidades.</li>
                <li><b>Resolver problemas complejos: </b>La participación en iniciativas sociales te desafía a pensar de manera crítica y creativa, buscando soluciones innovadoras. Esta habilidad es esencial para el éxito profesional.</li>
                <li>Destacarás si demuestras compromiso con valores éticos <b>amplía tu conocimiento leyendo a autoras, </b>fuera de la universidad. Esto te hará resaltar entre quienes buscan experiencia en responsabilidad corporativa y sostenibilidad. Tener un perfil único es importante en el mundo laboral actual.</li>
            </ul>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Beneficios de la RSU para la comunidad y el medio ambiente</h2>
            <p class="md:mb-4 mb-6">Las iniciativas de Responsabilidad Social Universitaria tienen un impacto significativo en las comunidades locales y el medio ambiente, un ejemplo: implementar un programa de reciclaje dirigido por los estudiantes. No sólo se reduce la basura en el campus, también la comunidad universitaria aprende la importancia del cuidado del entorno.</p>
            <p class="md:mb-4 mb-6">También si se promueve la gestión social del conocimiento de manera responsable y accesible, más personas tendrán la oportunidad de estudiar, ya que la calidad de vida depende cada día más, de nuevas habilidades socialmente útiles.</p>
            <p class="md:mb-4 mb-6">Por ello, compartir los conocimientos con la comunidad, ya sea con capacitaciones gratuitas, talleres, pláticas y más dinámicas, brindará motivación a las personas para aprender y lograr cambios sostenibles en sus comunidades.</p>
            <p class="md:mb-8 mb-10">De esta manera, tu participación como universitario en el entorno, aportará soluciones, como por ejemplo, proyectos medioambientales, económicos, de derechos humanos, y esto promueve el aprendizaje mutuo.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Conclusión</h2>
            <p class="md:mb-8 mb-10">Integrarte a los programas de responsabilidad social durante tu tiempo en la universidad, te brindará habilidades, experiencias y conexiones que trascienden el ámbito académico. No solamente tendrás éxito en tu carrera, también podrás asumir los retos del mundo y contribuir significativamente.</p>
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
              <Contacto class="m-3"/>
            </div>
            
            <p class="text-htwo text-left md:mb-4 mb-6">Explora más: <a href="https://humanitas.edu.mx/" target="_blank" class="text-htwo">Universidad Humanitas</a></p>
            <p class="text-htwo text-left mb-20">Te puede interesar: <router-link to="/blog/aprende/tendencias/tendencias-actuales-del-derecho-2024"  class="text-htwo">Tendencias actuales del Derecho en 2024</router-link></p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="true" :linkAnt="'/aprende/cultura-general/dia-de-la-mujer-financiar-derechos-acelerar-igualdad-de-genero-2024'" :linkSig="'/aprende/tips/estoicismo-para-estudiantes-universitarios-y-beneficios'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import Contacto from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue'
import SigArt from '../../pagesComponents/SigArt.vue';

export default {
    components: { Comentarios, ConComentarios, Contacto, LikesVue, ShareP, SigArt},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '12-ART-SERHUMANITAS-RESPSOCIAL-130324',
            ruta: '/ser-humanitas/responsabilidad-social/por-que-es-importante-la-responsabilidad-social-universitaria',
            title: '¿Por qué es importante la Responsabilidad Social Universitaria? Entérate',
            image: 'https://blog.humanitas.edu.mx/images/Por-que-es-importante-responsabilidad-social-universitaria-para-ti.jpg',
            text: 'Aprende cómo la Responsabilidad Social Universitaria impulsa el cambio social, la sostenibilidad y también el desarrollo de tus habilidades profesionales.'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx'+this.ruta;

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>