<template class="bg-white">
  <div class="flex relative flex-col items-center justify-center lg:mt-[83px] md:mt-[120px] mt-[83px] w-full font-sans text-black" >
    <!-- <img src="../pages/images/banner25.jpg" alt="portada" class="md:block hidden"> -->
    <section class="parallax-section justify-center w-full h-fit md:block hidden parallax-section" style="background-image: url('/images/banner.png'); background-attachment: fixed; background-position: center; background-repeat: no-repeat; background-size: cover;">
      <div class="flex items-center justify-center w-full">
        <img src="/images/logo-aniv.png" alt="Logo" class="py-6">
      </div>
    </section>
    <section class=" justify-center w-full max-w-[768px] h-fit md:hidden" style="background-image: url('/images/columnasMB.png'); position: relative; background-attachment: fixed; background-position: center center; background-repeat: no-repeat; background-size: cover; overflow: hidden; transition: transform 0.3s ease; width: 100%; height: 100%;">
      <div class="flex items-center justify-center w-full">
        <img src="/images/45MB.png" alt="Logo" class="py-12">
      </div>
    </section>
    <!-- <img src="/images/home-movil.png" alt="portada-movil" class="md:hidden"> -->
    <div class="flex flex-col lg:bottom-16 bottom-4 md:absolute px-2 md:bg-transparent bg-[#881912] py-8 text-left text-white max-w-6xl lg:max-w-[1200px] w-full justify-start">
      <p class="font-bold md:px-0 px-4 lg:text-[18] md:text-[16px] lg:mb-6 mb-2 font-helvetica">Bienvenidos al blog de Universidad Humanitas.</p>
      <p class=" lg:text-[18px] md:text-[16px] md:px-0 px-4 font-helvetica">Únete a esta aventura de <b>aprendizaje</b>, información,<br> crecimiento personal e inspiración. <b>¡Léenos y participa!</b></p>
    </div>
    <!-- Menu desplegale responsive -->
    <div class="w-full md:hidden z-40 bg-white">
      <div class="flex px-8 py-6 w-full justify-between items-center font-serif space-x-8">
        <div class="relative" @mouseover="openDropdown" @mouseleave="startCloseTimer">
          <button class="inline-flex text-[18px] items-center rounded-none  px-2 lg:px-4 py-2 border border-transparent text-hone bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white " style="text-decoration: underline;">
            Últimos artículos
            <svg :class="{ 'rotate-180': isOpen }" class="-mr-1 ml-2 h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M10 12a1 1 0 01-.707-.293l-4-4a1 1 0 111.414-1.414L10 9.586l3.293-3.293a1 1 0 111.414 1.414l-4 4A1 1 0 0110 12z" clip-rule="evenodd" />
            </svg>
          </button>
          <div v-if="isOpen" class="absolute left-0 mt-0 w-54 italic rounded-none shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="options-menu" @mouseover="cancelCloseTimer" @mouseleave="closeDropdown">
            <router-link v-for="(ultimo,index) in ultimos" :key=index :to="'/blog'+ultimo.link" class="dropdown-item block px-2 text-left py-2 text-sm text-gray-700 hover:bg-[#881912] hover:text-gray-900" role="menuitem">
              {{ ultimo.name }}
            </router-link>
          </div>
        </div>
        <div class="relative" @mouseover="openDropdown2" @mouseleave="startCloseTimer2">
          <button class="inline-flex  text-[18px] items-center rounded-none  px-2 lg:px-4 py-2 border border-transparent text-hone bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white " style="text-decoration: underline;">
            Histórico
            <svg :class="{ 'rotate-180': isOpen2 }" class="-mr-1 ml-2 h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M10 12a1 1 0 01-.707-.293l-4-4a1 1 0 111.414-1.414L10 9.586l3.293-3.293a1 1 0 111.414 1.414l-4 4A1 1 0 0110 12z" clip-rule="evenodd" />
            </svg>
          </button>
          <div v-if="isOpen2" class="absolute left-0 mt-0 w-32 italic rounded-none shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="options-menu" @mouseover="cancelCloseTimer2" @mouseleave="closeDropdown2">
            <router-link v-for="(historicos,index) in historico" :key=index :to="historicos.link" class="dropdown-item block px-2 text-left py-2 text-sm text-gray-700 hover:bg-[#881912] hover:text-gray-900" role="menuitem">
              {{ historicos.name }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-col md:px-4 lg:px-0 justify-center bg-gray-100 items-center w-full">
    <div class="flex justify-between items-start mb-8 max-w-6xl lg:max-w-[1200px] w-full">
      <IndCatalogo class="md:w-[75%] px-6 md:px-0 lg:mt-8 mt-8"/>
      <!-- <Catalogo/> -->
      <div class="lg:ml-12 md:ml-5 md:w-[25%] h-full hidden md:block">
        <div class="flex flex-col" style="font-family: 'Helvetica Neue Regular', sans-serif;">
          <div class="flex flex-col z-40 lg:mt-8 mt-8 w-full justify-start bg-white border h-[30%] border-[#797979]">
            <div class="my-4 mx-6">
              <p class="lg:text-[20px] text-[18px] font-serif text-left text-hone">&Uacute;ltimos Art&iacute;culos</p>
              <hr class="border-hone mb-4 lg:w-[160px] w-[140px]">
              <ul class="text-[#797979] lg:pl-5 pl-2 lg:text-[14px] text-[14px] text-left italic list-disc">
                <li v-for="(ultimo,index) in ultimos" :key=index class="leading-none mb-2">
                  <router-link :to="'/blog'+ultimo.link" class="text-[#797979] leading-tight">{{ ultimo.name }}</router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class=" bg-gray-100 z-40">
            <br>
          </div>
          <div class="flex flex-col z-40 border w-full justify-start bg-white h-[30%] border-[#797979]">
            <div class="my-4 mx-6">
              <p class="lg:text-[20px] text-[18px] font-serif text-left text-hone">Hist&oacute;rico</p>
              <hr class="border-hone lg:w-[100px]  w-[60%] mb-4">
              <ul class="text-[#797979] lg:pl-5 pl-2 lg:text-[14px] text-[14px] text-left italic list-disc">
                <li v-for="(historicos,index) in historico" :key=index>
                  <router-link :to="'/blog'+historicos.link" class="text-[#797979]">{{ historicos.name }}</router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class=" bg-gray-100 z-40">
            <br>
          </div>
          <div class="flex flex-col z-30 items-center justify-center bg-[#999999]">
            <div class="flex flex-col w-full my-4 justify-center">
              <p class="text-white font-serif px-5 lg:text-[20px] leading-tight mb-5">Te asesoramos para tomar la mejor decisión educativa</p>
              <div class="flex flex-col login-box form-container w-full mx-12 relative">
                <p class="text-white ml-6 text-[14px] text-left mb-4">Campos requeridos*</p>
                <form autocomplete="off">
                  <div class="mx-6">
                    <div class="user-box">
                      <input v-model="nombre" type="text" id="name" name="nombre" required maxlength="30" class="">
                      <label for="nombre" class=" italic">Nombre*</label>
                    </div>
                    <div class="user-box">
                      <input v-model="apellidos" type="text" id="apelidos" name="apellidos" required maxlength="55" class="">
                      <label for="apellidos" class=" italic">Apellidos*</label>
                    </div>
                    <div class="user-box">
                      <input v-model="email" type="text" id="email" name="email" @change="handleEmailChange" required class="">
                      <label for="email" class=" italic">E-mail*</label>
                    </div>
                    <div class="user-box">
                      <div class="flex">
                        <select v-model="selectedCountry" class="mr-2 rounded-none" style="width: 35%;padding: 10px 10px;font-size: 14px;color: white;border: none;border-bottom: 1px solid white;outline: none;background: transparent;">
                            <option v-for="country in ladas" :value="country.lada" :key="country.codigo" class="rounded-none" :style="{ backgroundImage: `url(${getFlagUrl(country.codigo)})` }">
                                {{ country.lada }} 
                            </option>
                        </select>
                        <div>

                        </div>
                        <div class="w-2/3">
                            <input v-model="telefono" id="telefono" type="tel" name="telefono" required maxlength="10" class="">
                            <label for="telefono" class=" italic" style="top: -25px;left: 0;color: white;font-size: 12px">N&uacute;mero de teléfono*</label>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="user-box">
                            <select name="campus" id="campus" v-model="campus"  required class="">
                                <option class="text-[#797979]" value="">Selecciona una opci&oacute;n</option>
                                <option class="text-[#797979]" value="cancun">Canc&uacute;n</option>
                                <option class="text-[#797979]" value="del-valle">Colonia Del Valle CDMX</option>
                                <option class="text-[#797979]" value="guadalajara">Guadalajara</option>
                                <option class="text-[#797979]" value="los-reyes">Los Reyes la Paz</option>
                                <option class="text-[#797979]" value="merida">M&eacute;rida</option>
                                <option class="text-[#797979]" value="presa-madin">Presa Mad&iacute;n</option>
                                <option class="text-[#797979]" value="queretaro">Quer&eacute;taro</option>
                                <option class="text-[#797979]" value="santa-fe">Santa Fe CDMX</option>
                                <option class="text-[#797979]" value="tijuana">Tijuana</option>
                                <option class="text-[#797979]" value="virtual-mex">Virtual</option>
                            </select>
                            <label class="italic" style="top: -25px;left: 0;color: white;font-size: 12px">Campus de Inter&eacute;s</label>
                    </div> -->
                    <div class="flex">
                      <div class="flex items-center mb-6">
                          <input type="checkbox" v-model="conditions" class=" cursor-pointer accent-[#CAAB55] rounded-none w-4 h-4 bg-[#881912] border-gray-300 focus:ring-[#520400] focus:ring-1 mr-1"> 
                          <label class="text-white text-[12px]">
                          He le&iacute;do y acepto el <a href="https://humanitas.edu.mx/aviso-de-privacidad" target="_blank" class="text-white hover:text-htwo">Aviso de privacidad</a>.
                          </label>
                      </div>
                  </div>
                    <div class="flex items-center justify-center">
                      <button @click.prevent="submitForm" class="rounded-none transition duration-300 ease-in-out hover:bg-[#CAAB55] hover:text-white flex items-center justify-center text-[#999999] bg-[white] w-[120px] h-[35px]">Enviar</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Destacados class="z-40 bg-white"/>
  
</template>

<script lang="js">
import IndCatalogo from './pagesComponents/IndCatalogo.vue';
import Destacados from './pagesComponents/Destacados.vue';
import Swal from 'sweetalert2';
import axios from 'axios';
import { SolicitudBlog } from '@/main'

export default {
  components: {
    IndCatalogo,
    Destacados
  },
    data() {
        return {
            toast : Swal.mixin({
                      toast: true,
                      position: 'top-end',
                      width: 500,
                      padding: '1em',
                      showConfirmButton: false,
                      timerProgressBar: true,
                      timer: 4000,
                      icon: 'error',
                      iconColor: '#881912'
                  }),
            selectedCountry: '+52', // Aquí se almacenará el país seleccionado
            nombre: '',
            email: '',
            apellidos: '',
            telefono: '',
            //campus: '',
            tokenizer: '',
            conditions: false,
            isOpen: false,
            isOpen2: false,
            closeTimer: null,
            closeTimer2: null,
            form: 'Home',
            campus: 'Virtual',
            ladas: [
                { codigo: "mx", lada: "+52", nombre: "México" },
                { codigo: "us", lada: "+1", nombre: "Estados Unidos" },
                { codigo: "ca", lada: "+1", nombre: "Canadá" },
                { codigo: "gb", lada: "+44", nombre: "Reino Unido" },
                { codigo: "es", lada: "+34", nombre: "España" },
                { codigo: "ar", lada: "+54", nombre: "Argentina" },
                { codigo: "br", lada: "+55", nombre: "Brasil" },
                { codigo: "cl", lada: "+56", nombre: "Chile" },
                { codigo: "co", lada: "+57", nombre: "Colombia" },
                { codigo: "pe", lada: "+51", nombre: "Perú" },
                { codigo: "ve", lada: "+58", nombre: "Venezuela" },
                { codigo: "ec", lada: "+593", nombre: "Ecuador" },
                { codigo: "bo", lada: "+591", nombre: "Bolivia" },
                { codigo: "py", lada: "+595", nombre: "Paraguay" },
                { codigo: "uy", lada: "+598", nombre: "Uruguay" },
                { codigo: "pa", lada: "+507", nombre: "Panamá" },
                { codigo: "cr", lada: "+506", nombre: "Costa Rica" },
                // Agrega más países según sea necesario
            ],
            ultimos: [
              {
                "name": "¿Qué hacer si no te quedaste en el IPN?, aquí la respuesta",
                "link": "/aprende/tips/que-hacer-si-no-te-quedaste-en-el-ipn"
              },
              {
                "name": "¿Ansiedad es lo mismo que angustia?: Aquí la respuesta",
                "link": "/aprende/tips/ansiedad-es-lo-mismo-que-angustia"
              },
              {
                "name": "Cómo identificar y superar relaciones tóxicas: Lo que debes de saber",
                "link": "/recursos/casos-reales/como-identificar-y-superar-relaciones-toxicas"
              },
              {
                "name": "Día Mundial de las Redes Sociales: Celebrando el 30 de junio",
                "link": "/aprende/cultura-general/dia-mundial-redes-sociales-30-junio-2024"
              },
              {
                "name": "Educación continua: ¿Qué es y porqué es importante?",
                "link": "/academico/educacion-continua/educacion-continua-que-es"
              },
            ],
            historico: [
              {
                "name": "Agosto 2024",
                "link": "/historico/2024/agosto"
              },
              {
                "name": "Julio 2024",
                "link": "/historico/2024/julio"
              },
              {
                "name": "Junio 2024",
                "link": "/historico/2024/junio"
              },
              {
                "name": "Mayo 2024",
                "link": "/historico/2024/mayo"
              },
              {
                "name": "Abril 2024",
                "link": "/historico/2024/abril"
              },
              {
                "name": "Marzo 2024",
                "link": "/historico/2024/marzo"
              },
              {
                "name": "Febrero 2024",
                "link": "/historico/2024/febrero"
              },
              {
                "name": "Enero 2024",
                "link": "/historico/2024/enero"
              },
            ]
        };
    },
    methods: {
      currentUrl() {
        return window.location.href;
      },

      openDropdown() {
        // @ts-ignore
        clearTimeout(this.closeTimer); // Limpiar el temporizador si está activo
        this.isOpen = true;
      },
      closeDropdown() {
        this.isOpen = false;
      },
      startCloseTimer() {
        // @ts-ignore
        this.closeTimer = setTimeout(() => {
          this.closeDropdown();
        }, 120); // Espera 200ms antes de cerrar el menú
      },
      openDropdown2() {
        // @ts-ignore
        clearTimeout(this.closeTimer); // Limpiar el temporizador si está activo
        this.isOpen2 = true;
      },
      closeDropdown2() {
        this.isOpen2 = false;
      },
      startCloseTimer2() {
        // @ts-ignore
        this.closeTimer2 = setTimeout(() => {
          this.closeDropdown2();
        }, 120); // Espera 200ms antes de cerrar el menú
      },
      cancelCloseTimer() {
        // @ts-ignore
        clearTimeout(this.closeTimer); // Limpiar el temporizador si el usuario vuelve al menú antes de que se cierre
      },
      cancelCloseTimer2() {
        // @ts-ignore
        clearTimeout(this.closeTimer2); // Limpiar el temporizador si el usuario vuelve al menú antes de que se cierre
      },

      // @ts-ignore
      getFlagUrl(countryCode) {
        return `https://flagcdn.com/64x48/${countryCode.toLowerCase()}.png`;
      },

      // @ts-ignore
      mostrarErrorSweetAlert(mensaje) {
        this.toast.fire({
        icon: 'error',
        title: 'Datos faltantes',
        text: mensaje,
        });
      },

      validarDatos(){
        if(!/^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s]{1,30}$/.test(this.nombre)){
            this.mostrarErrorSweetAlert('Ingrese un nombre válido.');
        } else {
          if(!/^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s]{1,60}$/.test(this.apellidos)){
            this.mostrarErrorSweetAlert('Por favor ingresa un(os) apellido(s) válido(s).');
          } else {
            if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email)){
                this.mostrarErrorSweetAlert('Ingrese un correo electrónico válido.')
            }else{
                if(!/^\d{10}$/.test(this.telefono)){
                    this.mostrarErrorSweetAlert('Ingrese un número de teléfono válido (10 dígitos).');
                } else {
                    return true
                }
            }
          }
        }
        return false;
      },

      validarPrivacidad(){
        if(!this.conditions){
          this.mostrarErrorSweetAlert('Debes estar de acuerdo con el Aviso de privacidad.');
        } else {
          return true
        }
        return false
      },

      handleEmailChange() {
        if (!this.tokenizer) {
            this.executeRecaptcha();
        }
      },

      executeRecaptcha() {
        //@ts-ignore
        grecaptcha.enterprise.execute('6Ldd0x0oAAAAAAaPVjMnVirgyfT6qahpksCqN0UX', { action: 'submit_lead' })
        // @ts-ignore
        .then((token) => {
        this.tokenizer = token;
        })
        // @ts-ignore
        .catch((error) => {
        console.error('Error al ejecutar reCAPTCHA:', error);
        });
      },



      submitForm() {
         // Validar el formulario antes de enviar los datos
         if (this.validarDatos()&&this.validarPrivacidad()) {
            // Crear un objeto FormData con los datos del formulario
            const formData = new FormData();
            formData.append('nombre', this.nombre );
            formData.append('apellidos', this.apellidos);
            formData.append('email', this.email);
            formData.append('telefono', this.selectedCountry + this.telefono);
            formData.append('g-recaptcha-response',this.tokenizer)
            formData.append('campus',this.campus)
            formData.append('form', this.form)
            formData.append('url', this.currentUrl())
            /* Realizar la solicitud POST
            let peticion = {
                method: 'POST',
                body: formData,
            };*/
            Swal.fire({
                title: 'ENVIANDO...',
                text: 'ESTAMOS ENVIANDO SUS DATOS. NO CIERRE LA VENTANA',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
                didOpen: () => {
                    // @ts-ignore
                    Swal.showLoading();
                    axios.post(SolicitudBlog, formData, {
                    // axios.post(Solicitud, formData, {
                        headers: {'Content-Type': 'multipart/form-data',},
                        responseType: 'json', // Asegúrate de incluir esta línea
                    })
                        // @ts-ignore
                        .then((response) => {
                                // if (response.data.error === false) {
                                    //@ts-ignore
                                    console.log(response);
                                    // if (dataLayer) {
                                    //     //@ts-ignore
                                    //     dataLayer.push({
                                    //     event: 'generate_lead',
                                    //     email: this.email,
                                    //     career: this.selectedOffer,
                                    //     });
                                    // }
                                    Swal.fire({
                                        title: '¡ENVIADO!',
                                        text: 'NUESTROS EJECUTIVOS SE PONDRÁN EN CONTACTO CONTIGO LO ANTES POSIBLE.',
                                        icon: 'success',
                                        iconColor: '#CAAB55',
                                        showConfirmButton: false,
                                        timer: 4000
                                    });
                                    this.nombre = '';
                                    this.apellidos = '';
                                    this.email = '';
                                    this.telefono = '';
                                    this.conditions = false;
                                // } else {
                                //     Swal.fire({
                                //         text: `${response.data.descripcion}`,
                                //         icon: "error",
                                //         iconColor: '#881912',
                                //         showConfirmButton: false,
                                //         timer: 4000
                                //     });
                                //     this.tokenizer='';
                                // }
                        // @ts-ignore
                        }).catch( (error) => console.log('error', error) );
                }
            });
          }
      },
    }
}


</script>

<style scoped>
    .parallax-section {
      position: relative;
      overflow: hidden;
      transition: transform 0.3s ease;
      background-image: url('/images/columnasMB.png');
    }

    .dropdown-item {
      font-family: 'Helvetica Neue Italic', sans-serif;
      font-size: 14px;
    }

    .dropdown-item:hover {
      background-color: #881912;
      color: white;
    }

    .login-box {
    max-width: 1000px;
    margin: 0 ;
    padding: 0px;
    box-sizing: border-box;
    border: black;
    border-radius: 1px;
    background: transparent;
    z-index: 1;
    
    
  }

  .login-box h2 {
    margin: 0 0 0px;
    padding: 0;
    color: transparent;
    text-align: center;
  }

  .login-box .user-box {
    position: relative;
    z-index: 1;
  }

  .login-box .user-box i {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 12px;
    color: white;
  }

  .form-container {
    position: relative;
    z-index: 1;
  }

  .login-box .user-box input,
  .login-box .user-box select,
  .login-box .user-box textarea {
   width: 100%;
   padding: 10px 10px; /* Ajusta según tu preferencia */
   font-size: 12px;
   color: white;
   margin-bottom: 24px;
   border: none;
   border-bottom: 1px solid white;
   outline: none;
   background: transparent;
}

.login-box .user-box select option {
  color: black; /* Color del texto de las opciones del select cuando se despliegan */
}



  .login-box .user-box label {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    padding: 10px 0;
    font-size: 12px;
    color: white;
    pointer-events: none;
    transition: .5s;
  }

  

  .login-box .user-box input:focus ~ label,
  .login-box .user-box input:valid ~ label,
  .login-box .user-box select:focus ~ label,
  .login-box .user-box textarea:focus ~ label {
    top: -25px;
    left: 0;
    color: white;
    font-size: 12px;
  }



  .login-box .user-box input.error-border,
  .login-box .user-box select.error-border,
  .login-box .user-box textarea.error-border {
    border-bottom: 1px solid #FF0000 !important;
  }

  .login-box .user-box label.error{
    color: #FF0000;
    font-size: 12px;
  }
  
</style>