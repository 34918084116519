<template>
    <div>
        <CataBueno :titulo="'Agosto 2024'" :subtitulo="''" :filtros="filtros" :articles="articles"/>
    </div>
</template>

<script>
import CataBueno from '../../pagesComponents/CataBueno.vue';

export default {
components: { CataBueno },
data() {
    return {
    articles: [
        {
            "id": "0058-ART-APRENDE-TIPS-010824",
            "titulo": "¿Qué hacer si no te quedaste en el IPN?, aquí la respuesta",
            "fecha": "01 de agosto de 2024",
            "autor": "Iván Solís Rivera",
            "descripcion": "¿Qué hacer si no te quedaste en el IPN? Descubre las mejores opciones educativas públicas y privadas de educación superior.",
            "portada": "/images/que-hacer-quedaste-ipn.webp",
            "enlace": "/aprende/tips/que-hacer-si-no-te-quedaste-en-el-ipn"
        },
        
    ], // Array de artículos original
    filtros: {
        "0058-ART-APRENDE-TIPS-010824": {
            Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y teatro"],
            Ciencias_sociales: ["Derecho", "Ciencias Políticas y Administración Pública"],
            Economico_administrativas: ["Administración", "Contabilidad"]
        },
    },
    filtrosSeleccionados: null, // Lista de filtros seleccionados
    };
},
methods: {
    
}
};
</script>

<style>
/* Estilos CSS si los tienes */
</style>