<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">Por qué una carrera corta es tu mejor aliada,<br class="md:block hidden"> descúbrelo</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-200">
          <img src="/images/carreras-cortas-beneficios.jpg" class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica" >
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/academico" class=" text-hone">Académico</router-link> > <router-link to="/blog/academico/licenciatura" class=" text-hone">Licenciatura</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex list-disc pl-5 italic text-left text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">06 de febrero de 2024</li>  <li>Carolina Reyes Martínez/Redacción Humanitas</li></ul>
            </div>
            <p class=" md:mb-4 mb-6"><b>Extracto:</b> Estudiar una licenciatura enfocada en tus necesidades sí es posible. Elegir tu futuro profesional es muy importante, por eso, <b>aquí te decimos cuáles son los beneficios de las carreras cortas</b> y cómo pueden ayudarte a iniciar en el mundo laboral.</p>
            <p class=" md:mb-4 mb-6"><b>Ya terminaste tu preparatoria o bachillerato… ¿Ahora qué sigue? </b>¿Seguir estudiando o trabajar, o hacer las dos cosas? O bien, ya sabes qué carrera estudiar pero aún tienes dudas sobre el tiempo que quieres invertir.</p>
            <p class=" md:mb-8 mb-10">Éstas son preguntas muy comunes pero esenciales para que tomes una decisión. Sigue leyendo y conoce más sobre <b>por qué estudiar una carrera corta.</b> Estudiar una licenciatura enfocada en tus necesidades sí es posible. Elegir tu futuro profesional es muy importante, por eso, <b>aquí te decimos cuáles son los beneficios de las carreras cortas</b></p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Pero primero, algunos datos que te interesarán</h2>
            <p class="md:mb-4 mb-6">Estudiar una licenciatura implica invertir tiempo, dinero y esfuerzo. De acuerdo al <b>Instituto Mexicano de la Competitividad </b> (IMCO), <b>estudiar una carrera es rentable </b>porque es una inversión que tiene un rendimiento anual de 11%. <b>Además, un egresado de licenciatura gana 85% más </b>que una persona que sólo tiene la preparatoria o el bachillerato, lo que le permite acceder a otras oportunidades laborales y a un mayor desarrollo personal.</p>
            <p class="md:mb-8 mb-10">Actualmente, <b>el mercado laboral está en constante cambio por lo que es importante que los jóvenes se adapten a esta dinámica, </b> ya que si bien se ha incrementado en un 25% el número de profesionales universitarios, <b>el Foro Económico Mundial estima que 43% de las habilidades básicas que demandarán los empleadores cambiarán en los próximos cinco años.</b> Por eso, es fundamental que te prepares e informes sobre las alternativas académicas, y desarrolles las habilidades necesarias para mantenerte al día.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">¿Por qué elegir una carrera corta tiene beneficios?</h2>
            <p class="md:mb-8 mb-10">Tal vez a tus papás o a otras personas les tocó todavía estar cuatro, cinco o hasta seis años en la universidad, pero <b>hoy en día tú puedes cursar una licenciatura en menos tiempo.</b> Tomar esta decisión representa un compromiso con tu desarrollo y éstos son los beneficios:</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Economía de tiempo y de recursos</h3>
            <p class="md:mb-4 mb-6"><b>Las carreras cortas tienen un costo más accesible,</b> <a class="text-htwo" href="https://humanitas.edu.mx/" target="_blank">algunas instituciones</a> privadas no cobran inscripción y tienen facilidades de pago, así como una duración de tres años y medio.</p>
            <p class="md:mb-8 mb-10">Además, <b>el retorno de la inversión es más rápido ya que puedes comenzar a trabajar y así, terminar de pagar tus estudios</b> estudios y comenzar a desarrollarte profesionalmente. Es una opción económicamente atractiva tanto para los estudiantes como para los padres que apoyan a sus hijos en su educación.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Flexibilidad: adapta tu licenciatura a tu vida</h3>
            <p class="md:mb-4 mb-6"><b>Las carreras más cortas te ofrecen opciones de estudio flexibles,</b>, puedes ir a la universidad en un modelo escolarizado, o sea las clases son cien por ciento presenciales.</p>
            <p class="md:mb-8 mb-10">También <b>puedes asistir algunos días a la universidad y otros tomar tus clases en línea.</b> A esto se le conoce como <b>modelo ejecutivo.</b> Otra opción es <b>estudiar en el modelo virtual,</b> sólo necesitas tu computadora para tomar clases desde cualquier lugar. <b>Esto te permite equilibrar tus estudios con otras actividades</b> laborales o personales.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Puedes continuar con otros estudios superiores</h3>
            <p class="md:mb-8 mb-10">Algunas carreras cortas te permiten transferir tus créditos a otras escuelas y continuar con una maestría o una nueva carrera. De esta manera, ya tienes una base para estudios más avanzados.</p>
            <img src="/images/carreras-cortas-beneficios-trabajar-rapido-2.jpg" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif text-hone">¿Cómo te ayudan las carreras cortas en tu éxito profesional?</h2>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Desarrollo de habilidades teóricas y prácticas</h3>
            <p class="md:mb-4 mb-6"><b>Estas licenciaturas están diseñadas para integrarte al mercado laboral más rápido,</b> y sus planes de estudios equilibran la teoría y la práctica, con las competencias necesarias para que inicies tu vida profesional.</p>
            <p class="md:mb-8 mb-10">Además, pueden incluir pasantías y prácticas profesionales para garantizar que adquieras experiencia real para enfrentar los desafíos de un trabajo.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Versatilidad para emprendedores y trabajadores</h3>
            <p class="md:mb-8 mb-10"><b>Si ya trabajas o tienes un negocio, las carreras cortas te permiten mejorar tus habilidades</b> sin descuidar tus responsabilidades laborales. Es una oportunidad para seguir actualizándote.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Consejos finales para elegir la carrera adecuada para ti</h2>
            <ul class="list-decimal pl-6 md:mb-4 mb-6">
                <li><b>Para tomar una decisión, primero evalúa qué es lo que a ti te gusta,</b> cuáles son tus habilidades y tus metas profesionales.</li>
                <li>Investiga las universidades que te interesan, compara tus alternativas <b>y qué carreras son las que hacen match con tus intereses y competencias. Lo importante es que haya un equilibrio entre lo que te apasiona y qué tan viable será encontrar trabajo al terminar de estudiar.</b></li>
                <li>Si aún tienes dudas, <b>toma asesoría vocacional y también solicita información en las universidades de tu interés:</b> qué planes o programas de estudios tienen, el nivel académico de los profesores, cuál es el costo de la colegiatura e inscripciones. Cómo son sus programas de servicio social o prácticas profesionales. Investiga todo lo que puedas para tomar una decisión consciente.</li>
                <li>Y por último, <b>cuando ya estés en la universidad, aprovecha tus clases y crea una red de contactos</b> (networking), en un futuro podrías brindar apoyo a otros compañeros o ellos a ti.</li>
            </ul>
            <p class="md:mb-8 mb-10">Ahora que tienes esta información <b>sobre las carreras universitarias cortas,</b> ya puedes decidir y enfocarte en tus metas. Tu educación no sólo se mide por el tiempo, también por la calidad y experiencia que obtendrás en tu camino.</p>
            <!-- Aqui va el componente de contacto, no se te olvide hacerlo manolo -->
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
                <Contacto class="m-3"/>
            </div>
            <p class="text-htwo text-left mb-6">Explora más: <a href="https://humanitas.edu.mx/" target="_blank" class="text-htwo">Universidad Humanitas</a></p>
            <p class="text-htwo text-left pb-12">Te puede interesar: <router-link to="/blog/academico/licenciatura/mejores-universidades-derecho-mexico-guia"  class="text-htwo">Guía de las mejores universidades para estudiar Derecho en México</router-link></p>
            <p class="text-htwo text-left font-bold mb-2">Fuentes:</p>
            <p class="text-left mb-20 text-base"><a href="https://humanitas.edu.mx/" target="_blank" class="text-black">Licenciaturas</a></p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArtVue :ant="true" :linkAnt="'/aprende/tendencias/tendencias-administracion-empresas-2024'" :linkSig="'/academico/doctorado/guia-todo-sobre-doctorado-perfil-y-competencias'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import Contacto from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue'
import SigArtVue from '../../pagesComponents/SigArt.vue';


export default {
    components: { Comentarios, ConComentarios, Contacto, LikesVue, ShareP, SigArtVue},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '05-ART-ACADEMICO-LICENCIATURA-060224',
            ruta: '/academico/licenciatura/beneficios-carreras-universitarias-cortas',
            title: 'Estudia una licenciatura en menor tiempo para impulsar tu éxito profesional.',
            image: 'https://blog.humanitas.edu.mx/images/carreras-cortas-beneficios.jpg',
            text: 'Conoce los beneficios de las carreras universitarias cortas y cómo pueden ser una opción ideal si quieres iniciar tu vida laboral más rápido.'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx'+this.ruta;

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>