<script>
    // @ts-ignore
    import axios from 'axios';
    import { ConsultasBlog } from '@/main';
    // @ts-ignore
    import Swal from 'sweetalert2';

    export default {
        mounted() {
            this.cargarComentarios()
        },

        data() {
            return {
                toast : Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    width: 500,
                    padding: '1em',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: 4000,
                    icon: 'error',
                    iconColor: '#881912'
                }),
                toastSus : Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    width: 500,
                    padding: '1em',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: 4000,
                    icon: 'success',
                    iconColor: '#881912'
                }),
                coments : true,
                vista: 'Articulos',
                estado: 'Nuevo',
                /**
                 * @type {{ idComentarios: any, AutorComent: string, ContenidoComent: string, FechaComent: string, EstadoComent: string, Articles_idArticles: string }[]}
                 */
                data: [],
                changeOfEstado: '',
                folio: '',
                titulo: '',
                fecha: '',
                autor: '',
                descripcion: '',
                portada: '',
                enlace: '',
                estadoAct: '',
                tituloLink: '',
                nombreLink: '/',
                categoriaLink: '',
                subCatLink: '',
                numero: 0,
                numeroFormat: '0000',
                fechaFormat: '010101',
                fechaArt: '',
                categoria: '',
                subcategoria: '',
                catFol: '',
                subFol: '',
                /**
                 * @type {{ name: string, enlace: string, subFolio: string }[]}
                 */
                subcategorias: [],
                categoriaArray: [
                    { name: 'Académico', enlace: '/academico', catFolio: 'ACADEMICO',
                        subcategorias: [
                            { name: 'Licenciatura', enlace: '/licenciatura', subFolio: 'LICENCIATURA' },
                            { name: 'Maestría', enlace: '/maestria', subFolio: 'MAESTRIA' },
                            { name: 'Doctorado', enlace: '/doctorado', subFolio: 'DOCTORADO' },
                            { name: 'Estudia en línea', enlace: '/estudia-en-linea', subFolio: 'ESTUDIA-EN-LINEA'},
                            { name: 'Educación Continua', enlace: '/educacion-continua', subFolio: 'EDUCAION-CONTINUA' }
                        ] 
                    },
                    { name: 'Aprende', enlace: '/aprende', catFolio: 'APRENDE',
                        subcategorias: [
                            { name: 'Cultura General', enlace: '/cultura-general', subFolio: 'CULTURA-GENERAL' },
                            { name: 'Tendencias', enlace: '/tendencias', subFolio: 'TENDENCIAS' },
                            { name: 'Tips', enlace: '/tips', subFolio: 'TIPS' }
                        ]
                    },
                    { name: 'Recursos', enlace: '/recursos', catFolio: 'RECURSOS',
                        subcategorias: [
                            { name: 'Casos Reales', enlace: '/casos-reales', subFolio: 'CASOS-REALES' },
                            { name: 'Entrevistas y Conferencias', enlace: '/entrevistas-y-conferencias', subFolio: 'ENTREVISTAS-Y-CONFERENCIAS' },
                            //{ name: 'Ebooks', enlace: '/ebooks', subFolio: 'EBOOKS' },
                            //{ name: 'Podcast', enlace: '/podcast', subFolio: 'PODCAST' },
                            //{ name: 'Webinars', enlace: '/webinars', subFolio: 'WEBINARS' },
                        ]
                    },
                    { name: 'Ser Humanitas', enlace: '/ser-humanitas', catFolio: 'SER-HUMANITAS',
                        subcategorias: [
                            { name: 'Responsabilidad Social', enlace: '/responsabilidad-social', subFolio: 'RESPONSABILIDAD-SOCIAL' },
                            { name: 'Comunidad', enlace: '/Comunidad', subFolio: 'COMUNIDAD' },
                            //{ name: 'Actividades en Campus', enlace: '/actividades-en-campus', subFolio: 'ACTIVIDADES-EN-CAMPUS' },
                            //{ name: 'Casos de Éxito', enlace: '/casos-de-exito', subFolio: 'CASOS-DE-EXITO' },
                            //{ name: 'Testimonios', enlace: '/testimonios', subFolio: 'TESTIMONIOS' }
                        ]
                    },
                ],
            }
        },

        methods: {
            changeView () {
                // @ts-ignore
                this.coments = !this.coments
                // @ts-ignore
                if ( this.vista == 'Articulos') {
                    // @ts-ignore
                    this.vista = 'Comentarios'
                } else {
                    // @ts-ignore
                    this.vista = 'Articulos'
                }
            },
            formatDate() {
                // @ts-ignore
                if (this.fecha) {
                    // @ts-ignore
                    const [year, month, day] = this.fecha.split('-');
                    // @ts-ignore
                    this.fechaFormat = day + month + year.slice(2);
                    // @ts-ignore
                    this.fechaArt = year + '-' + month + '-' + day
                }
                return '';
            },

            async cargarComentarios() {
                try {
                    const response = await axios.get(ConsultasBlog, {
                    params: {
                        action: 'obtenerComentariosPorEstado',
                        // @ts-ignore
                        params: JSON.stringify([this.estado])
                    }
                    });
                    if (response.data.error) {
                        console.error(response.data.error);
                    } else {
                    // @ts-ignore
                    this.data = response.data;
                    }
                } catch (error) {
                    console.error('Error al cargar los comentarios:', error);
                }
            },
            
            /**
             * @param {any} estado
             * @param {any} id
             */
            async modificarEstadoCom(estado, id) {
                try {
                    const params = {
                    action: 'actualizarEstadoComentario',
                    params: JSON.stringify({
                        id: id,
                        estado: estado
                    })
                };

                await axios.get(ConsultasBlog, { params });
                this.cargarComentarios()
                const mensaje= 'Comentario del artículo ' + id + ' se cambió a ' + estado;
                this.mostrarSucessSweetAlert(mensaje)
                } catch (error) {
                    console.error(error);
                }
            },

            async insertarArticulo() {
                if(this.validarDatos()) {
                    try {
                        const folio = `${this.numeroFormat}-ART-${this.catFol}-${this.subFol}-${this.fechaFormat}`;
                        const enlace = `${this.categoriaLink}${this.subCatLink}${this.nombreLink}`;

                        const params = {
                        action: 'insertarArticulo',
                        params: JSON.stringify({
                            folio: folio,
                            titulo: this.titulo,
                            fecha: this.fechaArt,
                            autor: this.autor,
                            descripcion: this.descripcion,
                            portada: this.portada,
                            enlace: enlace
                        })
                        
                        };
                        await axios.get(ConsultasBlog, { params });
                        this.numeroFormat = '0000'
                        this.catFol = ''
                        this.subFol = ''
                        this.fechaFormat = '010101'
                        this.categoriaLink = ''
                        this.subCatLink = ''
                        this.nombreLink
                        this.titulo = ''
                        this.fechaArt = ''
                        this.descripcion = ''
                        this.portada = ''
                        this.numero = 0
                        this.categoria = ''
                        this.subcategoria = ''
                        this.autor = ''
                        this.fecha = ''
                        this.tituloLink = ''
                        this.mostrarSucessSweetAlert('Artículo insertado')
                    } catch (error) {
                        console.error(error);
                    }
                }
            },

            consulCon() {
                this.cargarComentarios()
            },

            padNumberToFourDigits() {
                // @ts-ignore
                this.numeroFormat = this.numero.toString().padStart(4, '0');
            },

            convertToSlug() {
                // @ts-ignore
                this.nombreLink = '/' + this.tituloLink
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .replace(/\s+/g, '-');
            },

            updateSubcategorias() {
                // @ts-ignore
                const selectedCat = this.categoriaArray.find(cat => cat.name === this.categoria);
                if (selectedCat) {
                    // @ts-ignore
                    this.subcategorias = selectedCat.subcategorias;
                    // @ts-ignore
                    this.catFol = selectedCat.catFolio;
                    // @ts-ignore
                    this.categoriaLink = selectedCat.enlace
                    // @ts-ignore
                    this.subcategoria = ''
                    // @ts-ignore
                    this.subFol = ''
                    // @ts-ignore
                    this.subCatLink = ''
                } else {
                    // @ts-ignore
                    this.subcategorias = [];
                }
            },

            selectSubcategoria() {
                // Encuentra la categoría seleccionada en base a la subcategoría elegida
                // @ts-ignore
                const selectedCat = this.categoriaArray.find(cat =>
                    // @ts-ignore
                    cat.subcategorias.some(sub => sub.name === this.subcategoria)
                );

                if (selectedCat) {
                    // Encuentra la subcategoría específica dentro de la categoría seleccionada
                    // @ts-ignore
                    const selectedSub = selectedCat.subcategorias.find(sub => sub.name === this.subcategoria);
                    if (selectedSub) {
                        // @ts-ignore
                        this.subcategoria = selectedSub.name;
                        // @ts-ignore
                        this.subFol = selectedSub.subFolio;
                        // @ts-ignore
                        this.subCatLink = selectedSub.enlace;
                    }
                } else {
                    console.error("Subcategoría no encontrada");
                }
            },

            /**
            * @param {string} mensaje
            */
            mostrarErrorSweetAlert(mensaje) {
                // @ts-ignore
                this.toast.fire({
                icon: 'error',
                title: 'Validación incorrecta',
                text: mensaje,
                });
            },

            /**
            * @param {string} mensaje
            */
            mostrarSucessSweetAlert(mensaje) {
                // @ts-ignore
                this.toast.fire({
                icon: 'success',
                title: 'Operación exitosa',
                text: mensaje,
                });
            },

            validarDatos () {
                if(this.numero == 0) {
                    this.mostrarErrorSweetAlert('Ingrese el número de Artículo.');
                } else {
                    if (this.categoria == '') {
                        this.mostrarErrorSweetAlert('Selecciona la categoria del Artículo.');
                    } else {
                        if (this.subcategoria == '') {
                            this.mostrarErrorSweetAlert('Ingrese la subcategoria del Artículo.');
                        } else {
                            if (this.fecha == '') {
                                this.mostrarErrorSweetAlert('Ingrese la fecha del Artículo.');
                            } else {
                                if (this.titulo == '') {
                                    this.mostrarErrorSweetAlert('Ingrese el metatitulo del Artículo.');
                                } else {
                                    if (this.autor == '') {
                                        this.mostrarErrorSweetAlert('Ingrese el autor del Artículo.');
                                    } else {
                                        if (this.descripcion == '') {
                                            this.mostrarErrorSweetAlert('Ingrese la descripción del Artículo.');
                                        } else {
                                            if (this.portada == '') {
                                                this.mostrarErrorSweetAlert('Ingrese el nombre de la imagen para portada.');
                                            } else {
                                                if (this.tituloLink == '') {
                                                    this.mostrarErrorSweetAlert('Ingrese el nombre del enlace.');
                                                } else {
                                                    return true
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                return false
            }
        },
    }
    
</script>


<template>
    <section class="flex justify-center items-center w-full">
        <div class="flex flex-col justify-center md:py-36 py-32  w-full font-sans text-black">
            <h1 class="text-2xl text-hwine uppercase text-center w-full my-2">Panel de administración</h1>
            <div class="flex flex-col justify-center items-center w-full">
                <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1200px]">
                    <div class="flex items-end justify-end mb-6">
                        <button class="bg-hwine rounded-none w-[210px] h-[45px] items-center justify-center text-white hover:bg-hgold hover:text-white text-[16px] transition duration-300 focus:outline-none ease-in-out" @click.prevent="changeView">Cambiar a {{ vista }}</button>
                    </div>
                    <div v-if="coments" class="flex flex-col w-full">
                        <div class="flex items-end justify-end mb-4">
                            <div class="flex items-center justify-end text-[18px]"><p class="mr-4">Filtrar por</p>
                                <select v-model="estado" @change="consulCon" class="rounded-none block px-4 py-2 border border-gray-300 bg-white text-gray-700 shadow-sm focus:outline-none ">
                                    <option value="Nuevo">Nuevos</option>
                                    <option value="Autorizado">Autorizados</option>
                                    <option value="Rechazado">Rechazados</option>
                                </select>
                            </div>
                        </div>
                        <table class="min-w-full text-left bg-white">
                        <thead>
                            <tr>
                            <th class="px-3 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-800 tracking-wider">Autor</th>
                            <th class="pr-3 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-800 tracking-wider">Comentario</th>
                            <th class="px-3 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-800 tracking-wider">Fecha</th>
                            <th class="px-3 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-800 tracking-wider">Estado</th>
                            <th class="px-3 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-800 tracking-wider">Folio</th>
                            <th class="px-3 py-3 border-b-2 border-gray-300 text-left leading-4 text-gray-800 tracking-wider">Modificar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="comentario in data" :key="comentario.idComentarios">
                            <td class="px-3 py-4 whitespace-no-wrap border-b border-gray-300">
                                {{ comentario.AutorComent }}
                            </td>
                            <td class="pr-3 py-4 whitespace-no-wrap border-b border-gray-300">
                                {{ comentario.ContenidoComent }}
                            </td>
                            <td class="px-3 py-4 whitespace-no-wrap border-b border-gray-300">
                                {{ comentario.FechaComent }}
                            </td>
                            <td class="px-3 py-4 whitespace-no-wrap border-b border-gray-300">
                                {{ comentario.EstadoComent }}
                            </td>
                            <td class="px-3 py-4 whitespace-no-wrap border-b border-gray-300">
                                {{ comentario.Articles_idArticles }}
                            </td>
                            <td class="px-3 py-4 whitespace-no-wrap border-b border-gray-300">
                                <select v-model="comentario.EstadoComent" @change="modificarEstadoCom(comentario.EstadoComent, comentario.idComentarios)" class="rounded-none block px-4 py-2 border border-gray-300 bg-white text-gray-700 shadow-sm focus:outline-none ">
                                    <option v-if="estado != 'Autorizado'" value="Autorizado">Autorizado</option>
                                    <option v-if="estado != 'Rechazado'" value="Rechazado">Rechazado</option>
                                </select>
                            </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    <div v-else class="flex flex-col w-full">
                        <div class="w-[60%] mx-auto  bg-white p-6 rounded-none shadow-lg">
                            <h2 class="text-2xl font-bold mb-6 text-center">Insertar Artículo</h2>
                            <form @submit.prevent="insertarArticulo" id="insertarArticuloForm">
                                <div class="mb-4">
                                    <div class="flex flex-col justify-start items-start w-full">
                                        <label for="numero" class="block text-base font-medium text-gray-700">Número</label>
                                    </div>
                                    <input type="text" v-model="numero" placeholder="0000" id="numero" maxlength="4" @keyup="padNumberToFourDigits" name="numero" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-none shadow-sm focus:outline-none focus:ring-htwo focus:border-htwo sm:text-sm" required>
                                </div>
                                <div class="mb-4">
                                    <div class="flex flex-col justify-start items-start w-full">
                                        <label for="categoria" class="block text-base font-medium text-gray-700">Categoría</label>
                                    </div>
                                    <select v-model="categoria" @change="updateSubcategorias" id="categoria" name="categoria" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-none shadow-sm focus:outline-none focus:ring-htwo focus:border-htwo" required>
                                        <option v-for="categoria in categoriaArray" :key="categoria.name" :value="categoria.name">
                                            {{ categoria.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="mb-4">
                                    <div class="flex flex-col justify-start items-start w-full">
                                        <label for="subcategoria" class="block text-base font-medium text-gray-700">Subcategoría</label>
                                    </div>
                                    <select v-model="subcategoria" id="subcategoria" name="subcategoria" @change="selectSubcategoria" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-none shadow-sm focus:outline-none focus:ring-htwo focus:border-htwo" required>
                                        <option v-for="subcategoria in subcategorias" :key="subcategoria.name" :value="subcategoria.name">
                                            {{ subcategoria.name }}
                                        </option>
                                    </select>
                                </div>
                                <div class="mb-4">
                                    <div class="flex flex-col justify-start items-start w-full">
                                        <label for="fecha" class="block text-base font-medium text-gray-700">Fecha</label>
                                    </div>
                                    <input type="date" v-model="fecha" id="fecha" name="fecha" @change="formatDate" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-none shadow-sm focus:outline-none focus:ring-htwo focus:border-htwo sm:text-sm" required>
                                </div>
                                <div class="mb-4">
                                    <div class="flex flex-col justify-start items-start w-full">
                                        <label for="folio" class="block text-base font-medium text-gray-700">Folio</label>
                                        <label class="mt-1 block w-full px-3 py-2 text-left border border-gray-300 rounded-none shadow-sm focus:outline-none focus:ring-htwo focus:border-htwo sm:text-sm"> {{ numeroFormat + '-ART-' + catFol + '-' + subFol + '-' + fechaFormat }} </label>
                                    </div>
                                </div>
                                <div class="mb-4">
                                    <div class="flex flex-col justify-start items-start w-full">
                                        <label for="titulo" class="block text-base font-medium text-gray-700">Título</label>
                                    </div>
                                    <input type="text" v-model="titulo" id="titulo" name="titulo" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-none shadow-sm focus:outline-none focus:ring-htwo focus:border-htwo sm:text-sm" required>
                                </div>
                                <div class="mb-4">
                                    <div class="flex flex-col justify-start items-start w-full">
                                        <label for="autor" class="block text-base font-medium text-gray-700">Autor</label>
                                    </div>
                                    <input type="text" v-model="autor" id="autor" name="autor" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-none shadow-sm focus:outline-none focus:ring-htwo focus:border-htwo sm:text-sm" required>
                                </div>
                                <div class="mb-4">
                                    <div class="flex flex-col justify-start items-start w-full">
                                        <label for="descripcion" class="block text-base font-medium text-gray-700">Descripción</label>
                                    </div>
                                    <textarea v-model="descripcion" id="descripcion" name="descripcion" rows="4" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-none shadow-sm focus:outline-none focus:ring-htwo focus:border-htwo sm:text-sm" required></textarea>
                                </div>
                                <div class="mb-4">
                                    <div class="flex flex-col justify-start items-start w-full">
                                        <label for="portada" class="block text-base font-medium text-gray-700">Portada</label>
                                    </div>
                                    <input type="text" v-model="portada" placeholder="ejemplo.webp" id="portada" name="portada" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-none shadow-sm focus:outline-none focus:ring-htwo focus:border-htwo sm:text-sm" required>
                                </div>
                                <div class="mb-4">
                                    <div class="flex flex-col justify-start items-start w-full">
                                        <label for="Nombre enlace" class="block text-base font-medium text-gray-700">Nombre del enlace</label>
                                    </div>
                                    <input type="text" v-model="tituloLink" placeholder="" id="Nombre enlace" name="Nombre enlace" @keyup="convertToSlug" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-none shadow-sm focus:outline-none focus:ring-htwo focus:border-htwo sm:text-sm" required>
                                </div>
                                <div class="mb-4">
                                    <div class="flex flex-col justify-start items-start w-full">
                                        <label for="enlace" class="block text-base font-medium text-gray-700">Enlace</label>
                                        <label class="mt-1 block w-full px-3 py-2 text-left border border-gray-300 rounded-none shadow-sm focus:outline-none focus:ring-htwo focus:border-htwo sm:text-sm">{{ categoriaLink + subCatLink + nombreLink }}</label>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <button @click.prevent="insertarArticulo" class="bg-htwo w-[200px] text-white py-2 px-4 rounded-none htext-white hover:bg-hgold hover:text-white text-[16px] transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-htwo focus:ring-offset-2">Insertar Artículo</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<style scoped>
    select {
    border-radius: 0;
    }
</style>