<script lang="ts">
    import { defineComponent } from 'vue';
    

    export default defineComponent({
        components: {},
        data() {
            return {
                windowWidth: window.innerWidth,
                campuses: [
                    {
                        image: '../pagesImages/Slider/aniversario.webp',
                        image2: '/pagesImages/Home_Responsivo.webp',
                        type: '45 años de experiencia',
                        name: '',
                        address: '',
                        visitLink: '',
                    },
                    {
                        image: '../pagesImages/Slider/cancun.webp',
                        image2: '../pagesImages/Slider/cancun.webp',
                        type: 'Campus Magno',
                        name: 'Cancún',
                        address: 'Cancún, Quintana Roo',
                        visitLink: 'https://humanitas.edu.mx/campus/cancun',
                    },
                    {
                        image: '../pagesImages/Slider/guadalajara.webp',
                        image2: '../pagesImages/Slider/guadalajara.webp',
                        type: 'Campus Ejecutivo',
                        name: 'Guadalajara',
                        address: 'Guadalajara, Jalisco',
                        visitLink: 'https://humanitas.edu.mx/campus/guadalajara',
                    },
                    {
                        image: '../pagesImages/Slider/madin.webp',
                        image2: '../pagesImages/Slider/madin.webp',
                        type: 'Campus Magno',
                        name: 'Presa Madín',
                        address: 'Estado de México',
                        visitLink: 'https://humanitas.edu.mx/campus/presa-madin',
                    },
                    {
                        image: '../pagesImages/Slider/merida.webp',
                        image2: '../pagesImages/Slider/merida.webp',
                        type: 'Campus Magno',
                        name: 'Mérida',
                        address: 'Mérida, Yucatán',
                        visitLink: 'https://humanitas.edu.mx/campus/merida',
                    },
                    {
                        image: '../pagesImages/Slider/queretaro.webp',
                        image2: '../pagesImages/Slider/queretaro.webp',
                        type: 'Campus Magno',
                        name: 'Querétaro',
                        address: 'Juriqilla, Querétaro',
                        visitLink: 'https://humanitas.edu.mx/campus/queretaro',
                    },
                    {
                        image: '../pagesImages/Slider/reyes.webp',
                        image2: '../pagesImages/Slider/reyes.webp',
                        type: 'Campus Magno',
                        name: 'Los Reyes',
                        address: 'Estado de México',
                        visitLink: 'https://humanitas.edu.mx/campus/los-reyes',
                    },
                    {
                        image: '../pagesImages/Slider/santa-fe.webp',
                        image2: '../pagesImages/Slider/santa-fe.webp',
                        type: 'Campus Ejecutivo',
                        name: 'Santa Fe',
                        address: 'Ciudad de México',
                        visitLink: 'https://humanitas.edu.mx/campus/santa-fe',
                    },
                    {
                        image: '../pagesImages/Slider/tijuana.webp',
                        image2: '../pagesImages/Slider/tijuana.webp',
                        type: 'Campus Magno',
                        name: 'Tijuana',
                        address: 'Tijuana, BC.',
                        visitLink: 'https://humanitas.edu.mx/campus/tijuana',
                    },
                    {
                        image: '../pagesImages/Slider/valle.webp',
                        image2: '../pagesImages/Slider/valle.webp',
                        type: 'Campus Magno',
                        name: 'Del Valle',
                        address: 'Ciudad de México',
                        visitLink: 'https://humanitas.edu.mx/campus/del-valle',
                    },
                ],
                currentCampus: 0,
                timer: null,
            };
        },
        computed: {
            currentCampusData() {
                return this.campuses[this.currentCampus];
            },
            showCampus() {
                return this.currentCampus !== 0;
            },
        },
        methods: {
            changeCampus(index: number): void {
                this.currentCampus = index;
                this.resetTimer();
            },
            resetTimer(): void {
                //@ts-ignore
                clearInterval(this.timer);
                this.startTimer();
            },
            startTimer(): void {
            //@ts-ignore
                this.timer = setInterval(() => {
                    const nextIndex = (this.currentCampus + 1) % this.campuses.length;
                    this.changeCampus(nextIndex);
                }, 5000); // Cambia el intervalo según sea necesario (en milisegundos)
            },
        },
        mounted() {
            this.startTimer();
        },
        beforeUnmount() {
            //@ts-ignore
            clearInterval(this.timer);
        },
    });
</script>

<template>
    <div class="relative mb-12 carousel-container max-h-screen h-full">
        <transition name="fade">
        <div :key="currentCampusData.image" class="active flex flex-col items-center justify-center">
            <img  :src="currentCampusData.image2" :alt="currentCampusData.type+' '+currentCampusData.name" class="object-cover md:hidden object-center h-screen w-screen pt-[88px] lg:pt-[143px]" loading="eager" />
            <img  :src="currentCampusData.image" :alt="currentCampusData.type+' '+currentCampusData.name" class="object-cover hidden md:block object-center h-screen w-screen pt-[88px] lg:pt-[143px]" loading="eager" />
            <div v-if="currentCampus !== 0" class="absolute text-center md:text-left text-white z-10 w-full max-w-4xl lg:max-w-6x flex flex-col justify-center items-center md:items-start px-4 drop-shadow-md">
                <h2 class="lg:text-3xl text-xl uppercase" style="letter-spacing: 10px; text-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);">{{ currentCampusData.type }}</h2>
                <h3 class="font-bold lg:text-5xl text-3xl mb-3 uppercase" style="letter-spacing: 10px; text-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);">{{ currentCampusData.name }}</h3>
                <a :href="currentCampusData.visitLink" target="_blank">
                    <button v-if="currentCampus !== 0" class="flex items-center focus:outline-none justify-center bg-white/90 rounded-none hover:bg-white/60 lg:text-[24px] text-[#881912] font-semibold border-2 border-[#881912] w-[140px] h-[35px] lg:w-[210px] lg:h-[50px] transition duration-300 ease-in-out">
                        <span>Vis&iacute;tanos</span>
                    </button>
                </a>
            </div>
        </div>
        </transition>
        <div class="flex relative -top-12 justify-center mt-4 space-x-3">
            <span
                v-for="(_dot, index) in campuses"
                :key="index"
                :class="{ 'bg-gray-400': index !== currentCampus, 'bg-[#881912]': index === currentCampus }"
                class="h-2 w-2 rounded-full cursor-pointer"
                @click="changeCampus(index)"
            ></span>
        </div>
    </div>
</template>

<style scoped>
   .fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s ease-in-out;
    }
    .fade-enter, .fade-leave-to {
    opacity: 0;
    position: absolute;
    }
    .carousel-container {
        position: relative;
        overflow: hidden;
    }
    .carousel-container img {
        object-fit: cover; /* Mantiene la imagen cubierta sin desbordar */
    }
</style>


