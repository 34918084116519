<script lang="ts">
    import { defineComponent } from 'vue';
    import PortadaInd from '../components/PortadaInd.vue';
    import NavbarInd from '../components/NavbarInd.vue';
    import FooterInd from '../components/FooterInd.vue';
    import HoverNivel from './components/HoverNivel.vue';
    import EgresadosVid from './components/egresadosVid.vue';
    import Swal from 'sweetalert2';
    import axios from 'axios';
    import { Solicitud2 } from '@/main';
    // import { Solicitud } from '@/main';


    export default defineComponent({
        components: { PortadaInd, NavbarInd, FooterInd, HoverNivel, EgresadosVid},
        data() {
            return {
                //datos para los formularios
                tokenizer: "",
                selectedCampus: "",
                clave: "",
                studyTypeList: [], // Lista de niveles educativos
                selectedStudyType: "",
                offerList: [], // Lista de ofertas educativas
                selectedOffer: "",
                modelList: [], // Lista de modelos educativos
                selectedModel: "",
                campusData: {}, // Objeto para almacenar los datos del campus
                toast : Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    width: 500,
                    padding: '1em',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    timer: 4000,
                    icon: 'error',
                    iconColor: '#881912'
                }),

                nombre: '',
                apellidos: '',
                email: '',
                telefono: '',
                comentario: '',
                currentStep: 1,

                // Estados para manejar errores y validaciones
                nombreError: '',
                apellidosError: '',
                emailError: '',
                telefonoError: '',
                urlW: '',
            };
        },
        methods: {
            async updateOptions(section: any) {
                await this.loadData(section);
            },

            async loadData(section: string) {
                try {
                    // Importa el módulo del campus seleccionado
                    const campusModule = await import(`@/static/json/${this.selectedCampus}.json`);
                    // Obtiene los datos del campus
                    const campusData = campusModule.default;

                    // Verifica la sección y carga los datos correspondientes
                    if (section === 'studyType') {
                        this.selectedStudyType= "";
                        this.selectedOffer = "";
                        this.selectedModel = "";
                        this.studyTypeList = [];
                        this.offerList = [];
                        this.modelList = [];
                        // Carga los tipos de estudios para el campus seleccionado
                        //@ts-ignore
                        this.studyTypeList = Object.keys(campusData);
                    
                    } else if (section === 'offer') {
                        this.selectedOffer = "";
                        this.selectedModel = "";
                        this.offerList = [];
                        this.modelList = [];
                        // Lógica para cargar datos de oferta 
                        //@ts-ignore
                        this.offerList = campusData[this.selectedStudyType].map(item => item.oferta);
                    } else if (section === 'model') {
                        this.selectedModel = "";
                        this.modelList = [];
                        // Lógica para cargar datos de modelo 
                        this.modelList = campusData[this.selectedStudyType].find((item: { oferta: any; }) => item.oferta === this.selectedOffer).nivel.map((item: { name: any; }) => item.name);
                    } else if (section === 'clv') {
                        const nivelSeleccionado = this.selectedModel; 
                        const nivelObject = campusData[this.selectedStudyType].find((item: { oferta: any; }) => item.oferta === this.selectedOffer)
                            .nivel.find((nivel: { name: any; }) => nivel.name === nivelSeleccionado);

                        // Asigna el valor de la propiedad 'key' a la variable global clave
                        this.clave = nivelObject.key;
                    }
                } catch (error) {
                    // Maneja errores en la carga de datos
                    console.error('Error al cargar datos de la sección', section, error);
                }
            },
            mostrarErrorSweetAlert(mensaje: string) {
                this.toast.fire({
                    icon: 'error',
                    title: 'Error de validación',
                    text: mensaje,
                });
            },
            currentUrl() {
                return window.location.href;
            },
            validarDatos(){
                if(!/^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s]{1,30}$/.test(this.nombre)){
                    this.nombreError = 'Ingrese un nombre válido.';
                    this.mostrarErrorSweetAlert(this.nombreError);
                } else {
                    if(!/^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s]{1,55}$/.test(this.apellidos)){
                        this.apellidosError = 'Por favor ingresa un(os) apellido(s) válido(s)..';
                        this.mostrarErrorSweetAlert(this.apellidosError);
                    } else {
                        if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email)){
                            this.emailError = 'Ingrese un correo electrónico válido.';
                            this.mostrarErrorSweetAlert(this.emailError);
                        } else {
                            if(!/^\d{10}$/.test(this.telefono)){
                                this.telefonoError = 'Ingrese un número de teléfono válido (10 dígitos).';
                                this.mostrarErrorSweetAlert(this.telefonoError);
                            } else {
                                return true
                            }
                        }
                    }
                }
                return false;
            },
            validaDatosCarrera() {
                if ( !this.selectedCampus ) {
                    this.mostrarErrorSweetAlert('Por favor elige el campus de interés.' );
                } else {
                    if ( !this.selectedStudyType ) {
                        this.mostrarErrorSweetAlert('Por favor elige un nivel de estudio.');
                    }  else {
                        if(!this.selectedOffer){
                            this.mostrarErrorSweetAlert('Por favor elige una oferta educativa.');
                        } else {
                            if ( !this.selectedModel ) {
                            this.mostrarErrorSweetAlert('Por favor elige un modelo académico.');
                            }  else {
                                return true;
                            }
                        }
                    }
                }
                return false;
            },
            nextStep() {
                if(this.currentStep === 1){
                    this.validarDatos()          
                    if (
                        this.validarDatos()
                    ) {
                        this.currentStep += 1;
                    }
                } else if (this.currentStep === 2) {
                    this.validaDatosCarrera()          
                    if (
                        this.validaDatosCarrera()
                    ) {
                        this.currentStep += 1;
                    }
                }
            },
            prevStep() {
                this.currentStep -= 1;
            },
            handleEmailChange() {
                if (!this.tokenizer) {
                    this.executeRecaptcha();
                }
            },
            executeRecaptcha() {
                //@ts-ignore
                grecaptcha.enterprise.execute('6Ldd0x0oAAAAAAaPVjMnVirgyfT6qahpksCqN0UX', { action: 'submit_lead' })
                    .then((token: any) => {
                    this.tokenizer = token;
                    })
                    .catch((error: any) => {
                    console.error('Error al ejecutar reCAPTCHA:', error);
                    });
            },
            submitForm() {
                // Validar el formulario antes de enviar los datos
                if (this. validaDatosCarrera()&&this.validarDatos()) {
                    // Crear un objeto FormData con los datos del formulario
                    const formData = new FormData();
                    formData.append('nombre', this.nombre);
                    formData.append('apellidos', this.apellidos);
                    formData.append('email', this.email);
                    formData.append('telefono', this.telefono);
                    formData.append('nivel', this.selectedStudyType);
                    formData.append('campus', this.selectedCampus);
                    formData.append('modelo', this.selectedModel);
                    formData.append('carrera', this.clave);
                    formData.append('g-recaptcha-response',this.tokenizer)
                    formData.append('mensaje', this.comentario)
                    formData.append('url', this.currentUrl())
                    /* Realizar la solicitud POST
                    let peticion = {
                        method: 'POST',
                        body: formData,
                    };*/
                    Swal.fire({
                        title: 'ENVIANDO...',
                        text: 'ESTAMOS ENVIANDO SUS DATOS. NO CIERRE LA VENTANA',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        showConfirmButton: false,
                        didOpen: () => {
                            // @ts-ignore
                            Swal.showLoading();
                            axios.post(Solicitud2, formData, {
                            // axios.post(Solicitud, formData, {
                                headers: {'Content-Type': 'multipart/form-data',},
                                responseType: 'json', // Asegúrate de incluir esta línea
                            })
                                .then((response: { data: { error: any; descripcion: any; }; }) => {
                                        // if (response.data.error === false) {
                                            //@ts-ignore
                                            console.log(response);
                                            // if (dataLayer) {
                                            //     //@ts-ignore
                                            //     dataLayer.push({
                                            //     event: 'generate_lead',
                                            //     email: this.email,
                                            //     career: this.selectedOffer,
                                            //     });
                                            // }
                                            Swal.fire({
                                                title: '¡ENVIADO!',
                                                text: 'NUESTROS EJECUTIVOS SE PONDRÁN EN CONTACTO CONTIGO LO ANTES POSIBLE.',
                                                icon: 'success',
                                                iconColor: '#CAAB55',
                                                showConfirmButton: false,
                                                timer: 4000
                                            });
                                            this.nombre = '';
                                            this.apellidos = '';
                                            this.email = '';
                                            this.telefono = '';
                                            this.tokenizer = '';
                                            this.selectedStudyType= "";
                                            this.selectedOffer = "";
                                            this.selectedModel = "";
                                            this.studyTypeList = [];
                                            this.offerList = [];
                                            this.modelList = [];
                                            this.prevStep()
                                            this.prevStep()
                                        // } else {
                                        //     Swal.fire({
                                        //         text: `${response.data.descripcion}`,
                                        //         icon: "error",
                                        //         iconColor: '#881912',
                                        //         showConfirmButton: false,
                                        //         timer: 4000
                                        //     });
                                        //     this.tokenizer='';
                                        // }
                                }).catch( (error: any) => console.log('error', error) );
                        }
                    });
                }
            },
        },
    });
</script>

<template>
    <div class="flex flex-col mt-[88px] lg:mt-[143px] bg-white w-full items-center justify-center">
        <!-- Banner -->
        <section class="w-full flex flex-col items-center justify-center px-3">
            <img src="../pages/pagesImages/descuento-long.jpg" alt="Descuento banner" class="mb-12 w-[80%] hidden lg:block">
            <div class="aspect-square max-w-[500px] max-h-[500px] mb-12">
                <img src="../pages/pagesImages/descuento-square.jpg" alt="Descuento square" class="w-full lg:hidden object-cover">
            </div>
            <div class="flex flex-col w-full mb-8">
                <p class="text-gray-500 text-[28px]">Escr&iacute;benos un mensaje</p>
                <p class="text-hgold font-serif text-[32px] md:text-[42px]">Pronto nos pondremos en contacto contigo.</p>
            </div>
        </section>
        <!-- Div del formulrio -->
        <div class="flex flex-col w-full justify-center items-center">
            <div class="lg:flex items-center justify-center border bg-white p-16 mb-12 max-w-4xl lg:max-w-5xl w-full border-gray-200">
                <div class="login-box form-container border border-black w-full lg:m-20 ml-0 relative z-10">
                    <form id="contactForm" @submit.prevent="" class="">
                        <div v-if="currentStep === 1">
                            <div class="user-box mb-2 lg:mr-2">
                                <i class="fas fa-user" ></i>
                                <input type="text" name="nombre" id="nombre" v-model="nombre" required maxlength="30" class="ml-[25px]">
                                <label class="ml-[25px]">Nombre</label>
                            </div>
                            <div class="user-box mb-2 mr-2">
                                <i class="fas fa-user pr-2"></i>
                                <input type="text" name="apellidos" id="apellidos" v-model="apellidos" required maxlength="55" class="ml-[25px]">
                                <label class="ml-[25px]">Apellidos</label>
                            </div>
                            <div class="user-box mb-2 mr-2">
                                <i class="fas fa-envelope"></i>
                                <input type="email" name="email" id="email" v-model="email" @change="handleEmailChange" required class="ml-[25px]">
                                <label class="ml-[25px]">Email</label>
                            </div>
                            <div class="user-box mb-2 mr-2">
                                <i class="fas fa-phone"></i>
                                <input type="tel" name="telefono" id="telefono" v-model="telefono" required maxlength="10" class="ml-[25px]">
                                <label class="ml-[25px]">Tel&eacute;fono</label>
                            </div>
                            <button @click.prevent="nextStep" class="bg-hwine focus:outline-none rounded-none text-white hover:bg-hgold hover:text-white">Siguiente</button>
                        </div>

                            <!-- Segunda parte del formulario -->
                        <div v-if="currentStep === 2">
                            <h2>Informaci&oacute;n Acad&eacute;mica</h2>
                            <div class="user-box mb-2 lg:mr-2">
                                <i class="fas fa-map-marker-alt"></i>
                                <select name="campus" id="campus" v-model="selectedCampus" @change="updateOptions('studyType')" required class="ml-[25px]">
                                <!-- Opciones del select para campus -->
                                    <option class="text-[#797979]" value="">Selecciona una opci&oacute;n</option>
                                    <option class="text-[#797979]" value="cancun">Canc&uacute;n</option>
                                    <option class="text-[#797979]" value="del-valle">Colonia Del Valle CDMX</option>
                                    <!-- <option value="cuernavaca">Cuernavaca</option> -->
                                    <option class="text-[#797979]" value="guadalajara">Guadalajara</option>
                                    <option class="text-[#797979]" value="los-reyes">Los Reyes la Paz</option>
                                    <option class="text-[#797979]" value="merida">M&eacute;rida</option>
                                    <option class="text-[#797979]" value="presa-madin">Presa Mad&iacute;n</option>
                                    <option class="text-[#797979]" value="queretaro">Quer&eacute;taro</option>
                                    <option class="text-[#797979]" value="santa-fe">Santa Fe CDMX</option>
                                    <option class="text-[#797979]" value="tijuana">Tijuana</option>
                                    <option class="text-[#797979]" value="virtual-mex">Virtual</option>
                                </select>
                                <label class="ml-[25px]" style="top: -20px;left: 0;color: #CAAB55;font-size: 12px;">Campus de Inter&eacute;s</label>
                            </div>
                            <div class="user-box mb-2 mr-2">
                                <i class="fas fa-graduation-cap"></i>
                                <select
                                    name="nivelEducativo"
                                    id="nivelEducativo"
                                    v-model="selectedStudyType"
                                    @change="updateOptions('offer')"
                                    required
                                    class="ml-[25px]"
                                >
                                    <option class="text-[#797979]" value="" disabled>Selecciona una opci&oacute;n</option>
                                    <option class="text-[#797979]" v-for="studyType in studyTypeList" :key="studyType" :value="studyType">
                                    {{ studyType }}
                                    </option>
                                </select>
                                <label class="ml-[25px]" style="top: -20px;left: 0;color: #CAAB55;font-size: 12px;">Nivel Educativo</label>
                            </div>

                            <div class="user-box mb-2 mr-2">
                                <i class="fas fa-book"></i>
                                <select
                                    type="text"
                                    name="ofertaEducativa"
                                    id="ofertaEducativa"
                                    v-model="selectedOffer"
                                    @change="updateOptions('model')"
                                    required
                                    class="ml-[25px]"
                                >
                                    <option class="text-[#797979]" value="" disabled>Selecciona una opci&oacute;n</option>
                                    <option class="text-[#797979]" v-for="offer in offerList" :key="offer" :value="offer">
                                    {{ offer }}
                                    </option>
                                </select>
                                <label class="ml-[25px]" style="top: -20px;left: 0;color: #CAAB55;font-size: 12px;">Oferta Educativa</label>
                            </div>

                            <div class="user-box mb-2 mr-2">
                                <i class="fas fa-cogs"></i>
                                <select
                                    name="modeloEducativo"
                                    id="modeloEducativo"
                                    v-model="selectedModel"
                                    @change="updateOptions('clv')"
                                    required
                                    class="ml-[25px]"
                                >
                                    <option class="text-[#797979]" value="" disabled>Selecciona una opci&oacute;n</option>
                                    <option class="text-[#797979]" v-for="model in modelList" :key="model" :value="model">
                                    {{ model }}
                                    </option>
                                </select>
                                <label
                                    class="ml-[25px]"
                                    style="top: -20px;left: 0;color: #CAAB55;font-size: 12px;">Modelo Educativo</label>
                            </div>
                            <button @click="prevStep" class="bg-hwine focus:outline-none text-white rounded-none m-4 hover:bg-hgold hover:text-white">Anterior</button>
                            <button @click="nextStep" class="bg-hwine focus:outline-none text-white rounded-none m-4 hover:bg-hgold hover:text-white">Siguiente</button>
                        </div>

                        <!-- Tercera parte del formulario -->
                        <div v-if="currentStep === 3">
                            <h2>Comentarios (Opcional)</h2>
                            <div class="user-box mr-2 mb-2">
                                <i class="fas fa-comment"></i>
                                <textarea name="mensaje" id="mensaje" v-model="comentario" rows="5" class="ml-[25px]"></textarea>
                                <label class="ml-[25px]" style="top: -20px;left: 0;color: #CAAB55;font-size: 12px;">Mensaje (Opcional)</label>
                            </div>
                            <button @click="prevStep" class="bg-hwine focus:outline-none text-white rounded-none m-4 hover:bg-hgold hover:text-white">Anterior</button>
                            <button @click="submitForm" class="bg-hwine focus:outline-none text-white rounded-none m-4 hover:bg-hgold hover:text-white">¡Quiero Saber M&aacute;s!</button>
                        </div>
                    </form>
                </div>
                <div class="bg-[#F3F2F2] border md:ml-16 w-full border-gray-300 flex flex-col justify-center">
                    <p class="text-[22px] mt-4 text-gray-700" style="letter-spacing: 20px;">C&oacute;digo</p>
                    <p class="lg:text-[48px] text-[32px] font-serif text-hwine">Humanitas</p>
                    <p class="text-sm text-left mr-8 ml-4 mb-4">
                        1. Vivo y act&uacute;o con respeto.<br><br>
                        2. Soy congruente en pensamiento, acci&oacute;n y palabra.<br><br>
                        3. Todas mis actividades las realizo con orgullo, pasi&oacute;n y responsabilidad.<br><br>
                        4. Encuentro mi camino hacia la superaci&oacute;n en la educaci&oacute;n.<br><br>
                        5. Los obst&aacute;culos me permiten caminar cada vez m&aacute;s firme.<br><br>
                        6. Lo imposible lo convierto en posible.<br><br>
                        7. S&eacute; que mis acciones de hoy determinar&aacute;n el ma&ntilde;ana.<br><br>
                        8. Llegar al &eacute;xito y concretar mis sue&ntilde;os depende de mi actitud.<br><br>
                    </p>
                    <div class="flex justify-center mx-auto w-[60%] mb-4">
                        <div class="flex justify-start">
                            <a href="https://api.whatsapp.com/send?phone=5215611422526" target="_blank">
                                <img src="/pagesImages/whats-button.png" alt="Contacto vía WhastsApp" class="">
                            </a>
                        </div>
                    </div>  
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .login-box {
        margin: 0 1px;
        padding: 40px;
        box-sizing: border-box;
        border: black;
        border-radius: 0px;
        
        z-index: 1;
    }

    .login-box h2 {
        margin: 0 0 30px;
        padding: 0;
        color: #fff;
        text-align: center;
    }

    .login-box .user-box {
        position: relative;
        z-index: 1;
    }

    .login-box .user-box i {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 10px;
        color: #CAAB55;
    }

    .form-container {
        position: relative;
        z-index: 1;
    }

    .login-box .user-box input,
    .login-box .user-box select,
    .login-box .user-box textarea {
        width: 100%;
        padding: 10px 10px; /* Ajusta según tu preferencia */
        font-size: 16px;
        color: #797979;
        margin-bottom: 30px;
        border: none;
        border-bottom: 1px solid #CAAB55;
        outline: none;
        background: transparent;
    }

    .login-box .user-box label {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        padding: 10px 0;
        font-size: 16px;
        color: black;
        pointer-events: none;
        transition: .5s;
    }

    .login-box .user-box input:focus ~ label,
    .login-box .user-box input:valid ~ label,
    .login-box .user-box select:focus ~ label,
    .login-box .user-box textarea:focus ~ label {
        top: -25px;
        left: 0;
        color: #CAAB55;
        font-size: 12px;
    }

    .custom-button {
        display: inline-block;
        padding: 10px 20px;
        color: white;
        z-index: 1;
        font-size: 16px;
        text-decoration: none;
        text-transform: uppercase;
        margin-top: 40px;
        letter-spacing: 4px;
        background: #881912;
        border: 1px solid #881912;
        border-radius: 5px;
        color: white;
        transition: background-color 0.3s, color 0.3s;
    }

    .custom-button:hover {
        background-color: #CAAB55;
        color: #fff;
    }

    .login-box .user-box input.error-border,
    .login-box .user-box select.error-border,
    .login-box .user-box textarea.error-border {
        border-bottom: 1px solid #FF0000 !important;
    }

    .login-box .user-box label.error{
        color: #FF0000;
        font-size: 12px;
    }
</style>