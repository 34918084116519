<script lang="ts">
    import PortadaInd from '../components/PortadaInd.vue';
	import NavbarInd from '../components/NavbarInd.vue';
	import FooterInd from '../components/FooterInd.vue';
    import { defineComponent } from 'vue';
    import PersonaHumanitas from './components/PersonaHumanitas.vue';
    export default defineComponent({
        components: { PortadaInd, NavbarInd, FooterInd, PersonaHumanitas  },
        data() {
            return {
                activeButton: null as string | null,
                showImage: true,
                usuario: '',
                contrasena: '',
                folio: '',
                usuarioError: '',
                contrasenaError: '',
                folioError: '',
                videoUrl: "https://www.youtube.com/embed/kM6yarkMYr8?si=b8-0nC3xH0j-7yEn",
            };
        },
        methods: {
            showVideo() {
                this.showImage = false;
            },
            submitAcceso() {},
            submitConsta() {}
        },
    });
</script>

<template>
    <div class="flex flex-col w-full items-center mx-auto pt-[88px] lg:pt-[143px] justify-center">
        <div class="flex flex-col justify-center w-full md:w-[40%] mb-4 items-center ">
            <div class="flex items-center justify-center flex-col my-16 px-8 md:px-0 w-full max-w-[420px]">
                <label class="mt-4 mb-1 text-black text-2xl">Acceso a usuarios</label>
                <section class="login-box form-container border w-full border-black relative z-10">
                    <form id="accesoForm" action="https://www.mi-escuelamx.com/Humanitas/ValidaUsuarioHumanitas.asp" method="post">
                        <div>
                            <div class="user-box">
                                <i class="fas fa-user"></i>
                                <input type="text" name="txtUsuario" id="usuario" required maxlength="30" class="ml-[25px]">
                                <label class="ml-[25px]">Usuario</label>
                            </div>
                            <div class="user-box">
                                <i class="fas fa-lock pr-2"></i>
                                <input type="password" name="txtClave" id="contrasena" required maxlength="55" class="ml-[25px]">
                                <label class="ml-[25px]">Contrase&ntilde;a</label>
                            </div>
                            <div class="flex flex-col items-center justify-center">
                                <button @click="submitAcceso" class="bg-hwine rounded-none w-[220px] text-white m-4 hover:bg-hgold hover:text-white text-xl transition duration-300 ease-in-out">Ingresar</button>
                                <a class=" text-xs text-gray-500" href="https://www.mi-escuelamx.com/HUMANITAS/recuperacion_contrasena_humanitas.asp" target="_blank">
                                    <strong>&iquest;Olvidaste tu Contrase&ntilde;a&quest;</strong>
                                </a>
                            </div>
                        </div>
                    </form>
                </section>
            </div>

            <div class="mb-16 flex justify-center items-center px-8 md:px-0 flex-col w-full max-w-[420px]">
                <label class="mt-4 mb-1 text-black text-2xl">Verificaci&oacute;n de constancias</label>
                <section class="login-box form-container border w-full border-black relative z-10">
                    <form id="constaForm" action="https://www.mi-escuelamx.com/humanitas/ValidaConstancias.asp" method="post">
                        <div class="user-box mb-5">
                            <i class="fas fa-user" ></i>
                            <input type="text" name="txtFolio" id="numero" required maxlength="30" class="ml-[25px]">
                            <label class="ml-[25px]">Folio</label>
                        </div>
                        <div class="user-box">
                        <i class="fas fa-map-marker-alt"></i>
                        <!-- <select id="campus" name="cboCampus" class="ml-[25px] rounded-none text-[#797979]" required> -->
                        <select id="campus" name="cboCampus" class="ml-[25px] rounded-none text-[#ff4444]" required>
                            <option class="text-[#797979]">Selecciona una opci&oacute;n</option>
                            <option class="text-[#797979]" value="CANCUN">Canc&uacute;n</option>
                            <option class="text-[#797979]" value="DF">Colonia Del Valle CDMX</option>
                            <option class="text-[#797979]" value="CUERNAVACA">Cuernavaca</option>
                            <option class="text-[#797979]" value="GDL">Guadalajara</option>
                            <option class="text-[#797979]" value="REYES">Los Reyes la Paz</option>
                            <option class="text-[#797979]" value="MERIDA">M&eacute;rida</option>
                            <option class="text-[#797979]" value="PRESAMADIN">Presa Mad&iacute;n</option>
                            <option class="text-[#797979]" value="QUERETARO">Quer&eacute;taro</option>
                            <option class="text-[#797979]" value="SANTAFE">Santa Fe CDMX</option>
                            <option class="text-[#797979]" value="TIJUANA">Tijuana</option>
                            <option class="text-[#797979]" value="VIRTUAL">Virtual</option>
                        </select>
                        <label class="ml-[25px]" style="top: -25px;left: 0;color: #CAAB55;font-size: 12px;">Campus</label>
                    </div>
                    <button @click="submitConsta" class="bg-hwine rounded-none w-[220px] text-white m-4 hover:bg-hgold hover:text-white text-xl transition duration-300 ease-in-out">Verificar</button>
                    </form>
                </section>
            </div>
        </div>
        
        <PersonaHumanitas />
    </div>
</template>

<style scoped>
    .login-box {
        max-width: 1800px;
        margin: 0 1px;
        box-sizing: border-box;
        border: #797979;
        border-radius: 10px;
        background: transparent;
        z-index: 1;
        margin: 20px;
        margin-right: 45px;
    }

    .login-box h2 {
        margin: 0 0 30px;
        padding: 0;
        color: transparent;
        text-align: center;
    }

    .login-box .user-box {
        position: relative;
        z-index: 1;
    }

    .login-box .user-box i {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 10px;
        color: #881912;
    }

    .form-container {
        position: relative;
        z-index: 1;
    }

    .login-box .user-box input,
    .login-box .user-box select,
    .login-box .user-box textarea {
        width: 100%;
        padding: 10px 10px; /* Ajusta según tu preferencia */
        font-size: 16px;
        color: #797979;
        margin-bottom: 30px;
        border: none;
        border-bottom: 1px solid #797979;
        outline: none;
        background: transparent;
    }

    .login-box .user-box label {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        padding: 10px 0;
        font-size: 16px;
        color: black;
        pointer-events: none;
        transition: .5s;
    }

    .login-box .user-box input.error-border,
    .login-box .user-box select.error-border,
    .login-box .user-box textarea.error-border {
        border-bottom: 1px solid #881912 !important;
    }

    .login-box .user-box label.error{
        color: #881912;
        font-size: 12px;
    }

    input:focus:not(:placeholder-shown) {
        background-color: transparent; 
    }

    .login-box .user-box input:focus ~ label,
    .login-box .user-box input:valid ~ label,
    .login-box .user-box select:focus ~ label,
    .login-box .user-box textarea:focus ~ label {
        top: -25px;
        left: 0;
        color: #CAAB55;
        font-size: 12px;
    }
</style>