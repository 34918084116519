<template>
    <!-- <div class="fixed top-0 left-0  mb-1 w-full bg-white p-4 flex justify-center z-50">
		<div class="flex-shrink-0 justify-center">
			<router-link to="/">
				<img src="./compImages/logo-head.png" alt="Logo"/>
			</router-link>
		</div>
    </div>

    <div class="fixed top-7 right-6 justify-center items-center space-x-4 z-50">
        <RouterLink to="/contacto"><button class="bg-[#520400] text-white hover:bg-[#A69A68] hover:text-[#520400]">Contacto</button></RouterLink>
        <RouterLink to="/acceso"><button class="bg-[#520400] text-white hover:bg-[#A69A68] hover:text-[#520400]">Acceso</button></RouterLink>
	</div> -->


	<div class="flex relative justify-center max-w-4xl lg:max-w-6xl">
		<div class="w-full">
			<router-link to="/">
				<img src="./compImages/logo-head.png" alt="Logo"/>
			</router-link>
		</div>
		<div class="fixed top-7 right- justify-center items-center space-x-4 z-50">
			<RouterLink to="/contacto">
				<button class="bg-[#520400] text-white hover:bg-[#A69A68] hover:text-[#520400]">
					Contacto
				</button>
			</RouterLink>
        	<RouterLink to="/acceso">
				<button class="bg-[#520400] text-white hover:bg-[#A69A68] hover:text-[#520400]">
					Acceso
				</button>
			</RouterLink>
		</div>
	</div>
</template>

