<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">Descubre las mejores opciones de<br class="md:block hidden"> universidades con doctorado en México</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-200">
          <img src="/images/doctorado-humanitas-para-alcanzar-tus-metas.webp" alt="Elegir una universidad para estudiar el doctorado es fundamental, así que escoge la mejor opción." class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/academico" class=" text-hone">Académico</router-link> > <router-link to="/blog/academico/doctorado" class=" text-hone">Doctorado</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex list-disc pl-5 italic text-left text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">25 de junio de 2024</li>  <li>Carolina Reyes Martínez</li></ul>
            </div>
            <p class=" md:mb-4 mb-6">Realizar un doctorado es una meta ambiciosa que puede abrir puertas a una carrera académica o profesional de alto nivel.</p>
            <p class=" md:mb-8 mb-10">En México, muchas universidades ofrecen programas de doctorado en diversas disciplinas. Este artículo te puede orientar a elegir la que mejor se adapte a tus necesidades y objetivos. Sigue leyendo.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Tipos de doctorado en México</h2>
            <ul class="list-disc pl-6 md:mb-8 mb-10">
                <li><b>Doctorado en investigación:</b> Se enfoca en <b>crear nuevo conocimiento en temas específicos y en desarrollar proyectos de investigación</b> para una carrera académica. Estos programas requieren la realización de una tesis basada en investigación original y <b>pueden durar entre 3 y 5 años.</b><br>Algunos ejemplos de este tipo de investigación científica incluyen el Doctorado en Ciencias Sociales de la Universidad Nacional Autónoma de México (UNAM) y el Doctorado en Biotecnología del Instituto Politécnico Nacional (IPN).</li>
                <li><b>Doctorado profesional:</b> Está <b>centrado en la aplicación práctica del conocimiento y en la solución de problemas específicos</b> en diversas industrias para obtener el grado académico. Estos programas combinan formación teórica y práctica, y son ideales para quienes buscan avanzar en su carrera profesional. <br>Por ejemplo: Doctorado en Administración de Negocios de la Universidad Anáhuac, el Doctorado en Derecho de la Universidad Iberoamericana, <router-link to="/doctorado-en-alta-direccion" target="_blank" class=" text-htwo hover:text-hone">Doctorado en Alta Dirección</router-link> y <router-link to="/doctorado-en-derecho" target="_blank" class=" text-htwo hover:text-hone">Doctorado en Derecho</router-link> de Universidad Humanitas.</li>
                <li><b>Doctorado directo:</b> Éste es para estudiantes altamente capacitados que pueden ingresar a un programa de doctorado <b>sin necesidad de completar primero una maestría.</b> Permite ahorrar tiempo y es ideal para estudiantes con una licenciatura destacada. Algunas universidades en México que ofrecen esta opción incluyen:
                    <ul class="list-disc pl-12">
                        <li><b>Universidad Nacional Autónoma de México:</b> Ofrece el Doctorado Directo en Ciencias Biomédicas y el Doctorado Directo en Ciencias Físicas.</li>
                        <li><b>Instituto Politécnico Nacional:</b> Cuenta con el Doctorado Directo en Ciencias Biológicas y de la Salud.</li>
                        <li><b>Universidad de Guadalajara (UDG):</b> Proporciona el Doctorado Directo en Ciencias Exactas e Ingenierías.</li>
                    </ul>
                </li>
            </ul>
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
                <ContactoVue class="m-3"/>
            </div>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Ejemplos de doctorados en México</h3>
            <p class="md:mb-4 mb-6">Los doctorados disponibles en México abarcan una amplia gama de disciplinas. Aquí algunos ejemplos destacados:</p>
            <ul class="list-disc pl-6 md:mb-8 mb-10">
                <li><b>Doctorado en Ciencias Sociales y Humanidades:</b> Este tipo de doctorado abarca áreas como Sociología, Antropología, Historia, y Literatura. La UNAM y el Colegio de México son dos instituciones destacadas en estas áreas.</li>
                <li><b>Doctorado en Ciencias de la Salud:</b> Incluye programas en Medicina, Biomedicina, Psicología, y Enfermería. La UNAM y el Instituto Politécnico Nacional ofrecen programas muy completos en estas disciplinas.</li>
                <li><b>Doctorado en Ingeniería y Tecnología:</b> Para aquellos interesados en áreas como Ingeniería Civil, Ingeniería Eléctrica, y Tecnologías de la Información. El Tecnológico de Monterrey y la Universidad Autónoma de Nuevo León son referentes en estos campos.</li>
                <li><b>Doctorado en Educación:</b> Ideal para profesionales que desean investigar y mejorar prácticas educativas. La Universidad Anáhuac, la Universidad Iberoamericana y <router-link to="/doctorado-en-educacion" target="_blank" class=" text-htwo hover:text-hone">Universidad Humanitas</router-link> ofrecen programas de doctorado en esta área.</li>
            </ul>
            <img src="/images/doctorados-cortos-humanitas.webp" alt="Los doctorados cortos son para los profesionales que buscan equilibrar sus estudios y trabajo" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Doctorados cortos: Flexibilidad y eficiencia</h2>
            <p class="md:mb-4 mb-6">Los doctorados cortos <b>son programas diseñados para ser completados en un periodo de tiempo más reducido,</b> sin comprometer la calidad de la formación.</p>
            <p class="md:mb-4 mb-6">Estos programas suelen ser más intensivos y están orientados a profesionales que buscan equilibrar sus estudios con otras responsabilidades. Algunas universidades que ofrecen doctorados cortos son:</p>
            <ul class="list-disc pl-6 md:mb-8 mb-10">
                <li><b>Tecnológico de Monterrey:</b> Ofrece programas de doctorado en áreas como Administración y Dirección de Empresas, con opciones para completar en tres años.</li>
                <li><b>Universidad Anáhuac:</b> Proporciona doctorados en Educación y Derecho, diseñados para ser completados en periodos más cortos.</li>
                <li><b>Universidad Iberoamericana:</b> Cuenta con programas en Ciencias Sociales y Humanidades que pueden completarse en menos tiempo.</li>
                <li><b><router-link to="/" target="_blank" class=" text-htwo hover:text-hone">Universidad Humanitas</router-link>:</b> Es una opción que también puedes considerar, ya que los doctorados tienen una duración de cuatro bloques semestrales. Cada bloque equivale a tres materias de su inscripción e impartido por un especialista. <br> Se imparten de cuatro a seis horas de manera presencial, dependiendo de la complejidad del tema. Son 22 semanas de clase y una semana de evaluación final. La participación en clase, foros, trabajos y examen conforman la totalidad de la calificación.</li>
            </ul>
            <img src="/images/doctorados-diversas-disciplinas.webp" alt="Los doctorados abarcan una amplia gama de disciplinas para que alcances tus objetivos profesionales" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Conclusión</h2>
            <p class="md:mb-4 mb-6"><b>Elegir la universidad adecuada para tu doctorado es un paso fundamental en tu carrera académica.</b> Las universidades mencionadas en este ranking son excelentes opciones que ofrecen programas de alta calidad en diversas disciplinas.</p>
            <p class="mb-20">Hay muchas alternativas educativas de alta calidad disponibles. Puedes optar por obtener un doctorado o un programa más corto. Estas opciones te pueden ayudar a alcanzar tus metas profesionales.</p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArtVue :ant="true" :linkAnt="'/academico/licenciatura/licenciatura-en-educacion-y-administracion-de-centros-educativos'" :linkSig="'/academico/licenciatura/mejores-universidades-para-estudiar-psicologia-en-mexico'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import ContactoVue from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue'
import SigArtVue from '../../pagesComponents/SigArt.vue';


export default {
    components: { Comentarios, ConComentarios, ContactoVue, LikesVue, ShareP, SigArtVue},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '0047-ART-ACADEMICO-DOCTORADO-240624',
            ruta: '/academico/doctorado/universidades-con-doctorado-en-mexico-opciones',
            title: 'Descubre las mejores opciones de universidades con doctorado en México',
            image: 'https://blog.humanitas.edu.mx/images/Todo-sobre-el-doctorado-guia.jpg',
            text: 'Explora estas alternativas de las mejores universidades para estudiar un doctorado en México.'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx'+this.ruta;

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>