import art_005 from '../../pages/Blog/Academico/Licenciatura/05-ART-ACADEMICO-LICENCIATURA-060224.vue'
import art_010 from '../../pages/Blog/Academico/Licenciatura/10-GUIA-ACADEMICO-LICENCIATURA-010324.vue'
import art_014 from '../../pages/Blog/Academico/Licenciatura/14-ART-ACADEMICO-LICENCIATURA-270324.vue'
import art_018 from '../../pages/Blog/Academico/Licenciatura/018-ART-ACADEMICO-LICENCIATURA-260424.vue'
import art_020 from '../../pages/Blog/Academico/Licenciatura/020-ART-ACADEMICO-LICENCIATURA-290424.vue'
import art_021 from '../../pages/Blog/Academico/Licenciatura/021-ART-ACADEMICO-LICENCIATURA-070524.vue'
import art_0040 from '../../pages/Blog/Academico/Licenciatura/0040-ART-LICENCIATURA-CONTABILIDAD-060624.vue'
import art_0039 from '../../pages/Blog/Academico/Licenciatura/0039-ART-ACADEMICO-LICENCIATURA-060624.vue'
import art_0043 from '../../pages/Blog/Academico/Licenciatura/0043-ART-ACADEMICO-LICENCIATURA-130624.vue'
import art_0046 from '../../pages/Blog/Academico/Licenciatura/0046-ART-ACADEMICO-LICENCIATURA-180624.vue'
import art_0048 from '../../pages/Blog/Academico/Licenciatura/0048-ART-ACADEMICO-LICENCIATURA-200624.vue'
import art_0049 from '../../pages/Blog/Academico/Licenciatura/0049-ART-ACADEMICO-LICENCIATURA-250624.vue'


export default [
  {
    path: '/blog/academico/licenciatura/mejores-universidades-para-estudiar-psicologia-en-mexico',
    name: 'Identifica las mejores universidades para estudiar psicología en México',
    meta: {
      title: "Identifica las mejores universidades para estudiar psicología en México",
      description: "Reconoce las mejores universidades para estudiar psicología en México tomando en cuenta aspectos como validez educativa, infraestructura y planes de estudio.",
      image: "https://humanitas.edu.mx/images/meta/mejores-universidades-estudiar-psicologia-mexico.webp",
      keywords: "Mejores universidades para estudiar psicología en México, donde estudiar psicologia, universidades que tengan la carrera de psicología, psicología universidades, universidades para estudiar psicología, mejores escuelas de psicologia en mexico",
      metaTags:
      {
        "og:title": "Identifica las mejores universidades para estudiar psicología en México",
        "og:description": "Reconoce las mejores universidades para estudiar psicología en México tomando en cuenta aspectos como validez educativa, infraestructura y planes de estudio.",
        "og:image": "https://humanitas.edu.mx/images/meta/mejores-universidades-estudiar-psicologia-mexico.webp",
        "og:keywords": "Mejores universidades para estudiar psicología en México, donde estudiar psicologia, universidades que tengan la carrera de psicología, psicología universidades, universidades para estudiar psicología, mejores escuelas de psicologia en mexico",
      },
      requireAuth: false,
    },
    component: art_0049
  },
  {
    path: '/blog/academico/licenciatura/licenciatura-en-educacion-y-administracion-de-centros-educativos',
    name: 'Licenciatura en Educación y Administración de centros educativos: ¿Qué es?',
    meta: {
      title: "Licenciatura en Educación y Administración de centros educativos: ¿Qué es?",
      description: "La licenciatura en Educación y Administración de centros educativos combina conocimientos de pedagogía, gestión administrativa y liderazgo educativo.",
      image: "https://humanitas.edu.mx/images/meta/arquitectura-arte-de-disenar-y-construir.webp",
      keywords: "Carrera de educación, universidades con licenciatura en educación, licenciatura en administración educativa, licenciatura en educación de que se trata, instituciones educativas de administracion en mexico",
      metaTags:
      {
        "og:title": "Licenciatura en Educación y Administración de centros educativos: ¿Qué es?",
        "og:description": "La licenciatura en Educación y Administración de centros educativos combina conocimientos de pedagogía, gestión administrativa y liderazgo educativo.",
        "og:image": "https://humanitas.edu.mx/images/meta/arquitectura-arte-de-disenar-y-construir.webp",
        "og:keywords": "Carrera de educación, universidades con licenciatura en educación, licenciatura en administración educativa, licenciatura en educación de que se trata, instituciones educativas de administracion en mexico",
      },
      requireAuth: false,
    },
    component: art_0048
  },
  {
    path: '/blog/academico/licenciatura/arquitectura-licenciatura-o-ingenieria-te-decimos',
    name: 'Arquitectura: ¿Licenciatura o ingeniería? Aquí te decimos',
    meta: {
      title: "Arquitectura: ¿Licenciatura o ingeniería? Aquí te decimos",
      description: "Conoce si la arquitectura es licenciatura o ingeniería, qué estudia y su diferencia con la ingeniería civil.",
      image: "https://humanitas.edu.mx/images/meta/arquitectura-arte-de-disenar-y-construir.webp",
      keywords: "arquitectura es licenciatura o ingeniería, campo laboral de la arquitectura, que estudia la arquitectura, que es la carrera de arquitectura",
      metaTags:
      {
        "og:title": "Arquitectura: ¿Licenciatura o ingeniería? Aquí te decimos",
        "og:description": "Conoce si la arquitectura es licenciatura o ingeniería, qué estudia y su diferencia con la ingeniería civil.",
        "og:image": "https://humanitas.edu.mx/images/meta/arquitectura-arte-de-disenar-y-construir.webp",
        "og:keywords": "arquitectura es licenciatura o ingeniería, campo laboral de la arquitectura, que estudia la arquitectura, que es la carrera de arquitectura",
      },
      requireAuth: false,
    },
    component: art_0046
  },
  {
    path: '/blog/academico/licenciatura/principales-corrientes-teorias-psicologicas-modelos-y-representantes',
    name: 'Descubre cuáles son las principales corrientes y teorías psicológicas',
    meta: {
      title: "Descubre cuáles son las principales corrientes y teorías psicológicas",
      description: "Exploramos las principales corrientes y teorías psicológicas, sus modelos y representantes destacados. Ideal para ti que quieres saber más.",
      image: "https://humanitas.edu.mx/images/meta/corrientes-psicologicas-y-principales-representantes.webp",
      keywords: "teorías psicológicas, modelos psicológicos, principales corrientes psicológicas, representantes de la psicología",
      metaTags:
      {
        "og:title": "Descubre cuáles son las principales corrientes y teorías psicológicas",
        "og:description": "Exploramos las principales corrientes y teorías psicológicas, sus modelos y representantes destacados. Ideal para ti que quieres saber más.",
        "og:image": "https://humanitas.edu.mx/images/meta/corrientes-psicologicas-y-principales-representantes.webp",
        "og:keywords": "teorías psicológicas, modelos psicológicos, principales corrientes psicológicas, representantes de la psicología",
      },
      requireAuth: false,
    },
    component: art_0043
  },
  {
    path: '/blog/academico/licenciatura/que-es-un-acto-administrativo',
    name: '¿Qué es un acto administrativo? Guía para entenderlo',
    meta: {
      title: "¿Qué es un acto administrativo? Guía para entenderlo",
      description: "Entender qué es un acto administrativo es muy importante, ya que es esencial para el funcionamiento de la administración pública e impacta en la vida de los ciudadanos.",
      image: "https://humanitas.edu.mx/images/meta/que-es-acto-administrativo-concepto-ejemplos.webp",
      keywords: "qué es un acto administrativo, el acto administrativo, concepto de acto administrativo, derecho administrativo",
      metaTags:
      {
        "og:title": "¿Qué es un acto administrativo? Guía para entenderlo",
        "og:description": "Entender qué es un acto administrativo es muy importante, ya que es esencial para el funcionamiento de la administración pública e impacta en la vida de los ciudadanos.",
        "og:image": "https://humanitas.edu.mx/images/meta/que-es-acto-administrativo-concepto-ejemplos.webp",
        "og:keywords": "qué es un acto administrativo, el acto administrativo, concepto de acto administrativo, derecho administrativo",
      },
      requireAuth: false,
    },
    component: art_0039
  },
  {
    path: '/blog/academico/licenciatura/tipos-de-contabilidad',
    name: 'Tipos de Contabilidad que permiten una mejor planificación de capital',
    meta: {
      title: "Tipos de Contabilidad que permiten una mejor planificación de capital",
      description: "Reconocer los tipos de Contabilidad ayudan a la toma de decisiones informadas y mejorar la planificación de recursos.",
      image: "https://humanitas.edu.mx/images/meta/tipos-contabilidad-financiero.webp",
      keywords: "Tipos de contabilidad, Cuáles son los tipos de Contabilidad, clasificación de la contabilidad, ejemplo de contabilidad administrativa, contabilidad comercial, ejemplos de contabilidad",
      metaTags:
      {
        "og:title": "Tipos de Contabilidad que permiten una mejor planificación de capital",
        "og:description": "Reconocer los tipos de Contabilidad ayudan a la toma de decisiones informadas y mejorar la planificación de recursos.",
        "og:image": "https://humanitas.edu.mx/images/meta/tipos-contabilidad-financiero.webp",
        "og:keywords": "Tipos de contabilidad, Cuáles son los tipos de Contabilidad, clasificación de la contabilidad, ejemplo de contabilidad administrativa, contabilidad comercial, ejemplos de contabilidad",
      },
      requireAuth: false,
    },
    component: art_0040
  },
  {
    path: '/blog/academico/licenciatura/universidades-sin-examen-admision',
    name: 'Universidades sin examen de admisión: gratuitas',
    meta: {
      title: "Universidades sin examen de admisión: gratuitas",
      description: "Universidades sin examen de admisión privadas y públicas tienen un objetivo común: brindar continuidad de estudios.",
      image: "https://humanitas.edu.mx/images/meta/universidades-examen-admision.webp",
      keywords: "Universidades sin examen de admisión",
      metaTags:
      {
        "og:title": "Universidades sin examen de admisión: gratuitas",
        "og:description": "Universidades sin examen de admisión privadas y públicas tienen un objetivo común: brindar continuidad de estudios.",
        "og:image": "https://humanitas.edu.mx/images/meta/universidades-examen-admision.webp",
        "og:keywords": "Universidades sin examen de admisión",
      },
      requireAuth: false,
    },
    component: art_021
  },
  {
    path: '/blog/academico/licenciatura/tendencias-diseno-grafico-2024',
    name: 'Dominando el futuro: Descubre las tendencias clave del Diseño Gráfico para 2024',
    meta: {
      title: "Dominando el futuro: Descubre las tendencias clave del Diseño Gráfico para 2024",
      description: "Explora las principales tendencias de diseño gráfico para 2024. Desde la realidad aumentada hasta la sostenibilidad, descubre cómo influirán en tu carrera y proyectos creativos.",
      image: "https://blog.humanitas.edu.mx/images/meta/tendencia-diseno-grafico-2024-colores-fluorescentes-neon-humanitas.webp",
      keywords: "tendencias de diseño gráfico 2024, tendencias del diseño, tendencias diseño gráfico, estilos y tendencias en el diseño, diseño gráfico futuro",
      metaTags:
      {
        "og:title": "Dominando el futuro: Descubre las tendencias clave del Diseño Gráfico para 2024",
        "og:description": "Explora las principales tendencias de diseño gráfico para 2024. Desde la realidad aumentada hasta la sostenibilidad, descubre cómo influirán en tu carrera y proyectos creativos.",
        "og:image": "https://blog.humanitas.edu.mx/images/meta/tendencia-diseno-grafico-2024-colores-fluorescentes-neon-humanitas.webp",
        "og:keywords": "tendencias de diseño gráfico 2024, tendencias del diseño, tendencias diseño gráfico, estilos y tendencias en el diseño, diseño gráfico futuro",
      },
      requireAuth: false,
    },
    component: art_020
  },
  {
    path: '/blog/academico/licenciatura/aptitudes-habilidades-para-estudiar-derecho',
    name: 'Qué aptitudes y habilidades necesitas para estudiar Derecho.',
    meta: {
      title: "Qué aptitudes y habilidades necesitas para estudiar Derecho.",
      description: "Descubre las aptitudes y habilidades clave que necesitas para estudiar Derecho. Prepárate para enfrentar los desafíos de esta carrera. ¡Conócelas!",
      image: "https://blog.humanitas.edu.mx/images/meta/descubre-aptitudes-habilidades-clave-estudiar-derecho.jpg",
      keywords: "aptitudes necesarias para estudiar derecho, aptitudes para estudiar derecho, actitudes y aptitudes y valores para estudiar derecho, carrera de derecho",
      metaTags:
      {
        "og:title": "Qué aptitudes y habilidades necesitas para estudiar Derecho.",
        "og:description": "Descubre las aptitudes y habilidades clave que necesitas para estudiar Derecho. Prepárate para enfrentar los desafíos de esta carrera. ¡Conócelas!",
        "og:image": "https://blog.humanitas.edu.mx/images/meta/descubre-aptitudes-habilidades-clave-estudiar-derecho.jpg",
        "og:keywords": "aptitudes necesarias para estudiar derecho, aptitudes para estudiar derecho, actitudes y aptitudes y valores para estudiar derecho, carrera de derecho",
      },
      requireAuth: false,
    },
    component: art_018
  },
  {
    path: '/blog/academico/licenciatura/dia-mundial-del-teatro-2024',
    name: '27 de marzo Día Mundial del Teatro 2024: celebra la paz y el arte',
    meta: {
      title: "27 de marzo Día Mundial del Teatro 2024: celebra la paz y el arte",
      description: "Conoce la importancia y la belleza del Día Mundial del Teatro. Exploramos su historia, significado y cómo puedes unirte a este gran día, ¡participa!",
      image: "https://blog.humanitas.edu.mx/images/meta/27-marzo-2024-dia-mundial-teatro.jpg",
      metaTags:
      {
        "og:title": "27 de marzo Día Mundial del Teatro 2024: celebra la paz y el arte",
        "og:description": "Conoce la importancia y la belleza del Día Mundial del Teatro. Exploramos su historia, significado y cómo puedes unirte a este gran día, ¡participa!",
        "og:image": "https://blog.humanitas.edu.mx/images/meta/27-marzo-2024-dia-mundial-teatro.jpg",
      },
      requireAuth: false,
    },
    component: art_014
  },
  {
    path: '/blog/academico/licenciatura/mejores-universidades-derecho-mexico-guia',
    name: 'Guía de mejores universidades para estudiar Derecho en México: ¡infórmate!',
    meta: {
      title: "Guía de mejores universidades para estudiar Derecho en México: ¡infórmate!",
      description: "¿Quieres estudiar Derecho? Esta guía te ayudará a tomar una decisión informada. Conoce cuáles son las mejores opciones e inicia tu camino profesional.",
      image: "https://blog.humanitas.edu.mx/images/meta/Mejores-universidades-estudiar-derecho-Mexico-guía.jpg",
      metaTags:
      {
        "og:title": "Guía de mejores universidades para estudiar Derecho en México: ¡infórmate!",
        "og:description": "¿Quieres estudiar Derecho? Esta guía te ayudará a tomar una decisión informada. Conoce cuáles son las mejores opciones e inicia tu camino profesional.",
        "og:image": "https://blog.humanitas.edu.mx/images/meta/Mejores-universidades-estudiar-derecho-Mexico-guía.jpg",
      },
      requireAuth: false,
    },
    component: art_010
  },
  {
    path: '/blog/academico/licenciatura/beneficios-carreras-universitarias-cortas',
    name: 'Estudia una licenciatura en menor tiempo para impulsar tu éxito profesional.',
    meta: {
      title: "Estudia una licenciatura en menor tiempo para impulsar tu éxito profesional.",
      description: "Conoce los beneficios de las carreras universitarias cortas y cómo pueden ser una opción ideal si quieres iniciar tu vida laboral más rápido.",
      image: "https://blog.humanitas.edu.mx/images/meta/Carreras-cortas-beneficios-conocelos.jpg",
      metaTags:
      {
        "og:title": "Estudia una licenciatura en menor tiempo para impulsar tu éxito profesional.",
        "og:description": "Conoce los beneficios de las carreras universitarias cortas y cómo pueden ser una opción ideal si quieres iniciar tu vida laboral más rápido.",
        "og:image": "https://blog.humanitas.edu.mx/images/meta/Carreras-cortas-beneficios-conocelos.jpg",
      },
      requireAuth: false,
    },
    component: art_005
  },  
]