<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">Maximiza tu productividad al estilo <br class="md:block hidden"> de Bill Gates</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-200">
          <img src="/images/aumenta-tu-productividad-tecnica-trabajo-profundo-Bill-Gates.jpg" class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/aprende" class=" text-hone">Aprende</router-link> > <router-link to="/blog/aprende/tips" class=" text-hone">Tips</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex text-left list-disc pl-5 italic text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">02 de abril de 2024</li>  <li>Carolina Reyes Martínez/Redacción Humanitas</li></ul>
            </div>
            <p class=" md:mb-4 mb-6"><b>Extracto:</b> Imagina que Bill Gates te comparte su secreto para convertir cada día en una victoria de productividad. Se llama Trabajo profundo (Deep work). Prepárate para subir de nivel.</p>
            <p class=" md:mb-4 mb-6">La <b>concentración es la capacidad de mantener la atención en algo específico</b> o en una actividad. La atención precede a la concentración, pues selecciona lo que considera importante.</p>
            <p class=" md:mb-4 mb-6">Existen factores que pueden alterar la concentración como el exceso de trabajo, hacer una actividad repetitiva, el cansancio intelectual; un ambiente no adecuado para realizar tu trabajo o tarea y también los problemas personales.</p>
            <p class=" md:mb-4 mb-6">Para concentrarte, <b>además de poner atención, necesitas mover tus funciones cognitivas o procesos mentales</b> que permiten llevar a cabo las tareas. Éstas son: la orientación, la memoria, las gnosias, las funciones ejecutivas, las praxias, el lenguaje, la cognición social y las habilidades visoespaciales.</p>
            <p class=" md:mb-4 mb-6"><b>Subrayamos las funciones ejecutivas, ya que éstas son actividades complejas que te ayudan a planificar,</b> organizar, guiar, revisar, regularizar y evaluar el comportamiento para adaptarse a tu entorno y alcanzar los objetivos o metas.</p>
            <p class="mb-2">Estas funciones son:</p> 
            <ul class="list-disc pl-6 md:mb-4 mb-6">
                <li><b>La memoria de trabajo </b> que permite que gestiones la información adecuadamente.</li>
                <li><b>La planificación </b>que es la capacidad de generar objetivos, desarrollar planes de acción o pasos para obtenerlos y elegir los más adecuados.</li>
                <li><b>El razonamiento </b>que es la capacidad de comparar resultados, elaborar inferencias.</li>
                <li><b>Flexibilidad, </b> capacidad de generar alternativas para adaptarse a nuevos retos.</li>
                <li><b>Inhibición </b>que consiste en no hacer caso a la información irrelevante cuando estamos trabajando o estudiando algo.</li>
                <li><b>Toma de decisiones </b>que es poder elegir una forma de realizar las cosas después de analizar las opciones y sus posibles resultados.</li>
                <li><b>Estimación temporal: </b>capacidad de estimar cuánto tiempo puede llevar un trabajo o tarea.</li>
                <li><b>Ejecución dual: </b>capacidad de realizar dos tareas a la vez.</li>
                <li><b>Branching: </b>La capacidad de organizar diversas tareas que puedes intercalar y en qué estatus está cada tarea.</li>
            </ul>
            <p class=" md:mb-8 mb-10">Concentrarte en la escuela o en el trabajo, es fundamental, ya que la falta de concentración y atención impactarán en tu productividad y ahora te diremos por qué.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">¿Qué es la productividad?</h2>
            <p class="md:mb-4 mb-6">La productividad, no sólo en una empresa, también personal, <b>es la capacidad para aprovechar al máximo nuestro tiempo, energía y recursos.</b></p>
            <p class="md:mb-4 mb-6">Es la manera de <b>hacer más en menos tiempo y con menor esfuerzo,</b> pero sin dejar de lado la calidad y el bienestar personal.</p>
            <p class="md:mb-8 mb-10">El tiempo sigue siendo muy importante, recuerda el dicho de “el tiempo es oro”.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">La productividad según Bill Gates</h2>
            <p class=" md:mb-4 mb-6"><b>William Henry Gates III es un magnate empresarial,</b> desarrollador de software y sistemas operativos y que junto a Paul Allen, fueron fundadores de Microsoft.</p>
            <p class=" md:mb-4 mb-6">En el tiempo en el cual Gates estuvo en Microsoft ocupó los puestos de presidente, director ejecutivo, jefe de software, compitiendo con Steve Jobs en el desarrollo de productos o servicios únicos.</p>
            <p class=" md:mb-4 mb-6">Su fortuna se calcula en 129 mil millones de dólares y ha sido nombrado como una de las 100 personas más influyentes del siglo XX.</p>
            <p class=" md:mb-4 mb-6">El día para Bill Gates comienza sin desayunar y más que tomar café, le gusta el té, según el documental Inside Bill 's Brain: decoding Bill Gates, además él dice que el tiempo es lo único que no se puede comprar, por lo cual en su vida diaria, llega puntual a todos lados.</p>
            <p class=" md:mb-8 mb-10">En su viaje al éxito, Bill Gates cumplía con extenuantes jornadas operativas, sin embargo, años después reconoció que dedicar más horas al trabajo no implicaba ser más productivo: “Al final, tuve que relajarme cuando tuvimos un gran crecimiento económico”.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Trabajo profundo o Deep Work: la técnica que debes conocer</h2>
            <p class="md:mb-4 mb-6">Si bien el magnate tiene varios métodos para elevar los niveles de productividad, hay una técnica que se ha hecho muy famosa: Trabajo profundo (Deep work), que <b>consiste en aprovechar las horas en las que tu cerebro está más receptivo para realizar las tareas más difíciles</b> que tengas y hacerlas <b>sin interrupciones.</b></p>
            <p class="md:mb-8 mb-10">Es un estado de máxima concentración que te permite obtener más conocimientos complejos, producir trabajos de calidad y aumentar la productividad. <b>Este término lo creó Cal Newport,</b> quien es profesor de Ciencias de la Computación en Georgetown University y autor del libro Enfócate. Consejos para alcanzar el éxito en un mundo disperso.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Los pasos que debes seguir</h3>
            <p class="mb-2">Esta técnica le hace la vida “más fácil” al cerebro, ya que no todas las tareas exigen el mismo esfuerzo. Aquí te decimos:</p>
            <ul class="list-decimal pl-6 md:mb-4 mb-6">
                <li><b>Es importante que te olvides de todos los distractores</b> como el celular, las redes sociales, el correo electrónico, los mensajes de texto, música, todo aquello que te quite la atención en lo que estás haciendo.</li>
                <li><b>Identifica qué momento del día es el más adecuado para ti:</b> en las mañanas, tardes o noches. Hay quienes en las primeras horas del día se sienten supermotivados, pero otras personas pueden encontrar el momento perfecto en la noche o a mediodía. La idea es que tu cerebro esté descansado y receptivo para hacer las tareas que requieran más atención y concentración.</li>
                <li>Ahora que ya que sabes cuál es tu mejor hora, <b>haz una lista de las tareas que sean más complicadas y evita hacer varias cosas a la vez.</b></li>
                <li>En este momento es cuando <b>debes concentrarte en una sola actividad.</b> Trabaja 60 o 90 minutos de esta forma.</li>
                <li>El siguiente paso, es trabajar en esta actividad no más de cuatro horas y <b>tener descansos de unos cinco minutos</b> pero debes evitar ver tus redes sociales, o aventarte un maratón de Netflix, <b>sólo deja a tu cerebro descansar,</b> estírate, camina un poco, así le das la oportunidad a tus redes neuronales asimilar la información de lo que estás haciendo.</li>
            </ul>
            <p class="md:mb-4 mb-6"><b>Bill Gates ha preferido este método de organización en su día a día:</b> dos veces al año, él se aísla completamente una semana en su cabaña en el bosque. A esto lo denomina “semanas para pensar”.</p>
            <p class="md:mb-4 mb-6">Gates se olvida de la conexión a internet y <b>centra toda su atención en leer libros,</b> sobre todo, para aprender <b>e investigar acerca de temas que tengan que ver con sus proyectos</b> para la Fundación Bill y Melinda Gates.</p>
            <p class="md:mb-8 mb-10">Como puedes leer, <b>Bill Gates no toma ese tiempo para vacacionar,</b> al contrario, son siete días en los cuales <b>trabaja 122 horas,</b> con estos cinco minutos de receso en los cuales sólo camina o no hace nada, con la finalidad de que el cerebro descanse y asimile lo trabajado.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Beneficios del Trabajo profundo</h2>
            <p class="md:mb-4 mb-6">Esta técnica es efectiva porque <b>ayuda a evitar las distracciones y activa las conexiones cerebrales,</b> pues puedes aprender más rápido cuando te concentras por lo que vas reconfigurando tu cerebro, así tus trabajos o proyectos son de mejor calidad y elaborados en un tiempo menor.</p>
            <p class="md:mb-8 mb-10">Además, el trabajo profundo <b>te brinda satisfacción y calidad de vida al terminar tus tareas en tiempo y forma,</b> de una manera en la que hay fluidez en el proceso de tu trabajo.</p>
            <img src="/images/incorpora-tecnica-trabajo-profundo-bill-gates.jpg" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Incorpora el Deep work a tu vida</h2>
            <ul class="list-decimal pl-6 md:mb-8 mb-10">
                <li><b>Haz un cronograma personal</b> tomando en cuenta la filosofía rítmica: trabaja en bloques de una a cuatro horas, aproximadamente, en los que te concentrarás. Un ejemplo: si ya identificaste que tus mejores horas de atención son en la tarde, reserva un bloque de tres a seis de la tarde, para concentrarte todos los días y así formarás un hábito.</li>
                <li><b>Haz tu propio ritual de concentración.</b> Esto es importante, pues con ello, puedes disparar tu concentración.<br> <span class="">2.1. </span> Encuentra el lugar adecuado para trabajar, te puede inspirar que tu escritorio esté limpio. Ten al alcance los recursos disponibles para laborar, y retira los que puedan distraerte como tu celular. Desactiva las notificaciones, los banners y sonidos.</li>
                <li><b>Ten prioridades</b> para que al trabajar profundamente sólo te concentres en lo verdaderamente importante, de esa forma evitas trabajar en varias cosas a la vez.</li>
                <li><b>Realiza una lista de tus actividades</b> para identificar cuáles necesitan concentración profunda y qué otras son trabajo superficial o más repetitivo.</li>
                <li><b>Analiza las reuniones en las que participas</b> para darles también una calificación de la más importante a la menos, y cuánto tiempo puedes concentrarte en cada sesión.</li>
                <li><b>Organiza tu día:</b> Puedes dedicar un bloque de tiempo en el cual sólo sea para trabajo profundo y otro bloque para las tareas más sencillas, de esa manera, optimizas tu día.</li>
            </ul>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Conclusión</h2>
            <p class="md:mb-4 mb-6">Adoptar el enfoque de Bill Gates hacia la productividad y el trabajo profundo (Deep Work) puede parecer desafiante al principio, pero los beneficios son muchos.</p>
            <p class="md:mb-4 mb-6">Al cultivar la disciplina para enfocarte intensamente en las tareas más importantes, no sólo aumentas tu eficiencia, también mejoras la calidad de tu trabajo.</p>
            <p class="md:mb-8 mb-10">Recuerda, la clave del éxito no radica en trabajar más horas, sino hacerlo de manera más inteligente y con un propósito claro.</p>
            <!-- Aqui va el componente de contacto, no se te olvide hacerlo manolo -->
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
                <ContactoVue class="m-3"/>
            </div>
            <p class=" text-htwo text-left md:mb-4 mb-6">Explora más: <a href="https://humanitas.edu.mx/licenciatura-en-administracion" target="_blank" class="text-htwo">Licenciatura en Administración</a></p>
            <p class="text-htwo text-left mb-20">Te puede interesar: <router-link to="/blog/aprende/tips/mejores-tecnicas-estudio-para-universidad"  class="text-htwo">Las mejores técnicas de estudio para triunfar en la universidad</router-link></p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="true" :linkAnt="'/academico/licenciatura/dia-mundial-del-teatro-2024'" :linkSig="'/recursos/casos-reales/perfil-criminologico-como-descifra-la-mente-criminal'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import ContactoVue from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue';
import SigArt from '../../pagesComponents/SigArt.vue';

export default {
    components: { Comentarios, ConComentarios, ContactoVue, LikesVue, ShareP, SigArt},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '16-ART-APRENDE-TIPS-020424',
            ruta: '/aprende/tips/maximiza-tu-productividad-estilo-bill-gates',
            title: '27 de marzo Día Mundial del Teatro 2024: celebra la paz y el arte',
            image: 'https://blog.humanitas.edu.mx/images/Costo-y-requisitos-estudiar-maestria.jpg',
            text: 'Descubre la técnica de Bill Gates y potencia tu productividad, ¡lee más!'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx/academico/maestria/costo-y-requisitos-estudiar-maestria';

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>