<template>
    <div>
      <CataBueno :titulo="'Abril 2024'" :subtitulo="''" :filtros="filtros" :articles="articles"/>
    </div>
</template>

<script lang="js">
import CataBueno from '../../pagesComponents/CataBueno.vue';

export default {
components: { CataBueno },
data() {
    return {
    articles: [
        {
            "id": "18-ART-ACADEMICO-LICENCIATURA-260424",
            "titulo": "Qué aptitudes y habilidades necesitas para estudiar Derecho.",
            "fecha": "25 de abril de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Descubre las aptitudes y habilidades clave que necesitas para estudiar Derecho. Prepárate para enfrentar los desafíos de esta carrera. ¡Conócelas!",
            "portada": "/images/descubre-aptitudes-habilidades-clave-estudiar-derecho.jpg",
            "enlace": "/academico/licenciatura/aptitudes-habilidades-para-estudiar-derecho"
        },
        {
            "id": "019-ART-ACADEMICO-MAESTRIA-260424",
            "titulo": "Maestría en Psicología: Guía completa",
            "fecha": "24 de abril de 2024",
            "autor": "Iván Solís Rivera",
            "descripcion": "Describimos todo lo que necesita saber de la Maestría en Psicología, cuyo principal objetivo es fortalecer habilidades y conocimientos en terrenos prácticos y teóricos.",
            "portada": "/images/A-maestria-psicologia-tipos-mente-humana.jpg",
            "enlace": "/academico/maestria/maestria-psicologia-guia-completa"
        },
        {
            "id": "17-ART-RECURSOS-CASOSREALES-090424",
            "titulo": "Perfil criminológico: cómo los expertos descifran la mente criminal.",
            "fecha": "09 de abril de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "El perfil criminológico permite internarse en la mente de los delincuentes para resolver casos complejos. Explora qué es.",
            "portada": "/images/Perfil-criminologico-detras-mente-criminal-investigadores.jpg",
            "enlace": "/recursos/casos-reales/perfil-criminologico-como-descifra-la-mente-criminal"
        },
        {
            "id": "16-ART-APRENDE-TIPS-020424",
            "titulo": "Maximiza tu productividad al estilo de Bill Gates.",
            "fecha": "02 de abril de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Descubre la técnica de Bill Gates y potencia tu productividad, ¡lee más!",
            "portada": "/images/aumenta-tu-productividad-tecnica-trabajo-profundo-Bill-Gates.jpg",
            "enlace": "/aprende/tips/maximiza-tu-productividad-estilo-bill-gates"
        },
    ], // Array de artículos original
    filtros: {
        "18-ART-ACADEMICO-LICENCIATURA-260424": {
          Humanidades: [],
          Ciencias_sociales: ["Derecho", "Ciencias Políticas", "Administración Pública"],
          Economico_administrativas: ["Administración"]
        },
        "019-ART-ACADEMICO-MAESTRIA-260424": {
            Humanidades: [],
            Ciencias_sociales: ["Derecho", "Ciencias Políticas", "Administración Pública"],
            Economico_administrativas: []
        },
        "17-ART-RECURSOS-CASOSREALES-090424": {
            Humanidades: ["Psicología"],
            Ciencias_sociales: ["Derecho"],
            Economico_administrativas: []
        },
        "16-ART-APRENDE-TIPS-020424": {
            Humanidades: [],
            Ciencias_sociales: [],
            Economico_administrativas: ["Administración"]
        },
    },
    filtrosSeleccionados: null, // Lista de filtros seleccionados
    };
},
methods: {
}
};
</script>

<style>
/* Estilos CSS si los tienes */
</style>