<template>
    <div>
      <CataBueno :titulo="'Febrero 2024'" :subtitulo="''" :filtros="filtros" :articles="articles"/>
    </div>
</template>

<script lang="js">
import CataBueno from '../../pagesComponents/CataBueno.vue';

export default {
components: { CataBueno },
data() {
    return {
    articles: [
        {
            "id": "09-ART-ACADEMICO-ESTUDIA-EN-LINEA-220224",
            "titulo": "Amplía tus horizontes y estudia tu carrera en línea en México con flexibilidad y calidad",
            "fecha": "22 de febrero de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "La educación universitaria en línea te ofrece carreras flexibles y accesibles. Explora los beneficios de esta modalidad.",
            "portada": "/images/Estudia-tu-carrera-en-linea.jpg",
            "enlace": "/academico/estudia-en-linea/estudia-tu-carrera-en-linea-beneficios"
        },
        {
            "id": "08-ART-ACADEMICO-EDUCACION-CONTINUA-200224",
            "titulo": "El impacto de un diplomado en tu desarrollo profesional: entérate cómo",
            "fecha": "20 de febrero de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Un diplomado amplía tus habilidades y transforma tu carrera profesional, conoce sus ventajas.",
            "portada": "/images/Como-impacta-tu-vida-profesional-un-diplomado.jpg",
            "enlace": "/academico/educacion-continua/como-un-diplomado-transforma-tu-carrera"
        },
        {
            "id": "07-ART-APRENDE-TENDENCIAS-190224",
            "titulo": "Infórmate acerca de las tendencias actuales del Derecho en 2024",
            "fecha": "19 de febrero de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Entérate cuáles son las principales tendencias actuales del Derecho en 2024. Mantente al día con los cambios e innovaciones que conforman el futuro legal.",
            "portada": "/images/Tendencias-actuales-derecho-2024.jpg",
            "enlace": "/aprende/tendencias/tendencias-actuales-del-derecho-2024"
        },
        {
            "id": "06-ART-ACADEMICO-DOCTORADO-140224",
            "titulo": "Si quieres hacer el doctorado, esta guía te ayudará, te decimos todo lo que necesitas",
            "fecha": "14 de febrero de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Descubre todo sobre el doctorado: las cualidades del estudiante ideal, estrategias para destacar y las competencias que adquirirás para tu vida.",
            "portada": "/images/Todo-sobre-el-doctorado-guia.jpg",
            "enlace": "/academico/doctorado/guia-todo-sobre-doctorado-perfil-y-competencias"
        },
        {
            "id": "05-ART-ACADEMICO-LICENCIATURA-060224",
            "titulo": "Estudia una licenciatura en menor tiempo para impulsar tu éxito profesional",
            "fecha": "06 de febrero de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Conoce los beneficios de las carreras universitarias cortas y cómo pueden ser una opción ideal si quieres iniciar tu vida laboral más rápido.",
            "portada": "/images/carreras-cortas-beneficios.jpg",
            "enlace": "/academico/licenciatura/beneficios-carreras-universitarias-cortas"
        },
        
        
    ], // Array de artículos original
    filtros: {
        "05-ART-ACADEMICO-LICENCIATURA-060224": {
        Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y Teatro"],
        Ciencias_sociales: ["Derecho", "Ciencias Políticas", "Administración Pública"],
        Economico_administrativas: ["Administración", "Contabilidad"]
        },
        "06-ART-ACADEMICO-DOCTORADO-140224": {
        Humanidades: ["Educación"],
        Ciencias_sociales: ["Derecho"],
        Economico_administrativas: ["Administración"]
        },
        "07-ART-APRENDE-TENDENCIAS-190224": {
        Humanidades: [],
        Ciencias_sociales: ["Derecho"],
        Economico_administrativas: []
        },
        "08-ART-ACADEMICO-EDUCACION-CONTINUA-200224": {
        Humanidades: ["Psicología"],
        Ciencias_sociales: ["Derecho"],
        Economico_administrativas: ["Administración", "Contabilidad"]
        },
        "09-ART-ACADEMICO-ESTUDIA-EN-LINEA-220224": {
        Humanidades: ["Educación", "Psicología"],
        Ciencias_sociales: ["Derecho"],
        Economico_administrativas: ["Administración", "Contabilidad"]
        },
    },
    filtrosSeleccionados: null, // Lista de filtros seleccionados
    };
},
methods: {
}
};
</script>

<style>
/* Estilos CSS si los tienes */
</style>