<script setup lang="ts">
    import { ref, onMounted } from 'vue';

    interface Props {
        esco?: string;
        ejec?: string;
        virt?: string;
        escoT?: boolean;
        ejecT?: boolean;
        virtT?: boolean;
    }

    const props = withDefaults(defineProps<Props>(), {
        esco: '',
        ejec: '',
        virt: '',
        escoT: false,
        ejecT: false,
        virtT: false
    });

    const counter = ref(0);

    function gridClass() {
        return 'grid grid-cols-1 md:grid-cols-' + counter.value + ' gap-4';
    }

    const descargarFolletoReal = (folleto: string): void => {
        // const urlDelFolleto = folleto; // Reemplaza con la URL correcta.
        // window.open(urlDelFolleto, '_blank');
        const urlDelFolleto = folleto; // Reemplaza con la URL correcta.
        const enlace = document.createElement('a');
        enlace.href = urlDelFolleto;
        //@ts-ignore
        enlace.download = urlDelFolleto.split('/').pop(); // Usa el nombre del archivo del URL
        document.body.appendChild(enlace);
        enlace.click();
        document.body.removeChild(enlace);
    };

    onMounted(() => {
        if (props.escoT) counter.value++;
        if (props.ejecT) counter.value++;
        if (props.virtT) counter.value++;
    });
</script>

<template>
    <div class="flex flex-col items-center justify-center w-full mb-16 px-4">
        <p class="text-lg mb-8">Descarga el programa de tu preferencia</p>
		<div class="flex items-center justify-center w-full max-w-4xl lg:max-w-[1200px]">
			<div :class="gridClass()">
                <div v-if="props.escoT" class="flex flex-col justify-center items-center relative text-white text-left w-full max-w-[480px] ">
                    <img  src="../../pagesImages/folleto-escolarizado.jpg" alt="Folleto Escolarizado" @click="descargarFolletoReal(esco)" class="cursor-pointer image">
                    <!-- <div class="absolute bottom-7 md:bottom-12 lg:bottom-2 xl:bottom-10 mx-4">
                        <p class="text-center uppercase font-bold text-[28px]">Escolarizado</p>
                    </div> -->
                </div>
                <div v-if="props.ejecT" class="flex flex-col justify-center items-center relative text-white text-left w-full max-w-[480px] ">
                    <img  src="../../pagesImages/folleto-ejecutivo.jpg" alt="Folleto Ejecutivo" @click="descargarFolletoReal(ejec)" class="cursor-pointer image">
                    <!-- <div class="absolute bottom-7 md:bottom-12 lg:bottom-2 xl:bottom-10 mx-4">
                        <p class="text-center uppercase font-bold text-[28px]">Ejecutivo</p>
                    </div> -->
                </div>
                <div v-if="props.virtT" class="flex flex-col justify-center items-center relative text-white text-left w-full max-w-[480px] ">
                    <img  src="../../pagesImages/folleto-virtual.jpg" alt="Folleto Virtual" @click="descargarFolletoReal(virt)" class="cursor-pointer image">
                    <!-- <div class="absolute bottom-7 md:bottom-12 lg:bottom-2 xl:bottom-10 mx-4">
                        <p class="text-center uppercase font-bold text-[28px]">Virtual</p>
                    </div> -->
                </div>
			</div>
		</div>
	</div>
</template>

<style scoped>
</style>