<template>
  <div>
    <CataBueno :titulo="'Académico'" :subtitulo="'Estudia en Línea'" :filtros="filtros" :articles="articles"/>
  </div>
</template>
  
<script lang="js">
import CataBueno from '../pagesComponents/CataBueno.vue';

export default {
  components: { CataBueno },
  data() {
    return {
      articles: [
        {
            "id": "0042-ART-ESTUDIA-EN-LINEA-DERECHO-110624",
            "titulo": "Licenciatura en Derecho virtual: cómo tener éxito",
            "fecha": "11 de junio de 2024",
            "autor": "Iván Solís Rivera",
            "descripcion": "La Licenciatura en Derecho virtual ofrece una formación integral sobre la normatividad jurídica e institucional de la abogacía y sus ramas de conocimiento.",
            "portada": "/images/licenciatura-derecho-virtual-corporativo.webp",
            "enlace": "/academico/estudia-en-linea/licenciatura-en-derecho-virtual"
        },
        {
            "id": "0037-ART-ACADEMICO-EDUCACION-EN-LINEA-040624",
            "titulo": "Descubre la licenciatura de Psicología en línea y por qué puede ser para ti",
            "fecha": "04 de junio de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "¿Te interesa estudiar Psicología en línea? Entérate por qué puede ser la mejor opción para ti.",
            "portada": "/images/licenciatura-psicologia-en-linea-una-opcion-humanitas.webp",
            "enlace": "/academico/estudia-en-linea/licenciatura-psicologia-en-linea"
        },
        {
            "id": "022-ART-ACADEMICO-ESTUDIA-EN-LINEA-070524",
            "titulo": "Conoce las metodologías del aprendizaje en línea.",
            "fecha": "07 de mayo de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "Explora las principales metodologías de aprendizaje en línea y descubre cómo pueden transformar la experiencia educativa.",
            "portada": "/images/metodologias-aprendizaje-linea-.webp",
            "enlace": "/academico/estudia-en-linea/metodologias-aprendizaje-linea"
        },
        {
            "id": "09-ART-ACADEMICO-ESTUDIA-EN-LINEA-220224",
            "titulo": "Amplía tus horizontes y estudia tu carrera en línea en México con flexibilidad y calidad.",
            "fecha": "22 de febrero de 2024",
            "autor": "Carolina Reyes Martínez",
            "descripcion": "La educación universitaria en línea te ofrece carreras flexibles y accesibles. Explora los beneficios de esta modalidad.",
            "portada": "/images/Estudia-tu-carrera-en-linea.jpg",
            "enlace": "/academico/estudia-en-linea/estudia-tu-carrera-en-linea-beneficios"
        },
      ], // Array de artículos original
      filtros: {
        "0042-ART-ESTUDIA-EN-LINEA-DERECHO-110624": {
          Humanidades: [],
          Ciencias_sociales: ["Derecho"],
          Economico_administrativas: []
        },
        "0037-ART-ACADEMICO-EDUCACION-EN-LINEA-040624": {
          Humanidades: ["Psicología"],
          Ciencias_sociales: [],
          Economico_administrativas: []
        },
        "022-ART-ACADEMICO-ESTUDIA-EN-LINEA-070524": {
          Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y Teatro"],
          Ciencias_sociales: ["Derecho", "Ciencias Políticas", "Administración Pública"],
          Economico_administrativas: ["Administración", "Contabilidad"]
        },
        "09-ART-ACADEMICO-ESTUDIA-EN-LINEA-220224": {
          Humanidades: ["Educación", "Psicología"],
          Ciencias_sociales: ["Derecho"],
          Economico_administrativas: ["Administración", "Contabilidad"]
        },
      },
      filtrosSeleccionados: null, // Lista de filtros seleccionados
    };
  },
  methods: {
   
  }
};
</script>

<style>
/* Estilos CSS si los tienes */
</style>