<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">Universidades sin examen de admisión </h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-200">
          <img src="/images/universidades-examen-admision.webp" alt="Son diversas aptitudes y habilidades para estudiar Derecho: conócenos" class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/academico" class=" text-hone">Académico</router-link> > <router-link to="/blog/academico/licenciatura" class=" text-hone">Licenciatura</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex text-left list-disc pl-5 italic text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">7 de Mayo de 2024</li>  <li>Iván Solís Rivera</li></ul>
            </div>
            <p class="md:mb-4 mb-6">Conoce <b>cuáles son las universidades sin examen de admisión en México,</b> que además tienen reconocimiento a nivel nacional, <b>cuya oferta educativa les brinda a los estudiantes la posibilidad de dar continuidad</b> a sus estudios superiores.</p>
            <p class="md:mb-4 mb-6">Es importante recordar que los exámenes de admisión forman parte de los requisitos para ingresar a una institución educativa a nivel medio y superior.</p>
            <p class="md:mb-8 mb-10"><b>Las <router-link to="/blog/academico/licenciatura/mejores-universidades-derecho-mexico-guia"  class="text-htwo">universidades</router-link> que no aplican exámenes de admisión cuentan con sistemas de evaluación alternativos</b> o forman parte de planes educativos de gobierno cuyo principal objetivo es impulsar la educación a nivel medio y superior.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">¿Por qué las universidades aplican el examen de admisión?</h2>
            <p class="md:mb-4 mb-6">Las universidades aplican exámenes de admisión principalmente por un tema de selección de aspirantes para continuar sus estudios y especialización.</p>
            <p class="md:mb-4 mb-6">Con dicha selección, las instituciones educativas tienen la capacidad de crear planes de estudio y garantizar su correcta implementación.</p>
            <p class="md:mb-4 mb-6"><b>Las pruebas de admisión son herramientas cuya principal función es la de conocer el nivel de conocimientos y habilidades</b> de los aspirantes dentro del contexto de la pedagogía universitaria.</p>
            <p class="md:mb-8 mb-10">De acuerdo con especialistas en pedagogía, los exámenes de admisión son una referencia para los aspirantes, ya que les proporcionan retroalimentación sobre sus fortalezas y áreas de mejora en relación con el currículo universitario.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Requisitos generales para ingresar a la universidad</h2>
            <p class="md:mb-8 mb-10">Para acceder a la universidad tanto en México como en otros países, es crucial cumplir con ciertos requisitos establecidos por las instituciones educativas. <b>Estos requisitos pueden variar, pero los más comunes son:</b></p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Bachillerato concluido</h3>
            <p class="md:mb-8 mb-10">Es fundamental haber concluido satisfactoriamente el bachillerato. Esto implica haber <b>aprobado todas las materias requeridas durante la educación preparatoria.</b> Por lo general, las instituciones solicitarán el certificado original de estudios como comprobante.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Promedio de ingreso</h3>
            <p class="md:mb-8 mb-10">Universidades como la Universidad Nacional Autónoma de México (UNAM) o el Instituto Politécnico Nacional (IPN), <b>exigen un promedio mínimo de calificaciones para poder ingresar, usualmente alrededor de 7.0.</b> Sin embargo, algunas pueden requerir un promedio más alto. </p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Certificaciones adicionales</h3>
            <p class="md:mb-8 mb-10">Dependiendo del programa académico al que se postule, algunas universidades pueden solicitar certificados adicionales. <b>Por ejemplo, para carreras relacionadas con idiomas o artes, podrían requerir certificaciones</b> que demuestren conocimientos previos en áreas específicas.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Identificación oficial</h3>
            <p class="md:mb-4 mb-6">Contar con una identificación oficial es necesario para todo el proceso de admisión. <b>Para los aspirantes mayores de edad, pueden ser válidas la credencial del Instituto Nacional Electoral (INE),</b> licencia de conducir o cartilla militar.</p>
            <p class="md:mb-8 mb-10">Es de resaltar que la mayoría de las universidades requieren que los aspirantes se sometan a un examen de admisión, el resultado de éste puede ser determinante para el proceso de admisión.</p>
            <img src="/images/universidades-publicas-estudiante.webp" alt="Las universidades públicas sin examen están enfocadas en áreas de estudio" class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Universidades públicas que no aplican examen</h2>
            <p class="md:mb-4 mb-6"><b>En México, algunas universidades públicas no aplican exámenes de admisión debido a que se alinean a políticas de inclusión educativas delineadas por el Estado,</b> que tienen como objetivo ampliar el acceso de los estudiantes a la educación superior siguiendo el principio de igualdad.</p>
            <p class="md:mb-4 mb-6">En ese sentido, <b>evalúan a los estudiantes con otros criterios de selección como promedio de calificaciones obtenidas durante la educación media superior o como una evaluación</b> integral de los aspirantes, que incluya aspectos académicos, sociales y personales, para garantizar que los estudiantes seleccionados tengan el perfil adecuado para cursar sus <router-link to="/blog/aprende/tips/mejores-tecnicas-estudio-para-universidad"  class="text-htwo">estudios universitarios</router-link></p>
            <p class="md:mb-8 mb-10">Entre las universidades en México que no aplican examen de admisión se encuentran:</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Universidad Ciudadana de Nuevo León</h3>
            <p class="md:mb-4 mb-6">La Universidad Ciudadana de Nuevo León es una institución de educación superior. En el año 2022 obtuvo una matrícula de más de 9 mil estudiantes, predominantemente <b>ubicados en el estado de Nuevo León,</b> con la mayoría concentrada en la ciudad de Monterrey.</p>
            <p class="md:mb-8 mb-10">Esta universidad ofrece la posibilidad de cursar el bachillerato de forma completamente en línea y no requiere la realización de un examen de admisión para ingresar</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Universidad Rosario Castellanos</h3>
            <p class="md:mb-4 mb-6">El Instituto de Estudios Superiores de la Ciudad de México Rosario Castellanos es una institución dedicada a la formación profesional. En el año 2022 registró una matrícula de más de 15 mil estudiantes en la Ciudad de México.</p>
            <p class="md:mb-8 mb-10"><b>Este instituto tiene como misión ofrecer una opción educativa de nivel superior que no genere barreras de acceso</b> basadas en procesos de admisión complejos, los cuales podrían promover la desigualdad y un acceso diferenciado.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Universidad Abierta y a Distancia de México</h3>
            <p class="md:mb-4 mb-6">La (UnADM) se organiza en tres divisiones: Ciencias de la Salud, Biológicas y Ambientales; Ciencias Exactas, Ingeniería y Tecnología; y Ciencias Sociales y Administrativas.</p>
            <p class="md:mb-8 mb-10"><b>El instituto tiene como objetivo principal ampliar las oportunidades y atender la demanda de educación superior en el país bajo criterios y estándares de calidad e innovación</b> permanentes, prestando especial atención a aquellas regiones y grupos que no tienen acceso a servicios educativos escolarizados.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Universidad de la Salud</h3>
            <p class="md:mb-4 mb-6">La Universidad de la Salud es una institución dedicada a la formación de profesionales en el ámbito de la medicina y la enfermería, ubicada la alcaldía Álvaro Obregón de la Ciudad de México.</p>
            <p class="md:mb-8 mb-10">Esta universidad, respaldada por la Secretaría de Educación, Ciencia, Tecnología e Innovación, <b>ofrece programas educativos gratuitos para aquellas personas interesadas en seguir una carrera en el campo de la salud.</b></p>
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
                <ContactoVue class="m-3"/>
            </div>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Universidades privadas que no aplican examen de admisión</h2>
            <p class="md:mb-4 mb-6"><b>Las universidades privadas en México que no aplican exámenes de admisión suelen tener enfoques pedagógicos distintivos</b> que valoran otras formas de evaluación de la idoneidad de los aspirantes.</p>
            <p class="md:mb-4 mb-6">Este tipo de instituciones han priorizado la revisión integral de los expedientes académicos, evaluando el desempeño previo, las habilidades extracurriculares y las aptitudes personales.</p>
            <p class="md:mb-8 mb-10">En México, algunas universidades privadas han optado por no aplicar exámenes, como son:</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Universidad Iberoamericana (UIA)</h3>
            <p class="md:mb-4 mb-6">La Universidad Iberoamericana (UIA), confiada a la Compañía de Jesús, <b>ha cumplido más de 80 años de formación académica</b> para el México actual.</p>
            <p class="md:mb-8 mb-10">Su modelo educativo, influenciado por los principios jesuitas, ha sido destacado por sus procesos de enseñanza y aprendizaje.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Universidad Anáhuac</h3>
            <p class="md:mb-4 mb-6">La Red de Universidades Anáhuac es un conjunto de instituciones de educación superior que comparten la identidad y el modelo educativo Anáhuac. <b>Estas instituciones impulsan la consolidación, optimizar la operación y promover el crecimiento de sus miembros.</b></p>
            <p class="md:mb-8 mb-10">Estas instituciones se enfocan en la formación humanística que promueve el desarrollo científico y tecnológico.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Universidad del Valle de México (UVM)</h3>
            <p class="md:mb-4 mb-6">La Universidad del Valle de México cuenta con una oferta educativa que busca adaptarse a las tendencias laborales.</p>
            <p class="md:mb-8 mb-10">La <b>UVM cuenta con programas educativos diseñados para la formación académica y profesional,</b> en términos de educación preparatoria, posgrados y maestrías.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Universidad Insuco</h3>
            <p class="md:mb-8 mb-10">La Universidad Insuco incluye modalidades presenciales (híbridas) y en línea. Cuenta con validez oficial ante la Secretaría de Educación.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Universidad Humanitas</h3>
            <p class="md:mb-4 mb-6">La <b><a href="https://humanitas.edu.mx/" target="_blank" class="text-htwo">Universidad Humanitas</a> es una institución educativa comprometida con la formación integral de sus estudiantes,</b> promoviendo valores y conocimientos profesionales en diversas áreas de estudio, siempre bajo el principio de respeto al entorno y búsqueda de la superación continua.</p>
            <p class="md:mb-4 mb-6">Con presencia en varios estados de la República Mexicana, <b>cuenta con campus que se caracterizan por su arquitectura neoclásica, rodeados de jardines y fuentes</b> que crean un ambiente propicio para el estudio y la reflexión.</p>
            <p class="md:mb-4 mb-6">La institución tiene modelos educativos diseñados por especialistas y supervisados por el consejo académico.</p>
            <p class="md:mb-4 mb-6">En la Universidad Humanitas, <b>los catedráticos son expertos en sus respectivas áreas profesionales.</b> Muchos de ellos tienen experiencia tanto en dependencias gubernamentales como en el sector privado.</p>
            <p class="md:mb-8 mb-10">Su compromiso con la enseñanza busca transmitir sus conocimientos mediante una filosofía centrada en el razonamiento y la resolución de problemas para facilitar el aprendizaje, todo ello respaldado por una metodología práctica y altos estándares académicos.</p>
            <VideoClipVue :link="'https://www.youtube.com/embed/5wxHNbx6I1k?si=ZI4NMIw4FmYiIuQO'" />
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Conclusiones</h2>
            <p class="md:mb-8 mb-10">Cada institución educativa privada tiene sus propios criterios de selección, por lo que <b>es recomendable la consulta directamente con la universidad de interés</b> y así obtener información detallada sobre su proceso de admisión.</p>
            <!-- Aqui va el componente de contacto, no se te olvide hacerlo manolo -->
            <p class=" text-htwo text-left mb-6">Explora más: <a href="https://humanitas.edu.mx/" target="_blank" class="text-htwo">Universidad Humanitas</a></p>
            <p class="text-htwo text-left mb-20">Te puede interesar: <a href="https://humanitas.edu.mx/licenciaturas" target="_blank" class="text-htwo">Licenciaturas</a></p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="true" :linkAnt="'/academico/licenciatura/tendencias-diseno-grafico-2024'" :linkSig="'/academico/estudia-en-linea/metodologias-aprendizaje-linea'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import ContactoVue from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue';
import VideoClipVue from '../../pagesComponents/videoClip.vue';
import SigArt from '../../pagesComponents/SigArt.vue';

export default {
    components: { Comentarios, ConComentarios, ContactoVue, LikesVue, ShareP, VideoClipVue, SigArt},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '021-ART-ACADEMICO-LICENCIATURA-070524',
            ruta: '/academico/licenciatura/universidades-sin-examen-admision',
            title: 'Universidades sin examen de admisión: gratuita',
            image: 'https://blog.humanitas.edu.mx/images/Costo-y-requisitos-estudiar-maestria.jpg',
            text: 'Universidades sin examen de admisión privadas y públicas tienen un objetivo común: brindar continuidad de estudios.'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx/academico/maestria/costo-y-requisitos-estudiar-maestria';

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>