<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
        <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">Mediación: ¿Qué es y cuáles son sus características?</h1>
        <div class="flex flex-col justify-center items-center w-full">
            <div class="flex flex-col md:tracking-normal tracking-tight w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-200">
                <img src="/images/caracteristicas-mediacion-litigio-negociacion.webp" alt="Características de la mediación más relevantes: habilidades de negociación" class="mb-6">
            <div class="flex flex-col justify-center text-justify  text-[18px] lg:mx-12 mx-6 font-helvetica">
                <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/recursos" class=" text-hone">Recursos</router-link> > <router-link to="/blog/recursos/entrevistas-y-conferencias" class=" text-hone">Entrevistas y Conferencias</router-link></p>
                <div class="lg:flex text-left items-start justify-start">
                    <ul class="lg:flex list-disc pl-5 italic text-left text-[18px] md:text-[20px] mb-6 font-helvetica"><li class="mr-16 text-htwo">04 de junio de 2024</li>  <li>Iván Solís Rivera</li></ul>
                </div>
                <p class="md:mb-4 mb-6">A lo largo de este texto hablaremos sobre <b>qué es la mediación</b> y cuáles son sus características considerando que, más allá de ser una forma para resolver conflictos entre personas y organizaciones, para algunos expertos en <router-link to="/blog/recursos/entrevistas-y-conferencias/inclusion-diversidad-en-derecho-por-mariel-cabanas" class=" text-htwo hover:text-hone">Derecho</router-link>, <b>este concepto representa una filosofía de vida, ya que busca la paz y la armonía social.</b></p>
                <p class="md:mb-4 mb-6">Este término <b>tiene relevancia en los conflictos humanos, debido a que es la manera para alcanzar una solución integral entre las partes involucradas</b> (que pueden ser dos o más personas), evitando problemas mayores o recurrir a instancias judiciales.</p>
                <p class="md:mb-8 mb-10">De acuerdo con el consultor en mediación y manejo de conflictos en México, Rafael Lobo Niembro, <b>este concepto se puede ver de dos principales maneras: como una metodología o una serie de herramientas destrezas y competencias socio cognitivas para facilitar el diálogo.</b></p>
                <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">¿Cómo surge este concepto?</h2>
                <p class="md:mb-4 mb-6">Mediación deriva de la palabra "mediar", la cual proviene del latín "mediare", que <b>significa "interceder" lo que implica la acción de intervenir de un tercero.</b> De manera implícita, en el término de mediación residen los conceptos de conflicto y acuerdo.</p>
                <p class="md:mb-4 mb-6">En China, desde tiempos antiguos, la mediación ha sido un recurso fundamental para resolver desacuerdos. <b>Confucio sostenía que existía una armonía natural en las relaciones humanas que debía desarrollarse.</b> Según su pensamiento, el apoyo unilateral y la intervención contraria dificultan la comprensión.</p>
                <p class="md:mb-4 mb-6">En otras culturas, como en la africana, <b>los círculos familiares han proporcionado recursos para dirimir controversias entre sus miembros.</b></p>
                <p class="md:mb-8 mb-10"><b>Este término se ha visto reflejado claramente en grupos étnicos ancestrales que históricamente han establecido sus propias normas,</b> manteniendo su independencia y su poder a salvo de la autoridad religiosa, gubernamental u otra autoridad secular. Por lo tanto, <b>la mediación ha permeado en las culturas desde la conformación de las sociedades.</b></p>
                <img src="/images/mediacion-derecho-conflictos.webp" alt="La mediación es un método alternativo que ayuda a evitar conflictos graves" class="md:mb-8 mb-10">
                <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Características de la mediación</h2>
                <p class="md:mb-4 mb-6">De las principales características que tiene <b>la mediación es que se presenta como algo informal o sencillo, además, apela a los métodos alternos,</b> a las estructuras tradicionales que son los juicios en los que se involucran abogados y jueces, señala Lobo Niembro.</p>
                <p class="md:mb-8 mb-10">Algunas de las características y propiedades de la conciliación son las siguientes:</p>
                <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Voluntariedad</h3>
                <p class="md:mb-8 mb-10"><b>La participación en la conciliación es voluntaria,</b> es decir que las partes involucradas eligen libremente recurrir a este mecanismo para resolver su conflicto.</p>
                <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Imparcialidad y neutralidad</h3>
                <p class="md:mb-8 mb-10"><b>El conciliador debe ser una persona imparcial y neutral, sin ningún interés en el resultado del conflicto.</b> Su función es facilitar la comunicación y el entendimiento entre las partes, sin favorecer a ninguna de ellas.</p>
                <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Confidencialidad</h3>
                <p class="md:mb-8 mb-10"><b>Las partes y el conciliador están obligados a mantener en reserva la información</b> compartida durante el proceso, lo cual fomenta un ambiente de confianza y apertura.</p>
                <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Flexibilidad</h3>
                <p class="md:mb-8 mb-10"><b>La conciliación es un proceso flexible,</b> que puede adaptarse a las necesidades y circunstancias específicas de las partes involucradas, distinto a los procedimientos formales como los que caracterizan a los tribunales.</p>
                <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Rapidez</h3>
                <p class="md:mb-8 mb-10"><b>Las partes pueden acordar un calendario</b> que se ajuste a sus necesidades, evitando así las demoras que representa un juicio dentro del sistema judicial.</p>
                <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Costo</h3>
                <p class="md:mb-8 mb-10"><b>La conciliación suele ser menos costosa que los litigios judiciales,</b> las partes pueden ahorrar tanto en tiempo como en dinero.</p>
                <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Negociación colaborativa</h3>
                <p class="md:mb-8 mb-10">El objetivo de la conciliación es que las partes lleguen a un acuerdo mutuamente satisfactorio. <b>Las soluciones tienden a ser más creativas y satisfactorias para ambas partes.</b></p>
                <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Autonomía de las partes</h3>
                <p class="md:mb-8 mb-10">A diferencia de un juicio, en el que un juez o árbitro decide, <b>en la conciliación son las propias partes las que acuerdan la solución.</b></p>
                <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Prevención de conflictos futuros</h3>
                <p class="md:mb-8 mb-10">Al fomentar el diálogo y la cooperación, la conciliación no solo resuelve el conflicto actual, sino que también <b>ayuda a prevenir futuros desacuerdos, mejorando las relaciones entre las partes.</b></p>
                <h3 class="text-[20px] lg:text-[24px] font-serif mb-5 text-left text-hone">Formalización del acuerdo</h3>
                <p class="md:mb-8 mb-10">Aunque el proceso de conciliación es informal, <b>el acuerdo alcanzado puede formalizarse mediante un documento escrito que puede tener efectos vinculantes,</b> dependiendo de la jurisdicción y la naturaleza del conflicto.</p>
                <div class="md:mb-8 mb-10 border border-gray-300 w-full">
                    <Contacto class="m-3"/>
                </div>
                <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">¿Cómo se estableció el concepto de mediación de conflictos en México?</h2>
                <p class="md:mb-4 mb-6"><b>El término de mediación en México hace referencia a la antigua Roma,</b> en la que crearon instituciones bajo ciertas leyes y costumbres que diversas culturas fueron adoptando a través de los siglos.</p>
                <p class="md:mb-4 mb-6">En la época de la colonia permeó <b>la adopción de los modelos del  <router-link to="/blog/academico/licenciatura/mejores-universidades-derecho-mexico-guia" class=" text-htwo hover:text-hone">Derecho</router-link> romano que la corona española había asentado, que a su vez, se consolidó en México a través de la constitución de Cádiz en 1812 y posteriormente en la de 1824</b> cuando terminó el movimiento Insurgente de independencia, estas constituciones se alineaban al Derecho romano, en la que las personas no podían entablar una demanda si no agotaban la instancia de la conciliación, por lo que era obligatoria en esa época, refiere el especialista.</p>
                <p class="md:mb-4 mb-6">“En México existe un alto índice de cumplimiento, ya que <b>los acuerdos que se han firmado en más de 20 años bajo los términos de mediación, no han rebasado el 5 por ciento de los convenios que se han regresado a los tribunales</b> para pedir que se ejecuten por incumplimiento, entonces, este camino es importante en términos jurídicos”, explica Lobo Niembro.</p>
                <p class="md:mb-4 mb-6"><b>El especialista indica que la naturaleza de conciliación abarca asuntos de orden familiar, de orden civil,</b> cuestiones mercantiles, asuntos laborales, entre muchos otros ámbitos.</p>
                <p class="md:mb-8 mb-10">“Actualmente en la República mexicana, <b>la conciliación es obligatoria a fin de prevenir los procesos de juicio y con la reciente reforma laboral, hay una efervescencia importante,</b> lo que impulsa el mayor desarrollo de este término en México”, asevera el experto.</p>
                <VideoClipVue :link="'https://www.youtube.com/embed/V1jQx0hKck4?si=0itwLqnBhU8qTNqr'"/>
                <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Ejemplos de mediación en México</h2>
                <p class="md:mb-4 mb-6">Para ejemplificar la aplicación de la mediación en México, <b>el experto Rafael Lobo hace referencia a principios de la década de los 90 cuando se firmó el primer Tratado Libre Comercio de América del norte entre Canadá, Estados Unidos y México</b>, el cuál tenía contemplado un capítulo de solución de conflictos para asuntos comerciales internacionales, en ese sentido, los compromisos trilaterales que hicieron esos países en aquel entonces sobre ciertas modificaciones y cambios a su legislación para dicho dicho tratado tenían que ver con métodos alternos.</p>
                <p class="md:mb-4 mb-6">Eventualmente <b>el Estado mexicano adoptó el tema de conciliación y mediación en los intercambios que tienen con otros países en lo que ha solicitado apoyo como el plan Mérida u otras iniciativas</b>, en las que se ha marcado la influencia norteamericana con base en fondos de aquel gobierno.</p>
                <p class="md:mb-4 mb-6"><b>Un ejemplo contemporáneo sobre la conciliación en México se dio en 2008 cuando se impulsó el sistema penal acusatorio</b>, cuya composición fomenta que las partes involucradas no lleguen hasta el final del proceso y consideren las distintas salidas alternas como es la mediación.</p>
                <p class="md:mb-8 mb-10">Otra referencia más reciente es la de la <b>reforma Constitucional del artículo 123 en materia de justicia de trabajo que entró en vigor en 2017</b>, en la que se obliga acudir a los procesos de conciliación laboral antes de llegar a los tribunales.</p>
                <img src="/images/mediacion-conflictos-colaboracion.webp" alt="La mediación de conflictos se aplica en ámbitos mercantiles, laborales y civiles" class="md:mb-8 mb-10">
                <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">¿Qué es un mediador y qué habilidades requiere?</h2>
                <p class="md:mb-4 mb-6"><b>El mediador es un profesional que interviene en los conflictos entre personas y organizaciones.</b> Entre sus <router-link to="/blog/academico/licenciatura/aptitudes-habilidades-para-estudiar-derecho" class=" text-htwo hover:text-hone">habilidades más requeridas</router-link> es que sea imparcial, neutral y facilitador de la comunicación entre los interesados para que puedan solucionar su problema. Todo con absoluta confidencialidad.</p>
                <p class="md:mb-8 mb-10"><b>Un buen mediador sabe negociar, enfrentar y saber cómo responder a los conflictos, lo cual se ha convertido en una habilidad blanda en los últimos años, destaca el especialista.</b> Finalmente, destaca que es importante que esté certificado en materia de Derechos laborales, civiles, mercantiles, entre otros.</p>
                <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight font-serif mb-5 text-left text-hone">Conclusión</h2>
                <p class="md:mb-4 mb-6"><b>La mediación y conciliación son de gran importancia no sólo en ámbitos laborales, mercantiles o judiciales,</b> sino para resolver una serie de conflictos que podemos enfrentar durante el resto de nuestra vida, <b>“lo importante no es evitarlo, sino cómo lo enfrentaremos”</b>, inaliza Rafael Lobo.</p>
                <p class="text-left pb-20">Conoce más sobre las ramas de conocimiento de la carrera de <a href="https://humanitas.edu.mx/licenciatura-en-derecho" target="_blank" class="text-htwo hover:text-hone">Derecho en la Universidad Humanitas</a>.</p>
                <div class="flex justify-between md:mb-8 mb-10">
                <LikesVue :articleID="code"/>
                <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
                </div>
            </div>
            <div class="flex justify-start items-start">
                <img src="/images/barra-art.png" class="w-[15%]">
            </div>
            </div>
            <SigArt :ant="true" :linkAnt="'/ser-humanitas/responsabilidad-social/responsabilidad-social-individual-tipos-ejemplos-inspiracion'" :linkSig="'/academico/estudia-en-linea/licenciatura-psicologia-en-linea'" :sig="true"/>
            <ConComentarios :article="code" class="pb-12"/>
            <Comentarios :article="code" class="pb-12"/>
        </div>
    </div>
</template>

<script lang="js">
import VideoClipVue from '../../pagesComponents/videoClip.vue';
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import Contacto from '../../pagesComponents/Contacto.vue';
import EntrevistasVue from '../../pagesComponents/Entrevistas.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue'
import SigArt from '../../pagesComponents/SigArt.vue';



export default {
    components: { Comentarios, ConComentarios, Contacto, EntrevistasVue, LikesVue, ShareP, SigArt, VideoClipVue},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '0036-ART-RECURSOS-ENTREVISTAS-Y-CONFERENCIAS-060624',
            image: '',
            ruta: '/recursos/entrevistas-y-conferencias/inclusion-diversidad-en-derecho-por-mariel-cabanas',
            text: 'Respondemos a la pregunta: ¿Qué es y cuáles son las características de la mediación; la manera alternativa de evitar largos juicios.',
            memo: ' (No sirve el link ✧ʕ◔౪◔ʔ✧)'
        }
    },
    methods: {

    }
}
</script>

<style>

</style>