<template>
  <div class="flex flex-col justify-center items-center w-screen z-30 bg-white font-sans text-black">
    <div class="flex flex-col justify-start max-w-6xl lg:max-w-[1200px] lg:px-0 px-4 w-full z-30 bg-white">
      <div class="flex justify-start w-screen items-start flex-col">
        <p class="text-left pl-0 leading-tight uppercase text-black pt-6 text-[16px] md:text-[20px]" style="font-family: 'Helvetica Neue Regular', sans-serif;">Artículos</p>
        <h2 class="text-left pl-0 leading-tight uppercase text-hone font-bold text-[24px] md:text-[32px] lg:text-[34px]" style="font-family: Georgia, serif;">Destacados</h2>
        <hr class="mb-6 border-gray-500 lg:w-[270px] md:w-[250px] w-[200px]">
      </div>
      <div class="flex w-full justify-start items-center">
        <div class="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-3 gap-4 w-full">
          <!-- Aquí mostraremos los artículos -->
          <div v-for="(article, index) in visibleArticles" :key="index" class="flex flex-col items-center justify-center transition-all duration-500 ease-in-out">
            <router-link :to="'/blog'+article.enlace" class="">
              <img :src="article.portada" :alt="article.titulo" class="lg:h-[180px] md:h-[180px] w-full m-2 flex items-center justify-center">
              <h3 class="lg:text-[18px] leading-tight md:text-[16px] h-[95px] text-[14px] text-htwo" style="font-family: 'Helvetica Neue Regular', sans-serif;">{{ article.titulo }}</h3>
              <!-- Añadir otros campos que quieras mostrar -->
            </router-link>
          </div>
        </div>

        <button @click="nextSlide" name="adelante" class="bg-white focus:outline-none p-4 ml-4 lg:mb-12 md:mb-8 flex items-center justify-center z-10">
          <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
            <path fill-rule="evenodd" d="M9.293 16.707a1 1 0 01-1.414-1.414L12.586 10 7.879 5.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clip-rule="evenodd"></path>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>


<script lang="js">
  export default {
    data() {
      return {
        articles:[
          {
            "id": 1,
            "titulo": "Guía de mejores universidades para estudiar Derecho en México: ¡infórmate!",
            "portada": "/images/mejores-universidades-estudiar-derecho-Mexico-guia.jpg",
            "enlace": "/academico/licenciatura/mejores-universidades-derecho-mexico-guia",
            "likes": 12
          },
          {
            "id": 2,
            "titulo": "Infórmate acerca de las tendencias actuales del Derecho en 2024",
            "portada": "/images/Tendencias-actuales-derecho-2024.jpg",
            "enlace": "/aprende/tendencias/tendencias-actuales-del-derecho-2024",
            "likes": 7
          },
          {
            "id": 3,
            "titulo": "Estoicismo: cómo puede ayudar a los estudiantes a superar desafíos",
            "portada": "/images/Que-es-estoicismo-y-como-ayuda-a-universitarios.jpg",
            "enlace": "/aprende/tips/estoicismo-para-estudiantes-universitarios-y-beneficios",
            "likes": 2
          },
          {
            "id": 4,
            "titulo": "¿Cuánto cuesta una mensualidad en la Universidad Humanitas?",
            "portada": "/images/cuanto-cuesta-mensualidad-universidad-humanitas-instalaciones.webp",
            "enlace": "/ser-humanitas/comunidad/cuanto-cuesta-mensualidad-universidad-humanitas",
            "likes": 10
          },
          {
            "id": 5,
            "titulo": "Universidades sin examen de admisión",
            "portada": "/images/universidades-examen-admision.webp",
            "enlace": "/academico/licenciatura/universidades-sin-examen-admision",
            "likes": 9
          },
          {
            "id": 6,
            "titulo": "Perfil criminológico: cómo los expertos descifran la mente criminal",
            "portada": "/images/Perfil-criminologico-detras-mente-criminal-investigadores.jpg",
            "enlace": "/recursos/casos-reales/perfil-criminologico-como-descifra-la-mente-criminal",
            "likes": 1
          },
          {
            "id": 7,
            "titulo": "Estas son las tendencias clave en la administración en 2024, descúbrelas",
            "portada": "/images/Tendencias-administración-empresarial-2024-multinube.jpg",
            "enlace": "/aprende/tendencias/tendencias-administracion-empresas-2024",
            "likes": 8
          },
          {
            "id": 8,
            "titulo": "Responsabilidad Social Individual: Descubre qué es e inspírate",
            "portada": "/images/responsabilidad-social-individual-transforma-entorno.webp",
            "enlace": "/ser-humanitas/responsabilidad-social/responsabilidad-social-individual-tipos-ejemplos-inspiracion",
            "likes": 6
          },
          {
            "id": 5,
            "titulo": "Costo y requisitos para estudiar una maestría: entérate",
            "portada": "/images/Costo-y-requisitos-estudiar-maestria.jpg",
            "enlace": "/academico/maestria/costo-y-requisitos-estudiar-maestria",
            "likes": 4
          },
          {
            "id": 6,
            "titulo": "Si quieres hacer el doctorado, esta guía te ayudará, te decimos todo lo que necesitas",
            "descripcion": "Mauris aliquet felis a magna tempus, sed commodo turpis varius. Nulla non odio finibus, tincidunt tortor at, consectetur arcu.",
            "portada": "/images/Todo-sobre-el-doctorado-guia.jpg",
            "enlace": "/academico/doctorado/guia-todo-sobre-doctorado-perfil-y-competencias",
            "likes": 5
          },
          {
            "id": 7,
            "titulo": "Tendencias en Psicología para 2024: Tecnología en la salud mental",
            "portada": "/images/tendencias-psicologia-2024-chico-sentado-meditando-frente-laptop.jpg",
            "enlace": "/aprende/tendencias/tendencias-en-psicologia-2024-tecnologia-salud-mental",
            "likes": 11
          },
          {
            "id": 8,
            "titulo": "Qué aptitudes y habilidades necesitas para estudiar Derecho",
            "portada": "/images/descubre-aptitudes-habilidades-clave-estudiar-derecho.jpg",
            "enlace": "/academico/licenciatura/aptitudes-habilidades-para-estudiar-derecho",
            "likes": 3
          },
          
        ],
        currentIndex: 0,
        itemsPerPage: 4, // Número de artículos a mostrar por página
        transitionDuration: 500, // Duración de la transición en milisegundos
        windowWidth: window.innerWidth,
      };
    },
    mounted() {
      this.sortArticles(); // Ordena los artículos al cargar el componente
      window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
    },
    computed: {
      // Calcula los artículos visibles basados en el índice actual y el número de artículos por página
      visibleArticles() {
        let itemsPerPage = this.itemsPerPage;

        if (this.windowWidth < 768) { // Screen width menor que md (768px)
          itemsPerPage = 1;
        } else if (this.windowWidth < 1024) { // Screen width menor que lg (1024px)
          itemsPerPage = 3;
        }

        const start = Math.floor(this.currentIndex / itemsPerPage) * itemsPerPage;
        const end = start + itemsPerPage;
        return this.articles.slice(start, end);
      },
    },
    methods: {
      // Método para ordenar los artículos por cantidad de likes de mayor a menor
      sortArticles() {
        this.articles.sort((a, b) => b.likes - a.likes);
      },
      // Método para ir a la diapositiva anterior
      prevSlide() {
        const itemsPerPage = this.getItemsPerPage();
        if (this.currentIndex === 0) {
          const lastIndex = Math.ceil(this.articles.length / itemsPerPage) * itemsPerPage - itemsPerPage;
          this.currentIndex = lastIndex;
        } else {
          this.currentIndex -= itemsPerPage;
        }
      },
      // Método para ir a la siguiente diapositiva
      nextSlide() {
        const itemsPerPage = this.getItemsPerPage();
        if (this.currentIndex >= this.articles.length - itemsPerPage) {
          this.currentIndex = 0;
        } else {
          this.currentIndex += itemsPerPage;
        }
      },
      handleResize() {
        this.windowWidth = window.innerWidth;
      },
      getItemsPerPage() {
        if (this.windowWidth < 768) { // Screen width menor que md (768px)
          return 1;
        } else if (this.windowWidth < 1024) { // Screen width menor que lg (1024px)
          return 3;
        }
        return this.itemsPerPage;
      },
    },
  };
</script>