<template>
	<div class="flex flex-col justify-center items-center md:w-screen mb-16 ">
		<div class="flex flex-col justify-center mb-8 items-center border border-gray-400 w-full bg-gray-200">
			<div class="px-4">
				<h2 class="text-[38px] md:text-[42px] mt-14 mb-0 text-center text-gray-600 bg-transparent font-serif">Humanitas es mucho m&aacute;s...</h2>
				<p class="text-hgray text-center text-2xl md:text-[28px] mb-6">Conoce nuestros canales de comunicaci&oacute;n</p>
			</div>
			<br>
			<div class="grid grid-cols-1 lg:grid-cols-3 gap-4 px-4 mb-8 max-w-4xl lg:max-w-6xl">
				<div class="mb-6">
					<div class="flex items-center justify-center">
						<a href="https://capitel.humanitas.edu.mx/"><img src="./compImages/capitel.webp" alt="Capitel" class=" object-cover" loading="lazy"></a>
					</div>
					<br>
					<p class="text-2xl font-bold text-gray-600">Revista Capitel</p>
					<p class="text-lg text-gray-700">Conoce m&aacute;s de nuestra revista<br> Humanitas y s&uacute;mate a nuestros<br> eventos.</p>
				</div>
				<div class="section mb-6">
					<div class="flex items-center justify-center">
						<a href="https://trascendi.humanitas.edu.mx/"><img src="./compImages/trascendi_bann.webp" alt="Trascendi" class=" object-cover" loading="lazy"></a>
					</div>
					<br>
					<p class="text-2xl font-bold text-gray-600">Canal Trascendi</p>
					<p class="text-lg text-gray-700">M&aacute;s de 100 entrevistas que te<br> motivar&aacute; a ser mejor persona y<br> trascender positivamente.</p>
				</div>
				<div class="section mb-6">
					<div class="flex items-center justify-center">
						<a href="https://legatum.humanitas.edu.mx/"><img src="./compImages/legatum.webp" alt="Legatum" class=" object-cover" loading="lazy"></a>
					</div>
					<br>
					<p class="text-2xl font-bold text-gray-600">Legatum</p>
					<p class="text-lg text-gray-700">Conferencias en un formato que<br> te cautivar&aacute;. Nunca se deja de<br> aprender.</p>
				</div>
			</div>
			<br>
		</div>
		<div class="text-gray-700 flex w-full items-center justify-center mb-12">
			<div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 w-full max-w-md md:max-w-6xl justify-center">
				<div class="w-full p-2 text-left">
					<h3 class="font-bold text-gray-600 text-[14px]">LICENCIATURA</h3>
					<ul class="list-none p-0 m-0">
						<li><router-link to="/licenciatura-en-administracion" class="text-[14px] text-hgray">Administraci&oacute;n</router-link></li>
						<li><router-link to="/licenciatura-en-arquitectura" class="text-[14px] text-hgray">Arquitectura</router-link></li>
						<li><router-link to="/licenciatura-en-arte-y-teatro" class="text-[14px] text-hgray">Arte y teatro</router-link></li>
						<li><router-link to="/licenciatura-en-ciencias-politicas" class="text-[14px] text-hgray">Ciencias Pol&iacute;ticas</router-link></li>
						<li><router-link to="/licenciatura-en-contabilidad" class="text-[14px] text-hgray">Contabilidad</router-link></li>
						<li><router-link to="/licenciatura-en-derecho" class="text-[14px] text-hgray">Derecho</router-link></li>
						<li><router-link to="/licenciatura-en-diseno-grafico" class="text-[14px] text-hgray">Diseño gr&aacute;fico</router-link></li>
						<li><router-link to="/licenciatura-en-educacion" class="text-[14px] text-hgray">Educaci&oacute;n</router-link></li>
						<li><router-link to="/licenciatura-en-psicologia" class="text-[14px] text-hgray">Psicolog&iacute;a</router-link></li>
					</ul> 
				</div>
				<div class="w-full p-2 text-left">
					<h3 class="font-bold text-gray-600 text-[14px] mb-1">MAESTR&Iacute;A</h3>
					<ul class="list-none p-0 m-0">
						<li><router-link to="/maestria-en-alta-direccion" class="text-[14px] whitespace-nowrap text-hgray">Alta Direcci&oacute;n Corporativa</router-link></li>
						<li><router-link to="/maestria-en-contabilidad-e-impuestos" class="text-[14px] whitespace-nowrap text-hgray">Contabilidad e Impuestos</router-link></li>
						<li><router-link to="/maestria-en-derecho-corporativo" class="text-[14px] text-hgray">Derecho Corporativo</router-link></li>
						<li><router-link to="/maestria-en-derecho-fiscal" class="text-[14px] text-hgray">Derecho Fiscal</router-link></li>
						<li><router-link to="/maestria-en-derecho-penal" class="text-[14px] text-hgray">Derecho Penal</router-link></li>
						<li><router-link to="/maestria-en-educacion" class="text-[14px] text-hgray">Educaci&oacute;n</router-link></li>
						<li><router-link to="/maestria-en-justicia-penal" class="text-[14px] text-hgray">Justicia Penal</router-link></li>
						<li><router-link to="/maestria-en-mercadotecnia" class="text-[14px] text-hgray">Mercadotecnia</router-link></li>
						<li><router-link to="/maestria-en-psicoterapia" class="text-[14px] text-hgray">Psicoterapia</router-link></li>
					</ul>
				</div>
				<div class="w-full p-2 text-left">
					<h3 class="font-bold text-gray-600 text-[14px] mb-1">DOCTORADO</h3>
					<ul class="list-none p-0 m-0">
						<li><router-link to="/doctorado-en-alta-direccion" class="text-[14px] text-hgray">Alta Direcci&oacute;n</router-link></li>
						<li><router-link to="/doctorado-en-derecho" class="text-[14px] text-hgray">Derecho</router-link></li>
						<li><router-link to="/doctorado-en-educacion" class="text-[14px] text-hgray">Eduaci&oacute;n</router-link></li>
					</ul>
					<br>
					<h3 class="font-bold text-gray-600 whitespace-nowrap text-[14px] mb-1">MODELOS EDUCATIVOS</h3>
					<ul class="list-none p-0 m-0">
						<li><router-link to="/escolarizado" class="text-[14px] text-hgray">Escolarizado</router-link></li>
						<li><router-link to="/ejecutivo" class="text-[14px] text-hgray">Ejecutivo</router-link></li>
						<!-- <li><router-link to="/virtual" class="text-[14px] text-hgray">Virtual</router-link></li> -->
						<li><a href="https://virtual.humanitas.edu.mx/" target="_blank" class="text-[14px] text-hgray">Virtual</a></li>
					</ul>
				</div>
				<div class="w-full p-2 text-left">
					<h3 class="font-bold text-gray-600 text-[14px] mb-1">CAMPUS</h3>
					<ul class="list-none p-0 m-0">
						<li><a href="https://humanitas.edu.mx/campus/cancun" target="_blank" class="text-[14px] text-hgray">Canc&uacute;n, Q. Roo</a></li>
						<li><router-link to="/contacto" class=" text-[14px] text-hgray">Cuernavaca, Morelos</router-link></li>
						<li><router-link to="/contacto" class=" text-[14px] text-hgray">Chihuahua, Chihuahua</router-link></li>
						<li><a href="https://humanitas.edu.mx/campus/del-valle" target="_blank" class="text-[14px] text-hgray">Del Valle, CDMX</a></li>
						<li><a href="https://humanitas.edu.mx/campus/guadalajara" target="_blank" class="text-[14px] text-hgray">Guadalajara, Jalisco</a></li>
						<li><a href="https://humanitas.edu.mx/campus/los-reyes" target="_blank" class="text-[14px] text-hgray">Los Reyes, Edo. de Mex.</a></li>
						<li><a href="https://humanitas.edu.mx/campus/merida" target="_blank" class="text-[14px] text-hgray">M&eacute;rida, Yucat&aacute;n</a></li>
						<li><a href="https://humanitas.edu.mx/campus/presa-madin" target="_blank" class="text-[14px] text-hgray">Presa Mad&iacute;n, Edo. de Mex.</a></li>
						<li><a href="https://humanitas.edu.mx/campus/queretaro" target="_blank" class="text-[14px] text-hgray">Quer&eacute;taro, Qro.</a></li>
						<li><a href="https://humanitas.edu.mx/campus/santa-fe" target="_blank" class="text-[14px] text-hgray">Santa Fe, CDMX</a></li>
						<li><a href="https://humanitas.edu.mx/campus/tijuana" target="_blank" class="text-[14px] text-hgray">Tijuana, BC</a></li>
						<li><a href="https://virtual.humanitas.edu.mx/" target="_blank" class="text-[14px] text-hgray">Virtual</a></li>
					</ul>
				</div>
				<div class="w-full p-2 text-left col-span-2 md:col-span-1">
					<h3 class="font-bold text-gray-600 text-[14px] mb-1">COMUNIDAD</h3>
					<ul class="list-none p-0 m-0">
						<li><router-link to="/bolsa-de-trabajo" class=" text-[14px] text-hgray">Bolsa de Trabajo</router-link></li>
						<li><router-link to="/educacion-continua" class="text-[14px] text-hgray">Diplomados y Seminarios</router-link></li>
						<li><router-link to="/english-kingdom" class="text-[14px] text-hgray">English Kingdom</router-link></li>
					</ul>
					<br>
					<h3 class="font-bold text-gray-600 text-[14px]s mb-1">CULTURAL</h3>
					<ul class="list-none p-0 mb-6">
						<li><router-link to="/blog" class=" text-[14px] text-hgray">Blog Humanitas</router-link></li>
						<li><a href="https://capitel.humanitas.edu.mx/" target="_blank" class="text-[14px] text-hgray">Revista Capitel</a></li>
						<li><a href="https://trascendi.humanitas.edu.mx/" target="_blank" class="text-[14px] text-hgray">Canal Trascendi</a></li>
						<li><a href="https://legatum.humanitas.edu.mx/" target="_blank" class="text-[14px] text-hgray">Conferencias Legatum</a></li>
					</ul>
					<ul class="list-none w-full mb-6">
						<li class="font-bold"><a href="https://humanitas.edu.mx/Reglamento_Humanitas.pdf" class="text-[14px] text-gray-600 font-bold md:whitespace-nowrap text-text-gray-600" target="_blank">REGLAMENTO HUMANITAS</a></li>
					</ul>
					<ul class>
						<li class="font-bold"><router-link to="/aviso-de-privacidad" class="text-[14px] text-gray-600 font-bold whitespace-nowrap text-text-gray-600">AVISO DE PRIVACIDAD</router-link></li>
					</ul>
				</div>
			</div>
		</div>
		<div class="flex flex-col w-full  justify-center ">
			<div class="flex items-center justify-center w-full mb-1">
				<div class="flex items-center w-full relative">
					<div class="w-[37%] sm:w-[44%] lg:w-[48%]">
						<div class="h-7 border-b-[1px] border-gray-400">&nbsp;</div>
						<div class="h-7">&nbsp;</div>
					</div>
					<div class="w-[26%] sm:w-[12%] lg:w-[4%] flex justify-center lg:mx-2">
						<img src="./compImages/aniv-bn.png" alt="h-logo" class="z-20 inline-block align-middle" />	
					</div>
					<div class="w-[37%] sm:w-[44%] lg:w-[48%]">
						<div class="h-7 border-b-[1px] border-gray-400">&nbsp;</div>
						<div class="h-7">&nbsp;</div>
					</div>
				</div>
			</div>
			<div class="flex items-center justify-center w-full mb-10 lg:mb-2">
				<img src="./compImages/logo-humanitas-black.png" alt="logo-humanitas" class=" inline-block align-middle" />
			</div>
			<div class="grid grid-cols-1 lg:grid-cols-3 gap-4 w-full">
				<div class="order-1 flex justify-center items-center w-full">
					<a href="https://capitel.humanitas.edu.mx/" target="_blank" >
						<img src="./compImages/capitel.png" alt="Capitel" class="h-10 w-10 mx-1.5 py-1" />
					</a>
					<a href="https://trascendi.humanitas.edu.mx/" target="_blank">
						<img src="./compImages/trascendi.png" alt="Trascendi" class="h-10 w-10 mx-1.5" />
					</a>
					<a href="https://legatum.humanitas.edu.mx/" target="_blank" >
						<img src="./compImages/legatum.png" alt="Legatum" class="h-10 w-10 mx-1.5" />
					</a>
					<a href="https://www.threads.net/@universidadhumanitas" target="_blank">
						<img src="./compImages/threats-icons.png" alt="Threaths?" class="h-10 w-10 mx-1.5" />
					</a>
					<a href="https://www.linkedin.com/school/universidad-humanitas/mycompany/" target="_blank">
						<img src="./compImages/link-icons.png" alt="Linkedin" class="h-10 w-10 mx-1.5" />
					</a>
					<a href="https://api.whatsapp.com/send?phone=5215611422526" target="_blank">
						<img src="./compImages/whats-icons.png" alt="WhatsApp" class="h-10 w-10 mx-1.5" />
					</a>
				</div>
				<div class="order-3 lg:order-2 mt-10 lg:mt-0 flex flex-col items-center justify-center w-full">
					<p class="text-xs">Estudios con Reconocimiento de Validez Oficial. SEP</p>
					<p class=" text-xs">Universidad Humanitas &copy; Copyright. Todos los derechos reservados. 2024</p>
				</div>
				<div class="order-2 lg:order-3 flex justify-center items-center w-full">
					<a href="https://www.facebook.com/universidadhumanitas/" target="_blank">
						<img src="./compImages/face-icons.png" alt="Facebook" class="h-10 w-10 mx-1.5 py-1" />
					</a>
					<a href="https://www.youtube.com/channel/UCRUp4nYBNpyOCcCD5ryyi2Q" target="_blank">
						<img src="./compImages/youtube-icons.png" alt="Youtube" class="h-10 w-10 mx-1.5" />
					</a>
					<a href="https://www.instagram.com/universidadhumanitas/" target="_blank">
						<img src="./compImages/insta-icons.png" alt="Instagram" class="h-10 w-10 mx-1.5" />
					</a>
					<a href="https://www.tiktok.com/@uhumanitasoficial?lang=es" target="_blank">
						<img src="./compImages/tik-icons.png" alt="WhatsApp" class="h-10 w-10 mx-1.5" />
					</a>
					<a href="https://twitter.com/UHumanitas" target="_blank">
						<img src="./compImages/x-icons.png" alt="Twitter" class="h-10 w-10 mx-1.5" />
					</a>
					<a href="https://www.pinterest.com.mx/uhumanitas/" target="_blank">
						<img src="./compImages/pin.png" alt="Pinterest" class="h-10 w-10 mx-1.5" />
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
</script>

<style scoped>
</style>