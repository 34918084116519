<template>
  <div class="flex item-center justify-center w-full  md:hidden bg-[#999999] text-white font-serif text-[24px]" style="overflow-y: hidden;">
    <p class="py-6 cursor-pointer hover:scale-125 transition-transform duration-320" @click="openForm">¡Contáctanos!</p>
    <div v-if="deployment" class="fixed inset-0 z-50 flex cursor-default items-center justify-center bg-black bg-opacity-50">
        <div class="flex relative shadow-lg flex-col items-center px-8 justify-center z-50 bg-opacity-90 bg-[#999999]">
            <div class="flex items-center absolute top-3 right-0 justify-end z-50">
                <button @click="close" class="font-bold justify-center bg-transparent w-[10px] h-[12px] flex items-center text-white hover:text-gray-900 focus:outline-none">X</button>
            </div>
            <div class="flex flex-col w-full z-50 my-8 justify-center">
                <div class="flex flex-col z-50 w-full">
                    <p class="text-white font-serif lg:text-[25px] leading-tight mb-6">Te asesoramos para tomar la<br> mejor decisión educativa</p>
                    <p class="text-white ml-4 text-[14px] text-left mb-6">Campos requeridos*</p>
                </div>
                <div class="flex flex-col login-box form-container font-helvetica w-full mx-10 relative">
                    <form autocomplete="on">
                    <div class="lg:mx-12 mx-4">
                        <div class="user-box">
                            <input v-model="nombre" type="text" id="name" name="nombre" required maxlength="30" class="">
                            <label for="nombre" class=" italic">Nombre*</label>
                        </div>
                        <div class="user-box">
                            <input v-model="apellidos" type="text" id="apelidos" name="apellidos" required maxlength="55" class="">
                            <label for="apellidos" class=" italic">Apellidos*</label>
                        </div>
                        <div class="user-box">
                            <input v-model="email" type="email" id="email" name="email" @change="handleEmailChange" required class="">
                            <label for="email" class=" italic">E-mail*</label>
                        </div>
                        <div class="user-box">
                            <div class="flex">
                                <select v-model="selectedCountry" class="mr-2 rounded-none" style="width: 30%;padding: 10px 10px;font-size: 14px;color: white;border: none;border-bottom: 1px solid white;outline: none;background: transparent;">
                                    <option v-for="country in ladas" :value="country.lada" :key="country.codigo" class="rounded-none" :style="{ backgroundImage: `url(${getFlagUrl(country.codigo)})` }">
                                        {{ country.lada }} 
                                    </option>
                                </select>
                                <div>

                                </div>
                                <div class="w-2/3">
                                    <input v-model="telefono" id="telefono" type="tel" name="telefono" required maxlength="10" class="">
                                    <label for="telefono" class=" italic" style="top: -25px;left: 0;color: white;font-size: 14px">N&uacute;mero de teléfono*</label>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="user-box">
                            <select name="campus" id="campus" v-model="campus"  required class="">
                                <option class="text-[#797979]" value="">Selecciona una opci&oacute;n</option>
                                <option class="text-[#797979]" value="cancun">Canc&uacute;n</option>
                                <option class="text-[#797979]" value="del-valle">Colonia Del Valle CDMX</option>
                                <option class="text-[#797979]" value="guadalajara">Guadalajara</option>
                                <option class="text-[#797979]" value="los-reyes">Los Reyes la Paz</option>
                                <option class="text-[#797979]" value="merida">M&eacute;rida</option>
                                <option class="text-[#797979]" value="presa-madin">Presa Mad&iacute;n</option>
                                <option class="text-[#797979]" value="queretaro">Quer&eacute;taro</option>
                                <option class="text-[#797979]" value="santa-fe">Santa Fe CDMX</option>
                                <option class="text-[#797979]" value="tijuana">Tijuana</option>
                                <option class="text-[#797979]" value="virtual-mex">Virtual</option>
                            </select>
                            <label class="" style="top: -25px;left: 0;color: white;font-size: 14px">Campus de Inter&eacute;s</label>
                        </div> -->
                        <div class="flex">
                        <div class="flex items-center mb-6">
                            <input type="checkbox" v-model="conditions" class=" cursor-pointer accent-[#CAAB55] rounded-none w-4 h-4 bg-[#881912] border-gray-300 focus:ring-[#520400] focus:ring-1 mr-1"> 
                            <label class="text-white italic lg:text-[16px] text-[12px]">
                            He le&iacute;do y acepto el <a href="https://humanitas.edu.mx/aviso-de-privacidad" target="_blank" class="text-white hover:text-[#CAAB55]">Aviso de privacidad</a>.
                            </label>
                        </div>
                    </div>
                        <div class="flex items-center justify-center">
                            <button @click.prevent="" class="rounded-none text-[16px] transition duration-300 ease-in-out hover:bg-[#CAAB55] hover:text-white flex items-center justify-center text-[#999999] bg-[white] w-[150px] h-[35px]">Enviar</button>
                        </div>
                    </div>
                    </form>
                </div>
            </div>
          </div>
      </div>
  </div>
</template>

<script lang="js">
import Swal from 'sweetalert2';
import axios from 'axios';
import { SolicitudBlog } from '@/main';


export default {
    data() {
        return {
            toast : Swal.mixin({
                      toast: true,
                      position: 'top-end',
                      width: 500,
                      padding: '1em',
                      showConfirmButton: false,
                      timerProgressBar: true,
                      timer: 4000,
                      icon: 'error',
                      iconColor: '#881912'
                  }),
            selectedCountry: '+52', // Aquí se almacenará el país seleccionado
            nombre: '',
            apellidos: '',
            //campus:'',
            email: '',
            telefono: '',
            form: 'Home',
            campus: 'Virtual',
            conditions: false,
            tokenizer: '',
            deployment: false,
            ladas: [
                { codigo: "mx", lada: "+52", nombre: "México" },
                { codigo: "us", lada: "+1", nombre: "Estados Unidos" },
                { codigo: "ca", lada: "+1", nombre: "Canadá" },
                { codigo: "gb", lada: "+44", nombre: "Reino Unido" },
                { codigo: "es", lada: "+34", nombre: "España" },
                { codigo: "ar", lada: "+54", nombre: "Argentina" },
                { codigo: "br", lada: "+55", nombre: "Brasil" },
                { codigo: "cl", lada: "+56", nombre: "Chile" },
                { codigo: "co", lada: "+57", nombre: "Colombia" },
                { codigo: "pe", lada: "+51", nombre: "Perú" },
                { codigo: "ve", lada: "+58", nombre: "Venezuela" },
                { codigo: "ec", lada: "+593", nombre: "Ecuador" },
                { codigo: "bo", lada: "+591", nombre: "Bolivia" },
                { codigo: "py", lada: "+595", nombre: "Paraguay" },
                { codigo: "uy", lada: "+598", nombre: "Uruguay" },
                { codigo: "pa", lada: "+507", nombre: "Panamá" },
                { codigo: "cr", lada: "+506", nombre: "Costa Rica" },
                // Agrega más países según sea necesario
            ],
        }
    },
    methods: {
        openForm(){
            this.deployment = true
        },
        close(){
            this.deployment = false
        },
        // @ts-ignore
        getFlagUrl(countryCode) {
            return `https://flagcdn.com/64x48/${countryCode.toLowerCase()}.png`;
        },

        // @ts-ignore
        mostrarErrorSweetAlert(mensaje) {
            this.toast.fire({
            icon: 'error',
            title: 'Datos faltantes',
            text: mensaje,
            });
        },

        currentUrl() {
            return window.location.href;
        },

        validarPrivacidad(){
            if(!this.conditions){
                this.mostrarErrorSweetAlert('Debes estar de acuerdo con el Aviso de privacidad.');
            } else {
                return true
            }
            return false
        },

        validarDatos(){
            if(!/^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s]{1,30}$/.test(this.nombre)){
                this.mostrarErrorSweetAlert('Ingrese un nombre válido.');
            } else {
            if(!/^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s]{1,60}$/.test(this.apellidos)){
                this.mostrarErrorSweetAlert('Por favor ingresa un(os) apellido(s) válido(s).');
            } else {
                if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email)){
                    this.mostrarErrorSweetAlert('Ingrese un correo electrónico válido.')
                }else{
                    if(!/^\d{10}$/.test(this.telefono)){
                        this.mostrarErrorSweetAlert('Ingrese un número de teléfono válido (10 dígitos).');
                    } else {
                        return true
                    }
                }
            }
            }
            return false;
        },

        executeRecaptcha() {
            //@ts-ignore
            grecaptcha.enterprise.execute('6Ldd0x0oAAAAAAaPVjMnVirgyfT6qahpksCqN0UX', { action: 'submit_lead' })
            // @ts-ignore
            .then((token) => {
            this.tokenizer = token;
            })
            // @ts-ignore
            .catch((error) => {
            console.error('Error al ejecutar reCAPTCHA:', error);
            });
        },

        handleEmailChange() {
            if (!this.tokenizer) {
                this.executeRecaptcha();
            }
        },

        submitForm() {
         // Validar el formulario antes de enviar los datos
         if (this.validarDatos() && this.validarPrivacidad()) {
            // Crear un objeto FormData con los datos del formulario
            const formData = new FormData();
            formData.append('nombre', this.nombre );
            formData.append('apellidos', this.apellidos);
            formData.append('email', this.email);
            formData.append('telefono', this.selectedCountry + this.telefono);
            formData.append('g-recaptcha-response',this.tokenizer)
            formData.append('url', this.currentUrl())
            formData.append('campus',this.campus)
            formData.append('form', this.form)
            /* Realizar la solicitud POST
            let peticion = {
                method: 'POST',
                body: formData,
            };*/
            Swal.fire({
                title: 'ENVIANDO...',
                text: 'ESTAMOS ENVIANDO SUS DATOS. NO CIERRE LA VENTANA',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
                didOpen: () => {
                    // @ts-ignore
                    Swal.showLoading();
                    axios.post(SolicitudBlog, formData, {
                    // axios.post(Solicitud, formData, {
                        headers: {'Content-Type': 'multipart/form-data',},
                        responseType: 'json', // Asegúrate de incluir esta línea
                    })
                        // @ts-ignore
                        .then((response) => {
                                // if (response.data.error === false) {
                                    //@ts-ignore
                                    console.log(response);
                                    // if (dataLayer) {
                                    //     //@ts-ignore
                                    //     dataLayer.push({
                                    //     event: 'generate_lead',
                                    //     email: this.email,
                                    //     career: this.selectedOffer,
                                    //     });
                                    // }
                                    Swal.fire({
                                        title: '¡ENVIADO!',
                                        text: 'NUESTROS EJECUTIVOS SE PONDRÁN EN CONTACTO CONTIGO LO ANTES POSIBLE.',
                                        icon: 'success',
                                        iconColor: '#CAAB55',
                                        showConfirmButton: false,
                                        timer: 4000
                                    });
                                    this.nombre = '';
                                    this.apellidos = '';
                                    this.email = '';
                                    this.telefono = '';
                                    this.tokenizer = '';
                                    this.conditions = false;
                                // } else {
                                //     Swal.fire({
                                //         text: `${response.data.descripcion}`,
                                //         icon: "error",
                                //         iconColor: '#881912',
                                //         showConfirmButton: false,
                                //         timer: 4000
                                //     });
                                //     this.tokenizer='';
                                // }
                        // @ts-ignore
                        }).catch( (error) => console.log('error', error) );
                }
            });
          }
        },
    }
}
</script>

<style scoped>
.login-box {
    max-width: 1200px;
    margin: 0 ;
    padding: 0px;
    box-sizing: border-box;
    border: black;
    border-radius: 1px;
    background: transparent;
    z-index: 1;
    
    
  }

  .login-box h2 {
    margin: 0 0 0px;
    padding: 0;
    color: transparent;
    text-align: center;
  }

  .login-box .user-box {
    position: relative;
    z-index: 1;
  }

  .login-box .user-box i {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 10px;
    color: white;
  }

  .form-container {
    position: relative;
    z-index: 1;
  }

  .login-box .user-box input,
  .login-box .user-box select,
  .login-box .user-box textarea {
   width: 100%;
   padding: 10px 10px; /* Ajusta según tu preferencia */
   font-size: 14px;
   color: white;
   margin-bottom: 25px;
   border: none;
   border-bottom: 1px solid white;
   outline: none;
   background: transparent;
}

.login-box .user-box select option {
  color: black; /* Color del texto de las opciones del select cuando se despliegan */
}



  .login-box .user-box label {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    padding: 10px 0;
    font-size: 14px;
    color: white;
    pointer-events: none;
    transition: .5s;
  }

  

  .login-box .user-box input:focus ~ label,
  .login-box .user-box input:valid ~ label,
  .login-box .user-box select:focus ~ label,
  .login-box .user-box textarea:focus ~ label {
    top: -25px;
    left: 0;
    color: white;
    font-size: 14px;
  }



  .login-box .user-box input.error-border,
  .login-box .user-box select.error-border,
  .login-box .user-box textarea.error-border {
    border-bottom: 1px solid #FF0000 !important;
  }

  .login-box .user-box label.error{
    color: #FF0000;
    font-size: 12px;
  }
</style>