<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">¿Ansiedad es lo mismo que angustia?</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-200">
          <img src="/images/ansiedad-mismo-angustia.webp" alt="¿Ansiedad es lo mismo que angustia?, conoce a fondo estos padecimientos." class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/aprende" class=" text-hone">Aprende</router-link> > <router-link to="/blog/aprende/tips" class=" text-hone">Tips</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex text-left list-disc pl-5 italic text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">08 de julio de 2024</li>  <li>Iván Solís Rivera</li></ul>
            </div>
            <p class="md:mb-4 mb-6">En la sociedad contemporánea es reiterativa la pregunta: ¿Ansiedad es lo mismo que angustia?, debido a que los <b>problemas mentales se han ido incrementando, los cuales están relacionados con los cambios rápidos en términos de tecnología, economía y estructura social,</b> lo que genera una sensación de inestabilidad e incertidumbre.</p>
            <p class="md:mb-4 mb-6">De acuerdo con el psicoterapeuta, Eugenio Medina, <b>los conceptos de ansiedad y de angustia están relacionados, pero no son lo mismo.</b> El experto cita a Sigmund Freud catalogó a la ansiedad como un apronte de angustia.</p>
            <p class=" md:mb-8 mb-10">Ante este contexto, <b>la ansiedad tiende a estar asociada con la anticipación de amenazas específicas</b> y puede ser vista como una señal del ego en respuesta a conflictos inconscientes, mientras que <b>la angustia tiene una connotación profunda, frecuentemente vinculada a cuestiones existenciales</b> y a una sensación de malestar interno generalizado.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Ataque de ansiedad: un problema psicológico latente</h2>
            <p class="md:mb-4 mb-6">Un ataque de ansiedad es una de las manifestaciones más agresivas de este padecimiento, de acuerdo con Medina, este término se integra a una <b>especie de ansiedad patológica, que es desadaptativa, dificulta el contacto con la realidad y sobre todo, disfrutar de las cosas que la vida ofrece.</b></p>
            <p class=" md:mb-4 mb-6">“<b>En el sentido patológico podemos hablar de muchas variantes de la ansiedad: las fobias, la neurosis obsesiva, el estrés postraumático;</b> estas son formas o manifestaciones de la ansiedad a un nivel más patológico”, indica el experto.</p>
            <p class=" md:mb-4 mb-6">Otro tipo de ansiedad se refleja a través del cuerpo, en el que <b>hay pacientes que manifiestan la ansiedad en los movimientos: se rascan, se agitan;</b> necesitan estar moviéndose como si tuvieran un tic nervioso.</p>
            <p class=" md:mb-4 mb-6">El <b>otro tipo de ansiedad tiene que ver con lo mental, que van acompañados de pensamientos negativos y obsesivos.</b> “Muchas de las enfermedades que están relacionadas con la cabeza, el estómago, la piel, son efecto de ansiedad que se pasa directamente al cuerpo, <b>ya en casos más patógenos, estamos hablando de personas que sienten celotipia o paranoia.</b>”</p>
            <p class=" md:mb-8 mb-10">Un tipo de ansiedad compleja es la conocida como angustia primitiva que está relacionada con la ansiedad y los sistemas de memoria, apareciendo en los primeros meses de vida: “no es una ansiedad que se pueda llevar al campo de la palabra… <b>el bebé aún no tiene lenguaje desarrollado y sus experiencias van a quedar grabadas en el sistema somático, en algo que llamamos memoria implícita,</b> en el que muchas de las vivencias difíciles y traumáticas que el bebé va a vivir en relación con su cuidador y que posteriormente surgirán cuadros de ansiedad psicosomática sin aparente motivo.”</p>
            <VideoClip :link="'https://www.youtube.com/embed/Af9DRoSTwPQ?si=qgSghFo1ArmVH8Ts'" />
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">¿Tienes personalidad ansiosa?</h2>
            <p class="md:mb-4 mb-6">Aquella persona que <b>está acostumbrada a adelantarse a las cosas, a prever, actuar, planificar tiene una personalidad ansiosa,</b> señala Medina.</p>
            <p class="md:mb-4 mb-6">Y agrega: “<b>es alguien que necesita en movimiento, que resolver y trabajar, pero es funcional y no le provoca conflicto psíquico</b>, a este tipo sería lo que Freud llamó una neurosis de angustia; todos tenemos rasgos ansiosos que pueden ser saludables.”</p>
            <p class="md:mb-4 mb-6"><b>El problema empieza cuando la ansiedad rebasa el cuerpo</b>, insatisfacción, disfuncionalidad y conflictos sociales, conflictos de pareja, dificultades laborales.</p>
            <p class="md:mb-8 mb-10"><b>La ansiedad no se puede quitar porque forma parte de los sistemas emocionales básicos</b>, nos sirve para defendernos en la vida.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Ansiedad: prima-hermana del miedo</h2>
            <p class="md:mb-4 mb-6">Joseph LeDoux, destacado neurocientífico, <b>desarrolló una tesis sobre la relación entre la ansiedad y el miedo basada en sus investigaciones sobre el cerebro</b>, particularmente en la amígdala y otras estructuras relacionadas con las emociones.</p>
            <p class="md:mb-4 mb-6">La tesis de LeDoux distingue entre miedo y ansiedad, señalando que <b>el miedo es una respuesta emocional inmediata a un peligro concreto y presente, mientras que la ansiedad es una respuesta anticipatoria</b> ante una amenaza potencial o futura.</p>
            <p class="md:mb-4 mb-6"><b>El miedo se origina en la amígdala, una estructura del cerebro que se activa rápidamente ante estímulos amenazantes.</b> La amígdala desencadena respuestas automáticas de lucha o huida para proteger al organismo de peligros inmediatos. Aunque la <b>amígdala también está involucrada en la ansiedad, esta emoción implica circuitos cerebrales más complejos, incluyendo el hipocampo y la corteza prefrontal.</b></p>
            <p class="md:mb-4 mb-6"><b>La ansiedad se relaciona más con la memoria y la evaluación de riesgos a largo plazo, involucrando procesos cognitivos superiores que evalúan</b> y anticipan peligros futuros.</p>
            <p class="md:mb-4 mb-6">El especialista sugiere que para abordar efectivamente la ansiedad y los trastornos relacionados, es crucial entender estas diferencias y desarrollar intervenciones específicas <b>que aborden tanto los mecanismos inmediatos de respuesta al miedo como los procesos cognitivos más complejos que generan la ansiedad.</b></p>
            <p class="md:mb-8 mb-10">Ante esta premisa, Medina cita la obra de Freud que indica que <b>la angustia es señal es algo que viene de dentro se detecta un peligro,</b> pero curiosamente la persona que sufre ansiedad no sabe qué es peligro, la amígdala tampoco lo sabe, por eso mucha gente no detecta siquiera su ansiedad, simplemente tiene problemas estomacales, dolores de cabeza, dermatitis y todo esto tiene que ver con su ansiedad: <b>el cuerpo expresa lo que las palabras no pueden decir</b>, asegura.</p>
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
                <ContactoVue class="m-3"/>
            </div>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Relación entre miedo y ansiedad</h3>
            <p class="md:mb-4 mb-6"><b>La ansiedad y el miedo son emociones inherentes del ser humano, nos permiten reaccionar ante los estímulos externos y activar nuestro sistema de sobrevivencia.</b> El experto explica que la ansiedad es necesaria para vivir porque nos permite adelantarnos y resolver de manera natural.</p>
            <p class="md:mb-4 mb-6">Por otro lado, <b>el miedo nos permite preservar la vida, generalmente se presenta dejándonos paralizados, huyendo o nos prepara para enfrentar una situación extrema.</b></p>
            <p class="md:mb-8 mb-10"><b>La relación entre el miedo y la ansiedad, es que el primero se presenta ante una situación externa y que detectamos por medio de los sentidos, mientras que la ansiedad viene de nuestro sistema biológico</b> o de todo aquello que quedó de alguna manera almacenado, lo que Sigmund Freud llamaba el inconsciente o memoria implícita.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">¿La ansiedad es genética o cómo surge?</h2>
            <p class="md:mb-4 mb-6">Creemos que la ansiedad tiene que ver con situaciones estresantes o problemáticas, pero tiene un origen genético, considera el psicoterapeuta.</p>
            <p class="md:mb-4 mb-6">Una segunda posible causa de la ansiedad, se da en el desarrollo de la persona, mucho tiempo, el psicoanálisis ubicó el origen de <b>este padecimiento en la niñez, poco a poco se detectó que sus orígenes se dan en las experiencias tempranas, hablamos primeros meses de vida</b> donde la persona está indefensa, sujeto a las variaciones del entorno y sobre todo sujeto a las variaciones de carácter del cuidador primario.</p>
            <p class="md:mb-8 mb-10">“<b>Muchas de las ansiedades que va a manifestar una persona en la vida adulta tienen que ver con el desarrollo temprano y el vínculo con el cuidador</b>, si el cuidador es eficiente  tendremos como resultado una ansiedad baja, desde luego, si no es suficientemente bueno es muy probable que tengamos un exceso de ansiedad en la etapa adulta”, apunta el experto.</p>
            <img src="/images/ataque-ansiedad-mujer.webp" alt="Los ataques de ansiedad son las manifestaciones más dañinas." class="md:mb-8 mb-10">
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Ansiedad, angustia y miedo: ¿cómo evitarlas?</h3>
            <p class="md:mb-4 mb-6">El psicoterapeuta  recomienda controlar y canalizar la ansiedad hacia actividades que permitan disminuir el conflicto y que sean socialmente productivas. El paciente debe aprender a detectar su ansiedad, recomienda Medina.</p>
            <p class="md:mb-4 mb-6">Cuando la personalidad es ansiosa <b>se puede enfocar al ejercicio</b>, ya que permite que se segreguen sustancias que favorecen un estado de ánimo estable.</p>
            <p class="md:mb-4 mb-6">Otra manera de evitar la ansiedad es tener <b>una alimentación balanceada, no consumir sustancias químicas que alteran nuestro sistema nervioso</b>, evitar el café, bebidas energéticas, dulces o chocolates.</p>
            <p class="md:mb-4 mb-6">Cuando la ansiedad no es exagerada, <b>se puede recurrir al sistema de relajación y respiración,</b> con técnicas como la de los cuatro ciclos, conteo regresivo, entre otras.</p>
            <p class="md:mb-4 mb-6">Cuando la situación es más compleja, <b>el paciente debe trabajar por medio de Psicoterapia, cognitivo conductual que apunta al aprendizaje y pensamientos que fueron convertidos en factores de ansiedad</b> y reflexionar sobre formas de pensamiento sobre estereotipos o también optar por la hipnosis que va más profundidad de los pensamientos.</p>
            <p class="md:mb-4 mb-6"><b>La manera clásica para el trabajo de la ansiedad ha sido el <router-link to="/blog/academico/maestria/maestria-psicologia-guia-completa" class=" text-htwo hover:text-hone">Psicoanálisis</router-link> porque Freud pensaba que había un conflicto interno,</b> en ese sentido hay que hacer consciente eso inconsciente que detonan los padecimientos.</p>
            <p class="md:mb-4 mb-6">Cuando muchas de estas situaciones no se pueden solucionar con la pura Psicoterapia entonces recurrimos a la cuestión farmacológica: <b>“Me atrevo a decirles que toda ansiedad debe ser tratada por estas dos vías: lo terapéutico y lo farmacológico sobre todo cuando la ansiedad es sumamente intensa.”</b></p>
            <p class="md:mb-4 mb-6">“La mayor parte de los antidepresivos funcionan muy bien para disminuir la ansiedad porque recuerden: la ansiedad siempre va acompañada de la depresión, eso se puede evitar con la fluoxetina, sertralina o bien con benzodiacepinas, clonazepam, que regulan y bajan la actividad cerebral.”</p>
            <p class="md:mb-8 mb-10">A manera de conclusión, el experto hace énfasis en que <b>los humanos somos <router-link to="/blog/aprende/tendencias/tendencias-en-psicologia-2024-tecnologia-salud-mental" class=" text-htwo hover:text-hone">mente y cuerpo</router-link>, lo que le pase a la mente afecta al cuerpo</b>, de tal manera que cuando elijamos trabajar la ansiedad tenemos que pensar que al estar trabajando con la mente.</p>
            <img src="/images/como-evitar-angustia-miedo.webp" alt="La respuesta clásica para: ¿cómo evitar la angustia y el miedo? es acudir a terapia." class="md:mb-8 mb-10">
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">La Psicotereapia como respuesta a la ansiedad en el siglo XXI</h2>
            <p class="md:mb-4 mb-6"><b>El siglo XX y XXI se ha caracterizado por la existencia de múltiples formas de familia en el que  aparecen múltiples formas de familia</b>, dando como resultado el poco cuidado por los bebés, por lo que a veces tienen vivencias traumáticas y dispersas.</p>
            <p class="md:mb-4 mb-6"><b>“De ahí que sea tan importante voltear los ojos en esta época al cuidado que tiene el cuidador primario con el bebé.”</b></p>
            <p class="md:mb-4 mb-6"><b>Las ansiedades tempranas de una persona son de las más complicadas de trabajar a nivel psicoterapéutico,</b> ya que tiene que ver con la dificultad que tuvo el bebé en el incio de su vida.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Conclusión</h2>
            <p class="md:mb-4 mb-6">La ansiedad no tiene una sola forma de manifestación, <b>tenemos la presencia de manifestaciones múltiples de este padecimiento, es un problema generalizado de nuestra época</b> que causa tanto sufrimiento a las personas <b>y que es necesario revisar a profundidad.</b></p>
            <p class="mb-20">“<b>Si tenemos cada vez personas con más ansiedad es porque hemos descuidado mucho el contacto inicial con el bebé</b> y tendríamos que recuperar formas de estar con el bebé, pero desde una perspectiva integral”, finaliza Medina.</p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="true" :linkAnt="'/recursos/casos-reales/como-identificar-y-superar-relaciones-toxicas'" :linkSig="'/aprende/tips/que-hacer-si-no-te-quedaste-en-el-ipn'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import ContactoVue from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue';
import SigArt from '../../pagesComponents/SigArt.vue';
import VideoClip from '../../pagesComponents/videoClip.vue'

export default {
    components: { Comentarios, ConComentarios, ContactoVue, LikesVue, ShareP, SigArt, VideoClip},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '0055-ART-APRENDE-TIPS-050724',
            ruta: '/aprende/tips/puntos-clave-para-evaluar-la-calidad-de-una-universidad-privada',
            title: '¿Cómo evaluar la calidad de una universidad privada?',
            image: 'https://blog.humanitas.edu.mx/images/Costo-y-requisitos-estudiar-maestria.jpg',
            text: 'Alguna vez te haz preguntado: ¿Ansiedad es lo mismo que angustia?, ¿Haz experimentado estos padecimientos?, te decimos cómo evitarlos.'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx/academico/maestria/costo-y-requisitos-estudiar-maestria';

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>