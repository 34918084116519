<template>
  <section class="flex items-center justify-center w-full" style="background-image: url(../pagesImages/bg-carrusel.jpg);">
    <div class="max-w-4xl lg:max-w-[1200px]">
      <div class="lg:flex items-center justify-center">
        <div class="lg:w-5/12 lg:mr-10 mr-8 lg:my-10 my-8">
          <div class="mb-4">
            <h1 class="text-[30px] text-justify text-[#A69A68] font-bold font-serif mb-2">CONTAMOS CON</h1>
            <h1 class="text-[30px] text-justify text-[#A69A68] font-bold font-serif mb-2">10 CAMPUS</h1>
            <hr class=" border-[rgb(166,154,104)]">
            <p class="text-white text-justify">Durante el desarrollo académico de nuestros alumnos, promovemos la construcción de relaciones con personas de su entorno profesional o con las que en un futuro pudieran establecer un plan de negocios, lo cual se lleva a cabo no sólo dentro del aula, sino también mediante los coffee breaks que ofrecemos en el transcurso de sus sesiones.</p>
            <br>
            <p class="text-white text-justify">Tenemos presencia en diversos estados de la República Mexicana. Nuestros campus cuentan con una arquitectura neoclásica, con jardines y fuentes diseñados para fomentar el estudio y la reflexión. La arquitectura conserva y crea espacios verdes, impulsando la reforestación y el cuidado de la naturaleza, ya que tenemos la convicción de que el respeto y el amor por el entorno contribuirá a mejorar el mundo.</p>
          </div>

          <div class="flex items-center justify-center space-x-16">
            <Botones :button="buttons.button1" class="cursor-pointer" @click="prevSlide"></Botones>
            <Botones :button="buttons.button2" class="cursor-pointer" @click="nextSlide"></Botones>
          </div>
        </div>

        <div class="lg:w-7/12 flex items-center justify-center">
          <img :src="images[currentIndex]" alt="Descripción de la imagen" class="lg:w-[720px] lg:h-[510px] md:w-[600px] md:h-[418px] sm:w-[540px] sm:h-[380px] m-4">
        </div>
      </div>
    </div>
    
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Botones from './Botones.vue';

export default defineComponent ({
  components: {  Botones },
  data() {
    return {
      buttons: {
          button1: {
            imageUrl: "../pagesImages/atras-off.png", hoverImageUrl: "../pagesImages/atras-on.png"
          },
          button2: {
            imageUrl: "../pagesImages/siguiente-off.png", hoverImageUrl: "../pagesImages/siguiente-on.png"
          }
        },
      currentIndex: 0,
      images: [
        '../pagesImages/116401080_228743281616607_4131566402572531586_n.jpg',
        '../pagesImages/116401080_228743281616607_4131566402572531586_n.jpg',
        '../pagesImages/179071862_486677712767876_479713010229323467_n-crop-u398829.jpg',
        '../pagesImages/179095437_3969334386514485_3484680881766495808_n.jpg',
        '../pagesImages/180317109_295029675577814_3132669237070582229_n-crop-u392301.jpg',
        '../pagesImages/180725335_218449869648085_5134391566975440123_n.jpg',
        '../pagesImages/180838694_497858434686252_7988043031797044715_n.jpg',
        '../pagesImages/239184523_364193365333056_8968843177232698289_n.jpg',
        '../pagesImages/239590362_577951929876572_1693293809879785747_n.jpg',
        '../pagesImages/240514173_882122622404013_845367567683097357_n-crop-u392676.jpg',
        '../pagesImages/257484062_955953738351170_3072317899509474385_n.jpg',
        '../pagesImages/283288112_1444484359334350_6214725199489656452_n-crop-u392751.jpg',
        '../pagesImages/287497738_2282632191875359_4477791725656377912_n.jpg',
        '../pagesImages/297956695_540870157813384_2961324351727549099_n.jpg',
        '../pagesImages/307442347_497212768895845_2942805897818005511_n.jpg',
        '../pagesImages/307725271_190355196713602_6818065362456252371_n.jpg',
        '../pagesImages/313899827_1318639898879251_1373866736255668524_n-crop-u390390.jpg',
        '../pagesImages/318684203_464915238913084_964405860653074461_n.jpg',
        '../pagesImages/335696406_152339217738562_6240956185813351534_n.jpg',
        '../pagesImages/343358654_1633726773707875_7890475389848971168_n.jpg',
        '../pagesImages/356962794_828541261668288_7304006165859652578_n.jpg'
        
      ] as string[],
      autoplayInterval: null as ReturnType<typeof setInterval> | null,
    };
  },
  methods: {
    prevSlide() {
      this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
    },
    startAutoplay() {
      this.autoplayInterval = setInterval(this.nextSlide, 3000); // Cambia la duración según sea necesario
    },
    stopAutoplay() {
      if (this.autoplayInterval) {
        clearInterval(this.autoplayInterval);
      }
    },
  },
  mounted() {
    this.startAutoplay();
  },
  beforeUnmount() {
    this.stopAutoplay();
  },
});
</script>

<style>

</style>