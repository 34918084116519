<script setup lang="ts">
    import VideoHiL from '../VideoHiL.vue';

    interface Props {
        img?: string;
        url?: string;
    }

    const props = withDefaults(defineProps<Props>(), {
        img: '/pagesImages/video-ejec.jpg',
        url: 'https://www.youtube.com/embed/tWkEDcMN1Fo?si=9xvO4XVjTRWqT_Mj',
    });
</script>

<template>
    <section class="lg:flex items-center justify-center bg-cover bg-[top_left] bg-no-repeat" style="background-image: url(/pagesImages/nosotros.jpg); position: relative; background-attachment: fixed; background-position: center; background-repeat: no-repeat; background-size: cover; overflow: hidden; transition: transform 0.3s ease;" >
        <VideoHiL :thumbnailUrl="props.img" :videoUrl="props.url" class="lg:p-0 md:px-16 px-6 pt-6"/>
        <div class="flex flex-col lg:justify-start justify-center items-center lg:items-start lg:w-[40%] w-full py-24 text-justify md:px-4 px-12">
            <p class=" text-[28px] text-white leading-none text-center md:text-left">Excelencia</p>
            <p class="text-hgold text-[42px] mb-4 leading-tight text-center md:text-left font-serif">Acad&eacute;mica</p>
            <p class="text-justify text-white">La educaci&eacute;n que se imparte en Universidad Humanitas se replantea constantemente metas y expectativas a fin de responder a las necesidades del contexto actual, sirvi&eacute;ndose de diferentes recursos, estrategias y herramientas que permiten el desarrollo de un trabajo acad&eacute;mico independiente y con un alto grado de flexibilidad para desarrollar en sus alumnos j&eacute;venes o adultos las capacidades intelectuales indispensables para el aprendizaje de la profesi&eacute;n.</p>
            <br>
            <router-link to="/contacto">
                <div class="flex items-center justify-center w-[140px] h-[35px] bg-hwine hover:bg-hgold text-white text-lg transition duration-300 ease-in-out">
                    <span>Inscr&iacute;bete</span>
                </div>
            </router-link>
        </div>
    </section>
</template>

<style lang="scss" scoped>
</style>