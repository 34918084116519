<template>
    <div>
      <CataBueno :titulo="'Junio 2024'" :subtitulo="''" :filtros="filtros" :articles="articles"/>
    </div>
</template>

<script>
import CataBueno from '../../pagesComponents/CataBueno.vue';

export default {
components: { CataBueno },
data() {
    return {
    articles: [
        {
          "id": "0051-ART-APRENDE-CULTURA-GENERAL-270624",
          "titulo": "Día Mundial de las Redes Sociales: Celebrando el 30 de junio",
          "fecha": "27 de junio de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "Día Mundial de las Redes Sociales: conoce por qué se celebra y cómo las redes sociales influyen en nuestras vidas.",
          "portada": "/images/dia-mundial-redes-sociales-30-junio.webp",
          "enlace": "/aprende/cultura-general/dia-mundial-redes-sociales-30-junio-2024"
        },
        {
          "id": "0052-ART-ACADEMICO-EDUCACION-CONTINUA-270624",
          "titulo": "Educación continua: ¿Qué es y porqué es importante?",
          "fecha": "27 de junio de 2024",
          "autor": "Iván Solís Rivera",
          "descripcion": "Descubre qué es la educación continua y cómo puede ayudarte a mantenerte actualizado en tu carrera profesional.",
          "portada": "/images/educacion-continua-universitaria.webp",
          "enlace": "/academico/educacion-continua/educacion-continua-que-es"
        },
        {
          "id": "0049-ART-ACADEMICO-LICENCIATURA-250624",
          "titulo": "Identifica las mejores universidades para estudiar psicología en México",
          "fecha": "25 de junio de 2024",
          "autor": "Iván Solís Rivera",
          "descripcion": "Reconoce las mejores universidades para estudiar psicología en México tomando en cuenta aspectos como validez educativa, infraestructura y planes de estudio.",
          "portada": "/images/mejores-universidades-estudiar-psicologia-mexico.webp",
          "enlace": "/academico/licenciatura/mejores-universidades-para-estudiar-psicologia-en-mexico"
        },
        {
          "id": "0047-ART-ACADEMICO-DOCTORADO-240624",
          "titulo": "Descubre las mejores opciones de universidades con doctorado en México",
          "fecha": "24 de junio de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "Explora estas alternativas de las mejores universidades para estudiar un doctorado en México.",
          "portada": "/images/doctorado-humanitas-para-alcanzar-tus-metas.webp",
          "enlace": "/academico/doctorado/universidades-con-doctorado-en-mexico-opciones"
        },
        {
          "id": "0048-ART-ACADEMICO-LICENCIATURA-200624",
          "titulo": "Licenciatura en Educación y Administración de centros educativos: ¿Qué es?",
          "fecha": "20 de junio de 2024",
          "autor": "Iván Solís Rivera",
          "descripcion": "La licenciatura en Educación y Administración de centros educativos combina conocimientos de pedagogía, gestión administrativa y liderazgo educativo.",
          "portada": "/images/licenciatura-educacion-administracion-centros-educativos.webp",
          "enlace": "/academico/licenciatura/licenciatura-en-educacion-y-administracion-de-centros-educativos"
        },
        {
          "id": "0045-ART-ACADEMICO-DOCTORADO-180624",
          "titulo": "Doctorados virtuales en México: especialización y conocimiento",
          "fecha": "19 de junio de 2024",
          "autor": "Iván Solís Rivera",
          "descripcion": "Los doctorados virtuales en México representan la culminación de los estudios universitarios que contemplan investigación y conocimiento profundo sobre un tema.",
          "portada": "/images/doctorados-virtuales-mexico-estudiantes.webp",
          "enlace": "/academico/doctorado/doctorados-virtuales-en-mexico"
        },
        {
          "id": "0046-ART-ACADEMICO-LICENCIATURA-180624",
          "titulo": "Arquitectura: ¿Licenciatura o ingeniería? Aquí te decimos",
          "fecha": "18 de junio de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "Conoce si la arquitectura es licenciatura o ingeniería, qué estudia y su diferencia con la ingeniería civil.",
          "portada": "/images/arquitectura-arte-de-disenar-y-construir.webp",
          "enlace": "/academico/licenciatura/arquitectura-licenciatura-o-ingenieria-te-decimos"
        },
        {
          "id": "0043-ART-ACADEMICO-LICENCIATURA-130624",
          "titulo": "Descubre cuáles son las principales corrientes y teorías psicológicas",
          "fecha": "13 de junio de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "Exploramos las principales corrientes y teorías psicológicas, sus modelos y representantes destacados. Ideal para ti que quieres saber más.",
          "portada": "/images/corrientes-psicologicas-y-principales-representantes.webp",
          "enlace": "/academico/licenciatura/principales-corrientes-teorias-psicologicas-modelos-y-representantes"
        },
        {
          "id": "0041-ART-ACADEMICO-EDUCACION-CONTINUA-130624",
          "titulo": "Qué es un seminario: todo lo que quieres saber",
          "fecha": "13 de junio de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "Conoce más sobre el seminario de investigación, su importancia en la educación continua y cómo potencia tu conocimiento. ¡Infórmate!",
          "portada": "/images/que-es-seminario-todo-lo-que-debes-saber.webp",
          "enlace": "/academico/educacion-continua/que-es-un-seminario-todo-lo-que-quieres-saber"
        },
        {
          "id": "0044-ART-APRENDE-CULTURA-GENERAL-130624",
          "titulo": "¿Cuántos pisos tiene la torre de Pemex y cuál es su historia?",
          "fecha": "13 de junio de 2024",
          "autor": "Iván Solís Rivera",
          "descripcion": "Conoce cuántos pisos tiene la torre de Pemex y cuál es la historia de este edificio, que es símbolo de la arquitectura moderna mexicana.",
          "portada": "/images/cuantos-pisos-tiene-torre-pemex.webp",
          "enlace": "/aprende/cultura-general/cuantos-pisos-tiene-la-torre-de-pemex"
        },
        {
          "id": "0042-ART-ESTUDIA-EN-LINEA-DERECHO-110624",
          "titulo": "Licenciatura en Derecho virtual: cómo tener éxito",
          "fecha": "11 de junio de 2024",
          "autor": "Iván Solís Rivera",
          "descripcion": "La Licenciatura en Derecho virtual ofrece una formación integral sobre la normatividad jurídica e institucional de la abogacía y sus ramas de conocimiento.",
          "portada": "/images/licenciatura-derecho-virtual-corporativo.webp",
          "enlace": "/academico/estudia-en-linea/licenciatura-en-derecho-virtual"
        },
        {
          "id": "0039-ART-ACADEMICO-LICENCIATURA-060624",
          "titulo": "¿Qué es un acto administrativo? Guía para entenderlo",
          "fecha": "06 de junio de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "Entender qué es un acto administrativo es muy importante, ya que es esencial para el funcionamiento de la administración pública e impacta en la vida de los ciudadanos.",
          "portada": "/images/que-es-acto-administrativo-concepto-ejemplos.webp",
          "enlace": "/academico/licenciatura/que-es-un-acto-administrativo"
        },
        {
          "id": "0040-ART-LICENCIATURA-CONTABILIDAD-060624",
          "titulo": "Tipos de Contabilidad que permiten una mejor planificación de capital",
          "fecha": "06 de junio de 2024",
          "autor": "Iván Solís Rivera",
          "descripcion": "Reconocer los tipos de Contabilidad ayudan a la toma de decisiones informadas y mejorar la planificación de recursos.",
          "portada": "/images/tipos-contabilidad-financiero.webp",
          "enlace": "/academico/licenciatura/tipos-de-contabilidad"
        },
        {
          "id": "0036-ART-RECURSOS-ENTREVISTAS-Y-CONFERENCIAS-060624",
          "titulo": "Características de la mediación: la forma para evitar los tribunales",
          "fecha": "04 de junio de 2024",
          "autor": "Iván Solís Rivera",
          "descripcion": "Respondemos a la pregunta: ¿Qué es y cuáles son las características de la mediación; la manera alternativa de evitar largos juicios.",
          "portada": "/images/caracteristicas-mediacion-litigio-negociacion.webp",
          "enlace": "/recursos/entrevistas-y-conferencias/caracteristicas-de-la-mediacion"
        },
        {
          "id": "0037-ART-ACADEMICO-EDUCACION-EN-LINEA-040624",
          "titulo": "Descubre la licenciatura de Psicología en línea y por qué puede ser para ti",
          "fecha": "04 de junio de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "¿Te interesa estudiar Psicología en línea? Entérate por qué puede ser la mejor opción para ti.",
          "portada": "/images/licenciatura-psicologia-en-linea-una-opcion-humanitas.webp",
          "enlace": "/academico/estudia-en-linea/licenciatura-psicologia-en-linea"
        },
        {
          "id": "0034-ART-SER-HUMANITAS-RESPONSABILIDAD-SOCIAL-030624",
          "titulo": "Responsabilidad Social Individual: Descubre qué es e inspírate",
          "fecha": "03 de junio de 2024",
          "autor": "Carolina Reyes Martínez",
          "descripcion": "Explora qué es la Responsabilidad Social Individual, qué  tipos existen y ejemplos. Aprende cómo puedes marcar la diferencia en tu comunidad y entorno.",
          "portada": "/images/responsabilidad-social-individual-transforma-entorno.webp",
          "enlace": "/ser-humanitas/responsabilidad-social/responsabilidad-social-individual-tipos-ejemplos-inspiracion"
        },
    ], // Array de artículos original
    filtros: {
      "0051-ART-APRENDE-CULTURA-GENERAL-270624": {
        Humanidades: ["Educación", "Psicología"],
        Ciencias_sociales: ["Administración"],
        Economico_administrativas: []
      },
      "0052-ART-ACADEMICO-EDUCACION-CONTINUA-270624": {
        Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y Teatro"],
        Ciencias_sociales: ["Derecho", "Ciencias Políticas y Administración Pública"],
        Economico_administrativas: ["Administración", "Contabilidad"]
      },
      "0049-ART-ACADEMICO-LICENCIATURA-250624": {
        Humanidades: ["Psicología"],
        Ciencias_sociales: [],
        Economico_administrativas: []
      },
      "0047-ART-ACADEMICO-DOCTORADO-240624": {
        Humanidades: ["Educación"],
        Ciencias_sociales: ["Derecho"],
        Economico_administrativas: ["Administración"]
      },
      "0045-ART-ACADEMICO-DOCTORADO-180624": {
        Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y Teatro"],
        Ciencias_sociales: ["Derecho", "Ciencias Políticas y Administración Pública"],
        Economico_administrativas: ["Administración", "Contabilidad"]
      },
      "0048-ART-ACADEMICO-LICENCIATURA-200624": {
        Humanidades: ["Educación"],
        Ciencias_sociales: [],
        Economico_administrativas: []
      },
      "0046-ART-ACADEMICO-LICENCIATURA-180624": {
        Humanidades: ["Arquitectura"],
        Ciencias_sociales: [],
        Economico_administrativas: []
      },
      "0043-ART-ACADEMICO-LICENCIATURA-130624": {
        Humanidades: ["Psicología"],
        Ciencias_sociales: [],
        Economico_administrativas: []
      },
      "0041-ART-ACADEMICO-EDUCACION-CONTINUA-130624": {
        Humanidades: ["Psicología"],
        Ciencias_sociales: [],
        Economico_administrativas: []
      },
      "0044-ART-APRENDE-CULTURA-GENERAL-130624": {
        Humanidades: ["Arquitectura"],
        Ciencias_sociales: [],
        Economico_administrativas: []
      },
      "0042-ART-ESTUDIA-EN-LINEA-DERECHO-110624": {
        Humanidades: [],
        Ciencias_sociales: ["Derecho"],
        Economico_administrativas: []
      },
      "0039-ART-ACADEMICO-LICENCIATURA-060624": {
        Humanidades: [],
        Ciencias_sociales: ["Derecho", "Ciencias Políticas y Administración Pública"],
        Economico_administrativas: ["Administración"]
      },
      "0040-ART-LICENCIATURA-CONTABILIDAD-060624": {
        Humanidades: [],
        Ciencias_sociales: [],
        Economico_administrativas: ["Administración", "Contabilidad"]
      },
      "0037-ART-ACADEMICO-EDUCACION-EN-LINEA-040624": {
        Humanidades: ["Psicología"],
        Ciencias_sociales: [],
        Economico_administrativas: []
      },
      "0036-ART-RECURSOS-ENTREVISTAS-Y-CONFERENCIAS-060624": {
        Humanidades: [],
        Ciencias_sociales: ["Derecho", "Ciencias Políticas y Administración Pública"],
        Economico_administrativas: []
      },
      "0034-ART-SER-HUMANITAS-RESPONSABILIDAD-SOCIAL-030624": {
        Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y Teatro"],
        Ciencias_sociales: ["Derecho", "Ciencias Políticas y Administración Pública"],
        Economico_administrativas: ["Administración", "Contabilidad"]
      },
    },
    filtrosSeleccionados: null, // Lista de filtros seleccionados
    };
},
methods: {
    
}
};
</script>

<style>
/* Estilos CSS si los tienes */
</style>