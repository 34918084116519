<template>
    <div class="flex flex-col justify-center bg-gray-100 md:pt-36 pt-32 w-full font-sans text-black">
      <h1 class="text-[28px] md:text-[32px] lg:text-[40px] text-hone font-serif md:mb-8 mb-10">Qué aptitudes y habilidades necesitas si <br class="md:block hidden"> quieres estudiar Derecho</h1>
      <div class="flex flex-col justify-center items-center w-full">
        <div class="flex flex-col w-full justify-start max-w-4xl lg:max-w-[1080px] bg-white border border-gray-200">
          <img src="/images/descubre-aptitudes-habilidades-clave-estudiar-derecho.jpg" alt="Son diversas aptitudes y habilidades para estudiar Derecho: conócenos" class="mb-6">
          <div class="flex flex-col justify-center text-justify md:tracking-normal tracking-tight text-[18px] lg:mx-12 mx-6 font-helvetica">
            <p class="text-hone md:mb-4 mb-6 italic text-left text-[18px] md:text-[20px]"><router-link to="/blog/academico" class=" text-hone">Académico</router-link> > <router-link to="/blog/academico/licenciatura" class=" text-hone">Licenciatura</router-link></p>
            <div class="flex items-start justify-start">
                <ul class="lg:flex text-left list-disc pl-5 italic text-[18px] md:text-[20px] mb-6" style="font-family: 'Helvetica Neue Regular', sans-serif;"><li class="mr-16 text-htwo">25 de abril de 2024</li>  <li>Carolina Reyes Martínez/Redacción Humanitas</li></ul>
            </div>
            <p class="md:mb-8 mb-10"><b>Extracto:</b> ¿Piensas estudiar la carrera de Derecho? Descubre las aptitudes y habilidades clave que necesitarás. Aprende qué se necesita para sobresalir en esta carrera y cómo prepararte para los retos que enfrentarás. </p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Las consideraciones que debes conocer antes de estudiar Derecho </h2>
            <p class="md:mb-4 mb-6">¿Cómo saber <b>si tienes las aptitudes y habilidades para estudiar la carrera de Derecho</b>? Primero, es importante que consideres todos los aspectos de esta licenciatura como es entender los fundamentos del sistema legal, la estructura del sistema judicial y los diferentes campos de especialización que existen.</p>
            <p class="md:mb-4 mb-6">Deberás familiarizarte con los conceptos básicos de la teoría legal, como la jurisprudencia y la legislación. También es necesario aprender los derechos y deberes de los ciudadanos y de las leyes.</p>
            <p class="md:mb-4 mb-6">Otro aspecto importante es tener una comprensión de los principios éticos y morales que guían la práctica del Derecho. Esto implica conocer el código de conducta y la responsabilidad profesional de los abogados.</p>
            <p class="md:mb-4 mb-6">Además, es fundamental entender el papel del abogado en la sociedad y cómo se relaciona con otros actores, como los jueces, los fiscales y los clientes.</p>
            <p class="md:mb-8 mb-10">Ahora que ya te hemos dado un panorama de cómo es estudiar Derecho, te decimos qué aptitudes y habilidades necesitas para tener éxito al estudiar esta licenciatura.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">El compromiso con la justicia</h3>
            <p class="md:mb-4 mb-6">Estudiar Derecho requiere tener un compromiso por la justicia y un deseo de defender los derechos de los demás. Los estudiantes de Derecho deben tener esta habilidad para estar motivados por el sentido de equidad, de buscar la resolución de conflictos de manera justa y equitativa.</p>
            <p class="md:mb-4 mb-6">Además, es importante tener empatía y compasión hacia las personas que buscan la justicia. Esto ayudará a desarrollar un enfoque humano, centrado en el cliente en la práctica del Derecho.</p>
            <p class="md:mb-4 mb-6">Estudiar esta licenciatura implica una profunda pasión por la justicia y un compromiso para defender los derechos de las personas. Los estudiantes deben estar motivados por un sentido de equidad y la resolución de los problemas de manera justa.</p>
            <p class="md:mb-8 mb-10">Es fundamental tener empatía hacia las personas que buscan justicia. Esto ayudará a que el futuro abogado desarrolle un enfoque humano en la práctica del derecho.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">La aptitud de la comprensión</h3>
            <p class="md:mb-4 mb-6">El Derecho regula todo lo que existe: desde un contrato de compra-venta hasta normas muy, muy complicadas. Como futuro abogado es indispensable que tengas conocimiento general en diversas materias.</p>
            <p class="md:mb-4 mb-6">Al principio esto puede parecer difícil, pero no te preocupes, una manera de obtener estos conocimientos es estar al pendiente de las noticias para entender, analizar y comprender los temas de actualidad. Cuanto más comprendas una materia y las normas que estudia, mejor desempeño tendrás en tu carrera.</p>
            <p class="md:mb-8 mb-10">Por ejemplo: si te quieres dedicar al Derecho Mercantil, debes tener conocimientos en el mundo de la empresa y todo lo que sucede en este tema es imprescindible, pues derecho y empresa se relacionan.</p>
            <div class="md:mb-8 mb-10 border border-gray-300 w-full">
                <ContactoVue class="m-3"/>
            </div>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">La capacidad de la memorización</h3>
            <p class="md:mb-4 mb-6">Si odias memorizar… Tal vez tengas que reconsiderar estudiar otra licenciatura porque muchas materias de Derecho requieren que memorices datos y artículos, ya que un profesor puede preguntar sobre los artículos en cualquier momento.</p>
            <p class="md:mb-8 mb-10">Un ejemplo: las circunstancias que califican un homicidio como asesinato y el artículo que las explica, como sería el 315.  En derecho aprenderás varios conceptos pero te tocará memorizar.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">El interés por la legislación</h3>
            <p class="md:mb-4 mb-6">Un factor clave es tener un interés genuino por la legislación y su impacto en la sociedad. Esto implica mantenerse actualizado sobre los cambios en las leyes y regulaciones, así como comprender cómo se aplican en diferentes situaciones. Los estudiantes de Derecho deben estar dispuestos a investigar y analizar la legislación existente, así como proponer y argumentar a favor de cambios legales cuando sea necesario.</p>
            <p class="md:mb-8 mb-10">También es importante la comprensión de la historia del Derecho y su evolución. Esto da un contexto importante para comprender las leyes actuales y su desarrollo para abordar los constantes desafíos y necesidades de la sociedad.</p>
            <img src="/images/aptitud-habilidad-de-analisis-investigacion-derecho.jpg" alt="La aptitud y la habilidad de análisis e investigación para encontrar fuentes legales" class="md:mb-8 mb-10">
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">La habilidad de análisis e investigación</h3>
            <p class="md:mb-4 mb-6">Es fundamental examinar y comprender información compleja como casos legales, estatutos y precedentes judiciales. Debes identificar los problemas legales clave, evaluar la relevancia de la información y aplicar el razonamiento jurídico para llegar a las conclusiones relevantes.</p>
            <p class="md:mb-8 mb-10">Además, es esencial tener habilidades de investigación para encontrar y analizar fuentes legales, como libros, artículos y jurisprudencia para sintetizar y comunicar la información legal de manera clara y concisa.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">La aptitud de expresión oral y argumentación</h3>
            <p class="md:mb-4 mb-6">En la carrera tendrás que exponer y debatir. Por ello, deberás transmitir los conocimientos con convicción que demuestre que has comprendido muy bien lo que expones, y si añades técnicas de modulación de voz, sería lo ideal.</p>
            <p class="md:mb-4 mb-6">Las discusiones y los debates serán muy frecuentes, especialmente en las clases para resolver casos prácticos. Ser convincente y sin titubeos es muy importante porque en la vida real a eso te enfrentarás.</p>
            <p class="md:mb-8 mb-10">Para quienes deseen ejercer la abogacía, la capacidad de expresarse y exponer una serie de ideas que defiendan a su cliente es algo imprescindible.</p>
            <img src="/images/aptitud-habilidad-expresion-argumentacion-derecho.jpg" alt="Son diversas aptitudes y habilidades para estudiar Derecho: conócelas" class="md:mb-8 mb-10">
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">Compromiso con el aprendizaje continuo</h3>
            <p class="md:mb-4 mb-6">Estudiar Derecho es un proceso continuo de aprendizaje. Los abogados deben estar dispuestos a mantenerse actualizados sobre los cambios en las leyes y regulaciones, así como a adquirir nuevos conocimientos y habilidades a lo largo de su carrera. Esto implica participar en programas de educación continua y estar abierto a la retroalimentación y la mejora constante.</p>
            <p class="md:mb-8 mb-10">Además, es importante desarrollar habilidades de aprendizaje autodirigido, como la capacidad de investigar y estudiar de forma independiente. Los abogados deben ser capaces de adaptarse a los cambios en el entorno legal y tener la flexibilidad para abordar nuevos desafíos y oportunidades a lo largo de su carrera.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">La habilidad de redacción</h3>
            <p class="md:mb-8 mb-10">Plasmar en escrito las ideas y argumentarlas correctamente es primordial. Los escritos deben estar bien distribuidos en párrafos que agrupen las ideas o temáticas a tratar y evitar los errores de ortografía.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">La aptitud de resolución de conflictos</h3>
            <p class="md:mb-4 mb-6">El Derecho tiene la finalidad de regular la convivencia social. El fin último de ser abogado no es sólo saberse las leyes de memoria, sino que sea capaz de resolver el problema de su o sus clientes.</p>
            <p class="md:mb-8 mb-10">Un buen abogado debe tener esta aptitud. Cuando se presente un caso buscará qué medios existen dentro de la legalidad para resolverlo, sobre todo, cuando haya intereses contrarios deberá abordar la cuestión con tacto para evitar que la situación pueda empeorar y derive en otros procesos judiciales.</p>
            <h3 class="text-[20px] lg:text-[24px] mb-5 text-left font-serif text-hone">La aptitud de trabajo en equipo</h3>
            <p class="md:mb-4 mb-6">El trabajo en equipo es muy importante. En las diferentes materias tendrás que ayudar y colaborar con otros compañeros, así, aprenderás a delegar y a compartir tareas.</p>
            <p class="md:mb-8 mb-10">Al terminar la carrera, si trabajas en un despacho o en una empresa, trabajarás en equipo, o bien, te apoyarás con otros colegas de diversos ramos, pues en algún momento necesitarás asesoría en alguna área del Derecho que no sea tu especialidad.</p>
            <h2 class="text-[24px] md:text-[28px] lg:text-[30px] leading-tight mb-5 text-left font-serif text-hone">Conclusión</h2>
            <p class="md:mb-4 mb-6">Estudiar la carrera de Derecho es un compromiso que requiere aptitudes y habilidades clave. Cada una de ellas son decisivas para preparar al estudiante en los retos del ámbito legal actual, pues es una profesión dinámica y se ajusta a las demandas de la sociedad.</p>
            <p class="md:mb-4 mb-6">Elegir esta licenciatura significa comprometerse con un proceso de formación continua para servir y hacer una contribución real a la comunidad, garantizando que la justicia sea accesible y efectiva para todos.</p>
            <p class="md:mb-8 mb-10">Infórmate y <a href="https://www.youtube.com/watch?v=QaGmQPozlAM&t=2s" target="_blank" class="text-htwo">elige la mejor opción</a> para estudiar.</p>
            <!-- Aqui va el componente de contacto, no se te olvide hacerlo manolo -->
            <p class=" text-htwo text-left mb-6">Explora más: <a href="https://humanitas.edu.mx/" target="_blank" class="text-htwo">Universidad Humanitas</a></p>
            <p class="text-htwo text-left mb-20">Te puede interesar: <router-link to="/blog/aprende/tips/mejores-tecnicas-estudio-para-universidad"  class="text-htwo">Las mejores técnicas de estudio para triunfar en la universidad</router-link></p>
            <div class="flex justify-between md:mb-8 mb-10">
              <LikesVue :articleID="code"/>
              <ShareP :articleLink="ruta" :articleImage="image" :articleText="text"/>
            </div>
          </div>
          <div class="flex justify-start items-start">
            <img src="/images/barra-art.png" class="w-[15%]">
          </div>
        </div>
        <SigArt :ant="true" :linkAnt="'/academico/maestria/maestria-psicologia-guia-completa'" :linkSig="'/academico/licenciatura/tendencias-diseno-grafico-2024'" :sig="true"/>
        <ConComentarios :article="code" class="pb-12"/>
        <Comentarios :article="code" class="pb-12"/>
      </div>
    </div>
</template>
  
<script lang="js">
import Comentarios from '../../pagesComponents/Comentarios.vue';
import ConComentarios from '../../pagesComponents/ConComentarios.vue';
import ContactoVue from '../../pagesComponents/Contacto.vue';
import LikesVue from '../../pagesComponents/Likes.vue';
import ShareP from '../../pagesComponents/SharePoint.vue';
import SigArt from '../../pagesComponents/SigArt.vue';

export default {
    components: { Comentarios, ConComentarios, ContactoVue, LikesVue, ShareP, SigArt},
    mounted() {
      // Agregar metadatos Open Graph al DOM cuando el componente se monta
      //this.setOpenGraphMetaTags();
    },
    data(){
        return {
            code: '018-ART-ACADEMICO-LICENCIATURA-260424',
            ruta: '/academico/licenciatura/aptitudes-habilidades-para-estudiar-derecho',
            title: '27 de marzo Día Mundial del Teatro 2024: celebra la paz y el arte',
            image: 'https://blog.humanitas.edu.mx/images/Costo-y-requisitos-estudiar-maestria.jpg',
            text: 'Descubre las aptitudes y habilidades clave que necesitas para estudiar Derecho. Prepárate para enfrentar los desafíos de esta carrera. ¡Conócelas!'
        }
    },
    methods: {
      setOpenGraphMetaTags() {
        // Crear elementos meta para los metadatos Open Graph
        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = 'https://blog.humanitas.edu.mx/academico/maestria/costo-y-requisitos-estudiar-maestria';

        const ogType = document.createElement('meta');
        ogType.setAttribute('property', 'og:type');
        ogType.content = 'article';

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = this.title;

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = this.text;

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = this.image;

        // Agregar los elementos meta al head del documento
        document.head.appendChild(ogUrl);
        document.head.appendChild(ogType);
        document.head.appendChild(ogTitle);
        document.head.appendChild(ogDescription);
        document.head.appendChild(ogImage);
      }
    }
}
</script>

<style>

</style>