<template>
    <div>
      <CataBueno :titulo="'Ser Humanitas'" :subtitulo="''" :filtros="filtros" :articles="articles"/>
    </div>
</template>

<script lang="js">
import CataBueno from './pagesComponents/CataBueno.vue';

export default {
components: { CataBueno },
data() {
    return {
        articles: [
            {
                "id": "0034-ART-SER-HUMANITAS-RESPONSABILIDAD-SOCIAL-030624",
                "titulo": "Responsabilidad Social Individual: Descubre qué es e inspírate",
                "fecha": "03 de junio de 2024",
                "autor": "Carolina Reyes Martínez",
                "descripcion": "Explora qué es la Responsabilidad Social Individual, qué  tipos existen y ejemplos. Aprende cómo puedes marcar la diferencia en tu comunidad y entorno.",
                "portada": "/images/responsabilidad-social-individual-transforma-entorno.webp",
                "enlace": "/ser-humanitas/responsabilidad-social/responsabilidad-social-individual-tipos-ejemplos-inspiracion"
            },
            {
                "id": "0035-ART-SER-HUMANITAS-COMUNIDAD-30052",
                "titulo": "¿Cuánto cuesta una mensualidad en la Universidad Humanitas?",
                "fecha": "30 de mayo de 2024",
                "autor": "Redacción Humanitas",
                "descripcion": "Descubre cuánto cuesta una mensualidad en la Universidad Humanitas y el nivel de la calidad educativa de reconocimiento internacional.",
                "portada": "/images/cuanto-cuesta-mensualidad-universidad-humanitas-instalaciones.webp",
                "enlace": "/ser-humanitas/comunidad/cuanto-cuesta-mensualidad-universidad-humanitas"
            },
            {
                "id": "0023-ART-ACADEMICO-LICENCIATURA-090524",
                "titulo": "¿Qué es una acción socialmente responsable?",
                "fecha": "09 de mayo de 2024",
                "autor": "Iván Solís Rivera",
                "descripcion": "Para saber qué es una acción socialmente responsable, recurrimos a los estándares internacionales y a ejemplos concretos.",
                "portada": "/images/accion-socialmente-responsable.webp",
                "enlace": "/ser-humanitas/responsabilidad-social/acciones-socialmente-responsables"
            },
            {
                "id": "12-ART-SERHUMANITAS-RESPSOCIAL-130324",
                "titulo": "¿Por qué es importante la Responsabilidad Social Universitaria? Entérate",
                "fecha": "13 de marzo de 2024",
                "autor": "Carolina Reyes Martínez/Redacción Humanitas",
                "descripcion": "Aprende cómo la Responsabilidad Social Universitaria impulsa el cambio social, la sostenibilidad y también el desarrollo de tus habilidades profesionales.",
                "portada": "/images/Por-que-es-importante-responsabilidad-social-universitaria-para-ti.jpg",
                "enlace": "/ser-humanitas/responsabilidad-social/por-que-es-importante-la-responsabilidad-social-universitaria"
            },
        ], // Array de artículos original
        filtros: {
            "0034-ART-SER-HUMANITAS-RESPONSABILIDAD-SOCIAL-030624": {
                Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y Teatro"],
                Ciencias_sociales: ["Derecho", "Ciencias Políticas", "Administración Pública"],
                Economico_administrativas: ["Administración", "Contabilidad"]
            },
            "0035-ART-SER-HUMANITAS-COMUNIDAD-30052": {
                Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y Teatro"],
                Ciencias_sociales: ["Derecho", "Ciencias Políticas", "Administración Pública"],
                Economico_administrativas: ["Administración", "Contabilidad"]
            },
            "0023-ART-ACADEMICO-LICENCIATURA-090524": {
                Humanidades: [],
                Ciencias_sociales: [],
                Economico_administrativas: ["Administración"]
            },
            "12-ART-SERHUMANITAS-RESPSOCIAL-130324": {
                Humanidades: ["Educación", "Psicología", "Diseño Gráfico", "Arquitectura", "Arte y Teatro"],
                Ciencias_sociales: ["Derecho", "Ciencias Políticas", "Administración Pública"],
                Economico_administrativas: ["Administración", "Contabilidad"]
            },
        },
        filtrosSeleccionados: null, // Lista de filtros seleccionados
    };
},
methods: {
    // Método para aplicar los filtros seleccionados
    
}
};
</script>

<style>
/* Estilos CSS si los tienes */
</style>