<script setup lang="ts">
    import VideoHiL from '../VideoHiL.vue';

    interface Props {
        img?: string;
        url?: string;
    }

    const props = withDefaults(defineProps<Props>(), {
        img: '../pagesImages/ejecutivo-video.jpg',
        url: 'https://www.youtube.com/embed/bA3DNFmpeIs?si=ikULxtjQBxxiztZK',
    });
</script>

<template>
    <section class="lg:flex items-center my-12 justify-center h-full lg:h-[450px] w-full  bg-cover bg-[top_center] bg-no-repeat" style="background-image: url(/pagesImages/reyes1.jpg); position: relative; background-attachment: fixed; background-position: center; background-repeat: no-repeat; background-size: cover; overflow: hidden; transition: transform 0.3s ease;" >
        <div class="m-12 lg:w-[30%]">
            <br>
            <p class="text-white text-left font-serif text-[32px] mb-2">En Humanitas</p>
            <p class="text-justify text-white mb-4">
                Estamos comprometidos con la educaci&oacute;n y la superaci&oacute;n de nuestros alumnos. Por eso, constantemente estamos actualiz&aacute;ndonos, para ofrecerte la mejor calidad acad&eacute;mica.
            </p>
            <br>
            <router-link to="/contacto">
                <div class="flex items-center justify-center w-[140px] h-[35px] bg-hwine hover:bg-hgold text-white text-lg transition duration-300 ease-in-out">
                    <span>Inscr&iacute;bete</span>
                </div>
            </router-link>
        </div>
        <VideoHiL :thumbnailUrl="props.img" :videoUrl="props.url" class="lg:p-0 md:px-16"/>
    </section>
</template>

<style lang="scss" scoped>
</style>